import { Checkbox, Form, Input, Select } from 'antd'
import { useEffect } from 'react'

const DocumentForm = ({form, editData, handleFinish}) => {
  
  useEffect(() => {
    if(editData) {
      form.setFieldsValue({
        ...editData,
        contentType: editData.contentType.split("&")
      })
    }
    else {
      form.resetFields()
    }
  }, [editData])
  
  return (
    <Form
      form={form}
      onFinish={handleFinish}
      layout='vertical'>
      <Form.Item name="name" label="Name" rules={[{required: true, message: 'Name is required!'}]}>
        <Input></Input>
      </Form.Item>
      <Form.Item name="isRequired" label="" valuePropName='checked'>
        <Checkbox>
          Required
        </Checkbox>
      </Form.Item>
      <Form.Item name="contentType" label="Content type" rules={[{required: true, message: 'Content type is required!'}]}>
        <Select mode='multiple'>
          <Select.Option value="application/pdf,application/vnd.ms-excel">PDF</Select.Option>
          <Select.Option value=".xls, .csv">Excel</Select.Option>
          <Select.Option value=".doc">Word</Select.Option>
          <Select.Option value="image/png, image/jpeg, image/jpg">Image</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="description" label="Description">
        <Input.TextArea></Input.TextArea>
      </Form.Item>
    </Form>
  )
}

export default DocumentForm