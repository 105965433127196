// import { Editor } from '@tinymce/tinymce-react'
import { Button } from 'antd'
import Logo from 'assets/images/LogoIcon.svg'
import { useCallback, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { instance } from 'utils/axios'
import ReactQuill, { Quill} from 'react-quill-new';
import 'react-quill/dist/quill.snow.css';
import {Mention, MentionBlot} from "quill-mention";

Quill.register({ "blots/mention": MentionBlot, "modules/mention": Mention });

const atValues = [
  { id: 1, value: "Fredrik Sundqvist" },
  { id: 2, value: "Patrik Sjölin" }
];
const hashValues = [
  { id: 3, value: "Fredrik Sundqvist 2" },
  { id: 4, value: "Patrik Sjölin 2" }
];

const ChatWindow = ({thread, data, fetchChat}) => {
  const editorRef = useRef()
  const [ comment, setComment ] = useState('')
  const { id } = useParams()
  const reactQuillRef = useRef()

  const handleComment = () => {
    instance({
      method: 'post',
      url: '/Message/reply?wcode=tender-detail',
      data: {
        "tenderId": id,
        "messageThreadId": data.id,
        "toSupplier": 0,
        "body": comment
      }
    }).then(() => {
      fetchChat()
      setComment('')
    }).catch(() => {

    })
  }

  const matchedPeople = [
    {
      id: 1,
      value: 'John Doe',
    },
    {
      id: 2,
      value: 'Dear Doe',
    },
  ];

  const handleMentionSearch = useCallback(async (searchTerm, renderList) => {
    renderList(matchedPeople);
  }, []);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image'],
    ],
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ['@', '#'],
      source: handleMentionSearch,
    },
  };
  
  return (
    <div className="border-t-2 border-gray-300 flex gap-4 pt-6 px-4" id="chat-container">
      <div>
        <img src={Logo} className="h-[50px] w-[50px] rounded-full shadow-md"></img>
      </div>
      <div className="grow flex flex-col gap-4">
        <div className="font-bold">Add a comment</div>
        <div className="rounded overflow-hidden border border-gray-300">
          <ReactQuill
            ref={reactQuillRef}
            theme="snow" 
            value={comment} 
            onChange={setComment}
            modules={modules}>
          </ReactQuill>
          {/* <Editor
            onInit={(e, editor) => editorRef.current = editor}
            apiKey='79knxv9hbtuofr3q2v730liskq4whvaejztxnwq7w6qe2km3'
            value={comment}
            init={{
              // selector: "#editor",
              height:'400px',
              width: '100%',
              border: 'none',
              branding: false,
              menubar: false,
              toolbar: 'undo redo | fontselect | formatselect | fontsizeselect | bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | image | media',
              content_style: "@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); body { font-family:Roboto; font-size:14px }",
              plugins: 'advlist autolink lists link image charmap preview anchor help searchreplace visualblocks code insertdatetime media table wordcount',
              block_formats: 'Paragraph=p; Header 1=h2; Header 2=h3; Twitter=customtwitter',
              fontsize_formats: "8pt 10pt 12pt 14pt 16px 18pt 24pt 36pt 40px",
              font_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua, palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Oswald=oswald; Roboto=roboto, sans-serif; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats; ",
              formats: {
                customtwitter: { inline: 'span', styles: {backgroundColor: '#e9ebe4'}, classes: 'shareable', id: 'ww' },
                template: { inline: 'span', styles: { color: '#00aaad' }}
              },
              style_formats: [
                { title: 'My PDF file', selector: 'a', classes: 'mypdffile' },
                { title: 'Twitter format', format: 'customtwitter'}
              ],
              file_picker_callback: function (callback, value, meta) {
                var input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.setAttribute('accept', 'image/*');
                input.onchange = function () {
                  var file = this.files[0];
                  var reader = new FileReader();
                  reader.onload = function (e) {
                    callback(e.target.result, {
                      alt: file.name
                  });

                  };
                  reader.readAsDataURL(file);
                };
                input.click();
              },
            }}
            onEditorChange={(e, editor) => {
              setComment(e)
            }}
          /> */}
        </div>
        <div className="flex justify-end">
          <Button type="primary" onClick={handleComment}>
            Comment
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ChatWindow