import React, { useContext } from 'react';
import { AuthContext } from 'contexts';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = (props) => {
  const {state} = useContext(AuthContext)
  const location = useLocation()
  const { children, routeItem } = props;
  const childrenWithProps = React.Children.map(children, child => {
    if(React.isValidElement(child)) {
        return React.cloneElement(child, {userInfo: state})
    }
    return child;
  })

  if(!state.token && !state.loading) {
    return (
      <Navigate to='/login' state={{from: location}} replace/>
    )
  }
  else {
    return childrenWithProps
  }
  // else {
  //   return <div className='flex justify-center h-full items-center'>Таны эрх хүрэхгүй байна</div>
  // }

}

export default ProtectedRoute;
