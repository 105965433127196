import { useEffect, useState } from "react"
import { instance } from "utils/axios"
import { Skeleton, Card, Input, Select, Form, InputNumber, Radio, DatePicker, Upload, Checkbox, Button, Collapse, Modal, Tag } from 'antd'
import { SettingOutlined, StarOutlined, StarFilled } from '@ant-design/icons'
import useFormInstance from "antd/es/form/hooks/useFormInstance"

const Filter = ({wcode, onSearch, children, activeFilters, header}) => {
  const [ filters, setFilters ] = useState([])
  const [ defaultFilter, setDefaultFilter ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const [ filterModalOpen, setFilterModalOpen ] = useState()
  const [ selectedFilter, setSelectedFilter ] = useState(null)
  const [ activeKey, setActiveKey ] = useState(0)
  const [ form ] = Form.useForm()
  const [ masterForm ] = Form.useForm()

  useEffect(() => {
    fetchFilters()
    fetchDefaultFilters()
  }, [wcode])

  const fetchDefaultFilters = () => {
    if(wcode) {
      instance({
        method: 'get',
        url: `/WindowFilter/window/${wcode}`
      }).then(res => {
        setFilters(res.data.responseData)
      }).catch(err => {
  
      }).then(() => {
        setLoading(false)
      })
    }
  }

  const fetchFilters = () => {
    if(wcode) {
      instance({
        method: 'get',
        url: `/WindowFilter/default/${wcode}`
      }).then(res => {
        setDefaultFilter(res.data.responseData)
      }).catch(err => {
  
      }).then(() => {
        setLoading(false)
      })
    }
  }
  
  const handleFilterClick = (e) => {
    e.stopPropagation()
    setFilterModalOpen(true)
  }


  const handleSubmit = (e) => {
    onSearch(e)
    setFilterModalOpen(false)
  }

  const handleSaveConfig = () => {
    if(wcode) {
      instance({
        method: 'post',
        url: `/WindowFilter/user`,
        data: {
          userWindowConfig: wcode,
          windowFilterIds: filters.map(foo => foo.id)
        }
      }).then(res => {
        setFilterModalOpen(false)
      }).catch(err => {
  
      }).then(() => {
        setLoading(false)
      })
    }
  }

  const handleFavouriteClick = (_id) => {
    if(filters.find(foo => foo.id == _id)) {
      setFilters(prev => {
        let tmp = Object.assign([], prev)
        return tmp.filter(foo => foo.id != _id)
      })
    }
    else {
      setFilters(prev => {
        let tmp = Object.assign([], prev)
        tmp.push(defaultFilter.find(foo => foo.id == _id))
        return tmp
      })
    }
  }

  const handleRemove = (foo) => {
    let tmp = Object.assign(activeFilters, {})
    delete tmp[foo]
    onSearch(tmp)
  }

  if(loading) {
    return (
      <Skeleton active></Skeleton>
    )
  }
  return (
    <Card size="small">
      <Collapse
        activeKey={activeKey}
        onChange={e => setActiveKey(e)}
        ghost
        items={[
          {
            key: 0,
            label: (
              <div className="flex justify-between items-center gap-3">
                <div className="flex gap-3 items-center">
                  <span className="text-lg font-bold">
                    Filter
                  </span>
                  {
                    header
                  }
                </div>
                <div className="grow flex gap-3 flex-wrap">
                  {
                    activeFilters && 
                    Object.keys(activeFilters).map((foo, fooIndex) => {
                      if(activeFilters[foo] && foo != "type") {
                        if ((Array.isArray(activeFilters[foo]) && activeFilters[foo].length > 0) || typeof activeFilters[foo] == "string") {
                          return (
                            <Tag closable key={`active-${fooIndex}`} onClose={() => handleRemove(foo)}>
                              <span>{defaultFilter.find(_foo => _foo.name == foo)?.text} = {activeFilters[foo]}</span>
                            </Tag>
                          )
                        }
                      }
                      
                    })
                  }
                </div>
                <div>
                  <Button type="primary" onClick={handleFilterClick} icon={<SettingOutlined/>}></Button>
                </div>
              </div>
            ),
            children: (
              <div className="flex gap-3">
                <Form
                  layout="vertical"
                  className="grid grid-cols-4 lg:gap-x-3 gap-x-6 universal-filter grow"
                  form={form}
                  onFinish={onSearch}>
                  {
                    children
                  }
                  {
                    filters.map((foo, fooIndex) => {
                      return (
                        <FilterContainer key={`filter-${fooIndex}`} data={foo}/>
                      )
                    })
                  }
                </Form>
                <div className="col-span-4 flex justify-end items-end pb-5">
                  <Button type="primary" onClick={form.submit}>Search</Button>
                </div>
              </div>
            )
          }
        ]}>

      </Collapse>
      <Modal
        width={`max(50%, 500px)`}
        style={{top: '20px'}}
        title="Filter settings"
        open={filterModalOpen}
        onOK={() => {masterForm.submit()}}
        onCancel={() => setFilterModalOpen(false)}
        footer={<div className="flex gap-3 justify-end"
        destroyOnClose={true}>
          <Button onClick={() => setFilterModalOpen(false)}>Cancel</Button>
          <Button type="primary" onClick={handleSaveConfig}>Save config</Button>
          <Button type="primary" onClick={() => masterForm.submit()}>Filter</Button>
        </div>}>
        <Form
          layout="vertical"
          className="grid grid-cols-1 min-h-[50vh]"
          form={masterForm}
          onFinish={handleSubmit}>
          <div className="grid grid-cols-3 gap-3">
            <div className="bg-gray-300">
              {
                defaultFilter.map((foo, fooIndex) => {
                  return (
                    <div 
                      key={`filter-button-${fooIndex}`} 
                      className={`px-3 py-2 transition-all cursor-pointer hover:bg-[#ffffff70] relative flex`}
                      style={selectedFilter == foo ? {background: 'white'} : {}}
                      onClick={() => {
                        setSelectedFilter(foo)
                      }}>
                      <div className="grow">
                        {
                          foo.text
                        }
                      </div>
                      <NumberRenderer data={foo}></NumberRenderer>
                      {/* <Form.Item noStyle shouldUpdate={(prev, next) => prev[foo.name] != next[foo.name]}>
                        
                      </Form.Item> */}
                      {
                        selectedFilter == foo &&
                        <div className="absolute h-full w-[2px] bg-primary top-0 left-0">
                          
                        </div>
                      }
                    </div>
                  )
                })
              }
            </div>
            <div className="border col-span-2 p-4">
              {
                selectedFilter &&
                <FilterContainer data={selectedFilter} master={true} favourite={filters} handleFavouriteClick={handleFavouriteClick}></FilterContainer>
              }
            </div>
          </div>
          {/* {
            defaultFilter.map((foo, fooIndex) => {
              return (
                <FilterContainer key={`master-filter-${fooIndex}`} data={foo} master={true} favourite={filters} handleFavouriteClick={handleFavouriteClick}></FilterContainer>
              )
            })
          } */}
        </Form>
      </Modal>
    </Card>
  )
}

const NumberRenderer = (data) => {
  const form = useFormInstance()
  const field = Form.useWatch(data.name, form);

  console.log(field)

  return (
    <div className="font-bold text-primary">
      {data.name && field[data.name] ? Array.isArray(field[data.name]) ? field[data.name].length : 1 : 0}
    </div>
  )
}

const FilterContainer = ({data, master, favourite, handleFavouriteClick}) => {
  const [ loading, setLoading ] = useState(data.filterType == "Master" ? true : false)
  const [ options, setOptions ] = useState([])

  useEffect(() => {
    if(data.filterType == "Master") {
      fetchOptions()
    }
    else if(data.filterType == "Enum") {
      setOptions(eval(data.filterOption).map(foo => ({value: foo.id, label: foo.name})))
    }
  }, [data])
  
  const renderInput = () => {
    switch (data.editorTypeId) {
      case 1:
        return (
          <InputNumber className="w-full bg-[#FCFCFD]"/>
        )
      case 2:
        return (
          <Input className="w-full bg-[#FCFCFD]" placeholder={`Enter ${data.text.toLowerCase()}`}/>
        )
      case 3:
        return (
          <Select mode="multiple" placeholder={`Select ${data.text}`} options={options} loading={loading} className="bg-[#FCFCFD]"></Select>
        )
      case 4:
        return (
          <Radio className="w-full bg-[#FCFCFD]"/>
        )
      case 5:
        return (
          <DatePicker className="w-full bg-[#FCFCFD]"/>
        )
      case 7:
        return (
          <Upload className="w-full bg-[#FCFCFD]"/>
        )
      case 8:
        return (
          <Checkbox.Group className="w-full flex flex-col bg-[#FCFCFD]" options={options}/>
        )
      default:
        return (
          <Input className="bg-[#FCFCFD]"></Input>
        )
    }
  }

  const fetchOptions = () => {
    let tmp = []

    instance({
      method: 'get',
      url: data.filterOption.replace("/api", "")
    }).then(res => {
      res.data.responseData.map((foo, fooIndex) => {
        if(foo.item) {
          tmp.push({
            value: foo.item.id,
            label: foo.item.name
          })
        }
        else {
          tmp.push({
            value: foo.id,
            label: foo.name
          })
        }
      })
      setOptions(tmp)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }
  
  return (
    <div className="flex gap-3 items-end">
      <Form.Item className="grow" name={data.name} label={!master && data.text}>
        {renderInput()}
      </Form.Item>
      {
        master &&
        <Button 
          className="mb-6" 
          type="link" 
          onClick={() => handleFavouriteClick(data.id)} 
          icon={favourite.find(foo => foo.id == data.id) ? <StarFilled/> : <StarOutlined/>}>
        </Button>
      }
    </div>
  )
}

export default Filter