import { Button, Table } from "antd"
import { useState } from "react"
import { useOutletContext, useParams } from "react-router-dom"
import { instance } from "utils/axios"
import { expandedServiceColumns, expandedSuppliersColumns, totalColumns } from "./rfqFormColumns"
import { suppliersColumns } from './serviceFormColumns'

const ServiceReport = ({data, rawData, mainData, selected, closeModal, handleClose}) => {
  const [ loading, setLoading ] = useState(false)
  const [ outletData ] = useOutletContext()
  const { id } = useParams() 

  const handleSubmit = () => {
    instance({
      method: 'post',
      url: `/Tenderrfq/rfq/sendtoapproval?wcode=tender-detail`,
      data: {
        shortReport: data.shortReport,
        tenderId: id,
        summaryReport: data.summaryReport,
        totalComment: data.total.find(foo => foo.title == "Total Suppliers invited to RFQ").commentText ?? "",
        respondedComment: data.total.find(foo => foo.title == "Responded suppliers to RFQ").commentText ?? "",
        notParticipatedComment: data.total.find(foo => foo.title == "Not participated").commentText ?? "",
        supplierDetails: data.suppliersData.map((foo) => ({supplierId: foo.id, commentText: foo.commentText})),
        details: [],
        disQualifiedItems: [],
        users: [],
        serviceDetails: data.serviceDetails.map(foo => ({tenderSupplierDetailId: foo.id, status: 1, commentText: foo.commentText}))
      }
    }).then(() => {
      console.log('success')
      outletData.fetchData()
      handleClose()
    }).catch(() => {

    })
  }
  
  return (
    <div className="grid grid-cols-12 gap-3">
      <div className="col-span-4 grid grid-cols-3">
        <div className="col-span-2 font-bold">
          RFQ number:
        </div>
        <div className="col-span-1">
          {mainData.code}
        </div>
      </div>
      <div className="col-span-4 grid grid-cols-3">
        <div className="col-span-1 font-bold">
          RFQ name:
        </div>
        <div className="col-span-2">
          {mainData.name}
        </div>
      </div>  
      <div className="col-span-4 grid grid-cols-3">
        <div className="col-span-2 font-bold">
          Total value for approval:
        </div>
        <div className="col-span-1">
          {/* {mainData.name} */}
        </div>
      </div>
      <div className="col-span-12">
        <div className="font-bold">RFQ service Summary</div>
        <div className="border p-2" dangerouslySetInnerHTML={{__html: data.summaryReport}}>

        </div>
      </div>
      <div className="col-span-12 flex flex-col gap-3">
        <Table
          columns={totalColumns}
          dataSource={data.total}
          pagination={false}
          size="small"
          rowKey={(row, index) => {
            return index
          }}
          expandable={{
            expandedRowRender: rec => {
              return (
                <div>
                  <Table
                    columns={expandedSuppliersColumns}
                    dataSource={rec.data}
                    pagination={false}
                    size="small">

                  </Table>
                </div>
              )
            }
          }}>

        </Table>
        <Table
          columns={suppliersColumns()}
          dataSource={data.serviceDetails}
          pagination={false}
          rowKey={(row, index) => {
            return row.id
          }}
          expandable={{
            expandedRowRender: rec => {
              return (
                <div dangerouslySetInnerHTML={{__html: rec.content}}>
                </div>
              )
            }
          }}
          size="small">
          
        </Table>
      </div>
      <div className="col-span-12 flex justify-end gap-4">
        <Button onClick={closeModal} loading={loading}>Prev</Button>
        <Button type="primary" onClick={handleSubmit} loading={loading}>Submit</Button>
      </div>
    </div>
  )
}

export default ServiceReport