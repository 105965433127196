import { Button, Checkbox, Col, Input, Modal, Table, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { SearchOutlined, FilterOutlined, ControlOutlined, PlusOutlined, LeftOutlined, PrinterOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Excelexport } from 'components';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const Row = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    cursor: 'move',
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999,
        }
      : {}),
  };
  return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

const MainTable = ({ goback=false, dataTable=[], columns, setCurrentPage, loading, expandable=null, handleAdd, rowSelectionShow=false, pagination=false, setSelectedKeys, selectedKeys, title, borderHide=false, containerClass='', scroll, filter, draggable = false, excelExport = false, hideHeader = false, ...restProps }) => {
  const [ mainColumns, setMainColumns ] = useState([]);
  const [ dataSource, setDataSource ] = useState(dataTable)
  const navigate = useNavigate()
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1,
      },
    }),
  );

  useEffect(() => {
    setDataSource(dataTable)
  }, [dataTable])
  
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  useEffect(() => {
    let columnItem = [];
    columns.map((fooValue) => {
          columnItem.push({
          ...fooValue,
          ...renderFilter(fooValue)
        })
    })
    setMainColumns(columnItem);
    },[columns]);

    const rowSelections = {
      type: "checkbox",
      selectedRowKeys: selectedKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        setSelectedKeys(selectedRowKeys);
      }
      };

    const renderFilter = (_data) => {
      switch (_data?.filterType) {
          case "select":
              return {
                filterMode: "menu",
                filterSearch: true,
                onFilter:(value, record) => {
                  return parseInt(record[_data.dataIndex].id) === value
                },
                filterIcon: () => {
                  return <FilterOutlined />
                },
              }
          case "status":
              return {
                filterMode: "menu",
                onFilter:(value, record) => {
                  return record[_data.dataIndex] === value
                },
                filterIcon: () => {
                  return <FilterOutlined />
                },
              }
          case "search":
              return {
                filterDropdown:({ setSelectedKeys, selectedKeys, confirm, clearFilters  })=> {
                  return <div className='flex flex-col rounded-lg p-2'>
                    <Input
                    autoFocus
                    placeholder={`Search ${_data.title}`}
                    value={selectedKeys[0]}
                    onChange={(e)=> {
                      setSelectedKeys(e.target.value ? [e.target.value] : [])
                      confirm({ closeDropdown: false });
                    }}
                    onPressEnter={()=> {
                      confirm()
                    }}
                    onBlur={() => {
                      confirm()
                    }}
                    />
                  </div>
                },
                // sortDirections: ['descend', 'ascend'],
                // sorter: (value, record) => {
                //   return value[_data.dataIndex].localeCompare(record[_data.dataIndex])
                // },
                filterIcon: () => {
                  return <SearchOutlined />
                },
                onFilter: (value, record) =>{
                  return record[_data.dataIndex]
                  ? record[_data.dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                  : ''
                },
              }
          default:
              return (
                  <></>
              )
      }
  }

    return (
      <div className={`bg-white overflow-hidden rounded-[8px] ${borderHide ? '' : 'border border-gray-200 shadow-sm'} ${containerClass}`}>
        {
          !hideHeader && 
          <div className={'flex flex-row py-3 px-3 items-center justify-between'}>
            <div className="flex gap-3 items-center">
              {
                goback &&
                <Tooltip title="Go back">
                  <Button type="text" className='text-primary' onClick={() => navigate(-1)} icon={<LeftOutlined />}></Button>
                </Tooltip>
              }
              {
                title &&
                <div className='text-[16px] font-medium'>{title}</div>
              }
              {
                filter &&
                filter
              }
            </div>
            <div className="flex gap-2">
              {
                handleAdd &&
                <Tooltip title="Add new">
                  <Button type='text' className='text-primary' onClick={handleAdd} icon={<PlusOutlined></PlusOutlined>}>
                    {/* <PlusOutlined /> */}
                  </Button>
                </Tooltip>
              }
              {
                excelExport &&
                <Excelexport excelData={dataTable}></Excelexport>
              }
            </div>
          </div>
        }
        {
          draggable ?
          <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
            <SortableContext
              // rowKey array
              items={dataSource.map((i) => i.key)}
              strategy={verticalListSortingStrategy}
            >
              <Table
                id='print'
                className={borderHide ? 'shadow-xl' : 'shadow-xl'}
                rowKey={(row) => row.id}
                rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' :  'table-row-light'}
                columns={mainColumns}
                dataSource={dataSource}
                loading={loading}
                size='large'
                scroll={scroll ? scroll : {x: 'auto'}}
                expandable={expandable}
                rowSelection={rowSelectionShow && rowSelections}
                components={{
                  body: {
                    row: Row,
                  },
                }}
                pagination={pagination}
                {...restProps}
              />
            </SortableContext>
          </DndContext>
          :
          <Table
            id='print'
            className={borderHide ? 'shadow-xl' : 'shadow-xl'}
            rowKey={(row) => row.id}
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' :  'table-row-light'}
            columns={mainColumns}
            dataSource={dataTable}
            loading={loading}
            size='small'
            scroll={scroll ? scroll : {x: 'auto'}}
            expandable={expandable}
            rowSelection={rowSelectionShow && rowSelections}
            pagination={pagination}
            {...restProps}
          />
        }
        
      </div>
      )
    }

    export default MainTable;
