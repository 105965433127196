import { Table } from "antd"

const DisqualifiedSuppliers = ({data}) => {
  return (
    <div>
      <p>Disqualified suppliers</p>
      <Table
        className="border border-gray-300 rounded overflow-hidden darker-header"
        pagination={false}
        size="small"
        dataSource={data.disqualifiedSuppliers}
        columns={[
          {
            title: '#',
            dataIndex: 'index',
            width: 50,
            render: (cell, rec, index) => index+1
          },
          {
            title: 'Dis-qualified suppliers',
            dataIndex: 'name'
          },
          {
            title: 'Comment',
            dataIndex: 'commentText'
          },
        ]}>

      </Table>
    </div>
  )
}

export default DisqualifiedSuppliers