import React, { useEffect, useState } from 'react'
import { Button, Modal, Form as AntForm, Tag } from 'antd'
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Form, MainTable } from 'components'
import { instance } from 'utils/axios'
import fields from './fields'
import { Link } from 'react-router-dom'

function SupplierInfo() {
  const [ data, setData ] = useState([])
  const [ userPostionData, setUserPostionData ] = useState([])
  const [ currentPage, setCurrentPage ] = useState(0)
  const [ open, setOpen ] = useState(false)
  const [ editData, setEditData ] = useState(null)
  const [ isEdit, setIsEdit ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ modal, contextHolder ] = Modal.useModal();
  const [ form ] = AntForm.useForm();

  useEffect(() => {
    fetchData()
    fetchUserPositionData()
  },[])

  const fetchData = () => {
    setLoading(true)
    instance({
      method: 'get',
      url: '/SupplierInfo'
    }).then((res) => {
      setData(res.data.responseData)
    }).catch((err) => {
    }).then(() => setLoading(false))
  }

  const fetchUserPositionData = () => {
    setLoading(true)
    instance({
      method: 'get',
      url: '/UserPosition/list'
    }).then((res) => {
      setUserPostionData(res.data.responseData)
    }).catch((err) => {
    }).then(() => setLoading(false))
  }
  const columns = [
    {
      title: '#',
      dataIndex: 'No',
      key: 'No',
      width: 40,
      align: 'center',
      render: (text, row, i) => (
        <span>{currentPage+i+1}</span>
      )
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName ',
      filterType: "search",
      render: (text, row) => (
        <Link className='text-link hover:underline hover:text-link' to={`${row.id}`}>{text}</Link>
      )
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      filterType: "search",
    },
    {
      title: 'Position',
      dataIndex: 'userPositionId',
      key: 'userPositionId',
      filterType: "search",
      render: (text, row) => (
        <>{userPostionData.find((item) => item?.text === text)?.label}</>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filterType: "search",
      render: (text, row) => (
      <Tag color={text === 1 ? 'success' : 'default'}>
        {text === 1 ? 'Идэвхтэй' : 'Идэвхгүй'}
      </Tag>
      )
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      filterType: "search",
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 80,
      render: (text, row) => (
      <Button size='small' onClick={() => handleEdit(row)}>
        <div className='flex items-center gap-2'><EditOutlined/> Засах</div>
      </Button>
      )
    },
  ]
  const handleDelete = (id) => {
    modal.confirm({
      title: 'Warning!',
      content: 'You are about to delete this row of data',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: 'delete',
            url: `SupplierInfo/${id}`
          }).then((res) => {
            resolve()
          }).catch((err) => {
            reject()
          }).then(() => fetchData())
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

  const handleSubmit = (values) => {
    setLoading(true)
    if(editData){
      instance({
        method: 'put',
        url: '/SupplierInfo',
        data: {
          ...values,
          id: editData.id
        }
      }).then((res) => {
        handleCloseModal()
        fetchData()
      }).catch((err) => {

      }).then(() => setLoading(false))
    }
    else{
      instance({
        method: 'post',
        url: '/SupplierInfo',
        data: {
          ...values
        }
      }).then((res) => {
        handleCloseModal()
        fetchData()
      }).catch((err) => {

      }).then(() => setLoading(false))
    }
  }

  const handleAdd = () => {
    setIsEdit(true)
    setEditData(null)
    setOpen(true)
  }

  const handleEdit = (row) => {
    setEditData(row)
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
    form.resetFields()
  }
  return (
      <div className='p-4'>
        <MainTable
          dataTable={data}
          columns={columns}
          setCurrentPage={setCurrentPage}
          loading={loading}
          pagination={false}
          title={"SupplierInfo"}
          handleAdd={handleAdd}
        />
        <Modal
          title={editData ? <div className='flex items-center justify-start gap-2'><EditOutlined/>Edit</div> : <div className='flex items-center justify-start gap-2'><PlusCircleOutlined/>Add</div>}
          open={open}
          destroyOnClose
maskClosable={false}
          okText='Save'
          onOk={form.submit}
          onCancel={handleCloseModal}
        >
          <Form
            form={form}
            fields={fields()}
            length={data.length}
            layout="vertical"
            onFinish={handleSubmit}
            editData={editData}
            isEdit={isEdit}
            className='gap-4'
          />
        </Modal>
        {contextHolder}
      </div>
  )
}

export default SupplierInfo
