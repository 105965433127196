import { Card, Statistic } from "antd"
import { ArrowUpOutlined } from '@ant-design/icons'
import { Sankey, Tiny, Pie } from '@ant-design/plots';
import { useEffect, useState } from "react";

const data = [
  264, 417, 438, 887, 309, 397, 550, 575, 563, 430, 525, 592, 492, 467, 513, 546, 983, 340, 539, 243, 226, 192,
].map((value, index) => ({ value, index }));
const PlotMaps = {};

const Demo = () => {

  const config = {
    data: {
      type: 'fetch',
      value: 'https://assets.antv.antgroup.com/g2/energy.json',
    },
    scale: {
      color: {
        range: [
          '#4e79a7',
          '#f28e2c',
          '#e15759',
          '#76b7b2',
          '#59a14f',
          '#edc949',
          '#af7aa1',
          '#ff9da7',
          '#9c755f',
          '#bab0ab',
        ],
      },
    },
    layout: { nodeAlign: 'center', nodePadding: 0.03 },
    style: {
      labelSpacing: 3,
      labelFontWeight: 'bold',
      nodeStrokeWidth: 1.2,
      linkFillOpacity: 0.4,
    },
  };

  const areaConfig = {
    data,
    padding: 8,
    shapeField: 'smooth',
    xField: 'index',
    yField: 'value',
    style: {
      fill: 'linear-gradient(-90deg, white 0%, darkgreen 100%)',
      fillOpacity: 0.6,
    },
    annotations: [
      {
        type: 'lineY',
        data: [data.reduce((acc, cur) => acc + cur.value, 0) / data.length],
        label: {
          text: '平均值',
          position: 'left',
          dx: -10,
          style: { textBaseline: 'bottom' },
        },

        style: { stroke: 'rgba(0, 0, 0)' },
      },
      {
        type: 'lineY',
        data: [800],
        label: {
          text: '目标值',
          position: 'left',
          dx: -10,
          style: { textBaseline: 'bottom' },
        },
        style: { stroke: 'rgba(0, 0, 0)' },
      },
    ],
  };

  const pieConfig = {
    data: {
      type: 'fetch',
      value: 'https://render.alipay.com/p/yuyan/180020010001215413/antd-charts/pie-doughnut.json',
    },
    angleField: 'value',
    colorField: 'name',
    legend: false,
    innerRadius: 0.6,
    labels: [
      { text: 'name', style: { fontSize: 10, fontWeight: 'bold' } },
      {
        text: (d, i, data) => (i < data.length - 3 ? d.value : ''),
        style: {
          fontSize: 9,
          dy: 12,
        },
      },
    ],
    style: {
      stroke: '#fff',
      inset: 1,
      radius: 10,
    },
    scale: {
      color: {
        palette: 'spectral',
        offset: (t) => t * 0.8 + 0.1,
      },
    },
  };
  
  return (
    <div className="grid grid-cols-12 gap-3 col-span-12">
      <Card className="col-span-9">
        <div>
          <div className="flex gap-3 justify-between">
            <Statistic
              title="Active"
              value={11.28}
              precision={2}
              valueStyle={{
                color: '#3f8600',
              }}
              prefix={<ArrowUpOutlined />}
              suffix="%">

            </Statistic>
            <Statistic
              title="Active"
              value={11.28}
              precision={2}
              valueStyle={{
                color: '#3f8600',
              }}
              prefix={<ArrowUpOutlined />}
              suffix="%">

            </Statistic>
            <Statistic
              title="Active"
              value={11.28}
              precision={2}
              valueStyle={{
                color: '#3f8600',
              }}
              prefix={<ArrowUpOutlined />}
              suffix="%">

            </Statistic>
            <Statistic
              title="Active"
              value={11.28}
              precision={2}
              valueStyle={{
                color: '#3f8600',
              }}
              prefix={<ArrowUpOutlined />}
              suffix="%">

            </Statistic>
          </div>
          <Sankey {...config} />
        </div>
      </Card>
      <Card className="col-span-3">
        <div>
          <p className="text-lg">Demo</p>
          <p className="text-xl">Demo data area</p>
        </div>
        <div className="w-full">
          <Tiny.Area {...areaConfig} />
        </div>
      </Card>
    </div>
  )
}

export default Demo