import { Button, Card, Form, Input } from "antd"
import { LockOutlined } from '@ant-design/icons'
import { useState } from "react"
import { instance } from "utils/axios"

const ChangePassword = () => {
  const [ loading, setLoading ] = useState(false)
  const [ form ] = Form.useForm()

  const handleSubmit = (values) => {
    setLoading(true)
    instance({
      method: 'put',
      url: `/User/changepassword`,
      data: values
    }).then(res => {
      form.resetFields()
    }).catch(() => {

    }).then(() => {
      setLoading(false)
    })
  }
  
  return (
    <div className="container mx-auto py-10">
      <Card title="Change password" size="small">
        <Form
          form={form}
          scrollToFirstError
          onFinish={handleSubmit}
          layout="vertical">
          <Form.Item
            label="Old password"
            name='oldPassword'
            rules={[{required: true, message: 'Old password is required!'}]}>
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder='Old password'
            />
          </Form.Item>
          <Form.Item
            label="New password"
            name="newPassword"
            rules={[{required: true, message: 'New password is required!'}]}>
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder='New password'
            />
          </Form.Item>
          <Form.Item
            label="Confirm password"
            name="rePassword"
            rules={[
              {required: true, message: 'Confirm password is required!'},
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The new password that you entered do not match!'));
                },
              })
            ]}>
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder='Confirm password'
            />
          </Form.Item>
          <Form.Item>
            <Button
              loading={loading}
              disabled={loading}
              block
              type="primary"
              size="middle"
              htmlType="submit"
              className="bg-primary border-none hover:bg-blue-600 h-11 rounded-lg">
              Change
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  )
}

export default ChangePassword