import { useEffect, useLayoutEffect, useState } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { instance } from 'utils/axios';

function rndm( max, min = 0 ){ // [ min, max ]
  return min + Math.floor( Math.random() *( max - min + 1 ));
}
function rndmColour() { // Hue Saturation Lightness
  return `hsl(${rndm( 360 )},${rndm( 100 )}%,${rndm( 100 )}%)`;
}

const TenderPie = ({activeTender, activeStatus}) => {
  const [ data, setData ] = useState([]) 
  const [ mainData, setMainData ] = useState([])
  // let colorSet = new am4core.ColorSet();
  // let colorSet = am5.ColorSet();

  useEffect(() => {
    instance({
      method: 'get',
      url: `/Dashboard/tender/bysectcode?wcode=dashboard`,
      params: {
        tenderType: activeTender,
        status: activeStatus
      }
    }).then(res => {
      setMainData(res.data.responseData)
      // setData.map((foo, fooIndex) => {

      // })
    })
  }, [activeTender, activeStatus])
  
  useEffect(() => {
    let watermark = document.getElementsByClassName("am5-layer-30")
    if(watermark && watermark.length > 0) {
      for(var i = 0; i < watermark.length; i++) {
        watermark[i].style.display = "none"
      }
    }
  }, [])
  
  useLayoutEffect(() => {
    let root = am5.Root.new("chartdiv");
    root.setThemes([
      am5themes_Animated.new(root)
    ]);
    
    var chart = root.container.children.push(am5percent.PieChart.new(root, {
      innerRadius: 75,
      layout: root.verticalLayout
    }));
    
    var series = chart.series.push(am5percent.PieSeries.new(root, {
      valueField: "size",
      // categoryField: 'sector'
    }));
    
    series.data.setAll(mainData.map(foo => ({
      sector: foo.name,
      size: foo.total
    })));
    
    console.log(root.interfaceColors.get('stroke'))
    series.appear(450, 100);

    var label = root.tooltipContainer.children.push(am5.Label.new(root, {
      x: am5.p50,
      y: am5.p50,
      centerX: am5.p50,
      centerY: am5.p50,
      fill: am5.color(0x000000),
      fontSize: 20
    }));

    label.set("text", mainData.reduce((a,b) => a + b.total, 0))

    return () => {
      root.dispose();
    };
  }, [mainData]);
  
  return (
    <div className="flex gap-3 h-[250px]">
      <div className="grow">
        <p className='text-lg font-medium'>By Sect Code</p>
        <div>
          {mainData.map((foo, fooIndex) => {
            return (
              <div className="flex gap-3" key={`legend-pie-${fooIndex}`}>
                <div className="w-[8px] h-[8px]">
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="col-span-2 h-[250px]">
        <div id="chartdiv" className='aspect-video h-full'></div>
      </div>
    </div>
    
  )
}

export default TenderPie