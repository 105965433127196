import { useEffect, useState } from "react"
import { Button, Card, Form, Input, Modal, Select, Skeleton, Tooltip } from 'antd'
import { FormOutlined, LeftOutlined } from '@ant-design/icons'
import moment from 'moment'
import { instance } from "utils/axios"
import { useOutletContext, useParams } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component';
import ChatBubble from "./chatBubble"
import ChatWindow from "./chatWindow"

const PrivateChat = ({mainThread}) => {
  const [ loading, setLoading ] = useState(true)
  const [ data, setData ] = useState([])
  const [ hasMore, setHasMore ] = useState(false)
  const { id } = useParams()
  const [ selectedChat, setSelectedChat ] = useState(null)
  const [ chats, setChats ] = useState([])
  const [ chatModalOpen, setChatModalOpen ] = useState(false)
  const [ forwardModalOpen, setForwardModalOpen ] = useState(false)
  const [ suppliers, setSuppliers ] = useState([])
  const [ form ] = Form.useForm()
  const [ forwardForm ] = Form.useForm()
  
  useEffect(() => {
    fetchData()
    fetchSuppliers()
  }, [])

  useEffect(() => {
    if(selectedChat) {
      setLoading(true)
      fetchChat()
    }
  }, [selectedChat])

  const fetchChat = () => {
    instance({
      method: 'get',
      url: `/Message/thread/${selectedChat.id}`
    }).then(res => {
      setChats(res.data.responseData.reverse())
    }).catch(() => {
      
    }).then(() => {
      setLoading(false) 
    })
  }

  const fetchData = () => {
    Promise.all([
      instance({
        method: 'get',
        url: `/Message/tender/${id}?wcode=tender-detail`
      }),
      instance({
        method: 'get',
        url: `/Message/tender/buyer/${id}?wcode=tender-detail`
      })
    ]).then(res => {
      setData([...res[0].data.responseData, ...res[1].data.responseData])
    }).catch(err => {
      console.log(err)
    }).then(() => {
      setLoading(false)
    })
  }
  
  const fetchSuppliers = () => {
    instance({
      method: 'get',
      url: `/Tender/suppliers/${id}?wcode=tender-detail`
    }).then(res => {
      setSuppliers(res.data.responseData)
    }).catch(() => {

    })
  }

  const fetchNext = () => {

  }

  const handleSubmit = (values) => {
    instance({
      method: 'post',
      url: `/Message/send`,
      data: {
        "tenderId": id,
        ...values
      }
    }).then(() => {
      form.resetFields()
      setChatModalOpen(false)
      fetchData()
    })
  }

  const handleForward = (message) => {
    forwardForm.setFieldValue("message", message.body)
    setForwardModalOpen(true)
  }

  const handleForwardMessage = (values) => {
    setLoading(true)
    instance({
      method: 'post',
      url: `/Message/reply`, 
      data: {
        messageThreadId: values.threadId,
        tenderId: id,
        body: values.message,
      }
    }).then(() => {
      setForwardModalOpen(false)
    }).catch(() => {

    }).finally(() => {
      setLoading(false)
    })
  }
  
  return (
    <div className="flex flex-col gap-2">
      {
        loading ?
        <Skeleton active></Skeleton>
        :
        selectedChat ? 
        <div className="py-6 bg-white">
          <div className="border-b border-gray-200 flex gap-4 mb-6 pb-6">
            <div>
              <Tooltip title="Go back to list">
                <Button className="h-full" type='link' onClick={() => setSelectedChat(null)} icon={<LeftOutlined />}>

                </Button>
              </Tooltip>
            </div>
            <div className="grow">
              <div className="text-xl font-bold">
                {selectedChat.name}
              </div>
              <div>
                {selectedChat.title}
              </div>
            </div>
          </div>
          {chats.map((foo, fooIndex) => {
            return (
              <ChatBubble data={foo} key={`chat-bubble-${fooIndex}`} handleForward={handleForward}></ChatBubble>
            )
          })}
          <ChatWindow data={selectedChat} fetchChat={fetchChat}></ChatWindow>
        </div>
        :
        <div className="bg-white">
          <div className="flex justify-between items-center py-2 px-4 border-b border-gray-200">
            <div className="text-lg font-bold">Chat</div>
            <div>
              <Tooltip title="Create new chat">
                <Button type="link" onClick={() => setChatModalOpen(true)}>
                  <FormOutlined className="text-xl"/>
                </Button>
              </Tooltip>
            </div>
          </div>
          {
            data.map((foo, fooIndex) => {
              return (
                <Card className="rounded-none border-b border-slate-100 last:border-none hover:bg-gray-50 transition-all cursor-pointer duration-75" bordered={false} key={`chat-${fooIndex}`} bodyStyle={{padding: `8px 16px`}} onClick={() => setSelectedChat(foo)}>
                  <div className="flex gap-3">
                    <div className="w-50 h-50">
                      <img src={"https://via.placeholder.com/50"} className="shadow-md rounded-full"></img>
                    </div>
                    <div className="grow">
                      <div>{foo.name}</div>
                      <div className="flex gap-4">
                        <div className="truncate whitespace-nowrap">{foo.title}</div>
                        <div>{moment(foo.lastResponseDate).format("HH:mm")}</div>
                      </div>
                    </div>
                  </div>
                </Card>
              )
            })
          }
        </div>
      }
      <Modal
        open={chatModalOpen}
        onCancel={() => setChatModalOpen(false)}
        title="Create new chat"
        onOk={form.submit}>
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical">
          <Form.Item name="supplierId" label="To">
            <Select 
              showSearch
              optionFilterProp="label"
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
              placeholder="Select supplier" 
              options={suppliers.map((foo) => ({
              value: foo.id,
              label: foo.name
            }))}></Select>
          </Form.Item>
          <Form.Item name="title" label="Title">
            <Input placeholder="Title"></Input>
          </Form.Item>
          <Form.Item name="body" label="Body">
            <Input.TextArea placeholder="Comment" rows={4}></Input.TextArea>
          </Form.Item>
        </Form>
      </Modal>
      <Modal 
        open={forwardModalOpen}
        onCancel={() => {setForwardModalOpen(false)}}
        title="Forward to"
        onOk={() => {forwardForm.submit()}}>
        <Form form={forwardForm} layout="vertical" onFinish={handleForwardMessage} disabled={loading}>
          <Form.Item name="threadId" label="Forward to" rules={[{required: true, message: 'Please select thread!'}]}>
            <Select placeholder="Select thread" options={mainThread?.thread?.id ? [ {value: mainThread?.thread?.id, label: 'Public chat'}, ...data.map((foo, fooIndex) => ({value: foo.id, label: foo.title}))] : 
              data.map((foo, fooIndex) => ({value: foo.id, label: foo.title}))
            }></Select>
          </Form.Item>
          <Form.Item name="message" label="Forwarding message">
            <Input.TextArea autoSize={true}></Input.TextArea>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default PrivateChat