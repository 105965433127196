import React, { useEffect, useState } from 'react'
import { Button, Modal, Menu, Form as AntForm, Tag, Dropdown, Skeleton, Tooltip, Divider, Card } from 'antd'
import { EditOutlined, PlusCircleOutlined, DeleteOutlined,ExclamationCircleOutlined, LeftOutlined } from '@ant-design/icons'
import { Form, MainTable } from 'components'
import { instance } from 'utils/axios'
import fields from './fields'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { BidSummary, Overview } from './components'
import dayjs from 'dayjs'

function Auction() {
  const [ data, setData ] = useState([])
  const [ currentPage, setCurrentPage ] = useState(0)
  const [ open, setOpen ] = useState(false)
  const [ editData, setEditData ] = useState(null)
  const [ isEdit, setIsEdit ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ modal, contextHolder ] = Modal.useModal();
  const [ activeIndex, setActiveIndex ] = useState(0)
  const [ form ] = AntForm.useForm();
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    fetchData()
  },[])

  const fetchData = () => {
    setLoading(true)
    instance({
      method: 'get',
      url: `/auctionItem/auction/${location?.state?.id}?wcode=auction-detail`
    }).then((res) => {
      setData(res.data.responseData)
    }).catch((err) => {
    }).then(() => setLoading(false))
  }

  const columns = [
    {
      title: '№',
      dataIndex: 'No',
      key: 'No',
      width: 40,
      align: 'center',
      render: (text, row, i) => (
        <span>{currentPage+i+1}</span>
      )
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      filterType: "search",
      render: (text, row) => {
        if (text) {
          return (
            <img
              className='w-[40px] h-[40px] object-cover object-center rounded-full'
              src={`${process.env.REACT_APP_UPLOAD_URL}${text}`}
              alt={`image-${row.id}`}
            />
          );
        } else {
          return (
            <div className="w-[40px] h-[40px] flex items-center justify-center">
              {/* Replace 'EmptyIcon' with the icon component you want to use for representing an empty image */}
              {/* <EmptyIcon className="text-gray-400" /> */}
              <ExclamationCircleOutlined />
            </div>
          );
        }
      }
      
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      filterType: "search",
    },
    {
      title: 'Quantity',
      dataIndex: 'qty',
      key: 'qty',
      filterType: "search",
      
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filterType: "search",
      render: (text, row) => (
        <Tag color={text === 1 ? 'success' : 'default'}>
        {text === 1 ? 'Идэвхтэй' : 'Идэвхгүй'}
        </Tag>
      )
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      filterType: "search",
      width: 500,
      render: (text, row) => (
        <div className="h-16 relative overflow-y-auto">
          <div className="py-4">
            {text}
          </div>
        </div>
      )
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      filterType: "search",
      render: (text, row) => (
        dayjs(text).format("YYYY/MM/DD")
      )
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      filterType: "search",
      render: (text, row) => (
        text && dayjs(text).format("YYYY/MM/DD")
      )
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 80,
      render: (text, row) => (
        <div className=''>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="edit" onClick={() => handleEdit(row)}>
                  <div className='flex items-center gap-2'><EditOutlined/> Edit</div>
                </Menu.Item>
                <Menu.Item key="delete" onClick={() => handleDelete(row)}>
                  <div className='flex items-center gap-2'><DeleteOutlined /> Delete</div>
                </Menu.Item>
              </Menu>
            }
          >
            <Button size='small' type='text' className='m-1'>
              ...
            </Button>
          </Dropdown>
        </div>
      )
    },
  ]
  const handleDelete = (row) => {
    modal.confirm({
      title: 'Warning!',
      content: 'You are about to delete this row of data',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: 'delete',
            url: `auctionItem/${row.id}?wcode=auction-detail`
          }).then((res) => {
            resolve()
          }).catch((err) => {
            reject()
          }).then(() => fetchData())
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

  const handleSubmit = (values) => {
    console.log(values)
    setLoading(true);
    const formData = new FormData();
    formData.append("name", values?.name);
    formData.append("image", values?.image?.file); 
    formData.append("description", values?.description);
    formData.append("qty", values?.qty);
    formData.append("status", values?.status);
    formData.append("auctionId", location?.state?.id);
    if (editData) {
      formData.append('id', editData.id);
      instance({
        method: 'put',
        url: '/auctionItem?wcode=auction-detail', 
        data: {
          ...values,
          id: editData.id,
          auctionId: location.state.id,
          image: typeof values.image == 'string' ? values.image : values.image[0].response.responseData
        }
      })
      .then((res) => {
        handleCloseModal();
        fetchData();
      })
      .catch((err) => {
      })
      .finally(() => setLoading(false));
    } else {
      instance({
        method: 'post',
        url: '/auctionItem?wcode=auction-detail',
        data: {
          ...values,
          auctionId: location.state.id,
          image: values.image && values.image[0]?.response.responseData
        }
      })
      .then((res) => {
        handleCloseModal();
        fetchData();
      })
      .catch((err) => {
      })
      .finally(() => setLoading(false));
    }
  }

  const handleAdd = () => {
    setIsEdit(true)
    setEditData(null)
    setOpen(true)
  }

  const handleEdit = (row) => {
    setEditData(row)
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
    form.resetFields()
  }
  return ( 
    <div className='p-4 grid grid-cols-12 gap-3'>
        <div className='col-span-3'>
          <Card className="bg-white" size="small">
            <div className="flex flex-col items-center gap-2 px-10">
              <div className="w-12 h-12 rounded-full flex justify-center items-center text-white bg-primary text-3xl">
                {location.state.title?.slice(0,1)}
              </div>
              <div className='text-xl text-primary text-center'>
                {location.state.title}
              </div>
              {/* <div className="text-mute">
                Tender number: {data.code}
              </div> */}
            </div>
            <Divider></Divider>
            <div className="flex flex-col px-10">
              <Button 
                type='link'
                className={activeIndex == 0 ? "py-1 hover:text-primary transition-all hover:bg-slate-200 px-3 flex items-center gap-2 !bg-primary/10" : "py-1 hover:text-primary transition-all hover:bg-slate-200 px-3 flex items-center gap-2" }
                onClick={() => {setActiveIndex(0)}}>
                Overview
              </Button>
              <Button
                type='link'
                className={activeIndex == 1 ? "py-1 hover:text-primary transition-all hover:bg-slate-200 px-3 flex items-center gap-2 !bg-primary/10" : "py-1 hover:text-primary transition-all hover:bg-slate-200 px-3 flex items-center gap-2" }
                onClick={() => {setActiveIndex(1)}}>
                Items
              </Button>
              <Button
                type='link'
                className={activeIndex == 2 ? "py-1 hover:text-primary transition-all hover:bg-slate-200 px-3 flex items-center gap-2 !bg-primary/10" : "py-1 hover:text-primary transition-all hover:bg-slate-200 px-3 flex items-center gap-2" }
                onClick={() => {setActiveIndex(2)}}>
                Bidsummary
              </Button>
            </div>
          </Card>
        </div>
        <div className="col-span-9">
            {
              activeIndex == 0 ?
              <Overview location={location}></Overview>
              :
              activeIndex == 1 ?
              <MainTable
                dataTable={data}
                columns={columns}
                setCurrentPage={setCurrentPage}
                loading={loading}
                pagination={false}
                title={"Items"}
                handleAdd={handleAdd}
              />
              :
              <BidSummary/>
            }
        </div>
      <Modal
        title={editData ? <div className='flex items-center justify-start gap-2'><EditOutlined/>Edit</div> : <div className='flex items-center justify-start gap-2'><PlusCircleOutlined/>Add</div>}
        open={open}
        destroyOnClose
        maskClosable={false}
        okText='Save'
        onOk={form.submit}
        onCancel={handleCloseModal}
        width={"80%"}
        style={{top: '20px'}}
      >
        <Form
          form={form}
          fields={fields()}
          length={data?.length}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          isEdit={isEdit}
          className='gap-4'
        />
      </Modal>
      {contextHolder}
    </div>
  )
}

export default Auction
