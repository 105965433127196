import { useState, useEffect } from 'react'
import { Form } from 'components'
import { Button, Form as AntForm, Card, Input, Select } from 'antd'
import { instance } from 'utils/axios'

const ContactDetail = ({state, data}) => {
  const [ loading, setLoading ] = useState(false)
  const [ form ] = AntForm.useForm();

  useEffect(() => {
    form.setFieldsValue({contacts: data})
  }, [data])
  
  return (
    <div className='relative'>
      <Form
        disabled={true}
        form={form}
        fields={[]}
        layout="vertical"
        onFinish={() => {}}
        className='gap-4'>  
        <AntForm.List name="contacts">  
          {(fields, {add, remove}) => (
            <>
              {
                fields.map(({name, key, ...restFields}) => (
                  <Card title={`${key+1}. Contact`} className="col-span-12 rounded-none" size="small" key={key} {...restFields}>
                    <AntForm.Item label="Address line" name={[name, "address1"]} className='col-span-12'>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="Address line 2 / Soum" name={[name, "soum"]}>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="Address line 3" name={[name, "address2"]}>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="Postcode or zipcode" name={[name, "zipcode"]}>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="Town/City/Aimag" name={[name, "town"]}>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="State/Province" name={[name, "town"]}>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="Country" name={[name, "countryId"]}>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="Phone" name={[name, "phone1"]}>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="Phone 2" name={[name, "phone2"]}>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="E-mail" name={[name, "email"]}>
                      <Input></Input>
                    </AntForm.Item>
                  </Card>
                ))
              }
            </>
          )}
        </AntForm.List>
      </Form>
      
    </div>
  )
}

export default ContactDetail