import { Steps, Form, Skeleton } from 'antd'
import { useEffect, useState } from 'react'
import { SnippetsOutlined, SolutionOutlined, LoadingOutlined, CheckCircleOutlined } from '@ant-design/icons'
import TenderInformation from './TenderInformation'
import SuppliersInformation from './SuppliersInformation'
import Summary from './Summary'
import { instance } from 'utils/axios'
import PassengersInformation from './PassengersInformation'

const TenderNew = ({editData, type, closeModal, fetchData, open}) => {
  const [ activeStep, setActiveStep ] = useState(0)
  const [ tenderData, setTenderData ] = useState(editData ? editData : {})
  const [ loading, setLoading ] = useState(false)

  useEffect(() => {
    if(editData) {
      setTenderData(editData)
    }
  }, [editData])
  
  useEffect(() => {
    if(type == 3) {
      instance({
        method: 'get',
        url: `/Supplier/find/travel?wcode=tender`,
      }).then(res => {
        setTenderData(prev => ({...prev, supplierIds: res.data.responseData.map(foo => foo.id), suppliersLookup: res.data.responseData}))
      }).catch(err => {
        
      })
    }
  }, [])
  
  return (
    <>
      <Steps
        current={activeStep}
        items={[
          {
            title: 'Tender information',
            icon: <SnippetsOutlined />,
          },
          {
            title: type == 3 ? "Passengers" : 'Suppliers',
            icon: <SolutionOutlined />,
          },
          {
            title: 'Summary',
            icon: <CheckCircleOutlined />,
          },
        ]}/>
      <div className="mt-3">
        {
          loading ? 
          <Skeleton>

          </Skeleton>
          :
          activeStep == 0 ?
          <TenderInformation open={open} editData={editData} setActiveStep={setActiveStep} setTenderData={setTenderData} type={type} closeModal={closeModal} tenderData={tenderData}/>
          :
          activeStep == 1 ?
          type == 3 ? 
          <PassengersInformation setActiveStep={setActiveStep} type={type} setTenderData={setTenderData} tenderData={tenderData}></PassengersInformation>
          :
          <SuppliersInformation setActiveStep={setActiveStep} type={type} setTenderData={setTenderData} tenderData={tenderData}/>
          :
          <Summary type={type} fetchData={fetchData} editData={editData} closeModal={closeModal} tenderData={tenderData} setActiveStep={setActiveStep}/>
        }
      </div>
      
    </>
  )
}

export default TenderNew