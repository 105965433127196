import { Button, Card, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { instance } from 'utils/axios'
import { LeftOutlined } from '@ant-design/icons'

function SupportHubDetail({}) {
  const [ data, setData ] = useState(null)
  const navigate = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    instance({
      method: 'get',
      url: `/SupportHubDetail/${id}?wcode=support-hub-detail`
    }).then(res => {
      console.log(res.data.responseData)
      if(res.data.responseData.length > 0) {
        setData(res.data.responseData[0])
      }
    }).catch(err => {

    })
  }, [])

  return (
    <div className={`p-4`}>
      <div className='container mx-auto flex flex-col gap-6'>
        <div>
          <Tooltip title="Go back">
            <Button type="link" onClick={() => navigate("/support-hub")} icon={<LeftOutlined />}>
              Go back to support hub list
            </Button>
          </Tooltip>
        </div>
        {
          data &&
          <Card size="small">
            <div dangerouslySetInnerHTML={{__html: data.content}}>

            </div>
          </Card>
        }
      </div>
    </div>
  )
}

export default SupportHubDetail
