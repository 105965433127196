import React, { useEffect, useState } from 'react'
import { Button, Modal, Dropdown, Menu, Form as AntForm, Tag } from 'antd'
import { EditOutlined, PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import { Form, MainTable } from 'components'
import { instance } from 'utils/axios'
import fields from './fields'

function DesktopAudit() {
  const [ data, setData ] = useState([])
  const [ currentPage, setCurrentPage ] = useState(0)
  const [ open, setOpen ] = useState(false)
  const [ editData, setEditData ] = useState(null)
  const [ isEdit, setIsEdit ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ modal, contextHolder ] = Modal.useModal();
  const [ form ] = AntForm.useForm();

  useEffect(() => {
    fetchData()
  },[])

  const fetchData = () => {
    setLoading(true)
    instance({
      method: 'get',
      url: '/Auction'
    }).then((res) => {
      setData(res.data.responseData)
    }).catch((err) => {
    }).then(() => setLoading(false))
  }

  const columns = [
    {
      title: '№',
      dataIndex: 'No',
      key: 'No',
      width: 40,
      align: 'center',
      render: (text, row, i) => (
        <span>{currentPage+i+1}</span>
      )
    },
    {
      title: 'title',
      dataIndex: 'title',
      key: 'title',
      filterType: "search",
     
    }, 
    {
      title: 'image',
      dataIndex: 'image',
      key: 'image',
      filterType: "search",
      render: (text, row) => (
          <img className='w-[40px] h-[40px] object-cover object-center rounded-full' src={`${process.env.REACT_APP_MAIN_API_UR}${text}`} alt={`image-${row}`}/>
      )
    },
    {
      title: 'price',
      dataIndex: 'price',
      key: 'price',
      filterType: "search",
      
    },
    {
      title: 'startDate',
      dataIndex: 'startDate',
      key: 'startDate',
      filterType: "search",
    
    },
    {
      title: 'endDate',
      dataIndex: 'endDate',
      key: 'endDate',
      filterType: "search",
    
    },
    {
      title: 'awardDate',
      dataIndex: 'awardDate',
      key: 'awardDate',
      filterType: "search",
    
    },
    {
      title: 'status',
      dataIndex: 'status',
      key: 'status',
      filterType: "search",
      render: (text, row) => (
    <Tag color={text === 1 ? 'success' : 'default'}>
        {text === 1 ? 'Идэвхтэй' : 'Идэвхгүй'}
    </Tag>
      )
    },
    
   
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 80,
      render: (text, row) => (
        <div className=''>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="edit" onClick={() => handleEdit(row)}>
                  <div className='flex items-center gap-2'><EditOutlined/> Edit</div>
                </Menu.Item>
                <Menu.Item key="delete" onClick={() => handleDelete(row)}>
                  <div className='flex items-center gap-2'><DeleteOutlined /> Delete</div>
                </Menu.Item>
              </Menu>
            }
          >
            <Button size='small' type='text' className='m-1'>
              ...
            </Button>
          </Dropdown>
        </div>
      )
    },
  ]
  const handleDelete = (id) => {
    modal.confirm({
      title: 'Анхаарна уу !',
      content: 'Энэхүү мөр бичлэгийг устгах гэж байна',
      okText: 'Устгах',
      cancelText: 'Буцах',
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: 'delete',
            url: `/Auction/${id}`
          }).then((res) => {
            resolve();
          }).catch((err) => {
            reject();
          }).then(() => fetchData());
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {
      },
    });
  }
  

  const handleSubmit = (values) => {
    setLoading(true)
    if(editData){
      instance({
        method: 'put',
        url: '/Auction',
        data: {
          ...values,
          id: editData.id
        }
      }).then((res) => {
        handleCloseModal()
        fetchData()
      }).catch((err) => {

      }).then(() => setLoading(false))
    }
    else{
      instance({
        method: 'post',
        url: '/Auction',
        data: {
          ...values
        }
      }).then((res) => {
        handleCloseModal()
        fetchData()
      }).catch((err) => {

      }).then(() => setLoading(false))
    }
  }

  const handleAdd = () => {
    setIsEdit(true)
    setEditData(null)
    setOpen(true)
  }

  const handleEdit = (row) => {
    setEditData(row)
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
    form.resetFields()
  }
  return (
      <div className='p-4'>
        <MainTable
          dataTable={data}
          columns={columns}
          setCurrentPage={setCurrentPage}
          loading={loading}
          pagination={false}
          title={"Desktop audit"}
          handleAdd={handleAdd}
        />
        <Modal
          title={editData ? <div className='flex items-center justify-start gap-2'><EditOutlined/>Edit</div> : <div className='flex items-center justify-start gap-2'><PlusCircleOutlined/>Add</div>}
          open={open}
          destroyOnClose
maskClosable={false}
          okText='Save'
          onOk={form.submit}
          onCancel={handleCloseModal}
        >
          <Form
            form={form}
            fields={fields()}
            length={data.length}
            layout="vertical"
            onFinish={handleSubmit}
            editData={editData}
            isEdit={isEdit}
            className='gap-4'
          />
        </Modal>
        {contextHolder}
      </div>
  )
}

export default DesktopAudit
