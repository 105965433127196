import { Button, Checkbox, Tag, Tooltip } from "antd"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { instance } from "utils/axios"
import ls from "utils/ls"
import { DragOutlined } from '@ant-design/icons'

const PreviewTravel = () => {
  const [ outletContext, setOutletContext ] = useState({})
  const [ selected, setSelected ] = useState([])
  const [ awarding, setAwarding ] = useState(false)
  const [ data, setData ] = useState({
    suppliers: [],
    detail: []
  })
  const { id } = useParams()

  useEffect(() => {
    fetchData()
    fetchTenderData()
  }, [])

  const fetchTenderData = () => {
    instance({
      method: 'get',
      url: `/Tender/travel/${id}?wcode=tender-detail`
    }).then(res => {
      console.log(res.data.responseData)
      setOutletContext(res.data.responseData)
    }).catch(err => {
      console.log(err)
    })
  }
  
  const fetchData = () => {
    instance({
      method: 'get',
      url: `/Tender/travel/bidsummary/${id}?wcode=tender-detail`
    }).then(res => {
      setData(res.data.responseData)
    }).catch(err => {
      console.log(err)
    })
  }

  const renderSuppliers = () => {
    let tmp = []

    data?.suppliers?.map((foo, fooIndex) => {
      tmp.push(
        <th key={`supplier-${fooIndex}`} className="border-black font-semibold border p-1 bg-slate-200">
          {foo.name}
        </th>
      )
    })
    
    return tmp
  }

  const renderOptions = () => {
    let tmp = []
    let fixedData = []

    data.suppliers.map((foo, fooIndex) => {
      foo.details.sort((a,b) => a.totalPrice - b.totalPrice).map((_detail, _detailIndex) => {
        if(!fixedData[_detailIndex]) {
          fixedData[_detailIndex] = {}
        }
        fixedData[_detailIndex][`supplier-${fooIndex}`] = _detail
      })
    })

    fixedData.map((foo, fooIndex) => {
      let suppliers = Object.keys(foo)
      tmp.push(
        <tr key={`row-${fooIndex}`}>
          {
            suppliers.map((_supplier, _supplierIndex) => {
              return (
                <td>
                  {
                    foo[_supplier] &&
                    <div>
                      <div className="flex justify-between items-center bg-[#D3E1E3] px-3 py-2">
                        <div className="flex items-center gap-3 font-medium">
                          <DragOutlined></DragOutlined>
                          Option {fooIndex+1}
                          {
                            foo[_supplier].isPreapproved == 1 &&
                              <Tag bordered={false} color="processing">
                                Booked
                              </Tag>
                          }
                        </div>
                        <div>
                          <Checkbox
                            value={selected.find(ai => ai.id == foo[_supplier].id) ? true: false}
                            onClick={(e) => setSelected(prev => {
                              console.log(e)
                              let tmp = Object.assign([], prev)
                              let _index = selected.findIndex(ai => ai.id == foo[_supplier].id)
                              if(e.target.checked) {
                                tmp.push(foo[_supplier])
                                return tmp
                              }
                              else {
                                return tmp.filter(ai => ai.id != foo[_supplier].id)
                              }
                            })}
                          />
                        </div>
                      </div>
                      <div className="bg-[#F7F9FB] px-3 py-2 text-black font-semibold">
                        Price: {foo[_supplier].totalPrice.toLocaleString("en-US")}
                      </div>
                      <div className="bg-white px-3 pb-3" dangerouslySetInnerHTML={{__html: foo[_supplier].content}}>

                      </div>
                    </div>
                  }
                </td>
              )
            })
          }
        </tr>
      )
    })

    // data?.suppliers?.map((_supplier,_supplierIndex) => {
    //   tmp.push(
    //     <td key={`suppier-bid-${_supplierIndex}`} className="border p-1">
    //       {
    //         _supplier.details.map((_detail, _detailIndex) => {
    //           return (
    //             <div 
    //               key={`supplier-${_supplierIndex}-option-${_detailIndex}`} 
    //               className={
    //                 selected.findIndex(foo => foo.id == _detail.id) != -1 ? 
    //                 "border-primary border transition-all cursor-pointer" : 
    //                 "hover:border-primary border border-transparent transition-all cursor-pointer"
    //               }
    //               onClick={() => setSelected(prev => {
    //                 let tmp = Object.assign([], prev)
    //                 let _index = selected.findIndex(foo => foo.id == _detail.id)
    //                 if(_index == -1) {
    //                   tmp.push(_detail)
    //                   return tmp
    //                 }
    //                 else {
    //                   return tmp.filter(foo => foo.id != _detail.id)
    //                 }
    //               })}>
    //               <div className="bg-slate-200 flex justify-between p-2">
    //                 <div>
    //                   Option {_detailIndex+1}
    //                 </div>
    //                 <div className="flex gap-2">
    //                   {
    //                     _detail.isPreapproved == 1 &&
    //                     <Tag bordered={false} color="processing">
    //                       Booked
    //                     </Tag>
    //                   }
    //                   <div className="font-semibold">
    //                     Price: {_detail.totalPrice.toLocaleString("en-US")}₮
    //                   </div>
    //                 </div>
    //               </div>
    //               <div dangerouslySetInnerHTML={{__html: _detail.content}}>

    //               </div>
    //             </div>
    //           )
    //         })
    //       }
    //     </td>
    //   )
    // })

    return tmp
  }

  const handleAward = () => {
    setAwarding(true)
    instance({
      method: 'post',
      url: `/Tender/travel/award?wcode=tender-detail`,
      data: {
        tenderId: selected[0].tenderId,
        tenderTravelDetailId: selected[0].id
      }
    }).then(res => {
      setSelected([])
      fetchData()
      fetchTenderData()
    }).catch(err => {
      console.log(err)
    }).then(() => {
      setAwarding(false)
    })
  }
  
  const handleBook = () => {
    setAwarding(true)
    instance({
      method: 'post',
      url: `/Tender/travel/preapprove?wcode=tender-detail`,
      data: {
        tenderId: selected[0].tenderId,
        tenderTravelDetailIds: selected.map(foo => foo.id)
      }
    }).then(res => {
      setSelected([])
      fetchData()
      fetchTenderData()
    }).catch(err => {
      console.log(err)
    }).then(() => {
      setAwarding(false)
    })
  }
  
  return (
    <div className="bg-[#F0F1F3]">
    <div className="flex gap-3 py-2 px-4">
      <Tooltip title={outletContext.status >= 4 ? 'Tender is already over' : outletContext.status < 3 ? "Tender is not closed" : selected.length == 0 ? "Please select option first" : 'Book the selected option'}>
        <Button type="primary" disabled={selected.length == 0} onClick={handleBook} loading={awarding}>Book</Button>
      </Tooltip>
      <Tooltip title={(outletContext.status >= 4 && outletContext.status != 10) ? 'Tender is already over' : outletContext.status < 3 ? "Tender is not closed" : selected.length == 0 ? "Please select option first" : selected.length > 1 ? "Multiple options has been selected" : 'Award the selected option'}>
        <Button type="primary" disabled={selected.length != 1} onClick={handleAward} loading={awarding}>Award</Button>
      </Tooltip>
    </div>
    <table className="travel-preview-table">
      <colgroup>

      </colgroup>
      <thead>
        <tr>
          {
            renderSuppliers()
          }
        </tr>
      </thead>
      <tbody>
        {
          renderOptions()
        }
      </tbody>
    </table>
    </div>
  )
}

export default PreviewTravel