import { Button, Form, Table, Upload } from 'antd'
import dayjs from 'dayjs';
import moment from 'moment-timezone'
import { useEffect, useState } from 'react';
import { instance } from 'utils/axios';
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')

dayjs.extend(utc)
dayjs.extend(timezone)

const transportMode = [{label: 'Road', value: 'road'},{label: 'Rail', value: 'rail'},{label: 'Air', value: 'air'},{label: 'Maritime', value: 'maritime'},{label: 'Pipeline', value: 'pipeline'},{label: 'Sea', value: 'Sea'},]
const transportType = [
  {
    label: "AIR",
    value: "AIR"
  }, 
  {
    label: "LCL",
    value: "LCL"
  }, 
  {
    label: "LTL",
    value: "LTL"
  }, 
  {
    label: "FTL",
    value: "FTL"
  }, 
  {
    label: "20' FCL",
    value: "20' FCL"
  }, 
  {
    label: "40' FCL",
    value: "40' FCL"
  }, 
  {
    label: "40H FCL",
    value: "40H FCL"
  }, 
  {
    label: "20' OT",
    value: "20' OT"
  }, 
  {
    label: "40' OT",
    value: "40' OT"
  }, 
  {
    label: "20' FR",
    value: "20' FR"
  }, 
  {
    label: "40' FR",
    value: "40' FR"
  }, 
  {
    label: "20' RF",
    value: "20' RF"
  }, 
  {
    label: "40' RF",
    value: "40' RF"
  }
]

const Summary = ({ type, setActiveStep, tenderData, editData, fetchData, closeModal}) => {
  const [ loading, setLoading ] = useState(false)
  const [ systemDocuments, setSystemDocuments ] = useState([])
  const form = Form.useFormInstance();

  useEffect(() => {
    instance({
      method: 'get',
      url: `/SystemDocument?wcode=tender`
    }).then(res => {
      setSystemDocuments(res.data.responseData)
    })
  },[])
  
  const handleSubmit = (_status) => {
    setLoading(true)
    if(editData && editData.isReopen == 1 && !editData.fromEOI){
      setLoading(false)
      instance({
        method: 'post',
        url: `/Tender/${type == 1 ? 'eoi': type == 2 ? 'rfq' : type == 3 ? 'travel' : 'freight'}?wcode=tender`,
        data: {
          ...tenderData,
          startDate: dayjs(tenderData.startDate).tz("Asia/Ulaanbaatar").format("YYYY-MM-DDTHH:mm:00"),
          endDate: dayjs(tenderData.endDate).tz("Asia/Ulaanbaatar").format("YYYY-MM-DDTHH:mm:00"),
          attachments: tenderData.attachments?.fileList ? tenderData.attachments?.fileList.map(foo => ({path: foo.response.responseData, name: foo.name})) : tenderData.attachments?.map(foo => ({path: foo.fileName, name: foo.name})),
          tenderTypeId: type,
          productTypeIds: tenderData.productTypeIds ? tenderData.productTypeIds.map(foo => typeof foo == 'object' ? ({id: foo.id, status: 0}) : ({id: foo, state: 0})) : [],
          details: (type == 2 || type == 4) ? 
          tenderData.details ? tenderData.details.map(foo => ({
            ...foo,
            manufacturers: foo.manufacturers ? foo.manufacturers : []
          })) : [] : 
          type == 1 ?
          [...(tenderData.details ?? []), ...tenderData.required.map(foo => ({...foo, isRequired: 1}))].map((foo) => ({id: 0, state: 0, tenderId: 0, description: foo.description, systemDocumentId: foo.systemDocumentId, name: foo.name})) 
          :
          tenderData.details ? 
          tenderData.details.map((foo) => ({id: 0, state: 0, tenderId: 0, description: foo.description, systemDocumentId: foo.systemDocumentId})) 
          : [],
          endUserIds: tenderData.endUserIds ? tenderData.endUserIds.filter(foo => typeof foo != "string").map(foo => typeof foo == 'object' ? ({id: foo.value, state: 0}) : ({id: foo, state: 0})) : [],
          endUserIds: tenderData.endUserIds ? tenderData.endUserIds.filter(foo => typeof foo == "string") : [],
          supplierIds: tenderData.supplierIds &&  tenderData.supplierIds.length > 0 ? tenderData.supplierIds.map((foo) => ({id: foo, state: 1})) : tenderData.suppliers.map((foo) => ({...foo, state: 0})),
          status: _status,
          reminderDate: tenderData.reminderDate ? 1 : 0,
          allowAlternative: tenderData.allowAlternative ? 1 : 0
        }
      }).then((res) => {
        closeModal()
        fetchData()
      }).catch((err) => {

      }).then(() => setLoading(false))
    }
    else if (editData && !editData.fromEOI) {
      instance({
        method: 'put',
        url: `/Tender/${type == 1 ? 'eoi': type == 2 ? 'rfq' : type == 3 ? 'travel' : 'freight'}?wcode=tender`,
        data: {
          ...tenderData,
          startDate: dayjs(tenderData.startDate).tz("Asia/Ulaanbaatar").format("YYYY-MM-DDTHH:mm:00"),
          endDate: dayjs(tenderData.endDate).tz("Asia/Ulaanbaatar").format("YYYY-MM-DDTHH:mm:00"),
          id: editData.id,
          details: (type == 2 || type == 4) ? 
          tenderData.details ? tenderData.details.map(foo => ({
            ...foo,
            manufacturers: foo.manufacturers ? foo.manufacturers : []
          })) : [] : 
          type == 1 ?
          [...(tenderData.details ?? []), ...tenderData.required.map(foo => ({...foo, isRequired: 1}))].map((foo) => ({id: 0, state: 0, tenderId: 0, description: foo.description, systemDocumentId: foo.systemDocumentId, name: foo.name})) 
          :
          tenderData.details ? 
          tenderData.details.map((foo) => ({id: 0, state: 0, tenderId: 0, description: foo.description, systemDocumentId: foo.systemDocumentId})) 
          : [],
          productTypeIds: tenderData.productTypeIds ? tenderData.productTypeIds.map(foo => typeof foo == 'object' ? ({id: foo.id, status: 0}) : ({id: foo, state: 0})) : [],
          endUserIds: tenderData.endUserIds ? tenderData.endUserIds.map(foo => typeof foo == 'object' ? ({id: foo.value, state: 0}) : ({id: foo, state: 0})) : [],
          supplierIds: tenderData.supplierIds &&  tenderData.supplierIds.length > 0 ? tenderData.supplierIds.map((foo) => ({id: foo, state: 1})) : tenderData.suppliers.map((foo) => ({...foo, state: 0})),
          status: _status,
          reminderDate: tenderData.reminderDate ? 1 : 0,
          allowAlternative: tenderData.allowAlternative ? 1 : 0,
          attachments: tenderData.attachments?.map((foo) => {
            if(foo.path) {
              return {
                path: foo.response.responseData, 
                name: foo.name
              }
            }
            else {
              return {
                path: foo.fileName,
                name: foo.name
              }
            }
          })
        }
      }).then((res) => {
        closeModal()
        fetchData()
      }).catch((err) => {

      }).then(() => setLoading(false))
    }
    else{
      setLoading(false)
      instance({
        method: 'post',
        url: `/Tender/${type == 1 ? 'eoi': type == 2 ? 'rfq' : type == 3 ? 'travel' : 'freight'}?wcode=tender`,
        data: {
          ...tenderData,
          startDate: dayjs(tenderData.startDate).tz("Asia/Ulaanbaatar").format("YYYY-MM-DDTHH:mm:00"),
          endDate: dayjs(tenderData.endDate).tz("Asia/Ulaanbaatar").format("YYYY-MM-DDTHH:mm:00"),
          attachments: tenderData.attachments?.map(foo => ({path: foo.response.responseData, name: foo.name})),
          tenderTypeId: type,
          productTypeIds: tenderData.productTypeIds ? tenderData.productTypeIds.map(foo => ({id: foo, state: 0})) : [],
          details: (type == 2 || type == 4) ? 
          tenderData.details ? tenderData.details.map(foo => ({
            ...foo,
            manufacturers: foo.manufacturers ? foo.manufacturers : []
          })) : [] : 
          type == 1 ?
          [...(tenderData.details ?? []), ...tenderData.required.map(foo => ({...foo, isRequired: 1}))].map((foo) => ({id: 0, state: 0, tenderId: 0, description: foo.description, systemDocumentId: foo.systemDocumentId, name: foo.name})) 
          :
          tenderData.details ? 
          tenderData.details.map((foo) => ({id: 0, state: 0, tenderId: 0, description: foo.description, systemDocumentId: foo.systemDocumentId})) 
          : [],
          endUserIds: tenderData.endUserIds ? tenderData.endUserIds.filter(foo => typeof foo != 'string').map(foo => typeof foo == "object" ? ({id: foo.id, state: foo.state}) : ({id: foo, state: 1})) : [],
          endUserEmails: tenderData.endUserIds ? tenderData.endUserIds.filter(foo => typeof foo == 'string') : [],
          supplierIds: tenderData.supplierIds.map((foo) => ({id: foo, state: 1})),
          status: _status,
          reminderDate: tenderData.reminderDate ? 1 : 0,
          allowAlternative: tenderData.allowAlternative ? 1 : 0
        }
      }).then((res) => {
        closeModal()
        fetchData()
      }).catch((err) => {

      }).then(() => setLoading(false))
    }
  }

  const combineSuppliers = () => {
    let tmp = []

    tmp = [...tenderData.suppliersLookup.filter(foo => tenderData.supplierIds.includes(foo.id))]
    if(tenderData.emails) {
      tenderData.emails.map(foo => {
        tmp.push({
          name: foo+' /Invited/'
        })
      })
    }

    return tmp 
  }


  const renderTenderDetail = () => {
    let tmp = []
    if(type == 1) {
      tmp.push(
        <table className="w-full col-span-12">
          <colgroup>
            <col className="w-[50px]"></col>
            <col className="w-[350px]"></col>
            <col className="w-[150px]"></col>
          </colgroup>
          <thead>
            <tr className="bg-slate-200">
              <th className="py-2 px-1">#</th>
              <th className="py-2 px-1">Document name</th>
              <th className="py-2 px-1">Type</th>
              <th className="py-2 px-1">Description</th>
            </tr>
          </thead>
          <tbody>
            {
              tenderData.required.map((foo, fooIndex) => {
                return (
                  <tr className="border border-t border-b" key={`eoi-tender-detail-${fooIndex}`}>
                    <td className="py-2 px-1 border-r">{fooIndex+1}</td>
                    <td className="py-2 px-1 border-r">{systemDocuments.find(ai => ai.id == foo.systemDocumentId)?.name}</td>
                    <td className="py-2 px-1 border-r">{foo.systemDocumentId ? 'System' : 'Additional'}</td>
                    <td className="py-2 px-1 border-r">{foo.description}</td>
                  </tr>
                )
              })
            }
            {
              tenderData.details?.map((foo, fooIndex) => {
                return (
                  <tr className="border border-t border-b" key={`eoi-tender-detail-${fooIndex}`}>
                    <td className="py-2 px-1 border-r">{fooIndex+1+tenderData.required.length}</td>
                    <td className="py-2 px-1 border-r">{systemDocuments.find(ai => ai.id == foo.systemDocumentId)?.name}</td>
                    <td className="py-2 px-1 border-r"></td>
                    <td className="py-2 px-1 border-r">{foo.description}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      )
    }    
    if(type == 2) {
      tmp.push(
        <div className="h-[500px] overflow-auto col-span-12 ">
          <table className="w-full col-span-12">
            <thead>
              <tr className="bg-slate-200">
                <th className="py-2 px-1">#</th>
                <th className="py-2 px-1">PRNumber</th>
                <th className="py-2 px-1">OT Material Number</th>
                <th className="py-2 px-1">Material Description</th>
                <th className="py-2 px-1">Quantity</th>
                <th className="py-2 px-1">UOM</th>
                <th className="py-2 px-1">Manufacturer</th>
                <th className="py-2 px-1">Manufacturer Part Number</th>
              </tr>
            </thead>
            <tbody>
              {
                tenderData.details?.map((foo, fooIndex) => {
                  return (
                    <tr className="border border-t border-b">
                      <td className="py-2 px-1 border-r">{fooIndex+1}</td>
                      <td className="py-2 px-1 border-r">{foo.prNumber}</td>
                      <td className="py-2 px-1 border-r">{foo.materialNumber}</td>
                      <td className="py-2 px-1 border-r">{foo.description}</td>
                      <td className="py-2 px-1 border-r">{foo.qty}</td>
                      <td className="py-2 px-1 border-r">{foo.uom}</td>
                      <td className="py-2 px-1 border-r">{foo.manufacturers?.map((ai, aiIndex) => `${ai}${aiIndex+1 != foo.manufacturers.length ? ", " : ""}`)}</td>
                      <td className="py-2 px-1">{foo.partNumber}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      )
    }
    if(type == 4 ) {
      tmp.push(
        <table className="w-full col-span-12">
          <thead>
            <tr className="bg-slate-200">
              <th className="py-2 px-1">#</th>
              <th className="py-2 px-1">SRN Number</th>
              <th className="py-2 px-1">Total Gross Weight</th>
              <th className="py-2 px-1">Freight Description</th>
              <th className="py-2 px-1">Transport Mode</th>
              <th className="py-2 px-1">Transport Type</th>
              <th className="py-2 px-1">Package Quantity</th>
              <th className="py-2 px-1">Total CBM</th>
              <th className="py-2 px-1">Place Of Loading</th>
              <th className="py-2 px-1">Place Of Delivery</th>
            </tr>
          </thead>
          <tbody>
            {
              tenderData.details.map((foo, fooIndex) => {
                return (
                  <tr className="border border-t border-b">
                    <td className="py-2 px-1 border-r">{fooIndex+1}</td>
                    <td className="py-2 px-1 border-r">{foo.srnNumber}</td>
                    <td className="py-2 px-1 border-r">{foo.grossWeight}</td>
                    <td className="py-2 px-1 border-r">{foo.description}</td>
                    <td className="py-2 px-1 border-r">{transportMode.find(tmp => tmp.value == foo.transportMode)?.label}</td>
                    <td className="py-2 px-1 border-r">{foo.transportType}</td>
                    <td className="py-2 px-1 border-r">{foo.packageQty}</td>
                    <td className="py-2 px-1 border-r">{foo.cbm}</td>
                    <td className="py-2 px-1 border-r">{foo.placeLoading}</td>
                    <td className="py-2 px-1">{foo.placeDelivery}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      )
    }

    return tmp
  }
  
  return (
    <div className="grid grid-cols-12 gap-3">
      <div className="col-span-12 gap-2 grid grid-cols-12">
        <div className="col-span-12 flex">
          <label className='text-xs font-semibold w-[120px] flex items-end'>Number:</label>
          <div className="grow">{tenderData.code}</div>
        </div>
        <div className={type == 1 ? 'col-span-6 flex' : 'col-span-12 flex'}>
          <label className="text-xs font-semibold w-[120px] flex items-end">Description:</label>
          <div className="grow">{tenderData.name}</div>
        </div>
        {
          type == 1 &&
          <div className='col-span-6 flex'>
            <label className='text-xs font-semibold w-[120px] flex items-end'>Description /ENG/:</label>
            <div className="grow">{tenderData.enName}</div>
          </div>
        }
        <div className={type == 1 ? 'col-span-6  flex items-end' : 'col-span-12  flex items-end'}>
          <label className='text-xs font-semibold w-[120px] flex items-end'>Short content:</label>
          <div className="grow">{tenderData.description}</div>
        </div>
        {
          type == 1 &&
          <div className="col-span-6 flex">
            <label className='text-xs font-semibold w-[130px] flex items-end'>Short content /ENG/:</label>
            <div className="grow">{tenderData.enDescription}</div>
          </div>
        }
        <div className="col-span-12 flex justify-end">
          <div className='text-xs font-semibold w-[120px] flex items-end'>Publish date:</div>
          <div className="grow">{dayjs(tenderData.startDate).format("YYYY/MM/DD HH:mm:ss")}</div>
        </div>
        <div className="col-span-12 flex justify-end">
          <div className='text-xs font-semibold w-[120px] flex items-end'>Close date:</div>
          <div className="grow">{dayjs(tenderData.endDate).format("YYYY/MM/DD HH:mm:ss")}</div>
        </div>
        <div className="col-span-12 flex">
          <label className='text-xs font-semibold w-[120px] flex items-end'>Reminder:</label>
          <div className="grow">{tenderData.reminderDate ? 'Yes' : 'No'}</div>
        </div>
        <div className="col-span-12 flex">
          <label className='text-xs font-semibold w-[120px] flex items-start pt-2'>Attachment file:</label>
          <div className="grow">
            <Upload disabled={true} defaultFileList={tenderData.attachments} fileList={tenderData.attachments}>

            </Upload>
          {/* {
            tenderData.attachments?.fileList?.map((foo, fooIndex) => {
              return (
                
              )
          })} */}
          </div>
        </div>
        {
          type == 2 &&
          <>
          <div className="col-span-12">
            <label className='text-xs font-semibold'>Product codes:</label>
            <div className="flex gap-2">
              {tenderData.productsLookup.map((foo, fooIndex) => {
                return <span className="bg-slate-200 px-2 rounded" key={`product-${fooIndex}`}>{foo.name} /{foo.code}/</span>
              })}
            </div>
          </div>
          <div className="col-span-12">
            <label className='text-xs font-semibold'>End users:</label>
            <div className="flex gap-2">
              {tenderData.endUsersLookup.map((foo, fooIndex) => {
                return <span className="bg-slate-200 px-2 rounded" key={`product-${fooIndex}`}>{foo.firstName} {foo.lastName}</span>
              })}
            </div>
          </div>
          </>
        }
        <div className={type == 1 ? 'col-span-6' : 'col-span-12'}>
          <label className='text-xs font-semibold'>Body:</label>
          <div className="max-h-[500px] overflow-auto shadow-inner p-2" dangerouslySetInnerHTML={{__html: tenderData.content}}></div>
        </div>
        {
          type == 1 &&
          <div className='col-span-6'>
            <label className='text-xs font-semibold'>Body /ENG/:</label>
            <div className="h-[500px] overflow-auto shadow-inner p-2" dangerouslySetInnerHTML={{__html: tenderData.enContent}}></div>
          </div>
        }
        <div className="col-span-12 grid grid-cols-12 pt-3">
          {
            type == 2 && tenderData.rfqType == 1 &&
            <div className="font-bold">
              Material list
            </div>
          }
          {renderTenderDetail()}
        </div>
      </div>
      <div className="col-span-12">
        <div className="font-bold">
          {
            type == 3 || type == 2 &&
            'Suppliers'
          }
        </div>
        <Table
          scroll={{
            y: 400
          }}
          pagination={false}
          size="small"
          columns={[
            {
              dataIndex: 'id',
              title: 'ID',
              width: 80
            },
            {
              dataIndex: 'name',
              title: 'Supplier name',
            },
            {
              dataIndex: 'supplierTierName',
              title: 'Tier type',
            },
            {
              dataIndex: 'preQualificationStatus',
              title: 'Qualification status',
            },
            {
              dataIndex: 'vendorNumber',
              title: 'Vendor #',
            },
          ]}
          dataSource={combineSuppliers()}
        />
        {
          type == 3 &&
          <div className="mt-4">
            Passengers
            <Table
              scroll={{
                y: 400
              }}
              pagination={false}
              size="small"
              columns={[
                {
                  title: '#',
                  width: 50,
                  render: (cell, row, index) => index+1
                },
                {
                  title: 'Request ID',
                  dataIndex: 'requestId',
                },
                {
                  title: 'Firstname',
                  dataIndex: 'firstName',
                },
                {
                  title: 'Lastname',
                  dataIndex: 'lastName',
                },
                {
                  title: 'Gender',
                  dataIndex: 'gender',
                  render: (cell) => cell == 'male' ? 'Male' : 'Female'
                },
                {
                  title: 'Dob',
                  dataIndex: 'dob',
                  render: (cell) => {
                    return dayjs(cell).format('YYYY-MM-DD')
                  }
                },
              ]}
              dataSource={tenderData.passengers}
            />
          </div>
        }
      </div>
      <div className="flex justify-end gap-3 col-span-12">
        <Button onClick={() => setActiveStep(1)}>Previous</Button>
        <Button type="primary" onClick={() => handleSubmit(-1)}>Save as draft</Button>
        {/* {
          tenderData.bidType && tenderData.bidType == 2 &&
          <Button type="primary" onClick={() => handleSubmit(3)}>Send for approval</Button>
        } */}
        <Button type="primary" onClick={() => handleSubmit(0)}>Publish</Button>
      </div>
    </div>
  )
}

export default Summary