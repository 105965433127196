import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { instance } from "utils/axios"
import { Line } from '@ant-design/plots';
import { Card, Tabs } from "antd";
import dayjs from "dayjs";

const fixData = (_data) => {
  let tmp = []

  _data.map(foo => {
    tmp.push({
      value: foo.supplierCount,
      date: foo.logDate,
      category: 'Total suppliers'
    })
  })

  _data.map(foo => {
    tmp.push({
      value: foo.responseCount,
      date: foo.logDate,
      category: 'Suppliers response'
    })
  })

  _data.map(foo => {
    tmp.push({
      value: foo.awardCount,
      date: foo.logDate,
      category: 'Awarded suppliers'
    })
  })

  return tmp
}

const Log = () => {
  const [ data, setData ] = useState([])
  const [ supplierLogs, setSupplierLogs ] = useState([])
  const [ tenderLogs, setTenderLogs ] = useState([])
  const { id } = useParams()

  useEffect(() => {
    instance({
      method: 'get',
      url: `/TenderLog/report/tender/${id}?wcode=tender-detail`
    }).then(res => {
      setData(res.data.responseData.suppliers ?? [])
    })
    instance({
      method: 'get',
      url: `/TenderLog/tender/${id}?wcode=tender-detail`
    }).then(res => {
      setTenderLogs(res.data.responseData.log)
      setSupplierLogs(res.data.responseData.suppliers)
    })
  }, [])

  const config = {
    data: fixData(data),
    xField: (d) => new Date(d.date),
    yField: 'value',
    sizeField: 'value',
    shapeField: 'trail',
    legend: { size: false },
    colorField: 'category',
  };

  const renderSuppliersLogs = () => {
    let tmp = []

    supplierLogs.map((foo, fooIndex) => {
      if(foo.awardStatus == 1 || foo.emailStatus == 1 || foo.responseStatus == 1) {
        tmp.push(
          <div key={`supplierLog-${fooIndex}`} className="flex gap-3">
            <span className="text-mute font-medium">{dayjs(foo.logData).format("YYYY-MM-DD HH:mm")}</span>
            <span className="font-bold">{foo.supplierName}</span>
            <span className="text-green-500 font-medium">
              {
                foo.awardStatus == 1 ? 
                "awarded"
                :
                foo.emailStatus == 1 ?
                "email sent"
                :
                foo.responseStatus == 1 ?
                "response sent"
                :
                ""
              }
            </span>
          </div>
        )
      }
    })
    
    return tmp
  }
  
  return (
    <Card size="small">
      <Line {...config} />
      <Tabs
        items={[
          {
            key: 0,
            label: 'Tender',
            children: (
                <div className="h-[400px] overflow-auto p-4 bg-gray-100 rounded">

                </div>
              )
          },
          {
            key: 1,
            label: 'Suppliers',
            children: (
                <div className="h-[400px] overflow-auto p-4 bg-gray-100 rounded">
                  {renderSuppliersLogs()}
                </div>
              )
          },
        ]}>

      </Tabs>
    </Card>
  )
}

export default Log