import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { EditOutlined, PlusCircleOutlined, PlusOutlined, MinusCircleOutlined, MoreOutlined } from '@ant-design/icons'
import { Button, Form as AntForm, Modal, Table, Space, Input, Dropdown } from 'antd';
import { MainTable, Form } from 'components';
import { instance } from 'utils/axios';
import PrequalificationAnswers from './auditConfigAnswers';

const typeEnum = {
  1: 'Numeric',
  2: 'Text',
  3: 'Combobox',
  4: 'Radio button',
  5: 'Calendar',
  6: 'Text area',
  7: 'File upload',
  8: 'Checkbox',
  9: 'Table'
}

  
const items = ({row, handleEdit, handleDelete}) => {  
  return (
    [
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleEdit(row)
        }}>Edit</a>,
        key: 0
      },
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleDelete(row.id)
        }}>Delete</a>,
        key: 1
      },
    ]
  )
}

const QuestionTable = ({_data}) => {
  const columns = [
    {
      label: '#',
      dataIndex: 'answer',
      key: '',
      width: '50px',
      render: (cell, row, index) => <div className='text-center'>{index+1}</div>
    },
    {
      label: 'Type',
      dataIndex: 'answer',
      key: '',
      render: (cell, row) => <span>{typeEnum[cell.answerTypeId]}</span>
    },
    {
      label: 'Answer',
      dataIndex: 'option',
      key: 'option',
      render: (cell, row) => <span>{}</span>
    }
  ]
  
  return (
    <Table
       className='inner-table'
      bordered
      // dataSource={_data}
      columns={columns}
      showHeader={false}
      pagination={false}
      expandable={{
        expandedRowRender: rec => <QuestionTable _data={rec}></QuestionTable>
      }}>
      
    </Table>
  )
}

const fields = (data, editorTypes) => {
  return [
    {
      label: 'Question',
      name: 'title',
      rules: [{required: true, message: 'Question is required!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Description',
      name: 'description',
      type: 'textarea',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Question type',
      name: 'editorTypeId',
      className: 'col-span-12 mb-0',
      type: 'select',
      inputProps: {
        className: 'w-full',
        options: editorTypes.map(foo => ({label: foo.content, value: foo.id}))
      }
    },    
    {
      label: '',
      type: 'component',
      component: (
        <AntForm.Item noStyle shouldUpdate={(prev, next) => prev.editorTypeId != next.editorTypeId } className="col-span-12">
          {({getFieldValue}) => {
            if(getFieldValue("editorTypeId") == 3 || getFieldValue("editorTypeId") == 4) {
              return ( 
                <AntForm.List name="options" className='col-span-12'>
                  {(fields, {add ,remove}) => {
                    return (
                      <>
                        {fields.map(({key, name, ...restFields}) => (
                          <Space className='col-span-12 flex'>
                            <AntForm.Item {...restFields} name={[name, 'name']} className='flex-1 mb-0'>
                              <Input className='w-full'></Input>
                            </AntForm.Item>
                            <MinusCircleOutlined onClick={() => {remove(name)}} />
                          </Space>
                        ))}
                        <Button className='col-span-12' type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                          Add answer
                        </Button>
                      </>
                    )
                  }}
                </AntForm.List>
              )
            }
          }}
        </AntForm.Item>
      )
    },
    {
      label: 'Order',
      name: 'seq',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      type: 'divider'
    },
    {
      label: 'Parent question',
      name: 'parentId',
      type: 'select',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
        options: data.map((foo, fooIndex) => ({label: foo.title, value: foo.id}))
      }
    },
    {
      label: 'Option',
      name: 'optionId',
      type: 'select',
      dependentIndex: 'parentId',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
        optionsUrl: 'PreQualificationAnswer/withoption'
      }
    },
    {
      className: 'col-span-12 mb-0',
      type: 'dependent',
      dependentName: 'parentId',
      children: [
        {
          label: 'Option',
          name: 'optionId',
          className: 'col-span-12 mb-0',
          type: 'select',
          inputProps: {
            className: 'w-full',
            optionsUrl: 'PreQualificationAnswer/withoption'
          }
        }
      ],
      inputProps: {
        className: 'w-full',
      }
    },
  ]
}

const columns = ({currentPage, handleEdit, handleDelete}) => {
  return ([
    {
      title: '#',
      dataIndex: 'No',
      key: 'No',
      width: 40,
      align: 'center',
      render: (text, row, i) => (
        <span>{currentPage ? currentPage+i+1 : i+1}</span>
      )
    },
    {
      title: 'Question',
      dataIndex: 'title',
      key: 'title',
      filterType: "search",
      render: (text, row) => (
        <span to={`${row.id}`}>{text}</span>
      )
    },
    {
      title: 'Required',
      dataIndex: 'isRequired',
      key: 'isRequired',
      filterType: "search",
      width: 120,
      render: (text, row) => (
        <span>{text ? 'True' : 'False'}</span>
      )
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 80,
      render: (text, row) => (
        <Dropdown 
        trigger={['click']} 
        menu={{items: items({row, handleEdit, handleDelete}),}}>
        <a onClick={e => e.preventDefault()}>
          <Space>
            <MoreOutlined className='text-xl'/>
          </Space>
        </a>
      </Dropdown>
      )
    },
  ])
}

const AuditConfigGroupDetail = ({}) => {
  const [ data, setData ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const [ modal, contextHolder ] = Modal.useModal();
  const [ form ] = AntForm.useForm();
  const [ open, setOpen ] = useState(false)
  const [ editData, setEditData ] = useState(null)
  const [ isEdit, setIsEdit ] = useState(false)
  const [ editorType, setEditorType ] = useState([])
  const location = useLocation()

  const params = useParams()
  console.log(params)
  useEffect(() => {
    
    fetchData()
    fetchEditorType()
  }, [])

  const fetchEditorType = () => {
    instance({
      method: 'get',
      url: `EditorType`
    }).then(res => {
      // console.log(res.data);
      setEditorType(res.data.responseData)
    }).catch(err => {
      console.log(err);
    })
  }

  const fetchData = () => {
    instance({
      method: 'get',
      url: `/AuditQuestion/group/${params.groupid}?wcode=audit-config-detail`
    }).then(res => {
      setData(res.data.responseData)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const handleAdd = () => {
    setEditData(null)
    setOpen(true)
  }

  const handleEdit = (_row) => {
    setEditData(_row)
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
    form.resetFields()
  }

  const handleSubmit = (values) => {
    setLoading(true)
    if(editData) {
      instance({
        method: 'put',
        url: `/AuditQuestion?wcode=audit-config-detail`,
        data: {
          ...values,
          optionId: parseInt(values.optionId) ? parseInt(values.optionId): null,            
          auditGroupId: params.groupid,
          auditId: params.id,
          id: editData.id
        }
      }).then(res => {
        setOpen(false)
        fetchData()
      }).catch(err => {
        
      }).then(() => {
        setLoading(false)
      })
    }
    else {
      instance({
        method: 'post',
        url: `/AuditQuestion?wcode=audit-config-detail`,
        data: {
          ...values,
          auditGroupId: params.groupid,
          auditId: params.id,
          optionId: values.optionId ? values.optionId : null
        }
      }).then(res => {
        setOpen(false)
        fetchData()
      }).catch(err => {
        
      }).then(() => {
        setLoading(false)
      })
    }    
  }

  const handleDelete = (id) => {
    modal.confirm({
      title: 'Warning!',
      content: 'You are about to delete this row of data',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: 'delete',
            url: `/AuditQuestion/${id}?wcode=audit-config-detail`
          }).then((res) => {
            resolve()
          }).catch((err) => {
            reject()
          }).then(() => fetchData())
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

  return (
    <div className="p-4">
      <MainTable
        goback
        dataTable={data}
        columns={columns({handleEdit, handleDelete})}
        loading={loading}
        handleAdd={handleAdd}
        title={location.state.name}
        expandable={{
          expandedRowRender: rec => <PrequalificationAnswers record={rec}></PrequalificationAnswers>
        }}>

      </MainTable>
      {/* <MainTable
        goback
        dataTable={data}
        columns={columns()}
        loading={loading}
        handleAdd={handleAdd}
        title={location.state.name}
        expandable={{
          expandedRowRender: rec => <AnswerTable _data={rec}></AnswerTable>
        }}>

      </MainTable> */}
      <Modal
        title={editData ? <div className='flex items-center justify-start gap-2'><EditOutlined/>Edit</div> : <div className='flex items-center justify-start gap-2'><PlusCircleOutlined/>Add</div>}
        open={open}
        destroyOnClose
maskClosable={false}
        okText='Save'
        onOk={form.submit}
        onCancel={handleCloseModal}
      >
        <Form
          initialValue={{isUseCalculation: true}}
          form={form}
          fields={fields(data, editorType, form)}
          length={data.length}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          isEdit={isEdit}
          className='gap-4'
        />
      </Modal>
      {contextHolder}
    </div>
  );
}

export default AuditConfigGroupDetail;
