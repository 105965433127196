import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Button, Modal, Form as AntForm, Input, Space, Dropdown, List, InputNumber, Tag, Skeleton, Card, Tooltip, DatePicker, Upload, Select, TimePicker } from 'antd'
import { MoreOutlined, InboxOutlined } from '@ant-design/icons'
import { instance } from 'utils/axios'
import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component';
import { TenderNew } from './components'
import axios from 'axios'
import dayjs from 'dayjs'
import { AuthContext } from 'contexts'

const { Search } = Input
const { Dragger } = Upload

const statusEnum = {
  '-1': {
    title: 'Draft',
    color: '#F09D51'
  },
  0: {
    title: 'Published',
    color: '#2DCCFF'
  },
  1: {
    title: 'Open',
    color: '#e87b00'
  },
  2: {
    title: 'Reopened',
    color: '#e87b00'
  },
  3: {
    title: 'Closed',
    color: '#a7a7a7'
  },
  4: {
    title: 'Awarded',
    color: '#04aa51'
  },
  5: {
    title: 'Finished',
    color: '#03cb09'
  },
  6: {
    title: 'Postponed',
    color: '#FCE83A'
  },
  7: {
    title: 'Declined',
    color: '#d03f32'
  },
  8: {
    title: 'In evaluation',
    color: '#04cfcf'
  },
  9: {
    title: 'Approved',
    color: '#56F000'
  },
  10: {
    title: 'Pending approval',
    travel: 'Booked',
    color: '#e2bb5e'
  },
  11: {
    title: 'Canceled',
    color: '#d03f32'
  },
}


const items = ({type, row, user, handleEdit, handleCancel, handlePublish, handleClose, handleReopen, handleExtend, handlePublishPre, handleEndUser, handleAssign}) => {
  return (
    [
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleEdit(row)
        }}>Edit</a>,
        key: -1
      },
      // {
      //   label: <a onClick={(e) => {
      //     e.preventDefault()
      //   }}>Reopen</a>,
      //   key: 1
      // },
      (row?.status == 2 || row?.status == 1 ) && {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleExtend(row)
        }}>Extend</a>,
        key: 11
      },
      row?.status > 2 && {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleReopen(row)
        }}>Reopen</a>,
        key: 2
      },
      row?.status == -1 && {
        label: <a onClick={(e) => {
          e.preventDefault()
          handlePublishPre(row)
        }}>Publish</a>,
        key: 0
      },
      (row?.status == 1 || row?.status == 0 || row?.status == 2) && {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleClose(row.id)
        }}>Close</a>,
        key: 3
      },
      (type == 2) &&
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleEndUser(row)
        }}>Add end user</a>,
        key: 9998
      },
      (type == 2 && user.permissions.some(foo => foo.permissionId == 1002)) &&
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleAssign(row)
        }}>Assign to buyer</a>,
        key: 9999
      },
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleCancel(row)
        }}>Cancel</a>,
        key: 10000
      }
    ]
  )
}

const fixFiles = (_files) => {
  let tmp = []

  if(typeof _files == 'object') {
    _files.map((foo, fooindex) => {
      tmp.push({
        uid: fooindex,
        name: foo.split("/").pop(),
        status: 'done',
        url: `${process.env.REACT_APP_UPLOAD_URL}/${foo}`,
      })
    })
  }
  else if(typeof _files == "string") {
    tmp.push({
      uid: '-1',
      name: _files.split("/").pop(),
      status: 'done',
      url: `${process.env.REACT_APP_UPLOAD_URL}/${_files}`,
    })
  }
  
  return tmp 
}

function TenderTypeContainer({ type, modal, suppliers, filter }) {
  const [ data, setData ] = useState({
    total: 0,
    data: []
  })
  const [ currentPage, setCurrentPage ] = useState(1)
  const [ pageSize, setPageSize ] = useState(20)
  const [ hasMore, setHasMore ] = useState(true)
  const [ open, setOpen ] = useState(false)
  const [ editData, setEditData ] = useState(null)
  const [ isEdit, setIsEdit ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ templateList, setTemplateList ] = useState([])
  const [ fetching, setFetching ] = useState(false)
  const [ searchValue, setSearchValue ] = useState('')
  const [ extendModalOpen, setExtendModalOpen ] = useState(false)
  const [ cancelModalOpen, setCancelModalOpen ] = useState(false)
  const [ extending, setExtending ] = useState(false)
  const [ publishModalOpen, setPublishModalOpen ]  = useState(false)
  const [ assignModalOpen, setAssignModalOpen ] = useState(false)
  const [ endUserModalOpen, setEndUserModalOpen ] = useState(false)
  const [ users, setUsers ] = useState([])
  const [ form ] = AntForm.useForm();
  const [ extendForm ] = AntForm.useForm()
  const [ cancelForm ] = AntForm.useForm()
  const [ publishForm ] = AntForm.useForm()
  const [ assignForm ] = AntForm.useForm()
  const [ endUserForm ] = AntForm.useForm()

  const [ eoiImportModalOpen, setEOIImportModalOpen ] = useState(false)
  const [ eoiImportLoading, setEOIImportLoading ] = useState(false)
  const [ eoiImportForm ] = AntForm.useForm()

  const [ rfqCopyModalOpen, setRfqCopyModalOpen ] = useState(false)
  const [ rfqCopyLoading, setRfqCopyLoading ] = useState(false)
  const [ rfqCopyForm ] = AntForm.useForm()

  const [ closeModalOpen, setCloseModalOpen ] = useState(false)
  const [ closeForm ] = AntForm.useForm()
  
  const authContext = useContext(AuthContext)
  const navigate = useNavigate()
  let source = axios.CancelToken.source();
  
  // useEffect(() => {
  //   fetchUsers()
  // }, [])
  
  useEffect(() => {
    setData({
      total: 0,
      data: []
    })
    setCurrentPage(1)
    fetchData()
    setHasMore(true)
    return () => {
      source.cancel()
    }
  },[type, filter])

  useEffect(() => {
    if(endUserModalOpen) {
      instance({
        method: 'get',
        url: `/User/permission/7`
      }).then(res => {
        setUsers(res.data.responseData)
      }).catch(err => {
        console.log(err)
      })
    }
    if(assignModalOpen) {
      instance({
        method: 'get',
        url: `/User/permission/3`
      }).then(res => {
        setUsers(res.data.responseData)
      }).catch(err => {
        console.log(err)
      })
    }
  }, [endUserModalOpen, assignModalOpen])

  useEffect(() => {
    fetchTemplates()
    return () => {
      source.cancel()
    }
  }, [type])

  const fetchUsers = () => {
    instance({
      method: 'get',
      url: `/User/tender?wcode=tender`,
    }).then((res) => {
      setUsers(res.data.responseData)
    }).catch((err) => {
    })
  }

  const fetchTemplates = () => {
    instance({
      method: 'get',
      url: `/TemplateEvent/groupwithtemplate/${type}?type=2&wcode=tender`,
      cancelToken: source.token,
    }).then((res) => {
      setTemplateList(res.data.responseData)
    }).catch((err) => {
    }).then(() => setLoading(false))
  }

  const fetchData = () => {
    setLoading(true)
    instance({
      method: 'get',
      url: `/Tender/${type == 1 ? 'eoi': type == 2 ? 'rfq' : type == 3 ? 'travel' : 'freight'}`,
      params: {
        wcode: 'tender',
        page: currentPage,
        pageSize: 20,
        ...filter
      },
      paramsSerializer: {
        indexes: null
      },
      cancelToken: source.token,
    }).then((res) => {
      if(res.data.responseData.total < 20) {
        setHasMore(false)
      }
      setData(res.data.responseData)
    }).catch((err) => {
    }).then(() => setLoading(false))
  }

  const fetchNext = () => {
    instance({
      method: 'get',
      url: `/Tender/${type == 1 ? 'eoi': type == 2 ? 'rfq' : type == 3 ? 'travel' : 'freight'}`,
      paramsSerializer: {
        indexes: null
      },
      params: {
        wcode: 'tender',
        page: currentPage+1,
        pageSize: pageSize,
        ...filter
      }
    }).then((res) => {
      if(res.data.responseData.data.length < 20) {
        setHasMore(false)
      }
      setCurrentPage(prev => prev+1)
      setData(prev => ({total: res.data.responseData.total, data: [...prev.data, ...res.data.responseData.data]}))
    }).catch((err) => {
    })
  }

  const handlePublish = (_data) => {
    instance({
      method: 'put',
      url: `/Tender/publish?wcode=tender`,
      data: {
        id: editData.id,
        startDate: _data.startDate,
        endDate: _data.endDate,
        status: 0
      }
    }).then(res => {
      setEditData(null)
      setPublishModalOpen(false)
    }).catch(err => {

    }).then(() => {
      fetchData()
    })
  }

  const handleCancel = (values) => {
    instance({
      method: 'put',
      url: `/Tender/cancel`,
      data: {
        id: editData.id,
        justification: values.justification,
        file: values.attachment?.file?.response?.responseData,
        status: 0
      }
    }).then((res) => {
      setCancelModalOpen(false)
      setEditData(null)
    }).catch((err) => {

    }).then(() => fetchData())
  }

  const handleAdd = () => {
    setIsEdit(true)
    setEditData(null)
    setOpen(true)
  }

  const handleExtend = (row) => {
    setEditData(row)
    setExtendModalOpen(true)
    extendForm.setFieldValue('endDate', dayjs(row.endDate))
  }

  const extendTender = (value) => {
    setExtending(true)
    instance({
      method: 'put',
      url: `/Tender/extend?wcode=tender`,
      data: {
        id: editData.id,
        endDate: value.endDate
      }
    }).then((res) => {
      setExtendModalOpen(false)
      fetchData()
    }).catch((err) => {

    }).then(() => setExtending(false))
  }

  const handleEdit = (row) => {
    setFetching(true)
    instance({
      method: 'get',
      url: `/Tender/${type == 1 ? 'eoi': type == 2 ? 'rfq' : type == 3 ? 'travel' : 'freight'}/${row.id}`,
      params: {
        wcode: 'tender'
      }
    }).then((res) => {
      setEditData({
        ...res.data.responseData,
        startDate: dayjs(res.data.responseData.startDate),
        endDate: dayjs(res.data.responseData.endDate),
        productTypeIds: res.data.responseData.services.map(foo => ({...foo, value: foo.id, state: 0})),
        details: res.data.responseData.details.map(foo => ({...foo, state: 0})),        
        endUserIds: res.data.responseData.endUsers ? res.data.responseData.endUsers.map(foo => typeof foo == 'object' ? ({value: foo.userId, state: 0}) : ({value: foo, state: 0})) : [],
      })
      setOpen(true)
    }).catch((err) => {
    }).then(() => setFetching(false))
  }

  const handleCloseModal = () => {
    setOpen(false)
    form.resetFields()
  }

  const handleReopen = (row) => {
    setFetching(true)
    instance({
      method: 'get',
      url: `/Tender/${type == 1 ? 'eoi': type == 2 ? 'rfq' : type == 3 ? 'travel' : 'freight'}/${row.id}`,
      params: {
        wcode: 'tender'
      }
    }).then((res) => {
      setEditData({
        ...res.data.responseData,
        endDate: dayjs(res.data.responseData.endDate),
        productTypeIds: res.data.responseData.services.map(foo => ({...foo, value: foo.id, state: 0})),
        details: res.data.responseData.details.map(foo => ({...foo, state: 0})),        
        endUserIds: res.data.responseData.endUsers ? res.data.responseData.endUsers.map(foo => typeof foo == 'object' ? ({value: foo.userId, state: 0}) : ({value: foo, state: 0})) : [],
        isReopen: 1,
      })
      setOpen(true)
    }).catch((err) => {
    }).then(() => setFetching(false))
  }
  
  const onSearch = () => {
    
  }

  const handleClose = (values) => {
    instance({
      method: 'put',
      url: `Tender/close?wcode=tender`,
      data: {
        id: editData.id,
        status: 0,
        justification: values.closeJustification
      }
    }).then(res => {
      setData(prev => {
        let tmp = Object.assign([], prev.data)
        let index = tmp.findIndex(foo => foo.id == res.data.responseData.id)
        tmp[index] = res.data.responseData
        return {
          total: prev.total,
          data: tmp
        }
      })
      setCloseModalOpen(false)
      setEditData(false)
      closeForm.resetFields()
    }).catch(err => {
      console.log(err)
    })
  }

  const filteredData = useMemo(() => {
    return data.data.filter(foo => foo.name.toLowerCase().includes(searchValue.toLowerCase()) || foo.code.toLowerCase().includes(searchValue.toLowerCase()))
  }, [data, searchValue])

  const handlePublishPre = (_data) => {
    setEditData(_data)
    setPublishModalOpen(true)
  }

  const handleAssign = (values) => {
    setLoading(true)
    instance({
      method: 'put',
      url: `tender/assign`,
      data: {
        id: editData.id,
        userId: values.user
      }
    }).then(res => {
      fetchData()
      setAssignModalOpen(false)
      setEditData(null)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const handleEndUser = (values) => {
    setLoading(true)
    instance({
      method: 'put',
      url: `tender/enduser`,
      data: {
        id: editData.id,
        userId: values.user
      }
    }).then(res => {
      fetchData()
      setEndUserModalOpen(false)
      setEditData(null)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const handleEOIImportFinish = (_values) => {
    setEOIImportLoading(true)
    instance({
      method: 'get',
      url: `/Tender/eoi/bycode/${_values.number}?wcode=tender-detail`
    }).then((res) => {
      if(res.data.responseData) {
        setEditData(prev => ({
          ...prev,
          supplierIds: res.data.responseData.suppliers.filter(foo => foo.awardStatus == 1).map(foo => foo.id),
          supplierLookup: res.data.responseData.suppliers.filter(foo => foo.awardStatus == 1),
          fromEOI: true
        }))
      }
      setEOIImportModalOpen(false)
    }).catch(() => {
      eoiImportForm.setFields([
        {
          name: 'number',
          errors: ['EOI not found']
        }
      ])
    }).then(() => {
      setEOIImportLoading(false)
    })
  }

  const handleRFQCopyFinish = (_values) => {
    setRfqCopyLoading(true)
    instance({
      method: 'get',
      url: `/Tender/rfq/bycode/${_values.number}?wcode=tender-detail`
    }).then((res) => {
      if(res.data.responseData) {
        setEditData(prev => ({
          ...prev,
        }))
      }
      setRfqCopyModalOpen(false)
    }).catch(() => {
      rfqCopyForm.setFields([
        {
          name: 'number',
          errors: ['RFQ not found']
        }
      ])
    }).then(() => {
      setRfqCopyLoading(false)
    })
  }
  
  return (
    <div className='flex flex-col'>
      <div className="bg-white mb-3 font-semibold flex gap-3 items-center justify-between">
        <div className="shrink-0">
          {filteredData.length} of {data.total} Results
        </div>
        <Search 
          placeholder="Search" 
          className='border-none' 
          onSearch={onSearch} 
          allowClear
          enterButton 
          onChange={e => setSearchValue(e.target.value)} />
        <div className='flex gap-2'>
          <Button onClick={handleAdd} type="primary">
            {
              type == 2 ?
              'Announce RFQ'
              :
              type == 1 ?
              'Announce EOI'
              :
              'Announce new tender'
            }
          </Button>
        </div>
      </div>
      <InfiniteScroll
        dataLength={data.data.length}
        next={fetchNext}
        loader={<div className='text-center font-bold'>Fetching...</div>}
        className="flex flex-col gap-1 overflow-x-hidden"
        hasMore={hasMore}
        endMessage={
          <p className="text-center font-bold">
            {/* No more tender data */}
          </p>
        }>
        <table className="oyu-table">
          <colgroup>
            <col style={{width: '50px'}}></col>
            <col style={{width: '100px'}}></col>
            <col style={{width: '400px'}}></col>
            {
              type == 2 &&
              <col style={{width: '75px'}}></col>
            }
            <col style={{width: '100px'}}></col>
            {
              type == 2 &&
              <col style={{width: '100px'}}></col>
            }
            <col style={{width: '100px'}}></col>
            {
              type == 1 &&
              <col style={{width: '150px'}}></col>
            }
            <col style={{width: '150px'}}></col>
            <col style={{width: '150px'}}></col>
            <col style={{width: '50px'}}></col>
          </colgroup>
          <thead>
            <tr>
              <th style={{textAlign: 'left'}}>#</th>
              <th style={{textAlign: 'left'}}>{type == 1 ? "EOI #" : type == 2 ? "Bid #" : type == 3 ? "TR #" : 'SRNumber'}</th>
              <th style={{textAlign: 'left'}}>Description</th>
              {
                type == 2 &&
                <th>Type</th>
              }
              <th>Submitted</th>
              <th>Status</th> 
              {
                type == 2 &&
                <th>End user status</th> 
              }
              {
                type == 1 &&
                <th>Assigned user</th>
              }
              <th>Publish date</th>
              <th>Close date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              filteredData.map((foo, fooIndex) => {
                return (
                  <tr key={`tender-${fooIndex}`} className="hover:bg-gray-100 transition-all">
                    <td>
                      {fooIndex+1}
                    </td>
                    <td>{foo.code}{foo.seq > 1 ? `/V${foo.seq}` : ''}</td>
                    <td>
                      <Link 
                        className="text-primary cursor-pointer hover:underline truncate" 
                        to={`${type == 1 ? 'eoi' : type == 2 ? 'rfq' : type == 3 ? 'travel' : 'freight'}/${foo.id}`} 
                        state={{...foo, type: type}}>
                        <div className="truncate">
                          <Tooltip title={foo.name}>
                            <span className="truncate">
                              {foo.name}
                            </span>
                          </Tooltip>
                        </div>
                      </Link>
                    </td>
                    {
                      type == 2 &&
                      <td className='text-center'>{foo.rfqType == 1 ? 'Goods' : 'Service'}</td>
                    }
                    <td style={{textAlign: 'center'}}>{foo.suppliers && `${foo.suppliers.joinCount}/${foo.suppliers.invitedCount}`}</td>
                    <td className="text-center">
                      <Tag className="whitespace-normal w-[120px] text-center leading-tight" color={foo.isManuallyClosed && foo.status == 3 ? '#a7a7a7' : statusEnum[foo.status].color}>
                        {foo.isManuallyClosed && foo.status == 3 ? 'Manually closed' : type == 3 ?  statusEnum[foo.status].travel ?? statusEnum[foo.status].title : statusEnum[foo.status].title}
                      </Tag>
                    </td>
                    {
                      type == 2 &&
                      <td className="text-center">
                        <Tag className="whitespace-normal w-[120px] text-center leading-tight" color={foo.endUserStatus == 1 ? '#03cb09' : '#a7a7a7'}>
                          {foo.endUserStatus == 1 ? 'Confirmed' : 'Not confirmed' }
                        </Tag>
                      </td>
                    }
                    {
                      type == 1 &&
                      <td className="text-center">
                        {
                          foo.assignedFirstName ? 
                          `${foo.assignedFirstName} ${foo.assignedLastName}`
                          :
                          `${foo.firstName} ${foo.lastName}`
                        }
                      </td>
                    }
                    <td className="text-center">{moment(foo.startDate).format("YYYY/MM/DD HH:mm")}</td>
                    <td className="text-center">{moment(foo.endDate).format("YYYY/MM/DD HH:mm")}</td>
                    <td>
                      <Dropdown 
                        trigger={['click']} 
                        menu={{items: items({
                          type: type,
                          row: foo, handleEdit, 
                          user: authContext.state.userInfo,
                          handlePublishPre, 
                          handlePublish, 
                          handleClose: () => {
                            setEditData(foo)
                            setCloseModalOpen(true)
                          }, 
                          handleReopen,
                          handleExtend, 
                          handleCancel: () => {
                            setEditData(foo)
                            setCancelModalOpen(true)
                            },
                          handleAssign: () => {
                            setEditData(foo)
                            setAssignModalOpen(true)
                          },
                          handleEndUser: () => {
                            setEditData(foo)
                            setEndUserModalOpen(true)
                          }
                          }),
                        }}>
                        <a onClick={e => e.preventDefault()} className="flex justify-center items-center">
                          <Space className="px-3">
                            <MoreOutlined className='text-xl'/>
                          </Space>
                        </a>
                      </Dropdown>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>

      </InfiniteScroll>
      
      <Modal
        title={editData ? 
          <div className='flex items-center justify-between gap-2 mr-10'>
            Editor
          </div> 
          : 
          <div className='flex items-center justify-between gap-2 mr-10'>
            Add
            {
              type == 2 &&
              <div className="flex gap-3">
                <Button size="small" onClick={() => setEOIImportModalOpen(true)}>Import EOI</Button>
                <Button size="small" onClick={() => setRfqCopyModalOpen(true)}>Copy RFQ</Button>
              </div>
            }
          </div>
        }
        open={open}
        maskClosable={false}
        destroyOnClose
        okText='Save'
        onOk={form.submit}
        onCancel={handleCloseModal}
        width={"90%"}
        style={{top: '20px'}}
        footer={null}>
        <TenderNew open={open} fetchData={fetchData} editData={editData} type={type} closeModal={handleCloseModal}/>
      </Modal>
      <Modal
        title="Import EOI"
        destroyOnClose
        open={eoiImportModalOpen}
        onCancel={() => {
          setEOIImportModalOpen(false)
          eoiImportForm.resetFields()
        }}
        onOk={() => eoiImportForm.submit()}>
        <AntForm
          form={eoiImportForm}
          onFinish={handleEOIImportFinish}
          layout='vertical'>
          <AntForm.Item name='number' label="EOI Number">
            <Input placeholder='Enter EOI number'></Input>
          </AntForm.Item>
        </AntForm>
      </Modal>
      <Modal
        title="Copy RFQ"
        destroyOnClose
        open={rfqCopyModalOpen}
        onCancel={() => {
          setRfqCopyModalOpen(false)
          rfqCopyForm.resetFields()
        }}
        onOk={() => rfqCopyForm.submit()}>
        <AntForm
          form={rfqCopyForm}
          onFinish={handleRFQCopyFinish}
          layout='vertical'>
          <AntForm.Item name='number' label="RFQ Number">
            <Input placeholder='Enter RFQ number'></Input>
          </AntForm.Item>
        </AntForm>
      </Modal>
      <Modal
        destroyOnClose
        maskClosable={false}
        title="Extend tender duration"
        open={extendModalOpen}
        zIndex={100001}
        onCancel={() => {
          setExtendModalOpen(false)
          setEditData(null)
        }}
        onOk={extendForm.submit}>
        <AntForm form={extendForm} layout="vertical" onFinish={extendTender} className="flex items-end gap-3">
          <AntForm.Item name="endDate" label="End date">
            <DatePicker 
              className="w-full"
              format="YYYY-MM-DD">

            </DatePicker>
          </AntForm.Item>
          <AntForm.Item name="endDate" label="">
            <TimePicker format={"HH:mm"}>

            </TimePicker>
          </AntForm.Item>
        </AntForm>
      </Modal>
      <Modal
        destroyOnClose
        maskClosable={false}
        title="Cancel confirmation"
        open={cancelModalOpen}
        zIndex={100001}
        onCancel={() => {
          setCancelModalOpen(false)
          setEditData(null)
        }}
        onOk={cancelForm.submit}>
        <AntForm form={cancelForm} layout="vertical" onFinish={handleCancel}>
          <AntForm.Item name="justification" label="Justification" rules={[{required: true, message: 'Justification is required!'}]}>
            <Input.TextArea placeholder='Type justification'>

            </Input.TextArea>
          </AntForm.Item>
          <AntForm.Item name="attachment" label="Attachment">
            <Dragger 
              defaultFileList={editData && editData["attachment"] ? fixFiles(editData["attachment"]) : []} 
              maxCount={1} 
              action={`${process.env.REACT_APP_MAIN_API_URL}File/upload`}
              headers={{
                Authorization: `Bearer ${authContext.state.token}`,
              }}
              onRemove={(file) => {
                instance({
                  method: 'delete',
                  url: `File/remove`,
                  data: file.response.responseData
                })
              }}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                banned files.
              </p>
              {/* <Button icon={<UploadOutlined />}>Select file</Button> */}
            </Dragger>
          </AntForm.Item>
        </AntForm>
      </Modal>
      <Modal
        destroyOnClose
        maskClosable={false}
        title="Publish confirmation"
        open={publishModalOpen}
        zIndex={100001}
        onCancel={() => {
          setPublishModalOpen(false)
          setEditData(null)
        }}
        onOk={publishForm.submit}>
        {
          editData &&
          <AntForm 
            initialValues={{
              startDate: dayjs(editData.setDate),
              endDate: dayjs(editData.endDate),
            }}
            form={publishForm} 
            layout="vertical" 
            onFinish={handlePublish}>
            <AntForm.Item name="startDate" label="Start date" rules={[{required: true, message: 'Start date is required!'}]}>
              <DatePicker className='w-full' format={"YYYY-MM-DD HH:mm"} showTime={{format: 'HH:mm'}}/>
            </AntForm.Item>
            <AntForm.Item name="endDate" label="End date" rules={[{required: true, message: 'End date is required!'}]}>
              <DatePicker className='w-full' format={"YYYY-MM-DD HH:mm"} showTime={{format: 'HH:mm'}}/>
            </AntForm.Item>
          </AntForm>
        }
      </Modal>
      <Modal
        destroyOnClose
        maskClosable={false}
        title="Assign to another user"
        open={assignModalOpen}
        zIndex={100001}
        onCancel={() => {
          setAssignModalOpen(false)
          setEditData(null)
          assignForm.resetFields()
        }}
        onOk={assignForm.submit}
        confirmLoading={loading}>
        {
          editData &&
          <AntForm
            form={assignForm}
            layout='vertical'
            onFinish={handleAssign}>
            <AntForm.Item name='user' label="Assigning user">
              <Select options={users.map(foo => ({value: foo.id, label: `${foo.firstName} ${foo.lastName}`}))}/>
            </AntForm.Item>
          </AntForm>
        }
      </Modal>
      <Modal
        destroyOnClose
        maskClosable={false}
        title="Add end users"
        open={endUserModalOpen}
        zIndex={100001}
        onCancel={() => {
          setEndUserModalOpen(false)
          setEditData(null)
          endUserForm.resetFields()
        }}
        onOk={endUserForm.submit}
        confirmLoading={loading}>
        {
          editData &&
          <AntForm
            form={endUserForm}
            layout='vertical'
            onFinish={handleEndUser}>
            <AntForm.Item name='user' label="End users">
              <Select 
                options={users.map(foo => ({value: foo.id, label: `${foo.firstName} ${foo.lastName}`}))}
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
              />
            </AntForm.Item>
          </AntForm>
        }
      </Modal>
      <Modal
        destroyOnClose
        open={closeModalOpen}
        title="Close tender"
        onCancel={() => setCloseModalOpen(false)}
        onOk={() => closeForm.submit()}>
        <AntForm form={closeForm} layout='vertical' onFinish={handleClose}>
          <AntForm.Item name="closeJustification" label="Justification" rules={[{required: true, message: 'Justification required!'}]}>
            <Input.TextArea placeholder='Enter justification'>

            </Input.TextArea>
          </AntForm.Item>
        </AntForm>
      </Modal>
    </div>
  )
}

export default TenderTypeContainer
