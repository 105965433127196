import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { EditOutlined, PlusCircleOutlined, MoreOutlined } from '@ant-design/icons'
import { Button, Form as AntForm, Modal, Space, Dropdown, Tabs  } from 'antd';
import { MainTable, Form } from 'components';
import { instance } from 'utils/axios';
import { message } from 'antd';
import moment from 'moment';

const items = ({row, handleEdit, handleDelete}) => {  
  return (
    [
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleEdit(row)
        }}>Edit</a>,
        key: 0
      },
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleDelete(row.id)
        }}>Delete</a>,
        key: 1
      },
    ]
  )
}

const fields = ({suppliers, tenders}) => {
  return [
    {
      label: 'Title',
      name: 'title',
      rules: [{required: true, message: 'Title is required!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Content',
      name: 'body',
      rules: [{required: true, message: 'Content is required!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Attachment',
      name: 'file',
      type: 'file',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Tender',
      name: 'tenderId',
      type: 'select',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
        options: tenders.map(foo => ({value: foo.id, label: foo.name}))
      }
    },
    {
      label: 'Supplier',
      name: 'supplierId',
      type: 'select',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
        options: suppliers.map(foo => ({value: foo.id, label: foo.name}))
      }
    },
    {
      label: 'Supplier employee',
      name: 'supplierEmployeeId',
      type: 'select',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Object',
      name: 'objectId',
      type: 'select',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Route',
      name: 'route',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
  ]
}

const columns = ({currentPage, handleEdit, handleDelete}) => {
  return ([
    {
      title: '№',
      dataIndex: 'No',
      key: 'No',
      width: 40,
      align: 'center',
      render: (text, row, i) => (
        <span>{currentPage ? currentPage+i+1 : i+1}</span>
      )
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      filterType: "search",
      render: (text, row) => (
        <Link className='text-link hover:underline hover:text-link' to={`${row.id}`} state={row}>{text}</Link>
      )
    },
    {
      title: 'Last response date',
      dataIndex: 'lastResponseDate',
      key: 'lastResponseDate',
      filterType: "search",
      width: 200,
      render: (text, row) => <span>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</span>
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 80,
      render: (text, row) => (
        <Dropdown 
          trigger={['click']} 
          menu={{items: items({row, handleEdit, handleDelete}),}}>
          <a onClick={e => e.preventDefault()}>
            <Space>
              <MoreOutlined className='text-xl'/>
            </Space>
          </a>
        </Dropdown>
      )
    },
  ])
}

const Message = ({}) => {
  const [ data, setData ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const [ open, setOpen ] = useState(false)
  const [ editData, setEditData ] = useState(null)
  const [ isEdit, setIsEdit ] = useState(false)
  const [ modal, contextHolder ] = Modal.useModal();
  const [ suppliers, setSuppliers ] = useState([])
  const [ tenders, setTenders ] = useState([])
  const [ form ] = AntForm.useForm();

  useEffect(() => {
    fetchData()
    fetchTenders()
    fetchSuppliers()
  }, [])

  const fetchTenders = () => {
    instance({
      method: 'get',
      url: `/Tender`
    }).then(res => {
      setTenders(res.data.responseData)
    }).catch(err=> {

    }).then(() => {
      setLoading(false)
    })
  }

  const fetchSuppliers = () => {
    instance({
      method: 'get',
      url: `/Supplier`
    }).then(res => {
      setSuppliers(res.data.responseData)
    }).catch(err=> {

    }).then(() => {
      setLoading(false)
    })
  }

  const fetchData = () => {
    instance({
      method: 'get',
      url: `/Message/messagethreads?wcode=message`
    }).then(res => {
      setData(res.data.responseData)
    }).catch(err=> {

    }).then(() => {
      setLoading(false)
    })
  }

  const handleCloseModal = () => {
    setOpen(false)
    form.resetFields()
  }

  const handleEdit = (_row) => {
    setEditData(_row)
    setOpen(true)
  }

  const handleAddNew = () => {
    setEditData(null)
    setOpen(true)
  }

  const handleSubmit = (values) => {
    if(editData) {

    }
    else {
      instance({
        method: 'post',
        url: `/Message/send`,
        data: {
          ...values
        }
      }).then(() => {
        setOpen(false)
      }).catch(() => {

      }).then(() => {
        setLoading(false)
        fetchData()
      })
    }
  }

  const handleDelete = (id) => {
    modal.confirm({
      title: 'Warning!',
      content: 'You are about to delete this row of data',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: 'delete',
            url: `/CompanyQuestion/${id}`
          }).then((res) => {
            resolve()
          }).catch((err) => {
            reject()
          }).then(() => fetchData())
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

  return (
    <div className="p-4">
      <MainTable
        dataTable={data}
        columns={columns({handleDelete, handleEdit})}
        loading={loading}
        handleAdd={handleAddNew}>

      </MainTable>
      <Modal
        title={editData ? <div className='flex items-center justify-start gap-2'>Edit</div> : <div className='flex items-center justify-start gap-2'>Send</div>}
        open={open}
        destroyOnClose
maskClosable={false}
        okText='Save'
        onOk={form.submit}
        onCancel={handleCloseModal}
        width={"40%"}
      >
        <Form
          form={form}
          fields={fields({suppliers, tenders})}
          length={data.length}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          isEdit={isEdit}
          className='gap-4'
        />
      </Modal>
      {contextHolder}
    </div>
  );
}

export default Message;
