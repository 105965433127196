import { DatePicker, Form, Select, Table, Transfer } from "antd"
import { useEffect, useState } from "react";
import { instance } from "utils/axios";

const columns = [
  {
    dataIndex: 'id',
    title: 'ID',
    width: 50
  },
  {
    dataIndex: 'name',
    title: 'Name',
  },
  {
    dataIndex: 'vendorNumber',
    title: 'SAP ID',
  },
];

const TableTransfer = ({ leftColumns, rightColumns, loading, ...restProps }) => (
  <Transfer {...restProps}>
    {({
      direction,
      filteredItems,
      onItemSelect,
      onItemSelectAll,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      const columns = direction === 'left' ? leftColumns : rightColumns;
      const rowSelection = {
        getCheckboxProps: () => ({
          disabled: listDisabled,
        }),
        onChange(selectedRowKeys) {
          onItemSelectAll(selectedRowKeys, 'replace');
        },
        selectedRowKeys: listSelectedKeys,
        selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
      };
      return (
        <Table
          loading={loading}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={filteredItems}
          size="small"
          style={{
            pointerEvents: listDisabled ? 'none' : undefined,
          }}
          onRow={({ key, disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled || listDisabled) {
                return;
              }
              onItemSelect(key, !listSelectedKeys.includes(key));
            },
          })}
        />
      );
    }}
  </Transfer>
);

const SurveyForm = ({setModal, form}) => {
  const [ fetching, setFetching ] = useState(true)
  const [ suppliers, setSuppliers ] = useState({
    total: 0,
    data: []
  })
  const [ surveys, setSurveys ] = useState([])
  
  
  useEffect(() => {
    instance({
      method: 'get',
      url: '/Supplier?wcode=survey-suppliers',
    }).then((res) => {
      if(res.data.responseData.data.length > 0) {
        setSuppliers(res.data.responseData)
      }
    }).catch((err) => {
    }).then(() => setFetching(false))
    instance({
      method: 'get',
      url: '/survey/type/1?wcode=master',
    }).then((res) => {
      if(res.data.responseData.length > 0) {
        setSurveys(res.data.responseData)
      }
    }).catch((err) => {
    })
  }, [])
  
  const handleFinish = (values) => {
    console.log(values)
    instance({
      method: 'post',
      url: `/SurveySupplier?wcode=survey-suppliers`,
      data: {
        content: 'foo',
        startDate: values.startDate,
        endDate: values.endDate,
        surveyId: values.surveyId,
        toAllSuppliers: 0,
        supplierIds: values.supplierIds
      }
    }).then(() => {
      setModal(false)
    })
  }
  
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleFinish}
      className="grid grid-cols-12 gap-x-3">
      <Form.Item label="Survey" name="surveyId" className="col-span-12">
        <Select options={surveys.map((foo) => ({value: foo.id, label: foo.name}))}></Select>
      </Form.Item>
      <Form.Item label="Start date" name="startDate" className="col-span-6">
        <DatePicker className="w-full"></DatePicker>
      </Form.Item>
      <Form.Item label="End date" name="endDate" className="col-span-6">
        <DatePicker className="w-full"></DatePicker>
      </Form.Item>
      <Form.Item
        className="col-span-12"
        name="supplierIds" 
        label="Suppliers" 
        valuePropName={"targetKeys"}>
        <TableTransfer
          loading={fetching}
          rowKey={(record) => record.id}
          dataSource={suppliers.data}
          showSearch
          showSelectAll={true}
          filterOption={(inputValue, item) =>
            item.name.indexOf(inputValue) !== -1 || item.vendorNumber.indexOf(inputValue) !== -1
          }
          leftColumns={columns}
          rightColumns={columns}
        />
      </Form.Item>
    </Form>
  )
}

export default SurveyForm