import React, { useContext, useEffect, useMemo, useState } from 'react';
import { instance } from 'utils/axios';
import { Table, Card, Statistic, Button, Input, Tag, DatePicker, Select, Skeleton  } from 'antd'
import moment from 'moment';
import { Demo, SuppliersChart, TenderChart } from './components'
import { SearchOutlined, FileExcelOutlined, PrinterOutlined } from '@ant-design/icons'
import { ReactComponent as DocumentIcon } from "assets/icons/document.svg";
import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import TenderPie from './components/TenderPie';
import TenderBar from './components/TenderBar';
import { Link } from 'react-router-dom';

const statusEnum = {
  '-1': {name: 'Draft', color: '#D9D9D9'},
  0: {name: 'Published', color: '#F3A638'},
  1: {name: 'Open', color: '#01B4C5'},
  2: {name: 'Reopened', color: '#01B4C5'},
  3: {name: 'Closed', color: '#F3A638'},
  4: {name: 'Awarded', color: '#4CB64C'},
  5: {name: 'Finished', color: '#4CB64C'},
  6: {name: 'Postponed', color: '#6B81FF'},
  7: {name: 'Declined', color: '#FF5050'},
  8: {name: 'In evaluation', color: '#F3A638'},
  9: {name: 'Approved', color: '#4CB64C'},
  10: {name: 'Pending approval', color: '#F3A638'},
  11: {name: 'Pending approval', color: '#F3A638'},
  11: {name: 'Canceled', color: '#D55727'},
}

const Dashboard = ({}) => {
  const [ suppliersData, setSuppliersData ] = useState({
    preQualified: 0,
    supplierTier: [],
    total: 9,
    validated: 2
  })
  const [ tenderData, setTenderData ] = useState([])
  const [ activeStatus, setActiveStatus ] = useState(null)
  const [ activeTender, setActiveTender ] = useState(null)
  const [ loading, setLoading ] = useState(true)
  const [ data, setData ] = useState(null)

  useEffect(() => {
    fetchTotalData()
  }, [])

  useEffect(() => {
    if(activeTender != null) {
      fetchData()
    }
  }, [activeTender])

  const fetchTotalData = () => {
    instance({
      method: 'get',
      url: `/Dashboard/total/tender?wcode=dashboard`
    }).then(res => {
      setActiveTender(res.data.responseData[1]?.tenderType ?? null)
      setTenderData(res.data.responseData)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const fetchData = () => {
    instance({
      method: 'get',
      url: `/Dashboard/tender?wcode=dashboard`,
      params: activeTender && {
        tenderType: activeTender
      }
    }).then(res => {
      // console.log(res.data.responseData)
      if(res.data.responseData?.total.length > 0) {
        setActiveStatus(res.data.responseData.total[0].status)
      }
      setData(res.data.responseData)
    })
  }

  const filteredData = useMemo(() => {

    if(activeStatus) {
      return data?.tenders ? data.tenders.filter(foo => foo.status == activeStatus) : []
    }

    return data?.tenders ?? []
  }, [data, activeStatus])

  return (
    <div className='p-4 grid grid-cols-12 gap-3'>
      <Card className="col-span-7" size='small'>
        <div className="flex gap-5 pl-3">
          <div className="flex flex-col justify-center items-center lg:mx-0 mx-4">
            <UserIcon/>
            <div className="text-lg">Supplier</div>
          </div>
          <div className="grow grid grid-cols-4 gap-3">
            <div className="h-[80px] rounded-xl border flex flex-col justify-center items-center">
              <div className='text-[13px] text-[#515151]'>Total</div>
              <div className="text-3xl font-bold leading-none">{suppliersData.total ?? 0}</div>
            </div>
            <div className="h-[80px] rounded-xl border flex flex-col justify-center items-center">
              <div className='text-[13px] text-[#515151]'>Pre-Qualified</div>
              <div className="text-3xl font-bold leading-none">{suppliersData.preQualified ?? 0}</div>
            </div>
            <div className="h-[80px] rounded-xl border flex flex-col justify-center items-center">
              <div className='text-[13px] text-[#515151]'>Invited</div>
              <div className="text-3xl font-bold leading-none">{suppliersData.validated ?? 0}</div>
            </div>
            {/* <div className="h-[80px] rounded-xl border flex flex-col justify-center items-center">
              <div className='text-[13px] text-[#515151]'>Validated</div>
              <div className="text-3xl font-bold leading-none">{suppliersData.validated ?? 0}</div>
            </div> */}
            {/* <div className="h-[80px] rounded-xl border flex flex-col justify-center items-center">
              <div className='text-[11px] text-[#515151]'>Participated</div>
              <div className="text-3xl font-bold leading-none">{suppliersData.preQualified}</div>
            </div> */}
          </div>
        </div>
      </Card>
      <Card className="col-span-5" size='small'>
        <div className="flex gap-5 pl-3">
          <div className="flex flex-col justify-center items-center lg:mx-0 mx-4">
            <DocumentIcon/>
            <div className="text-lg">Project</div>
          </div>
          <div className={"grow grid gap-3"} style={{gridTemplateColumns: `repeat(2, minmax(0, 1fr))`}}>
            {/* <div className="h-[80px] rounded-xl border flex flex-col justify-center items-center cursor-pointer" onClick={() => setActiveTender(0)}>
              <div className='text-[11px] text-[#515151]'>Total</div>
              <div className="text-3xl font-bold leading-none">{tenderData.reduce((a,b) => a+b.count, 0)}</div>
            </div> */}
            {
              tenderData.filter(foo => foo.tenderType == 1 || foo.tenderType == 2).map((foo, fooIndex) => {
                return (
                  <div 
                    key={`tender-total-${fooIndex}`} 
                    className={
                      activeTender == foo.tenderType ?
                      "h-[80px] rounded-xl border flex flex-col justify-center items-center cursor-pointer bg-main transition-all"
                      :
                      "h-[80px] rounded-xl border flex flex-col justify-center items-center cursor-pointer bg-transparent transition-all hover:bg-main group"
                    } 
                    onClick={() => setActiveTender(foo.tenderType)}>
                    <div className='text-[#070707] group-hover:!text-white transition-all' style={{color: activeTender == foo.tenderType ? 'white' : '#515151'}}>{foo.tenderTypeName}</div>
                    <div className="text-3xl font-bold leading-none group-hover:!text-white transition-all" style={{color: activeTender == foo.tenderType ? 'white' : '#121212'}}>{foo.count ?? 0}</div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </Card>
      {/* <Card className="col-span-2" size='small'>
        <div className="flex gap-3">
          <div className="flex flex-col justify-center items-center lg:mx-0 mx-4">
            <DocumentIcon/>
            <div className="text-lg">Auctions</div>
          </div>
        </div>
      </Card> */}
      {/* <Card className="col-span-12" size='small'>
        <div className="flex items-center justify-between gap-3">
          <div className="flex items-center gap-3">
            <Input
              className='bg-[#FCFCFD]'
              placeholder="Search"
              prefix={
                <SearchOutlined
                  style={{
                    color: 'rgba(0,0,0,.25)',
                  }}
                />
              }
              allowClear={true}
            />
          </div>
          <div className="flex items-center gap-3">
            <DatePicker.RangePicker className="w-[240px]"/>
            <Button type="link" icon={<FileExcelOutlined/>}></Button>
            <Button type="link" icon={<PrinterOutlined/>}></Button>
          </div>
        </div>
      </Card> */}
      {
        data ?
        <>
          <Card className="col-span-12" size='small' bodyStyle={{paddingBottom: 0}}>
            <div className="flex gap-3 overflow-x-auto pb-3">
              {data.total.sort((a,b) => a.order - b.order).map((foo, fooIndex) => {
                return (
                  <Card className={
                    activeStatus == foo.status ? 
                    'shrink-0 cursor-pointer bg-main transition-all group'
                    :
                    'shrink-0 cursor-pointer hover:bg-main transition-all bg-transparent group'
                  } key={`tender-total-card-${fooIndex}`} size="small" onClick={() => setActiveStatus(foo.status)}>
                    <div className="flex justify-between items-center h-full gap-10">
                      <div className="leading-none group-hover:!text-white" style={{color: foo.status == activeStatus ? 'white' : statusEnum[foo.status].color}}>{foo.name}</div>
                      <div className="font-medium text-[20px] leading-none group-hover:!text-white" style={{color: foo.status == activeStatus ? 'white' : 'black'}}>{foo.count}</div>
                    </div>
                  </Card>
                )
              })}    
            </div>
          </Card>
          {/* <Card className='col-span-6' size='small'>
            
            <TenderPie activeTender={activeTender} activeStatus={activeStatus}/>
          </Card>
          <Card className='col-span-6' size='small'>
            <TenderBar activeTender={activeTender} activeStatus={activeStatus}/>
          </Card> */}
          <Card className="col-span-12" size="small">
            <div>
              {}
            </div>
            <Table
              pagination={false}
              size='small'
              dataSource={filteredData}
              columns={[
                // {
                //   dataIndex: 'id',
                //   title: 'S/N',
                //   width: 70
                // },
                {
                  dataIndex: 'code',
                  title: 'Bid #',
                  width: 100,
                  render: (cell, row) => {
                    return (
                      <Link to={`/tender/${row.tenderTypeId == 1 ? 'eoi' : row.tenderTypeId == 2 ? 'rfq' : row.tenderTypeId == 3 ? 'travel' : 'freight'}/${row.id}`} className="text-xs font-bold transition-all hover:text-primary">{cell}</Link>
                    )
                  }
                },
                {
                  dataIndex: 'name',
                  title: 'Description',
                  width: 400
                },
                // {
                //   dataIndex: 'buyerFirstName',
                //   title: 'Owner',
                //   width: 200,
                //   render: (cell, row) => `${cell} ${row.buyerLastName}`
                // },
                {
                  dataIndex: 'createdAt',
                  title: 'Publish date',
                  render: cell => <span className="text-xs font-bold">{moment(cell).format("YYYY/MM/DD HH:mm")}</span>,
                  width: 130
                },
                {
                  dataIndex: 'endDate',
                  title: 'Close date',
                  render: (cell) => <span className="text-xs font-bold">{moment(cell).format("YYYY/MM/DD HH:mm")}</span>,
                  width: 130
                },
                {
                  dataIndex: 'status',
                  title: 'Status',
                  fixed: 'right',
                  render: (cell) => <Tag color={statusEnum[cell].color}>{statusEnum[cell].name}</Tag>,
                  width: 80
                },
                {
                  dataIndex: '',
                  title: 'Assigned to',
                  fixed: 'right',
                  width: 200,
                  render: (cell, row) => {
                    if(row.assigned.length > 0) {
                      let tmp = []

                      row.assigned.map((foo, fooindex) => {
                        tmp.push(
                          <div>{`${foo.firstName} ${foo.lastName}`}</div>
                        )
                      })

                      return tmp
                    }
                    else {
                      return `${row.buyerFirstName} ${row.buyerLastName}`
                    }
                  }
                },
              ]}
              scroll={{
                x: window.innerWidth - 280 - 32
              }}>

            </Table>
          </Card>
        </>
        :
        loading ?
        <Skeleton active/>
        :
        <></>
      }
    </div>
  );
};

export default Dashboard;
