import { useState, useEffect } from 'react'
import { Form } from 'components'
import { Button, Form as AntForm, Card, Input, Select } from 'antd'
import { instance } from 'utils/axios'
import { useOutletContext } from 'react-router-dom'

const ManagementTeam = ({state}) => {
  const [ loading, setLoading ] = useState(false)
  const [ form ] = AntForm.useForm();
  const [ data ] = useOutletContext()
  const { supplierEmployees } = data
  
  useEffect(() => {
    form.setFieldsValue({managementTeam: supplierEmployees})
  }, [supplierEmployees])
  
  return (
    <div className='flex flex-col gap-3'>
      {
        supplierEmployees.length > 0 ?
        supplierEmployees.map((foo, fooIndex) => {
          return (
            <Card key={`management-team-${fooIndex}`} title={`Management team #${fooIndex+1}`} className='rounded-none'>
              <div className="grid grid-cols-4 gap-3">
                <div>
                  <label className="font-semibold">Fullname:</label>
                  <div className="text-mute">{(foo.firstName || foo.lastName) ? `${foo.firstName} ${foo.lastName}` : 'No name'}</div>
                </div>
                <div>
                  <label className="font-semibold">Job Title:</label>
                  <div className="text-mute">{foo.position ? foo.position : 'No position'}</div>
                </div>
                <div>
                  <label className="font-semibold">Phone:</label>
                  <div className="text-mute">{foo.phone ? foo.phone : 'No phone'}</div>
                </div>
                <div>
                  <label className="font-semibold">Email:</label>
                  <div className="text-mute">{foo.email ? foo.email : 'No name'}</div>
                </div>
              </div>
            </Card>
          )
        })
        :
        <div className="text-center font-semibold">
          No management team members
        </div>
      }
      {/* <Form
        disabled={true}
        form={form}
        fields={[]}
        layout="vertical"
        onFinish={() => {}}
        className='gap-4'>  
        <AntForm.List name="managementTeam">  
          {(fields, {add, remove}) => (
            <>
              {
                fields.map(({name, key, ...restFields}) => (
                  <Card title={`${key+1}. Team`} className="col-span-12 rounded-2xl" key={key} {...restFields}>
                    <AntForm.Item label="Firstname" name={[name, "firstName"]} className='col-span-12'>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="Lastname" name={[name, "lastName"]}>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="Job Title" name={[name, "address2"]}>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="Phone" name={[name, "phone"]}>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="Email" name={[name, "email"]}>
                      <Input></Input>
                    </AntForm.Item>
                  </Card>
                ))
              }
            </>
          )}
        </AntForm.List>
      </Form> */}
      
    </div>
  )
}

export default ManagementTeam