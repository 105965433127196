import React, { useEffect, useMemo, useState } from 'react'
import { Button, Modal, Form as AntForm, Dropdown, Space, Tag, Card, Input, Tooltip, Checkbox, Skeleton } from 'antd'
import { EditOutlined, PlusCircleOutlined, MoreOutlined, RightOutlined } from '@ant-design/icons'
import { Form, MainTable, Filters } from 'components'
import { instance } from 'utils/axios'
import fields from './fields'
import { Link } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component';
const { Search } = Input

const validationStatusEnum = {
  0: {
    title: 'New',
    color: '#00aaad'
  },
  1: {
    title: 'Requested',
    color: '#427EC1'
  },
  2: {
    title: 'Returned',
    color: '#D55727'
  },
  3: {
    title: 'Checked',
    color: '#009A72'
  },
}

const prequalificationStatusEnum = {
  0: {
    title: 'Not qualified',
    color: '#D55727'
  },
  1: {
    title: 'Qualified',
    color: '#009A72'
  }
}

const items = ({row, block}) => {  
  return (
    [
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          block(row)
        }}>Block</a>,
        key: 0
      },
    ]
  )
}

function Supplier() {
  const [ data, setData ] = useState({
    data: [],
    total: 0
  })
  const [ filter, setFilter ] = useState({})
  const [ currentPage, setCurrentPage ] = useState(1)
  const [ open, setOpen ] = useState(false)
  const [ editData, setEditData ] = useState(null)
  const [ isEdit, setIsEdit ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ modal, contextHolder ] = Modal.useModal();
  const [ selectedRowKeys, setSelectedRowKeys ] = useState([])
  const [ searchValue, setSearchValue ] = useState("") 
  const [ hasMore, setHasMore ] = useState(true)
  const [ form ] = AntForm.useForm();

  useEffect(() => {
    setCurrentPage(1)
    setHasMore(true)
    fetchData()
  },[filter])

  const fetchData = () => {
    setLoading(true)
    setHasMore(true)
    setData({total: 0, data: []})
    instance({
      method: 'get',
      url: '/Supplier?wcode=supplier',
      params: {
        page: 1,
        pageSize: 20,
        ...filter
      },
      paramsSerializer: {
        indexes: null
      }
    }).then((res) => {
      if(res.data.responseData.data.length < 20) {
        setHasMore(false)
      }
      setData(res.data.responseData)
    }).catch((err) => {
    }).then(() => setLoading(false))
  }

  const fetchNext = () => {
    instance({
      method: 'get',
      url: '/Supplier?wcode=supplier',
      params: {
        ...filter,
        page: currentPage+1,
        pageSize: 20
      },
      paramsSerializer: {
        indexes: null
      }
    }).then((res) => {
      if(res.data.responseData.data.length < 20) {
        setHasMore(false)
      }
      setCurrentPage(currentPage+1)
      setData(prev => ({total: res.data.responseData.total, data: [...prev.data, ...res.data.responseData.data]}))
    }).catch((err) => {
    })
  }
  
  const handleBlock = (id) => {

  }

  const handleSubmit = (values) => {
    setLoading(true)
    if(editData){
      instance({
        method: 'put',
        url: '/sector',
        data: {
          ...values,
          id: editData.id
        }
      }).then((res) => {
        handleCloseModal()
        fetchData()
      }).catch((err) => {

      }).then(() => setLoading(false))
    }
    else{
      instance({
        method: 'post',
        url: '/sector',
        data: {
          ...values
        }
      }).then((res) => {
        handleCloseModal()
        fetchData()
      }).catch((err) => {

      }).then(() => setLoading(false))
    }
  }

  const handleCloseModal = () => {
    setOpen(false)
    form.resetFields()
  }

  const onSearch = (values) => {
    setFilter(values)
  }

  const filteredData = useMemo(() => {
    return data.data.filter(foo => foo.vendorNumber?.includes(searchValue.toLowerCase()) || foo.name.toLowerCase().includes(searchValue.toLowerCase()))
  }, [data, searchValue])
  
  return (
      <div className='p-4'>
        <div className="grid grid-cols-12 gap-3">
          <div className='col-span-12'>
            <Filters wcode="supplier" onSearch={onSearch}/>
          </div>
          <Card className='col-span-12' size="small">
            <div className="flex flex-col gap-3">
              <div className="font-semibold flex items-center justify-between gap-3">
                <div className='shrink-0'>
                  {filteredData.length} of {data.total} Results
                </div>
                <Search 
                  placeholder="Search"
                  className='border-none' 
                  enterButton 
                  onChange={e => setSearchValue(e.target.value)} />
                <div className='flex gap-2'>
                  <Tooltip title={selectedRowKeys.length == 0 ? "Select suppliers to send email" : "Send email to selected suppliers"}>
                    <Button type="primary" disabled={selectedRowKeys.length == 0}>
                      Send notification
                    </Button>
                  </Tooltip>
                  <Tooltip title={selectedRowKeys.length == 0 ? "Select suppliers to block" : "Block selected suppliers"}>
                    <Button type="primary" disabled={selectedRowKeys.length == 0}>
                      Block
                    </Button>
                  </Tooltip>
                </div>
              </div>
              <div className="px-4">
                <Checkbox onChange={e => {
                  if(e.target.checked) {
                    setSelectedRowKeys(filteredData.map(foo => foo.id))
                  }
                  else {
                    setSelectedRowKeys([])
                  }
                }}
                checked={filteredData.length == selectedRowKeys.length}>
                  Check all
                </Checkbox>
              </div>
              <div className="flex flex-col gap-1">
                <InfiniteScroll
                  dataLength={data.data.length}
                  next={fetchNext}
                  loader={<div className='text-center font-bold'>Fetching...</div>}
                  className="flex flex-col gap-1"
                  hasMore={hasMore}
                  endMessage={
                    <p className="text-center font-bold">
                      No more data
                    </p>
                  }>
                  <table className="oyu-table">
                    <colgroup>
                      <col style={{width: '30px'}}></col>
                      <col style={{width: '50px'}}></col>
                      <col></col>
                      <col style={{width: '100px'}}></col>
                      <col style={{width: '200px'}}></col>
                      <col style={{width: '150px'}}></col>
                      <col style={{width: '150px'}}></col>
                      <col style={{width: '150px'}}></col>
                      <col style={{width: '50px'}}></col>
                    </colgroup>
                    <thead>
                      <tr>
                        <th></th>
                        <th style={{textAlign: 'left'}}>#</th>
                        <th style={{textAlign: 'left'}}>Name</th>
                        <th>Vendor #</th>
                        <th>Pre-qualification status</th>
                        <th>Tier type</th>
                        <th>Email address</th>
                        <th>Contact number</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        filteredData.map((foo, fooIndex) => {
                          return (
                            <tr key={`tender-${fooIndex}`} className="hover:bg-gray-100 transition-all">
                              <td>
                                <div>
                                  <Checkbox 
                                    checked={selectedRowKeys.find(_foo => _foo == foo.id)}
                                    onChange={e => {
                                      console.log(e)
                                      if(e.target.checked) {
                                        setSelectedRowKeys(prev => [...prev, foo.id])
                                      }
                                      else {
                                        setSelectedRowKeys(prev => prev.filter(_foo => _foo != foo.id))
                                      }
                                    }}/>
                                </div>
                              </td>
                              <td>
                                {fooIndex+1}
                              </td>
                              <td>
                                <Link 
                                  className="text-primary cursor-pointer hover:underline" 
                                  to={`${foo.id}`} 
                                  state={foo}>
                                  <div className="truncate">
                                    <Tooltip title={foo.name}>
                                      <span className="truncate">
                                        {foo.name}
                                      </span>
                                    </Tooltip>
                                  </div>
                                </Link>
                              </td>
                              <td style={{textAlign: 'center'}}>{foo.vendorNumber}</td>
                              <td className="text-center">
                                <Tag className="w-[100px] text-center" color={validationStatusEnum[foo.validationStatus] ? validationStatusEnum[foo.validationStatus].color : prequalificationStatusEnum[foo.preQualificationStatus].color}>
                                  {validationStatusEnum[foo.validationStatus] ? validationStatusEnum[foo.validationStatus].title : prequalificationStatusEnum[foo.preQualificationStatus].title}
                                </Tag>
                              </td>
                              <td className='text-center'>
                                {foo.supplierTierName}
                              </td>
                              <td className='text-center'>
                                {foo.companyEmail}
                              </td>
                              <td className='text-center'>
                                {foo.phone}
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </InfiniteScroll>
              </div>
            </div>
          </Card>
        </div>
        <Modal
          title={editData ? <div className='flex items-center justify-start gap-2'><EditOutlined/>Edit</div> : <div className='flex items-center justify-start gap-2'><PlusCircleOutlined/>Add</div>}
          open={open}
          destroyOnClose
          maskClosable={false}
          okText='Save'
          onOk={form.submit}
          onCancel={handleCloseModal}
        >
          <Form
            form={form}
            fields={fields()}
            length={data.length}
            layout="vertical"
            onFinish={handleSubmit}
            editData={editData}
            isEdit={isEdit}
            className='gap-4'
          />
        </Modal>
        {contextHolder}
      </div>
  )
}

export default Supplier
