import { useEffect, useRef, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { instance } from "utils/axios"
import { Button, Input, Tooltip } from "antd"
import { SendOutlined, LeftOutlined } from '@ant-design/icons'
import moment from "moment"

const MessageDetail = () => {
  const [ data, setData ] = useState([])
  const [ init, setInit ] = useState(true)
  const [ loading, setLoading ] = useState(false)
  const [ message, setMessage ] = useState('')
  const { id } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const messageContainer = useRef()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `/Message/thread/${id}`
    }).then(res => {
      setData(res.data.responseData)
    }).catch(err => {
      
    }).then(() => {
      setInit(false)
    })
    instance({
      method: 'get',
      url: `/Message/seen/${id}`
    })
  }

  const renderMessages = () => {
    let tmp = []

    data.map((message, messageIndex) => {
      tmp.push(
        <div key={messageIndex} className={message.isSupplier ? 'flex gap-4 items-start' : 'flex gap-4 items-start justify-end'}>
          {
            message.isSupplier ?
            <div className="w-8 h-8 rounded-full bg-gray-100"></div> : null
          }
          <Tooltip title={moment(message.createdAt).format("YYYY-MM-DD HH:mm:ss")} >
            <div className=" max-w-[60%] font-medium px-4 py-1 shadow bg-gray-100 rounded-xl">
              {message.body}
            </div>
          </Tooltip>
          {
            !message.isSupplier ?
            <div className="w-8 h-8 rounded-full bg-gray-100"></div> : null
          }
        </div>
      )
    })

    return tmp
  }

  const handleSubmit = () => {
    setLoading(true)
    console.log(messageContainer.current)
    instance({
      method: 'post',
      url: `/Message/reply`, 
      data: {
        body: message,
        messageThreadId: location.state.id
      }
    }).then(res => {
      setData(prev => ([{body: message, createdAt: moment().format("YYYY-MM-DD HH:mm:ss"), isSupplier: 0}, ...prev, ]))
      setMessage('')
      setLoading(false)
      messageContainer.current.focus()
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }
   
  return (
    <div className="p-4 container mx-auto">
      <div className='bg-white rounded-xl shadow max-h-[calc(100vh-72px)] flex flex-col relative overflow-hidden'>
        <div className="bg-white p-4 flex gap-4 border-b">
          <Tooltip title='Go back'>
            <Button onClick={() => navigate(-1)} type="text" className="text-primary" icon={<LeftOutlined />}>
              
            </Button>
          </Tooltip>
          <p className="font-medium text-lg mb-0">{location.state.title}</p>
        </div>
        <div className="p-4 flex flex-col-reverse gap-2 flex-1 overflow-auto">
          {renderMessages()}
        </div>
        <div className="p-4 bg-white flex gap-4 border-t">
          <Input ref={messageContainer} disabled={loading} placeholder="Reply" className="flex-1" value={message} onChange={e => setMessage(e.target.value)} onPressEnter={handleSubmit}></Input>
          <Button icon={<SendOutlined/>} type="text" onPress={handleSubmit} loading={loading}>
            
          </Button>
        </div>
      </div>
    </div>
  )
}

export default MessageDetail