import { Button, Card, Checkbox, Form, Input, Select, Skeleton, Tabs } from "antd"
import { TinyEditor } from "components"
import { useEffect, useState } from "react"
import { instance } from "utils/axios"

const EventGroup = {
  'Supplier Registration': 10,
  'Supplier Validation': 11,
  'Supplier PreQualification': 12,
  'Supplier Qualification': 13,
  EOI: 21,
  RFQ: 31,
  Travel: 41,
  Freight: 51,
  Auction: 61,
  Survey: 71,
  Feedback: 81
}

// const eventRules = {
//   Created: 1,
//   Requested: 2,
//   Approved: 3,
//   Awarded: 4,
//   Rejected: 5,
//   Opened: 6,
//   Edited: 7,
//   Published: 8,
//   Cancelled: 9,
//   Deleted :10,
//   Activated :11,
//   Deactivated: 12,
//   Closed: 13
// }

const GroupForm = ({ownKey, activeKey, type}) => {
  const [ loading, setLoading ] = useState(false)
  const [ eventRuleId, setEventRuleId ] = useState(null)
  const [ eventDirectionId, setEventDirectionId ] = useState(null)
  const [ eventRules, setEventRules ] = useState([])
  const [ eventRuleLoading, setEventRuleLoading ] = useState(true)
  const [ form ] = Form.useForm()

  useEffect(() => {
    setEventRuleId(null)
    setEventDirectionId(null)
    form.setFieldValue("eventRuleId", null)
    form.setFieldValue("systemEventDirectionId", null)
    form.resetFields()
    if(activeKey) {
      fetchEventRule()
    }
  }, [activeKey])

  useEffect(() => {
    if(eventRuleId && eventDirectionId) {
      fetchData()
    }
  }, [eventRuleId, eventDirectionId])

  const fetchEventRule = () => {
    instance({
      method: 'get',
      url: `SystemEvent/group/${activeKey}`
    }).then(res => {
      setEventRules(res.data.responseData)
    }).catch(err => {

    }).then(() => {
      setEventRuleLoading(false)
    })
  }

  const fetchData = () => {
    instance({
      method: 'get',
      url: `/EventTemplate/template?eventGroupId=${activeKey}&systemEventId=${eventRuleId}&systemDirectionId=${eventDirectionId}&wcode=event-template`
    }).then(res => {
      if(res.data.responseData) {
        form.setFieldsValue({
          email: res.data.responseData.email,
          languageId: res.data.responseData.languageId,
          subject: res.data.responseData.subject,
          emailNotification: res.data.responseData.emailNotification ? true : false,
          systemNotification: res.data.responseData.systemNotification ? true: false,
          content: res.data.responseData.content,
          status: res.data.responseData.status ? true: false
        })
      }
      else {
        form.setFieldsValue({
          email: '',
          languageid: null,
          subject: '',
          content: '',
          emailNotification: false,
          systemNotification: false,
          status: false
        })
      }
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const getOptions = () => {
    let tmp = []

    Object.keys(eventRules).map(foo => {
      if(Math.floor(eventRules[foo]/1000) == EventGroup[type] || (Math.floor(eventRules[foo]/1000) == 1 && EventGroup[type] == 2) || (Math.floor(eventRules[foo]/1000) == 1 && EventGroup[type] == 3) || (Math.floor(eventRules[foo]/1000) == 1 && EventGroup[type] == 4)) {
        tmp.push({
          label: foo,
          value: eventRules[foo]
        })
      }
    })
    return tmp
  }

  const handleSubmit = (values) => {
    setLoading(true)
    instance({
      method: 'post',
      url: `/EventTemplate?wcode=event-template`,
      data: {
        ...values,
        eventGroupId: activeKey,
        eventRuleId: eventRuleId,
        systemNotification: values.systemNotification ? 1 : 0,
        emailNotification: values.emailNotification ? 1 : 0,
        status: values.status ? 1 : 0
      }
    }).then(res => {

    }).catch(err => {
      
    }).then(() => {
      setLoading(false)
    })
  }

  const handleChange = (changedValues) => {
    if(Object.keys(changedValues) == "eventRuleId") {
      setEventRuleId(changedValues.eventRuleId)
    }
    if(Object.keys(changedValues) == "systemEventDirectionId") {
      setEventDirectionId(changedValues.systemEventDirectionId)
    }
  }
  
  return (
    <Form 
      form={form}
      disabled={loading}
      onValuesChange={handleChange}
      layout="vertical"
      onFinish={handleSubmit}
      className="grid grid-cols-12 gap-x-3">
      <Form.Item label="Kind:" name="eventRuleId" className="col-span-6">
        <Select loading={eventRuleLoading} options={eventRules.map(foo => ({label: foo.systemEventName, value: foo.systemEventId}))}></Select>
      </Form.Item>
      <Form.Item label="To:" name="systemEventDirectionId" className="col-span-6">
        <Select options={[
          {
            value: 1,
            label: 'To Employee'
          },
          {
            value: 2,
            label: 'To Admin'
          },
          {
            value: 3,
            label: 'To Supplier'
          }
        ]}></Select>
      </Form.Item>
      <Form.Item label="Email from:" name="email" className="col-span-4">
        <Input></Input>
      </Form.Item>
      <Form.Item label="Language:" name='languageId' className="col-span-2"> 
        <Select>
          <Select.Option value={1}>Монгол</Select.Option>
          <Select.Option value={2}>English</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="Email notification:" name="emailNotification" className="col-span-2" valuePropName="checked">
        <Checkbox/>
      </Form.Item>
      {/* <Form.Item label="System notification:" name="pushNotification" className="col-span-3">
        <Input></Input> 
      </Form.Item> */}
      <Form.Item label="System notification:" name="systemNotification" className="col-span-2" valuePropName="checked">
        <Checkbox/>
      </Form.Item>
      <Form.Item label="Status:" name="status" className="col-span-2" valuePropName="checked">
        <Checkbox/>
      </Form.Item>
      {/* <Form.Item label="On admin:" name="onAdmin" className="col-span-4">
        <Checkbox/>
      </Form.Item> */}
      <Form.Item label="Email subject:" name="subject" className="col-span-12">
        <Input.TextArea></Input.TextArea>
      </Form.Item>
      {
        ownKey == activeKey &&
        <Form.Item label="Email content:" name="content" className="col-span-12">
          <TinyEditor name="content" form={form}></TinyEditor>
        </Form.Item>
      }
      <Form.Item className="flex justify-end">
        <Button type="primary" htmlType="submit" loading={loading}>
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}

const EventTemplate = () => {
  const [ data, setData ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const [ activeKey, setActiveKey ] = useState(null)

  useEffect(() => {
    instance({
      method: 'get',
      url: `systemEvent`
    }).then(res => {
      setData(res.data.responseData)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }, [])
  
  return (
    <div className="p-4">
      <div className="grid grid-cols-12 gap-3">
        <div className="col-span-3">
          <Card title="Event template" className="rounded-none" size="small">
            {
              loading ?
              <Skeleton>
                
              </Skeleton>
              :
              <div className="flex flex-col gap-1">
                {
                  data.map((foo, fooIndex) => {
                    return (
                      <Button 
                        key={`active-event-${fooIndex}`} 
                        type="link" 
                        className={activeKey == foo.id ? "w-full flex justify-start text-primary !bg-primary/10" : "w-full flex justify-start text-primary" }
                        onClick={() => setActiveKey(foo.id)}>
                        {foo.name}
                      </Button>
                    )
                  })
                }
              </div> 
            }
          </Card>
        </div>
        <div className="col-span-9">
          {
            activeKey !== null ?
            <Card title="Settings" className="rounded-none" size="small">
              <GroupForm activeKey={activeKey} ownKey={activeKey} type={activeKey}></GroupForm>
            </Card>
            :
            <div className="text-center font-semibold">
              Please select event template type
            </div>
          }
        </div>
      </div>
      {/* <Card title="Event templates">
        <Tabs
          items={Object.keys(EventGroup).map((foo, fooIndex) => ({key: fooIndex, label: foo, children: <GroupForm activeKey={activeKey} ownKey={fooIndex} type={foo}></GroupForm>}))}
          onChange={(key) => setActiveKey(key)}>
          
        </Tabs>
      </Card> */}
    </div>
  )
}


export default EventTemplate