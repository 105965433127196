import React, { useContext, useEffect } from 'react';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Form, Input, Collapse, Tabs, DatePicker, Select, Upload, Button, InputNumber, TreeSelect, Switch, Checkbox, Divider, message, TimePicker } from 'antd';
import ISelect from 'components/Select';
import Map from 'components/Map';
import TinyEditor from 'components/TinyEditor';
import dayjs from 'dayjs';
import { AuthContext } from 'contexts';
import { instance } from 'utils/axios';

const { Panel } = Collapse
const { Dragger } = Upload
const { RangePicker } = DatePicker

const CustomForm = ({fields, className, editData, length, children, ...restProps}) => {
  const [antForm] = Form.useForm()
  const form = restProps.form || antForm
  const authContext = useContext(AuthContext)

  useEffect(() => {
    if(editData){
      form.setFieldsValue(editData)
      fields.map((item) => {
        if(item.type === 'date' && editData[item.name]){
          form.setFieldValue(item.name, dayjs(editData[item.name]))
        }
        if(item.type === 'time' && editData[item.name]){
          form.setFieldValue(item.name, dayjs(editData[item.name]))
        }
        if(item.type == "rangePicker" && editData[item.name]) {
          form.setFieldValue(item.name, [dayjs(editData['startDate']), dayjs(editData['endDate'])])
        }
      })
    }
  },[editData])

  const getFillPercent = (fields) => {
    let values = form.getFieldsValue()
    let filled = 0;
    let percent = 0
    if(fields){
      fields.map((field) => {
        if(values[`${field.name}`]){
          filled += 1
        }
      })
      percent = parseInt((filled * 100)/fields.length);
    }
    return `${percent} %`
  }

  const fixFiles = (_files) => {
    let tmp = []

    if(typeof _files == 'object') {
      _files.map((foo, fooindex) => {
        tmp.push({
          uid: fooindex,
          name: foo.split("/").pop(),
          status: 'done',
          url: `${process.env.REACT_APP_UPLOAD_URL}/${foo}`,
        })
      })
    }
    else if(typeof _files == "string") {
      tmp.push({
        uid: '-1',
        name: _files.split("/").pop(),
        status: 'done',
        url: `${process.env.REACT_APP_UPLOAD_URL}/${_files}`,
      })
    }
    
    return tmp 
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const renderFormItem = (_field, _fieldIndex) => {
    switch (_field.type) {
      case 'collapse':
        return (
          <Collapse defaultActiveKey={['0']} key={`form-collapse-${_fieldIndex}`} {..._field} >
            {
              _field.children?.map((item, itemIndex) => (
                <Panel
                  key={`${itemIndex}`}
                  header={
                    <Form.Item className='mb-0' shouldUpdate={(pre, cur) => pre[item.name] !== cur[item.name]}>
                      <span>{item.label} - {getFillPercent(item.children)}</span>
                    </Form.Item>
                  }
                >
                  <div className="grid grid-cols-12 gap-4">
                    {
                      item.children && item.children.map((child, childIndex) => (
                        renderFormItem(child, childIndex)
                      ))
                    }
                  </div>
                </Panel>
              ))
            }
          </Collapse>
        );
      case 'tabs':
        return (
          <Tabs>
          </Tabs>
        )
      case 'editor':
        return (
          <Form.Item name="content" {..._field}>
            <TinyEditor editData={editData} name={_field.name} disabled={restProps.disabled} {..._field.inputProps} form={form} />
          </Form.Item>
        )
      case 'dependent':
        return (
          <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} noStyle shouldUpdate={(preValue, curValue) => preValue[_field.dependentName] !== curValue[_field.dependentName]} className={_field.className} >
            {
              ({getFieldValue}) => {
                return (
                  <>
                  {
                    getFieldValue(_field.dependentName) != null &&
                    (getFieldValue(_field.dependentName) == _field.dependentValue) ?
                    _field.children && _field.children.map((child, childIndex) => (
                      renderFormItem(child, childIndex)
                    ))
                    :
                    null
                  }
                </>
                )
              }
            }
          </Form.Item>
        )
      case 'date':
        return (
          <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
            <DatePicker 
              className='w-full'
              {..._field.inputProps}
              />
          </Form.Item>
        )
      case 'rangePicker': 
        return (
          <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
            <RangePicker
              className='w-full'
              {..._field.inputProps}/>
          </Form.Item>
        )
      case 'time':
        return (
          <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
            <TimePicker format='HH:mm' picker='time' className='w-full' {..._field.inputProps} />
          </Form.Item>
        )
      case 'select':
        if(_field.dependentIndex) {
          return (
            <Form.Item noStyle shouldUpdate={(preValue, curValue) => preValue[_field.dependentIndex] !== curValue[_field.dependentIndex]} className={_field.className}>
              { ({getFieldValue, setFieldValue}) => {
                return (
                  <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
                    <ISelect
                      setFieldValue={setFieldValue}
                      getFieldValue={getFieldValue}
                      _field={_field}
                      optionsUrl={_field.inputProps.optionsUrl}
                      dependentValue={getFieldValue(_field.dependentIndex)}
                      placeholder={`${_field.label}`}
                      {..._field.inputProps}
                    />
                  </Form.Item>
                )
              }}
            </Form.Item>
          )
        }
        else {
          if(_field.name === 'status' && !editData){
            form.setFieldValue('status', 1)
          }
          return (
            <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
              <Select
                {..._field.inputProps}
              />
            </Form.Item>
          )
        }
      case 'inputSelect':
        return (
          <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
            <Select
              allowClear
              showSearch
              filterOption={(input, option) => option.text.toLowerCase().includes(input.toLowerCase())}
              {..._field.inputProps} />
          </Form.Item>
        )
      case 'treeSelect':
        return (
          <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
            <TreeSelect  {..._field.inputProps}/>
          </Form.Item>
        )
      case 'map':
        return (
          <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
            <Map {..._field.inputProps} value={form.getFieldValue(_field.name)} onChange={form.setFieldValue} disabled={restProps.disabled}/>
          </Form.Item>
        )
      case 'file':
        return (
          <div className={`flex gap-5  items-start ${_field.className}`}>
            {/* {
              editData && editData[_field.name] ?
              <img className='max-w-[100px]' src={`${process.env.REACT_APP_CDN_URL}${editData[_field.name]}`}/>
              :
              null
            } */}
            <Form.Item valuePropName="file" getValueFromEvent={normFile} key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
              <Dragger 
                defaultFileList={editData && editData[_field.name] ? fixFiles(editData[_field.name]) : []} 
                maxCount={1} 
                action={`${process.env.REACT_APP_MAIN_API_URL}File/upload`}
                headers={{
                  Authorization: `Bearer ${authContext.state.token}`,
                }}
                onRemove={(file) => {
                  instance({
                    method: 'delete',
                    url: `File/remove`,
                    data: file.response.responseData
                  })
                }}
                {..._field.inputProps}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                  banned files.
                </p>
                {/* <Button icon={<UploadOutlined />}>Select file</Button> */}
              </Dragger>
            </Form.Item>
          </div>
        )
      case 'listFile':
        return (
          <Form.Item valuePropName="file" key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
              <Upload beforeUpload {..._field.inputProps}>
                <Button icon={<UploadOutlined />}>Select file</Button>
              </Upload>
          </Form.Item>
        )
      case 'password':
        return (
          <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
            <Input.Password />
          </Form.Item>
        )
      case 'switch':
        return (
          <Form.Item valuePropName="checked" key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
            <Switch />
          </Form.Item>
        )
      case 'textarea':
        return (
          <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
            <Input.TextArea placeholder={`${_field.label}`} {..._field.inputProps} />
          </Form.Item>
        )
      case 'number':
        // if(_field.name === 'orderid' && !editData){
        //   form.setFieldValue(_field.name, length+1)
        // }
        return (
          <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
            <InputNumber
              controls={false}
              placeholder={`${_field.label}`}
              {..._field.inputProps}
            />
          </Form.Item>
        )
      case 'price':
        return(
          <Form.Item
            key={`form-item-${_field.name}-${_fieldIndex}`}
            {..._field}
          >
            <InputNumber
              controls={false}
              formatter={value => `${new Intl.NumberFormat('en-US').format(value)}`}
              placeholder={`${_field.label}`}
              {..._field.inputProps}
            />
          </Form.Item>
        )
      case 'percent':
        return (
          <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
            <InputNumber
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace('%', '')}
              placeholder={`${_field.label}`}
              {..._field.inputProps}
            />
          </Form.Item>
        )
      case 'checkbox':
        return (
          <Form.Item
            key={`form-item-${_field.name}-${_fieldIndex}`}
            valuePropName="checked"
            {..._field}
            label={_field.label}
          >
            <Checkbox
              {..._field.inputProps}
            >
              {/* {_field.label} */}
            </Checkbox>
          </Form.Item>
        );
      case 'divider': 
          return (
            <Divider className='col-span-12'>
  
            </Divider>
          )
      case 'component':
        return (_field.component)
      default:
        return (
          <Form.Item
            key={`form-item-${_field.name}-${_fieldIndex}`}
            {..._field}>
            <Input placeholder={`${_field.label}`} {..._field.inputProps} />
          </Form.Item>
        )
    }
  }

  return (
    <Form
      scrollToFirstError={true}
      className={`grid grid-cols-12 ${className}`}
      {...restProps}
      initialValues={{...restProps.initialValues, content: ''}}>
      {
        fields.map((item, itemIndex) => renderFormItem(item, itemIndex))
      }
      {
        children
      }
    </Form>
  );
}

CustomForm.propTypes = {
  fields: PropTypes.array,
}

CustomForm.defaultProps = {
  fields: [],
}


export default CustomForm;
