import { Button, Checkbox, Form } from "antd"
import { useEffect, useState } from "react"
import { useOutletContext, useParams } from "react-router-dom"
import { instance } from "utils/axios"


const SupplierType = () => {
  const [ loading, setLoading ] = useState(false)
  const [ form ] = Form.useForm()
  const { supplierId } = useParams()
  const [ data ] = useOutletContext()

  useEffect(() => {
    form.setFieldsValue({
      isTravel: data.supplierInfo.isTravel ? true : false,
      isFreight: data.supplierInfo.isFreight ? true : false
    })
  }, [])
  
  const handleFinish = (values) => {
    setLoading(true)
    instance({
      method: 'put',
      url: `/Supplier/tendertype?wcode=supplier-detail`,
      data: {
        id: supplierId,
        isTravel: values.isTravel ? 1 : 0,
        isFreight: values.isFreight ? 1 : 0
      }
    }).then(() => {

    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }
  
  return (
    <div className="bg-white p-4">
      <Form
        form={form}
        onFinish={handleFinish}
        layout="vertical"
        className="flex gap-4">
        <Form.Item label="Travel supplier" name="isTravel" valuePropName="checked">
          <Checkbox></Checkbox>
        </Form.Item>
        <Form.Item label="Freight supplier" name="isFreight" valuePropName="checked">
          <Checkbox></Checkbox>
        </Form.Item>
      </Form>
      <div className="flex justify-end">
        <Button type="primary" onClick={() => form.submit()} loading={loading}>Save</Button>
      </div>
    </div>
  )
}

export default SupplierType