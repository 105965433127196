import { AutoComplete, Button, Form, Input, InputNumber, Modal, Select, Table } from "antd"
import { TinyEditor } from "components"
import { createContext, memo, useContext, useEffect, useRef, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { instance } from "utils/axios";
import { disqualifiedColumns, expandedDetailColumns, expandedSuppliersColumns, qualifiedColumns, suppliersColumns, totalColumns } from "./rfqFormColumns";

const EditableContext = createContext(null);

const EditableRow = ({ index, ...props }) => {
  return (
    <tr {...props} />
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  type,
  options,
  parent,
  count,
  format,
  ...restProps
}) => {
  const [editing, setEditing] = useState(true);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  
  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={[record.name, dataIndex]}
      > 
        {
          type && type == 'select' ?
          <AutoComplete 
            options={options} 
            ref={inputRef}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }>
            <Input.TextArea>

            </Input.TextArea>
          </AutoComplete>
          :
          <Input.TextArea ref={inputRef}/>
        }
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
      >
        {children}
      </div>
    );
  }
  else {
    if(count) {
      childNode = record.name+1
    }
    else if(record) {
      childNode = form.getFieldValue([parent, record.name, dataIndex])
      if(format == 'currency') {
        childNode = childNode?.toLocaleString("en-US")
      }
    }
  }
  return (
    <td {...restProps}>
      {childNode}
    </td>
  )
};

const fixSuppliersData = (_data, selected) => {
  let tmp = []

  _data.map(_supplier => {
    let sum = 0;
    _supplier.details.map((foo, fooIndex) => {
      if(selected.find(ai => ai == foo.id)) {
        sum += foo.totalPrice
      }
    })
    _supplier.sum = 0
  })
  return _data.sort((a,b) => b.sum-a.sum)
}

const RFQForm = ({rawData, data, selected, closeModal, handleSubmit}) => {
  const [ suppliersData, setSuppliersData ] = useState(fixSuppliersData(rawData.suppliers, selected))
  const [ details, setDetails ] = useState(rawData.detail ?? [])
  const [ disqualifiedData, setDisqualifiedData ] = useState([])
  const [ total, setTotal ] = useState([])
  const { id } = useParams() 
  const [ form ] = Form.useForm()
  const [ outletData ] = useOutletContext()
  

  const findDisqualifiedData = () => {
    let tmp = []
    rawData.suppliers.map((foo) => foo.details.map(_detail => {
      if(selected.findIndex(el => el == _detail.id) != -1) {
        tmp.push(_detail.tenderDetailId)
      }
    }))
    form.setFieldValue("disqualifiedData", rawData.detail.filter(foo => !tmp.some(el => el == foo.id)))
  }

  const findQualifiedData = () => {
    let tmp = []
    rawData.suppliers.map((foo) => foo.details.map(_detail => {
      if(selected.findIndex(el => el == _detail.id) != -1) {
        tmp.push({...rawData.detail.find(foo => foo.id == _detail.tenderDetailId), ..._detail, commentText: _detail.comment, _supplier: foo.name })
      }
    }))
    form.setFieldValue("supplierDetails", tmp)
  }
  
  useEffect(() => {
    if(data.total) {
      form.setFieldsValue(data)
    }
    else {
      findDisqualifiedData()
      findQualifiedData()
      fetchTotal()
      fetchUSD()
      form.setFieldValue("suppliersData", fixSuppliersData(rawData.suppliers, selected).map(foo => {
        return ({
          ...foo,
          count: foo.details.filter(ai => selected.includes(ai.id)).length,
          value: foo.details.filter(ai => selected.includes(ai.id)).reduce((a,b) => a+b.totalPrice, 0),
          commentText: 'Disqualified as per agreed criteria'
        })
      }).filter(foo => foo.count == 0))
    }
  }, [])

  const fetchTotal = () => {
    instance({
      method: 'get',
      url: `/Tenderrfq/total/${id}?wcode=tender-detail`
    }).then(res => {
      let tmp = []
      tmp.push({
        title: 'Total Suppliers invited to RFQ',
        data: res.data.responseData.total
      })
      tmp.push({
        title: 'Responded suppliers to RFQ',
        data: res.data.responseData.statSubmitted
      })
      tmp.push({
        title: 'Not participated',
        data: res.data.responseData.statNotResponded
      })
      form.setFieldValue("total", tmp.map(foo => ({...foo, total: foo.data.length})))
    }).catch(err => {

    })
  }

  const fetchUSD = () => {
    if(outletData.usdRate && outletData.usdRate != 1) {
      form.setFieldValue("usdRate", outletData.usdRate ?? 1)
    }
    else {
      instance({
        method: 'get',
        url: `/Utils/usdrate`
      }).then(res => {
        form.setFieldValue("usdRate", res.data.responseData ?? 1)
      })
    }
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  
  const columns = (_columns, parent) => {
    return _columns.map((col) => {
      if (!col.editable) {
        return {
          ...col,
          onCell: (record) => ({
            record,
            dataIndex: col.dataIndex,
            parent: parent
          })
        }
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          type: col.type,
          options: col.options,
          dataIndex: col.dataIndex,
          title: col.title,
        }),
      };
    });
  }

  const handleFinish = (values) => {
    // console.log(values)
    handleSubmit(values)
  }

  return (
    <>
    <Form
      layout="vertical"
      form={form}
      onFinish={handleFinish}>
      <EditableContext.Provider value={form}>
        <Form.Item name="usdRate" label="Exchange rate:">
          <InputNumber min={1} className="w-full"/>
        </Form.Item>
        <Form.Item name="shortReport" label="Short background:">
          <Input.TextArea placeholder="For example: (explain purpose of purchase, for where, for what work, etc)"/>
        </Form.Item>
        <Form.Item name="summaryReport" label="Recommendation:">
          <TinyEditor form={form} name="summaryReport"/>
        </Form.Item>
        <div className="mb-4">
          <Form.List name="total">
            {(fields, {}, {errors}) => {
              return (
                <Table
                  size="small"
                  rowClassName={() => 'editable-row'}
                  dataSource={fields}
                  expandable={{
                    expandedRowRender: rec => {
                      return (
                        <div>
                          <Table
                            columns={expandedSuppliersColumns}
                            dataSource={form.getFieldValue(["total", rec.name]).data}
                            pagination={false}>

                          </Table>
                        </div>
                      )
                    }
                  }}
                  rowKey={(row, index) => {
                    return index
                  }}
                  pagination={false}
                  components={components}
                  bordered={true}
                  columns={columns(totalColumns, 'total')}>

                </Table>
              )
            }}
          </Form.List>
        </div>
        <div className="mb-4">
          <QualifiedItems></QualifiedItems>
        </div>
        <div className="mb-4">
          <DisqualifiedTable/>
        </div>
        <div className="mb-4">
          <div className="mb-2">
            <label className="">Dis-qualified details total</label>
          </div>
          <Form.List name="suppliersData">
            {(fields, {}, {errors}) => {
              return (
                <Table
                  size="small"
                  rowClassName={() => 'editable-row'}
                  dataSource={fields}
                  rowKey={(row, index) => {
                    return row.name
                  }}
                  pagination={false}
                  components={components}
                  bordered={true}
                  columns={columns(suppliersColumns(selected), 'suppliersData')}>

                </Table>
              )
            }}
          </Form.List>
        </div>
        <div className="flex gap-4 justify-end">
          <Button onClick={() => closeModal()}>Close</Button>
          <Button type="primary" htmlType="submit">Next</Button>
        </div>
      </EditableContext.Provider>
    </Form>
    
    </>
  )
}


const QualifiedItems = memo(() => {
  const [ open, setOpen ] = useState(false)
  const [ selectedRows, setSelectedRows ] = useState([])
  const [ justificationForm ] = Form.useForm()
  const form = useContext(EditableContext);
  
  const columns = (_columns, parent) => {
    return _columns.map((col) => {
      if (!col.editable) {
        return {
          ...col,
          onCell: (record) => ({
            record,
            count: col.count,
            dataIndex: col.dataIndex,
            parent: parent,
            format: col.format
          })
        }
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          type: col.type,
          format: col.format,
          options: col.options,
          dataIndex: col.dataIndex,
          title: col.title,
        }),
      };
    });
  }
  
  const rowSelection = {
    onChange: (selectedRowKeys, _selectedRows) => {
      setSelectedRows(_selectedRows.map(foo => foo.name))
    },
    // onSelect: (record, selected, selectedRows, nativeEvent) => {
    //   console.log(selectedRows)
    //   setSelectedRows(selectedRows)
    // },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
    selectedRowKeys: selectedRows,
    fixed: true
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleFinish = (values) => {
    let tmp = form.getFieldValue("supplierDetails")
    selectedRows.map(foo => {
      tmp[foo].commentText = values.justification
    })
    form.setFieldValue("supplierDetails", tmp)
    justificationForm.resetFields()
    setOpen(false)
    setSelectedRows([])
  }
  
  return (
    <>
      <div className="flex justify-between mb-2 items-center">
        <label>Award details</label>
        <Button onClick={() => setOpen(true)}>Bulk justification</Button>
      </div>
      <Form.List name="supplierDetails">
        {(fields, {}, {errors}) => {
          return (
            <Table
              virtual={fields > 50}
              scroll={{
                y: 500,
                x: 1000,
              }}
              rowSelection={{
                type: 'checkbox',
                columnWidth: 50,
                ...rowSelection
              }}
              size="small"
              rowClassName={() => 'editable-row'}
              dataSource={fields}
              rowKey={(row, index) => {
                return row.name
              }}
              pagination={false}
              components={components}
              bordered={true}
              columns={columns(qualifiedColumns, 'supplierDetails')}>

            </Table>
          )
        }}
      </Form.List>
      <Modal
        title="Justification"
        open={open}
        onOk={() => {justificationForm.submit()}}
        onCancel={() => {
          setOpen(false)
        }}>
        <Form
          form={justificationForm}
          onFinish={handleFinish}>
          <Form.Item name="justification">
            <AutoComplete 
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
              options={[
                {
                  label: 'Recommended because price is the lowest',
                  value: 'Recommended because price is the lowest'
                },
                {
                  label: '1 item is not the lowest but LT is the shortest, end user preffered shortest LT selected',
                  value: '1 item is not the lowest but LT is the shortest, end user preffered shortest LT selected'
                },
                {
                  label: '1 item is not the lowest and alternative choice but EU checked the datasheet and chosen alternative brand',
                  value: '1 item is not the lowest and alternative choice but EU checked the datasheet and chosen alternative brand'
                },
                {
                  label: '1 item is not the lowest but supplier required combined bundle shipment for other 4 items, thus selected',
                  value: '1 item is not the lowest but supplier required combined bundle shipment for other 4 items, thus selected'
                },
              ]}>
              <Input.TextArea>

              </Input.TextArea>
            </AutoComplete>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
})

const DisqualifiedTable = memo(() => {
  const [ open, setOpen ] = useState(false)
  const [ selectedRows, setSelectedRows ] = useState([])
  const [ justificationForm ] = Form.useForm()
  const form = useContext(EditableContext);
  
  const columns = (_columns, parent) => {
    return _columns.map((col) => {
      if (!col.editable) {
        return {
          ...col,
          onCell: (record) => ({
            record,
            count: col.count,
            dataIndex: col.dataIndex,
            parent: parent
          })
        }
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          type: col.type,
          options: col.options,
          dataIndex: col.dataIndex,
          title: col.title,
        }),
      };
    });
  }
  
  const rowSelection = {
    onChange: (selectedRowKeys, _selectedRows) => {
      setSelectedRows(_selectedRows.map(foo => foo.name))
    },
    // onSelect: (record, selected, selectedRows, nativeEvent) => {
    //   console.log(selectedRows)
    //   setSelectedRows(selectedRows)
    // },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
    selectedRowKeys: selectedRows,
    fixed: true
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleFinish = (values) => {
    let tmp = form.getFieldValue("disqualifiedData")
    
    selectedRows.map(foo => {
      tmp[foo].commentText = values.justification
    })
    form.setFieldValue("disqualifiedData", tmp)
    justificationForm.resetFields()
    setOpen(false)
    setSelectedRows([])
  }
  
  return (
    <>
      <div className="flex justify-between mb-2 items-center">
        <label>Dis-qualified details</label>
        <Button onClick={() => setOpen(true)}>Bulk justification</Button>
      </div>
      <Form.List name="disqualifiedData">
        {(fields, {}, {errors}) => {
          return (
            <Table
              // virtual
              scroll={{
                y: 500
              }}
              rowSelection={{
                type: 'checkbox',
                columnWidth: 50,
                ...rowSelection
              }}
              size="small"
              rowClassName={() => 'editable-row'}
              dataSource={fields}
              rowKey={(row, index) => {
                return row.name
              }}
              pagination={false}
              components={components}
              bordered={true}
              columns={columns(disqualifiedColumns, 'disqualifiedData')}>

            </Table>
          )
        }}
      </Form.List>
      <Modal
        title="Justification"
        open={open}
        onOk={() => {justificationForm.submit()}}
        onCancel={() => {
          setOpen(false)
        }}>
        <Form
          form={justificationForm}
          onFinish={handleFinish}>
          <Form.Item name="justification">
            <AutoComplete 
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
              options={[
                {
                  label: 'Excluded if 35% higher or 50% cheaper than historical average price',
                  value: 'Excluded if 35% higher or 50% cheaper than historical average price'
                },
                {
                  label: 'Excluded if single source bid with no historical purchase',
                  value: 'Excluded if single source bid with no historical purchase'
                },
                {
                  label: 'Excluded if no quote received',
                  value: 'Excluded if no quote received'
                },
                {
                  label: 'Excluded if quote difference is unreasonably high',
                  value: 'Excluded if quote difference is unreasonably high'
                },
                {
                  label: 'Excluded if high value and single quote received',
                  value: 'Excluded if high value and single quote received'
                },
              ]}>
              <Input.TextArea>

              </Input.TextArea>
            </AutoComplete>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
})

export default RFQForm