import { Button, Card, Collapse, Drawer, Input, Modal, Skeleton, Space, Table, Tag } from 'antd'
import { MainTable } from 'components'
import { useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useOutletContext, Link, useParams } from 'react-router-dom'
import { SearchOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { MailOutlined, LikeOutlined, DislikeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import AwardModalContent from './AwardModalContent'
import { instance } from 'utils/axios'
import RegretModalContent from './RegretModalContent'

const validationStatusEnum = {
  0: {
    title: 'New',
    color: '#00aaad'
  },
  1: {
    title: 'Requested',
    color: '#427EC1'
  },
  2: {
    title: 'Returned',
    color: '#D55727'
  },
  3: {
    title: 'Checked',
    color: '#009A72'
  },
}

const prequalificationStatusEnum = {
  0: {
    title: 'Not qualified',
    color: '#D55727'
  },
  1: {
    title: 'Qualified',
    color: '#009A72'
  }
}

const columns = ({currentPage, handleAttachmentClick, setType, tenderType, status}) => {
  return [
    {
      title: '#',
      dataIndex: 'No',
      key: 'No',
      width: 40,
      align: 'center',
      render: (text, row, i) => (
        <span>{currentPage+i+1}</span>
      )
    },
    {
      title: 'Supplier name',
      dataIndex: 'name',
      key: 'name',
      filterType: "search",
      render: (text, row) => (
        <Link className='text-link hover:underline hover:text-link' to={`/supplier/${row.id}`}>{text}</Link>
      )
    },
    {
      title: 'Email',
      dataIndex: 'companyEmail',
      key: 'companyEmail',
      filterType: "search",
    },
    {
      title: 'Vendor Number',
      dataIndex: 'vendorNumber',
      key: 'vendorNumber',
      align: 'center',
      filterType: "search",
    },
    {
      title: 'Prequalification Status',
      dataIndex: 'preQualificationStatus',
      key: 'preQualificationStatus',
      align: 'center',
      render: cell => (
        <Tag color={prequalificationStatusEnum[cell].color}>{prequalificationStatusEnum[cell].title}</Tag>
      )
    },
    status > 2 &&
    {
      title: 'Participated',
      dataIndex: 'responseStatus',
      key: 'responseStatus',
      align: 'center',
      filters: [
        {
          text: 'Participated',
          value: 1
        },
        {
          text: 'Not participated',
          value: 0
        },
      ],
      onFilter: (value, record) => record.responseStatus === value,
      render: cell => (
        <Tag color={cell == 0 ? '#D55727': '#009A72'}>{cell == 0 ? 'Not participated' : 'Participated'}</Tag>
      )
    },
    // tenderType != "eoi" &&
    // {
    //   title: 'Award Status',
    //   dataIndex: 'awardStatus',
    //   key: 'awardStatus',
    //   align: 'center',
    //   filters: [
    //     {
    //       text: 'Awarded',
    //       value: 1
    //     },
    //     {
    //       text: 'Not awarded',
    //       value: 0
    //     },
    //   ],
    //   onFilter: (value, record) => record.awardStatus === value,
    //   // sorter: (a, b) => a.awardStatus - b.awardStatus,
    //   // sortDirections: ['descend', 'ascend'],
    //   render: cell => (
    //     <Tag color={prequalificationStatusEnum[cell].color}>{cell == 1 ? 'Awarded' : 'Not awarded'}</Tag>
    //   )
    // },
    {
      title: 'Licenses',
      dataIndex: 'lisenceInfo',
      key: 'lisenceInfo',
      align: 'center',
      render: cell => cell?.length > 0 ? <Button type="link" size='small' onClick={() => {
        setType('licenses')
        handleAttachmentClick(cell)
      }}>Licenses</Button> : ''
    },
    {
      title: 'Data sheets',
      dataIndex: 'datasheets',
      key: 'datasheets',
      align: 'center',
      render: cell => cell?.filter(foo => foo.file?.length > 0).length > 0 ? <Button type="link" size='small' onClick={() => {
        setType('attachment')
        handleAttachmentClick(cell)
      }}>Data sheets</Button> : ''
    },
  ]
}

const TenderSuppliers = () => {
  const [ data ] = useOutletContext()  
  const [ selectedRow, setSelectedRow ] = useState(null)
  const [ searchText, setSearchText ] = useState('');
  const [ searchedColumn, setSearchedColumn ] = useState('');
  const [ type, setType ] = useState(null)
  const [ awardModalOpen, setAwardModalOpen ] = useState(false)
  const [ regretModalOpen, setRegretModalOpen ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const { id } = useParams()
  const searchInput = useRef(null);
  
  const handleAttachmentClick = (_data) => {
    setSelectedRow(_data)
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSendAwardLetter = () => {
    setLoading(true)
    instance({
      method: 'get',
      url: `/Tender/letter/sendaward/${id}?wcode=tender`
    }).then(res => {
      setAwardModalOpen(false)
      data.fetchData()
    }).finally(() => {
      setLoading(false)
    })
  }

  const handleSendRegretLetter = () => {
    setLoading(true)
    instance({
      method: 'get',
      url: `/Tender/letter/sendregret/${id}?wcode=tender`
    }).then(res => {
      setRegretModalOpen(false)
      data.fetchData()
    }).finally(() => {
      setLoading(false)
    })
  }
  
  if(!data) {
    return (
      <Skeleton active>

      </Skeleton>
    )
  }
  return (
    <Card title="Suppliers information" size='small'>
      <div className="grid grid-cols-4 gap-3">  
        <Card className="">
          <div className="flex justify-between items-center">
            <div>
              <div className="text-mute text-md font-semibold">
                Invited
              </div>
              <div className="text-2xl font-bold text-black leading-none">
                {data.stat.statTotal}
              </div>
            </div>
            <div>
              <div className="w-[50px] flex justify-center items-center bg-gray-200 rounded-xl aspect-square">
                <MailOutlined className='text-2xl text-mute'/>
              </div>
            </div>
          </div>
        </Card>
        <Card>
          <div className="flex justify-between items-center">
            <div>
              <div className="text-green-600 text-md font-semibold">
                Submitted
              </div>
              <div className="text-2xl font-bold text-black leading-none">
                {data.stat.statSubmitted}
              </div>
            </div>
            <div>
              <div className="w-[50px] flex justify-center items-center bg-[#bee1cb] rounded-xl aspect-square">
                <LikeOutlined className='text-2xl text-green-600'/>
              </div>
            </div>
          </div>
        </Card>
        <Card>
          <div className="flex justify-between items-center">
            <div>
              <div className="text-amber-500 text-md font-semibold">
                Not interested
              </div>
              <div className="text-2xl font-bold text-black leading-none">
                {data.stat.statNotInterested}
              </div>
            </div>
            <div>
              <div className="w-[50px] flex justify-center items-center bg-[#f3e6cf] rounded-xl aspect-square">
                <DislikeOutlined className='text-2xl text-amber-500'/>
              </div>
            </div>
          </div>
        </Card>
        <Card>
          <div className="flex justify-between items-center">
            <div>
              <div className="text-rose-500 text-md font-semibold">
                Not responded
              </div>
              <div className="text-2xl font-bold text-black leading-none">
                {data.stat.statResponded}
              </div>
            </div>
            <div>
              <div className="w-[50px] flex justify-center items-center bg-[#FFDADA] rounded-xl aspect-square">
                <EyeInvisibleOutlined className='text-2xl text-rose-500'/>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div className="mb-3">
        {
          data.type != 1 && data.status == 4 && ( !data.isAwardLetterSent || !data.isRegretLetterSent) &&
          <div className="flex gap-3 justify-end pt-3">
            {
              !data.isAwardLetterSent &&
              <Button type="primary" onClick={() => setAwardModalOpen(true)}>
                Send award letter
              </Button>
            }
            {
              !data.isRegretLetterSent &&
              <Button type="primary" onClick={() => setRegretModalOpen(true)}>
                Send regret letter
              </Button>
            }
          </div>
        }
      </div>
      <MainTable         
        borderHide
        hideHeader={true}
        dataTable={data.suppliers.sort((a,b) => b.responseStatus - a.responseStatus)}
        columns={columns({currentPage: 0, handleAttachmentClick, setType, tenderType: data.tendertype, status: data.status})}
      />
      <Drawer 
        width={720}
        title="Attachmets" 
        open={type == "attachment" ? true : false} 
        onClose={() => {
          setType(null)
          setSelectedRow(null)
        }}>
        {
          selectedRow &&
          <Table
            pagination={false}
            size='small'
            dataSource={selectedRow}
            columns={[
              {
                title: '#',
                dataIndex: 'index',
                render: (cell, row, index) => index+1
              },
              {
                title: 'Material code',
                dataIndex: 'materialNumber',
                filterSearch: true,
                ...getColumnSearchProps('materialNumber'),
              },
              {
                title: 'Description',
                dataIndex: 'description',
              },
              {
                title: 'File',
                dataIndex: 'file',
                render: cell => <a target='_blank' href={process.env.REACT_APP_CDN_URL+cell} className='text-link'>Preview</a>
              }
            ]}>

          </Table>
        }
      </Drawer>
      <Drawer 
        width={720}
        title="Licenses" 
        open={type == "licenses" ? true : false} 
        onClose={() => {
          setType(null)
          setSelectedRow(null)
        }}>
        {
          selectedRow &&
          <Table
            pagination={false}
            size='small'
            dataSource={selectedRow}
            columns={[
              {
                title: '#',
                dataIndex: 'index',
                render: (cell, row, index) => index+1
              },
              {
                title: 'Licenses Type',
                dataIndex: 'licenseTypeName',
                filterSearch: true,
                ...getColumnSearchProps('licenseTypeName'),
              },
              {
                title: 'Manufacturer',
                dataIndex: 'manufacturerName',
              },
              {
                title: 'Expire date',
                dataIndex: 'expireDate',
                render: cell => dayjs(cell).format("YYYY/MM/DD")
              },
              {
                title: 'File',
                dataIndex: 'fileName',
                render: cell => <a target='_blank' href={process.env.REACT_APP_CDN_URL+cell} className='text-link'>Preview</a>
              }
            ]}>

          </Table>
        }
      </Drawer>
      <Modal
        title="Send award letter"
        open={awardModalOpen}
        onCancel={() => setAwardModalOpen(false)}
        destroyOnClose
        width={'80%'}
        style={{
          top: 20
        }}
        okText="Send"
        onOk={handleSendAwardLetter}>
        <AwardModalContent>

        </AwardModalContent>
      </Modal>
      <Modal
        title="Send regret letter"
        open={regretModalOpen}
        onCancel={() => setRegretModalOpen(false)}
        destroyOnClose
        width={'80%'}
        style={{
          top: 20
        }}
        okText="Send"
        onOk={handleSendRegretLetter}>
        <RegretModalContent>

        </RegretModalContent>
      </Modal>
    </Card>
  )
}

export default TenderSuppliers