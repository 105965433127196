import { useState, useEffect } from 'react'
import { instance } from 'utils/axios'
import { Button, Dropdown, Space, Form as AntForm, Modal, Tag } from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import { MainTable, Form } from 'components'
import { Link } from 'react-router-dom'
import fields from './fields'
import UserMenus from './UserMenus'

const items = ({row, handleEdit, handleDelete}) => {  
  return (
    [
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleEdit(row)
        }}>Edit</a>,
        key: 0
      },
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleDelete(row.id)
        }}>Delete</a>,
        key: 1
      },
    ]
  )
}

const columns = ({currentPage, handleEdit, handleDelete}) => {
  return ([
    {
      title: '#',
      dataIndex: 'No',
      key: 'No',
      width: 40,
      align: 'center',
      render: (text, row, i) => (
        <span>{currentPage ? currentPage+i+1 : i+1}</span>
      )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      filterType: "search",
      render: (text, row) => (
        <Link className='text-link hover:underline hover:text-link' to={`${row.id}`} state={row}>{text}</Link>
      )
    },
    {
      title: 'Team name',
      dataIndex: 'teamName',
      key: 'teamName',
      filterType: "search",
    },
    {
      title: 'Permissions',
      dataIndex: 'permissions',
      key: 'permissions',
      render: (cell) => cell.map(foo => (<Tag>{foo.name}</Tag>))
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 80,
      render: (text, row) => (
        <Dropdown 
          trigger={['click']} 
          menu={{items: items({row, handleEdit, handleDelete}),}}>
          <a onClick={e => e.preventDefault()}>
            <Space>
              <MoreOutlined className='text-xl'/>
            </Space>
          </a>
        </Dropdown>
      )
    },
  ])
}

const UserPosition = () => {
  const [ data, setData ] = useState([])
  const [ permissions, setPermissions ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const [ currentPage, setCurrentPage ] = useState(0)
  const [ open, setOpen ] = useState(false)
  const [ editData, setEditData ] = useState(null)
  const [ isEdit, setIsEdit ] = useState(false)
  const [ form ] = AntForm.useForm();
  const [ modal, contextHolder ] = Modal.useModal();

  useEffect(() => {
    fetchData()
    fetchPermissions()
  }, [])

  const fetchPermissions = () => {
    instance({
      method: 'get',
      url: `/UserPermission`
    }).then(res => {
      setPermissions(res.data.responseData.map(foo => ({value: foo.id, label: foo.name})))
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const fetchData = () => {
    instance({
      method: 'get',
      url: `UserPosition/List`
    }).then(res => {
      setData(res.data.responseData)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const handleDelete = (id) => {
    modal.confirm({
      title: 'Warning!',
      content: 'You are about to delete this row of data',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: 'delete',
            url: `/UserPosition/${id}`
          }).then((res) => {
            resolve()
          }).catch((err) => {
            reject()
          }).then(() => fetchData())
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

  const handleSubmit = (values) => {
    setLoading(true)
    if(editData){
      instance({
        method: 'put',
        url: `/UserPosition`,
        data: {
          ...values,
          id: editData.id,
          permissions: values.permissions.map((foo) => ({id: foo, state: 1}))
        }
      }).then((res) => {
        handleCloseModal()
        fetchData()
      }).catch((err) => {

      }).then(() => setLoading(false))
    }
    else{
      instance({
        method: 'post',
        url: `/UserPosition`,
        data: {
          ...values,
          permissions: values.permissions.map((foo) => ({id: foo, state: 1}))
        }
      }).then((res) => {
        handleCloseModal()
        fetchData()
      }).catch((err) => {

      }).then(() => setLoading(false))
    }
  }

  const handleAdd = () => {
    setIsEdit(true)
    setEditData(null)
    setOpen(true)
  }

  const handleEdit = (row) => {
    setEditData({
      ...row,
      permissions: row.permissions && row.permissions.map((foo) => foo.permissionId)
    })
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
    form.resetFields()
  }
  
  return (
    <div className='p-4'>
      <MainTable
        dataTable={data}
        columns={columns({currentPage, handleEdit, handleDelete})}
        loading={loading}
        handleAdd={handleAdd}
        expandable={{
          expandedRowRender: rec => <UserMenus record={rec}></UserMenus>
        }}>
      
      </MainTable>
      <Modal
        title={editData ? <div className='flex items-center justify-start gap-2'>Edit</div> : <div className='flex items-center justify-start gap-2'>Add</div>}
        open={open}
        width="40%"
        destroyOnClose
        maskClosable={false}
        okText='Save'
        onOk={form.submit}
        onCancel={handleCloseModal}
        footer={
        <div className="flex gap-3">
          <Button onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button type="primary" onClick={form.submit}>
            Save
          </Button>
        </div>
        }
      >
      <Form
        form={form}
        fields={fields(permissions)}
        length={data.length}
        layout="vertical"
        onFinish={handleSubmit}
        editData={editData}
        isEdit={isEdit}
        className='gap-4'
      />
    </Modal>
      {contextHolder}
    </div>
  )
}

export default UserPosition