import { useState, useEffect } from 'react'
import { instance } from 'utils/axios'
import { Button, Dropdown, Space, Form as AntForm, Modal, Tag } from 'antd'
import { MoreOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { MainTable, Form } from 'components'
import { Link } from 'react-router-dom'
import fields from './fields'

const items = ({row, handleEdit, handleDelete}) => {  
  return (
    [
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleEdit(row)
        }}>Edit</a>,
        key: 0
      },
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleDelete(row.id)
        }}>Delete</a>,
        key: 1
      },
    ]
  )
}

const columns = ({currentPage, handleEdit, handleDelete}) => {
  return ([
    {
      title: '№',
      dataIndex: 'No',
      key: 'No',
      width: 40,
      align: 'center',
      render: (text, row, i) => (
        <span>{currentPage ? currentPage+i+1 : i+1}</span>
      )
    },
    {
      title: 'Fullname',
      dataIndex: 'name',
      key: 'name',
      filterType: "search",
      render: (cell, row) => {
        return <span>{row.firstName} {row.lastName}</span>
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      filterType: "search",
    },
    {
      title: 'Team name',
      dataIndex: 'teamName',
      key: 'teamName',
      filterType: "search",
    },
    {
      title: 'Position',
      dataIndex: 'name',
      key: 'name',
      filterType: "search",
    },
    {
      title: 'Permissions',
      dataIndex: 'permissions',
      key: 'permissions',
      render: cell => cell.map(foo => <Tag>{foo.name}</Tag>)
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (cell) => (
        <Tag color={cell == 1 ? '#009A72' : '#D55727'}>
          {cell == 1 ? 'Active' : 'Inactive'}
        </Tag>
      )
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 80,
      render: (text, row) => (
        <Dropdown 
          trigger={['click']} 
          menu={{items: items({row, handleEdit, handleDelete}),}}>
          <a onClick={e => e.preventDefault()}>
            <Space>
              <MoreOutlined className='text-xl'/>
            </Space>
          </a>
        </Dropdown>
      )
    },
  ])
}

const Users = () => {
  const [ data, setData ] = useState([])
  const [ positions, setPositions ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const [ currentPage, setCurrentPage ] = useState(0)
  const [ open, setOpen ] = useState(false)
  const [ editData, setEditData ] = useState(null)
  const [ isEdit, setIsEdit ] = useState(false)
  const [ form ] = AntForm.useForm();
  const [ modal, contextHolder ] = Modal.useModal();

  useEffect(() => {
    fetchData()
    fetchPositions()
  }, [])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `user`
    }).then(res => {
      setData(res.data.responseData)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const fetchPositions = () => {
    instance({
      method: 'get',
      url: `/UserPosition/list`
    }).then(res => {
      setPositions(res.data.responseData.map(foo => ({value: foo.id, label: foo.name})))
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const handleDelete = (id) => {
    modal.confirm({
      title: 'Warning!',
      content: 'You are about to delete this row of data',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: 'delete',
            url: `/User`,
            data: {
              id: id
            }
          }).then((res) => {
            resolve()
          }).catch((err) => {
            reject()
          }).then(() => fetchData())
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

  const handleSubmit = (values) => {
    setLoading(true)
    if(editData){
      instance({
        method: 'put',
        url: `/User`,
        data: {
          ...values,
          id: editData.id,
        }
      }).then((res) => {
        handleCloseModal()
        fetchData()
      }).catch((err) => {

      }).then(() => setLoading(false))
    }
    else{
      instance({
        method: 'post',
        url: `/User`,
        data: {
          ...values,
        }
      }).then((res) => {
        handleCloseModal()
        fetchData()
      }).catch((err) => {

      }).then(() => setLoading(false))
    }
  }

  const handleAdd = () => {
    setIsEdit(true)
    setEditData(null)
    setOpen(true)
  }

  const handleEdit = (row) => {
    console.log(row)
    setEditData(row)
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
    form.resetFields()
  }
  
  return (
    <div className='p-4'>
      <MainTable
        dataTable={data}
        columns={columns({currentPage, handleEdit, handleDelete})}
        loading={loading}
        handleAdd={handleAdd}>
      
      </MainTable>
      <Modal
          title={editData ? <div className='flex items-center justify-start gap-2'><EditOutlined/>Edit</div> : <div className='flex items-center justify-start gap-2'><PlusCircleOutlined/>Add</div>}
          open={open}
          destroyOnClose
          maskClosable={false}
          okText='Save'
          onOk={form.submit}
          onCancel={handleCloseModal}
        >
          <Form
            form={form}
            fields={fields({options: positions})}
            length={data.length}
            layout="vertical"
            onFinish={handleSubmit}
            editData={editData}
            isEdit={isEdit}
            className='gap-4'
          />
        </Modal>
      {contextHolder}
    </div>
  )
}

export default Users