import { Card, Tabs } from "antd"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { instance } from "utils/axios"
import BidReport from './BidReport'
import BidSummary from './BidSummary'
import ApprovalDetail from './ApprovalDetail'

const Review = () => {
  const [ loading, setLoading ] = useState(true)
  const [ mainData, setMainData ] = useState(null)
  const { id } = useParams()

  useEffect(() => {
    instance({
      method: 'get',
      url: `/TenderApproval/rfq/check/${id}`
    }).then(res => {
      setMainData(res.data.responseData)
    }).finally(() => {
      setLoading(false)
    })
  }, [])
  
  if(loading) {
    return (
      <div>
      </div>
    )
  }
  return (
    <div className='grid grid-cols-12 gap-3 relative'>
      <div className="col-span-12">
        <Card size="small" className='sticky top-6'>
          <>
            <div className="grid grid-cols-12 divide-x divide-y border-b border-r">
              <div className='col-span-3 text-sm font-bold border-t border-l px-3'>
                RFQ number: 
              </div>
              <div className="col-span-3 px-3">{mainData.tenderInfo?.code}</div>
              <div className="col-span-3 px-3 font-bold">
                Ticket number (if Pnow):
              </div>
              <div className="col-span-3 px-3 flex">
                {mainData.tenderInfo?.procurementNow}
              </div>
              <div className="col-span-3 px-3 font-bold">
                Recommendation from Purchasing officer:: 
              </div>
              <div className="col-span-3 px-3">{mainData.tenderInfo.buyerFirstName} {mainData.tenderInfo.buyerLastName}</div>
              <div className='col-span-3 px-3 font-bold'>
                Approver as per DFA: 
              </div>
              <div className="col-span-3 px-3">{mainData.approvalSteps.at(-1)?.firstName} {mainData.approvalSteps.at(-1)?.lastName}</div>
              <div className="col-span-3 px-3 font-bold">
                End user name Technical evaluation completed by:
              </div>
              <div className="col-span-3 px-3 flex gap-x-2">
                {
                  mainData.endUsers.map((foo, fooIndex) => {
                    return (<span className="" key={`end-user-${fooIndex}`}>{foo.firstName} {foo.lastName},</span>)
                  })
                }
              </div>
              
              <div className="col-span-3 px-3 font-bold">
                Department/Area:
              </div>
              <div className="col-span-3 px-3 flex gap-x-2">
                {
                  mainData.endUsers.map((foo, fooIndex) => {
                    return (<span className="" key={`end-user-department-${fooIndex}`}>{foo.teamName},</span>)
                  })
                }
              </div>
              
              <div className="col-span-3 px-3 font-bold">
                Total value for approval:
              </div>
              <div className="col-span-3 px-3">{mainData.data?.totalAmount ? mainData.data?.totalAmount.toLocaleString("en-US") : 0}$</div>
              <div className="col-span-3 px-3 font-bold">
                Negotiation outcome:
              </div>
              <div className="col-span-3 px-3 flex flex-col">
                
              </div>
              <div className="col-span-3 px-3 font-bold">
                Total items:
              </div>
              <div className="col-span-3 px-3">{mainData.totalItems.length}</div>
              <div className="col-span-3 px-3 font-bold">
                Total cost reduction:
              </div>
              <div className="col-span-3 px-3 flex flex-col">
                
              </div>
            </div>
          </>
        </Card>
      </div>
      <div className="col-span-12">
        <Tabs
          tabBarStyle={{ margin: 0}}
          items={[
            {
              key: 'bid',
              label: `Bid report`,
              children: <BidReport mainData={mainData}/>
            },
            // {
            //   key: 'bid-summary',
            //   label: `Bid summary`,
            //   children: <BidSummary mainData={mainData}/>
            // },
            {
              key: 'approval',
              label: `Approval detail`,
              children: <ApprovalDetail mainData={mainData}/>
            },
          ]}
          type="card">
          
        </Tabs>
      </div>
    </div>
  )
}

export default Review