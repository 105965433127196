import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { EditOutlined, PlusCircleOutlined, MoreOutlined, SwapOutlined } from '@ant-design/icons'
import { Button, Form as AntForm, Modal, Dropdown, Space, Skeleton, Card, Tooltip, Divider } from 'antd';
import { MainTable, Form } from 'components';
import { instance } from 'utils/axios';
import { message } from 'antd';
import PrequalificationGroupDetail from './prequalificationGroupDetail';
import { twMerge } from 'tailwind-merge';

const fields = () => {
  return [
    {
      label: 'Name',
      name: 'name',
      rules: [
        {required: true, message: 'Name is required!'},
        ({getFieldValue}) => ({
          validator(_,value) {
            if(getFieldValue('name').length < 5) {
              return Promise.reject("Minimum length is 5!")
            }
            return Promise.resolve()
          }
        })
      ],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
  ]
}

const PrequalificationGroup = ({location}) => {
  const [ data, setData ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const [ open, setOpen ] = useState(false)
  const [ editData, setEditData ] = useState(null)
  const [ isEdit, setIsEdit ] = useState(false)
  const [ modal, contextHolder ] = Modal.useModal();
  const [ selectedGroup , setSelectedGroup ] = useState(null)
  const [ fetchingQuestions, setFetchingQuestions ] = useState(false)
  const [ form ] = AntForm.useForm();

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `PreQualificationGroup?wcode=prequalification-group-config-list`
    }).then(res => {
      setData(res.data.responseData)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const handleAdd = () => {
    setEditData(null)
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
    form.resetFields()
  }

  const handleSubmit = (values) => {
    setLoading(true)
    if(editData){
      instance({
        method: 'put',
        url: 'PreQualificationGroup?wcode=prequalification-group-config-list',
        data: {
          ...values,
          id: editData.id
        }
      }).then((res) => {
        handleCloseModal()
        setEditData(null)
        fetchData()
      }).catch((err) => {

      }).then(() => setLoading(false))
    }
    else{
      instance({
        method: 'post',
        url: 'PreQualificationGroup?wcode=prequalification-group-config-list',
        data: {
          ...values
        }
      }).then((res) => {
        handleCloseModal()
        fetchData()
      }).catch((err) => {

      }).then(() => setLoading(false))
    }
  }

  const handleEdit = (_row) => {
    setEditData(_row)
    setOpen(true)
  }

  const handleDelete = (row) => {
    modal.confirm({
      title: 'Warning!',
      content: 'You are about to change this row of datas status',
      okText: 'Change',
      cancelText: 'Cancel',
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: 'get',
            url: `/PreQualificationGroup/${row.status == 1 ? 'inactive': 'active'}/${row.id}?wcode=prequalification-group-config-list`
          }).then((res) => {
            resolve()
          }).catch((err) => {
            reject()
          }).then(() => fetchData())
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

  const handlePublish = () => {
    modal.confirm({
      title: 'Warning!',
      content: 'You are about to publish all the changes',
      okText: 'Publish',
      cancelText: 'Cancel',
      onOk() {
        setLoading(true)
        return new Promise((resolve, reject) => {
          instance({
            method: 'get',
            url: `/PreQualificationGroup/publish?wcode=prequalification-group-config-list`
          }).then((res) => {
            message.success("Successfully published!")
            resolve()
          }).catch((err) => {
            message.error("There was error while publishing!")
            reject()
          }).then(() => {
            fetchData()
            setLoading(false)
          })
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

  return (
    <div className="p-4 grid grid-cols-12 gap-3">
      <div className="col-span-3">
        {
          loading ?
          <Skeleton></Skeleton>
          :
          <div className="col-span-3 flex flex-col gap-3">
            <Card title="Prequalification groups" size="small">
              {
                <div className="flex flex-col gap-1">
                  {
                    data.map((foo, fooIndex) => {
                      return (
                        <Tooltip title={foo.status == 0 && 'Inactive'}>
                          <Button
                            type="link"
                            className={twMerge("w-full flex justify-start group text-primary", selectedGroup?.id == foo.id && "bg-primary/10", foo.status == 0 && 'text-gray-400')}
                            key={`event-${fooIndex}`}
                            onClick={() => setSelectedGroup(foo)}>
                            <div className="flex gap-2 justify-between w-full">
                              {foo.name}
                              <div className="hidden gap-2 items-center group-hover:flex">
                                <Tooltip title="Edit">
                                  <EditOutlined onClick={(e) => {
                                    e.stopPropagation()
                                    handleEdit(foo)
                                  }}/>
                                </Tooltip>
                                <Tooltip title="Change status">
                                  <SwapOutlined className="text-red-400" onClick={(e) => {
                                    e.stopPropagation()
                                    handleDelete(foo)
                                  }}/>
                                </Tooltip>
                              </div>
                            </div>
                          </Button>
                        </Tooltip>
                      )
                    })
                  }
                  <Button className='mt-3' type="dashed" onClick={() => {
                    setEditData(null)
                    setOpen(true)                      
                  }}>Add new group</Button>
                  <Divider></Divider>
                  <Button className='mt-3' type="primary" onClick={handlePublish}>Publish</Button>
                </div>
              }
            </Card>
          </div>
        }
      </div>
      <div className="col-span-9">
        {
          selectedGroup &&
          <PrequalificationGroupDetail question={{name: selectedGroup.name, id: selectedGroup.id}}></PrequalificationGroupDetail>
        }
      </div>
      {/* <MainTable
        dataTable={data}
        columns={columns({handleEdit, handleDelete})}
        loading={loading}
        handleAdd={handleAdd}
        filter={<Button type='primary' onClick={handlePublish} loading={loading}>Publish</Button>}>

      </MainTable> */}
      <Modal
        title={editData ? <div className='flex items-center justify-start gap-2'>Edit</div> : <div className='flex items-center justify-start gap-2'>Add</div>}
        open={open}
        destroyOnClose
        maskClosable={false}
        okText='Save'
        onOk={form.submit}
        onCancel={handleCloseModal}
        width={"40%"}
      >
        <Form
          form={form}
          fields={fields()}
          length={data.length}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          isEdit={isEdit}
          className='gap-4'
        />
      </Modal>
      {contextHolder}
    </div>
  );
}

export default PrequalificationGroup;
