import { DatePicker, Form, Select, Table, Transfer, TreeSelect } from "antd"
import { useEffect, useState } from "react";
import { instance } from "utils/axios";

const SurveyForm = ({setModal, form}) => {
  const [ fetching, setFetching ] = useState(true)
  const [ surveys, setSurveys ] = useState({
    total: 0,
    data: []
  })
  const [ treeData, setTreeData ] = useState([
    {
      id: 0,
      pId: 0,
      value: 'eoi',
      title: 'EOI'
    },
    {
      id: 1,
      pId: 0,
      value: 'rfq',
      title: 'RFQ'
    },
    {
      id: 2,
      pId: 0,
      value: 'travel',
      title: 'Travel'
    },
    {
      id: 3,
      pId: 0,
      value: 'freight',
      title: 'Freight'
    },
  ])
  
  
  useEffect(() => {
    instance({
      method: 'get',
      url: '/survey/type/0&wcode=master',
    }).then((res) => {
      if(res.data.responseData.data.length > 0) {
        setSurveys(res.data.responseData)
      }
    }).catch((err) => {
    })
  }, [])
  
  const handleFinish = (values) => {
    console.log(values)
    instance({
      method: 'post',
      url: `/TenderFeedback?wcode=tender-feedback`,
      data: {
        surveyId: values.surveyId,
        tenderId: values.tenderId
      }
    }).then(() => {
      setModal(false)
    })
  }
  
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleFinish}
      className="grid grid-cols-12 gap-x-3">
      <Form.Item label="Survey" name="surveyId" className="col-span-12" rules={[{required: true, message: 'Select survey!'}]}>
        <Select options={surveys.data.map((foo) => ({value: foo.id, label: foo.name}))}></Select>
      </Form.Item>
      <Form.Item
        className="col-span-12"
        name="tenderId" 
        label="Tender"
        rules={[{required: true, message: 'Select tender!'}]}>
        <TreeSelect 
          dropdownStyle={{
            height: 400,
            overflow: 'auto'
          }}
          className="w-full"
          placeholder="Select tender"
          treeData={treeData}>

        </TreeSelect>
      </Form.Item>
    </Form>
  )
}

export default SurveyForm