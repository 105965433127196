import React, { useEffect, useMemo, useState } from 'react'
import { Form as AntForm, Input, Skeleton, Card, Tooltip, Tag, Modal, Select, Dropdown, Space } from 'antd'
import { instance } from 'utils/axios'
import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component';
import { MoreOutlined } from '@ant-design/icons'

const { Search } = Input


const statusEnum = {
  '-1': {
    title: 'Draft',
    color: '#F09D51'
  },
  0: {
    title: 'Published',
    color: '#427EC1'
  },
  1: {
    title: 'Open',
    color: '#009A72'
  },
  2: {
    title: 'Reopened',
    color: '#009A72'
  },
  3: {
    title: 'Closed',
    color: '#D55727'
  },
  4: {
    title: 'Awarded',
    color: '#009A72'
  },
  5: {
    title: 'Finished',
    color: '#009A72'
  },
  6: {
    title: 'Postponed',
    color: '#F09D51'
  },
  7: {
    title: 'Declined',
    color: '#D55727'
  },
  8: {
    title: 'Clarification',
    color: '#29ABE2'
  },
  9: {
    title: 'Approved',
    color: '#009A72'
  },
  10: {
    title: 'Pending approval',
    travel: 'Booked',
    color: '#F09D51'
  },
  11: {
    title: 'Canceled',
    color: '#D55727'
  },
}

const items = ({row, handleEndUser}) => {
  return (
    [
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleEndUser(row)
        }}>Add end user</a>,
        key: 9998
      },
    ]
  )
}

function TenderTypeContainer({ type, modal, suppliers, filter }) {
  const [ data, setData ] = useState({
    total: 0,
    data: []
  })
  const [ currentPage, setCurrentPage ] = useState(1)
  const [ hasMore, setHasMore ] = useState(true)
  const [ loading, setLoading ] = useState(false)
  const [ templateList, setTemplateList ] = useState([])
  const [ fetching, setFetching ] = useState(false)
  const [ products, setProducts ] = useState([])
  const [ users, setUsers ] = useState([])
  const [ searchValue, setSearchValue ] = useState('')
  const [ form ] = AntForm.useForm();
  const navigate = useNavigate()
  const [ editData, setEditData ] = useState(null)
  const [ endUserModalOpen, setEndUserModalOpen ] = useState(false)
  const [ assignModalOpen, setAssignModalOpen ] = useState(false)
  const [ endUserForm ] = AntForm.useForm()

  
  useEffect(() => {
    setData({
      total: 0,
      data: []
    })
    setCurrentPage(1)
    setHasMore(true)
    fetchData()
  },[type, filter])

  useEffect(() => {
    fetchProducts()
    fetchUsers()
  }, [])

  const fetchUsers = () => {
    instance({
      method: 'get',
      url: `/User/permission/6?wcode=tender`
    }).then((res) => {
      setUsers(res.data.responseData)
    }).catch((err) => {
    })
  }

  const fetchProducts = () => {
    instance({
      method: 'get',
      url: `/ProductType?wcode=tender`
    }).then((res) => {
      setProducts(res.data.responseData)
    }).catch((err) => {
    })
  }

  const fetchData = () => {
    setLoading(true)
    instance({
      method: 'get',
      url: `/TenderBidSummary/rfq`,
      params: {
        wcode: 'tender',
        page: currentPage,
        pageSize: 20,
        ...filter
      }
    }).then((res) => {
      if(res.data.responseData.length < 20) {
        setHasMore(false)
      }
      setData(res.data.responseData)
    }).catch((err) => {
    }).then(() => setLoading(false))
  }

  const fetchNext = () => {
    instance({
      method: 'get',
      url: `/TenderBidSummary/rfq`,
      params: {
        wcode: 'tender',
        page: currentPage+1,
        pageSize: 20,
        ...filter
      }
    }).then((res) => {
      if(res.data.responseData.length < 20) {
        setHasMore(false)
      }
      setData(prev => ({total: res.data.responseData.total, data: [...prev.data, ...res.data.responseData.data]}))
      setCurrentPage(currentPage+1)
    }).catch((err) => {

    })
  }

  const onSearch = () => {
    
  }

  const handleAssign = (values) => {
    setLoading(true)
    instance({
      method: 'put',
      url: `tender/assign`,
      data: {
        id: editData.id,
        userId: values.user
      }
    }).then(res => {
      fetchData()
      setAssignModalOpen(false)
      setEditData(null)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const handleEndUser = (values) => {
    setLoading(true)
    instance({
      method: 'put',
      url: `tender/enduser`,
      data: {
        id: editData.id,
        userIds: values.user.filter(foo => typeof foo == 'number'),
        emails: values.user.filter(foo => typeof foo == 'string')
      }
    }).then(res => {
      fetchData()
      setEndUserModalOpen(false)
      setEditData(null)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const filteredData = useMemo(() => {
    return data.data.filter(foo => foo.name.toLowerCase().includes(searchValue.toLowerCase()))
  }, [data, searchValue])
  
  return (
    <div className='flex flex-col'>
      <div className="mb-3">
        <Search 
          placeholder="Search" 
          className='border-none' 
          onSearch={onSearch} 
          enterButton 
          onChange={e => setSearchValue(e.target.value)} />
      </div>
      <div className="bg-white mb-3 font-semibold flex items-center justify-between">
        {filteredData.length} of {data.total} Results
        <div className='flex gap-2'>
        </div>
      </div>
      <InfiniteScroll
        dataLength={data.data.length}
        next={fetchNext}
        loader={<div className='text-center font-bold'>Fetching...</div>}
        className="flex flex-col gap-1 overflow-x-hidden"
        hasMore={hasMore}
        endMessage={
          <p className="text-center font-bold">
            No more tender data
          </p>
        }>
        <table className="oyu-table">
          <colgroup>
            <col></col>
            <col></col>
            <col></col>
            <col></col>
            <col></col>
            <col></col>
            <col></col>
            <col></col>
          </colgroup>
          <thead>
            <tr>
              <th style={{width: '75px'}}>Ticket #</th>
              <th>Description</th>
              <th style={{width: '100px'}}>Bid #</th>
              <th style={{width: '100px'}}>Buyer Name</th>
              <th style={{width: '100px'}}>Type</th>
              <th style={{width: '100px'}}>Status</th>
              <th style={{width: '150px'}}>Publish date</th>
              <th style={{width: '150px'}}>Close date</th>
              <th style={{width: '50px'}}></th>
            </tr>
          </thead>
          <tbody>
            {
              filteredData.map((foo, fooIndex) => {
                return (
                  <tr key={`tender-${fooIndex}`} className="hover:bg-gray-100 transition-all">
                    <td>{foo.procurementNow}</td>
                    <td className='max-w-[250px] truncate'>
                      <Link 
                        className="text-primary cursor-pointer hover:underline" 
                        to={`${foo.id}`} 
                        state={{...foo, type: type}}>
                        <div className="truncate">
                          <Tooltip title={foo.name}>
                            <span className="truncate">
                              {foo.name}
                            </span>
                          </Tooltip>
                        </div>
                      </Link>
                    </td>
                    <td>{foo.code}</td>
                    <td className='text-center'>{foo.firstName} {foo.lastName}</td>
                    <td className='text-center'>{foo.rfqType == 1 ? 'Goods' : 'Service'}</td>
                    <td className="flex justify-center">
                      <Tag className="whitespace-normal w-[120px] text-center" color={statusEnum[foo.status].color}>
                        {statusEnum[foo.status].title}
                      </Tag>
                    </td>
                    <td className="text-nowrap">{moment(foo.startDate).format("YYYY/MM/DD HH:mm")}</td>
                    <td className="text-nowrap">{moment(foo.endDate).format("YYYY/MM/DD HH:mm")}</td>
                    <td>
                      <Dropdown 
                        trigger={['click']} 
                        menu={{items: items({
                          row: foo, 
                          handleEndUser: () => {
                            setEditData(foo)
                            setEndUserModalOpen(true)
                          }
                          }),
                        }}>
                        <a onClick={e => e.preventDefault()} className="flex justify-center items-center">
                          <Space className="px-3">
                            <MoreOutlined className='text-xl'/>
                          </Space>
                        </a>
                      </Dropdown>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>

      </InfiniteScroll>
      <Modal
        destroyOnClose
        maskClosable={false}
        title="Add end users"
        open={endUserModalOpen}
        zIndex={100001}
        onCancel={() => {
          setEndUserModalOpen(false)
          setEditData(null)
          endUserForm.resetFields()
        }}
        onOk={endUserForm.submit}
        confirmLoading={loading}>
        {
          editData &&
          <AntForm
            form={endUserForm}
            layout='vertical'
            onFinish={handleEndUser}>
            <AntForm.Item name='user' label="End users">
              <Select 
                options={users.map(foo => ({value: foo.id, label: `${foo.firstName} ${foo.lastName}`}))}
                showSearch
                mode='tags'
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
              />
            </AntForm.Item>
          </AntForm>
        }
      </Modal>
      {/* {
        loading ? 
        <Skeleton></Skeleton>
        :
        <InfiniteScroll
          dataLength={data.data.length}
          next={fetchNext}
          loader={<div className='text-center font-bold'>Fetching...</div>}
          className="flex flex-col gap-1 overflow-x-hidden"
          hasMore={hasMore}
          endMessage={
            <p className="text-center font-bold">
              No more tender data
            </p>
          }>
          {
            filteredData.map((foo, fooIndex) => {
              return (
                <Card
                  className="rounded-none pr-0"
                  type="inner"
                  size="small"
                  key={`tender-${fooIndex}`}
                  bodyStyle={{padding: '0.4rem 0rem 0.4rem 1rem'}}>
                  <div className="">
                    <div className="flex items-center gap-x-2">
                      <div className='grow min-w-[300px] flex gap-4'>
                        <div className="shrink-0 w-12 h-12 rounded-full flex justify-center items-center text-white bg-primary text-3xl">
                          {foo.name.slice(0,1)}
                        </div>
                        <div className="grow overflow-hidden">
                          <Link 
                            className="text-primary cursor-pointer hover:underline" 
                            to={`${foo.id}`} 
                            state={{...foo, type: type}}>
                            <div className="truncate">
                              <Tooltip title={foo.name}>
                                <span className="truncate">
                                  {foo.name}
                                </span>
                              </Tooltip>
                            </div>
                          </Link>
                          <div className='text-mute'>{`${foo.code}${foo.seq != 1 ? `/v${foo.seq}` : ''}`}</div>
                        </div>
                      </div>
                      <div className='flex justify-between'>
                        <div className="flex p-4 gap-4">
                          <div className="flex flex-col items-center">
                            <label className="text-mute">Status</label>
                            <Tag className="whitespace-normal w-[120px] text-center" color={statusEnum[foo.status].color}>
                              {type == 3 ?  statusEnum[foo.status].travel ?? statusEnum[foo.status].title : statusEnum[foo.status].title}
                            </Tag>
                          </div>
                          <div className="flex flex-col items-center">
                            <div className="whitespace-nowrap">
                              <span className="font-semibold">{moment(foo.startDate).format("YYYY-MM-DD")}</span>
                              <span>{moment(foo.startDate).format(" HH:mm")}</span>
                            </div>
                            <div className="whitespace-nowrap">
                              <span className="font-semibold">{moment(foo.endDate).format("YYYY-MM-DD")}</span>
                              <span>{moment(foo.endDate).format(" HH:00")}</span>
                            </div>
                          </div>
                        </div>
                        <Dropdown 
                          trigger={['click']} 
                          menu={{items: items({row: foo, handleEdit, handlePublishPre, handlePublish, handleClose, handleReopen, handleExtend, handleCancel: () => {
                            setEditData(foo)
                            setCancelModalOpen(true)
                          }}),}}>
                          <a onClick={e => e.preventDefault()} className="flex justify-center items-center">
                            <Space className="px-3">
                              <MoreOutlined className='text-xl'/>
                            </Space>
                          </a>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  
                </Card>
              )
            })
          }
        </InfiniteScroll>
      } */}
    </div>
  )
}


export default TenderTypeContainer
