import { Table } from "antd"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { instance } from "utils/axios"

const columns = ({manufacturers, licenseTypes}) => {
  return [
    {
      dataIndex: '#',
      title: '#',
      editable: false,
      width: 50,
      render: (cell, row, index) => index+1
    },
    {
      dataIndex: 'licenseName',
      title: 'License type',
    },
    {
      dataIndex: 'manufacturerName',
      title: 'Manufacturer',
      editable: true,
    },
    {
      dataIndex: 'expireDate',
      title: 'Expire date',
      editable: true,
      inputType: 'date',
      width: 150,
      render: (cell) => dayjs(cell).format("YYYY/MM/DD")
    },
    {
      dataIndex: 'fileName',
      title: 'File',
      editable: true,
      inputType: 'file',
      width: 100,
      render: (cell) => <a href={process.env.REACT_APP_CDN_URL+cell} target="_blank" className="text-link">preview</a>
    },
    {
      dataIndex: 'delete',
      title: ' ',
      editable: true,
      width: 50
    },
  ]
}

const CompanyLicense = ({state, data}) => {
  const [ manufacturers, setManufacturers ] = useState([])
  const [ licenseTypes, setLicenseTypes ] = useState([])

  useEffect(() => {
    instance({
      method: 'get',
      url: `/Master/licensetype`
    }).then(res => {
      setLicenseTypes(res.data.responseData)
    })
    instance({
      method: 'get',
      url: `/Master/manufacturer`
    }).then(res => {
      setManufacturers(res.data.responseData)
    })
  }, [])
  
  return (
    <div>
      <Table 
        size="small"
        pagination={false}
        dataSource={data}
        columns={columns({manufacturers, licenseTypes})}>

      </Table>
    </div>
  )
}

export default CompanyLicense