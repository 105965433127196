import { useEffect, useState } from 'react'
import { Form, Input, Select, InputNumber } from 'antd'
import { instance } from 'utils/axios'

const PersonForm = ({form, editData, handleFinish}) => {
  const [ users, setUsers ] = useState([])
  const [ loading, setLoading ] = useState(true)

  useEffect(() => {
    instance({
      method: 'get',
      url: `/User/permission/9?wcode=tender-approval-list`
    }).then(res => {
      setUsers(res.data.responseData)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if(editData) {
      form.setFieldsValue(editData)
    }
  }, [editData])
  
  return (
    <Form
      // initialValues={editData}
      form={form}
      onFinish={handleFinish}
      layout="vertical">
      {
        editData ?
        <></>
        :
        <Form.Item name="userId" label="User" rules={[{required: true, message: 'User is required!'}]}>
          <Select loading={loading} placeholder="Select user">
            {
              users.map((foo, fooIndex) => {
                return (
                  <Select.Option key={`user-${fooIndex}`} value={foo.id}>{foo.firstName} {foo.lastName}</Select.Option>
                )
              })
            }
          </Select>
        </Form.Item>
      }
      <Form.Item name="name" label="Name" rules={[{required: true, message: 'Name is required!'}]}>
        <Input placeholder='Name'></Input>
      </Form.Item>
      <Form.Item name="amount" label="Amount" rules={[{required: true, message: 'Amount is required!'}]}>
        <InputNumber className="w-full" placeholder='Minimum amount for approval'></InputNumber>
      </Form.Item>
      <Form.Item name="sseAmount" label="SSE Amount" rules={[{required: true, message: 'SSE amount is required!'}]}>
        <InputNumber className="w-full" placeholder='Minimum amount for sse approval'></InputNumber>
      </Form.Item>
      <Form.Item name="status" label="Status" rules={[{required: true, message: 'Status is required!'}]}>
        <Select>
          <Select.Option value={1}>Active</Select.Option>
          <Select.Option value={0}>Inactive</Select.Option>
        </Select>
      </Form.Item>
    </Form>
  )
}

export default PersonForm