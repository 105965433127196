export default () => {
  return [
    {
      label: 'Title',
      name: 'Title',
      rules: [{required: true, message: 'Гарчиг оруулна уу!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Description',
      name: 'Description',
      type: 'textarea',
      rules: [{required: true, message: 'Тайлбар оруулна уу!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },

    {
      label: 'Image',
      name: 'Image',
      type: 'file',
      rules: [{required: true, message: 'Нэр оруулна уу!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Price',
      name: 'Price',
      type: 'number', 
      rules: [{required: true, message: 'Нэр оруулна уу!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Status',
      name: 'Status',
      type: 'select',
      rules: [{required: true, message: 'Нэр оруулна уу!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
        options: [
          { label: 'Идэвхтэй', value: 1 }, 
          { label: 'Идэвхгүй', value: 2 },   
      ],
      }
    },

  ]
}