import React, { useState, useEffect } from 'react';
import { Skeleton, Form as AntForm, Input, InputNumber, Select, Button, Space, Modal, Dropdown, Radio, DatePicker, Upload, Checkbox } from 'antd';
import { MinusCircleOutlined, PlusOutlined, MoreOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Form } from 'components';
import { instance } from 'utils/axios';
import TextArea from 'antd/lib/input/TextArea';
import { MainTable } from 'components';


const items = ({row, handleEdit, handleDelete}) => {  
  return (
    [
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleEdit(row)
        }}>Edit</a>,
        key: 0
      },
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleDelete(row.id)
        }}>Delete</a>,
        key: 1
      },
    ]
  )
}

const fields = () => {
  return [
    {
      label: 'Option name',
      name: 'name',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Status',
      name: 'status',
      type: 'select',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
        options: [{label: 'Active', value: 1}, {label: 'Inactive', value: 9}]
      }
    }
  ]
}

const PrequalificationAnswers = ({record}) => {
  const [ data, setData ] = useState([])
  const [ editorType, setEditorType ] = useState([])
  const [ init, setInit ] = useState(true)
  const [ loading, setLoading ] = useState(false)
  const [ editData, setEditData ] = useState(null)
  const [ isEdit, setIsEdit ] = useState(false)
  const [ modal, contextHolder ] = Modal.useModal();
  const [ open, setOpen ] = useState(false)
  const [ form ] = AntForm.useForm();

  useEffect(() => {
    fetchData()
    fetchEditorType()
  }, [])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `CompanyQuestionOption/${record.id}?wcode=company-question-config-detail`
    }).then(res => {
      setData(res.data.responseData)
      form.setFieldsValue({answers: res.data.responseData})
    }).catch(err => {

    }).then(() => {
      setInit(false)
    })
  }

  const fetchEditorType = () => {
    instance({
      method: 'get',
      url: `EditorType?wcode=company-question-config-detail`
    }).then(res => {
      // console.log(res.data);
      setEditorType(res.data.responseData)
    }).catch(err => {
      console.log(err);
    })
  }

  const handleEdit = (_row) => {
    setEditData(_row)
    setOpen(true)
  }

  const handleDelete = (_id) => {
    modal.confirm({
      title: 'Warning!',
      content: 'You are about to delete this row of data',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: 'delete',
            url: `/CompanyQuestionOption/${_id}?wcode=company-question-config-detail`
          }).then((res) => {
            resolve()
            fetchData()
          }).catch((err) => {
            reject()
          }).then(() => fetchData())
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

  const handleSubmit = (_values) => {
    setLoading(true)
    if(editData) {
      instance({
        method: 'put',
        url: `/CompanyQuestionOption?wcode=company-question-config-detail`,
        data: {
          ..._values,
          id: editData.id,
          preQualificationQuestionId: record.id
        }
      }).then(res => {
        setOpen(false)
        fetchData()
      }).catch(err => {
        
      }).then(() => {
        setLoading(false)
      })
    }
    else {
      instance({
        method: 'post',
        url: `/CompanyQuestionOption?wcode=company-question-config-detail`,
        data: {
          ..._values,
          preQualificationQuestionId: record.id
        }
      }).then(res => {
        setOpen(false)
        fetchData()
      }).catch(err => {
        
      }).then(() => {
        setLoading(false)
      })
    }
  }

  const handleCloseModal = () => {
    setOpen(false)
    form.resetFields()
  }
  
  const handleAdd = () => {
    setEditData(null)
    setOpen(true)
  }

  return (
    <div>
      {
        init ?
        <div className='flex justify-center items-center relative'>
          <Skeleton active></Skeleton>
        </div>
        :
        <MainTable
          className="rounded-none"
          dataTable={data}
          loading={loading}
          handleAdd={handleAdd}
          columns={[
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name'
            },
            {
              title: 'Status',
              dataIndex: 'status',
              key: 'status'
            },
            {
              title: '',
              dataIndex: 'action',
              key: 'action',
              width: 80,
              render: (text, row) => (
                <Dropdown 
                trigger={['click']} 
                menu={{items: items({row, handleEdit, handleDelete}),}}>
                <a onClick={e => e.preventDefault()}>
                  <Space>
                    <MoreOutlined className='text-xl'/>
                  </Space>
                </a>
              </Dropdown>
              )
            },
          ]}
          borderHide>

        </MainTable>
      }
      <Modal
        title={editData ? <div className='flex items-center justify-start gap-2'><EditOutlined/>Edit</div> : <div className='flex items-center justify-start gap-2'><PlusCircleOutlined/>Add</div>}
        open={open}
        destroyOnClose
maskClosable={false}
        okText='Save'
        onOk={form.submit}
        onCancel={handleCloseModal}
      >
        <Form
          initialValue={{isUseCalculation: true}}
          form={form}
          fields={fields(data, editorType, form)}
          length={data.length}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          isEdit={isEdit}
          className='gap-4'
        />
      </Modal>
      {contextHolder}
    </div>
  );
}

export default PrequalificationAnswers;
