import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { EditOutlined, PlusCircleOutlined, MoreOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button, Form as AntForm, Modal, Dropdown, Space, Skeleton, Card, Tooltip } from 'antd';
import { MainTable, Form } from 'components';
import { instance } from 'utils/axios';
import { message } from 'antd';
import CompanyQuestionGroupDetail from './companyQuestionGroupDetail';

const items = ({row, handleEdit, handleDelete}) => {  
  return (
    [
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleEdit(row)
        }}>Edit</a>,
        key: 0
      },
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleDelete(row.id)
        }}>Delete</a>,
        key: 1
      },
    ]
  )
}

const fields = () => {
  return [
    {
      label: 'Name',
      name: 'name',
      rules: [{required: true, message: 'Name is required!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    }
  ]
}

const columns = ({currentPage, handleEdit, handleDelete}) => {
  return ([
    {
      title: '№',
      dataIndex: 'No',
      key: 'No',
      width: 40,
      align: 'center',
      render: (text, row, i) => (
        <span>{currentPage ? currentPage+i+1 : i+1}</span>
      )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      filterType: "search",
      render: (text, row) => (
        <Link className='text-link hover:underline hover:text-link' to={`${row.id}`} state={row}>{text}</Link>
      )
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 80,
      render: (text, row) => (
        <Dropdown 
          trigger={['click']} 
          menu={{items: items({row, handleEdit, handleDelete}),}}>
          <a onClick={e => e.preventDefault()}>
            <Space>
              <MoreOutlined className='text-xl'/>
            </Space>
          </a>
        </Dropdown>
      )
    },
  ])
}

const CompanyQuestionGroup = ({}) => {
  const [ data, setData ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const [ open, setOpen ] = useState(false)
  const [ editData, setEditData ] = useState(null)
  const [ isEdit, setIsEdit ] = useState(false)
  const [ modal, contextHolder ] = Modal.useModal();  
  const [ selectedGroup , setSelectedGroup ] = useState(null)
  const [ form ] = AntForm.useForm();

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `CompanyQuestionGroup?wcode=company-question-config-list`
    }).then(res => {
      console.log(res.data);
      setData(res.data.responseData)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const handleAdd = () => {
    setEditData(null)
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
    form.resetFields()
  }

  const handleSubmit = (values) => {
    setLoading(true)
    if(editData){
      instance({
        method: 'put',
        url: 'CompanyQuestionGroup?wcode=company-question-config-list',
        data: {
          ...values,
          id: editData.id
        }
      }).then((res) => {
        handleCloseModal()
        fetchData()
      }).catch((err) => {

      }).then(() => setLoading(false))
    }
    else{
      instance({
        method: 'post',
        url: 'CompanyQuestionGroup?wcode=company-question-config-list',
        data: {
          ...values
        }
      }).then((res) => {
        handleCloseModal()
        fetchData()
      }).catch((err) => {

      }).then(() => setLoading(false))
    }
  }

  const handleEdit = (_row) => {
    setEditData(_row)
    setOpen(true)
  }

  const handleDelete = (id) => {
    modal.confirm({
      title: 'Warning!',
      content: 'You are about to delete this row of data',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: 'delete',
            url: `/CompanyQuestionGroup/${id}?wcode=company-question-config-list`
          }).then((res) => {
            resolve()
          }).catch((err) => {
            reject()
          }).then(() => fetchData())
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

  const handlePublish = () => {
    modal.confirm({
      title: 'Warning!',
      content: 'You are about to publish all the changes',
      okText: 'Publish',
      cancelText: 'Cancel',
      onOk() {
        setLoading(true)
        return new Promise((resolve, reject) => {
          instance({
            method: 'get',
            url: `/CompanyQuestionGroup/publish?wcode=company-question-config-list`
          }).then((res) => {
            message.success("Successfully published!")
            resolve()
          }).catch((err) => {
            message.error("There was error while publishing!")
            reject()
          }).then(() => {
            fetchData()
            setLoading(false)
          })
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

  return (
    <div className="p-4">
      <div className='grid grid-cols-12 gap-3'>
        <div className="col-span-3">
          {
            loading ?
            <Skeleton></Skeleton>
            :
            <div className="col-span-3 flex flex-col gap-3">
              <Card title="Templates events" size="small">
                {
                  <div className="flex flex-col gap-1">
                    {
                      data.map((foo, fooIndex) => {
                        return (
                          <Button 
                            type="link"
                            className={selectedGroup?.id == foo.id ? "w-full flex justify-start text-primary !bg-primary/10 group" : "w-full flex justify-start text-primary group" }
                            key={`event-${fooIndex}`}
                            onClick={() => setSelectedGroup(foo)}>
                            <div className="flex gap-2 justify-between w-full">
                              {foo.name}
                              <div className="hidden gap-2 items-center group-hover:flex">
                                <Tooltip title="Edit">
                                  <EditOutlined onClick={(e) => {
                                    e.stopPropagation()
                                    handleEdit(foo)
                                  }}/>
                                </Tooltip>
                                <Tooltip title="Delete">
                                  <DeleteOutlined className="text-red-400" onClick={(e) => {
                                    e.stopPropagation()
                                    handleDelete(foo.id)
                                  }}/>
                                </Tooltip>
                              </div>
                            </div>
                          </Button>
                        )
                      })
                    }
                    <Button className='mt-3' type="dashed" onClick={() => {
                      setEditData(null)
                      setOpen(true)
                    }}>Add new event</Button>
                  </div>
                }
              </Card>
            </div>
          }
        </div>
        <div className="col-span-9">
          {
            selectedGroup &&
            <CompanyQuestionGroupDetail question={{name: selectedGroup.name, id: selectedGroup.id}}></CompanyQuestionGroupDetail>
          }
        </div>
      </div>
    
      {/* <MainTable
        dataTable={data}
        columns={columns({handleEdit, handleDelete})}
        loading={loading}
        handleAdd={handleAdd}
        filter={<Button type='primary' onClick={handlePublish} loading={loading}>Publish</Button>}>

      </MainTable> */}
      <Modal
        title={editData ? <div className='flex items-center justify-start gap-2'>Edit</div> : <div className='flex items-center justify-start gap-2'>Add</div>}
        open={open}
        destroyOnClose
        maskClosable={false}
        okText='Save'
        onOk={form.submit}
        onCancel={handleCloseModal}
        width={"40%"}
      >
        <Form
          form={form}
          fields={fields()}
          length={data.length}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          isEdit={isEdit}
          className='gap-4'
        />
      </Modal>
      {contextHolder}
    </div>
  );
}

export default CompanyQuestionGroup;
