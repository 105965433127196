import { Select, Form } from 'antd'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { instance } from 'utils/axios'

function ISelect(props) {
  const { dependentValue,  optionsUrl, setFieldValue, _field, wcode } = props
  const [ options, setOptions ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const [ init, setInit ] = useState(true)

  useEffect(() => {
    if(props.dependentValue){
      getOptions()
    }
    // console.log(!init, props.dependentValue)
    if(!init || !props.dependentValue) {
      setFieldValue(_field.name, '')
    }
    else {
      setInit(false)
    }

  },[props.dependentValue])

  const getOptions = () => {
    setLoading(true)
    if(optionsUrl) {
      if(typeof optionsUrl === 'string') {
        instance({
          method: 'get',
          url: `${optionsUrl}/${dependentValue}${wcode && `?wcode=${wcode}`}`,
        }).then(res => {
          let tmp = [{
            label: 'Empty',
            value: null
          }]
          res.data.responseData.map((item,i) => {
            tmp.push({
              value: item.id,
              label: item.name,
            })
          })
          setOptions(tmp)
          
        }).catch(err => {
        }).then(() => {
          setLoading(false)
        })
      }
    }
  }

  return (
    <Select loading={loading} options={options} {...props}>
    </Select>
  )
}

export default ISelect