import { Button, Card, Popconfirm, Table, Tag } from "antd"
import moment from "moment"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { instance } from "utils/axios"
import { DeleteOutlined } from "@ant-design/icons"

const statusEnum = {
  1: {
    title: 'Open',
    color: '#009A72'
  },
  3: {
    title: 'Closed',
    color: '#D55727'
  },
  11: {
    title: 'Canceled',
    color: '#D55727'
  },
}

const Negotiation = () => {
  const [ data, setData ] = useState([])
  const { id } = useParams()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `/TenderPriceRequest/request/tender/${id}?wcode=tender-detail`
    }).then(res => {
      setData(res.data.responseData)
    })
  }
  
  const confirm = (_data) => {
    instance({
      method: 'delete',
      url: `/TenderPriceRequest/request/${_data.id}?wcode=tender-detail`
    }).then(res => {
      
    }).catch(() => {

    }).then(() => {
      fetchData()
    })
  }
  
  return (
    <Card title="Negotiation history" size="small" bodyStyle={{padding: 0}}>
      <Table
        scroll={{
          x: 800
        }}
        rowKey={"id"}
        dataSource={data}
        size="small"
        pagination={false}
        columns={[
          {
            title: 'Items',
            dataIndex: 'count',
            width: 200,
            fixed: 'left'
          },
          {
            title: 'Sent date',
            dataIndex: 'sentDate',
            render: foo => moment(foo).format("YYYY-MM-DD HH:mm"),
          },
          {
            title: 'Expire date',
            dataIndex: 'expireDate',
            render: foo => moment(foo).format("YYYY-MM-DD HH:mm"),
          },
          {
            title: 'Status',
            dataIndex: 'status',
            width: 100,
            render: cell => {
              if(statusEnum[cell])
              return (
                <Tag color={statusEnum[cell].color ?? 'error'}>
                  {statusEnum[cell].title ?? ''}
                </Tag>
              )
            }
          },
        ]}
        expandable={{
          columnWidth: 50,
          fixed: 'left',
          expandedRowRender: rec => {
            return(
              <ExpandedRow rec={rec}/>
            )
          }
        }}>

      </Table>
    </Card>
  )
}

const ExpandedRow = ({rec}) => {
  const [ data, setData ] = useState({
    detail: []
  })
  
  useEffect(() => {
    instance({
      method: 'get',
      url: `/TenderPriceRequest/request/${rec.id}?wcode=tender-detail`
    }).then(res => {
      let tmp = Object.assign({detail: []}, res.data.responseData)
      let suppliers = [...new Set(res.data.responseData.detail.map(foo => foo.supplierId))]
      suppliers = suppliers.sort((a,b) => a.supplierId - b.supplierId)
      tmp.detail = tmp.detail.sort((a,b) => a.supplierId - b.supplierId)
      suppliers.map((foo, fooIndex) => {
        let index = tmp.detail.findIndex(ab => ab.supplierId == foo)
        tmp.detail[index].useSpan = true
        tmp.detail[index].rowSpan = res.data.responseData.detail.filter(ab => ab.supplierId == foo).length 
        
      })

      setData(tmp)
    })
  }, [])
  
  return (
    <div>
      <Table
        dataSource={data.detail}
        pagination={false}
        scroll={{x: 1000}}
        columns={[
          {
            title: 'Supplier',
            dataIndex: 'supplierName',
            fixed: 'left',
            width: 200,
            onCell: (_, index) => {
              return {
                rowSpan: _.useSpan ? _.rowSpan : 0
              }
            }
          },
          {
            title: 'Supplier ID',
            dataIndex: 'supplierId',
            width: 120,
            onCell: (_, index) => {
              return {
                rowSpan: _.useSpan ? _.rowSpan : 0
              }
            }
          },
          {
            title: 'PR Number',
            dataIndex: 'prNumber',
            width: 120
          },
          {
            title: 'Material Number',
            dataIndex: 'materialNumber',
            width: 110
          },
          {
            title: 'Item',
            dataIndex: 'description',
            width: 250,
          },
          {
            title: 'Manufacturer',
            dataIndex: 'manufacturer',
            width: 200
          },
          {
            title: 'Part number',
            dataIndex: 'partNumber',
            width: 100
          },
          {
            title: 'Quantity',
            dataIndex: 'qty',
            width: 110
          },
          {
            title: 'Unit price',
            dataIndex: 'oldUnitPrice',
            width: 110,
            render: (cell) => cell?.toLocaleString('en-US')
          },
          {
            title: 'Ship days',
            dataIndex: 'oldShipDays',
            width: 110
          },
          {
            title: 'Updated QTY',
            dataIndex: 'oldShipDays',
            width: 110
          },
          {
            title: 'Updated price',
            dataIndex: 'updatedUnitPrice',
            width: 200,
            render: (cell) => cell.toLocaleString('en-US')
          },
          {
            title: 'Updated days',
            dataIndex: 'updatedShipDays',
            width: 110
          },
          {
            title: 'Updated UOM',
            dataIndex: 'updatedShipUOM',
            width: 110
          },
          {
            title: 'Updated UOM Rate',
            dataIndex: 'updatedUomRate',
            width: 110
          },
        ]}>

      </Table>
    </div>
  )
}

export default Negotiation