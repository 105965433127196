import React, { useState, useEffect } from 'react';
import { Skeleton, Form as AntForm, Input, InputNumber, Select, Button, Space, Modal, Dropdown, Radio, DatePicker, Upload, Checkbox } from 'antd';
import { MinusCircleOutlined, PlusOutlined, MoreOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Form } from 'components';
import { instance } from 'utils/axios';
import TextArea from 'antd/lib/input/TextArea';
import { MainTable } from 'components';


const items = ({row, handleEdit, handleDelete}) => {  
  return (
    [
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleEdit(row)
        }}>Edit</a>,
        key: 0
      },
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleDelete(row.id)
        }}>Delete</a>,
        key: 1
      },
    ]
  )
}

const AnswerType = ({answer, types, edit, record, setEditData}) => {
  const form = Form.useFormInstance();
  
  if(edit) {
    return (
      <Form.List name='answers'>
        {(fields, { add, remove }) => {
          return (
            <>
            {
              fields.map(({ key, name, ...restField }) => {
                if(!form.getFieldValue(["answers", name]) || form.getFieldValue(["answers", name])?.status == 1 || form.getFieldValue(["answers", name])?.status == 0) {
                  return (
                  <>
                  <Space key={key} className='flex items-center mb-3'>
                    <Form.Item {...restField} name={[name, 'name']} label="Option">
                      <Input></Input>
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => {
                      if(form.getFieldValue(["answers", name]).id) {
                        setEditData(prev => ([...prev, form.getFieldValue(["answers", name]).id]))
                      }
                      remove([name, 'name'])
                    }} />
                  </Space>
                  </>
                  )
                }
              })
            }
            <Form.Item>
              <Button type="dashed" onClick={() => {add()}} block icon={<PlusOutlined />}>
                Add Option
              </Button>
            </Form.Item>
            </>
          )
        }}
      </Form.List>
    )
  }
  else {
    if(types.find(foo => foo.id == record.editorTypeId)?.content == "Numeric") {
      return (
        <Form.Item label={types.find(foo => foo.id == record.editorTypeId)?.content}>
          <InputNumber style={{width: '100%'}}></InputNumber>
        </Form.Item>
      )
    }
    else if(types.find(foo => foo.id == record.editorTypeId)?.content == "RadioButton") {
      return (
        <Form.Item label={types.find(foo => foo.id == record.editorTypeId)?.content}>
          <Radio.Group>
            {
              answer.map((foo, fooindex) => {
                return (
                  <Radio key={`radio-options-${fooindex}`} value={foo.id}>{foo.name}</Radio>
                )
              })
            }
          </Radio.Group>
        </Form.Item>
      )
    }
    else if(types.find(foo => foo.id == record.editorTypeId)?.content == "Calendar") {
      return (
        <Form.Item label={types.find(foo => foo.id == record.editorTypeId)?.content}>
          <DatePicker/>
        </Form.Item>
      )
    }
    else if(types.find(foo => foo.id == record.editorTypeId)?.content == "TextArea") {
      return (
        <Form.Item label={types.find(foo => foo.id == record.editorTypeId)?.content}>
          <TextArea rows={4}></TextArea>
        </Form.Item>
      )
    }
    else if(types.find(foo => foo.id == record.editorTypeId)?.content == "FileUpload") {
      return (
        <Form.Item label={types.find(foo => foo.id == record.editorTypeId)?.content}>
          <Upload listType="picture-card">
            <button style={{ border: 0, background: 'none' }} type="button">
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </button>
          </Upload>
        </Form.Item>
      )
    }
    else if(types.find(foo => foo.id == record.editorTypeId)?.content == "Checkbox") {
      return (
        <Form.Item label={types.find(foo => foo.id == record.editorTypeId)?.content} valuePropName='checked'>
          <Checkbox.Group options={answer.map(foo => ({value: foo.id, label: foo.name}))}></Checkbox.Group>
        </Form.Item>
      )
    }
    else if(types.find(foo => foo.id == record.editorTypeId)?.content == "Table") {
      console.log(JSON.parse(record.tableConfig))
      return (
        <Form.Item label={types.find(foo => foo.id == record.editorTypeId)?.content}>

        </Form.Item>
      )
    }
    else if(types.find(foo => foo.id == record.editorTypeId)?.content == "Combobox") {
      return (
        <Form.Item label={`Select ${record.title}`}>
          <Select>
            {
              answer.map((foo, fooIndex) => (
                <Select.Option key={`answer-${answer.id}-option-${foo.id}`} value={foo.id}>{foo.name}</Select.Option>
              ))
            }
          </Select>
        </Form.Item>
      )
    }
    return (
      <Form.Item label={types.find(foo => foo.id == record.editorTypeId)?.content}>
        <Input></Input>
      </Form.Item>
    )
  }
}

const fields = () => {
  return [
    {
      label: 'Option name',
      name: 'name',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    // {
    //   label: 'Description',
    //   name: 'description',
    //   type: 'textarea',
    //   className: 'col-span-12 mb-0',
    //   inputProps: {
    //     className: 'w-full',
    //   }
    // },
    {
      label: 'Status',
      name: 'status',
      type: 'select',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
        options: [{label: 'Active', value: 1}, {label: 'Inactive', value: 9}]
      }
    }
  ]
}

const PrequalificationAnswers = ({record}) => {
  const [ data, setData ] = useState([])
  const [ editorType, setEditorType ] = useState([])
  const [ init, setInit ] = useState(true)
  const [ loading, setLoading ] = useState(false)
  const [ editData, setEditData ] = useState(null)
  const [ isEdit, setIsEdit ] = useState(false)
  const [ modal, contextHolder ] = Modal.useModal();
  const [ open, setOpen ] = useState(false)
  const [ form ] = AntForm.useForm();

  useEffect(() => {
    fetchData()
    fetchEditorType()
  }, [])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `AuditQuestionOption/question/${record.id}?wcode=audit-config-detail`
    }).then(res => {
      setData(res.data.responseData)
      form.setFieldsValue({answers: res.data.responseData})
    }).catch(err => {

    }).then(() => {
      setInit(false)
    })
  }

  const fetchEditorType = () => {
    instance({
      method: 'get',
      url: `EditorType`
    }).then(res => {
      // console.log(res.data);
      setEditorType(res.data.responseData)
    }).catch(err => {
      console.log(err);
    })
  }

  const handleEdit = (_row) => {
    setEditData(_row)
    setOpen(true)
  }

  const handleDelete = (_id) => {
    modal.confirm({
      title: 'Warning!',
      content: 'You are about to delete this row of data',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: 'delete',
            url: `/AuditQuestionOption/${_id}?wcode=audit-config-detail`
          }).then((res) => {
            resolve()
            fetchData()
          }).catch((err) => {
            reject()
          }).then(() => fetchData())
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

  const handleSubmit = (_values) => {
    setLoading(true)
    if(editData) {
      instance({
        method: 'put',
        url: `/AuditQuestionOption?wcode=audit-config-detail`,
        data: {
          ..._values,
          id: editData.id,
          auditQuestionId: record.id
        }
      }).then(res => {
        setOpen(false)
        fetchData()
      }).catch(err => {
        
      }).then(() => {
        setLoading(false)
      })
    }
    else {
      instance({
        method: 'post',
        url: `/AuditQuestionOption?wcode=audit-config-detail`,
        data: {
          ..._values,
          auditQuestionId: record.id
        }
      }).then(res => {
        setOpen(false)
        fetchData()
      }).catch(err => {
        
      }).then(() => {
        setLoading(false)
      })
    }
  }

  const handleCloseModal = () => {
    setOpen(false)
    form.resetFields()
  }
  
  const handleAdd = () => {
    setEditData(null)
    setOpen(true)
  }

  return (
    <div>
      {
        init ?
        <div className='flex justify-center items-center relative'>
          <Skeleton active></Skeleton>
        </div>
        :
        <MainTable
          className="rounded-none"
          dataTable={data}
          loading={loading}
          handleAdd={handleAdd}
          columns={[
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name'
            },
            // {
            //   title: 'Description',
            //   dataIndex: 'description',
            //   key: 'description'
            // },
            {
              title: 'Status',
              dataIndex: 'status',
              key: 'status'
            },
            {
              title: '',
              dataIndex: 'action',
              key: 'action',
              width: 80,
              render: (text, row) => (
                <Dropdown 
                trigger={['click']} 
                menu={{items: items({row, handleEdit, handleDelete}),}}>
                <a onClick={e => e.preventDefault()}>
                  <Space>
                    <MoreOutlined className='text-xl'/>
                  </Space>
                </a>
              </Dropdown>
              )
            },
          ]}
          borderHide>

        </MainTable>
      }
      <Modal
        title={editData ? <div className='flex items-center justify-start gap-2'><EditOutlined/>Edit</div> : <div className='flex items-center justify-start gap-2'><PlusCircleOutlined/>Add</div>}
        open={open}
        destroyOnClose
maskClosable={false}
        okText='Save'
        onOk={form.submit}
        onCancel={handleCloseModal}
      >
        <Form
          initialValue={{isUseCalculation: true}}
          form={form}
          fields={fields(data, editorType, form)}
          length={data.length}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          isEdit={isEdit}
          className='gap-4'
        />
      </Modal>
      {contextHolder}
      {/* <Form
        onValuesChange={handleChange}
        // initialValues={[data]}
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        autoComplete="off">
        {
          renderAnswers()
        }
        {
          (record?.editorTypeId == 3 || record?.editorTypeId == 4 || record?.editorTypeId == 8) &&
          <div className="flex justify-end gap-4">
          {
            edit &&
            <Button onClick={() => {
              form.setFieldsValue({answers: data})
              setEdit(false)
            }}>
              Cancel
            </Button>
          }
          <Button onClick={() => handleAnswerSubmit()} loading={loading}>
            {
              edit ?
              'Save'
              :
              'Edit'
            }
          </Button>
        </div>
        }          
      </Form> */}
    </div>
  );
}

export default PrequalificationAnswers;
