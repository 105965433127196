import { Button, Input, Tooltip } from "antd"
import { useEffect, useMemo, useState } from "react"
import { ExpandAltOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons'
import { instance } from "utils/axios"
import moment from "moment"
import { Link } from "react-router-dom"

const Messenger = ({open, onClose}) => {
  const [ loading, setLoading ] = useState(true)
  const [ search, setSearch ] = useState('')
  const [ activeTab, setActiveTab ] = useState(0)
  const [ data, setData ] = useState([])

  useEffect(() => {
    if(open) {
      fetchData()
    }
  }, [open])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `/Message/latestthreads`
    }).then(res => {
      setData(res.data.responseData)
    })
  }

  const filteredData = useMemo(() => {
    if(search) {
      return data.slice(0, 10).filter(foo => foo.title.toLowerCase().includes(search))
    }
    else {
      return data.slice(0, 10)
    }
  }, [search, data])

  const handleSeen = (_id) => {
    instance({
      method: 'get',
      url: `/Message/seen/${_id}`
    }).then(() => {
      fetchData()
      onClose()
    })
  }
  
  return (
    <div className={`${open ? 'fixed' : 'hidden'} right-3 top-14 bottom-3 bg-white shadow flex flex-col gap-3 z-[1000000] rounded px-3 py-2 w-[360px]`}>
      <div className="flex gap-3 items-center">
        <div className="grow font-bold text-xl">
          Chats
        </div>
        <div className="flex gap-3">
          <Tooltip title="See all in messenger">
            <Button type="link" icon={<ExpandAltOutlined />}>

            </Button>
          </Tooltip>
          <Tooltip title="Close the messenger">
            <Button type="link" icon={<CloseOutlined />} onClick={onClose}>

            </Button>
          </Tooltip>
        </div>
      </div>
      <div>
        <Input prefix={<SearchOutlined />} placeholder="Search messenger" value={search} onChange={e => setSearch(e.target.value)}/>
      </div>
      <div className="flex gap-3 items-center overflow-auto shrink-0">
        <Button type="link" className="hover:!bg-primary/5 transition-all">Suppliers</Button>
        <Button type="link" className="hover:!bg-primary/5 transition-all">End-users</Button>
        <Button type="link" className="hover:!bg-primary/5 transition-all">Others</Button>
      </div>
      <div className="grow overflow-auto">
        {
          filteredData.map((foo, fooIndex) => {
            return (
              <Link to={foo.route ? foo.route+"/"+foo.tenderId+'/chat' : ''} onClick={() => handleSeen(foo.id)} key={`global-chat-thread-${fooIndex}`} className="py-3 px-2 flex items-center gap-3 transition-all hover:bg-primary/10 rounded cursor-pointer">
                <div className="flex flex-col grow overflow-hidden">
                  <Tooltip title={foo.title}>
                    <div className="truncate font-semibold overflow-hidden">
                      {foo.title}
                    </div>
                  </Tooltip>
                  <div className="text-sm text-gray-700">
                    {moment(foo.lastResponseDate).fromNow()}
                  </div>
                </div>
                {
                  foo.responseStatus == 2 &&
                  <div className="w-3 h-3 rounded-full shrink-0 bg-primary">
                  </div>
                }
              </Link>
            )
          })
        }
      </div>
      <div className="bg-white flex justify-center items-center pt-2">
        <Button type="link">
          See all in messenger
        </Button>
      </div>
    </div>
  )
}

export default Messenger