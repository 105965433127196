import { Table } from "antd"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { instance } from "utils/axios"

const Total = () => {
  const [ data, setData ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const location = useLocation()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `/AuctionUser/bidsummary/total/${location.state.id}?wcode=auction-detail`
    }).then(res => {
      console.log(res.data.responseData)
      setData(res.data.responseData)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }
  
  return (
    <div>
      <Table
        pagination={false}
        dataSource={data}
        loading={loading}
        size="small"
        columns={[
          {
            dataIndex: 'name',
            title: 'Name'
          },
          {
            dataIndex: 'mobile',
            title: 'Mobile'
          },
          {
            dataIndex: 'price',
            title: 'Price',
            render: (cell) => cell.toLocaleString("en-US"),
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.price - b.price,
          },
          {
            dataIndex: 'auctionDate',
            title: 'Entered date',
            render: cell => cell && dayjs(cell).format("YYYY/MM/DD HH:mm:ss"),
            width: 200,
            defaultSortOrder: 'descend',
            sorter: (a, b) => dayjs(a.auctionDate).unix() - dayjs(b.auctionDate).unix(),
          },
        ]}>

      </Table>
    </div>
  )
}

export default Total