import React, { useEffect, useState, useRef } from 'react'
import { Button, Modal, Form as AntForm, Dropdown, Space, Tooltip, Card, Radio, Skeleton, Input } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { Form, MainTable } from 'components'
import { instance } from 'utils/axios'
import { Link, useLocation } from 'react-router-dom'
import { Editor } from '@tinymce/tinymce-react';

const items = ({row, handleDelete, handleEdit}) => {  
  return (
    [
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleEdit(row)
        }}>Edit</a>,
        key: 0
      },
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleDelete(row.id)
        }}>Delete</a>,
        key: 1
      },
    ]
  )
}

const fields = () => {
  return [
    {
      label: 'Name',
      name: 'name',
      rules: [{required: true, message: 'Name is required!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    // {
    //   label: 'Template type',
    //   name: 'templateType',
    //   rules: [{required: true, message: 'Template type is required!'}],
    //   type: 'select',
    //   className: 'col-span-12 mb-0',
    //   inputProps: {
    //     className: 'w-full',
    //     options: [{label: 'Email', value: 1}, {label: 'Tender', value: 2}],
    //   }
    // }
  ]
}

function TemplateGroup() {
  const [ data, setData ] = useState()
  const [ fetchingData, setFetchingData ] = useState(false)
  const [ currentPage, setCurrentPage ] = useState(0)
  const [ open, setOpen ] = useState(false)
  const [ editData, setEditData ] = useState(null)
  const [ isEdit, setIsEdit ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ modal, contextHolder ] = Modal.useModal();  
  const editorRef = useRef(null);
  const [ form ] = AntForm.useForm();
  const [ eventForm ] = AntForm.useForm();
  const [ fetchingEvents, setFetchingEvents ] = useState(false)
  const [ events, setEvents ] = useState([])
  const [ selectedEvent, setSelectedEvent ] = useState(null)
  const [ editEvent, setEditEvent ] = useState(null)
  const [ type, setType ] = useState(1)
  const location = useLocation()

  useEffect(() => {
    // fetchData()
    if(selectedEvent) {
      fetchData()
    }
  },[selectedEvent])

  const fetchData = () => {
    setFetchingData(true)
    instance({
      method: 'get',
      url: `/Template/event/${selectedEvent}?type=2&wcode=tender-template`
    }).then((res) => {
      setData(res.data.responseData)
    }).catch((err) => {
    }).then(() => setFetchingData(false))
  }

  useEffect(() => {
    if(type) {
      fetchEvents()
    }
  }, [type])

  useEffect(() => {
    if(editEvent) {
      eventForm.setFieldsValue(editData)
    }
  }, [editEvent])

  const fetchEvents = () => {
    setSelectedEvent(null)
    setData(null)
    setFetchingEvents(true)
    instance({
      method: 'get',
      url: `/TemplateEvent/group/${type}?wcode=tender-template`
    }).then((res) => {
      setEvents(res.data.responseData)
    }).catch((err) => {
    }).then(() => setFetchingEvents(false))
  }

  const handleDelete = (id) => {
    modal.confirm({
      title: 'Warning!',
      content: 'You are about to delete this row of data',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: 'delete',
            url: `/TemplateEvent/${id}?wcode=tender-template`
          }).then((res) => {
            resolve()
          }).catch((err) => {
            reject()
          }).then(() => {
            fetchEvents()
          })
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

  // const handleSubmit = (values) => {
  //   setLoading(true)
  //   if(editData){
  //     instance({
  //       method: 'put',
  //       url: '/TemplateGroup?wcode=tender-template',
  //       data: {
  //         ...values,
  //         id: editData.id,
  //         templateType: editData.templateType
  //       }
  //     }).then((res) => {
  //       handleCloseModal()
  //       fetchData()
  //     }).catch((err) => {

  //     }).then(() => setLoading(false))
  //   }
  //   else{
  //     instance({
  //       method: 'post',
  //       url: '/TemplateGroup?wcode=tender-template',
  //       data: {
  //         ...values,
  //         templateType: location.pathname.includes("tender") ? 2 : 1
  //       }
  //     }).then((res) => {
  //       handleCloseModal()
  //       fetchData()
  //     }).catch((err) => {

  //     }).then(() => setLoading(false))
  //   }
  // }

  const handleSubmit = (values) => {
    setLoading(true)
    if(data){
      instance({
        method: 'put',
        url: '/Template?wcode=tender-template',
        data: {
          ...values,
          content: editorRef.current?.getContent() ?? '',
          id: data.id,
          templateGroupId: type,
          templateEventId: selectedEvent,
          templateType: 2
        }
      }).then((res) => {
        fetchData()
        setSelectedEvent(null)
      }).catch((err) => {

      }).then(() => setLoading(false))
    }
    else{
      instance({
        method: 'post',
        url: '/Template?wcode=tender-template',
        data: {
          ...values,
          content: editorRef.current?.getContent() ?? '',
          templateGroupId: type,
          templateEventId: selectedEvent,
          templateType: 2
        }
      }).then((res) => {
        fetchData()
        setSelectedEvent(null)
      }).catch((err) => {

      }).then(() => setLoading(false))
    }
  }

  const handleAdd = () => {
    setIsEdit(true)
    setEditData(null)
    setEditEvent(null)
    setOpen(true)
  }

  const handleEdit = (row) => {
    setEditData(row)
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
    form.resetFields()
  }

  const handleAddEvent = (values) => {
    if(editEvent) {
      instance({
        method: 'put',
        url: `/TemplateEvent?wcode=tender-template`,
        data: {
          id: editEvent.id,
          name: values.name,
          templateType: 2,
          templateGroupId: type
        }
      }).then(res => {
        fetchEvents()
        setOpen(false)
      }).catch(err => {

      }).then(() => {
        setLoading(false)
      })
    }
    else {
      instance({
        method: 'post',
        url: `/TemplateEvent?wcode=tender-template`,
        data: {
          name: values.name,
          templateType: 2,
          templateGroupId: type
        }
      }).then(res => {
        fetchEvents()
        setOpen(false)
      }).catch(err => {

      }).then(() => {
        setLoading(false)
      })
    }
  }
  
  return (
      <div className='p-4'>
        {/* <MainTable
          dataTable={data}
          columns={columns}
          setCurrentPage={setCurrentPage}
          loading={loading}
          pagination={false}
          title={location.pathname.includes("tender") ? 'Tender template group' : 'Template group'}
          handleAdd={!location.pathname.includes("tender") && handleAdd}
        /> */}
        {
          loading ?
          <Skeleton></Skeleton>
          :
          <div className="grid grid-cols-12 gap-3">
            <div className="col-span-3 flex flex-col gap-3">
              <div className="flex justify-between">
                <div className="text-mute text-xl py-1">
                  Filters
                </div>
              </div>
              <Card title="Tender type" size="small" className="rounded-none">
                <Radio.Group onChange={e => setType(e.target.value)} value={type}>
                  <Radio.Button value={1}>EOI</Radio.Button>
                  <Radio.Button value={2}>RFQ</Radio.Button>
                  <Radio.Button value={3}>Travel</Radio.Button>
                  <Radio.Button value={4}>Freight</Radio.Button>
                </Radio.Group>
              </Card>
              <Card title="Templates events" size="small" className="rounded-none">
                {
                  fetchingEvents ?
                  <Skeleton></Skeleton>
                  :
                  <div className="flex flex-col gap-1">
                    {
                      events.map((foo, fooIndex) => {
                        return (
                          <Button 
                            type="link"
                            className={selectedEvent == foo.id ? "w-full flex justify-start text-primary !bg-primary/10 group" : "w-full flex justify-start text-primary group" }
                            key={`event-${fooIndex}`}
                            onClick={() => setSelectedEvent(foo.id)}>
                            <div className="flex gap-2 justify-between w-full">
                              {foo.name}
                              <div className="hidden gap-2 items-center group-hover:flex">
                                <Tooltip title="Edit">
                                  <EditOutlined onClick={(e) => {
                                    e.stopPropagation()
                                    setEditEvent(foo)
                                    eventForm.setFieldsValue(foo)
                                    setOpen(true)
                                  }}/>
                                </Tooltip>
                                <Tooltip title="Delete">
                                  <DeleteOutlined className="text-red-400" onClick={(e) => {
                                    e.stopPropagation()
                                    handleDelete(foo.id)
                                  }}/>
                                </Tooltip>
                              </div>
                            </div>
                          </Button>
                        )
                      })
                    }
                    <Button className="mt-3" type="dashed" onClick={() => handleAdd()}>Add new event</Button>
                  </div>
                }
              </Card>
            </div>
            <div className="col-span-9">
              {
                fetchingData ?
                <Skeleton></Skeleton>
                :
                data &&
                <Card>
                  <Form
                    form={form}
                    fields={fields()}
                    layout="vertical"
                    onFinish={handleSubmit}
                    editData={data}
                    isEdit={isEdit}
                    className="gap-4 mb-4">
                    <AntForm.Item className='col-span-12' name='content' label="Template content">
                      <Editor
                        className="col-span-12"
                        apiKey='79knxv9hbtuofr3q2v730liskq4whvaejztxnwq7w6qe2km3'
                        onInit={(evt, editor) => {
                          editorRef.current = editor
                          if(data) {
                            editor.setContent(data.content)
                          }
                        }}
                        initialValue=""
                        init={{
                          height: 500,
                          menubar: false,
                          branding: false,
                          plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                          ],
                          toolbar: 'undo redo | blocks | ' +
                          'bold italic forecolor | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | ' +
                          'removeformat | image media',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          file_picker_callback: function (callback, value, meta) {
                            var input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');
                            input.onchange = function () {
                              var file = this.files[0];
                              var reader = new FileReader();
                              reader.onload = function (e) {
                                callback(e.target.result, {
                                  alt: file.name
                              });

                              };
                              reader.readAsDataURL(file);
                            };
                            input.click();
                          },
                        }}/>
                    </AntForm.Item>
                    <AntForm.Item className="col-span-12 flex justify-end">
                      <Button onClick={() => form.submit()}>
                        Save
                      </Button>
                    </AntForm.Item>
                  </Form>
                </Card>
              }
            </div>
          </div>
        }
        <Modal
          title={editEvent ? 'Edit event' : 'Add new event'}
          open={open}
          destroyOnClose
          maskClosable={false}
          okText="Save"
          onCancel={() => {
            eventForm.resetFields()
            setOpen(false)
          }}
          onOk={eventForm.submit}
          confirmLoading={loading}>
          <AntForm
            layout='vertical'
            form={eventForm}
            onFinish={handleAddEvent}>
            <AntForm.Item name="name" label="Name">
              <Input></Input>
            </AntForm.Item>
          </AntForm>
        </Modal>
        {contextHolder}
      </div>
  )
}

export default TemplateGroup
