import { useContext, useEffect, useRef, useState } from "react"
import { instance } from "utils/axios"
import { useParams } from "react-router-dom"
import { Tooltip, Input, Button, Tabs, Dropdown, Modal, Popconfirm, Upload, Form, Select, Mentions, Checkbox } from 'antd'
import { SendOutlined, PlusOutlined, MoreOutlined, CloseOutlined, FileAddOutlined } from '@ant-design/icons'
import { TiArrowForward } from "react-icons/ti";
import moment from "moment"
import { EndUserChat, PrivateChat } from ".."
import { AuthContext } from "contexts"
import { SignalRContext } from "contexts/socketProvider"
import { twMerge } from "tailwind-merge"

const MessageComponent = ({threadData, signalName}) => {
  const [ data, setData ] = useState(threadData)
  const [ fetching, setFetching ] = useState(true)
  const [ loading, setLoading ] = useState(false)
  const [ message, setMessage ] = useState("")
  const [ comment, setComment ] = useState("")
  const [ selectActivated, setSelectActivated ] = useState(false)
  const [ selectedChat, setSelectedChat ] = useState([])
  const [ fileList, setFileList ] = useState([])
  const [ threads, setThreads ] = useState([])
  const { state } = useContext(AuthContext)
  const [ error, setError ] = useState(false)
  const [ reply, setReply ] = useState(null)
  const messageContainer = useRef()
  const uploadRef = useRef()
  const [ forwardForm ] = Form.useForm()
  const { id } = useParams()

  useEffect(() => {
    instance({
      method: 'get',
      url: `/Message/tender/buyer/${id}?wcode=tender-detail`
    }).then(res => {
      console.log(res.data.responseData)
      setThreads(res.data.responseData)
    }).catch(err => {

    }).then(() => {
      setFetching(false)
    })
  }, [])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `/Message/tender/main/${id}?wcode=tender-detail`
    }).then(res => {
      setData(res.data.responseData)
    }).catch(err => {

    }).then(() => {
      setFetching(false)
    })
  }

  SignalRContext.useSignalREffect(signalName, (_message) => {
    setData(prev => ({
      thread: prev.thread,
      messages: [_message, ...prev.messages]
    }))
  }, [signalName]);

  const handleDelete = (_message) => {
    instance({
      method: 'delete',
      url: `/Message/undo/${_message.id}?wcode=tender-detail`
    }).then(res => {
      fetchData()
    }).catch(err => {

    })
  }
  
  const renderMessages = () => {
    let tmp = []

    data.messages.filter(foo => foo.status != 9).map((message, messageIndex) => {
      tmp.push(
        <div className="flex gap-3 items-center">
          {
            selectActivated &&
            <div className="pt-4">
              <Checkbox 
                checked={selectedChat.find(foo => foo.id == message.id)}
                onChange={e => {
                  if(selectedChat.find(foo => foo.id == message.id)) {
                    setSelectedChat(prev => prev.filter(foo => foo.id != message.id))
                  }
                  else {
                    setSelectedChat(prev => [...prev, message])
                  }
                }}>

              </Checkbox>
            </div>
          }
          <div key={messageIndex} className={message.isSupplier ? 'flex gap-3 items-start grow' : 'flex gap-3 items-start justify-end grow'}>
            <div className="max-w-[60%] relative">
              {
                message.isSupplier ?
                <span className="text-xs text-gray-300 shrink-0 line-clamp-1">{message.supplierName}</span>
                :
                <></>
              }
              <div className="flex">
                {
                  message.isSupplier ?
                  <div className="w-8 h-8 rounded-full bg-gray-100 mr-3 shrink-0"></div> : 
                  <div>
                    <Dropdown
                      trigger={["click"]}
                      menu={{
                        items: [
                          !selectActivated && {
                            label: <a onClick={e => {
                              e.preventDefault()
                              setSelectActivated(true)
                            }}>
                              Select
                            </a>,
                            key: 2
                          },
                          {
                            label: (
                              <Popconfirm
                                title="Delete the task"
                                description="Are you sure to delete this task?"
                                onConfirm={() => handleDelete(message)}
                                onCancel={() => {}}
                                okText="Yes"
                                cancelText="No">
                              <a onClick={e => {
                                e.preventDefault()
                              }}>
                                Delete
                              </a>
                            </Popconfirm>
                            ),
                            key: 1
                          },
                        ]
                      }}>
                      <Button type="link" icon={<MoreOutlined/>}></Button>
                    </Dropdown>
                  </div>
                }
                <Tooltip title={moment(message.createdAt).format("YYYY-MM-DD HH:mm:ss")} >
                  <div className={twMerge("font-medium px-4 py-1 shadow bg-gray-100 rounded-xl transition-all", selectActivated && selectedChat.find(foo => foo.id == message.id) && "bg-primary text-white")}>
                    {
                      message.attachment ? 
                      <a href={message.attachment} target="_blank" className="text-link">
                        Preview attachment
                      </a>
                      :
                      message.body
                    }
                  </div>
                </Tooltip>
                {
                  message.isSupplier ?
                  <div>
                    <Dropdown
                      trigger={["click"]}
                      menu={{
                        items: [
                          !selectActivated && {
                            label: <a onClick={e => {
                              e.preventDefault()
                              setSelectActivated(true)
                              setSelectedChat([message])
                            }}>
                              Select
                            </a>,
                            key: 2
                          },
                          {
                            label: <a onClick={e => {
                              e.preventDefault()
                              setReply(true)
                              setSelectedChat([message])
                              setMessage(`"${message.body}"`)
                            }}>
                              Reply
                            </a>,
                            key: 0
                          },
                          {
                            label: <a onClick={e => {
                              e.preventDefault()
                              setReply(null)
                              setSelectedChat([message])
                              forwardForm.setFieldValue("message", message.body)
                            }}>
                              Forward message
                            </a>,
                            key: 1
                          },
                        ]
                      }}>
                      <Button type="link" icon={<MoreOutlined/>}></Button>
                    </Dropdown>
                  </div>
                  : <div className="w-8 h-8 rounded-full bg-gray-100 ml-3 shrink-0"></div>
                }
              </div>
            </div>
            
          </div>
        </div>
      )
    })

    return tmp
  }

  const handleSubmit = () => {
    setLoading(true)
    let tmp = []

    if(fileList.length > 0) {
      fileList.map((foo) => {
        tmp.push(
          instance({
            method: 'post',
            url: `/Message/reply`, 
            data: {
              messageThreadId: data.thread.id,
              tenderId: id,
              body: '',
              attachment: foo.url,
              messageId: selectedChat[0] && selectedChat[0].id
            }
          })
        )
      })
    }

    if(message && message.length > 0) {
      tmp.push(
        instance({
          method: 'post',
          url: `/Message/reply`, 
          data: {
            messageThreadId: data.thread.id,
            tenderId: id,
            body: message,
            messageId: selectedChat[0] && selectedChat[0].id
          }
        })
      )
    }
    else {
      setError(true)
    }
    Promise.all(tmp).then(res => {
      setLoading(false)
      setSelectedChat([])
      messageContainer.current.focus()
    }).catch(err => {

    }).then(() => {
      fetchData()
      setLoading(false)
      setFileList([])
      setMessage('')
    })
  }

  const handleForward = (values) => {
    setLoading(true)
    instance({
      method: 'post',
      url: `/Message/reply`, 
      data: {
        messageThreadId: values.threadId,
        tenderId: id,
        body: values.message,
      }
    }).then(() => {
      fetchData()
      setSelectedChat([])
      setReply(false)
    }).catch(() => {

    }).finally(() => {
      setLoading(false)
    })
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]

    const fd = new FormData()

    fd.append("file", file)
    
    instance({
      method: 'post',
      url: `File/upload`,
      data: fd,
      onUploadProgress: progressEvent => console.log(progressEvent)
    }).then(res => {
      setFileList(prev => {
        let tmp = Object.assign([], prev)
        tmp.push({
          uid: tmp.length,
          name: res.data.responseData.split("\\").at(-1),
          url: `${process.env.REACT_APP_CDN_URL}/${res.data.responseData}`,
          status: 'done'
        })

        return tmp
      })
    }).catch(err => {
      console.log(err)
    })
  }

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  
  return (
    <div className="bg-white border-r border-b border-l border-gray-200/60">
      <div className="p-4 flex flex-col-reverse gap-2 flex-1 overflow-auto h-[600px]">
        {renderMessages()}
      </div>
      <div className="p-4 bg-white border-t">
        {
          selectActivated ?
          <div className="flex gap-4 items-center">
            <div>
              <Button icon={<CloseOutlined />} type="text" onClick={() => {
                setSelectedChat([])
                setSelectActivated(false)
              }}></Button>
            </div>
            <div className="grow">
              {selectedChat.length} selected
            </div>
            <div>
              <Button icon={<TiArrowForward className="text-xl"/>} type="text" onClick={() => {
                setReply(null)
                forwardForm.setFieldValue("message", selectedChat.map((foo, fooIndex) => `${fooIndex != 0 ? "\n" : ""}${foo.body}`).join())
                setSelectActivated(false)
              }}></Button>
            </div>
          </div>
          :
          <div className="flex gap-4">
            <input type="file" ref={uploadRef} className="!hidden" onChange={handleFileChange} value={[]}></input>
            <Tooltip title="Attach a file">
              <Button icon={<FileAddOutlined />} type="text" onClick={() => {
                uploadRef.current && uploadRef.current.click()
              }}>
                
              </Button>
            </Tooltip>
            <div className="flex flex-col grow border rounded-[10px] bg-gray-100 custom-chat-reply">
              {
                fileList.length > 0 ?
                <Upload
                  className="mb-2 flex flex-nowrap overflow-auto"
                  fileList={fileList}
                  listType="picture-card"
                  action={`${process.env.REACT_APP_MAIN_API_URL}File/upload`}
                  headers={{
                    Authorization: `Bearer ${state.token}`,
                  }}
                  onChange={handleChange}
                  onRemove={(file) => {
                    instance({
                      method: 'delete',
                      url: `File/remove`,
                      data: file.response.responseData
                    })
                  }}>
                  <button
                    style={{
                      cursor: 'pointer',
                      border: 0,
                      background: 'none',
                    }}
                    type="button"
                  >
                    <PlusOutlined />
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Upload
                    </div>
                  </button>
                </Upload>
                :
                <></>
              }
              <Mentions status={error && "error"}
                variant="borderless" 
                ref={messageContainer} 
                disabled={loading} 
                placeholder="Reply" 
                value={message} 
                onChange={e => {
                  setError(false)
                  setMessage(e)
                }} 
                onPressEnter={handleSubmit}>

              </Mentions>
              {/* <Input 
                status={error && "error"}
                variant="borderless" 
                ref={messageContainer} 
                disabled={loading} 
                placeholder="Reply" 
                value={message} 
                onChange={e => {
                  setError(false)
                  setMessage(e.target.value)
                }} 
                onPressEnter={handleSubmit}>

              </Input> */}
            </div>
            <Tooltip title="Send">
              <Button icon={<SendOutlined/>} type="text" onClick={handleSubmit} loading={loading}>
                
              </Button>
            </Tooltip>
          </div>
        }
      </div>
      <Modal
        title={'Forward chat'}
        open={selectedChat[0] && !reply && ! selectActivated}
        onCancel={() => {
          setSelectedChat([])
        }}
        onOk={forwardForm.submit}
        loading={loading}>
        <div className="flex flex-col gap-3">
          <Form form={forwardForm} layout="vertical" onFinish={handleForward} disabled={loading}>
            <Form.Item name="threadId" label="Forward to" rules={[{required: true, message: 'Please select thread!'}]}>
              <Select placeholder="Select thread" options={threads.map((foo, fooIndex) => ({value: foo.id, label: foo.title}))}></Select>
            </Form.Item>
            <Form.Item name="message" label="Forwarding message">
              <Input.TextArea autoSize={true}></Input.TextArea>
            </Form.Item>
          </Form>
        </div>
        
      </Modal>
    </div>
  )
}

export default MessageComponent