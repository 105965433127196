import { EllipsisOutlined } from '@ant-design/icons'
import { Dropdown } from 'antd'
import moment from 'moment'

const ChatBubble = ({data, handleForward}) => {

  return (
    <div className="flex justify-start gap-3 px-4">
      <div>
        <img src={"https://via.placeholder.com/50"} className="rounded-full shadow-md"></img>
      </div>
      <div className="grow relative">
        <div className="border border-gray-300 rounded overflow-hidden">
          <div className="py-2 px-4 flex justify-between items-center bg-gray-200">
            <div><span className='font-bold'>{data.supplierName ? data.supplierName : `${data.firstName} ${data.lastName}`}</span> commented {moment(data.createdAt).fromNow()}</div>
            <div className="cursor-pointer hover:bg-slate-100 px-2 rounded transition-all">
              <Dropdown
                menu={{
                  items: [
                    {
                      key: 0,
                      label: <a onClick={e => {
                        e.preventDefault()
                        handleForward(data)
                      }}>
                        Forward
                      </a>,

                    }
                  ]
                }}>
                <EllipsisOutlined />
              </Dropdown>
            </div>
          </div>
          <div className="p-4 bg-white" dangerouslySetInnerHTML={{__html: data.body}}>
          </div>
        </div>
        <div className="h-10 w-[2px] bg-gray-300 ml-4">

        </div>
      </div>
    </div>
  )
}

export default ChatBubble