export default () => {
  return [
    {
      label: 'Name',
      name: 'name',
      rules: [{required: true, message: 'Name is required!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },  
    {
      label: 'Survey type',
      name: 'surveyType',
      type: 'select',
      rules: [{required: true, message: 'Survey type is required!'}],
      className: 'col-span-6 mb-0',
      inputProps: {
        className: 'w-full',
        options: [
          { label: 'Once', value: 1 }, 
          { label: 'Tender', value: 2 },   
      ],
      }
    },
    {
      label: 'Status',
      name: 'status',
      type: 'select',
      rules: [{required: true, message: 'Status is required!'}],
      className: 'col-span-6 mb-0',
      inputProps: {
        className: 'w-full',
        options: [
          { label: 'Active', value: 1 }, 
          { label: 'Inactive', value: 0 },   
      ],
      }
    },
    {
      label: 'Start date',
      name: 'startDate',
      type: 'date',
      rules: [{required: true, message: 'Start date is required!'}],
      className: 'col-span-6 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'End date',
      name: 'endDate',
      type: 'date',
      rules: [{required: true, message: 'End date is required!'}],
      className: 'col-span-6 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },  
    
    {
      label: 'Image',
      name: 'image',
      type: 'file', 
      rules: [{required: true, message: 'Image is required!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },

    
  ]
}