import React, { useEffect, useMemo, useState } from 'react'
import { Button, Modal, Form as AntForm, Dropdown, Space, Tag, Card, Input, Tooltip, Checkbox, Skeleton } from 'antd'
import { EditOutlined, PlusCircleOutlined, MoreOutlined, RightOutlined } from '@ant-design/icons'
import { Form, MainTable, Filters } from 'components'
import { instance } from 'utils/axios'
import { Link } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component';
import SurveyForm from './SurveyForm'
import moment from 'moment'
const { Search } = Input

const validationStatusEnum = {
  0: {
    title: 'New',
    color: '#00aaad'
  },
  1: {
    title: 'Requested',
    color: '#427EC1'
  },
  2: {
    title: 'Returned',
    color: '#D55727'
  },
  3: {
    title: 'Checked',
    color: '#009A72'
  },
}

const prequalificationStatusEnum = {
  0: {
    title: 'Not qualified',
    color: '#D55727'
  },
  1: {
    title: 'Qualified',
    color: '#009A72'
  }
}

const items = ({row, block}) => {  
  return (
    [
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          block(row)
        }}>Block</a>,
        key: 0
      },
    ]
  )
}

function SurveySuppliers() {
  const [ data, setData ] = useState({
    data: [],
    total: 0
  })
  const [ filter, setFilter ] = useState({})
  const [ currentPage, setCurrentPage ] = useState(0)
  const [ open, setOpen ] = useState(false)
  const [ editData, setEditData ] = useState(null)
  const [ isEdit, setIsEdit ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ modal, contextHolder ] = Modal.useModal();
  const [ surveyModalOpen, setSurveyModalOpen ] = useState(false)
  const [ searchValue, setSearchValue ] = useState("") 
  const [ hasMore, setHasMore ] = useState(true)
  const [ form ] = AntForm.useForm();

  useEffect(() => {
    fetchData()
  },[filter])

  const fetchData = () => {
    setLoading(true)
    setHasMore(true)
    setData({total: 0, data: []})
    instance({
      method: 'get',
      url: '/SurveySupplier?wcode=supplier',
      params: {
        ...filter,
        pageSize: 20,
        page: currentPage
      },
      paramsSerializer: {
        indexes: null
      }
    }).then((res) => {
      if(res.data.responseData.data.length < 20) {
        setHasMore(false)
      }
      setData(res.data.responseData)
    }).catch((err) => {
    }).then(() => setLoading(false))
  }

  const fetchNext = () => {
    instance({
      method: 'get',
      url: '/SurveySupplier?wcode=supplier',
      params: {
        filter,
        pageSize: 20,
        page: currentPage+1
      },
      paramsSerializer: {
        indexes: null
      }
    }).then((res) => {
      if(res.data.responseData.data.length < 20) {
        setHasMore(false)
      }
      setData(prev => ({total: res.data.responseData.total, data: [...prev.data, ...res.data.responseData.data]}))
      setCurrentPage(currentPage+1)
    }).catch((err) => {
    })
  }

  const handleSubmit = (values) => {
    setLoading(true)
    if(editData){
      instance({
        method: 'put',
        url: '/sector',
        data: {
          ...values,
          id: editData.id
        }
      }).then((res) => {
        handleCloseModal()
        fetchData()
      }).catch((err) => {

      }).then(() => setLoading(false))
    }
    else{
      instance({
        method: 'post',
        url: '/sector',
        data: {
          ...values
        }
      }).then((res) => {
        handleCloseModal()
        fetchData()
      }).catch((err) => {

      }).then(() => setLoading(false))
    }
  }

  const handleCloseModal = () => {
    setSurveyModalOpen(false)
    form.resetFields()
  }

  const onSearch = (values) => {
    setFilter(values)
  }

  const filteredData = useMemo(() => {
    return data.data.filter(foo => foo.content.toLowerCase().includes(searchValue.toLowerCase()))
  }, [data, searchValue])
  
  return (
      <div className='p-4'>
        <div className="grid grid-cols-12 gap-3">
          <div className='col-span-12'>
            <Filters wcode="survey-suppliers" onSearch={onSearch}/>
          </div>
          <Card className='col-span-12' size='small'>
            <div className="flex flex-col gap-3">
              <div className="font-semibold flex gap-3 items-center justify-between">
                <div className="shrink-0">
                  {filteredData.length} of {data.total} Results
                </div>
                <Search 
                  placeholder="Search"
                  className='border-none' 
                  enterButton 
                  onChange={e => setSearchValue(e.target.value)} />
                <div className='flex gap-2'>
                  <Tooltip title={"Take survey from suppliers"}>
                    <Button type="primary" onClick={() => setSurveyModalOpen(true)}>
                      Add new
                    </Button>
                  </Tooltip>
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <InfiniteScroll
                  dataLength={data.data.length}
                  next={fetchNext}
                  loader={<div className='text-center font-bold'>Fetching...</div>}
                  className="flex flex-col gap-1"
                  hasMore={hasMore}
                  endMessage={
                    <p className="text-center font-bold">
                      No more data
                    </p>
                  }>
                  <table className="oyu-table">
                    <colgroup>
                      <col className='w-[50px]'></col>
                      <col></col>
                      <col></col>
                      <col className="w-[150px]"></col>
                      <col className="w-[150px]"></col>
                      <col className="w-[150px]"></col>
                    </colgroup>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Survey</th>
                        <th>Supplier</th>
                        <th>Status</th>
                        <th>Start date</th>
                        <th>End date</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      filteredData.map((foo, fooIndex) => {
                        return (
                          <tr key={`row-${fooIndex}`} className="hover:bg-gray-100 transition-all">
                            <td className="text-center">
                              {fooIndex+1}
                            </td>
                            <td className="text-center">
                              {foo.survey.name}
                            </td>
                            <td className="text-center">
                              {foo.supplier.name}
                            </td>
                            <td className="text-center">
                              <Tag color={foo.status == 1 ? 'green' : 'yellow'}>{foo.status == 1 ? 'Submitted' : 'Not submitted'}</Tag>
                            </td>
                            <td className="text-center">
                              {moment(foo.startDate).format("YYYY/MM/DD")}
                            </td>
                            <td className="text-center">
                              {moment(foo.endDate).format("YYYY/MM/DD")}
                            </td>
                          </tr>
                        )
                      })
                    }
                    </tbody>
                  </table>
                  {/* {
                    loading ?
                    <Skeleton active></Skeleton>
                    :
                    filteredData.map((_survey, _surveyIndex) => {
                      return (
                        <Card 
                          type="inner" 
                          key={`supplier-${_surveyIndex}`} 
                          className={"p-0 rounded-none transition-all"}
                          bodyStyle={{padding: 0}}>
                          <div className="grid grid-cols-12 gap-x-2">
                            <div className='col-span-7 flex items-center gap-4 p-4'>
                              <div className="w-12 h-12 rounded-full flex justify-center items-center text-white bg-primary text-3xl">
                                {_survey.content.slice(0,1)}
                              </div>
                              <div className="flex-1 overflow-hidden">
                                <Tooltip title={_survey.name}>
                                  <Link to={`${_survey.id}`} state={_survey} className="text-lg text-primary overflow-hidden text-ellipsis whitespace-nowrap hover:underline">{_survey.name}</Link>
                                </Tooltip>
                                <div className='text-mute'>ID: {_survey.id}</div>
                              </div>
                            </div>
                            <div className='col-span-5 flex justify-between'>
                              <div className="flex p-4 gap-4">
                                <div className="flex flex-col w-[80px]">
                                  <label className="text-mute text-center">Survey</label>
                                  <div className="text-center">{_survey.surveyId}</div>
                                </div>
                                <div className="flex flex-col w-[80px]">
                                  <label className="text-mute text-center">Supplier</label>
                                  <div className="text-center">{_survey.supplierId}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      )
                    })
                  } */}
                </InfiniteScroll>
                
              </div>
            </div>
          </Card>
        </div>
        <Modal
          width={"80vw"}
          title={editData ? <div className='flex items-center justify-start gap-2'><EditOutlined/>Edit</div> : <div className='flex items-center justify-start gap-2'><PlusCircleOutlined/>Add</div>}
          open={surveyModalOpen}
          destroyOnClose
          maskClosable={false}
          okText='Save'
          onOk={form.submit}
          onCancel={handleCloseModal}
        >
          <SurveyForm setModal={setSurveyModalOpen} form={form}/>
        </Modal>
        {contextHolder}
      </div>
  )
}

export default SurveySuppliers
