import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { EditOutlined, PlusCircleOutlined, MoreOutlined } from '@ant-design/icons'
import { Button, Form as AntForm, Modal, Space, Dropdown, Input, Tooltip, Tag, Skeleton, Card } from 'antd';
import { MainTable, Form, Filters } from 'components';
import { instance } from 'utils/axios';
import moment from 'moment';
import Filter from './Filter';
import InfiniteScroll from 'react-infinite-scroll-component';

const { Search } = Input

const statusEnum = {
  0: {
    title: 'Draft',
    color: ''
  },
  1: {
    title: 'In progress',
    color: ''
  },
  2: {
    title: 'Approved',
    color: ''
  },
  3: {
    title: 'Declined',
    color: ''
  },
  4: {
    title: 'Expired',
    color: ''
  },
  5: {
    title: 'Supplier requested',
    color: ''
  }
}

const resultStatusEnum = {
  0: {
    title: 'Draft',
    color: ''
  },
  1: {
    title: 'In progress',
    color: ''
  },
  2: {
    title: 'Approved',
    color: ''
  },
  3: {
    title: 'Declined',
    color: ''
  }
}

const items = ({row, handleEdit, handleDelete}) => {  
  return (
    [
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleEdit(row)
        }}>Edit</a>,
        key: 0
      },
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleDelete(row.id)
        }}>Delete</a>,
        key: 1
      },
    ]
  )
}

const AuditQualification = ({}) => {
  const [ data, setData ] = useState({
    total: 0,
    data: []
  })
  const [ selectAudit, setSelectAudit ] = useState([])
  const [ supplier, setSupplier ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const [ open, setOpen ] = useState(false)
  const [ editData, setEditData ] = useState(null)
  const [ isEdit, setIsEdit ] = useState(false)
  const [ modal, contextHolder ] = Modal.useModal();
  const [ activeTab, setActiveTab ] = useState(0)
  const [ searchValue, setSearchValue ] = useState("")
  const [ filter, setFilter ] = useState({
    type: 1
  })
  const [ page, setPage ] = useState(1)
  const [ hasMore, setHasMore ] = useState(true)
  const [ form ] = AntForm.useForm();

  useEffect(() => {
    getSupplier()
    getAudit()
  }, [])

  useEffect(() => {
    fetchData()
    setPage(1)
    setHasMore(true)
  }, [filter])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `/AuditSupplier?wcode=audit-qualification`,
      params: filter,
      paramsSerializer: {
        indexes: null,
        page: page,
        pageSize: 20
      }
    }).then(res => {
      if(res.data.responseData.data.length < 20) {
        setHasMore(false)
      }
      setData(res.data.responseData)
      setPage(page+1)
    }).catch(err=> {

    }).then(() => {
      setLoading(false)
    })
  } 
  const getSupplier = () => {
    instance({
      method: 'get',
      url: `/Supplier/find?wcode=audit-qualification`
    }).then(res => {
      setSupplier(res.data.responseData.map(foo => ({value: foo.id, label: foo.name})))
    }).catch(err=> {

    }).then(() => {
      setLoading(false)
    })
  }
  const getAudit = () => {
    instance({
      method: 'get',
      url: `/Audit?wcode=audit-qualification`
    }).then(res => {
      setSelectAudit(res.data.responseData.map(foo => ({value: foo.id, label: foo.name})))
    }).catch(err=> {

    }).then(() => {
      setLoading(false)
    })
  }

  const fetchNext = () => {
    instance({
      method: 'get',
      url: `/AuditSupplier?wcode=audit-qualification`,
      params: filter,
      paramsSerializer: {
        indexes: null,
        page: page,
        pageSize: 20
      }
    }).then(res => {
      if(res.data.responseData.data.length < 20) {
        setHasMore(false)
      }
      setData(res.data.responseData)
      setPage(page+1)
    }).catch(err=> {

    }).then(() => {
      setLoading(false)
    })
  }

  const handleCloseModal = () => {
    setOpen(false)
    form.resetFields()
  }
  
  const fields = () => {
    return [
      {
        label: 'Name',
        name: 'name',
        rules: [{required: true, message: 'Name is required!'}],
        className: 'col-span-12 mb-0',
        inputProps: {
          className: 'w-full',
        }
      },
      {
        label: 'Audit',
        name: 'auditId',
        type: 'select',
        rules: [{required: true, message: 'Audit is required!'}],
        className: 'col-span-12 mb-0',
        inputProps: {
          className: 'w-full',
          options:  selectAudit
        }
      },
      {
        label: 'Supplier',
        name: 'supplierId',
        type: 'select',
        rules: [{required: true, message: 'Supplier is required!'}],
        className: 'col-span-12 mb-0',
        inputProps: {
          className: 'w-full',
          options:  supplier,
          showSearch: true,
          filterOption: (input, option) => (option?.label ?? '').includes(input),
          filterSort: (optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
        }
      },
      {
        label: 'Status',
        name: 'status',
        type: 'select',
        rules: [{required: true, message: 'Status is required!'}],
        className: 'col-span-12 mb-0',
        inputProps: {
          className: 'w-full',
          options:  [{value:1, label:'Acitve'},
                      {value:0, label:'Inactive'}]
        }
      },
      {
        label: 'Result status',
        name: 'resultStatus',
        type: 'select',
        rules: [{required: true, message: 'Result status is required!'}],
        className: 'col-span-12 mb-0',
        inputProps: {
          className: 'w-full',
          options:  [{value:1, label:'Acitve'},
                      {value:0, label:'Inactive'}]
        }
      },
      {
        label: 'Start date',
        name: 'startDate',
        type: 'date',
        rules: [{required: true, message: 'Start date is required!'}],
        className: 'col-span-12 mb-0',
        inputProps: {
          className: 'w-full',
          showTime: true

        }
      },
      {
        label: 'End date',
        name: 'endDate',
        type: 'date',
        rules: [{required: true, message: 'End date is required!'}],
        className: 'col-span-12 mb-0',
        inputProps: {
          className: 'w-full',
          showTime: true
        }
      },
      
      {
        label: 'Description',
        name: 'description',
        type: 'textarea',
        rules: [{required: true, message: 'Description is required!'}],
        className: 'col-span-12 mb-0',
        inputProps: {
          className: 'w-full',
        }
      }
    ]
  }

  const handleChange = (_index) => {
    setData([])
    setLoading(true)
    setActiveTab(_index)
  }

  const handleEdit = (_row) => {
    setEditData(_row)
    setOpen(true)
  }

  const handleAddNew = () => {
    setEditData(null)
    setOpen(true)
  }

  const handleSubmit = (values) => {
    if(editData) {
      instance({
        method: 'put',
        url: `/AuditSupplier?wcode=audit-qualification`,
        data: {
          ...values,
          id:editData.id,
          auditType: activeTab+1,
        }
      }).then(() => {
        setOpen(false)
      }).catch(() => {

      }).then(() => {
        setLoading(false)
        fetchData()
      })
    }
    else {
      instance({
        method: 'post',
        url: `/AuditSupplier?wcode=audit-qualification`,
        data: {
          ...values,
          auditType: activeTab+1,
        }
      }).then(() => {
        setOpen(false)
      }).catch(() => {

      }).then(() => {
        setLoading(false)
        fetchData()
      })
    }
  }

  const handleDelete = (id) => {
    modal.confirm({
      title: 'Warning!',
      content: 'You are about to delete this row of data',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: 'delete',
            url: `/AuditSupplier/${id}?wcode=audit-qualification`
          }).then((res) => {
            resolve()
          }).catch((err) => {
            reject()
          }).then(() => fetchData())
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

  const filteredData = useMemo(() => {
    return data.data.filter(foo => foo.supplierName.toLowerCase().includes(searchValue.toLowerCase()))
  }, [data, searchValue])

  const onSearch = (values) => {
    setFilter(values)
  }

  return (
    <div className="p-4">
      <div className="grid grid-cols-12 gap-3">
        <div className="col-span-12">
          <Filters wcode={'audit-qualification'} onSearch={onSearch}/>
        </div>
        <Card className="col-span-12" size="small">
          <div className="flex flex-col">
            <div className="mb-3">
              <Search 
                placeholder="Search"
                className='border-none'
                enterButton 
                onChange={e => setSearchValue(e.target.value)} />
            </div>
            <div className="bg-white mb-3 font-semibold flex items-center justify-between">
              1-{filteredData.length} of {data.total} Results
              <div className='flex gap-2'>
                <Button onClick={handleAddNew} type="primary">
                  Add new audit
                </Button>
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <InfiniteScroll
                dataLength={data.data.length}
                next={fetchNext}
                loader={<div className='text-center font-bold'>Fetching...</div>}
                className="flex flex-col gap-1"
                hasMore={hasMore}
                endMessage={
                  <p className="text-center font-bold">
                    No more data
                  </p>
                }>
                <table className="oyu-table">
                  <colgroup>
                    <col style={{width: '50px'}}></col>
                    <col style={{width: '100px'}}></col>
                    <col></col>
                    <col></col>
                    <col style={{width: '100px'}}></col>
                    <col style={{width: '100px'}}></col>
                    <col style={{width: '50px'}}></col>
                  </colgroup>
                  <thead>
                    <tr>
                      <th style={{textAlign: 'left'}}>#</th>
                      <th>Code</th>
                      <th style={{textAlign: 'left'}}>Name</th>
                      <th style={{textAlign: 'left'}}>Audit name</th>
                      <th>SAP ID</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      filteredData.map((foo, fooIndex) => {
                        return (
                          <tr key={`tender-${fooIndex}`} className="hover:bg-gray-100 transition-all">
                            <td>
                              {fooIndex+1}
                            </td>
                            <td style={{textAlign: 'center'}}>{foo.id}</td>
                            <td>
                              <Link 
                                className="text-primary cursor-pointer hover:underline" 
                                to={`${foo.id}`} 
                                state={foo}>
                                <div className="truncate">
                                  <Tooltip title={foo.name}>
                                    <span className="truncate">
                                      {foo.supplierName}
                                    </span>
                                  </Tooltip>
                                </div>
                              </Link>
                            </td>
                            <td>
                              <span className="truncate">
                                {foo.name}
                              </span>
                            </td>
                            <td style={{textAlign: 'center'}}>{foo.vendorNumber}</td>
                            <td>
                              <Tag color={statusEnum[foo.status]?.color}>
                                {statusEnum[foo.status]?.title}
                              </Tag>
                            </td>
                            <td>
                              <Dropdown 
                                trigger={['click']} 
                                menu={{items: items({row: foo, handleEdit, handleDelete}),}}>
                                <a onClick={e => e.preventDefault()} className="flex justify-center items-center">
                                  <Space className="px-3">
                                    <MoreOutlined className='text-xl'/>
                                  </Space>
                                </a>
                              </Dropdown>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </InfiniteScroll>
            </div>
          </div>
        </Card>
      </div>
      <Modal
        title={editData ? <div className='flex items-center justify-start gap-2'>Edit</div> : <div className='flex items-center justify-start gap-2'>Add</div>}
        open={open}
        destroyOnClose
        maskClosable={false}
        okText='Save'
        onOk={form.submit}
        onCancel={handleCloseModal}
        width={"40%"}
      >
        <Form
          form={form}
          fields={fields(supplier)}
          length={data.length}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          isEdit={isEdit}
          className='gap-4'
        />
      </Modal>
      {contextHolder}
    </div>
  );
}

export default AuditQualification;
