import React, { useContext, useEffect, useState } from 'react'
import { Button, Tooltip, Skeleton, Divider, Table, Card, Steps, Input, Collapse, Popover } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { useNavigate, Outlet } from 'react-router-dom';
import { instance } from 'utils/axios';
import { useParams, Link, useLocation } from "react-router-dom";
import { BsClipboardCheck, BsJournalBookmark, BsPeople, BsViewStacked, BsBoxes, BsChatQuote, BsPencilSquare, BsQuestionSquare  } from "react-icons/bs";
import { disqualifiedColumns, disqualifiedColumnsSummary, expandedDetailColumns, expandedServiceColumns, expandedSuppliersColumns, suppliersColumns, totalColumns } from './rfqFormColumns';
import BidSummaryList from '../BidSummary/BidSummaryTable';
import { twMerge } from 'tailwind-merge';
import { AuthContext } from 'contexts';

const customDot = ({dot, status, index, currentApprovers}) => (
  <Popover
    content={
      <span >
        Approver #{index+1} status: <span className={twMerge('font-semibold', currentApprovers[index].status == 1 ? 'text-green-500' : currentApprovers[index].status == 0 ? 'text-red-500' : 'text-yellow-500')}>{currentApprovers[index].status == 1 ? 'Approved' : currentApprovers[index].status == 0 ? 'Rejected' : status}</span>
      </span>
    }
  >
    {dot}
  </Popover>
);

function ApprovalDetail({mainData}) {
  const [ loading, setLoading ] = useState(false)
  const { state } = useContext(AuthContext)

  if(loading) {
    return (
      <div>
        <Skeleton active/>
      </div>
    )
  }
  
  return (
    <div className='grid grid-cols-12 gap-3 relative'>
      <div className="grid grid-cols-12 gap-3 col-span-12">
        <div className="col-span-12">
          <Card size="small">
            <div className="flex flex-col gap-4">
              <Table
                size='small'
                pagination={false}
                columns={[
                  {
                    title: '#',
                    dataIndex: '',
                    width: 50,
                    render: (cell, row, index) => index+1
                  },
                  {
                    title: 'Name',
                    dataIndex: 'firstName',
                    render: (cell, row, index) => <span>{row.firstName} {row.lastName}</span>
                  },
                  {
                    title: 'Role',
                    dataIndex: 'name',
                  },
                  {
                    title: 'Approval justification',
                    dataIndex: 'comment',
                  },
                ]}
                dataSource={mainData.approvalSteps}>

              </Table>
              
            </div>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default ApprovalDetail
