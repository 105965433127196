import { Button, Card, Carousel, Checkbox, Tag, Tooltip } from "antd"
import { useEffect, useRef, useState } from "react"
import { useOutletContext, useParams } from "react-router-dom"
import { instance } from "utils/axios"
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import ls from "utils/ls"

const SampleNextArrow = props => {
  const { className, style, onClick } = props
  return (
    <div
      className="absolute top-1/2 -translate-x-2/4 -translate-y-2/4 right-0 cursor-pointer group z-10 hover:bg-primary transition-all"
      style={{ ...style, display: 'block', background: '#00000040', borderRadius: '50%', width: '32px', height: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      onClick={onClick}
    >
      <RightOutlined />
    </div>
  )
}

const SamplePrevArrow = props => {
  const { className, style, onClick } = props
  return (
    <div
      className="absolute top-1/2 -translate-x-2/4 -translate-y-2/4 left-4 cursor-pointer group z-10 hover:bg-primary transition-all"
      style={{ ...style, display: 'block', background: '#00000040', borderRadius: '50%', width: '32px', height: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      onClick={onClick}
    >
      <LeftOutlined />
    </div>
  )
}

const TravelBidSummary = () => {
  const [ data, setData ] = useState({
    suppliers: [],
    detail: []
  })
  const { id } = useParams()
  const [ selectedOption, setSelectedOption ] = useState([])
  const [ awarding, setAwarding ] = useState(false)
  const [ outletContext ] = useOutletContext()
  const carouselRef = useRef([])

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if(data.suppliers.length > 0) {
      data.suppliers.sort((a,b) => b.awardStatus - a.awardStatus).map((foo, fooIndex) => {
        foo.details.map((_detail, _detailIndex) => {
          if(_detail.isAwarded == 1) {
            carouselRef.current[fooIndex]?.goTo(_detailIndex)
          }
        })
      })
    }
  }, [])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `/Tender/travel/bidsummary/${id}?wcode=tender-detail`
    }).then(res => {
      if(res.status != 204) {
        setData(res.data.responseData)
      }
    }).catch(err => {
      console.log(err)
    })
  }

  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }

  const handleAward = () => {
    setAwarding(true)
    instance({
      method: 'post',
      url: `/Tender/travel/award?wcode=tender-detail`,
      data: {
        tenderId: id,
        tenderTravelDetailId: selectedOption[0].id
      }
    }).then(res => {
      outletContext.fetchData()
      setSelectedOption([])
      outletContext.fetchData()
      fetchData()
    }).catch(err => {
      console.log(err)
    }).then(() => {
      setAwarding(false)
    })
  }

  const handleBook = () => {
    setAwarding(true)
    instance({
      method: 'post',
      url: `/Tender/travel/preapprove?wcode=tender-detail`,
      data: {
        tenderId: id,
        tenderTravelDetailIds: selectedOption.map(foo => foo.id)
      }
    }).then(res => {
      setSelectedOption([])
      outletContext.fetchData()
      fetchData()
    }).catch(err => {
      console.log(err)
    }).then(() => {
      setAwarding(false)
    })
  }
  
  return (
    <div className="flex flex-col gap-3">
      <Card className="p-0 rounded-none">
        <div className="">
          <div className="flex justify-between">
            <div className="flex gap-3">
              <Tooltip title={outletContext.status >= 4 ? 'Tender is already over' : outletContext.status < 3 ? "Tender is not closed" : selectedOption.length == 0 ? "Please select option first" : 'Book the selected option'}>
                <Button type="primary" disabled={selectedOption.length == 0 || outletContext.status != 3} onClick={handleBook} loading={awarding}>Book</Button>
              </Tooltip>
              <Tooltip title={(outletContext.status >= 4 && outletContext.status != 10) ? 'Tender is already over' : outletContext.status < 3 ? "Tender is not closed" : selectedOption.length == 0 ? "Please select option first" : selectedOption.length > 1 ? "Multiple options has been selected" : 'Award the selected option'}>
                <Button type="primary" disabled={selectedOption.length != 1 && (outletContext.status != 3 || outletContext.status != 10)} onClick={handleAward} loading={awarding}>Award</Button>
              </Tooltip>
            </div>
            <Tooltip title={(outletContext.status >= 4 && outletContext.status != 10) ? 'Tender is already over' : outletContext.status < 3 ? 'Tender is not closed' : 'Preview all bids on bigger screen'}>
              <Button type="primary" 
                disabled={outletContext.status != 3 && outletContext.status != 10}
                onClick={() => {
                  ls.set("data", data)
                  window.open(`/preview-travel/${id}`, "_blank", 'location=yes,height=570,width=520,scrollbars=yes,status=yes')
                }}>Preview all</Button>
            </Tooltip>
          </div>
          <div className="flex flex-col mt-3">
            {
              data.suppliers.sort((a,b) => b.awardStatus - a.awardStatus).map((foo, fooIndex) => {
                return (
                  <div className="grid grid-cols-12 border" key={`supplier-${fooIndex}`}>
                    <div className="col-span-4 flex flex-col gap-2 border-r py-2 px-4">
                      {foo.name}
                      {foo.regNo}
                    </div>
                    <div className="col-span-8">
                      <Carousel ref={(refElement) => carouselRef.current[fooIndex] = refElement} arrows={foo.details.length > 1 ? true : false} {...settings}>
                        {
                          foo.details.map((option, optionIndex) => {
                            return (
                              <div key={`supplier-${fooIndex}-option-${optionIndex}`} className="px-10 pt-4">
                                <div className="mb-3 px-4 py-2 bg-slate-200 rounded flex justify-between">
                                  <div className="flex">
                                    <span className="font-semibold mr-6">Option #{optionIndex+1}</span> 
                                    <Checkbox 
                                      checked={selectedOption.findIndex(foo => foo.id == option.id) != -1}
                                      onChange={e => {
                                      if(e.target.checked) {
                                        setSelectedOption([...selectedOption, option])
                                      }
                                      else {
                                        setSelectedOption(prev => {
                                          return prev.filter(foo => foo.id != option.id)
                                        })
                                      }
                                    }}>
                                      Select option
                                    </Checkbox>
                                  </div>
                                  <div className="flex items-center gap-2">
                                    {
                                      (outletContext.status == 9 || outletContext.status == 4) ?
                                      option.isAwarded == 1 &&
                                      <Tag bordered={false} color="#009A72">
                                        Awarded
                                      </Tag>
                                      :
                                      option.isPreapproved == 1 &&
                                      <Tag bordered={false} color="processing">
                                        Booked
                                      </Tag>
                                    }
                                    <span className="font-semibold">Total price: {option.totalPrice.toLocaleString("en-US")}₮</span>
                                  </div>
                                </div>
                                <div className="h-[250px] overflow-auto" dangerouslySetInnerHTML={{__html: option.content}}>

                                </div>
                              </div>
                            )
                          })
                        }
                      </Carousel>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </Card>
    </div>
  )
}

export default TravelBidSummary