import { AutoComplete, Button, Divider, Form, Input, Select, Space, Upload } from 'antd'
import { PlusOutlined, MinusCircleOutlined, UploadOutlined } from '@ant-design/icons'
import { instance } from 'utils/axios';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'contexts';


const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const EOIAdditionalForm = () => {
  const [ systemDocuments, setSystemDocuments ] = useState([])
  const [ eoiBuyers, setEoiBuyers ] = useState([])
  const { state } = useContext(AuthContext)
  const form = Form.useFormInstance();

  useEffect(() => {
    instance({
      method: 'get',
      url: `/User/permission/2?wcode=tender`
    }).then(res => {
      setEoiBuyers(res.data.responseData)
    })
  }, [])

  useEffect(() => {
    console.log()
    instance({
      method: 'get',
      url: `/SystemDocument?wcode=tender`
    }).then(res => {
      console.log(form.getFieldValue("required"))
      if(form.getFieldValue("required")?.length == 0 || !form.getFieldValue("required")) {
        form.setFieldValue('required', res.data.responseData.filter(foo => foo.isRequired == 1).map(foo => ({systemDocumentId: foo.id, description: foo.description})))
      }
      setSystemDocuments(res.data.responseData)
    })
  }, [])

  const handleSystemDocChange = (index, e) => {
    form.setFieldValue(['details', index, 'description'], systemDocuments.find(foo => foo.id == e).description)
  }
  
  return (
    <>
    <Divider className="col-span-12" orientation="left">Mandatory documents</Divider>
    <div className="col-span-12 grid grid-cols-5 gap-3">
      <div className="col-span-2">
        Description
      </div>
      <div className="col-span-3">
        Short content 
      </div>
    </div>
    <Form.List name="required">
      {(fields, {add, remove}, {errors}) => {
        return (
          <div className="col-span-12 flex flex-col gap-3">
          {
            fields.map(({key, name, ...restFields}) => (
            <div key={key} className="w-full flex flex-col gap-3">
              <div className="grid grid-cols-5 gap-3">
                <Form.Item name={[name, 'systemDocumentId']} className="mb-0 col-span-2" rules={[{required: true, message: 'Select system document!'}]}>
                  <Select disabled className="w-full" options={systemDocuments.map((foo) => ({value: foo.id, label: foo.name}))} onChange={(e) => handleSystemDocChange(name, e)}></Select>
                </Form.Item>
                <div className='flex gap-3 items-end col-span-3'>
                  <Form.Item name={[name, 'description']} className="mb-0 w-full grow">
                    <Input disabled></Input>
                  </Form.Item> 
                  <div className="w-[150px]">
                    <Button type="dashed" block icon={<MinusCircleOutlined />} onClick={() => remove(name)}>
                      Remove
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            ))
          }
            {/* <Form.Item noStyle>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Add
              </Button>
            </Form.Item> */}
            <Form.ErrorList className="text-red-500" errors={errors} />
          </div>
        )
      }} 
    </Form.List>
    <Divider className="col-span-12" orientation="left">Scope specific documents</Divider>
    <div className="col-span-12 grid grid-cols-5 gap-3">
      <div className="col-span-2">
        Description
      </div>
      <div className="col-span-3">
        Short content 
      </div>
    </div>
    <Form.List name="details">
      {(fields, {add, remove}, {errors}) => {
        return (
          <div className="col-span-12 flex flex-col gap-3">
            {
              fields.map(({key, name, ...restFields}) => (
              <div key={key} className="w-full flex flex-col gap-3">
                <div className="grid grid-cols-5 gap-3">
                  <Form.Item name={[name, 'name']} className="mb-0 col-span-2" rules={[{required: true, message: 'Select system document!'}]}>
                    <AutoComplete className="w-full" options={systemDocuments.filter(foo => foo.isRequired == 0).map((foo) => ({value: foo.name }))}></AutoComplete>
                  </Form.Item>
                  <div className='flex gap-3 items-end col-span-3'>
                    <Form.Item name={[name, 'description']} className="mb-0 w-full grow">
                      <Input></Input>
                    </Form.Item> 
                    <div className="w-[150px]">
                      <Button type="dashed" block icon={<MinusCircleOutlined />} onClick={() => remove(name)}>
                        Remove
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              ))
            }
            <Form.Item noStyle>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Add
              </Button>
            </Form.Item>
            <Form.ErrorList className="text-red-500" errors={errors} />
          </div>
        )
      }}      
      
    </Form.List> 
    <Form.Item name="attachments" label="Attachment files" className="col-span-6 mb-0" valuePropName="fileList" getValueFromEvent={normFile}>
      <Upload
        multiple
        name="file" 
        action={`${process.env.REACT_APP_MAIN_API_URL}File/upload`}
        headers={{
          Authorization: `Bearer ${state.token}`,
        }}
          // onChange={(info) => {
          //   if (info.file.status !== 'uploading') {
          //     console.log(info.file, info.fileList);
          //   }
          //   if (info.file.status === 'done') {
          //     message.success(`${info.file.name} file uploaded successfully`);
          //   } else if (info.file.status === 'error') {
          //     message.error(`${info.file.name} file upload failed.`);
          //   }  
          // }}
        onRemove={(file) => {
          instance({
            method: 'delete',
            url: `File/remove`,
            data: file.response.responseData
          })
        }}>
        <Button icon={<UploadOutlined />}>Select file</Button>
      </Upload>
    </Form.Item>
    <Form.Item className="col-span-6" name="assignedUserId" label="Assign to user">
      <Select 
        showSearch
        optionFilterProp="label"
        placeholder="Select if you want to assign to user" 
        className="w-full" 
        options={eoiBuyers.map((foo) => ({label: `${foo.firstName} ${foo.lastName}`, value: foo.id}))}></Select>
    </Form.Item>
    </>
  )
}

export default EOIAdditionalForm