import React, { useEffect, useState } from 'react'
import { Button, Tooltip, Skeleton, Divider, Table, Card, Steps, Input, Collapse, Tree } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { useNavigate, Outlet } from 'react-router-dom';
import { instance } from 'utils/axios';
import { useParams, Link, useLocation } from "react-router-dom";
import { BsClipboardCheck, BsJournalBookmark, BsPeople, BsViewStacked, BsBoxes, BsChatQuote, BsPencilSquare, BsQuestionSquare  } from "react-icons/bs";
import { disqualifiedColumns, disqualifiedColumnsSummary, expandedDetailColumns, expandedServiceColumns, expandedSuppliersColumns, suppliersColumns, totalColumns } from './rfqFormColumns';
import BidSummaryTable from './BidSummaryTable';
import BidSummaryService from './ServiceSummary';

function BidSummaryList({mainData}) {
  const [ loading, setLoading ] = useState(false)
  const [ sending, setSending ] = useState(false)
  const [ comment, setComment ] = useState("")
  const location = useLocation()
  const navigate = useNavigate()
  const { id } = useParams()
  
  return (
    <div className='grid grid-cols-12 gap-3 relative'>
      {
        loading ? 
        <div className='col-span-12'>
          <Skeleton active/>
        </div>
        :
        <div className="grid grid-cols-12 gap-3 col-span-12">
          <Card className="col-span-12" size="small" styles={{borderTopLeftRadius: 0}}>
            <div className="flex flex-col gap-3">
              {
                mainData &&
                mainData.rfqType == 1 ?
                <BidSummaryTable maindata={mainData}/>
                :
                <BidSummaryService mainData={mainData}/>
              }
            </div>
          </Card>
        </div>
      }
    </div>
  )
}

export default BidSummaryList
