import { useState, useEffect, useContext } from 'react'
import { Form } from 'components'
import { Button, Form as AntForm, Card, Input, Select, Upload, Space, Checkbox } from 'antd'
import { instance } from 'utils/axios'
import { UploadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'contexts';

const ProductsAndServices = ({state, products=[], service, fullData}) => {
  const [ loading, setLoading ] = useState(false)
  const [ form ] = AntForm.useForm();
  const authContext = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    form.setFieldValue('productIds', products.map((item) => item?.productId))
    form.setFieldValue('validationData', fullData?.validationData ? fullData?.validationData : [])
  }, [products])

  // useEffect(() => {
  //   console.log('service', service);
  // }, [service])
  
  const handleSubmit = (values) => {
    setLoading(true)

    instance({
      method: 'put',
      url: `/SupplierValidation?wcode=supplier-validation-detail`,
      data: {
        ...values,
        productModels: values.productIds.map((foo, fooIndex) => ({id: foo, status: 1})),
        SupplierId: fullData.supplierData.supplier.id,
        validationStatus: fullData.supplierData.supplier.validationStatus == 1 ? 3 : fullData.supplierData.supplier.validationStatus == 3 && 4,
        fileName: values.file?.file && values.file.file.response.responseData
      }
    }).then(res => {
      navigate(-1)
    }).catch(err => {
      
    }).then(() => {
      setLoading(false)
    })
  }
  
  const handleReturn = () => {
    setLoading(true)
    let fd = new FormData() 

    fd.append("productModels", form.getFieldValue("productIds"))
    fd.append("validationStatus", fullData?.supplierData?.supplier.validationStatus == 1 ? 2 : 1)
    fd.append("SupplierId", fullData.supplierData.supplier.id)

    instance({
      method: 'put',
      url: `/SupplierValidation?wcode=supplier-validation-detail`,
      data: {
        productModels: form.getFieldValue("productIds"),
        validationStatus: fullData?.supplierData?.supplier.validationStatus == 1 ? 2 : 1,
        SupplierId: fullData.supplierData.supplier.id
      }
    }).then(res => {
      navigate(-1)
    }).catch(err => {
      
    }).then(() => {
      setLoading(false)
    })
  }
  
  return (
    <div className='relative'>
      <Form
        form={form}
        fields={[]}
        layout="vertical"
        onFinish={handleSubmit}
        className='gap-4'>  
       <Card title="1. Products & Services" className="col-span-12" size="small">
          <AntForm.Item label="Product codes" name="productIds">
            <Select mode='multiple' disabled options={products.map((item) => ({value: item.productId, label: `${item.productCode} - ${item.productName}`}))}></Select>
          </AntForm.Item>
          {/* <AntForm.Item label="Description" name="description">
            <Input.TextArea rows={4}></Input.TextArea>
          </AntForm.Item> */}
          {/* <AntForm.Item label="Attachment" name="file">
            <Upload 
              maxCount={1}
              action={`${process.env.REACT_APP_MAIN_API_URL}File/upload`}
              headers={{
                Authorization: `Bearer ${authContext.state.token}`,
              }}
              onRemove={(file) => {
                instance({
                  method: 'delete',
                  url: `File/remove`,
                  data: file.response.responseData
                })
              }}>
              <Button icon={<UploadOutlined />}>Select file</Button>
            </Upload>
          </AntForm.Item> */}
          
          {/* <div className="flex gap-4 mt-4">
          {
            (fullData?.supplierData?.supplier.validationStatus == 3 || fullData?.supplierData?.supplier.validationStatus == 2 || fullData?.supplierData?.supplier.validationStatus == 1) &&
            <AntForm.Item noStyle>
              <Button className="col-span-2" type="primary" danger loading={loading} onClick={handleReturn}>Return</Button>
            </AntForm.Item>
          }
          {
            (fullData?.supplierData?.supplier.validationStatus == 1 || fullData?.supplierData?.supplier.validationStatus == 3 ) &&
            <AntForm.Item noStyle>
              <Button className="col-span-2" type="primary" htmlType='submit' loading={loading}>Submit</Button>
            </AntForm.Item>
          }
          </div> */}
        </Card>
        {/* <Card title="Validation history" className="col-span-12 rounded-none" size="small">
          <AntForm.List name="validationData">
              {
                (fields, {add, remove}) => {
                  return (
                    <>
                      {
                        fields.map(({key, name, ...restFields}) => {
                          return (
                            <div key={`row-${key}`} className="[&:not(:last-child)]:border-b [&:not(:first-child)]:mt-4">
                              <p className="font-semibold">
                                {
                                  key == 0 ?
                                  'Validation info'
                                  :
                                  'Approvement info'
                                }
                              </p>
                              <AntForm.Item name={[key, "fileName"]} noStyle>
                              <div className="flex gap-3">
                                <p>
                                  Attachment: 
                                </p>
                                <a className="text-link font-medium" target='_blank' href={`${process.env.REACT_APP_UPLOAD_URL}/${form.getFieldValue(['validationData', key, 'fileName'])}`}>
                                  {form.getFieldValue(['validationData', key, 'fileName'])?.split("/").slice(-1)}
                                </a>
                              </div>
                              </AntForm.Item>
                              <AntForm.Item label="Description" name={[key, "description"]}>
                                <Input.TextArea rows={4} disabled={true}></Input.TextArea>
                              </AntForm.Item>
                            </div>
                          )
                        })
                      }
                    </>
                  )
                }
              }
            </AntForm.List>
        </Card> */}
      </Form>
      
    </div>
  )
}

export default ProductsAndServices