import {
  Dashboard,
  Template,
  TemplateEvent,
  TemplateGroup,
  TenderList,
  TenderDetail,
  Auction,
  Supplier,
  SupplierValidation,
  SupplierValidationDetail,
  SupplierInfo,
  Survey,
  SupplierDetail,
  SurveyDetail,
  SupportHub,
  PrequalificationGroup,
  PrequalificationGroupDetail,
  AuctionDetails,
  PrequalificationList,
  PrequalificationEvaluate,
  SupportHubDetail,
  CompanyQuestionList,
  CompanyQuestionGroup,
  CompanyQuestionGroupDetail,
  ProductType,
  SupplierTier,
  Users,
  UserPositions,
  Window,
  UserPositionDetail,
  AuditConfigGroup,
  AuditConfigGroupDetail,
  AuditConfigList,
  Message,
  MessageDetail,
  AuditQualification,
  AuditQualificationDetail,
  EventTemplate,
  TenderListEndUser,
  TenderDetailEndUser,
  TenderApprovalPeople,
  SystemDocuments,
  SurveySuppliers,
  PlaceOfDelivery,
  PRNumber,
  MaterialNumber,
  TenderFeedback,
  ChangePassword,
  Delegation,
  ComingSoon
} from 'modules/admin';
import { Capacity, DesktopAudit, PhysicalAudit } from 'modules/admin/qualifaction';
import CompanyInformation from 'modules/admin/supplierDetail/CompanyInformation';
import CompanyShareholders from 'modules/admin/supplierDetail/CompanyShareHolders';
import ContactDetail from 'modules/admin/supplierDetail/ContactDetails';
import ManagementTeam from 'modules/admin/supplierDetail/ManagementTeam';
import ProductsAndServices from 'modules/admin/supplierDetail/ProductsAndServices';
import { Navigate } from 'react-router-dom'
import { Award, PrivateChat, TenderOverview, TenderSuppliers, EndUserChat, Log, TravelBidSummary, FreightBidSummary, Negotiation, Passengers, ApprovalHistory } from 'modules/admin/tender/components'
import BidSummaryList from 'modules/admin/tender/components/RFQDetail/BidSummaryList';
import Chat from 'modules/admin/tender/components/Chat';
import { TenderOverview as EndUserTenderOverview, Bidsummary, Chat as BuyerChat, BidSummaryService as BidSummaryServiceEnd } from 'modules/admin/tenderEndUser/components';
import { TenderDetailApproval, TenderListApproval } from 'modules/admin/tenderApproval';
import { TenderOverview as ApprovalTenderOverview, Bidsummary as ApprovalBidsummary, ApprovalDetail, BidReport } from 'modules/admin/tenderApproval/components';
import BidderList from 'modules/admin/tender/components/EOIDetail/BidderList';
import SupplierType from 'modules/admin/supplierDetail/SupplierType';
import { TenderDetailApprovalFreight, TenderListApprovalFreight } from 'modules/admin/tenderApprovalFreight';
import { TenderOverview as ApprovalTenderOverviewFreight, Bidsummary as ApprovalBidsummaryFreight } from 'modules/admin/tenderApproval/components';
import ShortList from 'modules/admin/tender/components/EOIDetail/ShortList';
import BidSummaryService from 'modules/admin/tender/components/RFQDetail/BidSummaryService';
import Feedback from 'modules/admin/tender/components/Feedback';
import { COI, CompanyRelation, DueDiligence } from 'modules/admin/compliance';

export default [
  {
    title: 'Dashboard',
    key: 'dashboard',
    path: '/',
    handle: {
      crumb: 'Home'
    },
    children: [
      {
        index: true,
        element: <Dashboard/>
      },
      {
        title: 'Change password',
        key: 'change-password',
        path: '/change-password',
        element: <ChangePassword />,
        handle: {
          crumb: 'Change password'
        },
      },
      {
        title: 'Tender template',
        key: 'tender-template',
        path: '/tender-template',
        element: <TemplateGroup />,
        handle: {
          crumb: 'Tender template'
        },
      },
      {
        title: 'Auction',
        key: 'auction',
        path: '/auction',
        handle: {
          crumb: 'Auction list'
        },
        children: [
          {
            index: true,
            element: <Auction />,
          },
          {
            title: 'Auction',
            key: 'auction-detail',
            path: ':auctionId',
            element: <AuctionDetails/>,
            handle: {
              crumb: 'Auction detail'
            },
          },
        ]
      },
      {
        title: 'Tender list',
        key: 'tender',
        path: '/tender',
        handle: {
          crumb: 'Tenders list'
        },
        children: [
          {
            index: true,
            element: <TenderList/>
          },
          {
            title: 'Tender detail',
            key: 'tender-detail',
            path: ':type',
            errorElement: <Navigate to="/tender" replace={true} />,
            element: <TenderDetail />,
            children: [
              {
                title: 'Tender detail',
                key: 'tender-detail',
                path: ':id',
                errorElement: <Navigate to="/tender" replace={true} />,
                handle: {
                  crumb: 'Tender detail'
                },
                children: [
                  {
                    index: true,
                    element: <TenderOverview/>
                  },
                  {
                    path: 'suppliers',
                    element: <TenderSuppliers/>
                  },
                  {
                    path: 'approval-history',
                    element: <ApprovalHistory/>
                  },
                  {
                    path: 'bid-summary',
                    element: <BidSummaryList/>
                  },
                  {
                    path: 'bid-summary-service',
                    element: <BidSummaryService/>
                  },
                  {
                    path: 'chat',
                    element: <Chat/>
                  },
                  {
                    path: 'award',
                    element: <Award/>
                  },
                  {
                    path: 'log',
                    element: <Log/>
                  },
                  {
                    path: 'travel-bid-summary',
                    element: <TravelBidSummary/>
                  },
                  {
                    path: 'freight-bid-summary',
                    element: <FreightBidSummary/>
                  },
                  {
                    path: 'eoi-bidder-list',
                    element: <BidderList/>
                  },
                  {
                    path: 'eoi-short-list',
                    element: <ShortList/>
                  },
                  {
                    path: 'negotiation',
                    element: <Negotiation/>
                  },
                  {
                    path: 'passengers',
                    element: <Passengers/>
                  },
                  {
                    path: 'feedback',
                    element: <Feedback/>
                  }
                ]
              },
            ]
          },
        ]
      },
      {
        title: 'Tender list end user',
        key: 'tender-end-user',
        path: '/tender-end-user',
        handle: {
          crumb: 'Tenders list'
        },
        children: [
          { index: true, element: <TenderListEndUser /> },
          {
            title: 'Tender detail end user',
            key: 'tender-detail-end-user',
            path: '/tender-end-user/:id',
            errorElement: <Navigate to="/tender-end-user" replace={true} />,
            element: <TenderDetailEndUser />,
            handle: {
              crumb: 'Tender detail'
            },
            children: [
              // {
              //   index: true,
              //   element: <EndUserTenderOverview/>
              // },
              {
                index: true,
                element: <Bidsummary/>
              },
              {
                path: 'bid-summary-service',
                element: <BidSummaryServiceEnd/>
              },
              {
                path: 'chat',
                element: <BuyerChat/>
              }
            ]
          },
        ]
      },
      {
        title: 'Tender list approval',
        key: 'tender-approval',
        path: '/tender-approval',
        handle: {
          crumb: 'Tenders list'
        },
        children: [
          { index: true, element: <TenderListApproval /> },
          {
            title: 'Tender detail approval',
            key: 'tender-detail-approval',
            path: '/tender-approval/:id',
            errorElement: <Navigate to="/tender-approval" replace={true} />,
            element: <TenderDetailApproval />,
            handle: {
              crumb: 'Tender detail'
            },
            children: [
              {
                index: true,
                element: <BidReport/>
              },
              {
                path: 'bid',
                element: <BidReport/>
              },
              {
                path: 'bid-summary',
                element: <ApprovalBidsummary/>
              },
              {
                path: 'approval',
                element: <ApprovalDetail/>
              }
            ]
          },
        ]
      },
      {
        title: 'Tender list approval',
        key: 'tender-approval-freight',
        path: '/tender-approval-freight',
        handle: {
          crumb: 'Tenders list'
        },
        children: [
          { index: true, element: <TenderListApprovalFreight />,},
          {
            title: 'Tender detail approval',
            key: 'tender-detail-approval-freight',
            path: '/tender-approval-freight/:id',
            handle: {
              crumb: 'Tender detail'
            },
            errorElement: <Navigate to="/tender-approval-freight" replace={true} />,
            element: <TenderDetailApprovalFreight />,
            children: [
              {
                index: true,
                element: <ApprovalTenderOverviewFreight/>
              },
              {
                path: 'bid-summary',
                element: <ApprovalBidsummaryFreight/>
              },
            ]
          },
        ]
      },
      {
        title: 'Supplier list',
        key: 'supplier',
        path: '/supplier',
        handle: {
          crumb: 'Suppliers list'
        },
        children: [
          { index: true, element: <Supplier />},
          {
            title: 'Supplier detail',
            key: 'supplier-detail',
            path: '/supplier/:supplierId',
            element: <SupplierDetail />,
            handle: {
              crumb: 'Supplier detail'
            },
            children: [
              {
                index: true,
                // path: 'company-information',
                element: <CompanyInformation/>
              },
              {
                path: 'contact-details',
                element: <ContactDetail/>
              },
              {
                path: 'management-team',
                element: <ManagementTeam/>
              },
              {
                path: 'shareholder-information',
                element: <CompanyShareholders/>
              },
              {
                path: 'products-services',
                element: <ProductsAndServices/>
              },
              {
                path: 'supplier-type',
                element: <SupplierType/>
              },
            ]
          },
        ]
      },
      {
        title: 'Supplier validation list',
        key: 'supplier-validation-list',
        path: '/supplier-validation',
        handle: {
          crumb: 'Suppliers validation list'
        },
        children: [
          { index: true, element: <SupplierValidation />,},
          {
            title: 'Supplier validation detail',
            key: 'supplier-validation-detail',
            path: '/supplier-validation/:id',
            element: <SupplierValidationDetail />,
            handle: {
              crumb: 'Supplier validation detail'
            },
          },
        ]
      },
      {
        title: 'Survey list',
        key: 'survey',
        path: '/survey',
        handle: {
          crumb: 'Survey list'
        },
        children: [
          { index: true, element: <Survey /> },
          {
            title: 'Survey detail',
            key: 'survey-detail',
            path: '/survey/:surveyId',
            element: <SurveyDetail/>,
            handle: {
              crumb: 'Survey detail'
            },
          },

        ]
      },
      {
        title: 'Survey suppliers',
        key: 'survey-suppliers',
        path: '/survey-suppliers',
        handle: {
          crumb: 'Taken survey list'
        },
        children: [
          { index: true, element: <SurveySuppliers/>}
        ]
      },
      {
        title: 'Tender feedback',
        key: 'tender-feedback',
        path: '/tender-feedback',
        handle: {
          crumb: 'Tender feedback list'
        },
        children: [
          { index: true, element: <TenderFeedback/>}
        ]
      },
      {
        title: 'Support Hub list',
        key: 'support-hub',
        path: '/support-hub',
        handle: {
          crumb: 'Support hub'
        },
        children: [
          { index: true, element: <SupportHub /> },
          {
            title: 'Support hub detail',
            key: 'support-hub-detail',
            path: '/support-hub/:id',
            element: <SupportHubDetail />,
            handle: {
              crumb: 'Support hub detail'
            }
          },
        ]
      },
      {
        title: 'COI',
        key: 'coi',
        path: '/coi',
        handle: {
          crumb: 'COI'
        },
        children: [
          { index: true, element: <COI/>}
        ]
      },      
      {
        title: 'Company relation',
        key: 'company-relation',
        path: '/company-relations',
        handle: {
          crumb: 'Company relations'
        },
        children: [
          { index: true, element: <CompanyRelation/>}
        ]
      },      
      {
        title: 'Due diligence',
        key: 'due-diligence',
        path: '/due-diligence',
        handle: {
          crumb: 'Due diligence'
        },
        children: [
          { index: true, element: <DueDiligence/>}
        ]
      },      
      {
        title: 'Delegation',
        key: 'delegation',
        path: '/delegation',
        handle: {
          crumb: 'Delegation'
        },
        children: [
          { index: true, element: <Delegation/>}
        ]
      },      
      {
        title: 'Desktop Audit',
        path: '/desktop-audit',
        key: `desktop-audit`,
        element: <DesktopAudit/>,
      },
      {
        title: 'Physical Audit',
        key: `physical-audit`,
        path: '/physical-audit',
        element: <PhysicalAudit/>,
      },  
      {
        title: 'Prequalification group config list',
        key: 'prequalification-group-config-list',
        path: '/prequalification-group-config',
        element: <PrequalificationGroup />,
      },
      {
        title: 'Prequalification group config detail',
        key: 'prequalification-group-config-detail',
        path: '/prequalification-group-config/:id',
        element: <PrequalificationGroupDetail />,
      },
      {
        title: 'Prequalification list',
        key: 'prequalification-group-list',
        path: '/prequalification',
        handle: {
          crumb: 'Prequalification list'
        },
        children: [
          {
            index: true, element: <PrequalificationList />,
          },
          {
            title: 'Prequalification detail',
            key: 'prequalification-detail',
            path: '/prequalification/:id',
            element: <PrequalificationEvaluate />,
            handle: {
              crumb: 'Prequalification detail'
            },
          },
        ]
      },
      {
        title: 'Company question config list',
        key: 'company-question-config-list',
        path: '/company-question-group-config',
        element: <CompanyQuestionGroup />,
      },
      {
        title: 'Company question config detail',
        key: 'company-question-config-detail',
        path: '/company-question-group-config/:id',
        element: <CompanyQuestionGroupDetail />,
      },
      {
        title: 'Product type list',
        key: 'product-type-list',
        path: '/producttype',
        element: <ProductType />,
      },
      {
        title: 'Supplier tier list',
        key: 'supplier-tier',
        path: '/supplier-tier',
        element: <SupplierTier />,
      },
      {
        title: 'Users list',
        key: 'users-list',
        path: '/users',
        element: <Users />,
      },
      {
        title: 'User positions',
        key: 'user-positions-list',
        path: "/user-positions",
        element: <UserPositions />,
      },
      // {
      //   title: 'User position detail',
      //   key: 'user-positions-detail',
      //   path: '/user-positions/:id',
      //   element: <UserPositionDetail></UserPositionDetail>
      // },
      {
        title: 'Audit Qualification', 
        key: 'audit-qualification', 
        path: '/audit-qualification',        
        handle: {
          crumb: 'Audit qualification list'
        },
        children: [
          {
            index: true, element: <AuditQualification />,
          },
          {
            title: 'Audit Qualification Detail',
            key: 'audit-qualification-detail',
            path: '/audit-qualification/:id',
            element: <AuditQualificationDetail />,
            handle: {
              crumb: 'Audit qualification detail'
            },
          },
        ]
      },
      {
        title: 'Audit config list',
        key: 'audit-config-list',
        path: '/audit-config',
        element: <AuditConfigList />,
      },
      {
        title: 'Audit config group',
        key: 'audit-config-group',
        path: '/audit-config/:id',
        element: <AuditConfigGroup />,
      },
      {
        title: 'Audit config detail',
        key: 'audit-config-detail',
        path: '/audit-config/:id/:groupid',
        element: <AuditConfigGroupDetail />,
      },
      {
        title: 'Message',
        key: 'message',
        path: '/message',
        element: <Message />,
      },
      {
        title: 'Message Detail',
        key: 'message-detail',
        path: '/message/:id',
        element: <MessageDetail />,
      },
      {
        title: 'Event Template',
        key: 'event-template',
        path: '/event-template',
        element: <EventTemplate />,
      },
      {
        title: 'Tender Approval People',
        key: 'tender-approval-list',
        path: '/tender-approval-people',
        element: <TenderApprovalPeople />,
      },
      {
        title: 'System Documents',
        key: 'system-documents',
        path: '/system-documents',
        element: <SystemDocuments />,
      },
      {
        title: 'Place of Delivery',
        key: 'place-of-delivery',
        path: '/place-of-delivery',
        element: <PlaceOfDelivery />,
      },
      {
        title: 'PRNumber',
        key: 'prnumber',
        path: '/prnumber',
        element: <PRNumber />,
      },
      {
        title: 'Material Number',
        key: 'materialNumber',
        path: '/material-number',
        element: <MaterialNumber />,
      },
      {
        title: 'Coming soon',
        key: 'comingSoon',
        path: '/coming-soon',
        element: <ComingSoon/>
      }
    ]
  },
]
