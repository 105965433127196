import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { instance } from "utils/axios"

const Feedback = () => {
  const [ data, seData ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const { id } = useParams()

  useEffect(() => {
    fetchData()
    
  }, [])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `/TenderFeedback/tender/${id}?wcode=tender-detail`
    }).then((res) => {
      console.log(res.data)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }
  
  return (
    <div>
    </div>
  )
}

export default Feedback