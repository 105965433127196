import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { EditOutlined, PlusCircleOutlined, PlusOutlined, MinusCircleOutlined, MoreOutlined } from '@ant-design/icons'
import { Button, Form as AntForm, Modal, Table, Space, Input, Dropdown, Tag, Checkbox } from 'antd';
import { MainTable, Form } from 'components';
import { instance } from 'utils/axios';
import PrequalificationAnswers from './prequalificationAnswers';

  // "title": "string",
  // "status": 0,
  // "preQualificationGroupId": 0,
  // "description": "string",
  // "parentId": 0,
  // "optionId": 0,
  // "seq": 0,
  // "isRequired": 0,
  // "point": 0

const typeEnum = {
  1: 'Number',
  2: 'Text',
  3: 'Dropdown',
  4: 'Radio button',
  5: 'Calendar',
  6: 'Rich text',
  7: 'Attachment',
  8: 'Checkbox',
  9: 'Table'
}

  
const items = ({row, handleEdit, handleDelete}) => {  
  return (
    [
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleEdit(row)
        }}>Edit</a>,
        key: 0
      },
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleDelete(row)
        }}>{row.status == 1 ? 'Inactivate' : 'Activate'}</a>,
        key: 1
      },
    ]
  )
}

const fields = (data, editorTypes) => {
  return [
    {
      label: 'Question',
      name: 'title',
      rules: [{required: true, message: 'Question is required!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: ' ',
      type: 'component',
      component: (
        <AntForm.Item name="isRequired" className="mb-0 col-span-3" valuePropName="checked">
          <Checkbox>
            Required
          </Checkbox>
        </AntForm.Item>
      )
    },
    {
      type: 'component',
      component: (
        <AntForm.Item name="isUseCalculation" className="mb-0 col-span-6" valuePropName="checked">
          <Checkbox>
            Use this question for evaluation
          </Checkbox>
        </AntForm.Item>
      )
    },
    {
      type: 'component',
      component: (
        <AntForm.Item name="hasAttachment" className="mb-0 col-span-3" valuePropName="checked">
          <Checkbox>
            Has attachment
          </Checkbox>
        </AntForm.Item>
      )
    },
    {
      label: 'Description',
      name: 'description',
      type: 'textarea',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Question type',
      name: 'editorTypeId',
      className: 'col-span-12 mb-0',
      type: 'select',
      rules: [{required: true, message: 'Question type is required!'}],
      inputProps: {
        className: 'w-full',
        options: editorTypes.map(foo => ({label: typeEnum[foo.id], value: foo.id}))
      }
    },    
    {
      type: 'dependent',
      className: "col-span-12 mb-0",
      dependentName: 'editorTypeId',
      dependentValue: 9,
      children: [
        {
          label: 'Row header',
          name: 'table-row',
          type: 'select',
          className: "col-span-12 mb-0",
          inputProps: {
            className: 'w-full',
            mode: 'tags'
          }
        },
        {
          label: 'Column name',
          name: 'table-col',
          type: 'select',
          className: "col-span-12 mb-0",
          inputProps: {
            className: 'w-full',
            mode: 'tags'
          }
        }
      ]      
    },
    // {
    //   label: '',
    //   type: 'component',
    //   component: (
    //     <AntForm.Item noStyle shouldUpdate={(prev, next) => prev.editorTypeId != next.editorTypeId } className="col-span-12">
    //       {({getFieldValue}) => {
    //         if(getFieldValue("editorTypeId") == 3 || getFieldValue("editorTypeId") == 4) {
    //           return ( 
    //             <AntForm.List name="options" className='col-span-12'>
    //               {(fields, {add ,remove}) => {
    //                 return (
    //                   <>
    //                     {fields.map(({key, name, ...restFields}) => (
    //                       <Space className='col-span-12 flex'>
    //                         <AntForm.Item {...restFields} name={[name, 'name']} className='flex-1 mb-0'>
    //                           <Input className='w-full'></Input>
    //                         </AntForm.Item>
    //                         <Tag color={getFieldValue(['options', name, 'status']) == 1 ? 'green' : 'red'}>{getFieldValue(['options', name, 'status']) == 1 ? 'Active' : 'Inactive'}</Tag>
    //                         <MinusCircleOutlined onClick={() => {remove(name)}} />
    //                       </Space>
    //                     ))}
    //                     <Button className='col-span-12' type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
    //                       Add answer
    //                     </Button>
    //                   </>
    //                 )
    //               }}
    //             </AntForm.List>
    //           )
    //         }
    //       }}
    //     </AntForm.Item>
    //   )
    // },
    {
      type: 'divider'
    },
    {
      label: 'Parent question',
      name: 'parentId',
      type: 'select',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
        options: [{label: ' ', value: null}, ...data.map((foo, fooIndex) => ({label: foo.title, value: foo.id}))]
      }
    },
    {
      label: 'Option',
      name: 'optionId',
      type: 'select',
      dependentIndex: 'parentId',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
        optionsUrl: '/PreQualificationQuestionOption',
        wcode: `prequalification-group-config-detail`
      }
    },
    // {
    //   type: 'component',
    //   component: (
    //     <AntForm.List name="evaluations" className='col-span-12'>
    //       {(fields, {add ,remove}) => {
    //         return (
    //           <>
    //             {fields.map(({key, name, ...restFields}) => (
    //               <Space className='col-span-12 flex'>
    //                 <AntForm.Item label="Evaluation button name" {...restFields} name={[name, 'name']} className='flex-1 mb-0'>
    //                   <Input className='w-full'></Input>
    //                 </AntForm.Item>
    //                 <AntForm.Item label="Evaluation score" {...restFields} name={[name, 'score']} className='flex-1 mb-0'>
    //                   <Input className='w-full'></Input>
    //                 </AntForm.Item>
    //                 <AntForm.Item label="Has comment" valuePropName="checked" {...restFields} name={[name, 'comment']} className='flex-1 mb-0'>
    //                   <Checkbox/>
    //                 </AntForm.Item>
    //                 <MinusCircleOutlined onClick={() => {remove(name)}} />
    //               </Space>
    //             ))}
    //             <Button className='col-span-12' type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
    //               Add evaluation scoring
    //             </Button>
    //           </>
    //         )
    //       }}
    //     </AntForm.List>
    //   )
    // }
  ]
}

const columns = ({currentPage, handleEdit, handleDelete}) => {
  return ([
    {
      title: '#',
      dataIndex: 'No',
      key: 'No',
      width: 40,
      align: 'center',
      render: (text, row, i) => (
        <span>{currentPage ? currentPage+i+1 : i+1}</span>
      )
    },
    {
      title: 'Question',
      dataIndex: 'title',
      key: 'title',
      filterType: "search",
      render: (text, row) => (
        <span to={`${row.id}`}>{text}</span>
      )
    },
    {
      title: 'Required',
      dataIndex: 'isRequired',
      key: 'isRequired',
      filterType: "search",
      render: (text, row) => (
        <span>{text ? 'True' : 'False'}</span>
      )
    },
    {
      title: 'Use for evaluation',
      dataIndex: 'isUseCalculation',
      key: 'isUseCalculation',
      filterType: "search",
      render: (text, row) => (
        <span>{text ? 'True' : 'False'}</span>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filterType: "search",
      render: (text, row) => (
        <Tag color={text == 0 ? 'red' : 'green'}>{text == 0 ? "Inactive" : 'Active'}</Tag>
      )
    },
    {
      title: 'Publish status',
      dataIndex: 'publishedStatus',
      key: 'publishedStatus',
      filterType: "search",
      render: (text, row) => (
        <Tag color={text == 0 ? 'yellow' : 'green'}>{text == 0 ? "Draft" : 'Published'}</Tag>
      )
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 80,
      render: (text, row) => (
        <Dropdown 
        trigger={['click']} 
        menu={{items: items({row, handleEdit, handleDelete}),}}>
        <a onClick={e => {e.preventDefault()}}>
          <Space>
            <MoreOutlined className='text-xl'/>
          </Space>
        </a>
      </Dropdown>
      )
    },
  ])
}

const PrequalificationGroupDetail = ({question}) => {
  const [ data, setData ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const [ modal, contextHolder ] = Modal.useModal();
  const [ form ] = AntForm.useForm();
  const [ open, setOpen ] = useState(false)
  const [ editData, setEditData ] = useState(null)
  const [ isEdit, setIsEdit ] = useState(false)
  const [ editorType, setEditorType ] = useState([])
  const location = useLocation()

  useEffect(() => {
    fetchData()
    fetchEditorType()
  }, [question])

  const fetchEditorType = () => {
    instance({
      method: 'get',
      url: `EditorType?wcode=prequalification-group-config-detail`
    }).then(res => {
      // console.log(res.data);
      setEditorType(res.data.responseData)
    }).catch(err => {
      console.log(err);
    })
  }

  const fetchData = () => {
    instance({
      method: 'get',
      url: `PreQualificationQuestion/${question.id}?wcode=prequalification-group-config-detail`
    }).then(res => {
      setData(res.data.responseData)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const handleAdd = () => {
    setEditData(null)
    setOpen(true)
  }

  const handleEdit = (_row) => {
    setEditData({
      ..._row,
      "table-row": _row.tableConfig?.length> 0 ? JSON.parse(_row.tableConfig).rows : [],
      "table-col":  _row.tableConfig?.length> 0 ? JSON.parse(_row.tableConfig).cols : [],
      evaluations: _row.evaluationConfig ? _row.evaluationConfig.length > 0 && JSON.parse(_row.evaluationConfig) : [],
      isRequired: _row.isRequired == 1
    })
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
    form.resetFields()
  }

  const handleSubmit = (values) => {
    setLoading(true)
    if(editData) {
      instance({
        method: 'put',
        url: `/PreQualificationQuestion?wcode=prequalification-group-config-detail`,
        data: {
          ...values,
          optionId: parseInt(values.optionId) ? parseInt(values.optionId): null,  
          isUseCalculation: values.isUseCalculation ? 1 : 0,
          preQualificationGroupId: question.id,
          id: editData.id,
          tableConfig: JSON.stringify({
            rows: values["table-row"]?values["table-row"] : [],
            cols: values["table-col"]?values["table-col"] : [],
          }),
          // options: [...editData.options.filter(foo => values.options.some(ai => foo.id == ai.id)), ...editData.options.filter(foo => values.options.some(ai => foo.id == ai.id)).map(foo => ({...foo, status: 0})), ...values.options.filter(foo => !editData.options.some(ai => ai.id == foo.id))],
          evaluationConfig: JSON.stringify(values.evaluations),
          isRequired: values.isRequired ? 1 : 0,
          hasAttachment: values.hasAttachment ? 1 : 0
        }
      }).then(res => {
        handleCloseModal()
        fetchData()
      }).catch(err => {
        
      }).then(() => {
        setLoading(false)
      })
    }
    else {
      instance({
        method: 'post',
        url: `/PreQualificationQuestion?wcode=prequalification-group-config-detail`,
        data: {
          ...values,
          isUseCalculation: values.isUseCalculation ? 1 : 0,
          preQualificationGroupId: question.id,
          optionId: values.optionId ? values.optionId : null,
          tableConfig: JSON.stringify({
            rows: values["table-row"]?values["table-row"] : [],
            cols: values["table-col"]?values["table-col"] : [],
          }),
          evaluationConfig: JSON.stringify(values.evaluations),
          isRequired: values.isRequired ? 1 : 0,
          hasAttachment: values.hasAttachment ? 1 : 0
        }
      }).then(res => {
        handleCloseModal()
        fetchData()
      }).catch(err => {
        
      }).then(() => {
        setLoading(false)
      })
    }    
  }

  const handleDelete = (row) => {
    modal.confirm({
      title: 'Warning!',
      content: 'You are about to change this row of datas status',
      okText: 'Change',
      cancelText: 'Cancel',
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: 'get',
            url: `/PreQualificationQuestion/${row.status == 1 ? 'inactive' : 'active'}/${row.id}?wcode=prequalification-group-config-detail`
          }).then((res) => {
            resolve()
            fetchData()
          }).catch((err) => {
            reject()
          }).then(() => fetchData())
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

  return (
    <div>
      <MainTable
        dataTable={data}
        columns={columns({handleEdit, handleDelete})}
        loading={loading}
        handleAdd={handleAdd}
        title={question.name}
        // draggable={true}
        expandable={{
          expandedRowRender: rec => <PrequalificationAnswers record={rec}></PrequalificationAnswers>,
          rowExpandable: rec => rec.editorTypeId == 3 || rec.editorTypeId == 4 || rec.editorTypeId == 8
        }}>

      </MainTable>
      <Modal
        width={700}
        title={editData ? <div className='flex items-center justify-start gap-2'><EditOutlined/>Edit</div> : <div className='flex items-center justify-start gap-2'><PlusCircleOutlined/>Add</div>}
        open={open}
        destroyOnClose
        maskClosable={false}
        okText='Save'
        onOk={form.submit}
        onCancel={handleCloseModal}
      >
        <Form
          initialValue={{isUseCalculation: true}}
          form={form}
          fields={fields(data, editorType, form)}
          length={data.length}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          isEdit={isEdit}
          className='gap-4 grid grid-cols-12'
        />
      </Modal>
      {contextHolder}
    </div>
  );
}

export default PrequalificationGroupDetail;
