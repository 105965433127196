import React from 'react';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { Button, Tooltip } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';

const Excelexport = ({ excelData, fileName }) => {
  
  const exportToExcel = async () => {
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const ws = XLSX.utils.json_to_sheet(excelData);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
      <>
        <Tooltip
          title={"Excel export"}
          placement="bottomRight">
          <Button
            type='text'
            icon={<FileExcelOutlined className='text-primary' />}
            onClick={(e) => exportToExcel(fileName)} color='primary'
          >
          </Button>
        </Tooltip>
      </>
    )
}

export default Excelexport;
