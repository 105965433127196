import { Card, Tag } from "antd"
import moment from "moment"

const Overview = ({location}) => {
  return (
    <Card className="rounded-none" title="Overview" size="small">
      <div className="flex-auto flex flex-col">
        <div className="flex flex-col items-baseline gap-3">
          <h1 className="w-full flex-none mb-3 text-xl leading-none text-slate-900 flex items-center gap-3">
            {location?.state?.title}
          </h1>
          <div className="flex gap-4 items-center">
            <p className="font-medium mb-0">Status:</p>
            <Tag color={location?.state?.status === 1 ? 'success' : 'default'}>{location?.state?.status === 1 ? 'Идэвхтэй' : 'Идэвхгүй'}</Tag>
          </div>
          <div className="flex-auto text-black font-medium">
            Starting price: <span className="font-medium">{location?.state?.price.toLocaleString("en-US")}₮</span>
          </div>
          <label>
            <div className="pl-0 flex items-center border-gray-500 text-black peer-checked:bg-slate-100 peer-checked:text-slate-900">
              Start Date: <span className="font-medium ml-4">{moment(location?.state?.startDate).format("YYYY-MM-DD HH:mm:ss")}</span>
            </div>
          </label>
          <label>
            <div className="pl-0 flex items-center border-gray-500 text-black peer-checked:bg-slate-100 peer-checked:text-slate-900">
              End Date: <span className="font-medium ml-4">{moment(location?.state?.endDate).format("YYYY-MM-DD HH:mm:ss")}</span>
            </div>
          </label>
        </div>
        <div className="relative overflow-y-auto mt-6">
          <div className="py-2 p-3 border rounded">
            {location?.state?.description}
          </div>
        </div>
      </div>
    </Card>
  )
}

export default Overview