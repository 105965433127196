import { Breadcrumb as AntdBreadcrumb, Badge, Button, Popover } from 'antd';
import { Link, useMatches } from "react-router-dom";
import { CaretRightOutlined } from '@ant-design/icons'
import { ReactComponent as BellIcon } from 'assets/icons/notification-bing.svg'
import { ReactComponent as MessageIcon } from 'assets/icons/messages.svg'
import Messenger from './Messenger';
import { useEffect, useState } from 'react';
import Notification from './Notification';
import { SignalRContext } from 'contexts/socketProvider';
import { instance } from 'utils/axios';

const Breadcrumb = () => {
  const [ messengerOpen, setMessengerOpen ] = useState(false)
  const [ notificationOpen, setNotificationOpen ] = useState(false)
  const [ notificationCount, setNotificationCount ] = useState(0)
  const [ notifications, setNotifications ] = useState([])
  const matches = useMatches()
  
  let crumbs = matches.filter((match) => Boolean(match.handle?.crumb)).map((match) => match.handle.crumb);
  
  const findroute = (_crumb) => {
    return matches.filter((match) => Boolean(match.handle?.crumb)).find(foo => foo.handle.crumb == _crumb).pathname
  }

  SignalRContext.useSignalREffect(
    "ReceiveNotification", 
    (message) => {
      setNotifications(prev => {
        let tmp = [{...message, seenStatus: 0}, ...prev]
        return tmp.length > 5 ? tmp.slice(0, -1) : tmp
      })
    },
  );

  useEffect(() => {
    instance({
      method: 'get',
      url: `/Notification`
    }).then(res => {
      setNotifications(res.data.responseData)
    })
  }, [])
  
  const renderRoutes = () => {
    let tmp = []

    crumbs.map((foo, fooIndex) => {
      tmp.push(
        <Link to={findroute(foo)} className="flex items-center gap-3 hover:!text-primary transition-all" key={`breadcrumb-${fooIndex}`} style={{color: fooIndex+1 == crumbs.length ? 'black' : '#939598'}}>
          <div>
            {foo}
          </div>
          {
            fooIndex+1 != crumbs.length &&
            <CaretRightOutlined/>
          }
        </Link>
      )
    })
    
    return tmp
  }

  const handleSeen = (_id) => {
    setNotifications(prev => {
      const index = prev.findIndex(foo => foo.id == _id)
      prev[index].seenStatus = 1
      return prev
    })
  }
  
  return (
    <div className="px-4 pt-3">
      <div className="flex justify-between items-center gap-3">
        <div className="flex gap-3">
          {
            renderRoutes()
          }
        </div>
        <div className="flex gap-3 items-center">
          <Button type="link" icon={<MessageIcon/>} onClick={() => setMessengerOpen(!messengerOpen)}>
            
          </Button>
          <Popover
            content={<Notification data={notifications} handleNotifSeen={handleSeen}/>}
            // title="Notification"
            trigger={"click"}
            open={notificationOpen}
            onOpenChange={setNotificationOpen}>
            <Badge count={notifications.reduce((a,b) => a+(b.seenStatus == 0 ? 1 : 0), 0)}>
              <Button type="link" icon={<BellIcon/>}>
                
              </Button>
            </Badge>
          </Popover>
        </div>
        <Messenger open={messengerOpen} onClose={() => setMessengerOpen(false)}/>
      </div>
    </div>
  )
}

export default Breadcrumb