import React, { useEffect, useState } from 'react';
import { Button, Modal, Menu, Form as AntForm, Tag, Dropdown, Table, List, Tooltip } from 'antd';
import { EditOutlined, PlusCircleOutlined, DeleteOutlined, ExclamationCircleOutlined, MenuOutlined, LeftOutlined } from '@ant-design/icons';
import { Form, MainTable } from 'components';
import { instance } from 'utils/axios';
import fields from './fields';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SurveyAnswer from './SurveyAnswer';

function Survey_Detail({}) {
  const [data, setData] = useState([]);
  const [editorTypeData, setEditorTypeData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [openOption, setOpenOption] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal, contextHolder] = Modal.useModal();
  const [form] = AntForm.useForm();
  const location = useLocation();
  const navigate = useNavigate()

  useEffect(() => {
    fetchData();
    fetchEditorTypeData();
  }, []);

  const fetchEditorTypeData = () => {
    setLoading(true);
    instance({
      method: 'get',
      url: '/editorType?wcode=survey-detail'
    }).then((res) => {
      let tmp = [];
      res.data.responseData.map((item) =>
        tmp.push({ value: item.id, label: item.content, text: item.content })
      );
      setEditorTypeData(tmp);
    }).catch((err) => {
    }).then(() => setLoading(false));
  };

  const fetchData = () => {
    setLoading(true);
    instance({
      method: 'get',
      url: `/surveyQuestion/surveywithoption/${location.state.id}?wcode=survey-detail`
    }).then((res) => {
      setData(res.data.responseData);
    }).catch((err) => {
    }).then(() => setLoading(false));
  };

  const field =
  [
    {
      label: 'Content',
      name: 'content',
      rules: [{required: true, message: 'Content is required!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'orderId',
      name: 'orderId',
      type: 'number',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Status',
      name: 'status',
      type: 'select',
      rules: [{required: true, message: 'Status is required!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-6',
        options: [
          { label: 'Идэвхтэй', value: 1 }, 
          { label: 'Идэвхгүй', value: 0 },   
      ],
      }
    }, 
  ]

  const columns = [
    {
      title: '№',
      dataIndex: 'No',
      key: 'No',
      width: 40,
      align: 'center',
      render: (text, row, i) => (
        <span>{currentPage ? currentPage+i+1 : i+1}</span>
      )
    },
    {
      title: 'Title',
      dataIndex: 'question',
      key: 'title',
      filterType: "search",   
      render: (cell, row) => {
        return row.question.title
      }
    },
 
    {
      title: 'Order',
      dataIndex: 'question',
      key: 'orderid',
      filterType: "search",
      render: (cell, row) => {
        return row.question.orderId
      }
      
    },
    {
      title: 'Status',
      dataIndex: 'question',
      key: 'status',
      filterType: "search",
      render: (text, row) => (
        <Tag color={row.question?.status === 1 ? 'success' : 'default'}>
        {row.question?.status === 1 ? 'Active' : 'Inactive'}
        </Tag>
      )
    },
    {
      title: 'Answer Type',
      dataIndex: 'question',
      key: 'answerTypeId',
      filterType: "search",
      render: (value, row) => (
        <>
          {editorTypeData.find((item) => item.value === value.answerTypeId)?.label}
        </>
      )
    },
    
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 80,
      render: (text, row) => (
        <div className=''>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="edit" onClick={() => handleEdit(row)}>
                  <div className='flex items-center gap-2'><EditOutlined/> Edit</div>
                </Menu.Item>
                <Menu.Item key="delete" onClick={() => handleDelete(row)}>
                  <div className='flex items-center gap-2'><DeleteOutlined /> Delete</div>
                </Menu.Item>
              </Menu>
            }
          >
            <Button size='small' type='text' className='m-1'>
              ...
            </Button>
          </Dropdown>
        </div>
      )
    },
  ]
  const handleDelete = (row) => {console.log("id",row.question.id)
    modal.confirm({
      title: 'Анхаарна уу !',
      content: 'Энэхүү мөр бичлэгийг устгах гэж байна',
      okText: 'Устгах',
      cancelText: 'Буцах',
      onOk() { 
        return new Promise((resolve, reject) => {
          instance({
            method: 'delete',
            url: `surveyQuestion/${row.question.id}?wcode=survey-detail`
          }).then((res) => {
            resolve()
          }).catch((err) => {
            reject()
          }).then(() => fetchData())
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

  const handleSubmit = (values) => {
    setLoading(true);
    if (editData) {
      instance({
        method: 'put',
        url: '/surveyQuestion?wcode=survey-detail', 
        headers: { 'Content-Type': 'application/json' },
        data: {
          ...values,
          id: editData?.question?.id,
          surveyId: location?.state?.id,
          tableConfig: JSON.stringify({
            rows: values["table-row"]?values["table-row"] : [],
            cols: values["table-col"]?values["table-col"] : [],
          }),
          orderId: 0
        }
      })
      .then((res) => {
        handleCloseModal();
        fetchData();
      })
      .catch((err) => {
      })
      .finally(() => setLoading(false));
    } else {
      instance({
        method: 'post',
        url: '/surveyQuestion?wcode=survey-detail',
        headers: { 'Content-Type': 'application/json' },
        data: {
          ...values,
          surveyId: location?.state?.id,
          tableConfig: JSON.stringify({
            rows: values["table-row"]?values["table-row"] : [],
            cols: values["table-col"]?values["table-col"] : [],
          }),
          orderId: 0
        }
      })
      .then((res) => {
        handleCloseModal();
        fetchData();
      })
      .catch((err) => {
      })
      .finally(() => setLoading(false));
    }
  }

  const handleAdd = () => {
    setIsEdit(true)
    setEditData(null)
    setOpen(true)
  }
  const handleEdit = (row) => {
    setEditData(row)
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
    setOpenOption(false)
    form.resetFields()
  }

  return ( 
    <div className='p-4'>
      <div className="col-span-12 mb-4">
        <Tooltip title="Go back">
          <Button type="link" onClick={() => navigate("/survey")} icon={<LeftOutlined />}>
            Go back to survey list
          </Button>
        </Tooltip>
      </div>
      <MainTable
        size="small"
        dataTable={data}
        columns={columns}
        rowKey={(row) => row.question.id}
        setCurrentPage={setCurrentPage}
        loading={loading}
        pagination={false}
        title={location.state.name}
        handleAdd={handleAdd}
        expandable={{
          expandedRowRender: rec => <SurveyAnswer record={rec}></SurveyAnswer>,
          rowExpandable: rec => rec.editorTypeId == 3 || rec.editorTypeId == 4 || rec.editorTypeId == 8
        }} 
      />
      <Modal
        title={editData ? <div className='flex items-center justify-start gap-2'><EditOutlined/>Edit</div> : <div className='flex items-center justify-start gap-2'><PlusCircleOutlined/>Add</div>}
        open={open}
        destroyOnClose
        maskClosable={false}
        okText='Save'
        onOk={form.submit}
        onCancel={handleCloseModal}
      >
        <Form
          form={form}
          fields={fields(editorTypeData)}
          length={data?.length}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData?.question}
          isEdit={isEdit}
          className='gap-4'
        />
      </Modal>

      
      {contextHolder}
    </div>
  )
}

export default Survey_Detail
