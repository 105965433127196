import { ConfigProvider } from "antd"

const AntdThemeProvider = ({children}) => {

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#01B4C5',
          colorLink: '#01B4C5',
        },
        components: {
          menu: {
            lineWidth: 0
          },
          Table: {
            headerBorderRadius: 0
          },
          Menu: {
            darkItemColor: 'white',
            darkSubMenuItemBg: "#375A7C",
            iconSize: 16
          },
          Collapse: {
            contentPadding: 0,
            headerPadding: 0
          }
        }
      }}>
      {children}
    </ConfigProvider>
  )
}

export default AntdThemeProvider