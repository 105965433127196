import React, { useEffect, useState } from 'react'
import { Skeleton, Modal, Card, Radio, Form } from 'antd'
import { instance } from 'utils/axios';
import TenderTypeContainer from './tenderTypeContainer';
import { Filter } from './components';
import { Filters } from 'components';

function TenderList() {
  const [ loading, setLoading ] = useState(true)
  const [ modal, contextHolder ] = Modal.useModal();
  const [ suppliers, setSuppliers ] = useState([])
  const [ types, setTypes ] = useState([])
  const [ filter, setFilter ] = useState({
    type: null
  })

  useEffect(() => {
    fetchSuppliers()
    fetchTypes()
  }, [])

  const fetchTypes = () => {
    instance({
      method: 'get',
      url: `TenderType?wcode=tender`
    }).then(res => {
      let tmp = res.data.responseData.sort((a,b) => b.name == "RFQ" ? 1 : -1)
      setTypes(tmp)
      if(res.data.responseData && res.data.responseData.length > 0) {
        setFilter(prev => ({...prev, type: tmp[0].id}))
      }
    })
  }

  const fetchSuppliers = () => {
    instance({
      method: 'get',
      url: `/Supplier/available?wcode=tender`
    }).then(res => {
      setSuppliers(res.data.responseData)
    }).catch(err => {
      
    }).then(() => {
      setLoading(false)
    })
  }

  const onSearch = (values) => {
    setFilter(prev => ({...prev, ...values}))
  }
  
  return (
    <div className='p-4'>
    {
      loading ?
      <div className="p-4 bg-white rounded-2xl">
        <Skeleton active>
        </Skeleton>
      </div>
      :
      <div className='grid grid-cols-12 gap-3'>
        <div className="col-span-12">
          <Radio.Group className='mb-3' onChange={(e) => {setFilter(prev => ({...prev, type: e.target.value}))}} value={filter.type}>
            {types.map((foo, fooIndex) => {
              return (
                <Radio.Button key={`type-filter-${fooIndex}`} value={foo.id}>{foo.name}</Radio.Button>
              )
            })}
          </Radio.Group>
          <Filters 
            wcode="tender" 
            onSearch={onSearch} 
            activeFilters={filter}>
            {/* {
              types.length > 0 &&
              <Form.Item label="Type">
                <Radio.Group onChange={(e) => {setFilter(prev => ({...prev, type: e.target.value}))}} value={filter.type}>
                  {types.map((foo, fooIndex) => {
                    return (
                      <Radio.Button key={`type-filter-${fooIndex}`} value={foo.id}>{foo.name}</Radio.Button>
                    )
                  })}
                </Radio.Group>
              </Form.Item>
            } */}
          </Filters>
        </div>
        <Card className='col-span-12' size="small">
          <TenderTypeContainer type={filter.type} modal={modal} suppliers={suppliers} filter={filter}/>
        </Card>
      </div>
    }
    {contextHolder}
    </div>
  )
}

export default TenderList
