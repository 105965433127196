import { useEffect, useState } from "react"
import { Button, Card, Form, Input, Modal, Select, Skeleton, Tooltip } from 'antd'
import { FormOutlined, LeftOutlined } from '@ant-design/icons'
import moment from 'moment'
import { instance } from "utils/axios"
import { useParams } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component';
import ChatBubble from "./chatBubble"
import ChatWindow from "./chatWindow"

const PrivateChat = () => {
  const [ loading, setLoading ] = useState(true)
  const [ data, setData ] = useState([])
  const [ hasMore, setHasMore ] = useState(false)
  const { id } = useParams()
  const [ selectedChat, setSelectedChat ] = useState(null)
  const [ chats, setChats ] = useState([])
  const [ chatModalOpen, setChatModalOpen ] = useState(false)
  const [ form ] = Form.useForm()

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if(selectedChat) {
      setLoading(true)
      fetchChat()
    }
  }, [selectedChat])

  const fetchChat = () => {
    instance({
      method: 'get',
      url: `/Message/thread/${selectedChat.id}`
    }).then(res => {
      setChats(res.data.responseData.reverse())
    }).catch(() => {
      
    }).then(() => {
      setLoading(false) 
    })
  }

  const fetchData = () => {
    instance({
      method: 'get',
      url: `/Message/tender/enduser/${id}?wcode=tender-detail`
    }).then(res => {
      if(res.data.responseData) {
        setData(res.data.responseData)
      }
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const fetchNext = () => {

  }

  const handleSubmit = (values) => {
    instance({
      method: 'post',
      url: `/Message/send`,
      data: {
        "tenderId": id,
        ...values
      }
    }).then(() => {
      form.resetFields()
      setChatModalOpen(false)
      fetchData()
    })
  }
  console.log(data)
  return (
    <div className="flex flex-col gap-2">
      {
        loading ?
        <Skeleton active></Skeleton>
        :
        selectedChat ? 
        <div className="py-6">
          <div className="border-b border-gray-200 flex gap-4 mb-6 pb-6">
            <div>
              <Tooltip title="Go back to list">
                <Button className="h-full" type='link' onClick={() => setSelectedChat(null)} icon={<LeftOutlined />}>

                </Button>
              </Tooltip>
            </div>
            <div className="grow">
              <div className="text-xl font-bold">
                {selectedChat.name}
              </div>
              <div>
                {selectedChat.title}
              </div>
            </div>
          </div>
          {chats.map((foo, fooIndex) => {
            return (
              <ChatBubble data={foo} key={`chat-bubble-${fooIndex}`}></ChatBubble>
            )
          })}
          <ChatWindow data={selectedChat} fetchChat={fetchChat}></ChatWindow>
        </div>
        :
        <div className="bg-white">
          <div className="flex justify-between items-center py-2 px-4 border-b border-gray-200">
            <div className="text-lg font-bold">Chat</div>
            <div>
              {/* <Tooltip title="Create new chat">
                <Button type="link" onClick={() => setChatModalOpen(true)}>
                  <FormOutlined className="text-xl"/>
                </Button>
              </Tooltip> */}
            </div>
          </div>
          {
            data.map((foo, fooIndex) => {
              return (
                <Card className="rounded-none border-b border-slate-100 last:border-none hover:bg-gray-50 transition-all cursor-pointer duration-75" bordered={false} key={`chat-${fooIndex}`} bodyStyle={{padding: `8px 16px`}} onClick={() => setSelectedChat(foo)}>
                  <div className="flex gap-3">
                    <div className="w-50 h-50">
                      <img src={"https://via.placeholder.com/50"} className="shadow-md rounded-full"></img>
                    </div>
                    <div className="grow">
                      <div>{foo.name}</div>
                      <div className="flex gap-4">
                        <div className="truncate whitespace-nowrap">{foo.title}</div>
                        <div>{moment(foo.lastResponseDate).format("HH:mm")}</div>
                      </div>
                    </div>
                  </div>
                </Card>
              )
            })
          }
        </div>
      }
    </div>
  )
}

export default PrivateChat