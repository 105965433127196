const suppliersColumns = (selected) => {
  return [
    {
      title: '#',
      width: 50,
      count: true,
      fixed: 'left',
      render: (cell, row, index) => index+1,
      shouldCellUpdate: false
    },
    {
      title: 'Supplier',
      dataIndex: 'name',
      editable: false,
    },
    // {
    //   title: 'Items',
    //   dataIndex: 'count',
    //   editable: false,
    //   width: 100,
    // },
    {
      title: 'Value',
      dataIndex: 'totalPrice',
      editable: false,
      width: 100,
      render: (cell) => cell?.toLocaleString('en-US')
    },
    {
      title: 'Comment',
      dataIndex: 'commentText',
      editable: true,
      type: 'select',
      options: [
        {
          label: 'Recommended because price is the lowest',
          value: 'Recommended because price is the lowest'
        },
        {
          label: '1 item is not the lowest but LT is the shortest, end user preffered shortest LT selected',
          value: '1 item is not the lowest but LT is the shortest, end user preffered shortest LT selected'
        },
        {
          label: '1 item is not the lowest and alternative choice but EU checked the datasheet and chosen alternative brand',
          value: '1 item is not the lowest and alternative choice but EU checked the datasheet and chosen alternative brand'
        },
        {
          label: '1 item is not the lowest but supplier required combined bundle shipment for other 4 items, thus selected',
          value: '1 item is not the lowest but supplier required combined bundle shipment for other 4 items, thus selected'
        },
      ],
      width: 500
    },
  ]
}

const losersColumns = (selected) => {
  return [
    {
      title: '#',
      width: 50,
      count: true,
      fixed: 'left',
      render: (cell, row, index) => index+1,
      shouldCellUpdate: false
    },
    {
      title: 'Supplier',
      dataIndex: 'name',
      editable: false,
    },
    // {
    //   title: 'Items',
    //   dataIndex: 'count',
    //   editable: false,
    //   width: 100,
    // },
    {
      title: 'Comment',
      dataIndex: 'commentText',
      editable: true,
      type: 'select',
      options: [
        {
          label: 'Recommended because price is the lowest',
          value: 'Recommended because price is the lowest'
        },
        {
          label: '1 item is not the lowest but LT is the shortest, end user preffered shortest LT selected',
          value: '1 item is not the lowest but LT is the shortest, end user preffered shortest LT selected'
        },
        {
          label: '1 item is not the lowest and alternative choice but EU checked the datasheet and chosen alternative brand',
          value: '1 item is not the lowest and alternative choice but EU checked the datasheet and chosen alternative brand'
        },
        {
          label: '1 item is not the lowest but supplier required combined bundle shipment for other 4 items, thus selected',
          value: '1 item is not the lowest but supplier required combined bundle shipment for other 4 items, thus selected'
        },
      ],
      width: 500
    },
  ]
}

export { suppliersColumns, losersColumns }