import { useState, useEffect } from 'react'
import { Form } from 'components'
import { Button, Form as AntForm, Card, Input, Select, Divider } from 'antd'
import { instance } from 'utils/axios'
import { useOutletContext } from 'react-router-dom'

const CompanyInformation = () => {  
  const [ data ] = useOutletContext()
  const [ loading, setLoading ] = useState(false)
  const [ form ] = AntForm.useForm();
  const { supplierInfo } = data

  useEffect(() => {
    form.setFieldsValue(data)
  }, [data])
  
  const fields = () => {
    return [
      {
        label: 'Description',
        name: 'description',
        type: 'textarea',
        rules: [{required: true, message: 'Description is required!'}],
        className: 'col-span-12 mb-0',
        inputProps: {
          className: 'w-full',
          rows: 4
        }
      },
      {
        label: 'File',
        name: 'file',
        type: 'file',
        rules: [{required: true, message: 'File is required!'}],
        className: 'col-span-12 mb-0',
        inputProps: {
          className: 'w-full',
        }
      },
    ]
  }
  
  return (
    <div className='flex flex-col gap-3'>
      <Card size="small">
        <div className="font-bold text-lg">
          1. Company details
        </div>
        <Divider className="my-3"></Divider>
        <div className="grid grid-cols-4 gap-3 mb-7">
          <div>
            <label className="font-semibold">Company name:</label>
            <div className="text-mute text-sm">{supplierInfo.enName ? supplierInfo.enName : ''}</div>
          </div>
          <div>
            <label className="font-semibold">Business type:</label>
            <div className="text-mute text-sm">{supplierInfo.businessTypeName ? supplierInfo.businessTypeName : 'No business types'}</div>
          </div>
          <div>
            <label className="font-semibold">Existing supplier:</label>
            <div className="text-mute text-sm">{supplierInfo.isExistingSupplier ? "Yes" : 'No'}</div>
          </div>
        </div>
        <div className="font-bold text-lg">
          2. Address
        </div>
        <Divider className='my-3'></Divider>
        <div className="grid grid-cols-4 gap-3 mb-7">
          <div>
            <label className="font-semibold">Address line:</label>
            <div className="text-mute text-sm">{supplierInfo.address1 ? supplierInfo.address1 : 'No address'}</div>
          </div>
          <div>
            <label className="font-semibold">Address line 2 / Soum:</label>
            <div className="text-mute text-sm">{supplierInfo.address2 ? supplierInfo.address2 : 'No address'}</div>
          </div>
          <div>
            <label className="font-semibold">Address line 3:</label>
            <div className="text-mute text-sm">{supplierInfo.address3 ? supplierInfo.address3 : 'No address'}</div>
          </div>
          <div>
            <label className="font-semibold">Postcode or zipcode:</label>
            <div className="text-mute text-sm">{supplierInfo.zipcode}</div>
          </div>
          <div>
            <label className="font-semibold">Town/City/Aimag:</label>
            <div className="text-mute text-sm">{supplierInfo.town}</div>
          </div>
          <div>
            <label className="font-semibold">State/Province:</label>
            <div className="text-mute text-sm">{supplierInfo.province}</div>
          </div>
          <div>
            <label className="font-semibold">Country:</label>
            <div className="text-mute text-sm">{supplierInfo.addressCountryName}</div>
          </div>
        </div>
        <div className="font-bold text-lg">
          3. More information
        </div>
        <Divider className='my-3'></Divider>
        <div className="grid grid-cols-4 gap-3 mb-7">
          <div>
            <label className="font-semibold">Company registered country:</label>
            <div className="text-mute text-sm">{supplierInfo.registeredCountryName}</div>
          </div>
          <div>
            <label className="font-semibold">Company registered aimag:</label>
            <div className="text-mute text-sm">{supplierInfo.registeredAimagName}</div>
          </div>
          <div>
            <label className="font-semibold">Company registration number:</label>
            <div className="text-mute text-sm">{supplierInfo.regNo}</div>
          </div>
          <div>
            <label className="font-semibold">Certificate of registration:</label>
            <div className="text-mute text-sm"><a href={process.env.REACT_APP_CDN_URL+supplierInfo.registrationCertificate} target='_blank'>{supplierInfo.registrationCertificate}</a></div>
          </div>
          <div>
            <label className="font-semibold">Company website:</label>
            <div className="text-mute text-sm">{supplierInfo.website}</div>
          </div>
          <div>
            <label className="font-semibold">Company e-mail:</label>
            <div className="text-mute text-sm">{supplierInfo.companyEmail}</div>
          </div>
          <div>
            <label className="font-semibold">Percentage of company is owned by foreign entity:</label>
            <div className="text-mute text-sm">{supplierInfo.foreignPercentName}</div>
          </div>
          <div>
            <label className="font-semibold">Total number of employees:</label>
            <div className="text-mute text-sm">{supplierInfo.totalEmployee}</div>
          </div>
          <div>
            <label className="font-semibold">Total number of Mongolian employees:</label>
            <div className="text-mute text-sm">{supplierInfo.totalMongolianEmployee}</div>
          </div>
          <div>
            <label className="font-semibold">Total number of Umnugovi employees:</label>
            <div className="text-mute text-sm">{supplierInfo.totalUmnugoviEmployee}</div>
          </div>
        </div>
      </Card>
      {/* <Form
        disabled={true}
        form={form}
        fields={[]}
        layout="vertical"
        onFinish={() => {}}
        className='gap-4'>  
        <Card title="1. Company details" className="col-span-12 rounded-2xl">
          <AntForm.Item label="Are you existing supplier?" name="isExistingSupplier">
            <Select options={[{label: 'Yes', value: 1}, {label: 'No', value: 2}]}></Select>
          </AntForm.Item>
          <AntForm.Item label="Business type" name="businessTypeName">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="Company name (in English alphabet only)" name="name">
            <Input></Input>
          </AntForm.Item>
        </Card>
        <Card title="2. Address" className="col-span-12 rounded-2xl">
          <AntForm.Item label="Address line" name="address1">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="Address line 2 / Soum" name="address2">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="Address line 3">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="Postcode or zipcode" name="zipcode">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="Town/City/Aimag" name="town">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="State/Province" name="province">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="Country" name="registeredCountryName">
            <Input></Input>
          </AntForm.Item>
        </Card>
        <Card className="col-span-12 rounded-2xl">
          <AntForm.Item label="3. Country you are registered in" name="registeredCountryName">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="Aimag you are registered in" name="registeredAimagName">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="6. Company registration number" name="regNo">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="7. Certificate of registration">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="8. Company website" name="website">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="9. Company e-mail" name="companyEmail">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="10. Please indicate what percentage of company is owned by foreign entity" name="foreignOwnPercentId">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="11. Total number of employees" name="totalEmployee">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="12. Total number of Mongolian employees" name="totalMongolianEmployee">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="13. Total number of Umnugovi employees" name="totalUmnugoviEmployee">
            <Input></Input>
          </AntForm.Item>
        </Card>
      </Form> */}
      
    </div>
  )
}

export default CompanyInformation