import { Button, Input, Modal, Select, Table, Popover, Card, Dropdown, Tooltip, Transfer, InputNumber, Form, DatePicker, Checkbox, Switch, Segmented, TimePicker, Badge, AutoComplete } from "antd"
import { useEffect, useMemo, useRef, useState } from "react"
import { useOutletContext, useParams } from "react-router-dom"
import { instance } from "utils/axios"
import { FilterOutlined, CheckCircleOutlined, QuestionCircleOutlined, StarOutlined, StarFilled, LinkOutlined, WarningOutlined, CheckOutlined } from '@ant-design/icons'
import { twMerge } from "tailwind-merge"

const BidSummaryList = ({}) => {
  const [ rawData, setRawData ] = useState({
    suppliers: [],
    comments: [],
    detail: []
  })
  const [ data, setData ] = useState([])
  const [ loading, setLoading ] = useState([])
  const [ messageData, setMessageData ] = useState({
    thread: {},
    messages: []
  })
  const [ modal, contextHolder ] = Modal.useModal();
  const currencyRef = useRef()
  const [ checked, setChecked ] = useState([])
  const { id } = useParams()
  const [ sortValue, setSortValue ] = useState(['end', 'buyer'])
  const [ lowestPriceSort, setLowestPriceSort ] = useState(false)
  const [ buyerSort, setBuyerSort ] = useState(false)
  const [ endSort, setEndSort ] = useState(false)
  const [ licenseSort, setLicenseSort ] = useState(false)

  useEffect(() => {
    if(sortValue.includes("lowest")) {
      setLowestPriceSort(true)
    }
    if(sortValue.includes("lowestDays")) {
      findLowestDays()
    }
    if(sortValue.includes("alternative")) {
      findNonAlternative()
    }
    if(sortValue.includes("end")) {
      setEndSort(true)
    }
    if(sortValue.includes("buyer")) {
      setBuyerSort(true)
    }
    if(sortValue.includes("license")) {
      setLicenseSort(true)
    }
    if(sortValue.includes("partial")) {
      findNonPartial()
    }
  }, [sortValue])

  useEffect(() => {
    fetchData()
    fetchMessageData()
  }, [])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `/Tender/rfq/bidsummary/${id}?wcode=tender-detail`
    }).then(res => {
      if(res.status != 204) {
        setRawData(res.data.responseData)
        // generateColumns(res.data.responseData)
        fixSuppliersData(res.data.responseData)
      }
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const fetchMessageData = () => {
    instance({
      method: 'get',
      url: `/Message/tender/main/${id}?wcode=tender-detail`
    }).then(res => {
      setMessageData(res.data.responseData)
    }).catch(err => {

    })
  }

  const generateColumns = useMemo(() => {
      let tmp = []
  
      tmp.push({
        title: 'Items',
        dataIndex: 'itemInfo',
        key: 'itemInfo',
        width: 150,
        fixed: 'left',
        onCell: undefined,
        render: (text, row, index) => {
          return (
            <div className="flex flex-col p-1 cursor-pointer hover:underline px-3">
              <Popover title={
                <div className="max-w-[300px] text-sm">
                  <div className="flex gap-2">
                    <div>Description:</div>
                    <div className="text-wrap truncate">{row.description}</div>
                  </div>
                  <div className="flex gap-2">
                    <div>Material number:</div>
                    <div>{row.materialNumber}</div>
                  </div>
                  <div className="flex gap-2">
                    <div>Manufacturer:</div>
                    <div>{row.manufacturer}</div>
                  </div>
                  <div className="flex gap-2">
                    <div>Part number:</div>
                    <div>{row.partNumber}</div>
                  </div>
                  <div className="flex gap-2">
                    <div>Quantity:</div>
                    <div>{row.qty}</div>
                  </div>
                  <div className="flex gap-2">
                    <div>UOM:</div>
                    <div>{row.uom}</div>
                  </div>
                </div>
              }>
                <div className="text-nowrap truncate">{row.description.slice(0, 20)}... /{row.materialNumber}/ {row.qty} {row.uom}</div>
              </Popover>
              <div className="flex gap-2 text-xs">
                <div>Average price:</div><div>{row?.avgPrice?.toLocaleString("en-US")}</div>
              </div>
              <div className="flex gap-2 text-xs">
              <div>Last price:</div><div>{row?.lastPrice?.toLocaleString("en-US")}</div>
              </div>
              <div className="flex gap-2 text-xs">
              <div>Criticality:</div><div>{row?.criticality}</div>
              </div>
              
            </div>
          )
        }
      })
      tmp.push({
        title: '',
        dataIndex: 'spec',
        key: 'spec',
        fixed: 'left',
        width: 130,
        render: (_, row, index) => {
          return (
            <div className="flex flex-col text-xs">
              <div className="border-b px-3 leading-5">Quantity</div>
              <div className="border-b px-3 leading-5">Days</div>
              <div className="border-b px-3 leading-5">Price</div>
              <div className="border-b px-3 leading-5">Alternative</div>
              <div className="px-3 leading-5">License</div>
            </div>
          )
        }
      })
      if(data) {
        let copy = Object.assign({
          suppliers: [],
          comments: [],
          detail: []
        }, rawData)
        copy.suppliers.map((supplier, supplierIndex) => {
          supplier.itemsCount = supplier.details.length
          supplier.itemsTotalPrice = supplier.details.reduce((a,b) => a+(b.unitPrice*b.shipQty), 0)
          supplier.totalLowest = supplier.details.filter(foo => foo.lowest).length
          supplier.itemsLead = Math.max(...supplier.details.map(foo => foo.shipDays))
        })

        copy.suppliers.sort((a,b) => {
          if(a.totalLowest == b.totalLowest) {
            if(a.itemsCount == b.itemsCount) {
              if(a.itemsLead == b.itemsLead) {
                  return a.name > b.name ? 1 : -1
              } 
              return a.itemsLead > b.itemsLead ? 1 : -1 
            }
            return a.itemsCount < b.itemsCount ? 1 : -1
          }
          return a.totalLowest < b.totalLowest ? 1 : -1
        }).map((supplier, supplierIndex) => {
          tmp.push({
            title: <Tooltip placement="bottomLeft" className="truncate text-nowrap overflow-hidden" title={supplier.name}>
              <div className="cursor-pointer hover:underline">{supplier.name}</div>
            </Tooltip>,
            dataIndex: `supplier-${supplier.id}`,
            key: `supplier-${supplier.id}`,
            width: 120,
            render: (text, row) => {
              if(text?.shipDays && text?.shipQty && text?.unitPrice) {
                return (
                  <div 
                    className="relative h-full cursor-pointer hover:border-primary border border-transparent transition-all" 
                    style={checked.find(foo => foo == text.id) ? {backgroundColor: '#24c8d130'} : {}}>
                    <div className={twMerge("grid grid-cols-1 gap-x-2 border-b px-3 leading-5 font-medium",  text?.partial && 'bg-green-600 text-white')}>
                      <div>{text?.shipQty} <span className="">/{text?.shipUOM}/</span> {text.negotiateStatus == 0 && text.oldShipQty && <Tooltip title="Supplier confirmed"><CheckOutlined className="text-primary"/></Tooltip>}</div>
                    </div>
                    <div className={twMerge("grid grid-cols-1 gap-x-2 border-b px-3 leading-5 font-medium",  text?.lowestDays && 'bg-green-600 text-white')}>
                      <div>{text?.shipDays} {text.negotiateStatus == 0 && text.oldShipDays && <Tooltip title="Supplier confirmed"><CheckOutlined className="text-primary"/></Tooltip>}</div>
                    </div>
                    <div className={twMerge("grid grid-cols-1 gap-x-2 border-b px-3 leading-5 font-medium",  (text?.lowest && lowestPriceSort == true) && 'bg-green-600 text-white')}>
                      <div>{text?.unitPriceUsd.toLocaleString("en-US")} {text.negotiateStatus == 0 && text.oldUnitPrice && <Tooltip title="Supplier confirmed"><CheckOutlined className="text-primary"/></Tooltip>}</div>
                    </div>
                    <div className={twMerge("grid grid-cols-1 gap-x-2 border-b px-3 leading-5 font-medium",  text?.alternative && 'bg-green-600 text-white')}>
                      <div>{text?.isAlternative == 0 ? 'No': 'Yes'}</div>
                    </div>
                    <div className={twMerge("grid grid-cols-1 gap-x-2 px-3 leading-5 font-medium", (text?.hasLicense == 1 && licenseSort == true) && 'bg-green-600 text-white')}>
                      <div>{text?.hasLicense == 0 ? 'No': 'Yes'}</div>
                    </div>
                    {
                      text.negotiateStatus == 1 ?
                      <div className="absolute inset-0 z-[1]">
                        <Badge.Ribbon text={'Negotiation'}>

                        </Badge.Ribbon>
                      </div>
                      :
                      <></>
                    }
                    <div className="absolute h-full right-0 top-0 flex flex-col gap-1 py-2 px-1 z-[1] bg-red-[400]">
                      {
                        (text.oldUnitPrice || text.oldShipDays || text.comment) &&
                        <Tooltip title={
                          <div className="grid grid-cols-2 gap-3">
                            {
                              text.oldUnitPrice ?
                              <>
                              <div>Old unit price:</div>
                              <div>{text.oldUnitPrice.toLocaleString("en-US")}</div>
                              </>
                              :
                              <></>
                            }
                            {
                              text.oldShipQty ?
                              <>
                              <div>Old qty:</div>
                              <div>{text.oldShipQty}</div>
                              </>
                              :
                              <></>
                            }
                            {
                              text.oldShipDays ?
                              <>
                              <div>Old ship days:</div>
                              <div>{text.oldShipDays}</div>
                              </>
                              :
                              <></>
                            }
                            {
                              text.comment &&
                              <>
                              <div>Comment:</div>
                              <div>{text.comment}</div>
                              </>
                            }
                          </div>
                        }>
                          <QuestionCircleOutlined />
                        </Tooltip>
                      }
                      {
                        text.file &&
                        <Tooltip title="Attachment">
                          <a target="_blank" href={process.env.REACT_APP_CDN_URL+text.file}>
                            <LinkOutlined />
                          </a>
                        </Tooltip>
                      }
                      {
                        text.isUserSuggested == 1 && endSort &&
                        <Tooltip title={
                          <div className="">
                            <div>
                              {rawData.comments.filter(foo => foo.isEndUserAnswered == 1 && foo.status == 1).slice(-1)[0]?.comment}
                            </div>
                          </div>
                        }>
                          <div className="bg-black/60 rounded-full w-4 h-4 font-bold text-white text-xs flex justify-center items-center leading-none">
                            E
                          </div>
                        </Tooltip>
                      }
                      {
                        text.isSelected == 1 && buyerSort &&
                        <Tooltip title={
                          <div className="">
                            <div>
                              {rawData.comments.filter(foo => foo.status == 1 && foo.isEndUserAnswered == 0).slice(-1)[0]?.comment}
                            </div>
                          </div>
                        }>
                          <div className="bg-black/60 rounded-full w-4 h-4 font-bold text-white text-xs flex justify-center items-center leading-none">
                            B
                          </div>
                        </Tooltip>
                      }
                    </div>
                  </div>
                )
              }
              return null
            }
          })
        })
      }

      return tmp
  }, [data, buyerSort, endSort, licenseSort, lowestPriceSort])

  const fixSuppliersData = (_data) => {
    let tmp = []

    _data.detail.map((foo,fooIndex) => {
      tmp.push(foo)
      foo.supplierData.map((_supplier, _supplierIndex) => {
        tmp[fooIndex][`supplier-${_supplier.supplierId}`] = _supplier
      })
    })
    
    setData(tmp)
  }
  
  const handleClearSort = (_key) => {
    if(_key) {
      if(_key == "lowest") {
        setLowestPriceSort(false)
      }
      else if(_key == 'buyer') {
        setBuyerSort(false)
      }
      else if (_key == 'end') {
        setEndSort(false)
      }
      else if (_key == 'license') {
        setLicenseSort(false)
      }
      else {
        setData(prev => {
          let tmp = Object.assign([], prev)
          tmp.map((foo) => {
            let suppliers = Object.keys(foo).filter(foo => foo.includes('supplier-'))
            suppliers.map(_supplier => {
              if(foo[_supplier][_key] ) {
                delete foo[_supplier][_key]
              }
            })
          })
          
          return tmp
        })
      }
    }
    else {
      setData(prev => {
        let tmp = Object.assign([], prev)
        tmp.map((foo) => {
          let suppliers = Object.keys(foo).filter(foo => foo.includes('supplier-'))
          suppliers.map(_supplier => {
            if(foo[_supplier].lowestDays || foo[_supplier].alternative) {
              delete foo[_supplier].lowestDays
              delete foo[_supplier].alternative
            }
          })
        })
        
        return tmp
      })
      setLowestPriceSort(false)
      setBuyerSort(false)
      setEndSort(false)
      setLicenseSort(false)
      setSortValue([])
    }
  }
  
  const findLowestDays = () => {   
    setData(prev => {
      let tmp = Object.assign([], prev)

      tmp.map((_item, _itemIndex) => {
        let suppliers = Object.keys(_item).filter(foo => foo.includes('supplier-'))
        if(suppliers.length > 0) {
          suppliers.map(foo => {
            _item[foo].lowestDays = false
          })
          
          let lowest = suppliers[0]
          let otherlowers = []
          suppliers.map((_supplier, _supplierIndex) => {
            if(_item[_supplier].shipDays < _item[lowest].shipDays) {
              lowest = _supplier
              otherlowers = []
            }
            else if(_item[_supplier].shipDays == _item[lowest].shipDays) {
              otherlowers.push(_supplier)
            }
          })
          _item[lowest].lowestDays = true
          otherlowers.map(foo => {
            _item[foo].lowestDays = true
          })
        }
      })

      return tmp
    })
  }

  const findNonAlternative = () => {
    setData(prev => {
      let tmp = Object.assign([], prev)

      tmp.map((_item, _itemIndex) => {
        let suppliers = Object.keys(_item).filter(foo => foo.includes('supplier-'))
        if(suppliers.length > 0) {
          suppliers.map(foo => {
            _item[foo].alternative = _item[foo].isAlternative == 1 ? false : true
          })
        }
      })

      return tmp
    })
  }

  const findLowestPrice = () => {   

    setData(prev => {
      let tmp = Object.assign([], prev)

      tmp.map((_item, _itemIndex) => {
        let suppliers = Object.keys(_item).filter(foo => foo.includes('supplier-'))
        if(suppliers.length > 0) {
          suppliers.map(foo => {
            _item[foo].lowest = false
          })

          let lowest = suppliers[0]
          let otherlowers = []
          suppliers.map((_supplier, _supplierIndex) => {
            if(_item[_supplier].unitPrice < _item[lowest].unitPrice) {
              lowest = _supplier
              otherlowers = []
            }
            else if(_item[_supplier].unitPrice == _item[lowest].unitPrice) {
              otherlowers.push(_supplier)
            }
          })
          _item[lowest].lowest = true
          otherlowers.map(foo => {
            _item[foo].lowest = true
          })
        }
      })

      return tmp
    })
  }

  const findNonPartial = () => {
    setData(prev => {
      let tmp = Object.assign([], prev)

      tmp.map((_item, _itemIndex) => {
        let suppliers = Object.keys(_item).filter(foo => foo.includes('supplier-'))
        if(suppliers.length > 0) {
          suppliers.map(foo => {
            _item[foo].partial = false
          })

          suppliers.map((_supplier, _supplierIndex) => {
            if(_item[_supplier].shipUOM == _item.uom && _item[_supplier].shipQty == _item.qty) {
              _item[_supplier].partial = true
            }
            else if(_item[_supplier].qty * _item[_supplier].uomRate == _item.qty) {
              _item[_supplier].partial = true
            }
          })
        }
      })
      return tmp
    })
  }

  return (
    <div>
      <Card className="p-0" bodyStyle={{padding: 0}}>
        <div className="flex justify-between gap-2 px-4 py-2">
          <div className="flex gap-3 items-center">
            
          </div>
          <div className="flex items-center gap-3 flex-wrap">
            <Popover 
              trigger={['hover']} 
              content={
                <div className="flex flex-col gap-3">
                  <Checkbox.Group onChange={setSortValue} value={sortValue}>
                    <div className="grid grid-cols-1">
                      <Checkbox value="lowest" onChange={e => !e.target.checked && handleClearSort('lowest')}>Lowest price</Checkbox>
                      <Checkbox value="lowestDays" onChange={e => !e.target.checked && handleClearSort('lowestDays')}>Shortest lead time</Checkbox>
                      <Checkbox value="alternative" onChange={e => !e.target.checked && handleClearSort('alternative')}>Exclude alternative</Checkbox>
                      <Checkbox value="license" onChange={e => !e.target.checked && handleClearSort('license')}>Has license</Checkbox>
                      <Checkbox value="partial" onChange={e => !e.target.checked && handleClearSort('partial')}>Non partial</Checkbox>
                      <Checkbox value="buyer" onChange={e => !e.target.checked && handleClearSort('buyer')}>Buyer recommendation</Checkbox>
                      <Checkbox value="end" onChange={e => !e.target.checked && handleClearSort('end')}>End user recommendation</Checkbox>
                    </div>
                  </Checkbox.Group>
                  <a onClick={(e) => {
                    e.preventDefault()
                    handleClearSort()
                  }}>
                    Clear
                  </a>
                </div>
              }
              placement="bottom"
              arrow={false}>
              <Button icon={<FilterOutlined className="align-middle"/>} type="primary">Sort</Button>
            </Popover>
          </div>
        </div>
        <Table
          virtual={data.length > 100}
          scroll={{
            y: window.innerHeight-300,
            x: 500,
          }}
          rowClassName={(record, index) => index % 2 === 0 ? '' :  'table-row-light-blue'}
          className="cell-no-space bid-summary-table"
          pagination={false}
          bordered={true}
          size="small"
          loading={loading}
          dataSource={data}
          columns={generateColumns}
          summary={(pageData) => {
            let tmp = []
            pageData.map((foo, fooIndex) => {
              for(var i = 0; i < generateColumns.length; i++) {
                if(tmp[i]) {
                  if(generateColumns[i+2] && foo[generateColumns[i+2].dataIndex]) {
                    tmp[i].totalPrice += foo[generateColumns[i+2].dataIndex].totalPriceUsd
                    tmp[i].shipQty += foo[generateColumns[i+2].dataIndex] ? 1 : 0
                    tmp[i].shipDays = Math.max(foo[generateColumns[i+2].dataIndex].shipDays, tmp[i].shipDays)
                  }
                }
                else {
                  if(generateColumns[i+2] && foo[generateColumns[i+2].dataIndex]) {
                    tmp.push({
                      totalPrice: foo[generateColumns[i+2].dataIndex].totalPriceUsd, 
                      shipQty: foo[generateColumns[i+2].dataIndex] ? 1 : 0, 
                      shipDays: foo[generateColumns[i+2].dataIndex].shipDays, 
                      header: generateColumns[i+2].dataIndex,
                    })
                  }
                }
              }
            })
            
            return (
              <Table.Summary fixed={true}>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    Summary
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <div className="text-xs">
                      <div>Quote total value</div>
                      <div>Total items</div>
                      <div>Leadtime</div>
                    </div>
                  </Table.Summary.Cell>
                  {
                    tmp.map((foo, fooIndex) => {
                      return (
                        <Table.Summary.Cell index={fooIndex+2} key={`summary-cell-${fooIndex}`}>
                          <div className="grid grid-cols-1 font-semibold leading-[18.84px]" key={`summary-${foo.header}`}>
                            <div className="text-nowrap">{foo.totalPrice.toLocaleString("en-US")}</div>
                            <div>{foo.shipQty}</div>
                            <div>{foo.shipDays}</div>
                          </div>
                        </Table.Summary.Cell>
                      )
                    })
                  }
                </Table.Summary.Row>
              </Table.Summary>
            )
          }}
          >
    
        </Table>
      </Card>
      {contextHolder}
    </div>
  )
}

export default BidSummaryList