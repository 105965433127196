import React, { useContext, useEffect, useState } from 'react'
import { Button, Tooltip, Skeleton, Divider, Table, Card, Steps, Input, Collapse, Popover, Select } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { useNavigate, Outlet } from 'react-router-dom';
import { instance } from 'utils/axios';
import { useParams, Link, useLocation } from "react-router-dom";
import { BsClipboardCheck, BsJournalBookmark, BsPeople, BsViewStacked, BsBoxes, BsChatQuote, BsPencilSquare, BsQuestionSquare  } from "react-icons/bs";
import { disqualifiedColumns, disqualifiedColumnsSummary, expandedDetailColumns, expandedServiceColumns, expandedSuppliersColumns, suppliersColumns, totalColumns } from './rfqFormColumns';
import BidSummaryList from '../BidSummary/BidSummaryTable';
import { twMerge } from 'tailwind-merge';
import { AuthContext } from 'contexts';

const customDot = ({dot, status, index, currentApprovers}) => (
  <Popover
    content={
      <span >
        Approver #{index+1} status: <span className={twMerge('font-semibold', currentApprovers[index].status == 1 ? 'text-green-500' : currentApprovers[index].status == 0 ? 'text-red-500' : 'text-yellow-500')}>{currentApprovers[index].status == 1 ? 'Approved' : currentApprovers[index].status == 0 ? 'Rejected' : status}</span>
      </span>
    }
  >
    {dot}
  </Popover>
);

function ApprovalDetail() {
  const [ mainData, setMainData ] = useState(null)
  const [ loading, setLoading ] = useState(true)
  const [ sending, setSending ] = useState(false)
  const [ reviewer, setReviewer ] = useState([])
  const [ comment, setComment ] = useState("")
  const { id } = useParams()
  const { state } = useContext(AuthContext)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `/TenderApproval/rfq/summary/${id}?wcode=tender-detail`
    }).then(res => {
      setMainData(res.data.responseData)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const handleSend = (approveStatus) => {
    setSending(true)
    instance({
      method: 'post',
      url: approveStatus == 1 ? `/TenderApproval/rfq/approve?wcode=tender-detail` : `/TenderApproval/rfq/reject?wcode=tender-detail`,
      data: {
        tenderId: id,
        comment: comment,
      }
    }).then(() => {
      setComment("")
      fetchData()
    }).catch(err => {

    }).then(() => {
      setSending(false)
    })
  }

  const handleAddReviewer = () => {
    instance({
      method: 'post',
      url: `/TenderApprovalStep/addreviewer`,
      data: {
        tenderId: id,
        email: reviewer[0]
      }
    }).then(res => {
      console.log(res.data.responseData)
    })
  }
  
  if(loading) {
    return (
      <div>
        <Skeleton active/>
      </div>
    )
  }
  return (
    <div className='grid grid-cols-12 gap-3 relative'>
      <div className="grid grid-cols-12 gap-3 col-span-12">
        <div className="col-span-12">
          <Card size="small">
            <div className="flex flex-col gap-4">
              <Steps
                size='small'
                labelPlacement='vertical'
                progressDot={(dot, {status, index}) => customDot({dot, status, index, currentApprovers: mainData.approvalSteps})}
                current={mainData.approvalSteps.findIndex(foo => foo.isCurrent == 1)}
                items={mainData.approvalSteps.map((foo, fooIndex) => fooIndex == 0 ? ({title: 'Review'}) : ({title: `${foo.firstName} ${foo.lastName}`}))}>

              </Steps>
              <Table
                size='small'
                pagination={false}
                columns={[
                  {
                    title: '#',
                    dataIndex: '',
                    width: 50,
                    render: (cell, row, index) => index+1
                  },
                  {
                    title: 'Name',
                    dataIndex: 'firstName',
                    render: (cell, row, index) => <span>{row.firstName} {row.lastName}</span>
                  },
                  {
                    title: 'Role',
                    dataIndex: 'name',
                  },
                  {
                    title: 'Approved justification',
                    dataIndex: 'comment',
                  },
                ]}
                dataSource={mainData.approvalSteps}>

              </Table>
            </div>
          </Card>
        </div>
      </div>
      {
        state.userInfo?.userId == mainData.approvalSteps.find(foo => foo.isCurrent == 1)?.userId &&
        <div className='col-span-12 sticky bottom-0 left-0 right-0'>
          <div className='rounded-lg'>
            <Card title="Approval" className="overflow-hidden" size="small">
              <div className="flex flex-col gap-3">
                <Input.TextArea onChange={e => setComment(e.target.value)} value={comment}>

                </Input.TextArea>
                <div className="flex gap-4">
                  <Button loading={sending} disabled={sending} className='w-full' type="primary" danger onClick={() => handleSend(0)}>Reject</Button>
                  <Button loading={sending} disabled={sending} className='w-full' type="primary" onClick={() => handleSend(1)}>
                    {
                      state.userInfo?.permissions.findIndex(foo => foo.permissionId == 1002) != -1 ?
                      'Review'
                      :
                      'Approve'
                    }
                  </Button>
                </div>
              </div>
            </Card>
          </div>
        </div>
      }
    </div>
  )
}

export default ApprovalDetail
