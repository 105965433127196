import { Button, Input, Modal, Select, Table, Popover, Card, Dropdown, Tooltip, Transfer, InputNumber, Form, DatePicker, Checkbox, Switch, Segmented, TimePicker, Badge, AutoComplete } from "antd"
import { useEffect, useMemo, useRef, useState } from "react"
import { useOutletContext, useParams } from "react-router-dom"
import { instance } from "utils/axios"
import { FilterOutlined, CheckCircleOutlined, QuestionCircleOutlined, StarOutlined, StarFilled, LinkOutlined, WarningOutlined, CheckOutlined } from '@ant-design/icons'
import ApprovalModal from "./ApprovalModal"
import { twMerge } from "tailwind-merge"

const BidSummaryList = ({}) => {
  const [ rawData, setRawData ] = useState({
    suppliers: [],
    comments: [],
    detail: []
  })
  const [ data, setData ] = useState([])
  const [ columns, setColumns ] = useState([])
  const [ loading, setLoading ] = useState([])
  const [ selected, setSelected ] = useState([])
  const [ suggestModalOpen, setSuggestModalOpen ] = useState(false)
  const [ noteModalOpen, setNoteModalOpen ] = useState(false)
  const [ sending, setSending ] = useState(false)
  const [ comment, setComment ] = useState('')
  const [ selectedRow, setSelectedRow ] = useState([])
  const [ selectedHeader, setSelectedHeader ] = useState([])
  const [ messageModalOpen, setMessageModalOpen ] = useState(false)
  const [ awardModalOpen, setAwardModalOpen ] = useState(false)
  const [ approvalModalOpen, setApprovalModalOpen ] = useState(false)
  const [ negotiationModalOpen, setNegotiationModalOpen ] = useState(false)
  const [ users, setUsers ] = useState([])
  const [ targetKeys, setTargetKeys ] = useState([]);
  const [ messageData, setMessageData ] = useState({
    thread: {},
    messages: []
  })
  const [ outletData ] = useOutletContext()
  const [ modal, contextHolder ] = Modal.useModal();
  const [ currency, setCurrency ] = useState(1)
  const currencyRef = useRef()
  const [ currencyEdit, setCurrencyEdit ] = useState(false)
  const [ checked, setChecked ] = useState([])
  const { id } = useParams()
  const [ negotiationForm ] = Form.useForm()
  const [ sortValue, setSortValue ] = useState(['end', 'buyer'])
  const [ lowestPriceSort, setLowestPriceSort ] = useState(false)
  const [ buyerSort, setBuyerSort ] = useState(false)
  const [ endSort, setEndSort ] = useState(false)
  const [ licenseSort, setLicenseSort ] = useState(false)
  const [ pricePreview, setPricePreview ] = useState('MNT')

  useEffect(() => {
    if(currencyEdit && currencyRef.current) {
      currencyRef.current.focus()
    }
  }, [currencyEdit])

  useEffect(() => {
    if(sortValue.includes("lowest")) {
      setLowestPriceSort(true)
    }
    if(sortValue.includes("lowestDays")) {
      findLowestDays()
    }
    if(sortValue.includes("alternative")) {
      findNonAlternative()
    }
    if(sortValue.includes("end")) {
      setEndSort(true)
    }
    if(sortValue.includes("buyer")) {
      setBuyerSort(true)
    }
    if(sortValue.includes("license")) {
      setLicenseSort(true)
    }
    if(sortValue.includes("partial")) {
      findNonPartial()
    }
  }, [sortValue])

  useEffect(() => {
    fetchData()
    fetchMessageData()
    // fetchUsers()
    fetchCurrency()
  }, [])

  const fetchCurrency = () => {
    if(outletData.usdRate != 1) {
      setCurrency(outletData.usdRate)
    }
    else {
      instance({
        method: 'get',
        url: `/Utils/usdrate`
      }).then(res => {
        setCurrency(res.data.responseData ?? 0)
      })
    }
  }

  const fetchUsers = () => {
    instance({
      method: 'get',
      url: `/User`
    }).then(res => {
      setUsers(res.data.responseData)
    }).catch(err => {

    })
  }

  const fetchData = () => {
    instance({
      method: 'get',
      url: `/Tender/rfq/bidsummary/${id}?wcode=tender-detail`
    }).then(res => {
      if(res.status != 204) {
        setRawData(res.data.responseData)
        // generateColumns(res.data.responseData)
        fixSuppliersData(res.data.responseData)
      }
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const fetchMessageData = () => {
    instance({
      method: 'get',
      url: `/Message/tender/main/${id}?wcode=tender-detail`
    }).then(res => {
      setMessageData(res.data.responseData)
    }).catch(err => {

    })
  }
  
  const generateColumns = useMemo(() => {
      let tmp = []
  
      tmp.push({
        title: 'Items',
        dataIndex: 'itemInfo',
        key: 'itemInfo',
        width: 150,
        fixed: 'left',
        onCell: undefined,
        render: (text, row, index) => {
          return (
            <div className="flex flex-col p-1 cursor-pointer hover:underline px-3" onClick={() => handleRowClick(row)}>
              <Popover title={
                <div className="max-w-[300px] text-sm">
                  <div className="flex gap-2">
                    <div>Description:</div>
                    <div className="text-wrap truncate">{row.description}</div>
                  </div>
                  <div className="flex gap-2">
                    <div>Material number:</div>
                    <div>{row.materialNumber}</div>
                  </div>
                  <div className="flex gap-2">
                    <div>Manufacturer:</div>
                    <div>{row.manufacturer}</div>
                  </div>
                  <div className="flex gap-2">
                    <div>Part number:</div>
                    <div>{row.partNumber}</div>
                  </div>
                  <div className="flex gap-2">
                    <div>Quantity:</div>
                    <div>{row.qty}</div>
                  </div>
                  <div className="flex gap-2">
                    <div>UOM:</div>
                    <div>{row.uom}</div>
                  </div>
                </div>
              }>
                <div className="text-nowrap truncate">{row.description.slice(0, 20)}... /{row.materialNumber}/ {row.qty} {row.uom}</div>
              </Popover>
              <div className="flex gap-2 text-xs">
                <div>Average price:</div><div>{row?.avgPrice?.toLocaleString("en-US")}</div>
              </div>
              <div className="flex gap-2 text-xs">
              <div>Last price:</div><div>{row?.lastPrice?.toLocaleString("en-US")}</div>
              </div>
              <div className="flex gap-2 text-xs">
              <div>Criticality:</div><div>{row?.criticality}</div>
              </div>
              
            </div>
          )
        }
      })
      tmp.push({
        title: '',
        dataIndex: 'spec',
        key: 'spec',
        fixed: 'left',
        width: 130,
        render: (_, row, index) => {
          return (
            <div className="flex flex-col text-xs">
              <div className="border-b px-3 leading-5">Quantity</div>
              <div className="border-b px-3 leading-5">Days</div>
              <div className="border-b px-3 leading-5">Price</div>
              <div className="border-b px-3 leading-5">Alternative</div>
              <div className="px-3 leading-5">License</div>
            </div>
          )
        }
      })
      if(data) {
        let copy = Object.assign({
          suppliers: [],
          comments: [],
          detail: []
        }, rawData)
        copy.suppliers.map((supplier, supplierIndex) => {
          supplier.itemsCount = supplier.details.length
          supplier.itemsTotalPrice = supplier.details.reduce((a,b) => a+(b.unitPrice*b.shipQty), 0)
          supplier.totalLowest = supplier.details.filter(foo => foo.lowest).length
          supplier.itemsLead = Math.max(...supplier.details.map(foo => foo.shipDays))
        })

        copy.suppliers.sort((a,b) => {
          if(a.totalLowest == b.totalLowest) {
            if(a.itemsCount == b.itemsCount) {
              if(a.itemsLead == b.itemsLead) {
                  return a.name > b.name ? 1 : -1
              } 
              return a.itemsLead > b.itemsLead ? 1 : -1 
            }
            return a.itemsCount < b.itemsCount ? 1 : -1
          }
          return a.totalLowest < b.totalLowest ? 1 : -1
        }).map((supplier, supplierIndex) => {
          tmp.push({
            title: <Tooltip placement="bottomLeft" className="truncate text-nowrap overflow-hidden" title={supplier.name}>
              <div onClick={() => handleColumnClick(supplier.details)} className="cursor-pointer hover:underline">{supplier.name}</div>
            </Tooltip>,
            dataIndex: `supplier-${supplier.id}`,
            key: `supplier-${supplier.id}`,
            width: 120,
            render: (text, row) => {
              if(text?.shipDays && text?.shipQty && text?.unitPrice) {
                return (
                  <div 
                    onClick={() => handleCellClick(text)} 
                    className="relative h-full cursor-pointer hover:border-primary border border-transparent transition-all" 
                    style={checked.find(foo => foo == text.id) ? {backgroundColor: '#24c8d130'} : {}}>
                    <div className={twMerge("grid grid-cols-1 gap-x-2 border-b px-3 leading-5 font-medium",  text?.partial && 'bg-green-600 text-white')}>
                      <div>{text?.shipQty} <span className="">/{text?.shipUOM}/</span> {text.negotiateStatus == 0 && text.oldShipQty && <Tooltip title="Supplier confirmed"><CheckOutlined className="text-primary"/></Tooltip>}</div>
                    </div>
                    <div className={twMerge("grid grid-cols-1 gap-x-2 border-b px-3 leading-5 font-medium",  text?.lowestDays && 'bg-green-600 text-white')}>
                      <div>{text?.shipDays} {text.negotiateStatus == 0 && text.oldShipDays && <Tooltip title="Supplier confirmed"><CheckOutlined className="text-primary"/></Tooltip>}</div>
                    </div>
                    <div className={twMerge("grid grid-cols-1 gap-x-2 border-b px-3 leading-5 font-medium",  (text?.lowest && lowestPriceSort == true) && 'bg-green-600 text-white')}>
                      <div>{pricePreview == 'MNT' ? text?.unitPriceMnt.toLocaleString("en-US") : text?.unitPriceUsd.toLocaleString("en-US")} {text.negotiateStatus == 0 && text.oldUnitPrice && <Tooltip title="Supplier confirmed"><CheckOutlined className="text-primary"/></Tooltip>}</div>
                    </div>
                    <div className={twMerge("grid grid-cols-1 gap-x-2 border-b px-3 leading-5 font-medium",  text?.alternative && 'bg-green-600 text-white')}>
                      <div>{text?.isAlternative == 0 ? 'No': 'Yes'}</div>
                    </div>
                    <div className={twMerge("grid grid-cols-1 gap-x-2 px-3 leading-5 font-medium", (text?.hasLicense == 1 && licenseSort == true) && 'bg-green-600 text-white')}>
                      <div>{text?.hasLicense == 0 ? 'No': 'Yes'}</div>
                    </div>
                    
                    {
                      selected.find(foo => foo == text?.id) &&
                      <div className="absolute inset-0 flex flex-col justify-center items-center backdrop-blur-[1px] bg-black/10">
                        <CheckCircleOutlined className='text-2xl text-black' />
                        {
                          text.comment &&
                          <Tooltip title={text.comment} className="text-primary font-bold">Noted</Tooltip>
                        }
                      </div>
                    }
                    {
                      text.negotiateStatus == 1 ?
                      <div className="absolute inset-0 z-[1]">
                        <Badge.Ribbon text={'Negotiation'}>

                        </Badge.Ribbon>
                      </div>
                      :
                      <></>
                    }
                    <div className="absolute h-full right-0 top-0 flex flex-col gap-1 py-2 px-1 z-[1] bg-red-[400]">
                      {
                        (text.oldUnitPrice || text.oldShipDays || text.comment) &&
                        <Tooltip title={
                          <div className="grid grid-cols-2 gap-3">
                            {
                              text.oldUnitPrice ?
                              <>
                              <div>Old unit price:</div>
                              <div>{text.oldUnitPrice.toLocaleString("en-US")}</div>
                              </>
                              :
                              <></>
                            }
                            {
                              text.oldShipQty ?
                              <>
                              <div>Old qty:</div>
                              <div>{text.oldShipQty}</div>
                              </>
                              :
                              <></>
                            }
                            {
                              text.oldShipDays ?
                              <>
                              <div>Old ship days:</div>
                              <div>{text.oldShipDays}</div>
                              </>
                              :
                              <></>
                            }
                            {
                              text.comment &&
                              <>
                              <div>Comment:</div>
                              <div>{text.comment}</div>
                              </>
                            }
                          </div>
                        }>
                          <QuestionCircleOutlined />
                        </Tooltip>
                      }
                      {
                        text.file &&
                        <Tooltip title="Attachment">
                          <a target="_blank" href={process.env.REACT_APP_CDN_URL+text.file}>
                            <LinkOutlined />
                          </a>
                        </Tooltip>
                      }
                      {
                        text.isUserSuggested == 1 && endSort &&
                        <Tooltip title={
                          <div className="">
                            <div>
                              {rawData.comments.filter(foo => foo.isEndUserAnswered == 1 && foo.status == 1).slice(-1)[0]?.comment}
                            </div>
                          </div>
                        }>
                          <div className="bg-black/60 rounded-full w-4 h-4 font-bold text-white text-xs flex justify-center items-center leading-none">
                            E
                          </div>
                        </Tooltip>
                      }
                      {
                        text.isSelected == 1 && buyerSort &&
                        <Tooltip title={
                          <div className="">
                            <div>
                              {rawData.comments.filter(foo => foo.status == 1 && foo.isEndUserAnswered == 0).slice(-1)[0]?.comment}
                            </div>
                          </div>
                        }>
                          <div className="bg-black/60 rounded-full w-4 h-4 font-bold text-white text-xs flex justify-center items-center leading-none">
                            B
                          </div>
                        </Tooltip>
                      }
                    </div>
                  </div>
                )
              }
              return null
            }
          })
        })
      }

      return tmp
  }, [data, selected, buyerSort, endSort, licenseSort, pricePreview, lowestPriceSort])

  const fixSuppliersData = (_data) => {
    let tmp = []

    _data.detail.map((foo,fooIndex) => {
      tmp.push(foo)
      foo.supplierData.map((_supplier, _supplierIndex) => {
        tmp[fooIndex][`supplier-${_supplier.supplierId}`] = _supplier
      })
    })

    // tmp.map((_item, _itemIndex) => {
    //   let suppliers = Object.keys(_item).filter(foo => foo.includes('supplier-'))
    //   if(suppliers.length > 0) {
    //     suppliers.map(foo => {
    //       _item[foo].lowest = false
    //     })

    //     let lowest = suppliers[0]
    //     let otherlowers = []
    //     suppliers.map((_supplier, _supplierIndex) => {
    //       if(_item[_supplier].unitPrice < _item[lowest].unitPrice) {
    //         lowest = _supplier
    //         otherlowers = []
    //       }
    //       else if(_item[_supplier].unitPrice == _item[lowest].unitPrice) {
    //         otherlowers.push(_supplier)
    //       }
    //     })
    //     _item[lowest].lowest = true
    //     otherlowers.map(foo => {
    //       _item[foo].lowest = true
    //     })
    //   }
    // })
    
    setData(tmp)
  }

  const handleHeaderClick = (_supplier) => {
    if(selectedHeader.find(foo => foo == _supplier.supplierId)) {
      let tmp = []
      data.map((foo) => {
        if(foo[`supplier-${_supplier.supplierId}`]) {
          tmp.push(foo[`supplier-${_supplier.supplierId}`].id)
        }
      })
      setSelected(prev => prev.filter(item => !tmp.includes(item)))
      setSelectedHeader(prev => prev.filter(foo => foo != _supplier.supplierId))
    }
    else {
      let tmp = []
      data.map((foo) => {
        if(foo[`supplier-${_supplier.supplierId}`]) {
          tmp.push(foo[`supplier-${_supplier.supplierId}`].id)
        }
      })
      setSelected(prev => ([...new Set([...prev, ...tmp])]))
      setSelectedHeader(prev => [...prev, _supplier.supplierId])
    }
  }

  const handleRowClick = (_item) => {
    if(selectedRow.find(foo => foo == _item.id)) {
      let keys = Object.keys(_item).filter(foo => foo.includes("supplier-"))
      let _ids = keys.map(foo => _item[foo].id)
      setSelected(prev => prev.filter(item => !_ids.includes(item)))
      setSelectedRow(selectedRow.filter(foo => foo != _item.id))
    }
    else {
      let keys = Object.keys(_item).filter(foo => foo.includes("supplier-"))
      let _ids = keys.map(foo => _item[foo].id)
      setSelected(prev => ([...new Set([...prev, ..._ids])]))
      setSelectedRow(prev => [...prev, _item.id])
    }
  }

  const handleColumnClick = (_item) => {
    if(selected.find(foo => _item.find(_tmp => _tmp.id == foo))) {
      const ids = _item.map(foo => foo.id)
      setSelected(prev => prev.filter(item => !ids.includes(item)))
    }
    else {
      let ids = _item.map(foo => foo.id)
      setSelected(prev => ([...new Set([...prev, ...ids])]))
    }
  }
  
  const handleClearSort = (_key) => {
    if(_key) {
      if(_key == "lowest") {
        setLowestPriceSort(false)
      }
      else if(_key == 'buyer') {
        setBuyerSort(false)
      }
      else if (_key == 'end') {
        setEndSort(false)
      }
      else if (_key == 'license') {
        setLicenseSort(false)
      }
      else {
        setData(prev => {
          let tmp = Object.assign([], prev)
          tmp.map((foo) => {
            let suppliers = Object.keys(foo).filter(foo => foo.includes('supplier-'))
            suppliers.map(_supplier => {
              if(foo[_supplier][_key] ) {
                delete foo[_supplier][_key]
              }
            })
          })
          
          return tmp
        })
      }
    }
    else {
      setData(prev => {
        let tmp = Object.assign([], prev)
        tmp.map((foo) => {
          let suppliers = Object.keys(foo).filter(foo => foo.includes('supplier-'))
          suppliers.map(_supplier => {
            if(foo[_supplier].lowestDays || foo[_supplier].alternative) {
              delete foo[_supplier].lowestDays
              delete foo[_supplier].alternative
            }
          })
        })
        
        return tmp
      })
      setLowestPriceSort(false)
      setBuyerSort(false)
      setEndSort(false)
      setLicenseSort(false)
      setSortValue([])
    }
  }
  
  const findLowestDays = () => {   
    setData(prev => {
      let tmp = Object.assign([], prev)

      tmp.map((_item, _itemIndex) => {
        let suppliers = Object.keys(_item).filter(foo => foo.includes('supplier-'))
        if(suppliers.length > 0) {
          suppliers.map(foo => {
            _item[foo].lowestDays = false
          })
          
          let lowest = suppliers[0]
          let otherlowers = []
          suppliers.map((_supplier, _supplierIndex) => {
            if(_item[_supplier].shipDays < _item[lowest].shipDays) {
              lowest = _supplier
              otherlowers = []
            }
            else if(_item[_supplier].shipDays == _item[lowest].shipDays) {
              otherlowers.push(_supplier)
            }
          })
          _item[lowest].lowestDays = true
          otherlowers.map(foo => {
            _item[foo].lowestDays = true
          })
        }
      })

      return tmp
    })
  }

  const findNonAlternative = () => {
    setData(prev => {
      let tmp = Object.assign([], prev)

      tmp.map((_item, _itemIndex) => {
        let suppliers = Object.keys(_item).filter(foo => foo.includes('supplier-'))
        if(suppliers.length > 0) {
          suppliers.map(foo => {
            _item[foo].alternative = _item[foo].isAlternative == 1 ? false : true
          })
        }
      })

      return tmp
    })
  }

  const findLowestPrice = () => {   

    setData(prev => {
      let tmp = Object.assign([], prev)

      tmp.map((_item, _itemIndex) => {
        let suppliers = Object.keys(_item).filter(foo => foo.includes('supplier-'))
        if(suppliers.length > 0) {
          suppliers.map(foo => {
            _item[foo].lowest = false
          })

          let lowest = suppliers[0]
          let otherlowers = []
          suppliers.map((_supplier, _supplierIndex) => {
            if(_item[_supplier].unitPrice < _item[lowest].unitPrice) {
              lowest = _supplier
              otherlowers = []
            }
            else if(_item[_supplier].unitPrice == _item[lowest].unitPrice) {
              otherlowers.push(_supplier)
            }
          })
          _item[lowest].lowest = true
          otherlowers.map(foo => {
            _item[foo].lowest = true
          })
        }
      })

      return tmp
    })
  }

  const findNonPartial = () => {
    setData(prev => {
      let tmp = Object.assign([], prev)

      tmp.map((_item, _itemIndex) => {
        let suppliers = Object.keys(_item).filter(foo => foo.includes('supplier-'))
        if(suppliers.length > 0) {
          suppliers.map(foo => {
            _item[foo].partial = false
          })

          suppliers.map((_supplier, _supplierIndex) => {
            if(_item[_supplier].shipUOM == _item.uom && _item[_supplier].shipQty == _item.qty) {
              _item[_supplier].partial = true
            }
            else if(_item[_supplier].qty * _item[_supplier].uomRate == _item.qty) {
              _item[_supplier].partial = true
            }
          })
        }
      })
      return tmp
    })
  }

  const handleCellClick = (e) => {
    if(selected.find(foo => foo == e.id)) {
      setSelected(prev => prev.filter(foo => foo != e.id))
      setSelectedRow(prev => prev.filter(foo => foo != e.tenderDetailId))
      setSelectedHeader(prev => prev.filter(foo => foo != e.supplierId))
    }
    else {
      setSelected(prev => [...prev, e.id])
    }
  }

  const handleSend = () => {
    setSending(true)
    instance({
      method: 'post',
      url: `/Tender/rfq/generatebidsummary`,
      data: {
        id: id,
        comment: comment,
        supplierDetails: selected
      }
    }).then(() => {
      setComment("")
      setSelected([])
      setSuggestModalOpen(false)
      fetchData()
    }).catch(err => {

    }).then(() => {
      setSending(false)
    })
  }

  const handleTakeNote = () => {
    setSending(true)
    instance({
      method: 'put',
      url: `/Tender/rfq/note`,
      data: {
        tenderId: id,
        tenderRFQItemNotes: selected.map(foo => ({supplierDetailId: foo, note: comment}))
      }
    }).then(() => {
      setComment("")
      setSelected([])
      setNoteModalOpen(false)
      fetchData()
    }).catch(err => {

    }).then(() => {
      setSending(false)
    })
  }

  const handleSendMessage = () => {
    setSending(true)
    instance({
      method: 'post',
      url: `/Tender/rfq/generatebidsummary`,
      data: {
        messageThreadId: messageData.thread.id,
        tenderId: id,
        body: comment,
        details: selected
      }
    }).then(() => {
      setComment("")
      setSelected([])
      setMessageModalOpen(false)
      setSuggestModalOpen(false)
      fetchData()
    }).catch(err => {

    }).then(() => {
      setSending(false)
    })
  }

  const handleApprove = () => {
    modal.confirm({
      title: 'Warning!',
      content: `You are about to send ${selected.length} numbers of items for approval`,
      okText: 'Send',
      cancelText: 'Cancel',
      onOk() {
        return new Promise((resolve, reject) => {
          setSending(true)
          instance({
            method: 'post',
            url: `/Tender/rfq/sendtoapproval`,
            data: {
              id: id,
              comment: comment,
              supplierDetails: selected,
              users: targetKeys.map((foo, fooIndex) => ({userId: foo, orderId: fooIndex}))
            }
          }).then(() => {
            setComment("")
            setSelected([])
            setApprovalModalOpen(false)
            setTargetKeys([])
            fetchData()
            resolve()
          }).catch(err => {
            reject()
          }).then(() => {
            setSending(false)
          })
        })
      },
      onCancel() {}
    }) 
  }
  
  const handleAward = () => {
    modal.confirm({
      title: 'Warning!',
      content: `You are about to award ${selected.length} numbers of items`,
      okText: 'Award',
      cancelText: 'Cancel',
      onOk() {
        return new Promise((resolve, reject) => {
          setSending(true)
          instance({
            method: 'post',
            url: `/Tender/rfq/award`,
            data: {
              id: id,
              comment: comment,
              supplierDetails: selected
            }
          }).then(() => {
            setComment("")
            setSelected([])
            setAwardModalOpen(false)
            fetchData()
            resolve()
          }).catch(err => {
            reject()
          }).then(() => {
            setSending(false)
          })
        })
      },
      onCancel() {}
    }) 
  }

  const onChange = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys);
  };

  const handleCurrencyKeyDown = (e) => {
    var keyCode = e.code || e.key
    if(keyCode == "Enter") {
      handleUpdateExchange()
    }
  }

  const handleUpdateExchange = () => {
    instance({
      method: 'put',
      url: `/Tender/updaterate?wcode=tender-detail`,
      data: {
        id: id,
        usdRate: currency
      }
    })
    setCurrencyEdit(false)
  }

  const handleNegotiation = (values) => {
    setSending(true)
    instance({
      method: 'put',
      url: `/Tender/opennegotiate`,
      data: {
        id: id,
        endDate: values.endDate,
        supplierItemIds: selected,
        comment: values.comment
      }
    }).then(() => {
      setComment("")
      setSelected([])
      setChecked([])
      setNegotiationModalOpen(false)
      fetchData()
    }).catch(err => {

    }).then(() => {
      setSending(false)
    })
  }

  const handleCheck = () => {
    if(selected.every(ai => checked.includes(ai))) {
      setChecked(prev => {
        let tmp = Object.assign([], prev)
        return tmp.filter(foo => !selected.includes(foo))
      })
    }
    else {
      setChecked(prev => {
        return [...new Set([...prev, ...selected])]
      })
    }
    setSelected([])
  }

  // console.log(checked)s

  const getSelectedDatas = () => {
    let tmp = []

    if(rawData.suppliers) {
      rawData.suppliers?.map((_suppliers, _supplierIndex) => {
        _suppliers.details.map((_detail, _detailIndex) => {
          if(selected.find(foo => foo == _detail.id)) {
            let found = tmp.findIndex(foo => foo.id == _suppliers.id)
            if(found != -1) {
              let detailCopy = rawData.detail.find(foo => foo.id == _detail.tenderDetailId)
              detailCopy = {
                ...detailCopy,
                ..._detail
              }
              tmp[found].details.push(detailCopy)
            }
            else {
              let detailCopy = rawData.detail.find(foo => foo.id == _detail.tenderDetailId)
              tmp.push({
                ..._suppliers,
                details: [{..._detail, ...detailCopy}]
              })
            }
          }
        })
      })
    }
    
    return tmp
  }


  return (
    <div>
      <Card className="p-0" bodyStyle={{padding: 0}}>
        <div className="flex justify-between gap-2 px-4 py-2">
          <div className="flex gap-3 items-center">
            <Segmented
              value={pricePreview}
              onChange={setPricePreview}
              options={['MNT', 'USD']}>

            </Segmented>
            <div className="cursor-pointer select-none hover:underline" onDoubleClick={() => setCurrencyEdit(true)}>
              {
                currencyEdit ? 
                <div className="flex items-center">
                  <span>USD:</span>
                  <InputNumber ref={currencyRef} className="w-[100px]" value={currency} onChange={e => {
                    setCurrency(e)
                  }} onKeyDown={handleCurrencyKeyDown} onBlur={() => {
                    handleUpdateExchange()
                  }}></InputNumber>
                </div>
                :
                `USD: ${currency}₮`
              }
            </div>
          </div>
          <div className="flex items-center gap-3 flex-wrap">
            {
              <>
                <Button type="primary" onClick={() => setApprovalModalOpen(true)} disabled={selected.length == 0 || !outletData || outletData?.status == 11}>Send for approval</Button>
                {
                  (outletData.tendertype == 'rfq' && outletData.rfqType == 1) &&
                  <Button type="primary" onClick={() => setNoteModalOpen(true)} disabled={selected.length == 0 || !outletData || outletData?.status == 11}>Take note for approval</Button>
                }
                {
                  outletData.sseType != 2 &&
                  <>
                  <Button type="primary" onClick={() => setSuggestModalOpen(true)} disabled={selected.length == 0 || !outletData || outletData?.status == 11}>Clarification to EU</Button>
                  <Button type="primary" onClick={() => setNegotiationModalOpen(true)} disabled={selected.length == 0 || !outletData || outletData?.status == 11}>Negotiate with supplier</Button>
                  </>
                }
              </>
            }
            <Popover 
              trigger={['hover']} 
              content={
                <div className="flex flex-col gap-3">
                  <Checkbox.Group onChange={setSortValue} value={sortValue}>
                    <div className="grid grid-cols-1">
                      <Checkbox value="lowest" onChange={e => !e.target.checked && handleClearSort('lowest')}>Lowest price</Checkbox>
                      <Checkbox value="lowestDays" onChange={e => !e.target.checked && handleClearSort('lowestDays')}>Shortest lead time</Checkbox>
                      <Checkbox value="alternative" onChange={e => !e.target.checked && handleClearSort('alternative')}>Exclude alternative</Checkbox>
                      <Checkbox value="license" onChange={e => !e.target.checked && handleClearSort('license')}>Has license</Checkbox>
                      <Checkbox value="partial" onChange={e => !e.target.checked && handleClearSort('partial')}>Non partial</Checkbox>
                      <Checkbox value="buyer" onChange={e => !e.target.checked && handleClearSort('buyer')}>Buyer recommendation</Checkbox>
                      <Checkbox value="end" onChange={e => !e.target.checked && handleClearSort('end')}>End user recommendation</Checkbox>
                    </div>
                  </Checkbox.Group>
                  <a onClick={(e) => {
                    e.preventDefault()
                    handleClearSort()
                  }}>
                    Clear
                  </a>
                </div>
              }
              placement="bottom"
              arrow={false}>
              <Button icon={<FilterOutlined className="align-middle"/>} type="primary">Sort</Button>
            </Popover>
          </div>
        </div>
        <Table
          virtual={data.length > 100}
          scroll={{
            y: window.innerHeight-300,
            x: 500,
          }}
          rowClassName={(record, index) => index % 2 === 0 ? '' :  'table-row-light-blue'}
          className="cell-no-space bid-summary-table"
          pagination={false}
          bordered={true}
          size="small"
          loading={loading}
          dataSource={data}
          columns={generateColumns}
          summary={(pageData) => {
            let tmp = []
            pageData.map((foo, fooIndex) => {
              for(var i = 0; i < generateColumns.length; i++) {
                if(tmp[i]) {
                  if(generateColumns[i+2] && foo[generateColumns[i+2].dataIndex]) {
                    tmp[i].totalPrice += pricePreview == 'MNT' ? foo[generateColumns[i+2].dataIndex].totalPriceMnt : foo[generateColumns[i+2].dataIndex].totalPriceUsd
                    tmp[i].shipQty += foo[generateColumns[i+2].dataIndex] ? 1 : 0
                    tmp[i].shipDays = Math.max(foo[generateColumns[i+2].dataIndex].shipDays, tmp[i].shipDays)
                    if(selected.includes(foo[generateColumns[i+2]?.dataIndex].id)) {
                      tmp[i].selectedTotalPrice += pricePreview == 'MNT' ? foo[generateColumns[i+2].dataIndex].totalPriceMnt : foo[generateColumns[i+2].dataIndex].totalPriceUsd
                      tmp[i].selectedCount += 1
                      tmp[i].selectedLeadtime = Math.max(foo[generateColumns[i+2]?.dataIndex].shipDays, tmp[i].selectedLeadtime)
                    }
                  }
                }
                else {
                  if(generateColumns[i+2] && foo[generateColumns[i+2].dataIndex]) {
                    tmp.push({
                      totalPrice: pricePreview == 'MNT' ? foo[generateColumns[i+2].dataIndex].totalPriceMnt : foo[generateColumns[i+2].dataIndex].totalPriceUsd, 
                      shipQty: foo[generateColumns[i+2].dataIndex] ? 1 : 0, 
                      shipDays: foo[generateColumns[i+2].dataIndex].shipDays, 
                      header: generateColumns[i+2].dataIndex,
                      selectedTotalPrice: 0,
                      selectedCount: 0,
                      selectedLeadtime: 0
                    })
                    if(selected.includes(foo[generateColumns[i+2]?.dataIndex].id) && tmp[i]) {
                      tmp[i].selectedTotalPrice = pricePreview == 'MNT' ? foo[generateColumns[i+2].dataIndex].totalPriceMnt : foo[generateColumns[i+2].dataIndex].totalPriceUsd
                      tmp[i].selectedCount = 1
                      tmp[i].selectedLeadtime = foo[generateColumns[i+2]?.dataIndex].shipDays
                    }
                  }
                }
              }
            })
            
            return (
              <Table.Summary fixed={true}>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    Summary
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <div className="text-xs">
                      <div>Quote total value</div>
                      <div>Total items</div>
                      <div>Leadtime</div>
                      <Tooltip title="Recommended PO total value">
                        <div className="truncate">Recommended PO total value</div>
                      </Tooltip>
                      <div>Selected items</div>
                      <div>Selected leadtime</div>
                    </div>
                  </Table.Summary.Cell>
                  {
                    tmp.map((foo, fooIndex) => {
                      return (
                        <Table.Summary.Cell index={fooIndex+2} key={`summary-cell-${fooIndex}`}>
                          <div className="grid grid-cols-1 font-semibold leading-[18.84px]" key={`summary-${foo.header}`}>
                            <div className="text-nowrap">{foo.totalPrice.toLocaleString("en-US")}</div>
                            <div>{foo.shipQty}</div>
                            <div>{foo.shipDays}</div>
                            <div className="text-primary">{foo.selectedTotalPrice.toLocaleString("en-US") ?? 0}</div>
                            <div className="text-primary">{foo.selectedCount ?? 0}</div>
                            <div className="text-primary">{foo.selectedLeadtime ?? 0}</div>
                          </div>
                        </Table.Summary.Cell>
                      )
                    })
                  }
                </Table.Summary.Row>
              </Table.Summary>
            )
          }}
          >
    
        </Table>
      </Card>
      <Modal
        title={`Send message`}
        open={suggestModalOpen}
        onCancel={() => {
          setComment('')
          setSuggestModalOpen(false)
        }}
        okText="Send"
        confirmLoading={sending}
        onOk={handleSend}
        footer={<div className="flex gap-3 justify-end">
          <Button onClick={() => {
            setComment("")
            setSuggestModalOpen(false)
          }}>Cancel</Button>
          <Button type="primary" onClick={handleSend}>Send to end-user</Button>
        </div>}>
        <div>
          <label className="text-xs text-mute">Total selected suppliers:</label>
          <div className="mb-2 font-semibold">{selected.length}</div>
          <label className="text-xs text-mute">Comment:</label>
          <Input.TextArea onChange={e => setComment(e.target.value)} value={comment}>

          </Input.TextArea>
        </div>
      </Modal>
      <Modal
        title={`Take note`}
        open={noteModalOpen}
        onCancel={() => {
          setComment('')
          setNoteModalOpen(false)
        }}
        okText="Send"
        confirmLoading={sending}
        onOk={handleTakeNote}
        footer={<div className="flex gap-3 justify-end">
          <Button onClick={() => {
            setComment("")
            setNoteModalOpen(false)
          }}>Cancel</Button>
          <Button type="primary" onClick={handleTakeNote}>Save note for approval</Button>
        </div>}>
        <div>
          <label className="text-xs text-mute">Total selected details:</label>
          <div className="mb-2 font-semibold">{selected.length}</div>
          <label className="text-xs text-mute">Comment:</label>
          <AutoComplete className="w-full" onChange={e => {
            setComment(e)
          }} value={comment} options={[
            {
              label: 'Recommended because price is the lowest',
              value: 'Recommended because price is the lowest'
            },
            {
              label: '1 item is not the lowest but LT is the shortest, end user preffered shortest LT selected',
              value: '1 item is not the lowest but LT is the shortest, end user preffered shortest LT selected'
            },
            {
              label: '1 item is not the lowest and alternative choice but EU checked the datasheet and chosen alternative brand',
              value: '1 item is not the lowest and alternative choice but EU checked the datasheet and chosen alternative brand'
            },
            {
              label: '1 item is not the lowest but supplier required combined bundle shipment for other 4 items, thus selected',
              value: '1 item is not the lowest but supplier required combined bundle shipment for other 4 items, thus selected'
            },
          ]}>

          </AutoComplete>
        </div>
      </Modal>
      <Modal
        title={`Send to message to suppliers`}
        open={messageModalOpen}
        onCancel={() => {
          setComment('')
          setMessageModalOpen(false)
        }}
        okText="Send"
        confirmLoading={sending}
        onOk={handleSendMessage}>
        <div>
          <label className="text-xs text-mute">Total selected suppliers:</label>
          <div className="mb-2 font-semibold">{selected.length}</div>
          <label className="text-xs text-mute">Message:</label>
          <Input.TextArea onChange={e => setComment(e.target.value)} value={comment}>

          </Input.TextArea>
        </div>
      </Modal>
      <Modal
        title={`Award`}
        open={awardModalOpen}
        onCancel={() => {
          setComment('')
          setAwardModalOpen(false)
        }}
        okText="Award"
        confirmLoading={sending}
        onOk={handleApprove}>
        <div>
          <label className="text-xs text-mute">Selected details:</label>
          <div className="mb-2 font-semibold">{selected.length}</div>
          <label className="text-xs text-mute">Comment:</label>
          <Input.TextArea onChange={e => setComment(e.target.value)} value={comment}>

          </Input.TextArea>
        </div>
      </Modal>
      <ApprovalModal 
        open={approvalModalOpen} 
        close={setApprovalModalOpen} 
        selected={selected}
        rawData={rawData}
        currency={currency}
        />
      <Modal
        width={'80vw'}
        title={`Request update price`}
        open={negotiationModalOpen}
        onCancel={() => {
          setComment('')
          setNegotiationModalOpen(false)
        }}
        okText="Send"
        confirmLoading={sending}
        onOk={negotiationForm.submit}>
        <div>
          <Form
            form={negotiationForm}
            layout="vertical"
            onFinish={handleNegotiation}>
            <div className="flex gap-3">
              <Form.Item name="endDate" label="Request expire date">
                <DatePicker format="YYYY-MM-DD" className="w-full"></DatePicker>
              </Form.Item>
              <Form.Item name="endDate" className="self-end">
                <TimePicker picker="time" showTime={{format: 'HH:mm'}} format="HH:mm" className="w-full"></TimePicker>
              </Form.Item>
            </div>
            <Form.Item name="comment" label="Comment">
              <Input.TextArea placeholder="Comment"></Input.TextArea>
            </Form.Item>
          </Form>
          <label className="text-xs text-mute">Total selected details:</label>
          <Table
            pagination={false}
            size="small"
            rowKey={'id'}
            columns={[
              {
                title: 'Name',
                dataIndex: 'name',
              },
              {
                title: 'Vendor #',
                dataIndex: 'vendorNumber',
              },
              {
                title: 'Items',
                dataIndex: 'details',
                render: (cell) => cell.length
              },
            ]}
            dataSource={getSelectedDatas()}
            expandable={{
              expandedRowRender: rec => {
                return (
                  <Table
                    pagination={false}
                    size="small"
                    columns={[
                      {
                        title: 'Name',
                        dataIndex: 'name',
                        render: (cell, row) => (
                          <div className="flex flex-col p-1">
                            <div>{row.name} /{row.materialNumber}/</div>
                            <div>{row.manufacturer} /{row.partNumber}/</div>
                          </div>
                        ),
                      },
                      {
                        title: 'Unit price',
                        dataIndex: 'unitPrice',
                        render: cell => cell.toLocaleString("en-US")
                      },
                      {
                        title: 'Quantity',
                        dataIndex: 'qty',
                      },
                    ]}
                    dataSource={rec.details}>
                  </Table>
                )
              }
            }}>

          </Table>
          {/* {
            rawData.detail.filter(foo => checked.includes(foo.id)).length
          }
          <div className="mb-2 font-semibold">{checked.length}</div> */}
        </div>
      </Modal>
      {contextHolder}
    </div>
  )
}

const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <Transfer {...restProps}>
    {({
      direction,
      filteredItems,
      onItemSelect,
      onItemSelectAll,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      const columns = direction === 'left' ? leftColumns : rightColumns;
      const rowSelection = {
        getCheckboxProps: () => ({
          disabled: listDisabled,
        }),
        onChange(selectedRowKeys) {
          onItemSelectAll(selectedRowKeys, 'replace');
        },
        selectedRowKeys: listSelectedKeys,
        selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
      };
      return (
        <Table
          rowSelection={rowSelection}
          columns={[
            {
              title: 'ID',
              dataIndex: 'id'
            },
            {
              title: 'Fullname',
              dataIndex: 'firstName',
              render: (cell, row) => <>{row.firstName} {row.lastName}</>
            },
            {
              title: 'Role',
              dataIndex: 'Name'
            },
          ]}
          dataSource={filteredItems}
          size="small"
          style={{
            pointerEvents: listDisabled ? 'none' : undefined,
          }}
          onRow={({ key, disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled || listDisabled) {
                return;
              }
              onItemSelect(key, !listSelectedKeys.includes(key));
            },
          })}
        />
      );
    }}
  </Transfer>
);

export default BidSummaryList