import React, { useEffect, useState } from 'react'
import { Button, Tooltip, Skeleton, Divider, Card, Form, Modal, Input } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { useNavigate, Outlet, NavLink } from 'react-router-dom';
import { instance } from 'utils/axios';
import { useParams, Link, useLocation } from "react-router-dom";
import { BsClipboardCheck, BsJournalBookmark, BsPeople, BsViewStacked, BsBoxes, BsChatQuote, BsPencilSquare, BsQuestionSquare, BsChatLeftDots   } from "react-icons/bs";
import SurveyForm from './SurveyForm';
import { twMerge } from 'tailwind-merge';


const statusEnum = {
  '-1': {
    title: 'Draft',
    color: '#F09D51'
  },
  0: {
    title: 'Published',
    color: '#427EC1'
  },
  1: {
    title: 'Open',
    color: '#009A72'
  },
  2: {
    title: 'Reopened',
    color: '#009A72'
  },
  3: {
    title: 'Closed',
    color: '#D55727'
  },
  4: {
    title: 'Awarded',
    color: '#009A72'
  },
  5: {
    title: 'Finished',
    color: '#009A72'
  },
  6: {
    title: 'Postponed',
    color: '#F09D51'
  },
  7: {
    title: 'Declined',
    color: '#D55727'
  },
  8: {
    title: 'In evaluation',
    color: '#29ABE2'
  },
  9: {
    title: 'Approved',
    color: '#009A72'
  },
  10: {
    title: 'Pending approval',
    color: '#F09D51'
  },
  11: {
    title: 'Canceled',
    color: '#D55727'
  },
}

function TenderDetail() {
  const [ data, setData ] = useState(null)
  const [ loading, setLoading ] = useState(true)
  const [ awarding, setAwarding ] = useState(false)
  const [ surveyModalOpen, setSurveyModalOpen ] = useState(false)
  const [ withdrawModalOpen, setWithdrawModalOpen ] = useState(false)
  const [ withdrawComment, setWithdrawComment ] = useState('')
  const [ form ] = Form.useForm()
  const location = useLocation()
  const navigate = useNavigate()
  const { type, id } = useParams()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `Tender/${type}/${id}?wcode=tender-detail`
    }).then(res => {
      console.log(res.data.responseData.tendertype)
      setData(res.data.responseData)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const handleAward = () => {
    setAwarding(true)
    instance({
      method: 'post',
      url: `Tender/rfq/award?wcode=tender-detail`,
      data: {
        tenderId: id
      }
    }).then(res => {
      
    }).catch(err => {

    }).then(() => {
      setAwarding(false)
      fetchData()
    })
  }

  const handleSetFeedback = () => {
    setSurveyModalOpen(true)
  }

  const handleCloseModal = () => {
    setSurveyModalOpen(false)
    form.resetFields()
  }

  const handleWithdraw = () => {
    instance({
      method: 'put',
      url: `Tender/withdraw?wcode=tender-detail`,
      data: {
        id: id,
        comment: withdrawComment
      }
    }).then(res => {
      setWithdrawModalOpen(false)
      setWithdrawComment("")
    }).catch(err => {

    }).then(() => {
      setAwarding(false)
      fetchData()
    })
  }

  return (
    <div 
      className='p-4 gap-3 grid grid-cols-[min-content_1fr] min-w-0'
      // className="p-4 gap-3 grid grid-cols-12"
    >
      {/* <div className="col-span-12">
        <Tooltip title="Go back">
          <Button type="link" onClick={() => navigate("/tender")} icon={<LeftOutlined />}>
            Go back to tender list
          </Button>
        </Tooltip>
      </div> */}
      <div className="w-[270px]">
        <Card className="bg-white py-3" size="small">
          {
            loading ?
            <div className="px-10">
              <Skeleton/>
            </div>
            :
            <div className="flex flex-col gap-2 px-3">
              <div className="flex gap-3 items-center">
                <div className='text-lg text-primary'>
                  {data.name}
                </div>
              </div>
              <div className="text-mute">
                Tender number: {data.code}
                <div className="flex items-center">
                Status: <div className="w-3 h-3 rounded-full ml-3 mr-1 shrink-0" style={{backgroundColor: statusEnum[data.status].color}}></div> {statusEnum[data.status].title}
                </div>
              </div>
            </div>
          }
          <Divider></Divider>
          <div className="flex flex-col px-3">
            <NavLink className={({isActive}) => twMerge("py-1 hover:text-primary transition-all hover:bg-gray-100 px-3 flex items-center gap-2 rounded", location.pathname.split("/").length == 3 && isActive && "bg-gray-200 text-primary")} 
              to={`/tender/${type}/${id}`}>
              <BsClipboardCheck className="text-lg"/> Overview
            </NavLink>
            <NavLink className={({isActive}) => twMerge("py-1 hover:text-primary transition-all hover:bg-gray-100 px-3 flex items-center gap-2 rounded", isActive && "bg-gray-200 text-primary")} 
              to={`/tender/${type}/${id}/suppliers`}>
              <BsJournalBookmark className="text-lg"/> Suppliers
            </NavLink>
            {
              data?.tendertype == "travel" &&
              <NavLink className={({isActive}) => twMerge("py-1 hover:text-primary transition-all hover:bg-gray-100 px-3 flex items-center gap-2 rounded", isActive && "bg-gray-200 text-primary")} 
                to={`/tender/${type}/${id}/passengers`}>
                <BsPeople className="text-lg"/> Passengers
              </NavLink>
            }
            {
              data?.tendertype != "eoi" &&
              <NavLink className={({isActive}) => twMerge("py-1 hover:text-primary transition-all hover:bg-gray-100 px-3 flex items-center gap-2 rounded", isActive && "bg-gray-200 text-primary")} 
                to={`/tender/${type}/${id}/${data?.tendertype == "rfq" ? data?.rfqType == 1 ? `bid-summary` : 'bid-summary-service' : data?.tendertype == "travel" ? 'travel-bid-summary' : 'freight-bid-summary'}`}>
                <BsPeople className="text-lg"/> Bid summary
              </NavLink>
            }
            {
              data?.tendertype == "rfq" || data?.tendertype == "freight" &&
              <NavLink className={({isActive}) => twMerge("py-1 hover:text-primary transition-all hover:bg-gray-100 px-3 flex items-center gap-2 rounded", isActive && "bg-gray-200 text-primary")} 
                to={`/tender/${type}/${id}/award`}>
                <BsViewStacked className="text-lg"/>Award
              </NavLink>
            }
            {
              data?.tendertype == 'eoi' &&
              <>
              <NavLink className={({isActive}) => twMerge("py-1 hover:text-primary transition-all hover:bg-gray-100 px-3 flex items-center gap-2 rounded", isActive && "bg-gray-200 text-primary")} 
                to={`/tender/${type}/${id}/eoi-bidder-list`}>
                <BsPeople className="text-lg"/> Bidder list
              </NavLink>
              <NavLink className={({isActive}) => twMerge("py-1 hover:text-primary transition-all hover:bg-gray-100 px-3 flex items-center gap-2 rounded", isActive && "bg-gray-200 text-primary")} 
                to={`/tender/${type}/${id}/eoi-short-list`}>
                <BsPeople className="text-lg"/> Short list
              </NavLink>
              </>
            }
            {
              (data?.tendertype != 'eoi' && data?.tendertype != "travel") &&
              <NavLink className={({isActive}) => twMerge("py-1 hover:text-primary transition-all hover:bg-gray-100 px-3 flex items-center gap-2 rounded", isActive && "bg-gray-200 text-primary")} 
                to={`/tender/${type}/${id}/chat`}>
                <BsChatLeftDots className="text-lg"/> Chat
              </NavLink>
            }
            <NavLink className={({isActive}) => twMerge("py-1 hover:text-primary transition-all hover:bg-gray-100 px-3 flex items-center gap-2 rounded", isActive && "bg-gray-200 text-primary")} 
              to={`/tender/${type}/${id}/log`}>
              <BsViewStacked className="text-lg"/>Log
            </NavLink>
            <NavLink className={({isActive}) => twMerge("py-1 hover:text-primary transition-all hover:bg-gray-100 px-3 flex items-center gap-2 rounded", isActive && "bg-gray-200 text-primary")} 
              to={`/tender/${type}/${id}/feedback`}>
              <BsViewStacked className="text-lg"/>Feedback
            </NavLink>
            {
              (data?.tendertype == "rfq") &&
              <NavLink className={({isActive}) => twMerge("py-1 hover:text-primary transition-all hover:bg-gray-100 px-3 flex items-center gap-2 rounded", isActive && "bg-gray-200 text-primary")} 
                to={`/tender/${type}/${id}/negotiation`}>
                <BsViewStacked className="text-lg"/>Negotiation history
              </NavLink>
            }
            {
              (data?.tendertype == "rfq") &&
              <NavLink className={({isActive}) => twMerge("py-1 hover:text-primary transition-all hover:bg-gray-100 px-3 flex items-center gap-2 rounded", isActive && "bg-gray-200 text-primary")} 
                to={`/tender/${type}/${id}/approval-history`}>
                <BsViewStacked className="text-lg"/>Bid approval history
              </NavLink>
            }
          </div>
          {
            data && ( data.status == 9 || data.status == 10) &&
            <Divider></Divider>
          }
          <div className="px-3">
            {
              data && data.status == 9 &&
              <Button disabled={awarding} loading={awarding} type="link" className="w-full hover:!bg-gray-200 transition-all" onClick={handleAward}>
                Award
              </Button>
            }
            {
              data && data.status == 10 &&
              <Button disabled={awarding} loading={awarding} type="link" className="w-full hover:!bg-gray-200 transition-all" onClick={() => setWithdrawModalOpen(true)}>
                Withdraw from approval
              </Button>
            }
          </div>
          <div className="flex flex-col px-10">
          </div>
        </Card>
      </div>
      <div className="min-w-0">
        {
          loading  ?
          <Skeleton active></Skeleton>
          :
          <Outlet context={[{...data, loading, fetchData}]}></Outlet>
        }
        {/* <TenderMain/> */}
      </div>
      <Modal
        open={surveyModalOpen}
        destroyOnClose
        maskClosable={false}
        okText='Save'
        onOk={form.submit}
        onCancel={handleCloseModal}
      >
        <SurveyForm setModal={setSurveyModalOpen} form={form}/>
      </Modal>
      <Modal
        title="Withdraw from approval"
        okText="Withdraw"
        destroyOnClose
        open={withdrawModalOpen}
        onCancel={() => setWithdrawModalOpen(false)}
        onOk={handleWithdraw}>
        <Input.TextArea placeholder='Withdrawal justification' value={withdrawComment} onChange={(e) => setWithdrawComment(e.target.value)}></Input.TextArea>
      </Modal>
    </div>
  )
}

export default TenderDetail
