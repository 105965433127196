import { Tabs } from "antd"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { instance } from "utils/axios"
import Total from "./Total"
import Items from "./Items"

const BidSummary = () => {
  const location = useLocation()
  

  return (
    <Total/>
  )
  // return (
  //   <Tabs
  //     type="card"
  //     size="small"
  //     tabBarStyle={{margin: 0}}
  //     items={[
  //       {
  //         label: 'Total',
  //         key: 0,
  //         children: <Total/>
  //       },
  //       {
  //         label: 'Items',
  //         key: 1,
  //         children: <Items/>
  //       },
  //     ]}>

  //   </Tabs>
  // )
}

export default BidSummary