import React from 'react';
import { Header, Footer, Sidebar, Breadcrumb } from './components';

const Layout = ({children}) => {
  
  return (
    <div id="layout">
      {/* <Header></Header> */}
      <Sidebar></Sidebar>
      {/* <Footer></Footer> */}
      <main>
        <Breadcrumb/>
        {children}
      </main>
    </div>
  );
}

export default Layout;
