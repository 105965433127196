import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { EditOutlined, PlusCircleOutlined, MoreOutlined } from '@ant-design/icons'
import { Button, Form as AntForm, Modal, Space, Dropdown, Tabs  } from 'antd';
import { MainTable, Form } from 'components';
import { instance } from 'utils/axios';
import { message } from 'antd';

const items = ({row, handleEdit, handleDelete}) => {  
  return (
    [
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleEdit(row)
        }}>Edit</a>,
        key: 0
      },
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleDelete(row.id)
        }}>Delete</a>,
        key: 1
      },
    ]
  )
}

const fields = () => {
  return [
    {
      label: 'Name',
      name: 'name',
      rules: [{required: true, message: 'Name is required!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
  ]
}

const columns = ({currentPage, handleEdit, handleDelete}) => {
  return ([
    {
      title: '№',
      dataIndex: 'No',
      key: 'No',
      width: 40,
      align: 'center',
      render: (text, row, i) => (
        <span>{currentPage ? currentPage+i+1 : i+1}</span>
      )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      filterType: "search",
      render: (text, row) => (
        <Link className='text-link hover:underline hover:text-link' to={`${row.id}`} state={row}>{text}</Link>
      )
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 80,
      render: (text, row) => (
        <Dropdown 
          trigger={['click']} 
          menu={{items: items({row, handleEdit, handleDelete}),}}>
          <a onClick={e => e.preventDefault()}>
            <Space>
              <MoreOutlined className='text-xl'/>
            </Space>
          </a>
        </Dropdown>
      )
    },
  ])
}

const AuditConfigGroup = ({}) => {
  const [ data, setData ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const [ open, setOpen ] = useState(false)
  const [ editData, setEditData ] = useState(null)
  const [ isEdit, setIsEdit ] = useState(false)
  const [ modal, contextHolder ] = Modal.useModal();
  const [ activeTab, setActiveTab ] = useState(0)
  const { id } = useParams()
  const [ form ] = AntForm.useForm();
  const location = useLocation()

  useEffect(() => {
    fetchData()
  }, [activeTab])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `/AuditGroup/audit/${id}?wcode=audit-config-group`
    }).then(res => {
      setData(res.data.responseData)
    }).catch(err=> {

    }).then(() => {
      setLoading(false)
    })
  }

  const handleCloseModal = () => {
    setOpen(false)
    form.resetFields()
  }

  const handleChange = (_index) => {
    setData([])
    setLoading(true)
    setActiveTab(_index)
  }

  const handleEdit = (_row) => {
    setEditData(_row)
    setOpen(true)
  }

  const handleAddNew = () => {
    setEditData(null)
    setOpen(true)
  }

  const handleSubmit = (values) => {
    if(editData) {
      setLoading(true)
      instance({
        method: 'put',
        url: `/AuditGroup?wcode=audit-config-group`,
        data: {
          ...editData,
          ...values,
          auditId: id
        }
      }).then(() => {
        setOpen(false)
      }).catch(() => {

      }).then(() => {
        setLoading(false)
        fetchData()
      })
    }
    else {
      setLoading(true)
      instance({
        method: 'post',
        url: `/AuditGroup?wcode=audit-config-group`,
        data: {
          ...values,
          auditId: id
        }
      }).then(() => {
        setOpen(false)
      }).catch(() => {

      }).then(() => {
        setLoading(false)
        fetchData()
      })
    }
  }

  const handleDelete = (id) => {
    modal.confirm({
      title: 'Warning!',
      content: 'You are about to delete this row of data',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: 'delete',
            url: `/AuditGroup/${id}?wcode=audit-config-group`
          }).then((res) => {
            resolve()
          }).catch((err) => {
            reject()
          }).then(() => fetchData())
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

  const handlePublish = () => {
    modal.confirm({
      title: 'Warning!',
      content: 'You are about to publish all the changes',
      okText: 'Publish',
      cancelText: 'Cancel',
      onOk() {
        setLoading(true)
        return new Promise((resolve, reject) => {
          instance({
            method: 'get',
            url: `/Audit/publish/${id}?wcode=audit-config-group`
          }).then((res) => {
            message.success("Successfully published!")
            resolve()
          }).catch((err) => {
            message.error("There was error while publishing!")
            reject()
          }).then(() => {
            setLoading(false)
          })
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }
  
  return (
    <div className="p-4">
      <MainTable
        dataTable={data}
        columns={columns({handleDelete, handleEdit})}
        loading={loading}
        handleAdd={handleAddNew}
        goback
        title={`${location.state.name}`}
        filter={<Button type='primary' onClick={handlePublish} loading={loading}>Publish</Button>}>

      </MainTable>
      <Modal
        title={editData ? <div className='flex items-center justify-start gap-2'>Edit</div> : <div className='flex items-center justify-start gap-2'>Add</div>}
        open={open}
        destroyOnClose
maskClosable={false}
        okText='Save'
        onOk={form.submit}
        onCancel={handleCloseModal}
        width={"40%"}
      >
        <Form
          form={form}
          fields={fields()}
          length={data.length}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          isEdit={isEdit}
          className='gap-4'
        />
      </Modal>
      {contextHolder}
    </div>
  );
}

export default AuditConfigGroup;
