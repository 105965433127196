export default ({options, permissions}) => {
  return [
    {
      label: 'Firstname',
      name: 'firstName',
      rules: [{required: true, message: 'Firstname is required!'}],
      className: 'col-span-6 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Lastname',
      name: 'lastName',
      rules: [{required: true, message: 'Lastname is required!'}],
      className: 'col-span-6 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Email',
      name: 'email',
      rules: [{required: true, message: 'Email is required!'}, {type: 'email', message: 'Email is not valid!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Position',
      name: 'userPositionId',
      rules: [{required: true, message: 'Position is required!'}],
      className: 'col-span-12 mb-0',
      type: "select",         
      inputProps: {
        className: 'w-full',
        options: options
      }
    },
    {
      label: 'Status',
      name: 'status',
      rules: [{required: true, message: 'Status is required!'}],
      className: 'col-span-12 mb-0',
      type: "select",         
      inputProps: {
        className: 'w-full',
        options: [
          {
            label: 'Active',
            value: 1
          },
          {
            label: 'Inactive',
            value: 0
          },
        ]
      }
    },
  ]
}