import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { Button, Modal, Form as AntForm, Tabs, Spin, Divider, Tooltip, Skeleton, Card, Checkbox } from 'antd'
import { LeftOutlined, CaretDownOutlined } from '@ant-design/icons'
import { instance } from 'utils/axios'
import { Link, useParams, useNavigate, Outlet, useMatches } from 'react-router-dom'
import { AuthContext } from 'contexts'

function SupplierDetail() {
  const [ data, setData ] = useState({
    supplierContacts: [],
    supplierEmployees: [],
    supplierInfo: {},
    supplierProducts: [],
    supplierServices: [],
    supplierShareholders:[]
  })
  const [ activeRoute, setActiveRoute ] = useState("")
  const [ loading, setLoading ] = useState(true)
  const [ modal, contextHolder ] = Modal.useModal();
  const authContext = useContext(AuthContext)
  const { supplierId } = useParams()
  const matches = useMatches()
  const routes = [
    {
      link: `/supplier/${data.supplierInfo.id}`,
      name: 'Company information'
    },
    {
      link: `contact-details`,
      name: 'Contact details'
    },
    {
      link: `management-team`,
      name: 'Management team'
    },
    {
      link: `shareholder-information`,
      name: 'Shareholder information'
    },
    {
      link: `products-services`,
      name: 'Products & services'
    },
  ]
  
  useEffect(() => {
    setActiveRoute(matches.at(-1).pathname.split("/").at(-1))
  }, [matches])
  
  useEffect(() => {
    fetchData()
  },[])

  const fetchData = () => {
    setLoading(true)
    instance({
      method: 'get',
      url: `/Supplier/${supplierId}?wcode=supplier-detail`
    }).then((res) => {
      setData(res.data.responseData)
    }).catch((err) => {
    }).then(() => setLoading(false))
  }

  console.log(authContext.state)
  
  return (
    <div className="p-4 grid grid-cols-12 gap-3 h-full">
      <div className="col-span-3 sticky top-3" style={{height: 'calc(100vh - 76px)'}}>
        <Card className="h-full" size='small' styles={{body: {height: '100%'}}}>
          <div className="flex flex-col gap-3 h-full">
            {
              loading ?
              <Skeleton/>
              :
              <div className="flex items-center gap-3 mb-3">
                <div className="shrink-0 w-12 h-12 rounded-full flex justify-center items-center text-white bg-primary text-3xl">
                  {data.supplierInfo.name?.slice(0,1)}
                </div>
                <div className="grow">
                  <div className='text-xl font-bold'>
                    {data.supplierInfo.name}
                  </div>
                  <div className="text-mute">
                    Vendor number: {data.supplierInfo.vendorNumber}
                  </div>
                </div>
              </div>
            }
            <div className="flex flex-col grow">
              {
                routes.map((foo, fooindex) => {
                  return (
                    <Link key={`supplier-route-${fooindex}`} className="py-3 hover:text-primary transition-all hover:bg-slate-200 px-3 flex items-center gap-2 rounded" 
                      to={foo.link}>
                      <div className={`grow font-medium ${!activeRoute ? fooindex == 0 && `text-primary` : activeRoute == foo.link && `text-primary`}`}>
                        {foo.name}
                      </div>
                      <CaretDownOutlined className={`text-black/40 transition-all  ${!activeRoute ? fooindex == 0 && `text-primary` : activeRoute == foo.link && `!text-primary -rotate-90`}`}/>
                    </Link>
                  )
                })
              }
            </div>
            <Divider className='my-2'></Divider>
            <div className="flex flex-col">
            {
              authContext.state.userInfo.permissions.some(foo => foo.permissionId == 1) &&
              <>
                <Link className="py-3 hover:text-primary transition-all hover:bg-slate-200 px-3 flex items-center gap-2 rounded" 
                  to={``}>
                  Supplier request
                </Link>
                <Link className="py-3 hover:text-primary transition-all hover:bg-slate-200 px-3 flex items-center gap-2 rounded" 
                  to={`supplier-type`}>
                  Supplier type
                </Link>
              </>
            }
              {/* <Link className="py-3 hover:text-primary transition-all hover:bg-slate-200 px-3 flex items-center gap-2 rounded" 
                to={`supplier-type`}>
                Supplier travel/freight
              </Link> */}
            </div>
          </div>
        </Card>
      </div>
      {
        loading ?
        <div className='bg-white p-4'>
          <Skeleton>
            
          </Skeleton>
        </div>
        :
        <div className="col-span-9">
          <Outlet context={[data]}></Outlet>
        </div>
      }
      {contextHolder}
    </div>
  )
}

export default SupplierDetail
