import { Button, Tag } from "antd"

export default () => {
  return ([
    {
      title: 'FORWARDER NAME',
      dataIndex: 'name',
    },
    {
      title: 'SRN number',
      dataIndex: 'srnNumber'
    },
    {
      title: 'LEADTIME (by Days)',
      dataIndex: 'leadTime'
    },
    {
      title: 'FREIGHT COST /USD/',
      dataIndex: 'totalPrice',
      render: (cell) => cell.toLocaleString("en-US")
    },
    {
      title: 'TRANSPORT MODE',
      dataIndex: 'transportMode'
    },
    {
      title: 'TRANSPORT TYPE',
      dataIndex: 'transportType'
    },
    {
      title: 'MULTIPLE QUOTE ?',
      dataIndex: 'description'
    },
    {
      title: 'Award status',
      dataIndex: 'awardStatus',
      render: (cell, row) => {
        if(cell == 1 && row.isAwarded) {
          return (
            <Tag color="#009A72">
              Awarded
            </Tag>
          )
        }
      }
    },
    {
      title: 'Attachment',
      dataIndex: 'fileName',
      render: (cell) => {
        if(cell) {
          return (
            <Button type="link" href={process.env.REACT_APP_UPLOAD_URL+cell} target="_blank">
              Preview
            </Button>
          )
        }
        else {
          return (
            <></>
          )
        }
      }
    },
  ])
} 