export const headerFields = (parentData) => {
  return [
    {
      label: 'Title',
      name: 'title',
      rules: [{required: true, message: 'Title is required!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Description',
      name: 'description',
      type: 'textarea',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Status',
      name: 'status',
      type: 'select',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
        options: [
          { label: 'Active', value: 1 }, 
          { label: 'Inactive', value: 0 },   
      ],
      }
    },
    {
      label: 'Image',
      name: 'image',
      type: 'file',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
  ]
}

export const detailFields = () => {
  return [
    {
      label: 'Title',
      name: 'title',
      rules: [{required: true, message: 'Title is required!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Description',
      name: 'description',
      rules: [{required: true, message: 'Description is required!'}],
      type: 'textarea',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Content',
      name: 'content',
      type: 'editor',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Status',
      name: 'status',
      type: 'select',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
        options: [
          { label: 'Active', value: 1 }, 
          { label: 'Inactive', value: 0 },   
      ],
      }
    },
    {
      label: 'Image',
      name: 'image',
      type: 'file',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
  ]
}