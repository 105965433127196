import React, { useEffect, useState } from 'react'
import { Skeleton, Modal, Card } from 'antd'
import { instance } from 'utils/axios';
import TenderTypeContainer from './tenderTypeContainer';
import { Filter } from './components';
import { Filters } from 'components';

function TenderList() {
  const [ loading, setLoading ] = useState(false)
  const [ modal, contextHolder ] = Modal.useModal();
  const [ filter, setFilter ] = useState({
    type: null
  })

  const onSearch = (values) => {
    setFilter(prev => ({...prev, ...values}))
  }
  
  return (
    <div className='p-4'>
    {
      loading ?
      <div className="p-4 bg-white rounded-2xl">
        <Skeleton active>
        </Skeleton>
      </div>
      :
      <div className='grid grid-cols-12 gap-3'>
        <div className="col-span-12">
          <Filters wcode="tender" onSearch={onSearch}/>
        </div>
        <Card className='col-span-12' size="small">
          <TenderTypeContainer type={filter.type} modal={modal} filter={filter}/>
        </Card>
      </div>
    }
    {/* <Tabs
      type="card"
      tabBarStyle={{marginBottom: 0}}
      items={types.map((foo, fooIndex) => {
        return {
          label: foo.name,
          key: fooIndex,
          children: <TenderTypeContainer type={foo} modal={modal} suppliers={suppliers}></TenderTypeContainer>
        }
      })}>
      
    </Tabs>  */}
    {contextHolder}
    </div>
  )
}

export default TenderList
