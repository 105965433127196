import { useState, useEffect } from 'react'
import { Form } from 'components'
import { Button, Form as AntForm, Card, Input, Select } from 'antd'
import { instance } from 'utils/axios'
import { useOutletContext } from 'react-router-dom'

const CompanyShareholders = ({state}) => {
  const [ loading, setLoading ] = useState(false)
  const [ form ] = AntForm.useForm();
  const [ data ] = useOutletContext()
  const { supplierShareholders } = data 

  useEffect(() => {
    form.setFieldsValue({companyShareholders: supplierShareholders})
  }, [supplierShareholders])
  
  return (
    <div className='flex flex-col gap-3 h-full'>
      {
        supplierShareholders.length > 0 ?
        supplierShareholders.map((foo, fooIndex) => {
          return (
            <Card title={`Shareholder ${fooIndex+1}`} key={`shareholder-${fooIndex}`} className="h-full">
              <div className="grid grid-cols-4 gap-3">
                <div>
                  <label className="font-semibold">Fullname:</label>
                  <div className="text-mute">{(foo.firstName || foo.lastName) ? `${foo.firstName} ${foo.lastName}` : 'No name'}</div>
                </div>
                <div>
                  <label className="font-semibold">Shareholder type:</label>
                  <div className="text-mute">{foo.shareholderType == 1 ? 'Individual' : 'Organization'}</div>
                </div>
                <div>
                  <label className="font-semibold">Share percentage:</label>
                  <div className="text-mute">{foo.percent ? foo.percent : 'No share'}</div>
                </div>
              </div>
            </Card>
          )
        })
        :
        <div className="text-center font-semibold">
          No shareholders information
        </div>
      }
      {/* <Form
        disabled={true}
        form={form}
        fields={[]}
        layout="vertical"
        onFinish={() => {}}
        className='gap-4'>  
        <AntForm.List name="companyShareholders">  
          {(fields, {add, remove}) => (
            <>
              {
                fields.map(({name, key, ...restFields}) => (
                  <Card title={`Shareholder ${key+1}`} className="col-span-12 rounded-2xl" key={key} {...restFields}>
                    <AntForm.Item label="Type" name={[name, "type"]} className='col-span-12'>
                      <Select></Select>
                    </AntForm.Item>
                    <AntForm.Item label="Firstname" name={[name, "firstName"]}>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="Lastname" name={[name, "lastName"]}>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="Job Title" name={[name, "address2"]}>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="Share percentage %" name={[name, "percent"]}>
                      <Input></Input>
                    </AntForm.Item>
                  </Card>
                ))
              }
            </>
          )}
        </AntForm.List>
      </Form> */}
      
    </div>
  )
}

export default CompanyShareholders