import { AutoComplete, Button, Checkbox, Divider, Form, Input, InputNumber, Modal, Popconfirm, Popover, Select, Table, Tooltip, Tree, TreeSelect, Upload } from 'antd'
import { PlusOutlined, DeleteOutlined, UploadOutlined, DownloadOutlined, MoreOutlined } from '@ant-design/icons'
import { createContext, memo, useCallback, useContext, useEffect, useMemo, useRef, useState, useTransition } from 'react'
import * as XLSX from 'xlsx';
import { instance } from 'utils/axios'
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import RFQTemplate from 'assets/file/template.xlsx'
import ls from 'utils/ls';

const EditableContext = createContext(null);

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const EditableRow = memo(({ index, ...props }) => {
  const form = Form.useFormInstance();

  return (
    <EditableContext.Provider value={form}>
      <tr {...props} />
    </EditableContext.Provider>
  );
});

const EditableCell = memo(({
  title,
  editable,
  children,
  remove,
  dataIndex,
  record,
  handleSave,
  type,
  options,
  mode,
  ...restProps
}) => {
  const [editing, setEditing] = useState(true);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={[record.key, dataIndex]}
        rules={[
        ]}
      >
        {
          type ? 
          type == 'select' ?
          <Select size="small" options={options} mode={mode == 'tags' ? 'tags' : undefined} maxCount={1}/>
          :
          type == 'autocomplete' ? 
          <AutoComplete size="small" options={options} filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }>
            
          </AutoComplete>
          :
          type == "number" &&
          <InputNumber size="small" ref={inputRef} className="w-full"></InputNumber>
          :
          <Input size="small" ref={inputRef}/>
        }
      </Form.Item>
    ) : (
      {children}
    );
  }
  return <td {...restProps}>{childNode}</td>;
});

const RFQAdditionalForm = ({endUsers, products}) => {
  const [ prModalOpen, setPrModalOpen ] = useState(false)
  const [ materialModalOpen, setMaterialModalOpen ] = useState(false)
  const [ uomTypes, setUomTypes ] = useState([])
  const [ prNumber, setPrNumber ] = useState("")
  const [ materialNumber, setMaterialNumber ] = useState("")
  const [ isPending, startTransition ] = useTransition();
  const [ lockedModal, setLockedModal ] = useState(false)
  const [ locked, setLocked ] = useState([])
  const [ selectedLockedRow, setSelectedLockedRow ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const [ reservedLoading, setReservedLoading ] = useState(false)
  const [ selectedRow, setSelectedRow ] = useState([])
  const form = Form.useFormInstance();
  const uploadRef = useRef(null)
  const templateButtonRef = useRef(null)
  const userInfo = ls.get("userInfo");
  const token = userInfo && userInfo.jwtToken;

  useEffect(() => {
    fetchLocked()
  }, [])

  const fetchLocked = () => {
    instance({
      method: 'get',
      url: `/PurchaseRequest/locked?wcode=tender`
    }).then(res => {
      setLocked(res.data.responseData.requests)
    })
  }
  
  useEffect(() => {
    instance({
      method: 'get',
      url: `/Master/uom`
    }).then(res => {
      setUomTypes(res.data.responseData)
    })
  }, [])

  const handleImportPrNumber = (_ids) => {
    instance({
      method: 'post',
      url: `/PurchaseRequest/load?wcode=tender`,
      data: {
        ids: _ids ? _ids : prNumber.split(" ")
      }
    }).then(res => {
      let tmp = form.getFieldValue("details") ?? []
      res.data.responseData?.requests.map((foo) => {
        tmp.push(
          {
            ...foo,
            prNumber: foo.prNumber.toString(),
            description: foo.shortText,
            qty: foo.prQty,
            manufacturers: foo.manufacturers ? foo.manufacturers.map(foo => typeof foo == 'object' ? foo.manufacturerName?? '' : foo) : [],
            criticality: foo.criticalityCode
          }
        )
      })
      form.setFieldValue("details", tmp)
      form.setFieldValue("productTypeIds", [...new Set(tmp.filter(foo => foo.productTypeId != null).map(foo => foo.productTypeId))])
      setPrNumber("")
      setSelectedLockedRow([])
      setPrModalOpen(false)
      setReservedLoading(false)
    })
  }

  const handleImportMaterialNumber = () => {
    instance({
      method: 'post',
      url: `/Material/load?wcode=tender`,
      data: {
        materialIds: materialNumber.split(" ")
      }
    }).then(res => {
      let tmp = form.getFieldValue("details") ?? []
      res.data.responseData.map((foo) => {
        tmp.push(
          {
            ...foo,
            materialNumber: foo.id,
            description: foo.name,
            qty: foo.prQty,
            manufacturers: foo.manufacturer.length > 0 ?  [foo.manufacturer] : [],
            criticality: foo.criticalityCode
          }
        )
      })
      form.setFieldValue("details", tmp)
      setMaterialNumber("")
      setMaterialModalOpen(false)
    })
  }

  const handleDelete = (key) => {
    let tmp = [...form.getFieldValue("details")]
    tmp.splice(key, 1);
    form.setFieldValue("details", tmp);
  };

  const handleBulkDelete = () => {
    const tmp = [...form.getFieldValue("details")]

    const data = tmp.reduce((p, c, i) => (!selectedRow.includes(i) ? [...p, c] : p), []).sort((a, b) => b - a);
    form.setFieldValue("details", data)
    setSelectedRow([])
  }

  const handleSave = (row) => {
    const newData = [...form.getFieldValue("details")]
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    form.setFieldValue("details", newData);
  };

  const rfqColumns = useMemo(() => {
    return (
      [
        {
          title: 'Purchase #',
          dataIndex: 'prNumber',
          width: 150,
          editable: true,
        },
        {
          title: 'Material #',
          dataIndex: 'materialNumber',
          width: 150,
          editable: true,
        },
        {
          title: 'Material Description',
          dataIndex: 'description',
          editable: true,
        },
        {
          title: 'Criticality',
          dataIndex: 'criticality',
          width: 150,
          editable: true,
        },
        {
          title: 'Manufacturer',
          dataIndex: 'manufacturers',
          width: 200,
          type: 'select',
          mode: 'tags',
          editable: true,
        },
        {
          title: 'Manufacturer Part #',
          dataIndex: 'partNumber',
          editable: true,
        },
        {
          title: 'Quantity',
          dataIndex: 'qty',
          width: 100,
          editable: true,
          type: 'number'
        },
        {
          title: 'UOM',
          dataIndex: 'uom',
          editable: true,
          width: 150,
          type: 'select',
          options: uomTypes.map(foo => ({value: foo.shortName, label: foo.name}))
        },
        {
          title: '',
          dataIndex: 'operation',
          width: 50,
          render: (_, record) =>
            form.getFieldValue("details").length >= 1 ? (
              <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.name)}>
                <a className='w-full h-full flex justify-center items-center'><DeleteOutlined /></a>
              </Popconfirm>
            ) : null,
        },
      ]
    )
  }, [uomTypes])

  const columns = rfqColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        type: col.type,
        options: col.options,
        mode: col.mode,
        handleSave,
      }),
    };
  });

  const handleFileUpload = (e) => {
    startTransition(() => {
      const file = e.target.files[0];
      const reader = new FileReader();
  
      reader.onload = (event) => {
        const workbook = XLSX.read(event.target.result, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const sheetData = XLSX.utils.sheet_to_json(sheet);
  
        let data = form.getFieldValue("details")
        let tmp = []

        if(sheetData) {
          sheetData.map(foo => {
            let manufacturers = []
            if(foo.Manufacturer) {
              manufacturers.push(foo.Manufacturer)
            }
            if(foo.Manufacturer2) {
              manufacturers.push(foo.Manufacturer2)
            }
            if(foo.Manufacturer3) {
              manufacturers.push(foo.Manufacturer3)
            }
            if(foo.Manufacturer4) {
              manufacturers.push(foo.Manufacturer4)
            }
            if(foo.Manufacturer5) {
              manufacturers.push(foo.Manufacturer5)
            }
            tmp.push(
              {
                prNumber: foo['Purchase Request Number']?.toString(),
                materialNumber: foo['OT Material Code'],
                description: foo['Short Text'],
                qty: foo['Quantity']?? 1,
                uom: foo['UOM'],
                manufacturers: manufacturers,
                partNumber: foo['Manufacturer Part Number']?.toString(),
                criticality: foo['Criticality']
              }
            )
          })
        }
        form.setFieldValue("details", tmp)
      };
  
      reader.readAsBinaryString(file);
    })
   
  };

  const fixProductCodes = (pr, depth) => {
    let tmp = []
  
    pr.map((foo) => {
      tmp.push({
        selectable: depth == 1 ? true: false,
        value: foo.item.id, 
        title: `${foo.item.name} /${foo.item.code}/`, 
        children: fixProductCodes(foo.children, depth+1)
      })
    })
  
    return tmp 
  }

  const unload = () => {
    setLoading(true)
    instance({
      method: 'post',
      url: `/PurchaseRequest/unload?wcode=tender`,
      data: {
        loadIds: locked.filter(foo => selectedLockedRow.includes(foo.id)).map(foo => foo.loadId)
      }
    }).then(res => {
      setSelectedLockedRow([])
      fetchLocked()
      setReservedLoading(false)
    }).finally(() => {
      setLoading(false)
    })
  }

  // const handleCheckAll = (target, _fields) => {
  //   if(selectedRow.length == _fields.length) {
  //     setSelectedRow([])
  //   }
  //   else {
  //     setSelectedRow(_fields.map(foo => foo.name))
  //   }
  // }
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRow(selectedRowKeys)
    },
  };
  
  return (
    <>
    {/* <Divider className="col-span-12" orientation="left"></Divider> */}
    <div className="my-1 font-bold text-lg text-nowrap">Material list</div>
    <div className="col-span-12 flex gap-4 justify-between items-center">
      <div className="flex gap-4">
        <Button type="primary" disabled={locked.length == 0} onClick={() => setLockedModal(true)}>Reserved PR ({locked.length})</Button>
        <Button type="primary" onClick={() => setPrModalOpen(true)}>Import PR Number</Button>
        <Button type="primary" onClick={() => setMaterialModalOpen(true)}>Import Material Number</Button>
        <Button type="primary" loading={isPending} onClick={() => {
          uploadRef.current && uploadRef.current.click()
        }}>Import From XLSX</Button>
        <input type="file" ref={uploadRef} className="!hidden" accept={".xls,.xlsx,.csv"} onChange={handleFileUpload} value={[]}/>
        
        <a download href={RFQTemplate} classname="!hidden" ref={templateButtonRef}></a>
      </div>
      <div className="flex items-center gap-4">
        <Form.Item name="allowAlternative" className="mb-0">
          <Checkbox>Allow alternative</Checkbox>
        </Form.Item>
        <Tooltip title="Download excel template">
          <Button className="justify-self-end" type="primary" onClick={() => templateButtonRef.current.click()}>
            <DownloadOutlined className='text-lg'/>
          </Button>
        </Tooltip>
        <Tooltip title="Bulk delete">
          <Popconfirm title="Sure to delete checked rows?" onConfirm={handleBulkDelete}>
            <Button className="justify-self-end" type="primary" >
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Tooltip>
      </div>
    </div>
    <Form.List name="details">
      {(fields, {add, remove}) => {
        return (
          <div className="col-span-12 flex flex-col">
            {/* <tr className="flex">
              <td className="w-[50px] py-1 font-medium border border-r-0 flex justify-center items-center">
                <Tooltip title="Check all">
                  <Checkbox onChange={e => handleCheckAll(e, fields)} checked={selectedRow.length == fields.length}></Checkbox>
                </Tooltip>
              </td>
              <td className="w-[50px] text-center py-1 font-medium border border-r-0">
                #
              </td>
              <td className="flex-1 px-3 py-1 font-medium border border-r-0">
                PR Number
              </td>
              <td className="flex-1 px-3 py-1 font-medium border border-r-0">
                Material Number
              </td>
              <td className="flex-1 px-3 py-1 font-medium border border-r-0">
                Material Description
              </td>
              <td className="w-[100px] px-3 py-1 font-medium border border-r-0">
                Criticality
              </td>
              <td className="flex-1 px-3 py-1 font-medium border border-r-0">
                Manufacturer
              </td>
              <td className="flex-1 px-3 py-1 font-medium border border-r-0">
                Manufacturer Part Number
              </td>
              <td className="w-[100px] px-3 py-1 font-medium border border-r-0">
                Quantity
              </td>
              <td className="flex-1 px-3 py-1 font-medium border border-r-0">
                UOM
              </td>
              <td className="w-[50px] px-3 py-1 border">
                <Tooltip title="Bulk delete">
                  <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete('all')}>
                    <a className='h-full w-full flex justify-center items-center'><DeleteOutlined /></a>
                  </Popconfirm>
                </Tooltip>
              </td>
            </tr> */}
            {/* <List
              className='List'
              height={fields.length > 0 ? 500 : 0}
              width={(window.innerWidth*0.9) - 56}
              itemCount={fields.length}
              itemSize={40}>
              {({index, style}) => EditRow({index, style, handleDelete, uomTypes, setSelectedRow, selectedRow})}
            </List> */}
            <Table
              scroll={{
                y: 500
              }}
              size="small"
              dataSource={fields}
              rowKey={(row, index) => {
                return index
              }}
              rowSelection={{
                selectedRowKeys: selectedRow,
                type: 'checkbox',
                ...rowSelection,
              }}
              pagination={false}
              components={{
                body: {
                  cell: EditableCell,
                  row: EditableRow
                },
              }}
              bordered={true}
              columns={columns}>

            </Table>
            <Form.Item noStyle>
              <Button type="dashed" className="mt-3" onClick={() => {
                add({state: 1})
              }} block icon={<PlusOutlined />}>
                Add
              </Button>
            </Form.Item>
          </div>
        )
      }}      
      
    </Form.List> 
    <Form.Item name="productTypeIds" label="Scope SECT Code" className="col-span-6 mb-0">
      <TreeSelect allowClear multiple placeholder="Select product sect code" className="w-full" treeData={fixProductCodes(products, 0)} disabled></TreeSelect>
    </Form.Item>
    <Form.Item name="attachments" label="Attachment" className="col-span-6 mb-0" valuePropName="fileList" getValueFromEvent={normFile}>
      <Upload 
        multiple
        action={`${process.env.REACT_APP_MAIN_API_URL}File/upload`}
        headers={{
          Authorization: `Bearer ${token}`,
        }}
        onRemove={(file) => {
          if(file.response) {
            instance({
              method: 'delete',
              url: `File/remove`,
              data: file.response.responseData
            })
          }
        }}>
        <Button icon={<UploadOutlined />}>Select file</Button>
      </Upload>
    </Form.Item>
    <Form.Item className="col-span-12 mb-0" label="End users" name="endUserIds">
      <Select maxTagCount={2} maxCount={2} options={endUsers.map(foo => ({label: `${foo.firstName} ${foo.lastName}`, value: foo.id}))} multiple className='w-full' mode="tags"></Select>
    </Form.Item>
    <Modal
      title="Import PR number"
      open={prModalOpen}
      onCancel={() => setPrModalOpen(false)}
      onOk={() => handleImportPrNumber()}
      destroyOnClose>
      <Input className='w-full' placeholder="PR numbers" onChange={e => setPrNumber(e.target.value)} value={prNumber}></Input>
    </Modal>
    <Modal
      title="Import material number"
      open={materialModalOpen}
      onCancel={() => setMaterialModalOpen(false)}
      onOk={() => handleImportMaterialNumber()}
      destroyOnClose>
      <Input className='w-full' placeholder="Material numbers" onChange={e => setMaterialNumber(e.target.value)} value={materialNumber}></Input>
    </Modal>
    <Modal
      width={'80%'}
      style={{top: 20}}
      title="Reserved PR"
      open={lockedModal}
      onCancel={() => setLockedModal(false)}
      loading={loading}
      destroyOnClose
      footer={[
          <Tooltip title={selectedLockedRow.length > 0 ? 'Click to use selected PR numbers' : 'Select to continue'}>
            <Button type="primary" disabled={selectedLockedRow.length == 0} loading={reservedLoading} onClick={() => {
              // setPrNumber(locked.filter(foo => selectedLockedRow.includes(foo.id)).map(foo => foo.prNumber).join(" "))
              setReservedLoading(true)
              handleImportPrNumber(locked.filter(foo => selectedLockedRow.includes(foo.id)).map(foo => foo.prNumber))
              setLockedModal(false)
              fetchLocked()
            }}>
              Continue
            </Button>
          </Tooltip>,
          <Tooltip title={selectedLockedRow.length > 0 ? 'Click to use release PR numbers' : 'Select to release'}>
            <Button type="primary" disabled={selectedLockedRow.length == 0} loading={reservedLoading} onClick={() => {
              setReservedLoading(true)
              unload()
            }}>
              Release
            </Button>
          </Tooltip>,
          <Button onClick={() => setLockedModal(false)} loading={reservedLoading}>
            Close
          </Button>,
        ]}>
      <Table
        loading={loading}
        dataSource={locked}
        rowKey={e => e.id}
        rowSelection={{
          selectedRowKeys: selectedLockedRow,
          onChange: (e) => {setSelectedLockedRow(e)}
        }}
        columns={[
          {
            title: 'Purchase #',
            dataIndex: 'prNumber'
          },
          {
            title: 'Material #',
            dataIndex: 'materialNumber'
          },
          {
            title: 'Material Description',
            dataIndex: 'shortText'
          },
          {
            title: 'Criticality',
            dataIndex: 'criticalityCode'
          },
          {
            title: 'Manufacturer',
            dataIndex: 'manufacturers',
            render: (cell) => cell.map(foo => foo.manufacturerName).join(";")
          },
          {
            title: 'Manufacturer Part #',
            dataIndex: 'partNumber'
          },
          {
            title: 'Quantity',
            dataIndex: 'prQty'
          },
          {
            title: 'UOM',
            dataIndex: 'uom'
          },
        ]}>

      </Table>
    </Modal>
    </>
  )
}

const EditRow = ({index, style, handleDelete, uomTypes, selectedRow, setSelectedRow}) => {
  const [ checked, setChecked ] = useState(false)
  
  return (
    <tr style={style} className="flex">
      <td className='w-[50px] border border-r-0 border-t-0 flex justify-center items-center'>
        <Checkbox checked={checked} onChange={e => setChecked(e.target.checked)}></Checkbox>
      </td>
      <td className="w-[50px] flex justify-center items-center border border-r-0 border-t-0">{index+1}</td>
      <td className="flex-1 px-3 py-1 border border-r-0 border-t-0">
        <Form.Item name={[index, 'prNumber']} className='mb-0'>
          <Input size="small"/>
        </Form.Item>
      </td>
      <td className="flex-1 px-3 py-1 border border-r-0 border-t-0">
        <Form.Item name={[index, 'materialNumber']} className='mb-0'>
          <InputNumber size="small" className='w-full'/>
        </Form.Item>
      </td>
      <td className="flex-1 px-3 py-1 border border-r-0 border-t-0">
        <Form.Item name={[index, 'description']} className='mb-0'>
          <Input size="small"/>
        </Form.Item>
      </td>
      <td className="w-[100px] px-3 py-1 border border-r-0 border-t-0">
        <Form.Item name={[index, 'criticality']} className='mb-0'>
          <Input size="small"/>
        </Form.Item>
      </td>
      <td className="flex-1 px-3 py-1 border border-r-0 border-t-0">
        <Form.Item name={[index, 'manufacturers']} className='mb-0'>
          {/* <Input size="small"/> */}
          <Select mode="tags" size="small" className="manufacturer-custom-select w-full" maxTagCount="responsive"></Select>
        </Form.Item>
      </td>
      <td className="flex-1 px-3 py-1 border border-r-0 border-t-0">
        <Form.Item name={[index, 'partNumber']} className='mb-0'>
          <Input size="small"/>
        </Form.Item>
      </td>
      <td className="w-[100px] px-3 py-1 border border-r-0 border-t-0">
        <Form.Item name={[index, 'qty']} className='mb-0' rules={[{required: true, message: 'Quantity is required!'}]}>
          <InputNumber size="small" className="w-full"/>
        </Form.Item>
      </td>
      <td className="flex-1 px-3 py-1 border border-r-0 border-t-0">
        <Form.Item name={[index, 'uom']} className='mb-0'>
          <Select options={uomTypes.map(foo => ({value: foo.shortName, label: foo.name}))} size="small"/>
        </Form.Item>
      </td>
      <td className="w-[50px] flex justify-center items-center border border-t-0">
        <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(index)}>
          <a><DeleteOutlined /></a>
        </Popconfirm>
      </td>
    </tr>
  )
}

export default RFQAdditionalForm