import { useEffect, useRef, useState } from "react"
import { instance } from "utils/axios"
import { useParams } from "react-router-dom"
import { Tooltip, Input, Button } from 'antd'
import { SendOutlined, LeftOutlined } from '@ant-design/icons'
import moment from "moment"

const Chat = ({tenderData}) => {
  const [ data, setData ] = useState({
    thread: {},
    messages: []
  })
  const [ fetching, setFetching ] = useState(true)
  const [ loading, setLoading ] = useState(false)
  const [ message, setMessage ] = useState("")
  const messageContainer = useRef()
  const { id } = useParams()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `/Message/tender/buyer/${id}?wcode=tender-detail`
    }).then(res => {
      setData(res.data.responseData)
    }).catch(err => {

    }).then(() => {
      setFetching(false)
    })
  }

  const renderMessages = () => {
    let tmp = []

    data.messages.map((message, messageIndex) => {
      tmp.push(
        <div key={messageIndex} className={message.isSupplier ? 'flex gap-4 items-start' : 'flex gap-4 items-start justify-end'}>
          {
            message.isSupplier ?
            <div className="w-8 h-8 rounded-full bg-gray-100"></div> : null
          }
          <Tooltip title={moment(message.createdAt).format("YYYY-MM-DD HH:mm:ss")} >
            <div className=" max-w-[60%] font-medium px-4 py-1 shadow bg-gray-100 rounded-xl">
              {message.body}
            </div>
          </Tooltip>
          {
            !message.isSupplier ?
            <div className="w-8 h-8 rounded-full bg-gray-100"></div> : null
          }
        </div>
      )
    })

    return tmp
  }

  const handleSubmit = () => {
    setLoading(true)
    instance({
      method: 'post',
      url: `/Message/reply`, 
      data: {
        body: message,
        messageThreadId: data.thread.id,
        tenderId: id
      }
    }).then(res => {
      setData(prev => ({messages: [{body: message, createdAt: moment().format("YYYY-MM-DD HH:mm:ss"), isSupplier: 0}, ...prev.messages], thread: prev.thread}))
      setMessage('')
      setLoading(false)
      messageContainer.current.focus()
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }
  
  return (
    <div className="bg-white">
      <div className="p-4 flex flex-col-reverse gap-2 flex-1 overflow-auto h-[400px]">
        {renderMessages()}
      </div>
      <div className="p-4 bg-white flex gap-4 border-t">
        <Input ref={messageContainer} disabled={loading} placeholder="Reply" className="flex-1" value={message} onChange={e => setMessage(e.target.value)} onPressEnter={handleSubmit}></Input>
        <Button icon={<SendOutlined/>} type="text" onClick={handleSubmit} loading={loading}>
          
        </Button>
      </div>
    </div>
  )
}

export default Chat