import { useEffect, useMemo, useState } from "react"
import { instance } from "utils/axios"
import Filter from './Filter'
import { Button, Input, Skeleton, Card, Modal, Form, Dropdown, Space, Tag } from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component';
import DocumentForm from "./PersonForm";
import { MoreOutlined } from '@ant-design/icons'

const { Search } = Input


const items = ({row, handleEdit}) => {
  return (
    [
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleEdit(row)
        }}>Edit</a>,
        key: -1
      },
      // {
      //   label: <a onClick={(e) => {
      //     e.preventDefault()
      //     handleEdit(row)
      //   }}>Delete</a>,
      //   key: 0
      // }
    ]
  )
}

const TenderApprovalPeople = () => {
  const [ data, setData ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const [ searchValue, setSearchValue ] = useState("")
  const [ hasMore, setHasMore ] = useState(true)
  const [ editData, setEditData ] = useState(null)
  const [ formModalOpen, setFormModalOpen ] = useState(false)
  const [ submitting, setSubmitting ] = useState(false)
  const [ form ] = Form.useForm()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `/TenderApprovalUser?wcode=tender-approval-list`
    }).then(res => {
      if(res.data.responseData.length > 0){
        setData(res.data.responseData)
      }
      else {
        setHasMore(false)
      }
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const fetchNext = () => {
    instance({
      method: 'get',
      url: `TenderApprovalUser?wcode=tender-approval-list`
    }).then(res => {
      if(res.data.responseData.length > 0) {
        setData(prev => [...prev, ...res.data.responseData])
      }
      else {
        setHasMore(false)
      }
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const handleFormFinish = (values) => {
    setSubmitting(true)
    if(editData) {
      instance({
        method: 'put',
        url: `TenderApprovalUser?wcode=tender-approval-list`,
        data: {
          id: editData.id,
          ...values
        }
      }).then(res => {
        fetchData()
        setFormModalOpen(false)
        form.resetFields()
      }).catch(err => {

      }).then(() => {
        setSubmitting(false)
      })
    }
    else {
      instance({
        method: 'post',
        url: `TenderApprovalUser?wcode=tender-approval-list`,
        data: values
      }).then(res => {
        setFormModalOpen(false)
        fetchData()
        form.resetFields()
      }).catch(err => {

      }).then(() => {
        setSubmitting(false)
      })
    }
  }

  const filteredData = useMemo(() => {
    return data
  }, [data])

  const handleEdit = (_row) => {
    setEditData(_row)
    setFormModalOpen(true)
  }

  const handleDelete = () => {

  }
  
  return (
    <div className="p-4">
      <div className="grid grid-cols-12 gap-3">
        {/* <div className="col-span-3">
          <Filter/>
        </div> */}
        <div className="col-span-12">
          <div className="mb-3">
            <Search 
              placeholder="Search" 
              size="large" 
              className='border-none' 
              enterButton 
              onChange={e => setSearchValue(e.target.value)} />
          </div>
          <div className="bg-white p-5 mb-3 font-semibold flex items-center justify-between">
            {filteredData.length} of {data.length} Results
            <div className='flex gap-2'>
              <Button onClick={() => setFormModalOpen(true)} type="primary">
                Add new person
              </Button>
            </div>
          </div>
          {
            loading ?
            <Skeleton></Skeleton>
            :
            <InfiniteScroll
              dataLength={data.length}
              next={fetchNext}
              loader={<div className="text-center font-bold">Fetching...</div>}
              className="flex flex-col gap-1"
              hasMore={hasMore}
              endMessage={<p className="text-center font-bold">No more document data</p>}>
              {
                filteredData.map((foo, fooindex) => {
                  return (
                    <Card className="rounded-none" key={`system-document-${fooindex}`} type="inner">
                      <div className="flex">
                        <div className="grow grid grid-cols-12 gap-x-2">
                          <div className="col-span-7 flex gap-4">
                            <div className='shrink-0 w-12 h-12 rounded-full flex justify-center items-center text-white bg-primary text-3xl'>
                              {foo.name.slice(0,1)}
                            </div>
                            <div className="grow">
                              <div className="text-primary truncate">{foo.name}</div>
                              <div className="text-mute">{foo.firstName} {foo.lastName}</div>
                            </div>
                          </div>
                          <div className="col-span-5 flex gap-10">
                            <div className="flex flex-col items-center">
                              <label className="text-mute">Bid Amount</label>
                              <div className="font-semibold">
                                {foo.amount.toLocaleString("en-US")}
                              </div>
                            </div>
                            <div className="flex flex-col items-center">
                              <label className="text-mute">SSE Amount</label>
                              <div className="font-semibold">
                                {foo.sseAmount?.toLocaleString("en-US")}
                              </div>
                            </div>
                            <div className="flex flex-col items-center">
                              <label className="text-mute">Status</label>
                              <Tag color={foo.status == 1 ? 'green' : 'red'}>
                                {foo.status == 1 ? 'Active' : 'Inactive'}
                              </Tag>
                            </div>
                          </div>
                        </div>
                        <Dropdown 
                          trigger={['click']} 
                          menu={{items: items({row: foo, handleEdit, handleDelete}),}}>
                          <a onClick={e => e.preventDefault()} className="flex justify-center items-center">
                            <Space className="px-3">
                              <MoreOutlined className='text-xl'/>
                            </Space>
                          </a>
                        </Dropdown>
                      </div>
                    </Card>
                  )
                })
              }
            </InfiniteScroll>
          }
        </div>
      </div>
      <Modal 
        title={editData ? 'Edit' : 'Add new'}
        open={formModalOpen}
        onOk={() => form.submit()}
        onCancel={() => {
          setFormModalOpen(false)
          setEditData(null)
        }}
        okText="Save"
        confirmLoading={submitting}>
        <DocumentForm form={form} editData={editData} handleFinish={handleFormFinish}/>
      </Modal>
    </div>
  )
}

export default TenderApprovalPeople