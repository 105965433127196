import { useState, useEffect } from 'react'
import { Form } from 'components'
import { Button, Form as AntForm, Card, Input, Select } from 'antd'
import { instance } from 'utils/axios'

const CompanyShareholders = ({state, data=[]}) => {
  const [ loading, setLoading ] = useState(false)
  const [ form ] = AntForm.useForm();

  useEffect(() => {
    form.setFieldsValue({companyShareholders: data})
  }, [data])
  
  return (
    <div className='relative'>
      <Form
        disabled={true}
        form={form}
        fields={[]}
        layout="vertical"
        onFinish={() => {}}
        className='gap-4'>  
        <AntForm.List name="companyShareholders">  
          {(fields, {add, remove}) => (
            <>
              {
                fields.map(({name, key, ...restFields}) => (
                  <Card title={`Shareholder ${key+1}`} className="col-span-12 rounded-none" size="small" key={key} {...restFields}>
                    <AntForm.Item label="Type" name={[name, "type"]} className='col-span-12'>
                      <Select></Select>
                    </AntForm.Item>
                    <AntForm.Item label="Firstname" name={[name, "firstName"]}>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="Lastname" name={[name, "lastName"]}>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="Job Title" name={[name, "address2"]}>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="Share percentage %" name={[name, "percent"]}>
                      <Input></Input>
                    </AntForm.Item>
                  </Card>
                ))
              }
            </>
          )}
        </AntForm.List>
      </Form>
      
    </div>
  )
}

export default CompanyShareholders