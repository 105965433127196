import { Modal, Form, Checkbox, Select, Button, Input } from "antd"
import { MainTable } from "components"
import { useState, useEffect } from "react"
import { instance } from "utils/axios"
import menus from 'routes/adminRoutes'

const fixMenu = (_menus) => {
  let tmp = []

  _menus.map(foo => {
    if(foo.children) {
      let result = fixMenu(foo.children)
      tmp = [...tmp, ...result]
    }
    if(foo.key) {
      tmp.push(foo)
    }
  })

  return tmp
}

const UserMenus = ({record}) => {
  const [ data, setData ] = useState([])
  const [ windows, setWindows ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const [ fetching, setFetching ] = useState(true)
  const [ open, setOpen ] = useState(false)
  const [ form ] = Form.useForm()
  const fixedMenus = fixMenu(menus)
  
  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `/UserPositionWindow/position/${record.id}`
    }).then(res => {
      setData(res.data.responseData)
      let tmp = [...fixedMenus].sort((a,b) => { return a.title.localeCompare(b.title, "en", {sensitivity: 'base'})})
      res.data.responseData.map((foo, fooIndex) => {
        let index = tmp.findIndex(_foo => _foo.key == foo.windowConfig)
        if(index == 0 || index) {
          tmp[index] = {
            ...tmp[index],
            ...foo,
            c: foo.c ? true: false,
            r: foo.r ? true: false,
            u: foo.u ? true: false,
            d: foo.d ? true: false,
            e: foo.e ? true: false,
            a: foo.a ? true: false,
          }
        }
      })
      form.setFieldsValue({windows: tmp})
    }).catch(err => {
      
    }).then(() => {
      setFetching(false)
      console.log(form.getFieldsValue())
    })
  }

  const handleSubmit = (values) => {
    let tmp = []
    values.windows.map((foo, fooindex) => {
      tmp.push({
        userPositionId: record.id,
        windowConfig: foo.key,
        c: foo.c ? 1: 0,
        r: foo.r ? 1: 0,
        u: foo.u ? 1: 0,
        d: foo.d ? 1: 0,
        e: foo.e ? 1: 0,
        a: foo.a ? 1: 0
      })
    })
    setLoading(true)

    instance({
      method: 'put',
      url: `/UserPositionWindow/bulk?wcode=user-position-list`,
      data: tmp
    }).then(res => {
      setOpen(false)
      fetchData()
    }).catch(err => {
      
    }).then(() => {
      setLoading(false)
      fetchData()
    })
  }
  
  return (
    <>
    <Form 
      preserve={false}
      loading={loading}
      form={form} 
      onFinish={handleSubmit} 
      disabled={loading}
      className='flex flex-col pl-10'
      layout="vertical">
      <Form.List name="windows">
        {(fields, { add, remove}) => fields.map((foo, fooIndex) => {
          return (
          <div className="grid grid-cols-12 items-center gap-10 border-b">
            <Form.Item name={[foo.name, "title"]} className="font-semibold mb-0 col-span-4">
              <Input className="border-none !bg-transparent !text-black font-semibold" disabled></Input>
            </Form.Item>
            <Form.Item label="Create" name={[foo.name, 'c']} className='col-span-1 mb-0' valuePropName="checked">
                <Checkbox></Checkbox>
            </Form.Item> 
            <Form.Item label="Read" name={[foo.name, 'r']} className='col-span-1 mb-0' valuePropName="checked">
                <Checkbox></Checkbox>
            </Form.Item>
            <Form.Item label="Update" name={[foo.name, 'u']} className='col-span-1 mb-0' valuePropName="checked">
                <Checkbox></Checkbox>
            </Form.Item>
            <Form.Item label="Delete" name={[foo.name, 'd']} className='col-span-1 mb-0' valuePropName="checked">
                <Checkbox></Checkbox>
            </Form.Item>
            <Form.Item label="Execute" name={[foo.name, 'e']} className='col-span-1 mb-0' valuePropName="checked">
                <Checkbox></Checkbox>
            </Form.Item>
            <Form.Item label="Approve" name={[foo.name, 'a']} className='col-span-1 mb-0' valuePropName="checked">
                <Checkbox></Checkbox>
            </Form.Item>
          </div>
          )
          
        })
        }
      </Form.List>
      <Form.Item className="mt-4 flex justify-end">
        <Button type="primary" htmlType="submit">Save</Button>
      </Form.Item>
    </Form>
    </>
  )
}

export default UserMenus