import { Upload, Button, Form } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import ls from 'utils/ls';
import { instance } from 'utils/axios';

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

export default () => {
  const userInfo = ls.get("userInfo");
  const token = userInfo && userInfo.jwtToken;
  
  return [
    {
      label: 'Title',
      name: 'title',
      rules: [{required: true, message: 'Title is required!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Description',
      name: 'description',
      type: 'textarea',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
        rows: 6
      }
    },

    {
      label: 'Starting price',
      name: 'price',
      type: 'number', 
      className: 'col-span-4 mb-0',
      inputProps: {
        className: 'w-full',
        formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        parser: (value) => value?.replace(/\$\s?|(,*)/g, '')
      }
    },
   
    {
      label: 'Start date',
      name: 'startDate',
      type: 'date',
      rules: [{required: true, message: 'Start date is required!'}],
      className: 'col-span-4 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'End date',
      name: 'endDate',
      type: 'date', 
      rules: [{required: true, message: 'End date is required!'}],
      className: 'col-span-4 mb-0',
      inputProps: {
        className: 'w-full',
      }
    }, 
    {
      label: 'Attachment file',
      type: 'component',
      className: 'col-span-12 mb-0',
      component: (
        <Form.Item name="fileName" label="Attachment file" className="col-span-12 mb-0" valuePropName="fileList" getValueFromEvent={normFile}>
          <Upload 
            name="file" 
            action={`${process.env.REACT_APP_MAIN_API_URL}File/upload`}
            headers={{
              Authorization: `Bearer ${token}`,
            }}
            multiple
            onRemove={(file) => {
              instance({
                method: 'delete',
                url: `File/remove`,
                data: file.response.responseData
              })
            }}>
            <Button icon={<UploadOutlined />}>Select file</Button>
          </Upload>
        </Form.Item>
      )
    },
    {
      label: 'Image',
      name: 'image',
      type: 'file',
      className: 'col-span-12 mb-0 w-full',
      inputProps: {
        className: 'w-full',
        accept: "image/*"
      }
    },
  ]
}