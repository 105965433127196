import { useState, useEffect, useMemo, useContext, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { instance } from 'utils/axios'
import { Skeleton, Input, Radio, Button, Divider, Card, Select, InputNumber, Tooltip, Upload, Modal, message, Form, Table } from 'antd'
import { LeftOutlined, UploadOutlined, InboxOutlined, CaretDownOutlined } from '@ant-design/icons'
import { AuthContext } from 'contexts'
import moment from 'moment'

const { Dragger } = Upload

const RenderInputByType = ({inputObject, value}) => {
  if(inputObject.editorTypeId == 3) {
    return (
      <>
        <Select disabled defaultValue={inputObject.supplierAnswer ? parseInt(inputObject.supplierAnswer) : 'False'} options={inputObject.options.map(option => ({label: option.name, value: parseInt(option.id)}))}>

        </Select> 
        {
          inputObject.supplierAttachment ?
          <a className="text-primary hover:underline" target='_blank' href={process.env.REACT_APP_CDN_URL + inputObject.supplierAttachment}>
            Attachment /{inputObject.supplierAttachment && inputObject.supplierAttachment.split(`\\`).at(-1)}/
          </a>
          :
          <></>
        }
      </>
    )
  }
  else if(inputObject.editorTypeId == 4) {
    return (
      <>
      <Radio.Group disabled value={value}>
        {
          inputObject.options.map((option, optionIndex) => {
            return (
              <Radio key={`question-${inputObject.answer.questionId}-${optionIndex}`} value={option.id}>{option.name}</Radio>
            )
          })
        }
      </Radio.Group>
      {
        inputObject.supplierAttachment ?
        <a className="text-primary hover:underline" target='_blank' href={process.env.REACT_APP_CDN_URL + inputObject.supplierAttachment}>
        Attachment /{inputObject.supplierAttachment && inputObject.supplierAttachment.split(`\\`).at(-1)}/
        </a>
        :
        <></>
      }
      </>
    )
  }
  else if(inputObject.editorTypeId == 1) {
    return (
      <>
      <InputNumber 
        className="w-full" 
        value={value} 
        disabled
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}>

      </InputNumber>
      {
        inputObject.supplierAttachment ?
        <a className="text-primary hover:underline" target='_blank' href={process.env.REACT_APP_CDN_URL + inputObject.supplierAttachment}>
        Attachment /{inputObject.supplierAttachment && inputObject.supplierAttachment.split(`\\`).at(-1)}/
        </a>
        :
        <></>
      }
      </>
    )
  }
  else if(inputObject.editorTypeId == 5) {
    return (
      <>
        <Input disabled value={value}>

        </Input>
        {
          inputObject.supplierAttachment ?
          <a className="text-primary hover:underline" target='_blank' href={process.env.REACT_APP_CDN_URL + inputObject.supplierAttachment}>
          Attachment /{inputObject.supplierAttachment && inputObject.supplierAttachment.split(`\\`).at(-1)}/
          </a>
          :
          <></>
        }
      </>
    )
  }
  else if(inputObject.editorTypeId == 6) {
    return (
      <>
      <Input.TextArea disabled value={value}>

      </Input.TextArea>
      {
        inputObject.supplierAttachment ?
        <a className="text-primary hover:underline" target='_blank' href={process.env.REACT_APP_CDN_URL + inputObject.supplierAttachment}>
        Attachment /{inputObject.supplierAttachment && inputObject.supplierAttachment.split(`\\`).at(-1)}/
        </a>
        :
        <></>
      }
      </>
    )
  }
  else if(inputObject.editorTypeId == 7) {
    return (
      <a className="text-primary hover:underline" target='_blank' href={process.env.REACT_APP_CDN_URL + inputObject.supplierAnswer}>
        File /{inputObject.supplierAnswer && inputObject.supplierAnswer.split(`\\`).at(-1)}/
      </a>
    )
    // return (
    //   <Upload disabled>

    //   </Upload>
    // )
  }
  else if(inputObject.editorTypeId == 9) {
    const parsedData = JSON.parse(inputObject.tableConfig)
    const parsedAnswers = JSON.parse(JSON.stringify(inputObject.supplierAnswer))
    
    return (
      <>
        <div className={`grid gap-2`} style={{gridTemplateColumns: `repeat(${parsedData.cols.length+1}, minmax(0, 1fr))`}}>
          <div></div>
          {
            parsedData.cols.map((foo, fooindex) => (
              <div className="text-xs text-[#85878E]" key={`col-${fooindex}`}>
                {foo}
              </div>
            ))
          }
          {/* fooindex*parsedData.cols.length */}
          {
            parsedData.rows.map((foo, fooindex) => (
              <div className="text-xs text-[#85878E]" key={`row-${fooindex}`} style={{gridRowStart: (fooindex+2)}}>
                {foo}
              </div>
            ))
          }
          {
            parsedData.rows.map((row, rowindex) => (
              parsedData.cols.map((col, colidnex) => {
                return (
                  <Input className="w-full" value={parsedAnswers ? parsedAnswers[`${row}@${col}`]: ''} disabled>

                  </Input>
                )
              })
            ))
          }
        </div>
        {
          inputObject.supplierAttachment ?
          <a className="text-primary hover:underline" target='_blank' href={process.env.REACT_APP_CDN_URL + inputObject.supplierAttachment}>
          Attachment /{inputObject.supplierAttachment && inputObject.supplierAttachment.split(`\\`).at(-1)}/
          </a>
          :
          <></>
        }
      </>
    )
  }
  else {
    return (
      <>
      <Input disabled={true} value={value}></Input>
      {
        inputObject.supplierAttachment ?
        <a className="text-primary hover:underline" target='_blank' href={process.env.REACT_APP_CDN_URL + inputObject.supplierAttachment}>
        Attachment /{inputObject.supplierAttachment && inputObject.supplierAttachment.split(`\\`).at(-1)}/
        </a>
        :
        <></>
      }
      </>
    )
  }
  
}

const QuestionsContainer = ({data, setData, onScoreChange, groupIndex, count, setCount, mainData}) => {
  const authContext = useContext(AuthContext)

  const renderAnswer = (answer) => {
    return <RenderInputByType key={`answer-${answer.id}`} inputObject={answer} value={answer.supplierAnswer}></RenderInputByType>
  }

  const handleFileChange = useCallback((info, foo, fooIndex) => {
    let newFileList = [...info.fileList];

    newFileList = newFileList.map((file) => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });
    setData(prev => {
      let tmp = Object.assign([], prev)
      tmp.answer[groupIndex].questions[fooIndex].fileName = newFileList
      return tmp
    })
    if(info.file.status == 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    }
  }, [])
  
  return (
    <div>
      {data.map((foo, fooIndex) => {
        if(foo.optionId == null && foo.parentId == null) {
          return (
            <div key={`question-${groupIndex}-${fooIndex}`} className='grid grid-cols-2 mb-8 gap-3'>
              <div className="flex flex-col gap-2">
                <label className="text-xs text-[#85878E]">{fooIndex+1}. {foo.title}</label>
                {renderAnswer(foo)}
              </div>
              {
                foo.isUseCalculation ?
                <div className="flex flex-col gap-4 justify-end">
                  <Radio.Group 
                    disabled={!(mainData.preQualificationInfo.status == 1 || mainData.preQualificationInfo.status == 5)}
                    className="mt-4" 
                    buttonStyle="solid" 
                    onChange={e => {
                      setData(prev => {
                        let tmp = Object.assign([], prev)
                        tmp.answer[groupIndex].questions[fooIndex].qualityStatus = e.target.value
                        return tmp
                      })
                    }}
                    value={foo.qualityStatus}
                    optionType="button">
                    <Radio.Button value={0} danger>Not prequalified</Radio.Button>
                    <Radio.Button value={1}>Needs improvement</Radio.Button>
                    <Radio.Button value={2}>Prequalified</Radio.Button>
                  </Radio.Group>
                  {
                    (foo.qualityStatus == 1 || foo.qualityStatus == 0) &&
                    <>
                      <Input.TextArea
                        disabled={!(mainData.preQualificationInfo.status == 1 || mainData.preQualificationInfo.status == 5)} 
                        placeholder='Comment' 
                        value={foo.comment}
                        onChange={e => setData(prev => {
                          let tmp = Object.assign([], prev)
                          tmp.answer[groupIndex].questions[fooIndex].comment = e.target.value
                          return tmp
                        })}>

                      </Input.TextArea>
                      <Upload 
                        disabled={!(mainData.preQualificationInfo.status == 1 || mainData.preQualificationInfo.status == 5)}
                        maxCount={1}
                        action={`${process.env.REACT_APP_MAIN_API_URL}File/upload`}
                        headers={{
                          Authorization: `Bearer ${authContext.state.token}`,
                        }}
                        onRemove={(file) => {
                          instance({
                            method: 'delete',
                            url: `File/remove`,
                            data: file.response? file.response.responseData : file.name
                          })
                        }}
                        fileList={typeof foo.fileName == "object" ? foo.fileName ? foo.fileName : [] : [{
                          uid: '-1',
                          name: foo.fileName,
                          status: 'done',
                          url: process.env.REACT_APP_CDN_URL+foo.fileName,
                        }]}
                        onChange={(info) => handleFileChange(info, foo, fooIndex)}>
                        <Button disabled={!(mainData.preQualificationInfo.status == 1 || mainData.preQualificationInfo.status == 5)}  icon={<UploadOutlined />}>Click to Upload</Button>
                      </Upload>
                    </>
                  }
                </div>
                : ''
              }
            </div>
          )
        }
        else {
          let found = data.find(_question => _question.id == foo.parentId)
          if(found && found.supplierAnswer == foo.optionId) {
            return (
              <div key={`question-${groupIndex}-${fooIndex}`} className='grid grid-cols-2 mb-8 gap-3'>
                <div className="flex flex-col gap-2">
                  <label className="text-xs text-[#85878E]">{fooIndex+1}. {foo.title}</label>
                  {renderAnswer(foo)}
                </div>
                {
                  foo.isUseCalculation ?
                  <div className='flex flex-col gap-4 justify-end'>
                    <Radio.Group 
                      disabled={!(mainData.preQualificationInfo.status == 1 || mainData.preQualificationInfo.status == 5)}
                      className="mt-4" 
                      buttonStyle="solid" 
                      onChange={e => {
                        setData(prev => {
                          let tmp = Object.assign([], prev)
                          tmp.answer[groupIndex].questions[fooIndex].qualityStatus = e.target.value
                          return tmp
                        })
                      }}
                      value={foo.qualityStatus}
                      optionType="button">
                      <Radio.Button value={0} danger>Not prequalified</Radio.Button>
                      <Radio.Button value={1}>Needs improvement</Radio.Button>
                      <Radio.Button value={2}>Prequalified</Radio.Button>
                    </Radio.Group>
                    {
                      (foo.qualityStatus == 1 || foo.qualityStatus == 0) &&
                      <>
                        <Input.TextArea 
                          disabled={!(mainData.preQualificationInfo.status == 1 || mainData.preQualificationInfo.status == 5)}
                          placeholder='Comment' 
                          value={foo.comment} 
                          onChange={e => setData(prev => {
                            let tmp = Object.assign([], prev)
                            tmp.answer[groupIndex].questions[fooIndex].comment = e.target.value
                            return tmp
                          })}>

                        </Input.TextArea>
                        <Upload
                          disabled={!(mainData.preQualificationInfo.status == 1 || mainData.preQualificationInfo.status == 5)} 
                          maxCount={1}
                          action={`${process.env.REACT_APP_MAIN_API_URL}File/upload`}
                          headers={{
                            Authorization: `Bearer ${authContext.state.token}`,
                          }}
                          onRemove={(file) => {
                            instance({
                              method: 'delete',
                              url: `File/remove`,
                              data: file.response? file.response.responseData : file.name
                            })
                          }}
                          fileList={typeof foo.fileName == "object" ? foo.fileName ? foo.fileName : [] : [{
                            uid: '-1',
                            name: foo.fileName,
                            status: 'done',
                            url: process.env.REACT_APP_CDN_URL+foo.fileName,
                          }]}
                          onChange={(info) => handleFileChange(info, foo, fooIndex)}>
                          <Button disabled={!(mainData.preQualificationInfo.status == 1 || mainData.preQualificationInfo.status == 5)}  icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload>
                      </>
                    }
                  </div>
                  : ''
                }
              </div>
            )
          }
        }
      })}
      {/* <Button className="w-full" type="primary" onClick={() => mainData.answer.length == groupIndex+1 ? handleSubmit() : setTabIndex(groupIndex+1)}>{mainData.answer.length == groupIndex+1 ? "Save" : "Next" }</Button> */}
    </div>
  )
}

const PrequalificationEvaluate = () => {
  const [ data, setData ] = useState({
    answer: [],
    preQualificationInfo: {
      status: 0
    }
  })
  const [ loading, setLoading ] = useState(true)
  const location = useLocation()
  const navigate = useNavigate()
  const [ submit, setSubmit ] = useState(false)
  const [ tabIndex, setTabIndex ] = useState(0)
  const [ rejectModalOpen, setRejectModalOpen ] = useState(false)
  const [ form ] = Form.useForm()  
  const [ count, setCount ] = useState(0)
  const authContext = useContext(AuthContext)

  useEffect(() => {
    fetchQuestions()
  }, [])

  const fetchQuestions = () => {
    instance({
      method: 'get',
      url: `/SupplierPreQualification/${location.state.supplier.id}?wcode=prequalification-detail`
    }).then(res => {
      if(res.data.responseData.answer) {
        setCount(prev => {
          let tmp = 0
          res.data.responseData.answer.map((_group, fooIndex) => {
            _group.questions.map((_question) => {
              if(_question.optionId == null && _question.parentId == null && _question.isUseCalculation) {
                tmp += 1
              }
              else {
                let found = _group.questions.find(_innerquestion => _innerquestion.id == _question.parentId)
                if(found && found.supplierAnswer == _question.optionId && _question.isUseCalculation) {
                  tmp += 1
                }
              }
            })
          })
          return tmp
        })
      }
      setData(res.data.responseData)
    }).catch(err => {
      console.log(err)
    }).then(() => {
      setLoading(false)
    })
  }

  const handleScoreChange = (_data) => {

  }

  const handleSubmit = (status) => {
    setSubmit(true)
    let tmp = []

    data.answer.map((group, groupIndex) => {
      group.questions.map((question, questionIndex) => {
        if(typeof question != undefined && data.answer[groupIndex].questions[questionIndex].supplierAnswerId) {
          tmp.push({
            point: question.supplierPoint,
            percent: question.supplierPercent,
            id: question.supplierAnswerId,
            comment: question.comment,
            fileName: question.fileName
          })
        }
      })
    })

    instance({
      method: 'put',
      url: `/SupplierPreQualification?wcode=prequalification-detail`,
      data: {
        status: status,
        supplierId: location.state.supplier.id,
        preQualificationQialifyAnswerModels: tmp
      }
    }).then(res => {
      navigate(-1)
    }).catch(err => {
      
    }).then(() => {
      setSubmit(false)
    })
  }
  
  const handleFinish = (values) => {
    setSubmit(true)
    let details = []

    data.answer.map((group) => {
      group.questions.map(question => {
        if(question) {
          details.push({
            id: question.supplierAnswerId,
            status: question.status,
            comment: question.comment,
            qualityStatus: question.qualityStatus,
            fileName: typeof question.fileName == "string" ? question.fileName : question.fileName ? question.fileName[0].response.responseData : ''
          })
        }
      }) 
    })

    instance({
      method: 'put',
      url: `/SupplierPreQualification?wcode=audit-qualification-detail`,
      data: {
        status: values.resultStatus,
        supplierId: location.state.supplier.id,
        renewDay: values.renewDay,
        report: values.report && values.report.file.response.responseData,
        // improvePlan: values.improvePlan && values.improvePlan.file.response.responseData,
        preQualificationQialifyAnswerModels: details
      }
    }).then(res => {
      navigate("/prequalification")
    }).catch(err => {
      
    }).then(() => {
      setSubmit(false)
    })
  }

  const menu = useMemo(() => {
    let tmp = []
    data.answer.map((foo, fooIndex) => {
      const _supplierScore = foo.questions.reduce((a,b) => b.supplierPoint ? a+parseInt(b.supplierPoint) : a , 0)
      tmp.push({
        label: <span>{foo.name} </span>,
        score: `${_supplierScore}/${foo.qualifyScore ? foo.qualifyScore : 0}`,
        key: fooIndex,
        children: (
          <Card title={foo.name} size="small">
            <QuestionsContainer setCount={setCount} groupIndex={fooIndex} data={foo.questions} setData={setData} setTabIndex={setTabIndex} mainData={data}></QuestionsContainer>
          </Card>
        )
      })
    })
    return tmp
  }, [data])
  
  const setImprovementPlan = () => {
    let tmp = []

    data.answer.map(foo => {
      foo.questions.map(_question => {
        if(_question.isUseCalculation) {
          tmp.push(_question)
        }
      })
    })

    let res = ''
    tmp.map((foo, fooIndex) => {
      if(foo.comment) {
        res += `${fooIndex != 0 ? "\n" : ""}${fooIndex+1}. ${foo.comment}`
      }
    })
    form.setFieldValue("improvePlan", res)
  }
  
  return (
    <div className="p-4 grid grid-cols-12 gap-3 relative">
      <Card  className="col-span-12" size="small">
        <div className="flex flex-col gap-3 h-full">
          {
            loading ?
            <Skeleton active/>
            :
            <div className="flex items-center gap-3">
              <div className="w-12 h-12 rounded-full flex justify-center items-center text-white bg-primary text-3xl">
                {location.state?.supplier?.name.slice(0,1)}
              </div>
              <div className="grow">
                <div className='text-xl font-bold'>
                  {location.state?.supplier?.name}
                </div>
                <div className="text-mute">
                  ID: {location.state?.supplier?.id}
                </div>
              </div>
            </div>
          }
          {/* <div className="flex flex-col grow">
            {
              menu.map((foo, fooindex) => {
                return (
                  <div 
                    key={`menu-${fooindex}`} 
                    className="py-3 text-left hover:text-primary transition-all hover:bg-slate-200 px-3 flex items-center gap-2 rounded cursor-pointer"
                    onClick={() => setTabIndex(fooindex)}>
                    <div className={`grow font-medium ${tabIndex == fooindex && `text-primary`}`}>
                      {fooindex+1}. {foo.label}
                    </div>
                    <CaretDownOutlined className={`text-black/40 transition-all  ${tabIndex == fooindex && `!text-primary -rotate-90`}`}/>
                  </div>
                )
              })
            }
          </div> */}
        </div>
      </Card>
      <div className="col-span-12 relative">
        {
          loading  ?
          <Skeleton active></Skeleton>
          :
          <div className="flex flex-col gap-3 mb-3">
            {menu.map((foo, fooIndex) => {
              return foo.children
            })}
          </div>
        }
        <Card className="sticky bottom-3 w-full" size="small">
          <div className="flex justify-between items-center gap-3">
            <div className="flex gap-3">
              <div className="font-medium">
                Total: {count} evaluation questions
              </div>
              <div className="font-medium text-primary">
                Prequalified: {data.answer.reduce((a,b) => a+b.questions.filter(foo => foo.qualityStatus == 2).length, 0)}
              </div>
              <div className="font-medium text-yellow-500">
                Needs improvement: {data.answer.reduce((a,b) => a+b.questions.filter(foo => foo.qualityStatus == 1).length, 0)}
              </div>
              <div className="font-medium text-red-600">
                Not prequalified: {data.answer.reduce((a,b) => a+b.questions.filter(foo => foo.qualityStatus == 0).length, 0)}
              </div>
            </div>
            <div className="flex gap-3">
              {
                data.answer.reduce((a,b) => a+b.questions.filter(foo => foo.qualityStatus == 2).length, 0) == count ?
                <Button disabled={!(data.preQualificationInfo.status == 1 || data.preQualificationInfo.status == 5)} className="w-full" type="primary" onClick={() =>{
                  //  handleSubmit(2)
                  form.setFieldValue("resultStatus", 2)
                  form.setFieldValue("renewDay", 730)
                  setRejectModalOpen(true)
                }} loading={submit}>Prequalified</Button>
                :
                <>
                <Button disabled={!(data.preQualificationInfo.status == 1 || data.preQualificationInfo.status == 5)} className="w-full !bg-yellow-500 !border-yellow-500 hover:bg-yellow-500/90 hover:border-yellow-500/90" type="primary" onClick={() => {
                  // handleSubmit(1)
                  form.setFieldValue("resultStatus", 4)
                  form.setFieldValue("renewDay", 90)
                  setImprovementPlan()
                  setRejectModalOpen(true)
                }} loading={submit}>Needs improvement</Button>
                <Button disabled={!(data.preQualificationInfo.status == 1 || data.preQualificationInfo.status == 5)} className="w-[120px]" type="primary" danger onClick={() => {
                  // handleSubmit(0)
                  form.setFieldValue("resultStatus", 3)
                  form.setFieldValue("renewDay", 30)
                  setImprovementPlan()
                  setRejectModalOpen(true)
                }} loading={submit}>Not prequalified</Button>
                </>
              }
            </div>
          </div>
        </Card>
      </div>
      <Modal
        title="Improvement duration"
        open={rejectModalOpen}
        onCancel={() => {
          form.resetFields()
          setRejectModalOpen(false)
        }}
        onOk={form.submit}
        width={"75%"}
        style={{top: '5%'}}
        okText={"Save"}>
        <Form
          layout='vertical'
          form={form}
          onFinish={handleFinish}>
          <Form.Item name="resultStatus" className='hidden'><Input/></Form.Item>
          <Form.Item name="renewDay" label="Duration /days/" rules={[{required: true, message: 'Improvement duration is required!'}]}>
            <InputNumber className="w-full"/>
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prev, curr) => prev.resultStatus != curr.resultStatus}>
            {({getFieldValue}) => {
              if(getFieldValue('resultStatus') != 2) {
                return (
                  <Form.Item name="improvePlan" label="Improvement plan file" className="col-span-12">
                    <Input.TextArea autoSize={true}>

                    </Input.TextArea>
                  </Form.Item>
                )
              }
            }}
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prev, curr) => prev.resultStatus != curr.resultStatus}>
            {({getFieldValue}) => {
              if(getFieldValue('resultStatus') != 2) {
                return (
                  <>
                  <label className="pb-2">Report file</label>
                  <div className='grid grid-cols-2 border divide-x divide-y'>
                    <div className="px-3 py-1">Company name (English)</div>
                    <div className="px-3 py-1">{location.state.enName}</div>
                    <div className="px-3 py-1">Pre-qualification status</div>
                    <div className="font-bold px-3 py-1">{form.getFieldValue('resultStatus') == 2 ? 'Prequalified' : form.getFieldValue('resultStatus') == 3 ? 'Not prequalified' : 'Needs improvement'}</div>
                    <div className="px-3 py-1">Pre-qualification start date</div>
                    <div className="px-3 py-1">{moment().format("YYYY-MM-DD")}</div>
                    {data.answer.map((_group, _groupIndex) => {
                      return (
                        <div key={`_group-${_groupIndex}`} className="grid grid-cols-3 col-span-2 divide-x divide-y">
                          <div className='px-3 py-1 bg-orange-500 text-white'>{_groupIndex+1}. {_group.name}</div>
                          <div className='px-3 py-1 bg-orange-500 text-white'>Supplier`s response</div>
                          <div className='px-3 py-1 bg-orange-500 text-white'>Pre-qualification assessment</div>
                          {
                            _group.questions.map((foo, fooIndex) => {
                              return (
                                <div key={`_group-${_groupIndex}-question-${fooIndex}`} className="col-span-3 grid grid-cols-3 divide-x divide-y">
                                  <div className='px-3 py-1'>{foo.title}</div>
                                  <div className='px-3 py-1'>
                                    <RenderInputByType inputObject={foo} value={foo.supplierAnswer}></RenderInputByType>
                                  </div>
                                  <div className='px-3 py-1'>{foo.isUseCalculation == 1 ? foo.qualityStatus == 2 ? 'Prequalified' : foo.qualityStatus == 1 ? 'Needs improvement' : 'Not prequalified' : ''}</div>
                                </div>
                              )
                            })
                          }
                        </div>
                      )
                    })}
                  </div>
                  </>
                )
              }
            }}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default PrequalificationEvaluate