import { Button, Card, Table } from "antd"
import { useEffect, useState } from "react"
import { useOutletContext, useParams } from "react-router-dom"
import { instance } from "utils/axios"
import { expandedDetailColumns, suppliersColumns } from "./columns"

const Award = () => {
  const [ data, setData ] = useState({
    suppliers: []
  })
  const [ loading, setLoading ] = useState(false)
  const [ outletData ] = useOutletContext()
  const { id } = useParams()
  
  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `/Tender/${outletData.tendertype}/award/${id}`
    }).then(res => {
      setData(res.data.responseData)
    })
  }
  
  const handleAward = () => {
    setLoading(true)
    instance({
      method: 'post',
      url: `tender/${outletData.tendertype}/award`,
      data: {
        tenderId: id
      }
    }).then(() => {
      
    }).catch(() => {
      
    }).then(() => {
      setLoading(false)
      outletData.fetchData()
    })
  }
  
  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-end">
        <Button type="primary" loading={loading} onClick={handleAward}>Award</Button>
      </div>
      <Card title="Winners" size="small" bodyStyle={{padding: 0}}>
        <Table
          columns={suppliersColumns()}
          dataSource={data.suppliers.filter(foo => foo.details.length > 0)}
          pagination={false}
          rowKey={(row, index) => {
            return row.id
          }}
          expandable={{
            expandedRowRender: rec => {
              return (
                <div>
                  <Table
                    columns={expandedDetailColumns}
                    dataSource={rec.details}
                    pagination={false}
                    size="small">

                  </Table>
                </div>
              )
            }
          }}
          size="small">
          
        </Table>
      </Card>
      <Card title="Others" size="small" bodyStyle={{padding: 0}}>
        <Table
          columns={suppliersColumns()}
          dataSource={data.suppliers.filter(foo => foo.details.length == 0)}
          pagination={false}
          rowKey={(row, index) => {
            return row.id
          }}
          size="small">
          
        </Table>
      </Card>
    </div>
  )
}

export default Award