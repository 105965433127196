import React, { useEffect, useMemo, useState } from 'react'
import { Form as AntForm, Input, Skeleton, Card, Tooltip, Tag } from 'antd'
import { instance } from 'utils/axios'
import { Link } from 'react-router-dom'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component';

const { Search } = Input

const statusEnum = {
  '-1': {
    title: 'Draft',
    color: '#F09D51'
  },
  0: {
    title: 'Published',
    color: '#427EC1'
  },
  1: {
    title: 'Open',
    color: '#009A72'
  },
  2: {
    title: 'Reopened',
    color: '#009A72'
  },
  3: {
    title: 'Closed',
    color: '#D55727'
  },
  4: {
    title: 'Awarded',
    color: '#009A72'
  },
  5: {
    title: 'Finished',
    color: '#009A72'
  },
  6: {
    title: 'Postponed',
    color: '#F09D51'
  },
  7: {
    title: 'Declined',
    color: '#D55727'
  },
  8: {
    title: 'In evaluation',
    color: '#29ABE2'
  },
  9: {
    title: 'Approved',
    color: '#009A72'
  },
  10: {
    title: 'Pending approval',
    travel: 'Booked',
    color: '#F09D51'
  },
  11: {
    title: 'Canceled',
    color: '#D55727'
  },
}

function TenderTypeContainer({ type, filter }) {
  const [ data, setData ] = useState([])
  const [ page, setPage ] = useState(1)
  const [ hasMore, setHasMore ] = useState(true)
  const [ loading, setLoading ] = useState(false)
  const [ templateList, setTemplateList ] = useState([])
  const [ fetching, setFetching ] = useState(false)
  const [ products, setProducts ] = useState([])
  const [ users, setUsers ] = useState([])
  const [ searchValue, setSearchValue ] = useState('')
  const [ form ] = AntForm.useForm();
  
  useEffect(() => {
    setPage(1)
    setHasMore(true)
    fetchData()
  },[type, filter])

  useEffect(() => {
    fetchProducts()
    fetchUsers()
  }, [])

  const fetchUsers = () => {
    instance({
      method: 'get',
      url: `/User?wcode=tender`,
    }).then((res) => {
      setUsers(res.data.responseData)
    }).catch((err) => {
    })
  }

  const fetchProducts = () => {
    instance({
      method: 'get',
      url: `/ProductType?wcode=tender`
    }).then((res) => {
      setProducts(res.data.responseData)
    }).catch((err) => {
    })
  }

  const fetchData = () => {
    setLoading(true)
    instance({
      method: 'get',
      url: `/TenderApproval/rfq`,
      params: {
        wcode: 'tender',
        page: page,
        pageSize: 20,
        ...filter
      }
    }).then((res) => {
      if(res.data.responseData.length < 20) {
        setHasMore(false)
      }
      setData(res.data.responseData)
      setPage(page+1)
    }).catch((err) => {
    }).then(() => setLoading(false))
  }

  const fetchNext = () => {
    instance({
      method: 'get',
      url: `/TenderApproval/rfq`,
      params: {
        wcode: 'tender',
        page: page,
        pageSize: 20,
        ...filter
      }
    }).then((res) => {
      if(res.data.responseData.length < 20) {
        setHasMore(false)
      }
      setData(prev => ([...prev, ...res.data.responseData]))
      setPage(page+1)
    }).catch((err) => {
    })

  }

  // render: (text, row) => (
  //   fetching ?
  //   <Spin>
      
  //   </Spin>
  //   :
  //   <Dropdown 
  //     trigger={['click']} 
  //     menu={{items: items({row, handleEdit, handleDelete, handlePublish}),}}>
  //     <a onClick={e => e.preventDefault()}>
  //       <Space>
  //         <MoreOutlined className='text-xl'/>
  //       </Space>
  //     </a>
  //   </Dropdown>
  // )
  const onSearch = () => {
    
  }

  const filteredData = useMemo(() => {
    return data.filter(foo => foo.name.toLowerCase().includes(searchValue.toLowerCase()))
  }, [data, searchValue])
  
  return (
    <div className='flex flex-col'>
      <div className="mb-3">
        <Search 
          placeholder="Search" 
          size="large" 
          className='border-none' 
          onSearch={onSearch} 
          enterButton 
          onChange={e => setSearchValue(e.target.value)} />
      </div>
      <div className="bg-white mb-3 font-semibold flex items-center justify-between">
        {filteredData.length} of {data.total} Results
        <div className='flex gap-2'>

        </div>
      </div>
      <InfiniteScroll
        dataLength={data.data? data.data.length : 0}
        next={fetchNext}
        loader={<div className='text-center font-bold'>Fetching...</div>}
        className="flex flex-col gap-1 overflow-x-hidden"
        hasMore={hasMore}
        endMessage={
          <p className="text-center font-bold">
            {/* No more tender data */}
          </p>
        }>
        <table className="oyu-table">
          <colgroup>
            <col style={{width: '50px'}}></col>
            <col style={{width: '100px'}}></col>
            <col></col>
            <col style={{width: '100px'}}></col>
            <col style={{width: '100px'}}></col>
            <col style={{width: '200px'}}></col>
            <col style={{width: '200px'}}></col>
            <col style={{width: '50px'}}></col>
            <col style={{width: '50px'}}></col>
          </colgroup>
          <thead>
            <tr>
              <th style={{textAlign: 'left'}}>#</th>
              <th>Bid #</th>
              <th style={{textAlign: 'left'}}>Description</th>
              <th>Submitted</th>
              <th>Status</th>
              <th>Publish date</th>
              <th>Close date</th>
              <th>Buyer name</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              filteredData.map((foo, fooIndex) => {
                return (
                  <tr key={`tender-${fooIndex}`} className="hover:bg-gray-100 transition-all">
                    <td>
                      {fooIndex+1}
                    </td>
                    <td style={{textAlign: 'center'}}>{foo.code}</td>
                    <td>
                      <Link 
                        className="text-primary cursor-pointer hover:underline" 
                        to={`${foo.id}`} 
                        state={{...foo, type: type}}>
                        <div className="truncate">
                          <Tooltip title={foo.name}>
                            <span className="truncate">
                              {foo.name}
                            </span>
                          </Tooltip>
                        </div>
                      </Link>
                    </td>
                    <td style={{textAlign: 'center'}}>{foo.suppliers && `${foo.suppliers.joinCount}/${foo.suppliers.invitedCount}`}</td>
                    <td>
                      <Tag className="whitespace-normal w-[120px] text-center" color={statusEnum[foo.status].color}>
                        {type == 3 ?  statusEnum[foo.status].travel ?? statusEnum[foo.status].title : statusEnum[foo.status].title}
                      </Tag>
                    </td>
                    <td className='text-center'>{moment(foo.startDate).format("YYYY/MM/DD HH:mm")}</td>
                    <td className='text-center'>{moment(foo.endDate).format("YYYY/MM/DD HH:mm")}</td>
                    <td className='text-nowrap'>{foo.firstName} {foo.lastName}</td>
                    <td></td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>

      </InfiniteScroll>
      {/* <MainTable
        borderHide
        dataTable={data}
        columns={columns}
        setCurrentPage={setCurrentPage}
        loading={loading}
        pagination={true}
        handleAdd={handleAdd}
      /> */}
    </div>
  )
}


export default TenderTypeContainer
