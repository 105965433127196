import { DatePicker, Form, Select, Table, Transfer, TreeSelect } from "antd"
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { instance } from "utils/axios";

const SurveyForm = ({setModal, form}) => {
  const [ fetching, setFetching ] = useState(true)
  const [ surveys, setSurveys ] = useState([])
  const { id } = useParams()
  
  
  useEffect(() => {
    instance({
      method: 'get',
      url: '/survey/type/2?wcode=master',
    }).then((res) => {
      if(res.data.responseData.length > 0) {
        setSurveys(res.data.responseData)
      }
    }).catch((err) => {
    })
  }, [])
  
  const handleFinish = (values) => {
    instance({
      method: 'post',
      url: `/TenderFeedback?wcode=tender-detail`,
      data: {
        surveyId: values.surveyId,
        tenderId: id
      }
    }).then(() => {
      setModal(false)
    })
  }
  
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleFinish}
      className="grid grid-cols-12 gap-x-3">
      <Form.Item label="Survey" name="surveyId" className="col-span-12" rules={[{required: true, message: 'Select survey!'}]}>
        <Select options={surveys.map((foo) => ({value: foo.id, label: foo.name}))} placeholder="Select survey"></Select>
      </Form.Item>
    </Form>
  )
}

export default SurveyForm