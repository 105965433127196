import {
  BiUserCheck,
  BiUser,
  BiIdCard,
  BiCoin,
  BiPieChartAlt,
  BiCog,
  BiUserPin,
  BiBaguette,
  BiListCheck,
  BiBookBookmark,
  BiCalendarPlus,
  BiDish,
  BiReceipt,
  BiCalendarEdit,
  BiCalendar,
  BiBookReader,
  BiMessageEdit,
  BiSupport ,
  BiFile,
  BiBody,
  BiSolidBookReader, 
  BiDesktop,
  BiInfoCircle ,
  BiShield ,
  BiListUl ,
  BiSelection,
  BiQuestionMark,
  BiCategoryAlt,
  BiWindows,
  BiSpreadsheet,
  BiMessage,
  BiArchive,
} from "react-icons/bi";
import { RiAuctionFill, RiAuctionLine, RiBitCoinFill  } from "react-icons/ri";
import { HiTemplate } from "react-icons/hi";
import { TbTemplate } from "react-icons/tb";
import { PiHandCoins } from "react-icons/pi";
import { Link } from "react-router-dom";
import manualPDF from '../assets/file/ttt-guide.pdf'
import { FaBoxes } from "react-icons/fa";

export default [
  {
    label: <Link to='/'>Dashboard</Link>,
    icon: <BiPieChartAlt size={16} className="inline text-primary" />,
    key: 'dahboard',
  },
  {
    label: 'Supplier managment',
    role: [1004, 2, 3, 4, 5, 9, 10, 1002],
    icon: <BiSelection size={16} className="inline text-primary" />,
    children: [
      {
        label: <Link to='/supplier'>List</Link>,
        key: 'supplier',
        role: [1004, 2, 3, 4, 5, 9, 10, 1002],
        icon: <BiListUl  size={16} className="inline text-primary" />,
      },
      {
        label: <Link to='/supplier-validation'>Data verification</Link>,
        key: 'supplier-validation',
        role: [1004],
        icon: <BiShield  size={16} className="inline text-primary" />,
      },
      {
        label: <Link to='/prequalification'>Pre-qualification</Link>,
        key: 'prequalification',
        role: [1004],
        icon: <BiBookReader size={16} className="inline text-primary" />,
      },
      {
        label: <Link to='/audit-qualification'>Qualification</Link>,
        key: 'audit-qualification',
        role: [1004],
        icon: <BiListCheck  size={16} className="inline text-primary" />,
      }
    ]
  },
  {
    label: 'Tender management',
    role: [2, 3, 4, 5, 6, 9, 1002, 8],
    icon: <BiCoin size={16} className="inline text-primary" />,
    children: [
      {
        label: <Link to='/tender'>Tender</Link>,
        key: 'tender',
        role: [2, 3, 4, 5, 1002, 8],
        icon: <PiHandCoins size={16} className="inline text-primary" />,
      },
      {
        label: <Link to='/tender-end-user'>Tender /End-user/</Link>,
        key: 'tender-end-user',
        role: [6],
        icon: <PiHandCoins size={16} className="inline text-primary" />,
      },
      {
        label: <Link to='/tender-approval'>RFQ Approval</Link>,
        key: 'tender-approval',
        role: [1002, 9, 8],
        icon: <PiHandCoins size={16} className="inline text-primary" />,
      },
      {
        label: <Link to='/tender-approval-freight'>Freight Approval</Link>,
        key: 'tender-approval-freight',
        role: [1003],
        icon: <PiHandCoins size={16} className="inline text-primary" />,
      },
      {
        label: <Link to='/auction'>Auction</Link>,
        key: 'auction',
        role: [3],
        icon: <RiAuctionFill size={16} className="inline text-primary" />,
      },
    ]
  },
  {
    type: 'divider'
  },
  {
    label: 'Settings',
    role: [1],
    icon: <BiCog size={16} className="inline text-primary" />,
    children: [      
      {
        label: <Link to='/event-template'>Event template</Link>,
        key: 'template',
        icon: <TbTemplate size={16} className="inline text-primary" />,
      },
      {
        label: <Link to='/tender-template'>Tender template</Link>,
        key: 'tender-template',
        icon: <HiTemplate  size={16} className="inline text-primary" />,
      },
      {
        label: <Link to='/prequalification-group-config'>Prequalification config</Link>,
        key: 'prequalification-group-config',
        icon: <BiSolidBookReader size={16} className="inline text-primary" />,
      },
      {
        label: <Link to='/company-question-group-config'>Company question config</Link>,
        key: 'company-question-group-config',
        icon: <BiQuestionMark  size={16} className="inline text-primary" />,
      },
      {
        label: <Link to='/audit-config'>Audit config</Link>,
        key: 'audit-config',
        icon: <BiListCheck size={16} className="inline text-primary" />,
      },
      {
        label: <Link to='/producttype'>Product type</Link>,
        key: 'product-type',
        icon: <BiCategoryAlt  size={16} className="inline text-primary" />,
      },
      {
        label: <Link to='/supplier-tier'>Supplier tier</Link>,
        key: 'supplier-tier',
        icon: <FaBoxes   size={16} className="inline text-primary" />,
      },
      {
        label: <Link to='/system-documents'>System documents</Link>,
        key: 'system-documents',
        icon: <BiFile size={16} className="inline text-primary" />,
      },
      {
        label: <Link to='/place-of-delivery'>Place of delivery</Link>,
        key: 'place-of-delivery',
        icon: <BiFile size={16} className="inline text-primary" />,
      },
      {
        label: <Link to='/prnumber'>PRNumber</Link>,
        key: 'prnumber',
        icon: <BiFile size={16} className="inline text-primary" />,
      },
      {
        label: <Link to='/material-number'>Material number</Link>,
        key: 'material-number',
        icon: <BiFile size={16} className="inline text-primary" />,
      }
    ]
  },
  {
    label: 'Report',
    role: [1],
    icon: <BiArchive size={16} className="inline text-primary" />,
    children: [
      {
        label: <Link to='/coming-soon'>Tender report</Link>,
        key: 'tender-approval-people',
        icon: <BiUserCheck  size={16} className="inline text-primary" />,
      },
      {
        label: <Link to='/coming-soon'>Supplier report</Link>,
        key: 'user',
        icon: <BiUser size={16} className="inline text-primary" />,
      },
      {
        label: <Link to='/coming-soon'>Audit report</Link>,
        key: 'user-positions',
        icon: <BiUserPin size={16} className="inline text-primary" />,
      },
    ]
  },
  {
    label: 'User management',
    role: [1],
    icon: <BiUser size={16} className="inline text-primary" />,
    children: [
      {
        label: <Link to='/tender-approval-people'>Tender approval people</Link>,
        key: 'tender-approval-people',
        icon: <BiUserCheck  size={16} className="inline text-primary" />,
      },
      {
        label: <Link to='/users'>User</Link>,
        key: 'user',
        icon: <BiUser size={16} className="inline text-primary" />,
      },
      {
        label: <Link to='/user-positions'>User positions</Link>,
        key: 'user-positions',
        icon: <BiUserPin size={16} className="inline text-primary" />,
      },
    ]
  },
  {
    label: 'Feedback & Survey',
    role: [1],
    icon: <BiMessageEdit size={16} className="inline text-primary" />,
    children: [
      {
        label: <Link to='/survey'>Survey</Link>,
        key: 'survey',
        icon: <BiSpreadsheet  size={16} className="inline text-primary" />,
      },
      {
        label: <Link to='/survey-suppliers'>Take survey</Link>,
        key: 'survey-suppliers',
        icon: <BiSpreadsheet  size={16} className="inline text-primary" />,
      },
      // {
      //   label: <Link to='/tender-feedback'>Tender feedback</Link>,
      //   key: 'tender-feedback',
      //   icon: <BiSpreadsheet  size={16} className="inline text-primary" />,
      // },
    ]
  }, 
  {
    label: <Link to='/support-hub'>Support Hub</Link>,
    role: [1],
    icon: <BiSupport  size={16} className="inline text-primary" />,
    // children: [
    //   {
    //     label: <Link to='/support-hub'>Support Hub</Link>,
    //     key: 'supportHub',
    //     icon: <BiSupport   size={16} className="inline text-primary" />,
    //   },
    //   {
    //     label: <Link to='/message'>Message</Link>,
    //     key: 'message',
    //     icon: <BiMessage size={16} className="inline text-primary" />,
    //   },
    // ]
  },
  {
    label: `Compliance team`,
    role: [1],
    icon: <BiSupport  size={16} className="inline text-primary" />,
    children: [
      {
        label: <Link to='/coi'>COI</Link>,
        key: 'coi',
        role: [1],
        icon: <PiHandCoins size={16} className="inline text-primary" />,
      },
      {
        label: <Link to='/company-relations'>Company relations</Link>,
        key: 'company-relations',
        role: [1],
        icon: <PiHandCoins size={16} className="inline text-primary" />,
      },
      {
        label: <Link to='/due-diligence'>Due diligence</Link>,
        key: 'due-diligence',
        role: [1],
        icon: <PiHandCoins size={16} className="inline text-primary" />,
      },
    ]
  }
]
