import { useState, useEffect, useMemo, useContext } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { instance } from 'utils/axios'
import { Skeleton, Input, Radio, Button, Divider, Card, Select, InputNumber, Modal, Tooltip, Form, Upload, message } from 'antd'
import { LeftOutlined, UploadOutlined, InboxOutlined } from '@ant-design/icons'
import ls from 'utils/ls';
import { AuthContext } from 'contexts'

const { TextArea } = Input;
const { Dragger } = Upload

const RenderInputByType = ({inputObject, value}) => {
  const [fileList, setFileList] = useState([]);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  if (inputObject.editorTypeId == 3) {
    return (
      <div className="font-semibold">
        {inputObject.option.find(foo => foo.id == inputObject.answer)?.name}
        {/* <Select 
          disabled 
          defaultValue={parseInt(inputObject.answer)} 
          options={inputObject.option.map(option => ({
            label: option.name,
            value: parseInt(option.id)
          }))} 
        /> */}
      </div>
    )
  }
  else if(inputObject.editorTypeId == 4) {
    return (
      <Radio.Group disabled value={value}>
        {
          inputObject.options.map((option, optionIndex) => {
            return (
              <Radio key={`question-${inputObject.id}-${optionIndex}`} value={option.id}>{option.name}</Radio>
            )
          })
        }
      </Radio.Group>
    )
  }
  else if(inputObject.editorTypeId == 1) {
    return (
      <InputNumber className="w-full" value={value} disabled>

      </InputNumber>
    )
  }
  else if(inputObject.editorTypeId == 5) {
    // return (
    //   <Input disabled value={value}>

    //   </Input>
    // )
    return (
      <div>
        {value}
      </div>
    )
  }
  else if(inputObject.editorTypeId == 6) {
    return (
      <div>
        {value}
      </div>
    )
    // return (
    //   <Input.TextArea disabled value={value}>

    //   </Input.TextArea>
    // )
  }
  else if(inputObject && inputObject.editorTypeId == 7) {
     {/* <Upload
        disabled
        listType="picture-card"
        fileList={fileList}
        onChange={onChange}
        onPreview={onPreview}
      >
        {fileList.length < 5 && '+ Upload'}
      </Upload> */}
    return (
      <img className='w-full' src={process.env.REACT_APP_UPLOAD_URL+value}></img>
    )
  }
  else {
    // return (
    //   <div className="">
    //     {value}
    //   </div>
    // )
    return (
      <div className="font-semibold">{value}</div>
      // <Input disabled={true} value={value}></Input>
    )
  }
  
}
const QuestionsContainer = ({ data, setData, ownIndex, groupIndex }) => {
  const authContext = useContext(AuthContext)

  const renderAnswer = (answer) => {
    return <RenderInputByType key={`answer-${answer.id}`} inputObject={answer} value={answer.answer} />;
  };

  let fooIndex = 0; 

  return (
    <div key={`question-${fooIndex}`} className="flex flex-col mb-8">
      <label className="text-xs text-[#85878E] mb-2">{data.title}:</label>
      <div className="grid grid-cols-2 gap-4 items-start">
        {renderAnswer(data)}
        <div className='flex flex-col gap-3'>
          <Radio.Group
            value={data.qualityStatus}
            onChange={e => setData(prev => {
              let tmp = Object.assign([], prev)
              tmp[groupIndex].questions[ownIndex].qualityStatus = e.target.value
              return tmp
            })}
            options={[
              {
                label: 'Not enough',
                value: 0
              },
              {
                label: 'Need improvment',
                value: 1
              },
              {
                label: 'Accepted',
                value: 2
              },
            ]}
            optionType="button"
            buttonStyle="solid"
          />
          {
            (data.qualityStatus == 1 || data.qualityStatus == 0) &&
            <>
              <TextArea 
                placeholder='Comment' 
                value={data.comment} 
                onChange={e => setData(prev => {
                  let tmp = Object.assign([], prev)
                  tmp[groupIndex].questions[ownIndex].comment = e.target.value
                  return tmp
                })}>

              </TextArea>
              <Upload 
                maxCount={1}
                action={`${process.env.REACT_APP_MAIN_API_URL}File/upload`}
                headers={{
                  Authorization: `Bearer ${authContext.state.token}`,
                }}
                onRemove={(file) => {
                  instance({
                    method: 'delete',
                    url: `File/remove`,
                    data: file.response.responseData
                  })
                }}
                onChange={(info) => {
                  if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                  }
                  if (info.file.status === 'done') {
                    setData(prev => {
                      let tmp = Object.assign([], prev)
                      tmp[groupIndex].questions[ownIndex].fileName = info.file.response.responseData
                      return tmp
                    })
                    message.success(`${info.file.name} file uploaded successfully`);
                  } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                  }
                }}>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </>
          }
        </div>
      </div>
    </div>
  );
};


const AuditqualificationDetail = () => {
  const [ data, setData ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const [ submit, setSubmit ] = useState(false)
  const [ tabIndex, setTabIndex ] = useState(0)
  const [ rejectModalOpen, setRejectModalOpen ] = useState(false)
  const [ form ] = Form.useForm()
  const { id } = useParams()
  const location = useLocation()
  const authContext = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    fetchQuestions()
  }, [])


  const fetchQuestions = () => {
    instance({
      method: 'get',
      url: `/AuditSupplier/detail/${location.state.id}?wcode=audit-qualification-detail`
    }).then(res => {
      if(res.data.responseData) {
        setData(res.data.responseData.detail)
      }
    }).catch(err => {
      console.log(err)
    }).then(() => {
      setLoading(false)
    })
  }

  const handleSubmit = (value) => {
    setSubmit(true)
    let details = []

    data.map((group) => {
      group.questions.map(question => {
        if(question) {
          details.push({
            id: question.id,
            status: question.status,
            comment: question.comment,
            qualityStatus: question.qualityStatus,
            fileName: question.fileName
          })
        }
      }) 
    })
    
    instance({
      method: 'put',
      url: `/AuditSupplier/validate?wcode=audit-qualification-detail`,
      data: {
        resultStatus: value,
        id: id,
        details: details
      }
    }).then(res => {
      navigate("/audit-qualification")
    }).catch(err => {
      
    }).then(() => {
      setSubmit(false)
    })
  } 

  const menu = useMemo(() => {
    let tmp = []
    data.map((foo, fooIndex) => {
      tmp.push({
        label: foo.name,
        key: fooIndex,
        children: (
          <Card title="Answers" size="small" className="rounded-none">
            {
              foo.questions.map((question, questionIndex) => {
                return (
                  <QuestionsContainer key={`card-${questionIndex}`} groupIndex={fooIndex} ownIndex={questionIndex} data={question} setData={setData}></QuestionsContainer>
                )
              })
            }
          </Card>
        )
      })
    })
    return tmp
  }, [data])

  const handleFinish = (values) => {
    setSubmit(true)
    let details = []

    data.map((group) => {
      group.questions.map(question => {
        if(question) {
          details.push({
            id: question.id,
            status: question.status,
            comment: question.comment,
            qualityStatus: question.qualityStatus,
            fileName: question.fileName
          })
        }
      }) 
    })
    
    instance({
      method: 'put',
      url: `/AuditSupplier/reject?wcode=audit-qualification-detail`,
      data: {
        resultStatus: values.resultStatus,
        id: id,
        renewDay: values.renewDay,
        report: values.report && values.report.file.response.responseData,
        improvePlan: values.improvePlan && values.improvePlan.file.response.responseData,
        details: details
      }
    }).then(res => {
      navigate("/audit-qualification")
    }).catch(err => {
      
    }).then(() => {
      setSubmit(false)
    })
  }
  
  return (
    <div className="p-4 grid grid-cols-12 gap-3">
      <Card className="col-span-3 sticky top-3" size='small' style={{height: `calc(100vh - 76px)`}}>
        <div className="flex flex-col gap-3 h-full">
          {
            loading ?
            <Skeleton active/>
            :
            <div className="flex gap-3 mb-3">
              <div className="w-12 h-12 rounded-full flex justify-center items-center text-white bg-primary text-3xl">
                {location.state?.supplierName?.slice(0,1)}
              </div>
              <div>
                <div className='text-xl text-primary text-center'>
                  {location.state?.supplierName}
                </div>
                <div className="text-mute">
                  Audit type: {location.state?.auditName}
                </div>
              </div>
            </div>
          }
          <div className="flex flex-col">
            {
              menu.map((foo, fooindex) => {
                return (
                  <Button type="link" key={`menu-${fooindex}`} className={tabIndex == fooindex ? "flex justify-start !bg-primary/10 truncate" : "flex justify-start truncate"} onClick={() => setTabIndex(fooindex)}>
                    {foo.label}
                  </Button>
                )
              })
            }
          </div>
          <Divider></Divider>
          <div className="flex flex-col gap-3">
            <div>
              Total: {data.reduce((a, b) => a+b.questions.length, 0)} questions
            </div>
            <div>
              Accepted: {data.reduce((a,b) => a+b.questions.filter(foo => foo.qualityStatus == 2).length, 0)}
            </div>
            <div>
              Need improvement: {data.reduce((a,b) => a+b.questions.filter(foo => foo.qualityStatus == 1).length, 0)}
            </div>
            <div>
              Not enough: {data.reduce((a,b) => a+b.questions.filter(foo => foo.qualityStatus == 0).length, 0)}
            </div>
            <div className="flex flex-wrap gap-3">
              {
                data.reduce((a,b) => a+b.questions.filter(foo => foo.qualityStatus == 2).length, 0) == data.reduce((a,b) => a+b.questions.length, 0) ?
                <Button className="w-full" type="primary" onClick={() => handleSubmit(2)} loading={submit}>Approve</Button>
                :
                <>
                <Button className="w-full" type="primary" danger onClick={() => {
                  // handleSubmit(0)
                  form.setFieldValue("resultStatus", 0)
                  setRejectModalOpen(true)
                }} loading={submit}>Reject</Button>
                <Button className="w-full !bg-yellow-500 !border-yellow-500 hover:bg-yellow-500/90 hover:border-yellow-500/90" type="primary" onClick={() => {
                  // handleSubmit(1)
                  form.setFieldValue("resultStatus", 1)
                  setRejectModalOpen(true)
                }} loading={submit}>Need improvement</Button>
                </>
              }
            </div>
          </div>
        </div>
      </Card>
      <div className="col-span-9">
        {
          loading  ?
          <Skeleton active></Skeleton>
          :
          menu[tabIndex].children
        }
      </div>
      <Modal
        title="Improvement duration"
        open={rejectModalOpen}
        onCancel={() => {
          form.resetFields()
          setRejectModalOpen(false)
        }}
        onOk={form.submit}
        okText={"Save"}>
        <Form
          layout='vertical'
          form={form}
          onFinish={handleFinish}>
          <Form.Item name="resultStatus" className='hidden'><Input/></Form.Item>
          <Form.Item noStyle shouldUpdate={(prev,cur) => prev.resultStatus != cur.resultStatus}>
            {
              ({getFieldValue}) => {
                if(getFieldValue("resultStatus") == 1) {
                  return (
                    <Form.Item name="renewDay" label="Improvment duration" rules={[{required: true, message: 'Improvement duration is required!'}]}>
                      <InputNumber className="w-full"/>
                    </Form.Item>
                  )
                }
              }
            }
          </Form.Item>
          <Form.Item name="improvePlan" label="Improvement plan file" className="col-span-12">
            <Dragger 
              maxCount={1}
              name="file" 
              action={`${process.env.REACT_APP_MAIN_API_URL}File/upload`}
              headers={{
                Authorization: `Bearer ${authContext.state.token}`,
              }}
              onChange={(info) => {
                if (info.file.status !== 'uploading') {
                  // console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                  message.success(`${info.file.name} file uploaded successfully`);
                } else if (info.file.status === 'error') {
                  message.error(`${info.file.name} file upload failed.`);
                }
              }}
              onRemove={(file) => {
                instance({
                  method: 'delete',
                  url: `File/remove`,
                  data: file.response.responseData
                })
              }}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                  banned files.
                </p>
            </Dragger>
          </Form.Item>
          <Form.Item name="report" label="Report file" className="col-span-12">
            <Dragger 
              maxCount={1}
              name="file" 
              action={`${process.env.REACT_APP_MAIN_API_URL}File/upload`}
              headers={{
                Authorization: `Bearer ${authContext.state.token}`,
              }}
              onChange={(info) => {
                if (info.file.status !== 'uploading') {
                  // console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                  message.success(`${info.file.name} file uploaded successfully`);
                } else if (info.file.status === 'error') {
                  message.error(`${info.file.name} file upload failed.`);
                }
              }}
              onRemove={(file) => {
                instance({
                  method: 'delete',
                  url: `File/remove`,
                  data: file.response.responseData
                })
              }}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                  banned files.
                </p>
            </Dragger>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}
export default AuditqualificationDetail