import { Button, Popover } from "antd"
import { useEffect, useMemo, useState } from "react"
import { instance } from "utils/axios"
import { MoreOutlined, CheckOutlined } from '@ant-design/icons'
import { twMerge } from "tailwind-merge"
import { Link } from "react-router-dom"
import moment from "moment"
import { SignalRContext } from "contexts/socketProvider"

const Notification = ({data, handleNotifSeen}) => {
  // const [ data, setData ] = useState([])
  const [ filter, setFilter ] = useState(0)
  
  // useEffect(() => {
  //   instance({
  //     method: 'get',
  //     url: `/Notification`
  //   }).then(res => {
  //     setData(res.data.responseData)
  //   })
  // }, [])
  
  const handleSeen = (_id) => {
    instance({
      method: 'get',
      url: `/Notification/seen`,
      params: {
        nofiticationId: _id
      }
    })
    handleNotifSeen(_id)
  }

  const filteredData = useMemo(() => {
    if(filter == 0) {
      return data
    }
    return data.filter(foo => foo.seenStatus == 0)
  }, [filter, data])
  
  return (
    <div className="w-[360px] flex flex-col gap-3">
      <div className="flex justify-between items-center">
        <div className="font-bold text-xl">
          Notification
        </div>
        <Popover
          content={<div className="w-[340px] flex flex-col">
            <Button className="flex items-center justify-start" type='text'>
              <CheckOutlined className="text-lg"/>
              Mark all as read
            </Button>
          </div>}
          trigger={"click"}
          placement="bottomRight">
          <Button type="link" icon={<MoreOutlined className="rotate-90"/>}>

          </Button>
        </Popover>
      </div>
      <div className="flex gap-3">
        <Button type="text" onClick={() => setFilter(0)} className={twMerge(filter == 0 && "bg-primary/20 text-black hover:!bg-primary/10")}>All</Button>
        <Button type="text" onClick={() => setFilter(1)} className={twMerge(filter == 1 && "bg-primary/20 text-black hover:!bg-primary/10")}>Unread</Button>
      </div>
      <div className="flex flex-col">
        {
          filteredData.map((foo, fooIndex) => {
            return (
              <Link to={foo.route} onClick={() => {handleSeen(foo.id)}} key={`notification-${fooIndex}`} className={twMerge("hover:bg-slate-100 transition-all rounded-lg pl-3 group py-2")}>
                <div className="flex items-center">
                  <div className="flex-1">
                    <div className={twMerge(foo.seenStatus == 0 ? 'text-black' : 'text-slate-700')}>
                      {foo.body}
                    </div>
                    <div className="text-xs text-primary">
                      {moment(foo.createdAt).fromNow()}
                    </div>
                  </div>
                  <div className="w-[24px]">
                    {
                      foo.seenStatus == 0 &&
                      <div className="w-3 h-3 rounded-full bg-primary">
                      </div>
                    }
                  </div>
                </div>
              </Link>
            )
          })
        }
      </div>
    </div>
  )
}

export default Notification