import { Button, Table } from "antd"
import { useContext, useEffect, useState } from "react"
import { useOutletContext, useParams } from "react-router-dom"
import { instance } from "utils/axios"
import { disqualifiedColumns, disqualifiedColumnsSummary, expandedDetailColumns, expandedSuppliersColumns, suppliersColumns, totalColumns } from "./rfqFormColumns"
import { AuthContext } from "contexts"
import LowestPriceSuppliers from "./LowestPriceSuppliers"
import OtherRecommended from "./OtherRecommended"

const RFQReport = ({data, rawData, mainData, selected, closeModal, handleClose}) => {
  const [ loading, setLoading ] = useState(false)
  const [ outletData ] = useOutletContext()
  const { id } = useParams() 
  const [ info, setInfo ] = useState({
    approverUsers: [],
    negotiateTotal: 0,
    total: 0
  })
  const systemContext = useContext(AuthContext)
  
  useEffect(() => {
    instance({
      method: 'post',
      url: `/Tender/rfq/approvalinfo`,
      data: {
        id: id,
        usdRate: data.usdRate,
        supplierDetails: selected
      }
    }).then(res => {
      setInfo(res.data.responseData)
    })
  }, [])

  const fixDisqualifiedData = () => {
    let tmp = []

    let foo = Object.groupBy(data.disqualifiedData, ({description}) => description)

    Object.keys(foo).map((key,fooIndex) => {
      tmp.push({
        title: key,
        items: foo[key].length,
        data: foo[key],
        key: fooIndex
      })
    })

    return tmp
  }

  const handleSubmit = () => {
    instance({
      method: 'post',
      url: `/Tenderrfq/rfq/sendtoapproval?wcode=tender-detail`,
      data: {
        shortReport: data.shortReport,
        usdRate: data.usdRate,
        tenderId: id,
        summaryReport: data.summaryReport,
        totalComment: data.total.find(foo => foo.title == "Total Suppliers invited to RFQ").commentText ?? "",
        respondedComment: data.total.find(foo => foo.title == "Responded suppliers to RFQ").commentText ?? "",
        notParticipatedComment: data.total.find(foo => foo.title == "Not participated").commentText ?? "",
        supplierDetails: data.supplierDetails.map(foo => ({tenderSupplierDetailId: foo.id, status: 1, commentText: foo.commentText})),
        details: data.suppliersData.map((foo) => ({supplierId: foo.id, commentText: foo.commentText})),
        disQualifiedItems: data.disqualifiedData.map((foo) => ({tenderDetailId: foo.id, commentText: foo.commentText})),
        // qualifiedItems: data.qualifiedData.map((foo) => ({tenderDetailId: foo.id, commentText: foo.commentText})),
        // users: data.users.map((foo, _index) => ({userId: foo, orderId: _index+1})),
        users: []
      }
    }).then(() => {
      outletData.fetchData()
      handleClose()
    }).catch(() => {

    })
  }
  
  return (
    <div className="grid grid-cols-12 gap-3">
      <div className="col-span-12 grid grid-cols-12 divide-x divide-y border-b border-r">
        <div className='col-span-3 text-sm font-bold border-t border-l px-3'>
          RFQ number: 
        </div>
        <div className="col-span-3 px-3">{mainData?.code}</div>
        <div className="col-span-3 px-3 font-bold">
          Total value for approval:
        </div>
        <div className="col-span-3 px-3">{info.total.toFixed(2).toLocaleString("en-US")}$</div>
        <div className="col-span-3 px-3 font-bold">
          Recommendation from Purchasing officer: 
        </div>
        <div className="col-span-3 px-3">{systemContext.state?.userInfo?.userName}</div>
        <div className='col-span-3 px-3 font-bold'>
          Approver as per DFA: 
        </div>
        <div className="col-span-3 px-3 flex flex-col gap-1">
          {
            info.approverUsers.map((foo, fooIndex) => <div>{`${foo.firstName} ${foo.lastName}`}</div>)
          }
        </div>
        <div className="col-span-3 px-3 font-bold">
          End user name:
        </div>
        <div className="col-span-3 px-3 flex gap-x-2">
          {
            mainData.endUsers.map((foo, fooIndex) => {
              return (<span className="" key={`end-user-${fooIndex}`}>{foo.userInfo.firstName} {foo.userInfo.lastName} {fooIndex+1 != mainData.endUsers.length && ","}</span>)
            })
          }
        </div>
        <div className="col-span-3 px-3 font-bold">
          Ticket number (if Pnow):
        </div>
        <div className="col-span-3 px-3 flex">
          {mainData?.procurementNow}
        </div>
        <div className="col-span-3 px-3 font-bold">
          Department/Area:
        </div>
        <div className="col-span-3 px-3 flex gap-x-2">
          {/* {
            mainData.endUsers.map((foo, fooIndex) => {
              return (<span className="" key={`end-user-department-${fooIndex}`}>{foo.teamName},</span>)
            })
          } */}
        </div>
        <div className="col-span-3 px-3 font-bold">
          Negotiation outcome:
        </div>
        <div className="col-span-3 px-3 flex flex-col">
          {info.negotiateTotal.toFixed(2).toLocaleString("en-US")}$
        </div>
      </div>
      <div className="col-span-12">
        <div className="font-bold">Short background: </div>
        <div className="border p-2">
          {data.shortReport}
        </div>
      </div>
      <div className="col-span-12">
        <div className="font-bold">Recommendation: </div>
        <div className="border p-2" dangerouslySetInnerHTML={{__html: data.summaryReport}}>

        </div>
      </div>
      <div className="col-span-12 flex flex-col gap-3">
        <Table
          className="border border-gray-300 rounded overflow-hidden"
          columns={totalColumns}
          dataSource={data.total}
          pagination={false}
          size="small"
          rowKey={(row, index) => {
            return index
          }}
          expandable={{
            expandedRowRender: rec => {
              return (
                <div>
                  <Table
                    columns={expandedSuppliersColumns}
                    dataSource={rec.data}
                    pagination={false}
                    size="small">

                  </Table>
                </div>
              )
            }
          }}>

        </Table>
        <LowestPriceSuppliers data={data}/>
        <OtherRecommended data={data}/>
        <Table
          className="border border-gray-300 rounded overflow-hidden"
          scroll={{
            y: 500,
            x: 'max-content'
          }}
          columns={disqualifiedColumnsSummary}
          dataSource={data.disqualifiedData}
          // dataSource={fixDisqualifiedData()}
          // expandable={{
          //   expandedRowRender: rec => {
          //     return (
          //       <div>
          //         <Table
          //           columns={disqualifiedColumns}
          //           dataSource={rec.data}
          //           pagination={false}
          //           size="small">

          //         </Table>
          //       </div>
          //     )
          //   }
          // }}
          pagination={false}
          size="small">

        </Table>
        <Table
          className="border border-gray-300 rounded overflow-hidden"
          columns={[
            {
              title: '#',
              width: 50,
              fixed: 'left',
              render: (cell, row, index) => index+1
            },
            {
              title: 'Total disqualified suppliers',
              dataIndex: 'details',
              render: cell => cell.length
            },
            {
              title: 'Comment',
              dataIndex: 'comment',
              width: 500
            },

          ]}
          dataSource={Object.keys(Object.groupBy(data.suppliersData, ({commentText}) => commentText)).map(foo => ({
            comment: foo,
            details: Object.groupBy(data.suppliersData, ({commentText}) => commentText)[foo]
          }))}
          pagination={false}
          rowKey={(row, index) => {
            return row.id
          }}
          expandable={{
            expandedRowRender: rec => {
              return (
                <div>
                  <Table
                    columns={[
                      {
                        title: '#',
                        width: 50,
                        fixed: 'left',
                        render: (cell, row, index) => index+1
                      },
                      {
                        title: 'Name',
                        dataIndex: 'name',
                      },
                    ]}
                    dataSource={rec.details}
                    pagination={false}
                    size="small">

                  </Table>
                </div>
              )
            }
          }}
          size="small">
          
        </Table>
        
      </div>
      <div className="col-span-12 flex justify-end gap-4">
        <Button onClick={closeModal}>Prev</Button>
        <Button type="primary" onClick={handleSubmit}>Submit</Button>
      </div>
    </div>
  )
}

export default RFQReport