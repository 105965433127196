import React, { useEffect, useLayoutEffect, useState } from 'react';
import moment from 'moment';
import { ConfigProvider, message } from 'antd';
import { HashRouter, RouterProvider } from 'react-router-dom';
import { AuthProvider } from 'contexts';
import { ThemeProvider } from 'contexts/themeContext';
import AxiosProvider from 'utils/axios';
import router from 'routes';
import AntdThemeProvider from './AntdThemeProvider'
import { SocketProvider } from 'contexts/socketProvider';

message.config({
  prefixCls: 'power-message',
})

function App() {
  const [ headerBgColor, setHeaderBgColor ] = useState('transparent')

  useLayoutEffect(() => {
    if (document.body.clientWidth < 1920) {
      let viewport = document.querySelector("meta[name=viewport]");
      viewport.setAttribute('content', `width=device-width, initial-scale=${document.body.clientWidth/1920}, user-scalable=0`);
    }
  }, [])

//   useEffect(() => {
//     signalRService.start();

//     return () => {
//         signalRService.off("ReceiveMessage");
//         signalRService.stop();
//     };
// }, []);
  
  return (
    <React.Suspense fallback={<div>Loading</div>}>
      <AntdThemeProvider>
        <ThemeProvider value={{headerBgColor, setHeaderBgColor}}>
          <AuthProvider>
            <AxiosProvider>
              <SocketProvider>
                <RouterProvider router={router}>
                </RouterProvider>
              </SocketProvider>
            </AxiosProvider>
          </AuthProvider>
        </ThemeProvider>
      </AntdThemeProvider>
    </React.Suspense>
  );
}

export default App;
