import { EllipsisOutlined } from '@ant-design/icons'
import { Dropdown } from 'antd'
import moment from 'moment'

const ChatBubble = ({data}) => {
  
  return (
    <div className="flex justify-start gap-3">
      <div>
        <img src={"https://via.placeholder.com/50"} className="rounded-full shadow-md"></img>
      </div>
      <div className="grow relative">
        <div className="border border-gray-300 rounded overflow-hidden">
          <div className="py-2 px-4 flex justify-between items-center bg-gray-200">
            <div><span className='font-bold'>{ data.isSupplier == 1 ? 'Supplier' : data.isEndUser == 1 ? 'I' : `Buyer ${data.firstName} ${data.lastName}`}</span> commented {moment(data.createdAt).fromNow()}</div>
            <div>
              {/* <Dropdown
                menu={{
                  items: data.isSupplier == 1 ?
                  [
                    {
                      key: 0,
                      label: 'Forward to end user'
                    }
                  ]
                  :
                  data.isEndUser == 1 ?
                  [
                    {
                      key: 0,
                      label: 'Forward to supplier'
                    }
                  ]
                  :
                  []
                }}>
                <EllipsisOutlined />
              </Dropdown> */}
            </div>
          </div>
          <div className="p-4 bg-white" dangerouslySetInnerHTML={{__html: data.body}}>
          </div>
        </div>
        <div className="h-10 w-[2px] bg-gray-300 ml-4">

        </div>
      </div>
    </div>
  )
}

export default ChatBubble