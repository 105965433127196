import { Button, Card, Input, Modal, Space, Table, Tooltip } from "antd"
import { useEffect, useRef, useState } from "react"
import { instance } from "utils/axios"
import * as XLSX from 'xlsx';
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words';

const MaterialNumber = () => {
  const [ data, setData ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const [ importing, setImporting ] = useState(false)
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const uploadRef = useRef()
  const searchInput = useRef(null);

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `/Material?wcode=master`
    }).then(res => {
      setData(res.data.responseData)
    }).finally(() => {
      setLoading(false)
    })
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex, label) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${label}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleFileUpload = (e) => {
    setImporting(true)
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const workbook = XLSX.read(event.target.result, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet);

      instance({
        method: 'post',
        url: `/Material/importwithmanufacturers?wcode=master`,
        data: sheetData.map((foo) => ({
          materialId: foo.Material,
          name: foo[`Material description`] ?? '',
          uom: foo.UOM ?? '',
          partNumber: foo.PN ?? '',
          manufacturer1: foo.Manufacturer1 ?? '',
          manufacturer2: foo.Manufacturer2 ?? '',
          manufacturer3: foo.Manufacturer3 ?? '',
          manufacturer4: foo.Manufacturer4 ?? '',
          manufacturer5: foo.Manufacturer5 ?? '',
        }))
      }).then(() => {
        fetchData()
      }).catch(() => {

      }).then(() => {
        setImporting(false)
      })
    };

    reader.readAsBinaryString(file);
  }
  
  return (
    <div className="p-4">
      <Card size="small">
        <div className="flex justify-end mb-3">
          <Tooltip title={(
            <div>
              Import material numbers from excel (Manufacturers column should be named 'Manufacturer1', 'Manufacturer2' etc )
            </div>
          )}>
            <Button type="primary" 
              loading={importing}
              onClick={() => {
                uploadRef.current && uploadRef.current.click()
              }}>
              Import from excel
            </Button>
          </Tooltip>
          <input type="file" ref={uploadRef} className="!hidden" accept={".xls,.xlsx,.csv"} onChange={handleFileUpload} value={[]}/>
        </div>
        <Table
          loading={loading}
          size="small"
          dataSource={data}
          columns={[
            {
              title: 'Material number',
              dataIndex: 'id',
              ...getColumnSearchProps('id', 'material number'),
            },
            {
              title: 'Criticality',
              dataIndex: 'criticality',
              width: 50
            },
            {
              title: 'Name',
              dataIndex: 'name'
            },
            {
              title: 'Manufacturer',
              dataIndex: 'manufacturers',
              render: (cell) => cell.map(foo => `${foo.name} `)
            },
            {
              title: 'Part number',
              dataIndex: 'partNumber'
            },
            {
              title: 'UOM',
              dataIndex: 'uom'
            },
          ]}>

        </Table>
      </Card>
    </div>
  )
}

export default MaterialNumber