import React, { useEffect, useMemo, useState } from 'react'
import { Button, Tooltip, Skeleton, Divider, Table, Card, Steps, Input, Tag } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { useNavigate, Outlet } from 'react-router-dom';
import { instance } from 'utils/axios';
import { useParams, Link, useLocation } from "react-router-dom";
import { BsClipboardCheck, BsJournalBookmark, BsPeople, BsViewStacked, BsBoxes, BsChatQuote, BsPencilSquare, BsQuestionSquare  } from "react-icons/bs";
import { disqualifiedColumns, disqualifiedColumnsSummary, expandedDetailColumns, expandedSuppliersColumns, suppliersColumns, totalColumns } from './rfqFormColumns';

const columns = () => {
  return ([
    {
      title: 'FORWARDER NAME',
      dataIndex: 'name',
    },
    {
      title: 'SRN number',
      dataIndex: 'srnNumber'
    },
    {
      title: 'LEADTIME (by Days)',
      dataIndex: 'leadTime'
    },
    {
      title: 'FREIGHT COST /USD/',
      dataIndex: 'totalPrice',
      render: (cell) => cell.toLocaleString("en-US")
    },
    {
      title: 'TRANSPORT MODE',
      dataIndex: 'transportMode'
    },
    {
      title: 'TRANSPORT TYPE',
      dataIndex: 'transportType'
    },
    {
      title: 'MULTIPLE QUOTE ?',
      dataIndex: 'description'
    },
    {
      title: 'Award status',
      dataIndex: 'awardStatus',
      render: (cell, row) => {
        if(cell == 1 && row.isAwarded) {
          return (
            <Tag color="#009A72">
              Awarded
            </Tag>
          )
        }
      }
    },
    {
      title: 'Attachment',
      dataIndex: 'fileName',
      render: (cell) => {
        if(cell) {
          return (
            <Button type="link" href={process.env.REACT_APP_UPLOAD_URL+cell} target="_blank">
              Preview
            </Button>
          )
        }
        else {
          return (
            <></>
          )
        }
      }
    },
  ])
} 

function TenderDetail() {
  const [ data, setData ] = useState(null)
  const [ mainData, setMainData ] = useState({
    suppliers: []
  })
  const [ loading, setLoading ] = useState(true)
  const [ sending, setSending ] = useState(false)
  const [ comment, setComment ] = useState("")
  const location = useLocation()
  const navigate = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    // instance({
    //   method: 'get',
    //   url: `TenderBidSummary/rfq/${id}?wcode=tender-detail`
    // }).then(res => {
    //   console.log(res.data.responseData)
    //   setData(res.data.responseData)
    // }).catch(err => {

    // }).then(() => {
    //   setLoading(false)
    // })
    instance({
      method: 'get',
      url: `/TenderApproval/freight/summary/${id}?wcode=tender-detail`
    }).then(res => {
      console.log(res.data.responseData)
      setMainData(res.data.responseData)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const handleSend = (approveStatus) => {
    setSending(true)
    instance({
      method: 'post',
      url: approveStatus == 1 ? `/TenderApproval/freight/approve?wcode=tender-detail` : `/TenderApproval/freight/reject?wcode=tender-detail`,
      data: {
        tenderId: id,
        comment: comment,
      }
    }).then(() => {
      setComment("")
      fetchData()
    }).catch(err => {

    }).then(() => {
      setSending(false)
    })
  }

  const fixData = useMemo(() => {
    let tmp = []

    mainData.suppliers.map((foo, fooIndex) => {
      let pushed = false
      foo.details.map(_detail => {
        // let _detailFound = data.detail.find(foo => foo.id == _detail.detailId)
        tmp.push({
          ..._detail,
          status: foo.status,
          name: foo.name,
          awardStatus: foo.awardStatus,
          srnNumber: _detail?.srnNumber,
          transportMode: _detail?.transportMode,
          transportType: _detail?.transportType,
          rowSpan: foo.details.length,
          useSpan: !pushed,
        })
        pushed = true
      })
    })

    return tmp
  }, [mainData])
  
  return (
    <div className='p-4 grid grid-cols-12 gap-3 relative'>
      {/* <div className="col-span-12">
        <Tooltip title="Go back">
          <Button type="link" onClick={() => navigate("/tender-approval")} icon={<LeftOutlined />}>
            Go back to tender list
          </Button>
        </Tooltip>
      </div> */}
      {
        loading ? 
        <div className='col-span-12'>
          <Skeleton active/>
        </div>
        :
        <div className="grid grid-cols-12 gap-3 col-span-12">
          {/* <div className="col-span-12">
            <Card size="small">
              <Steps
                progressDot
                current={mainData.approvalSteps.findIndex(foo => foo.isCurrent == 1)}
                items={mainData.approvalSteps.map(foo => ({title: `${foo.firstName} ${foo.lastName}`}))}>

              </Steps>
            </Card>
          </div> */}
          <Card className="col-span-12" size="small">
            <div className="grid grid-cols-12 gap-3">
              <div className="col-span-1 flex flex-col gap-1">
                <div className="">
                  Freight number:
                </div>
                <div className="font-bold">
                  {mainData.tenderInfo.code}
                </div>
              </div>
              <div className="col-span-3 flex flex-col gap-1">
                <div className="">
                  Freight name:
                </div>
                <div className="font-bold">
                  {mainData.tenderInfo.name}
                </div>
              </div>
              <div className="col-span-1 flex flex-col gap-1">
                <div className="">
                  Total value:
                </div>
                <div className="font-bold">
                  {mainData.suppliers.find(foo => foo.status == 9)?.details.reduce((a,b) => a+b.totalPrice, 0).toLocaleString("en-US")}
                </div>
              </div>
              <div className="col-span-2 flex flex-col gap-1">
                <div className="">
                  Purchasing officer:
                </div>
                <div className="font-bold">
                  {mainData.tenderInfo.buyerFirstName} {mainData.tenderInfo.buyerLastName}
                </div>
              </div>
              <div className="col-span-2 flex flex-col gap-1">
                <div className="">
                  RFQ Requested By:
                </div>
                <div className="font-bold">
                  {
                    mainData.endUsers.map((foo, fooIndex) => {
                      return (<div key={`end-user-${fooIndex}`}>{foo.firstName} {foo.lastName}</div>)
                    })
                  }
                </div>
              </div>
              <div className="col-span-2 flex flex-col gap-1">
                <div className="">
                  Approver as per DFA:
                </div>
                <div className="font-bold">
                  {mainData.approvalSteps[mainData.approvalSteps.length - 1].firstName} {mainData.approvalSteps[mainData.approvalSteps.length - 1].lastName}
                </div>
              </div>
            </div>
          </Card>
          {/* <Card className="col-span-12" size='small'>
            <div className="font-bold bg-[#F7F9FB] rounded-t-lg p-2">RFQ Summary</div>
            <div className="p-2" dangerouslySetInnerHTML={{__html: mainData.data.summaryReport}}>

            </div>
          </Card> */}
          <Card className="col-span-12" size="small">
            <div className="flex flex-col gap-3">
              <Table
                rowKey={row => row.supplierId}
                pagination={false}
                size="small"
                onRow={(record, index) => {
                  return {
                    style: {
                      background: record.status == 9 ? '#bdffbd' : record.isSelected == 1 ? 'rgb(253, 208, 167)' : ''
                    }
                  }
                }}
                dataSource={fixData}
                columns={columns()}>

              </Table>
              {/* <Table
                columns={totalColumns}
                dataSource={[
                  {
                    title: 'Total Suppliers invited to RFQ',
                    data: mainData.total.data,
                    description: mainData.total.commentText
                  },
                  {
                    title: 'Responded suppliers to RFQ',
                    data: mainData.statSubmitted.data,
                    description: mainData.statSubmitted.commentText
                  },
                  {
                    title: 'Not participated',
                    data: mainData.statNotInterested.data,
                    description: mainData.statNotInterested.commentText
                  },
                ]}
                pagination={false}
                size="small"
                rowKey={(row, index) => {
                  return index
                }}
                expandable={{
                  expandedRowRender: rec => {
                    // console.log(rec.data)
                    return (
                      <div>
                        <Table
                          columns={expandedSuppliersColumns}
                          dataSource={rec.data}
                          pagination={false}
                          size="small">

                        </Table>
                      </div>
                    )
                  }
                }}>

              </Table> */}
              {/* <Table
                columns={suppliersColumns()}
                dataSource={mainData.suppliers}
                pagination={false}
                rowKey={(row, index) => {
                  return row.id
                }}
                expandable={{
                  expandedRowRender: rec => {
                    return (
                      <div>
                        <Table
                          columns={expandedDetailColumns}
                          dataSource={rec.details}
                          pagination={false}
                          size="small">

                        </Table>
                      </div>
                    )
                  }
                }}
                size="small">
                
              </Table> */}
              {/* <Table
                columns={disqualifiedColumnsSummary}
                dataSource={Object.keys(Object.groupBy(mainData.disqualified, ({comment}) => comment)).map(foo => {
                  return ({
                    title: foo,
                    items: Object.groupBy(mainData.disqualified, ({comment}) => comment)[foo].length,
                    data: Object.groupBy(mainData.disqualified, ({comment}) => comment)[foo]
                  })
                })}
                expandable={{
                  expandedRowRender: rec => {
                    return (
                      <div>
                        <Table
                          columns={disqualifiedColumns}
                          dataSource={rec.data}
                          pagination={false}
                          size="small">

                        </Table>
                      </div>
                    )
                  }
                }}
                pagination={false}
                size="small">

              </Table> */}
            </div>
          </Card>
          {/* <div className="col-span-6 flex flex-col gap-3">
          </div> */}
        </div>
      }
      <div className='col-span-12 sticky bottom-0 left-0 right-0'>
        <div className='rounded-lg' style={{boxShadow: `0px -15px 30px 5px rgba(0, 0, 0, 0.1)`}}>
          <Card title="Approval" className="overflow-hidden" size="small">
            <div className="flex flex-col gap-3">
              <Input.TextArea onChange={e => setComment(e.target.value)} value={comment} placeholder='Comment'>

              </Input.TextArea>
              <div className="flex gap-4">
                <Button loading={sending} disabled={sending} className='w-full' type="primary" danger onClick={() => handleSend(0)}>Reject</Button>
                <Button loading={sending} disabled={sending} className='w-full' type="primary" onClick={() => handleSend(1)}>Approve</Button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default TenderDetail
