import { Table } from "antd"
import { useMemo } from "react"

const OtherRecommended = ({data}) => {
  // console.log(data)
  const fixedData = useMemo(() => {
    let tmp = []

    const lowestList = data.supplierDetails.filter(foo => foo.commentText != 'Recommended because price is the lowest')
    
    const groupedData = Object.groupBy(lowestList, ({supplierId}) => supplierId)

    Object.keys(groupedData).map(foo => {
      tmp.push({
        ...data.suppliersData.find(_tmp => _tmp.id == foo),
        details: groupedData[foo].map(foo => ({
          ...foo,
          costReduction: foo.avgPrice - foo.unitPriceUsd < 0 ? 0 : foo.avgPrice - foo.unitPriceUsd
        }))
      })
    })
    
    return tmp;
  }, [data])
  
  return (
    <div>
      <Table
        className="border border-gray-300 rounded overflow-hidden"
        pagination={false}
        size="small"
        dataSource={fixedData}
        columns={[
          {
            title: '#',
            dataIndex: 'index',
            width: 50,
            render: (cell, rec, index) => index+1
          },
          {
            title: 'Recommended to award items selected by other requirements',
            dataIndex: '_supplier',
            width: 400,
            render: (cell, row) => row.details[0]?._supplier
          },
          {
            title: fixedData.reduce((a,b) => a+b.details.length, 0),
            dataIndex: 'details',
            width: 50,
            render: (cell) => cell.length
          },
          {
            title: `$${fixedData.reduce((a,b) => a+b.details.reduce((_a,_b) => _a+_b.totalPriceUsd, 0), 0).toLocaleString("en-US")}`,
            dataIndex: 'details',
            width: 150,
            render: (cell) => cell.reduce((a,b) => a+b.totalPriceUsd, 0).toLocaleString(`en-US`)
          },
          {
            title: '$Cost reduction',
            dataIndex: 'details',
            width: 150,
            render: (cell) => cell.reduce((a,b) => a+b.costReduction, 0).toLocaleString(`en-US`)
          },
          {
            title: 'Comment',
            dataIndex: 'details',
            render: (cell) => {              
              return (
                <span className="line-clamp-2">
                  {
                    cell.filter((obj1, i, arr) => 
                      cell.findIndex(obj2 => (obj2.commentText === obj1.commentText)) === i
                    ).reduce((a,b) => a+`${b.commentText}, `, '')
                  }
                </span>
              )
            }
          },
          {
            title: <span className="text-nowrap truncate overflow-hidden">Difference in price (recommending vs cheapest price)</span>,
            children: [
              {
                title: 'Total value',
                dataIndex: 'details',
                width: 150,
                render: (cell) => cell.reduce((a,b) => a+b.priceDifferenceTotal, 0).toLocaleString(`en-US`)
              },
              {
                title: 'Percentage',
                dataIndex: 'details',
                width: 50,
                render: (cell) => (cell.reduce((a,b) => a+b.priceDifferencePercent, 0)/cell.length).toLocaleString(`en-US`)+'%'
              },
            ]
          }
        ]}
        rowKey={"id"}
        expandable={{
          expandedRowRender: rec => {
            return (
              <Table 
                pagination={false} size="small"
                dataSource={rec.details}
                columns={[
                  {
                    title: '#',
                    dataIndex: 'index',
                    width: 50,
                    render: (cell, rec, index) => index+1
                  },
                  {
                    title: 'Material #',
                    dataIndex: 'materialNumber',
                    width: 100
                  },
                  {
                    title: 'Criticality',
                    dataIndex: 'criticality',
                    width: 50
                  },
                  {
                    title: 'Description',
                    dataIndex: 'description',
                  },
                  {
                    title: 'Quantity',
                    dataIndex: 'shipQty',
                    width: 50
                  },
                  {
                    title: 'UOM',
                    dataIndex: 'shipUOM',
                    width: 50
                  },
                  {
                    title: 'Unit price',
                    dataIndex: 'unitPriceUsd',
                    width: 150
                  },
                  {
                    title: 'Total',
                    dataIndex: 'totalPriceUsd',
                    width: 150
                  },
                  {
                    title: 'Cost reduction',
                    dataIndex: 'costReduction',
                    width: 150
                  },
                  {
                    title: 'Price difference',
                    dataIndex: 'priceDifferenceTotal',
                    width: 150,
                    render: cell => cell?.toLocaleString("en-US")
                  },
                  {
                    title: 'Differcnce percent',
                    dataIndex: 'priceDifferencePercent',
                    width: 150,
                    render: cell => cell?.toLocaleString("en-US")+'%'
                  },
                ]}>
              </Table>
            )
          }
        }}>

      </Table>
    </div>
  )
}

export default OtherRecommended