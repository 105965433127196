export default (permissions) => {
    return [
      {
        label: 'Name',
        name: 'name',
        rules: [{required: true, message: 'Name is required!'}],
        className: 'col-span-12 mb-0',
        inputProps: {
          className: 'w-full',
        }
      },
      {
        label: 'Team name',
        name: 'teamName',
        rules: [{required: true, message: 'Team name is required!'}],
        className: 'col-span-12 mb-0',
        inputProps: {
          className: 'w-full',
        }
      },
      {
        label: 'Permissions',
        name: 'permissions',
        rules: [{required: true, message: 'Atlest 1 permission is required!'}],
        className: 'col-span-12 mb-0',
        type: "select",         
        inputProps: {
          mode: 'multiple',
          className: 'w-full',
          options: permissions,
          placeholder: 'Select permission'
        }
      },
    ]
  }