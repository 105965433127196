import React, { useEffect, useMemo, useState } from 'react'
import { Button, Menu, Modal, Form as AntForm, Dropdown, Checkbox, Tooltip, Input, Card, Tag } from 'antd'
import { EditOutlined, PlusCircleOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Form, MainTable, Filters } from 'components'
import { instance } from 'utils/axios'
import fields from './fields'
import { Link } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment'

const { Search } = Input

function Survey() {
  const [ data, setData ] = useState({
    total: 0,
    data: []
  })
  const [ currentPage, setCurrentPage ] = useState(1)
  const [ open, setOpen ] = useState(false)
  const [ editData, setEditData ] = useState(null)
  const [ isEdit, setIsEdit ] = useState(false)
  const [ loading, setLoading ] = useState(true)
  const [ selectedRowKeys, setSelectedRowKeys ] = useState([])
  const [ hasMore, setHasMore ] = useState(true)
  const [ searchValue, setSearchValue ] = useState('')
  const [ modal, contextHolder ] = Modal.useModal();
  const [ form ] = AntForm.useForm();

  useEffect(() => {
    fetchData()
  },[])

  const fetchData = () => {
    instance({
      method: 'get',
      url: '/survey',
      params: {
        wcode: 'survey',
        page: currentPage,
        pageSize: 20
      }
    }).then((res) => {
      if(res.data.responseData.data.length < 20) {
        setHasMore(false)
      }
      setData(res.data.responseData)
    }).catch((err) => {
    }).then(() => setLoading(false))
  }

  const fetchNext = () => {
    instance({
      method: 'get',
      url: '/survey',
      params: {
        wcode: 'survey',
        page: currentPage+1,
        pageSize: 20
      }
    }).then((res) => {
      if(res.data.responseData.data.length < 20) {
        setHasMore(false)
      }
      else {
        setCurrentPage(currentPage+1)
      }
      setData(prev => ({total: res.data.responseData.total, data: [...prev.data, ...res.data.responseData.data]}))
    }).catch((err) => {
    }).then(() => setLoading(false))
  }

  const handleDelete = (row) => {
    modal.confirm({
      title: 'Warning!',
      content: 'You are about to delete this row of data',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: 'delete',
            url: `survey/${row.id}?wcode=survey`
          }).then((res) => {
            resolve()
          }).catch((err) => {
            reject()
          }).then(() => fetchData())
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

  const handleSubmit = (values) => {
    setLoading(true);
    if (editData) {
      instance({
        method: 'put',
        url: '/survey?wcode=survey', 
        data: {
          id: editData.id,
          ...values,
          image: values.image[0]?.response?.responseData ?? null
        }
      })
      .then((res) => {
        handleCloseModal();
        fetchData();
      })
      .catch((err) => {
      })
      .finally(() => setLoading(false));
    } else {
      instance({
        method: 'post',
        url: '/survey?wcode=survey',
        data: {
          ...values,
          image: values.image[0]?.response?.responseData ?? null
        }
        
      })
      .then((res) => {
        handleCloseModal();
        fetchData();
      })
      .catch((err) => {
      })
      .finally(() => setLoading(false));
    }
  }
  
  const handleAdd = () => {
    setIsEdit(true)
    setEditData(null)
    setOpen(true)
  }

  const handleEdit = (row) => {
    setEditData(row)
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
    form.resetFields()
  }

  const filteredData = useMemo(() => {
    return data.data.filter(foo => foo.name.toLowerCase().includes(searchValue.toLowerCase()))
  }, [data, searchValue])
  
  return (
    <div className='p-4 grid grid-cols-12 gap-3'>
      <div className="col-span-12">
        <Filters wcode="survey"/>
      </div>
      <div className="col-span-12">
        <div className="flex flex-col gap-3">
          <Search 
            placeholder="Search" 
            size="large" 
            className='border-none' 
            enterButton 
            onChange={e => setSearchValue(e.target.value)} />
          <div className="bg-white p-5 font-semibold flex  items-center justify-between">
            {filteredData.length} of {data.total} Results
            <div className='flex gap-2'>
              <Tooltip title={"Add new survey"}>
                <Button type="primary" onClick={handleAdd}>
                  Add new survey
                </Button>
              </Tooltip>
            </div>
          </div>
          <div className="px-4">
            <Checkbox onChange={e => {
              if(e.target.checked) {
                setSelectedRowKeys(filteredData.map(foo => foo.id))
              }
              else {
                setSelectedRowKeys([])
              }
            }}
            checked={filteredData.length == selectedRowKeys.length}>
              Check all
            </Checkbox>
          </div>
          <div className="flex flex-col gap-1">
            <InfiniteScroll
              dataLength={data.data.length}
              next={fetchNext}
              loader={<div className='text-center font-bold'>Fetching...</div>}
              className="flex flex-col gap-1"
              hasMore={hasMore}
              endMessage={
                <p className="text-center font-bold">
                  No more data
                </p>
              }>
              {
                filteredData.map((_survey, _surveyIndex) => {
                  return (
                    <Card 
                      type="inner" 
                      key={`supplier-${_surveyIndex}`} 
                      className={"p-0 rounded-none transition-all"}>
                      <div className="grid grid-cols-12 gap-x-2">
                        <div className='col-span-7 flex items-center gap-4 p-4'>
                          <div>
                            <Checkbox 
                              checked={selectedRowKeys.find(foo => foo == _survey.id)}
                              onChange={e => {
                                console.log(e)
                                if(e.target.checked) {
                                  setSelectedRowKeys(prev => [...prev, _survey.id])
                                }
                                else {
                                  setSelectedRowKeys(prev => prev.filter(foo => foo != _survey.id))
                                }
                              }}/>
                          </div>
                          <div className="w-12 h-12 overflow-hidden rounded-full flex justify-center items-center text-white bg-primary text-3xl">
                            <img className="w-full" src={process.env.REACT_APP_UPLOAD_URL+_survey.image}></img>
                          </div>
                          <div className="flex-1 overflow-hidden">
                            <Tooltip title={_survey.name}>
                              <Link to={`${_survey.id}`} state={_survey} className="text-lg text-primary overflow-hidden text-ellipsis whitespace-nowrap hover:underline">{_survey.name}</Link>
                            </Tooltip>
                            <div className='text-mute'>ID: {_survey.id}</div>
                          </div>
                        </div>
                        <div className='col-span-5 flex justify-between'>
                          <div className="flex p-4 gap-4">
                            <div className="flex flex-col items-center">
                              <label className="text-mute">Date</label>
                              <div className="font-semibold">
                                {moment(_survey.startDate).format("YYYY-MM-DD")}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  )
                })
              }
            </InfiniteScroll>
            
          </div>
        </div>
      </div>
      <Modal
        title={editData ? <div className='flex items-center justify-start gap-2'><EditOutlined/>Edit</div> : <div className='flex items-center justify-start gap-2'><PlusCircleOutlined/>Add</div>}
        open={open}
        destroyOnClose
        maskClosable={false}
        okText='Save'
        onOk={form.submit}
        onCancel={handleCloseModal}
      >
        <Form
          form={form}
          fields={fields()}
          length={data.length}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          isEdit={isEdit}
          className='gap-4'
        />
      </Modal>
      {contextHolder}
    </div>
  )
}

export default Survey
