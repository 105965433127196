import React, { useContext, useLayoutEffect } from 'react'
import axios from "axios";
import ls from "./ls";
import { AuthContext } from 'contexts';

const instance = axios.create()
instance.defaults.baseURL = process.env.REACT_APP_MAIN_API_URL;
instance.interceptors.request.use(
	async (config) => {
		const userInfo = ls.get("userInfo");
		const token = userInfo && userInfo.jwtToken;
		if (token) {
			config.headers["Authorization"] = `Bearer ${token}`;
		}
		return config;
	},
	function (err) {
		return Promise.reject(err);
	}
);

function MainInstance(props) {
	const {action} = useContext(AuthContext);

	useLayoutEffect(() => {
		instance.interceptors.response.use(
			function (response) {
				if(response.config.method === 'delete') {
					action.onSuccess('Successfully deleted!')
				}
				else if(response.config.method === 'post') {
					if(response.config.url === 'login'){
						action.onSuccess('Logged in successfully')
					}
					else if(response.config.url === 'forgotpassword'){
						action.onSuccess('Successful!')
					}
					else{
						action.onSuccess('Successful!')
					}
				}
				else if(response.config.method === 'put') {
					action.onSuccess('Edited successfully!')
				}
				else if(response.config.method === 'patch') {
					action.onSuccess('Successful!')
				}
				return response;
			},
			function (error) {
				if(error.response) {
					if(error.response && error.response.status === 401){
						action.logout()
					}
					else if(error.response && error.response.status == 403) {
						action.onError("Access denied")
					}
					else if(error.response && error.response.status == 400){
						if(error.response.data && error.response.data.code == 400) {
							action.onError(error.response.data.message)
						} else if(error.response.data && error.response.data.code == 412){
							let tmp = []
							Object.keys(error.response.data.responseData).map((foo, fooindex) => {
								// if(fooindex != 0) {
								// 	tmp += "\n"
								// }
								if(error.response.data.responseData[foo].length > 0) {
									// tmp.push(<div>{foo}</div>)
									error.response.data.responseData[foo].map((_text, _textIndex) => {
										// tmp += "\n"
										tmp.push(<div>{"\u2022"+_text}</div>)
									})
								}
							})
							console.log(tmp)
							action.onError(tmp)
						}
						else {
							action.onError('Error please try again later!')
						}
					}
				}
				return Promise.reject(error);
			}
		);
	}, [])


	return (
		<>{props.children}</>
	)
}

export { instance }

export default MainInstance
