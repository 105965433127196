import { useState, useEffect } from 'react'
import { Form } from 'components'
import { Button, Form as AntForm, Card, Input, Select, Divider } from 'antd'
import { instance } from 'utils/axios'
import { useOutletContext } from 'react-router-dom'

const ContactDetail = ({state}) => {
  const [ loading, setLoading ] = useState(false)
  const [ data ] = useOutletContext()
  const [ form ] = AntForm.useForm();
  const { supplierContacts } = data

  useEffect(() => {
    form.setFieldsValue({contacts: data})
  }, [data])
  
  return (
    <div className='flex flex-col gap-3'>
      {
        supplierContacts.length > 0 ?
        supplierContacts.map((foo, fooIndex) => {
          return (
            <Card key={`contact-${fooIndex}`} size="small">
              <div className="font-bold text-lg">
                1. Company details
              </div>
              <Divider className="my-3"></Divider>
              <div className="grid grid-cols-4 gap-3">
                <div>
                  <label className="font-semibold">Full name:</label>
                  <div className="text-mute">{foo.fullName ? foo.fullName : 'No name'}</div>
                </div>
                <div>
                  <label className="font-semibold">Phone:</label>
                  <div className="text-mute">{foo.phone1 ? foo.phone1 : 'No phone number'}</div>
                </div>
                <div>
                  <label className="font-semibold">Phone 2:</label>
                  <div className="text-mute">{foo.phone2 ? foo.phone2 : 'No phone number'}</div>
                </div>
                <div>
                  <label className="font-semibold">Email:</label>
                  <div className="text-mute">{foo.email ? foo.email : 'No email'}</div>
                </div>
                <div>
                  <label className="font-semibold">Address line:</label>
                  <div className="text-mute">{foo.address1 ? foo.address1 : 'No address'}</div>
                </div>
                <div>
                  <label className="font-semibold">Address line 2 / Soum:</label>
                  <div className="text-mute">{foo.soum ? foo.soum : 'No address'}</div>
                </div>
              </div>
            </Card>
          )
        })
        :
        <div className="text-center font-semibold">
          No contact information
        </div>
      }
      {/* <Form
        disabled={true}
        form={form}
        fields={[]}
        layout="vertical"
        onFinish={() => {}}
        className='gap-4'>  
        <AntForm.List name="contacts">  
          {(fields, {add, remove}) => (
            <>
              {
                fields.map(({name, key, ...restFields}) => (
                  <Card title={`${key+1}. Contact`} className="col-span-12 rounded-2xl" key={key} {...restFields}>
                    <AntForm.Item label="Address line" name={[name, "address1"]} className='col-span-12'>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="Address line 2 / Soum" name={[name, "soum"]}>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="Address line 3" name={[name, "address2"]}>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="Postcode or zipcode" name={[name, "zipcode"]}>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="Town/City/Aimag" name={[name, "town"]}>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="State/Province" name={[name, "town"]}>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="Country" name={[name, "countryId"]}>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="Phone" name={[name, "phone1"]}>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="Phone 2" name={[name, "phone2"]}>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="E-mail" name={[name, "email"]}>
                      <Input></Input>
                    </AntForm.Item>
                  </Card>
                ))
              }
            </>
          )}
        </AntForm.List>
      </Form> */}
      
    </div>
  )
}

export default ContactDetail