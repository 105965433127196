import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { EditOutlined, PlusCircleOutlined, PlusOutlined, MinusCircleOutlined, MoreOutlined } from '@ant-design/icons'
import { Button, Form as AntForm, Modal, Table, Space, Input, Dropdown } from 'antd';
import { MainTable, Form } from 'components';
import { instance } from 'utils/axios';
import PrequalificationAnswers from './companyQuestionAnswers';

const typeEnum = {
  1: 'Numeric',
  2: 'Text',
  3: 'Combobox',
  4: 'Radio button',
  5: 'Calendar',
  6: 'Text area',
  7: 'File upload',
  8: 'Checkbox',
  9: 'Table'
}

  
const items = ({row, handleEdit, handleDelete}) => {  
  return (
    [
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleEdit(row)
        }}>Edit</a>,
        key: 0
      },
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleDelete(row.id)
        }}>Delete</a>,
        key: 1
      },
    ]
  )
}

const fields = (data, editorTypes) => {
  return [
    {
      label: 'Question',
      name: 'title',
      rules: [{required: true, message: 'Question is required!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Description',
      name: 'description',
      type: 'textarea',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Question type',
      name: 'editorTypeId',
      className: 'col-span-12 mb-0',
      type: 'select',
      inputProps: {
        className: 'w-full',
        options: editorTypes.map(foo => ({label: typeEnum[foo.id], value: foo.id}))
      }
    },    
    {
      type: 'dependent',
      className: "col-span-12 mb-0",
      dependentName: 'editorTypeId',
      dependentValue: 9,
      children: [
        {
          label: 'Row header',
          name: 'table-row',
          type: 'select',
          className: "col-span-12 mb-0",
          inputProps: {
            className: 'w-full',
            mode: 'tags'
          }
        },
        {
          label: 'Column name',
          name: 'table-col',
          type: 'select',
          className: "col-span-12 mb-0",
          inputProps: {
            className: 'w-full',
            mode: 'tags'
          }
        }
      ]      
    },
    {
      label: '',
      type: 'component',
      component: (
        <AntForm.Item noStyle shouldUpdate={(prev, next) => prev.editorTypeId != next.editorTypeId } className="col-span-12">
          {({getFieldValue}) => {
            if(getFieldValue("editorTypeId") == 3 || getFieldValue("editorTypeId") == 4) {
              return ( 
                <AntForm.List name="options" className='col-span-12'>
                  {(fields, {add ,remove}) => {
                    return (
                      <>
                        {fields.map(({key, name, ...restFields}) => (
                          <Space className='col-span-12 flex'>
                            <AntForm.Item {...restFields} name={[name, 'name']} className='flex-1 mb-0'>
                              <Input className='w-full'></Input>
                            </AntForm.Item>
                            <MinusCircleOutlined onClick={() => {remove(name)}} />
                          </Space>
                        ))}
                        <Button className='col-span-12' type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                          Add answer
                        </Button>
                      </>
                    )
                  }}
                </AntForm.List>
              )
            }
          }}
        </AntForm.Item>
      )
    },
    {
      type: 'divider'
    },
    {
      label: 'Parent question',
      name: 'parentId',
      type: 'select',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
        options: [
          {value: null, label: 'Empty'},
          ...data.map((foo, fooIndex) => ({label: foo.title, value: foo.id}))
        ]
      }
    },
    {
      label: 'Option',
      name: 'optionId',
      type: 'select',
      dependentIndex: 'parentId',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
        optionsUrl: '/PreQualificationQuestionOption'
      }
    },
  ]
}

const columns = ({currentPage, handleEdit, handleDelete}) => {
  return ([
    {
      title: '#',
      dataIndex: 'No',
      key: 'No',
      width: 40,
      align: 'center',
      render: (text, row, i) => (
        <span>{currentPage ? currentPage+i+1 : i+1}</span>
      )
    },
    {
      title: 'Question',
      dataIndex: 'title',
      key: 'title',
      filterType: "search",
      render: (text, row) => (
        <span to={`${row.id}`}>{text}</span>
      )
    },
    {
      title: 'Required',
      dataIndex: 'isRequired',
      key: 'isRequired',
      filterType: "search",
      width: 120,
      render: (text, row) => (
        <span>{text ? 'True' : 'False'}</span>
      )
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 80,
      render: (text, row) => (
        <Dropdown 
        trigger={['click']} 
        menu={{items: items({row, handleEdit, handleDelete}),}}>
        <a onClick={e => e.preventDefault()}>
          <Space>
            <MoreOutlined className='text-xl'/>
          </Space>
        </a>
      </Dropdown>
      )
    },
  ])
}

const PrequalificationGroupDetail = ({question}) => {
  const [ data, setData ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const [ modal, contextHolder ] = Modal.useModal();
  const [ form ] = AntForm.useForm();
  const [ open, setOpen ] = useState(false)
  const [ editData, setEditData ] = useState(null)
  const [ isEdit, setIsEdit ] = useState(false)
  const [ editorType, setEditorType ] = useState([])
  const location = useLocation()

  useEffect(() => {
    fetchData()
    fetchEditorType()
  }, [question])

  const fetchEditorType = () => {
    instance({
      method: 'get',
      url: `EditorType?wcode=company-question-config-detail`
    }).then(res => {
      // console.log(res.data);
      setEditorType(res.data.responseData)
    }).catch(err => {
      console.log(err);
    })
  }

  const fetchData = () => {
    instance({
      method: 'get',
      url: `/CompanyQuestion/${question.id}?wcode=company-question-config-detail`
    }).then(res => {
      setData(res.data.responseData)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const handleAdd = () => {
    setEditData(null)
    setOpen(true)
  }

  const handleEdit = (_row) => {
    setEditData(_row)
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
    form.resetFields()
  }

  const handleSubmit = (values) => {
    setLoading(true)
    if(editData) {
      instance({
        method: 'put',
        url: `/CompanyQuestion?wcode=company-question-config-detail`,
        data: {
          ...values,
          optionId: parseInt(values.optionId) ? parseInt(values.optionId): null,  
          companyQuestionGroupId: question.id,
          id: editData.id
        }
      }).then(res => {
        setOpen(false)
        fetchData()
      }).catch(err => {
        
      }).then(() => {
        setLoading(false)
      })
    }
    else {
      instance({
        method: 'post',
        url: `/CompanyQuestion?wcode=company-question-config-detail`,
        data: {
          ...values,
          companyQuestionGroupId: question.id,
          optionId: values.optionId ? values.optionId : null,
        }
      }).then(res => {
        setOpen(false)
        fetchData()
      }).catch(err => {
        
      }).then(() => {
        setLoading(false)
      })
    }    
  }

  const handleDelete = (id) => {
    modal.confirm({
      title: 'Warning!',
      content: 'You are about to delete this row of data',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: 'delete',
            url: `/CompanyQuestion/${id}?wcode=company-question-config-detail`
          }).then((res) => {
            resolve()
          }).catch((err) => {
            reject()
          }).then(() => fetchData())
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

  return (
    <div>
      <MainTable
        dataTable={data}
        columns={columns({handleEdit, handleDelete})}
        loading={loading}
        handleAdd={handleAdd}
        title={question.name}
        expandable={{
          expandedRowRender: rec => <PrequalificationAnswers record={rec}></PrequalificationAnswers>,
          rowExpandable: rec => rec.editorTypeId == 3 || rec.editorTypeId == 4 || rec.editorTypeId == 8
        }}>

      </MainTable>
      <Modal
        title={editData ? <div className='flex items-center justify-start gap-2'><EditOutlined/>Edit</div> : <div className='flex items-center justify-start gap-2'><PlusCircleOutlined/>Add</div>}
        open={open}
        destroyOnClose
        maskClosable={false}
        okText='Save'
        onOk={form.submit}
        onCancel={handleCloseModal}
      >
        <Form
          initialValue={{isUseCalculation: true}}
          form={form}
          fields={fields(data, editorType, form)}
          length={data.length}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          isEdit={isEdit}
          className='gap-4'
        />
      </Modal>
      {contextHolder}
    </div>
  );
}

export default PrequalificationGroupDetail;
