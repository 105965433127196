import { useEffect, useMemo, useState } from "react"
import { instance } from "utils/axios"
import Filter from './Filter'
import { Button, Input, Skeleton, Card, Modal, Form, Dropdown } from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import InfiniteScroll from 'react-infinite-scroll-component';
import DocumentForm from "./DocumentForm";

const { Search } = Input

const fileEnum = [
    {
      value: 'application/pdf,application/vnd.ms-excel',
      name: 'PDF '
    }, 
    {
      value: '.xls, .csv',
      name: 'Excel '
    },
    {
      value: '.doc',
      name: 'Word '
    },
    {
      value: 'image/png, image/jpeg, image/jpg',
      name: 'Image '
    }
  ]

const SystemDocuments = () => {
  const [ data, setData ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const [ searchValue, setSearchValue ] = useState("")
  const [ hasMore, setHasMore ] = useState(true)
  const [ editData, setEditData ] = useState(null)
  const [ formModalOpen, setFormModalOpen ] = useState(false)
  const [ submitting, setSubmitting ] = useState(false)
  const [ form ] = Form.useForm()
  const [ modal, contextHolder ] = Modal.useModal()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `SystemDocument?wcode=system-documents`
    }).then(res => {
      if(res.data.responseData.length > 0){
        setData(res.data.responseData)
      }
      else {
        setHasMore(false)
      }
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const fetchNext = () => {
    instance({
      method: 'get',
      url: `SystemDocument?wcode=system-documents`
    }).then(res => {
      if(res.data.responseData.length > 0) {
        setData(prev => [...prev, ...res.data.responseData])
      }
      else {
        setHasMore(false)
      }
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const handleFormFinish = (values) => {
    setSubmitting(true)
    console.log(values)
    
    if(editData) {
      instance({
        method: 'put',
        url: `SystemDocument?wcode=system-documents`,
        data: {
          id: editData.id,
          ...values,
          contentType: typeof values.contentType == 'string' ? values.contentType : values.contentType.join("&"),
          isRequired: values.isRequired ? 1 :0 
        }
      }).then(res => {
        setFormModalOpen(false)
        form.resetFields()
      }).catch(err => {

      }).then(() => {
        setSubmitting(false)
      })
    }
    else {
      instance({
        method: 'post',
        url: `SystemDocument?wcode=system-documents`,
        data: {
          ...values,
          contentType: values.contentType.join("&"),
          isRequired: values.isRequired ? 1 :0 
        }
      }).then(res => {
        setFormModalOpen(false)
        fetchData()
        form.resetFields()
      }).catch(err => {

      }).then(() => {
        setSubmitting(false)
      })
    }
  }

  const handleDelete = (_data) => {
    modal.confirm({
      title: 'Warning!',
      content: 'You are about to delete this row of data',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: 'delete',
            url: `/SystemDocument/${_data.id}`,
            params: {
              wcode: 'system-documents'
            }
          }).then((res) => {
            resolve()
          }).catch((err) => {
            reject()
          }).then(() => fetchData())
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

  const filteredData = useMemo(() => {
    return data
  }, [data])

  const renderAccepts = (ai) => {
    let tmp = ai

    fileEnum.map((foo, fooIndex) => {
      tmp = tmp.replaceAll(foo.value, foo.name)
    })

    return tmp
  }
  
  return (
    <div className="p-4">
      <div className="grid grid-cols-12">
        {/* <div className="col-span-3">
          <Filter/>
        </div> */}
        <Card className="col-span-12" size="small">
          <div className="mb-3">
            <Search 
              placeholder="Search" 
              size="large" 
              className='border-none' 
              enterButton 
              onChange={e => setSearchValue(e.target.value)} />
          </div>
          <div className="bg-white mb-3 font-semibold flex items-center justify-between">
            {filteredData.length} of {data.length} Results
            <div className='flex gap-2'>
              <Button onClick={() => setFormModalOpen(true)} type="primary">
                Add new document
              </Button>
            </div>
          </div>
          {
            loading ?
            <Skeleton></Skeleton>
            :
            <InfiniteScroll
              dataLength={data.length}
              next={fetchNext}
              loader={<div className="text-center font-bold">Fetching...</div>}
              className="flex flex-col gap-1"
              hasMore={hasMore}
              endMessage={<p className="text-center font-bold">No more document data</p>}>
              <table className="oyu-table">
                <colgroup>
                  <col style={{width: '50px'}}></col>
                  <col style={{width: '250px'}}></col>
                  <col></col>
                  <col></col>
                  <col style={{width: '50px'}}></col>
                </colgroup>
                <thead>
                  <tr>
                    <th style={{textAlign: 'left'}}>#</th>
                    <th style={{textAlign: 'left'}}>System document name</th>
                    <th style={{textAlign: 'left'}}>Description</th>
                    <th style={{textAlign: 'left'}}>Accepts</th>
                    <th style={{textAlign: 'left'}}></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredData.map((foo, fooIndex) => {
                      return (
                        <tr key={`tender-${fooIndex}`} className="hover:bg-gray-100 transition-all">
                          <td>
                            {fooIndex+1}
                          </td>
                          <td>
                            {foo.name}
                          </td>
                          <td>
                            {foo.description}
                          </td>
                          <td>
                            {renderAccepts(foo.contentType)}
                          </td>
                          <td>
                            <Dropdown 
                              trigger={['click']} 
                              menu={{items: [
                                {
                                  label: (
                                    <a onClick={e => {
                                      e.preventDefault()
                                      setEditData(foo)
                                      setFormModalOpen(true)
                                    }}>
                                      Edit
                                    </a>
                                  ),
                                  key: 0
                                },
                                {
                                  label: (
                                    <a onClick={e => {
                                      e.preventDefault()
                                      handleDelete(foo)
                                    }}>
                                      Delete
                                    </a>
                                  ),
                                  key: 1
                                },
                              ]}}>
                              <a onClick={e => e.preventDefault()} className="flex justify-center items-center h-full">
                                <MoreOutlined className='text-xl'/>
                              </a>
                            </Dropdown>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
              
            </InfiniteScroll>
          }
        </Card>
      </div>
      <Modal 
        title={editData ? 'Edit' : 'Add new'}
        open={formModalOpen}
        onOk={() => form.submit()}
        destroyOnClose
        onCancel={() => {
          setEditData(null)
          setFormModalOpen(false)
          form.resetFields()
        }}
        okText="Save"
        confirmLoading={submitting}>
        <DocumentForm form={form} editData={editData} handleFinish={handleFormFinish}/>
      </Modal>
      {contextHolder}
    </div>
  )
}

export default SystemDocuments