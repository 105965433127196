import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { EditOutlined, PlusCircleOutlined, MoreOutlined } from '@ant-design/icons'
import { Button, Form as AntForm, Modal, Space, Dropdown, Skeleton, Card, Radio, Input, Tooltip, Tag } from 'antd';
import { MainTable, Form } from 'components';
import { instance } from 'utils/axios';
import { message } from 'antd';
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component';

const { Search } = Input

const items = ({row, handleEdit, handleDelete}) => {  
  return (
    [
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleEdit(row)
        }}>Edit</a>,
        key: 0
      },
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleDelete(row.id)
        }}>Delete</a>,
        key: 1
      },
    ]
  )
}

const fields = () => {
  return [
    {
      label: 'Name',
      name: 'name',
      rules: [{required: true, message: 'Name is required!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Description',
      name: 'description',
      rules: [{required: true, message: 'Description is required!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Status',
      name: 'status',
      rules: [{required: true, message: 'Status is required!'}],
      type: 'select',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
        options: [
          {
            label: 'Active',
            value: 1
          },
          {
            label: 'Inactive',
            value: 0
          },
        ]
      }
    },
  ]
}

const columns = ({currentPage, handleEdit, handleDelete}) => {
  return ([
    {
      title: '№',
      dataIndex: 'No',
      key: 'No',
      width: 40,
      align: 'center',
      render: (text, row, i) => (
        <span>{currentPage ? currentPage+i+1 : i+1}</span>
      )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      filterType: "search",
      render: (text, row) => (
        <Link className='text-link hover:underline hover:text-link' to={`${row.id}`} state={row}>{text}</Link>
      )
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 80,
      render: (text, row) => (
        <Dropdown 
          trigger={['click']} 
          menu={{items: items({row, handleEdit, handleDelete}),}}>
          <a onClick={e => e.preventDefault()}>
            <Space>
              <MoreOutlined className='text-xl'/>
            </Space>
          </a>
        </Dropdown>
      )
    },
  ])
}

const AuditConfigList = ({}) => {
  const [ data, setData ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const [ open, setOpen ] = useState(false)
  const [ editData, setEditData ] = useState(null)
  const [ isEdit, setIsEdit ] = useState(false)
  const [ modal, contextHolder ] = Modal.useModal();
  const [ activeTab, setActiveTab ] = useState(0)
  const [ page, setPage ] = useState(1)
  const [ hasMore, setHasMore ] = useState(true)
  const [ searchValue, setSearchValue ] = useState('')
  const [ form ] = AntForm.useForm();

  useEffect(() => {
    fetchData()
    setPage(1)
    setHasMore(true)
  }, [activeTab])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `/Audit/type/${activeTab+1}?wcode=audit-config-list`,
      params: {
        page: page, 
        pageSize: 20
      }
    }).then(res => {
      if(res.data.responseData.length < 20) {
        setHasMore(false)
      }
      setData(res.data.responseData)
      setPage(page+1)
    }).catch(err=> {

    }).then(() => {
      setLoading(false)
    })
  }

  const fetchNext = () => {
    instance({
      method: 'get',
      url: `/Audit/type/${activeTab+1}?wcode=audit-config-list`,
      params: {
        page: page, 
        pageSize: 20
      }
    }).then(res => {
      if(res.data.responseData.length < 20) {
        setHasMore(false)
      }
      setPage(page+1)
      setData(prev => ([...prev, ...res.data.responseData]))
    }).catch(err=> {

    }).then(() => {
      setLoading(false)
    })
  }

  const handleCloseModal = () => {
    setOpen(false)
    form.resetFields()
  }

  const handleEdit = (_row) => {
    setEditData(_row)
    setOpen(true)
  }

  const handleAddNew = () => {
    setEditData(null)
    setOpen(true)
  }

  const handleSubmit = (values) => {
    if(editData) {
      instance({
        method: 'put',
        url: `/Audit?wcode=audit-config-list`,
        data: {
          ...editData,
          ...values,
          auditType: activeTab+1
        }
      }).then(() => {
        setOpen(false)
      }).catch(() => {

      }).then(() => {
        setLoading(false)
        fetchData()
      })
    }
    else {
      instance({
        method: 'post',
        url: `/Audit?wcode=audit-config-list`,
        data: {
          ...values,
          auditType: activeTab+1
        }
      }).then(() => {
        setOpen(false)
      }).catch(() => {

      }).then(() => {
        setLoading(false)
        fetchData()
      })
    }
  }

  const handleDelete = (id) => {
    modal.confirm({
      title: 'Warning!',
      content: 'You are about to delete this row of data',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: 'delete',
            url: `/Audit/${id}?wcode=audit-config-list`
          }).then((res) => {
            resolve()
          }).catch((err) => {
            reject()
          }).then(() => fetchData())
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

  const filteredData = useMemo(() => {
    return data.filter(foo => foo.name.toLowerCase().includes(searchValue.toLowerCase()))
  }, [data, searchValue])

  return (
    <div className="p-4">
      <div className="grid grid-cols-12 gap-3">
        {/* <div className="col-span-3">
          <div className="col-span-3 flex flex-col gap-3">
            <div className="flex justify-between">
              <div className="text-mute text-xl py-1">
                Filters
              </div>
            </div>
            <Card title="Templates events" size="small" className="rounded-none">
              <Radio.Group onChange={handleChange} value={activeTab}>
                <Radio.Button value={0}>Desktop</Radio.Button>
                <Radio.Button value={1}>Physical</Radio.Button>
              </Radio.Group>
            </Card>
          </div>
        </div> */}
        <Card className='col-span-12 flex flex-col' size='small'>
          <div className="mb-3">
            <Search 
              placeholder="Search" 
              className='border-none' 
              enterButton 
              onChange={e => setSearchValue(e.target.value)} />
          </div>
          <div className="bg-white mb-3 font-semibold flex items-center justify-between">
            {filteredData.length} of {data.length} Results
            <div className='flex gap-2'>
              <Button onClick={handleAddNew} type="primary">
                Add new audit
              </Button>
            </div>
          </div>
          {
            loading ? 
            <Skeleton></Skeleton>
            :
            <InfiniteScroll
              dataLength={data.length}
              next={fetchNext}
              loader={<div className='text-center font-bold'>Fetching...</div>}
              className="flex flex-col gap-1"
              hasMore={hasMore}
              endMessage={
                <p className="text-center font-bold">
                  No more audit config data
                </p>
              }>
              <table className="oyu-table">
                <colgroup>
                  <col style={{width: '50px'}}></col>
                  <col></col>
                  <col style={{width: '100px'}}></col>
                  <col style={{width: '150px'}}></col>
                  <col style={{width: '150px'}}></col>
                  <col style={{width: '50px'}}></col>
                </colgroup>
                <thead>
                  <tr>
                    <th style={{textAlign: 'left'}}>#</th>
                    <th style={{textAlign: 'left'}}>Name</th>
                    <th>Status</th>
                    <th>Start date</th>
                    <th>End date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredData.map((foo, fooIndex) => {
                      return (
                        <tr key={`tender-${fooIndex}`} className="hover:bg-gray-100 transition-all">
                          <td>
                            {fooIndex+1}
                          </td>
                          <td>
                            <Link 
                              className="text-primary cursor-pointer hover:underline" 
                              to={`${foo.id}`} 
                              state={{...foo}}>
                              <div className="truncate">
                                <Tooltip title={foo.name}>
                                  <span className="truncate">
                                    {foo.name}
                                  </span>
                                </Tooltip>
                              </div>
                            </Link>
                          </td>
                          <td>
                            <Tag className="whitespace-normal w-[120px] text-center" color={foo.status == 0 ? "error" : "success"}>
                              {foo.status == 0 ? 'Inactive' : 'Active'}
                            </Tag>
                          </td>
                          <td>{moment(foo.startDate).format("YYYY-MM-DD HH:mm")}</td>
                          <td>{moment(foo.endDate).format("YYYY-MM-DD HH:mm")}</td>
                          <td>
                            <Dropdown 
                              trigger={['click']} 
                              menu={{items: items({row: foo, handleEdit, handleDelete})}}>
                              <a onClick={e => e.preventDefault()} className="flex justify-center items-center">
                                <Space className="px-3">
                                  <MoreOutlined className='text-xl'/>
                                </Space>
                              </a>
                            </Dropdown>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </InfiniteScroll>
          }
          {/* <MainTable
            dataTable={data}
            columns={columns({handleDelete, handleEdit})}
            loading={loading}>

          </MainTable> */}
        </Card>
      </div>
      <Modal
        title={editData ? <div className='flex items-center justify-start gap-2'>Edit</div> : <div className='flex items-center justify-start gap-2'>Add</div>}
        open={open}
        destroyOnClose
        maskClosable={false}
        okText='Save'
        onOk={form.submit}
        onCancel={handleCloseModal}
        width={"40%"}
      >
        <Form
          form={form}
          fields={fields()}
          length={data.length}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          isEdit={isEdit}
          className='gap-4'
        />
      </Modal>
      {contextHolder}
    </div>
  );
}

export default AuditConfigList;
