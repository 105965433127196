import { createBrowserRouter } from "react-router-dom";
import { Register, Login, Review } from 'modules/public';
import adminRoutes from './adminRoutes';
import { ProtectedRoute } from 'components';
import RootPage from "modules/core";
import PreviewTravel from "modules/admin/tender/components/TravelBidSummary/PreviewTravel";

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootPage/>,
    errorElement: <div className="h-screen flex items-center justify-center text-xl font-bold italic">ERROR</div>,
    children: [...adminRoutes].map(foo => {
      return {
        ...foo,
        element: foo.element && <ProtectedRoute routeItem={foo}>
          {foo.element}
        </ProtectedRoute>,
      }
    })
  },
  {
    path: '/login',
    element: <Login></Login>
  },
  {
    path: '/register',
    element: <Register></Register>
  },
  {
    path: '/review/:id',
    element: <Review></Review>
  },
  {
    path: '/preview-travel/:id',
    element: <PreviewTravel></PreviewTravel>
  },
])

export default router
