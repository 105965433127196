import { Button, Card, DatePicker, Form, Modal, Select } from "antd"
import { UserSwitchOutlined } from '@ant-design/icons'
import { useEffect, useState } from "react"
import { instance } from "utils/axios"

const Delegation = () => {
  const [ open, setOpen ] = useState(false)
  const [ users, setUsers ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const [ form ] = Form.useForm()

  useEffect(() => {
    instance({
      method: 'get',
      url: `user`
    }).then(res => {
      setUsers(res.data.responseData)
    })
  }, [])
  
  const handleSubmit = (values) => {
    setLoading(true)
    instance({
      method: 'post',
      url: `/UserDelegation`,
      data: values
    }).then(res => {
      form.resetFields()
      setOpen(false)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }
  
  return (
    <div className="p-4">
      <Card title="Delegation" size="small">
        <div className="flex gap-3 items-center">
          <div>
            <UserSwitchOutlined className="text-2xl"/>
          </div>
          <div className="grow">
            <div className="font-bold">User permission and data delegation:</div>
            <div>Delegate your permission and data to another user</div>
          </div>
          <div>
            <Button type='primary' onClick={() => setOpen(true)}>Delegate</Button>
          </div>
        </div>
      </Card>
      <Modal
        title="Delegate to user"
        open={open}
        onCancel={() => {
          setOpen(false)
        }}
        onOk={() => form.submit()}
        loading={loading}>
        <Form
          onFinish={handleSubmit}
          layout="vertical"
          form={form}>
          <Form.Item name="userId" label="Delegation user" rules={[{required: true}]}>
            <Select options={users.map(foo => ({value: foo.id, label: `${foo.firstName} ${foo.lastName}`}))}></Select>
          </Form.Item>
          <div className="grid grid-cols-2 gap-3">
            <Form.Item label="Start date" name="startDate" rules={[{required: true}]}>
              <DatePicker className="w-full">

              </DatePicker>
            </Form.Item>
            <Form.Item label="End date" name="endDate" rules={[{required: true}]}>
              <DatePicker className="w-full">

              </DatePicker>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default Delegation