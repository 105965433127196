import { Children, useEffect, useMemo, useState } from 'react';
import { Table, Transfer, Button, Skeleton, Form, Select, Collapse, TreeSelect, InputNumber, Modal, Input, Tag } from 'antd';
import { instance } from 'utils/axios';

const { Panel } = Collapse


const prequalificationStatusEnum = {
  0: {
    title: 'Not qualified',
    color: 'red'
  },
  1: {
    title: 'Prequalified',
    color: 'green'
  }
}

const TableTransfer = ({ leftColumns, rightColumns, loading, ...restProps }) => (
  <Transfer {...restProps}>
    {({
      direction,
      filteredItems,
      onItemSelect,
      onItemSelectAll,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      const columns = direction === 'left' ? leftColumns : rightColumns;
      const rowSelection = {
        getCheckboxProps: () => ({
          disabled: listDisabled,
        }),
        onChange(selectedRowKeys) {
          onItemSelectAll(selectedRowKeys, 'replace');
        },
        selectedRowKeys: listSelectedKeys,
        selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
      };
      return (
        <Table
          loading={loading}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={filteredItems}
          size="small"
          style={{
            pointerEvents: listDisabled ? 'none' : undefined,
          }}
          onRow={({ key, disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled || listDisabled) {
                return;
              }
              onItemSelect(key, !listSelectedKeys.includes(key));
            },
          })}
        />
      );
    }}
  </Transfer>
);

const columns = [
  {
    dataIndex: 'id',
    title: 'ID',
    width: 50
  },
  {
    dataIndex: 'name',
    title: 'Name',
  },
  {
    dataIndex: 'supplierTierName',
    title: 'Tier',
  },
  {
    dataIndex: 'vendorNumber',
    title: 'Vendor #',
  },
];

const supplierFilterItem = [
  {
    key: '0',
    label: 'Supplier filters',
    children: <></>
  }
]

const SuppliersInformation = ({setActiveStep, setTenderData, tenderData, type}) => {
  const [ targetKeys, setTargetKeys ] = useState([]);
  const [ loading, setLoading ] = useState(true)
  const [ suppliers, setSuppliers ] = useState([])
  const [ allSuppliers, setAllSuppliers ] = useState([])
  const [ supplierLookup, setSupplierLookup ] = useState([])
  const [ fetching, setFetching ] = useState(false)
  const [ form ] = Form.useForm()
  
  useEffect(() => {
    console.log(tenderData)
    form.setFieldsValue(tenderData)
    if(tenderData.supplierLookup) {
      setSuppliers(tenderData.supplierLookup)
    }
    else {
      fetchSuppliers()
    }
    
    if(tenderData.supplierIds && tenderData.supplierIds.length > 0) {
      setTargetKeys(tenderData.supplierIds)
      form.setFieldValue("supplierIds", tenderData?.supplierIds)
    }
    else if(tenderData.suppliers) {
      setTargetKeys(tenderData.suppliers.map(foo => typeof foo == "object" ? foo.id : foo))
      form.setFieldValue("supplierIds", tenderData?.suppliers?.map(foo => typeof foo == "object" ? foo.id : foo))
    }
    else if(tenderData.productTypeIds && tenderData.productTypeIds) {
      setTargetKeys(allSuppliers.map(foo => foo.id))
      form.setFieldValue("supplierIds", allSuppliers.map(foo => foo.id))
    }
  }, [])

  useEffect(() => {
    if(targetKeys) {
      setSupplierLookup(allSuppliers.filter(foo => targetKeys.includes(foo.id)))
    }
  }, [targetKeys, allSuppliers])

  const handleFilter = (_filters) => {
    let tmp = Object.assign([], allSuppliers)
    
    if(_filters.tier && _filters.tier.length > 0) {
      tmp = tmp.filter(foo => _filters.tier.includes(foo.supplierTierId))
      // setTargetKeys(tmp.filter(foo => _filters.tier.includes(foo.supplierTierId)).map(foo => foo.id))
      // form.setFieldValue("supplierIds", tmp.filter(foo => _filters.tier.includes(foo.supplierTierId)).map(foo => foo.id))
    }
    if(_filters.preQualificationStatus && _filters.preQualificationStatus) {
      tmp = tmp.filter(foo => foo.preQualificationStatus == _filters.preQualificationStatus)
    }
    setTargetKeys(tmp.map(foo => foo.id))
    form.setFieldValue("supplierIds", tmp.map(foo => foo.id))
  } 
  
  const fetchSuppliers = () => {
    if(type == 3){
      instance({
        method: 'get',
        url: `/Supplier/find/travel?wcode=tender`,
      }).then(res => {
        setAllSuppliers(res.data.responseData)
        let tmp = tenderData.suppliersLookup ? tenderData.suppliersLookup : [] 
        setSuppliers([...new Set([...res.data.responseData, ...tmp ])])
      }).catch(err => {
        
      }).then(() => {
        setLoading(false)
      })
    }
    else if(type == 4){
      instance({
        method: 'get',
        url: `/Supplier/find/freight?wcode=tender`,
        // params: {
        //   difotScore: 0
        // }
      }).then(res => {
        setAllSuppliers(res.data.responseData)
        let tmp = tenderData.suppliersLookup ? tenderData.suppliersLookup : [] 
        setSuppliers([...new Set([...res.data.responseData, ...tmp ])])
      }).catch(err => {
        
      }).then(() => {
        setLoading(false)
      })
    }
    else {
      instance({
        method: 'get',
        url: `/Supplier/find?wcode=tender`,
        params: tenderData.productTypeIds && tenderData.productTypeIds.length > 0 && {
          productIds: tenderData.productTypeIds.map(foo => foo.id ? foo.id : foo)
        },
        paramsSerializer: {
          indexes: null
        },
      }).then(res => {
        if((!tenderData.supplierIds || tenderData.supplierIds.length == 0) && (!tenderData.suppliers || tenderData.suppliers.length == 0)) {
          setTargetKeys(res.data.responseData.map(foo => foo.id))
          form.setFieldValue("supplierIds", res.data.responseData.map(foo => foo.id))
        }
        setAllSuppliers(res.data.responseData)
        let tmp = tenderData.suppliersLookup ? tenderData.suppliersLookup : [] 
        setSuppliers([...new Set([...res.data.responseData, ...tmp ])])
      }).catch(err => {
        
      }).then(() => {
        setLoading(false)
      })
    }
  }

  const onChange = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys);
  };

  const filteredData = useMemo(() => {
    return [...new Set([...supplierLookup, ...suppliers])]
  }, [suppliers, supplierLookup])

  const handleSubmit = (values) => {
    setTenderData(prev => ({...prev, ...values, suppliersLookup: supplierLookup}))
    setActiveStep(2)
  }

  const handleAddSupplier = (addKeys, addLookup) => {
    setAllSuppliers(prev => ([...new Set([...prev, ...addLookup])]))
    setTargetKeys(prev => ([...new Set([...prev, ...addKeys])]))
    form.setFieldValue("supplierIds", [...new Set([...form.getFieldValue("supplierIds"), ...addKeys])])
  }
  
  return (
    <>
    {
      loading ?
      <Skeleton active></Skeleton>
      :
      <>
      {
        type == 1 ?
        <Eoifilter setSuppliers={setSuppliers}></Eoifilter>
        :
        <AddExistingSupplier type={type} handleAddSupplier={handleAddSupplier} handleFilter={handleFilter} tenderData={tenderData}/>
      }
      <Form 
        layout='vertical' 
        form={form} 
        onFinish={handleSubmit}>
        <Form.Item 
          name="supplierIds" 
          label="Invited suppliers" 
          rules={[
            {
              required: true, 
              message: 'Please choose at least 1 supplier!'
            }]}
          valuePropName={'targetKeys'}>
          <TableTransfer
            disabled={tenderData.fromEOI}
            className="flex-row-reverse custom-transfer"
            onChange={onChange}
            loading={fetching}
            rowKey={(record) => record.id}
            dataSource={filteredData}
            showSearch
            showSelectAll={false}
            filterOption={(inputValue, item) =>
              item.name?.includes(inputValue) || item.vendorNumber?.includes(inputValue)
            }
            selectAllLabels={[
              ({ selectedCount, totalCount }) => (
                <span>
                  {selectedCount ? `${selectedCount}/${totalCount} uninvited` : `${totalCount} uninvited`}
                </span>
              ), ({ selectedCount, totalCount }) => (
                <span>
                  {selectedCount ? `${selectedCount}/${totalCount} invited` : `${totalCount} invited`}
                </span>
              )
            ]}
            leftColumns={columns}
            rightColumns={columns}
          />
        </Form.Item>
        {
          type != 4 &&
          <Form.Item name="emails" label="Add non registered suppliers">
            <Select mode="tags" maxTagCount="responsive" placeholder="email@gmail.com">

            </Select>
          </Form.Item>
        }
        <div className="flex justify-end gap-3 mt-3">
          <Button onClick={() => setActiveStep(0)}>
            Previous
          </Button>
          <Button type="primary" onClick={() => form.submit()}>
            Next
          </Button>
        </div>
      </Form>        
      </>
    }
    </>
  )
}

const Eoifilter = ({setSuppliers}) => {
  const [ supplierTier, setSupplierTier ] = useState([])

  useEffect(() => {
    instance({
      method: 'get',
      url: `/SupplierTier?wcode=tender`
    }).then(res => {
      setSupplierTier(res.data.responseData)
    })
  }, [])
  
  const handleSubmit = (values) => {
    console.log(values)
  }
  
  return (
    <div className="flex gap-3">
      <Form
        className="grow"
        layout='vertical'
        initialValues={{
          supplierTiers: -1
        }}
        onFinish={handleSubmit}>
        <div className="flex gap-3">
          <Form.Item name="supplierTiers" label="Supplier tier" className="grow">
            <Select
              options={[{value: -1, label: 'All'}, ...supplierTier.map(foo => ({value: foo.id, label: foo.name}))]}>

            </Select>
          </Form.Item>
          <Form.Item className="self-end" label={null}>
            <Button type='primary' htmlType='submit'>
              Filter
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

const AddExistingSupplier = ({type, handleAddSupplier, handleFilter, tenderData}) => {
  const [ data, setData ] = useState([])
  const [ selectedRowKeys, setSelected ] = useState([])
  const [ open, setOpen ] = useState(false)
  const [ filterModalOpen, setFilterModalOpen ] = useState(false)
  const [ productTypes, setProductTypes ] = useState([])
  const [ productThirdTypes, setProductThirdTypes ] = useState([])
  const [ supplierTier, setSupplierTier ] = useState([])
  const [ fetching, setFetching ] = useState(false)
  const [ findForm ] = Form.useForm()
  const [ filterForm ] = Form.useForm()
  const [ activeFilter, setActiveFilter ] = useState({
    tier: [],
    qualificationStatus: null,
    productIds: []
  })
  

  useEffect(() => {
    fetchFilterDatas()
  }, [])

  const fetchFilterDatas = () => {
    Promise.all([
      instance({
        method: 'get',
        url: `/Master/producttype/3?wcode=tender`
      }),
      instance({
        method: 'get',
        url: `/SupplierTier?wcode=tender`
      }),
      instance({
        method: 'get',
        url: `/Master/producttype?wcode=tender`
      }),
    ]).then(res => {
      setProductTypes(res[2].data.responseData)
      setSupplierTier(res[1].data.responseData)
      setProductThirdTypes(res[0].data.responseData)
    })
  }

  const fixTreeData = (_data) => {
    let tmp = []

    _data.map((foo) => {
      tmp.push({value: foo.item.id, title: `${foo.item.name} /${foo.item.code}/`, children: fixTreeData(foo.children)})
    })

    return tmp
  }
  
  const handleFind = (values) => {
    setFetching(true)
    setData([])
    setSelected([])
    instance({
      method: 'get',
      url: `/Supplier/find?wcode=tender`,
      params: values,
      paramsSerializer: {
        indexes: null
      }
    }).then(res => {
      setData(res.data.responseData)
    }).catch(err => {
      
    }).then(() => {
      setFetching(false)
    })
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelected(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  
  const handleOk = () => {
    handleAddSupplier(selectedRowKeys, data.filter(foo => selectedRowKeys.includes(foo.id)))
    setOpen(false)
    setSelected([])
  }

  const handleFilterFormSubmit = (values) => {
    handleFilter(values)
    setFilterModalOpen(false)
    setActiveFilter(values)
  }
  
  return (
    <div>
      <div className='flex justify-between gap-3 mb-3'>
        <div className="grow items-center grid grid-cols-2 gap-3">
          {
            type == 2 &&
            <div>
              Scope SECT code: {
                tenderData.productTypeIds &&
                tenderData.productTypeIds.map((foo, fooIndex) => {
                  return (
                    <Tag>{productThirdTypes.find(_foo => _foo.id == (foo.id ? foo.id : foo))?.name}</Tag>
                  )
                })
              }
            </div>
          }
          <div>
            <span className="mr-3">Active filters: </span>
            {
              activeFilter.tier?.map((foo, fooIndex) => {
                return (
                  <Tag key={`active-tier-filter-${fooIndex}`}>{supplierTier.find(_foo => _foo.id == foo)?.name}</Tag>
                )
              })
            }
            {
              activeFilter.productIds?.map((foo, fooIndex) => {
                return (
                  <Tag key={`active-tier-filter-${fooIndex}`}>{productTypes.find(_foo => _foo.id == foo)?.name}</Tag>
                )
              })
            }
          </div>
        </div>
        <div className="flex gap-3">
          <Button type="primary" onClick={() => setFilterModalOpen(true)}>Filter</Button>
          {
            type != 4 &&
            <Button type='primary' onClick={() => setOpen(true)}>Add existing supplier</Button>
          }
        </div>
      </div>
      <Modal 
        width={'80vw'}
        title="Add existing supplier"
        open={open}
        onCancel={() => setOpen(false)}
        onOk={handleOk}>
        <Form
          form={findForm}
          onFinish={handleFind}
          layout='vertical'
          initialValues={{
            qualificationStatus: 0
          }}
          className="grid grid-cols-12 gap-x-3">
          <Form.Item className="col-span-2 mb-0" name="name" label="Name">
            <Input placeholder='Enter name'></Input>
          </Form.Item>
          <Form.Item className="col-span-1 mb-0" name="vendorNumber" label="Vendor #">
            <Input placeholder='Enter vendor #'></Input>
          </Form.Item>
          <Form.Item className="col-span-2 flex flex-col mb-0" label="Supplier tiers" name="supplierTierIds">
            <Select showSearch allowClear options={supplierTier.map((foo) => ({value: foo.id, label: foo.name}))} mode="multiple" placeholder="All"></Select>
          </Form.Item>
          {
            type == 2 ?
            <Form.Item className="col-span-4 flex flex-col mb-0" label="Product SECT code" name="productIds">
              <TreeSelect showSearch allowClear treeData={fixTreeData(productTypes)} multiple placeholder="All"></TreeSelect>
            </Form.Item>
            :
            <></>
          }
          {/* {
            type == 2 ?
            <Form.Item className="col-span-1 flex flex-col mb-0" label="Difot score" name="difotScore">
              <InputNumber className="w-full"></InputNumber>
            </Form.Item>
            :
            <></>
          } */}
          {
            type == 2 ? 
            <Form.Item className="col-span-2 flex flex-col mb-0" label="Pre-Qualification status" name="qualificationStatus">
              <Select showSearch allowClear options={[
                {
                  value: 0,
                  label: 'All'
                },
                {
                  value: 1,
                  label: 'Prequalified'
                },
              ]} placeholder="All"></Select>
            </Form.Item>
            :
            <></>
          }
          <div className="col-span-1 flex justify-end items-end">
            <Button type="primary" onClick={findForm.submit}>Search</Button>
          </div>
        </Form>
        <Table
          rowSelection={rowSelection}
          size='small'
          className="mt-10"
          rowKey={(record) => record.id}
          dataSource={data}
          columns={columns}
          loading={fetching}
        />
      </Modal>
      <Modal
        title="Filter modal"
        open={filterModalOpen}
        onCancel={() => setFilterModalOpen(false)}
        onOk={() => {
          filterForm.submit()
        }}>
        <Form
          form={filterForm}
          layout='vertical'
          onFinish={handleFilterFormSubmit}>
          <Form.Item name="tier" label="Supplier Tiers">
            <Select showSearch allowClear options={supplierTier.map((foo) => ({value: foo.id, label: foo.name}))} mode="multiple" placeholder="All"></Select>
          </Form.Item>
          <Form.Item name="preQualificationStatus" label="Prequalification">
            <Select showSearch allowClear options={[{label: 'All', value: null}, {label: 'Pre-qualified', value: 1}, {label: 'Exclude not qualified', value: 2}]} placeholder="All"></Select>
          </Form.Item>
          {/* {
            type == 2 &&
            <Form.Item className="col-span-4 flex flex-col mb-0" label="Product SECT code" name="productIds">
              <TreeSelect showSearch allowClear treeData={fixTreeData(productTypes)} multiple placeholder="All"></TreeSelect>
            </Form.Item>
          } */}
        </Form>
      </Modal>
    </div>
  )
}

export default SuppliersInformation