import { Table } from "antd"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { instance } from "utils/axios"

const AwardModalContent = () => {
  const [ data, setData ] = useState({
    awardEmail: {
      email: '',
      subject: '',
      content: ''
    },
    suppliers: []
  })
  const { id } = useParams()

  useEffect(() => {
    instance({
      method: 'get',
      url: `/Tender/letter/sendaward/preview/${id}?wcode=tender`
    }).then(res => {
      setData(res.data.responseData)
    })
  }, [])
  
  return (
    <div>
      <div className="grid grid-cols-12 mb-3 gap-3">
        <div className="col-span-4">
          <label>Email: <span className="font-bold">{data.awardEmail.email}</span></label>
        </div>
        <div className="col-span-4">
          <label>Subject: <span className="font-bold">{data.awardEmail.subject}</span></label>
        </div>
        <div className="col-span-12">
          <label>Content:</label>
          <div className="p-3 border rounded" dangerouslySetInnerHTML={{__html: data.awardEmail.content}}></div>
        </div>
      </div>
      <Table
        size="small"
        pagination={false}
        dataSource={data.suppliers}
        columns={[
          {
            title: '#',
            dataIndex: 'No',
            key: 'No',
            width: 40,
            align: 'center',
            render: (cell, row, index) => index+1
          },
          {
            title: 'Supplier name',
            dataIndex: 'name',
            key: 'name',
            filterType: "search"
          },
          {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            filterType: "search",
          },
          {
            title: 'Vendor Number',
            dataIndex: 'vendorNumber',
            key: 'vendorNumber',
            align: 'center',
            filterType: "search",
            render: (cell) => cell ? cell : ''
          },
        ]}>

      </Table>
    </div>
  )
}

export default AwardModalContent