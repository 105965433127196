export default () => {
  return [
    {
      label: 'Name',
      name: 'name',
      rules: [{required: true, message: 'Name is required!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Description',
      name: 'description',
      type: 'textarea',
      rules: [{required: true, message: 'Description is required!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
        rows: 10
      }
    },
    {
      label: 'Quantity',
      name: 'qty',
      type: 'number',
      rules: [{required: true, message: 'Quantity is required!'}],
      className: 'col-span-4 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
   
    {
      label: 'Status',
      name: 'status',
      type: 'select',
      rules: [{required: true, message: 'Status is required!'}],
      className: 'col-span-4 mb-0',
      inputProps: {
        className: 'w-6',
        options: [
          { label: 'Идэвхтэй', value: 1 }, 
          { label: 'Идэвхгүй', value: 0 },   
      ],
      }
    }, 
    {
      label: 'Image',
      name: 'image',
      type: 'file',
      className: 'col-span-4 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
  ]
}
