import { useState, useEffect } from 'react'
import { Form } from 'components'
import { Button, Form as AntForm, Card, Input, Select } from 'antd'
import { instance } from 'utils/axios'

const ManagementTeam = ({state, data}) => {
  const [ loading, setLoading ] = useState(false)
  const [ form ] = AntForm.useForm();

  useEffect(() => {
    form.setFieldsValue({managementTeam: data})
    console.log('dasdas', data);
  }, [data])
  
  return (
    <div className='relative'>
      <Form
        disabled={true}
        form={form}
        fields={[]}
        layout="vertical"
        onFinish={() => {}}
        className='gap-4'>  
        <AntForm.List name="managementTeam">  
          {(fields, {add, remove}) => (
            <>
              {
                fields.map(({name, key, ...restFields}) => (
                  <Card title={`${key+1}. Team`} className="col-span-12 rounded-none" size="small" key={key} {...restFields}>
                    <AntForm.Item label="Firstname" name={[name, "firstName"]} className='col-span-12'>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="Lastname" name={[name, "lastName"]}>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="Job Title" name={[name, "address2"]}>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="Phone" name={[name, "phone"]}>
                      <Input></Input>
                    </AntForm.Item>
                    <AntForm.Item label="Email" name={[name, "email"]}>
                      <Input></Input>
                    </AntForm.Item>
                  </Card>
                ))
              }
            </>
          )}
        </AntForm.List>
      </Form>
      
    </div>
  )
}

export default ManagementTeam