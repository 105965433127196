import React, { useEffect, useState } from 'react'
import { useOutletContext, useParams } from "react-router-dom";
import { Skeleton, Card, Steps, Popover, Select } from 'antd'
import moment from 'moment';

var special = ['zeroth','First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh', 'Eighth', 'Ninth', 'Tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];
var deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

function stringifyNumber(n) {
  if (n < 20) return special[n];
  if (n%10 === 0) return deca[Math.floor(n/10)-2] + 'ieth';
  return deca[Math.floor(n/10)-2] + 'y-' + special[n%10];
}

const TenderOverview = () => {
  const [ data ] = useOutletContext()
  const { id } = useParams()
  
  if(data.loading) {
    return (
      <Skeleton active/>
    )
  } 
  
  return (
    <div className='flex flex-col gap-3 col-span-12'>
      <Card title="Info" size="small">
        <div className="grid grid-cols-12 gap-2">
          <div className="col-span-2">
            <label className="font-semibold">Start date</label>
            <div className="border py-1 px-3 rounded text-sm font-medium">{moment(data.startDate).format("YYYY/MM/DD HH:mm")}</div>
          </div>
          <div className="col-span-2">
            <label className="font-semibold">End date</label>
            <div className="border py-1 px-3 rounded text-sm font-medium">{moment(data.endDate).format("YYYY/MM/DD HH:mm")}</div>
          </div>
          <div className="col-span-2">
            <label className="font-semibold">Reminder date</label>
            <div className="border py-1 px-3 rounded text-sm font-medium">{data.reminderDate ? 'Yes' : 'No'}</div>
          </div>
          {
            data.endUsers &&  data.endUsers?.length > 0 &&
            <div className="col-span-6">
              <label className="font-semibold">End users</label>
                <div className="flex gap-2 flex-wrap border py-1 px-3 rounded text-sm font-medium">
                  {data.endUsers?.map((foo, fooindex) => (
                    <span key={`end-user-${fooindex}`} className="bg-gray-200 px-3 rounded">{foo.userInfo.firstName} {foo.userInfo.lastName} </span>
                  ))}
                </div>
            </div>
          }
          {
            data.attachments?.length > 0 &&
            <div className='col-span-12'>
              <label className="font-semibold">Attachments</label>
                <div className="flex gap-2 flex-wrap border py-1 px-3 rounded text-sm font-medium">
                  {data.attachments.map((foo, fooindex) => (
                    <a href={process.env.REACT_APP_CDN_URL+foo.fileName} target='_blank' key={`end-user-${fooindex}`} className="bg-gray-200 px-3 rounded">{foo.fileName.split(`\\`).at(-1)}</a>
                  ))}
                </div>
            </div>
          }
          {
            data.services?.length > 0 &&
            <div className="col-span-12">
              <label className="font-semibold">Products & services</label>
                <div className="flex gap-2 flex-wrap border py-1 px-3 rounded text-sm font-medium">
                  {data.services.map((foo, fooindex) => (
                    <span key={`service-${fooindex}`} className="bg-gray-200 px-3 rounded">{foo.name}</span>
                  ))}
                </div>
            </div>
          }
          {
            data.description &&
            <div className="col-span-12">
              <label className="font-semibold">Description</label>
              <div className="border py-1 px-3 rounded text-sm font-medium">
                {data.description}
              </div>
            </div>
          }
          <div className="col-span-12">
            <label className="font-semibold">Content</label>
            <div dangerouslySetInnerHTML={{__html: data.content}} className="h-[400px] overflow-auto shadow-inner p-2">
            </div>
          </div>
        </div>
      </Card>
      {/* {
        location.state.type == 1 ?
        <EOIDetail tenderData={data}></EOIDetail>
        :
        location.state.type == 2?
        <RFQDetail tenderData={data}></RFQDetail>
        :
        <></>
      } */}
    </div>
  )
}

export default TenderOverview