import React, { useState, useEffect } from 'react';
import { Input, Form, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from 'contexts';
import { instance } from 'utils/axios';
import bgImage from 'assets/images/bg.jpg';
import logo from 'assets/images/Logo.svg';
import moment from 'moment';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const Login = () => {
  const [ loading, setLoading ] = useState(false)
  const [ isReset, setIsReset ] = useState(false)
  const authContext = React.useContext(AuthContext)
  const navigate = useNavigate()
  const [ form ] = Form.useForm()

  useEffect(() => {
    if(authContext.state.token) {
      navigate("/", {replace: true});
    }
  }, [authContext.state])

  const handleSubmit = (values) => {
    setLoading(true)
    instance({
      method: 'post',
      url: `/Auth/login`,
      data: values
    }).then(res => {
      console.log(res.data);
      authContext.action.login(res.data.jwtToken, res.data)
    }).catch(err => {
      form.setFields([
        {
          name: 'email',
          errors: ['']
        },
        {
          name: 'password',
          errors: [err.response.data.value]
        }
      ])
    }).then(() => {
      setLoading(false)
    })
  }

  const handleSubmitReset = (values) => {
    setLoading(true)
    instance({
      method: 'post',
      url: 'auth/resetpassword',
      data: {
        ...values
      }
    }).then((res) => {
      setIsReset(!isReset)
    }).catch((err) => {

    }).then(() => setLoading(false))
  }

  const handleFormChange = () => {
    form.setFields([
      {
        name: 'email',
        errors: []
      },
      {
        name: 'password',
        errors: []
      }
    ])
  }

  return (
    <div className='flex h-screen'>
      <div className='hidden md:block flex-1 relative'>
        <div className='background-tint absolute inset-0 bg-cover bg-no-repeat flex justify-center items-end pb-4' style={{backgroundImage: `url(${bgImage})`}}>
        <div className='text-2xl text-white font-bold '>{moment().format('YYYY')}</div>
        </div>
      </div>
      <div className="flex-1 flex flex-col p-4 md:p-0 bg-white">
        <div className='flex-1 flex justify-center flex-col'>
          <div className="py-10 grid grid-cols-12">
            <div className="col-span-12 xl:col-start-4 xl:col-span-6 sm:col-start-3 sm:col-span-8 md:col-start-5 md:col-span-4">
              <div className='flex flex-col items-center'>
                <img src={logo} alt="logo" />
              </div>
              {
                !isReset ?
                <Form
                  form={form}
                  className="md:mt-10"
                  onFinish={handleSubmit}
                  onValuesChange={handleFormChange}
                  layout='vertical'
                >
                  <Form.Item
                    name="email"
                    rules={[{required: true, message: 'Please enter e-mail!'}]}
                  >
                    <Input
                      size="middle"
                      prefix={<UserOutlined />}
                      placeholder='E-mail'
                      className="py-3 rounded-lg"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[{required: true, message: 'Please enter password!'}]}
                  >
                    <Input.Password
                      size="middle"
                      prefix={<LockOutlined />}
                      type="password"
                      placeholder='Password'
                      className="py-3 rounded-lg"
                    />
                  </Form.Item>
                  <Form.Item>
                    <div className="flex justify-end">
                      <Link className="underline" onClick={() => setIsReset(!isReset)}>
                        Forgot password?
                      </Link>
                    </div>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      loading={loading}
                      disabled={loading}
                      block
                      type="primary"
                      size="middle"
                      htmlType="submit"
                      className="bg-primary border-none hover:bg-orange-600 h-11 rounded-lg"
                    >
                      Login
                    </Button>
                  </Form.Item>
                </Form>
                :
                <>
                  <div className='text-2xl text-center text-primary mt-5 font-bold'>Reset password</div>
                  <Form
                    form={form}
                    className="md:mt-8"
                    onFinish={handleSubmitReset}
                    layout='vertical'
                  >
                    <Form.Item
                      label='E-mail'
                      name="email"
                      className='mb-3'
                      rules={[{required: true, message: 'Please enter e-mail!'}]}
                    >
                      <Input
                        size="middle"
                        placeholder='И-Мэйл хаяг'
                        className="py-3 rounded-lg"
                      />
                    </Form.Item>
                    <div className='mb-4 text-xs text-gray-500 p-3 border border-orange-200 rounded-lg bg-orange-100'>Та зөвхөн өөрийн бүртгэлтэй и-мэйл хаягийг оруулснаар тухайн и-мэйл хаягт шинэ нууц үг илгээх болно</div>
                    <Form.Item>
                      <div className="flex justify-end">
                        <Link className="underline" onClick={() => setIsReset(!isReset)}>
                          Go back to login
                        </Link>
                      </div>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        loading={loading}
                        disabled={loading}
                        block
                        type="primary"
                        size="middle"
                        htmlType="submit"
                        className="bg-primary border-none hover:bg-blue-600 h-11 rounded-lg"
                      >
                        Send
                      </Button>
                    </Form.Item>
                  </Form>
                </>
              }
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Login;
