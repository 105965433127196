import { Form as AntForm, Space, Input, Button } from 'antd'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'

const typeEnum = {
  1: 'Number',
  2: 'Text',
  3: 'Dropdown',
  4: 'Radio button',
  5: 'Calendar',
  6: 'Rich text',
  7: 'Attachment',
  8: 'Checkbox',
  9: 'Table'
}

export default (editorTypeData) => {
  return [
    {
      label: 'Question',
      name: 'title',
      rules: [{required: true, message: 'Question is required!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Description',
      name: 'description',
      type: 'textarea',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Question type',
      name: 'answerTypeId',
      className: 'col-span-12 mb-0',
      type: 'select',
      inputProps: {
        className: 'w-full',
        options: editorTypeData.map(foo => ({label: typeEnum[foo.value], value: foo.value}))
      }
    },    
    {
      type: 'dependent',
      className: "col-span-12 mb-0",
      dependentName: 'answerTypeId',
      dependentValue: 9,
      children: [
        {
          label: 'Row header',
          name: 'table-row',
          type: 'select',
          className: "col-span-12 mb-0",
          inputProps: {
            className: 'w-full',
            mode: 'tags'
          }
        },
        {
          label: 'Column name',
          name: 'table-col',
          type: 'select',
          className: "col-span-12 mb-0",
          inputProps: {
            className: 'w-full',
            mode: 'tags'
          }
        }
      ]      
    },
    {
      label: '',
      type: 'component',
      component: (
        <AntForm.Item noStyle shouldUpdate={(prev, next) => prev.answerTypeId != next.answerTypeId } className="col-span-12">
          {({getFieldValue}) => {
            if(getFieldValue("answerTypeId") == 3 || getFieldValue("answerTypeId") == 4) {
              return ( 
                <AntForm.List name="options" className='col-span-12'>
                  {(fields, {add ,remove}) => {
                    return (
                      <>
                        {fields.map(({key, name, ...restFields}) => (
                          <Space className='col-span-12 flex'>
                            <AntForm.Item {...restFields} name={[name, 'name']} className='flex-1 mb-0'>
                              <Input className='w-full'></Input>
                            </AntForm.Item>
                            <MinusCircleOutlined onClick={() => {remove(name)}} />
                          </Space>
                        ))}
                        <Button className='col-span-12' type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                          Add answer
                        </Button>
                      </>
                    )
                  }}
                </AntForm.List>
              )
            }
          }}
        </AntForm.Item>
      )
    },
    // {
    //   label: 'Use this question for evaluation',
    //   name: 'isUseCalculation',
    //   rules: [{required: true, message: 'Description is required!'}],
    //   type: 'checkbox',
    //   className: 'col-span-12 mb-0',
    //   inputProps: {
    //     className: 'w-full',
    //   }
    // },
    // {
    //   type: 'divider'
    // },
    // {
    //   label: 'Parent question',
    //   name: 'parentId',
    //   type: 'select',
    //   className: 'col-span-12 mb-0',
    //   inputProps: {
    //     className: 'w-full',
    //     options: data.map((foo, fooIndex) => ({label: foo.title, value: foo.id}))
    //   }
    // },
    // {
    //   label: 'Option',
    //   name: 'optionId',
    //   type: 'select',
    //   dependentIndex: 'parentId',
    //   className: 'col-span-12 mb-0',
    //   inputProps: {
    //     className: 'w-full',
    //     optionsUrl: '/PreQualificationQuestionOption',
    //     wcode: `prequalification-group-config-detail`
    //   }
    // },
  ]
}
