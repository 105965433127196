import React, { useState, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { EditOutlined, PlusCircleOutlined, RightOutlined } from '@ant-design/icons'
import { Button, Form as AntForm, Modal, Drawer, Tag, Tooltip, Input, Card  } from 'antd';
import { MainTable, Form, Filters } from 'components';
import { instance } from 'utils/axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';

const { Search } = Input

const fields = () => {
  return [
    {
      label: 'Name',
      name: 'name',
      rules: [{required: true, message: 'Name is required!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    }
  ]
}

const PrequalificationList = ({}) => {
  const [ data, setData ] = useState({
    total: 0,
    data: []
  })
  const [ filter, setFilter ] = useState({})
  const [ loading, setLoading ] = useState(true)
  const [ open, setOpen ] = useState(false)
  const [ editData, setEditData ] = useState(null)
  const [ isEdit, setIsEdit ] = useState(false)
  const [ modal, contextHolder ] = Modal.useModal();
  const [ form ] = AntForm.useForm();  
  const [ searchValue, setSearchValue ] = useState("") 
  const [ selectedRowKeys, setSelectedRowKeys ] = useState([])
  const [ hasMore, setHasMore ] = useState(true)
  const [ page, setPage ] = useState(1)
  const [ pageSize, setPageSize ] = useState(20)
  const location = useLocation()

  useEffect(() => {
    fetchData()
    setPage(1)
    setHasMore(true)
  }, [location, filter])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `SupplierPreQualification?wcode=prequalification-group-list`,
      params: filter,
      paramsSerializer: {
        indexes: null,
        page: page,
        pageSize: 20
      }
    }).then(res => {
      if(res.data.responseData.data.length < 20) {
        setHasMore(false)
      }
      setData(res.data.responseData)
      setPage(page+1)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const fetchNext = () => {
    instance({
      method: 'get',
      url: '/SupplierPreQualification?wcode=prequalification-group-list',
      params: {
        ...filter,
        page: page+1,
        pageSize: 20
      },
      paramsSerializer: {
        indexes: null
      }
    }).then((res) => {
      if(res.data.responseData.data.length < 20) {
        setHasMore(false)
      }
      setData(prev => ({total: res.data.responseData.total, data: [...prev.data, ...res.data.responseData.data]}))
      setPage(page+1)
    }).catch((err) => {
    })
  }

  const handleAdd = () => {
    setEditData(null)
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
    form.resetFields()
  }

  const handleSubmit = (values) => {
    setLoading(true)
    if(editData){
      instance({
        method: 'put',
        url: 'SupplierPreQualification?wcode=prequalification-group-list',
        data: {
          ...values,
          id: editData.id
        }
      }).then((res) => {
        handleCloseModal()
        fetchData()
      }).catch((err) => {

      }).then(() => setLoading(false))
    }
    else{
      instance({
        method: 'post',
        url: 'SupplierPreQualification?wcode=prequalification-group-list',
        data: {
          ...values
        }
      }).then((res) => {
        handleCloseModal()
        fetchData()
      }).catch((err) => {

      }).then(() => setLoading(false))
    }
  }

  const handleEdit = (_row) => {
    setEditData(_row)
    setOpen(true)
  }

  const onSearch = (values) => {
    setFilter(values)
  }

  const filteredData = useMemo(() => {
    return data.data.filter(foo => foo.supplier.name.toLowerCase().includes(searchValue.toLowerCase()))
  }, [data, searchValue])

  return (
    <div className="p-4">
      <div className="grid grid-cols-12 gap-3">
        <div className="col-span-12">
          <Filters wcode="prequalification-group-list" onSearch={onSearch}/>
        </div>
        <Card className='col-span-12' size='small'>
          <div className="flex flex-col">
            <div className="mb-3">
              <Search placeholder="Search" className='border-none' onSearch={onSearch} enterButton />
            </div>
            <div className="bg-white mb-3 font-semibold flex justify-between items-center">
              {filteredData.length} of {data.total} Results
              <div className='flex gap-2'>
                <Tooltip title={selectedRowKeys.length == 0 ? "Select suppliers to send email" : "Send email to selected suppliers"}>
                  <Button type="primary" disabled={selectedRowKeys.length == 0}>
                    Send email
                  </Button>
                </Tooltip>
              </div>
            </div>
            <div className="flex flex-col gap-1">
                <InfiniteScroll
                  dataLength={data.data.length}
                  next={fetchNext}
                  loader={<div className='text-center font-bold'>Fetching...</div>}
                  className="flex flex-col gap-1"
                  hasMore={hasMore}
                  endMessage={
                    <p className="text-center font-bold">
                      No more data
                    </p>
                  }>
                  <table className="oyu-table">
                    <colgroup>
                      <col style={{width: '50px'}}></col>
                      <col style={{width: '50px'}}></col>
                      <col></col>
                      <col style={{width: '100px'}}></col>
                      <col style={{width: '200px'}}></col>
                      <col style={{width: '50px'}}></col>
                    </colgroup>
                    <thead>
                      <tr>
                        <th style={{textAlign: 'left'}}>#</th>
                        <th>ID</th> 
                        <th style={{textAlign: 'left'}}>Supplier name</th>
                        <th>Vendor #</th>
                        <th>Pre-qualification status</th>
                        <th>Tier type</th>
                        <th>Submission date</th>
                        <th># of submission</th>
                        <th>Evaluation date</th>
                        <th>Expiration date</th>
                        <th>Contact person</th>
                        <th>Email address</th>
                        <th>Phone number</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        filteredData.map((foo, fooIndex) => {
                          return (
                            <tr key={`tender-${fooIndex}`} className="hover:bg-gray-100 transition-all">
                              <td>
                                {fooIndex+1}
                              </td>
                              <td style={{textAlign: 'center'}}>{foo.supplier.id}</td>
                              <td>
                                <Link 
                                  className="text-primary cursor-pointer hover:underline" 
                                  to={`${foo.supplier.id}`} 
                                  state={foo}>
                                  <div className="truncate">
                                    <Tooltip title={foo.supplier.name}>
                                      <span className="truncate">
                                        {foo.supplier.name}
                                      </span>
                                    </Tooltip>
                                  </div>
                                </Link>
                              </td>
                              <td style={{textAlign: 'center'}}>{foo.supplier.vendorNumber}</td>
                              <td className="text-center">
                                <Tag color={foo.preQual.status == 1 ? "yellow" :  foo.preQual.status == 2 ? "green" : foo.preQual.status == 3 ? "red" : "yellow"}>
                                  {foo.preQual.status == 1 ? "New assessment" : foo.preQual.status == 2 ? 'Pre-qualified' : foo.preQual.status == 3 ? "Not qualified" : foo.preQual.status == 4 ? 'Need Improvement ' : 'Re-Assessment'}
                                </Tag>
                              </td>
                              <td>
                                {foo.supplier.supplierTierName}
                              </td>
                              <td className="text-center">
                                {moment(foo.preQual.createdAt).format("YYYY/MM/DD")}
                              </td>
                              <td className='text-center'>
                                {foo.preQual.submissionCount}
                              </td>
                              <td className="text-center">
                                {foo.preQual.updatedAt && moment(foo.preQual.updatedAt).format("YYYY/MM/DD")}
                              </td>
                              <td className="text-center">
                                {foo.preQual.expireDate && moment(foo.preQual.expireDate).format("YYYY/MM/DD")}
                              </td>
                              <td className='text-center'>
                                {foo.supplier.firstName} {foo.supplier.lastName}
                              </td>
                              <td className='text-center'>
                                {foo.supplier.companyEmail}
                              </td>
                              <td className='text-center'>
                                {foo.supplier.phone}
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </InfiniteScroll>
              </div>
            {/* {
              filteredData.map((_supplier, _supplierIndex) => {
                return (
                  <Card 
                    type="inner" 
                    key={`supplier-${_supplierIndex}`} 
                    className={selectedRowKeys.findIndex(foo => foo == _supplier.supplier.id) == -1 ? "mb-1 p-0 rounded-none transition-all": "mb-1 p-0 rounded-none transition-all border-primary"}
                          bodyStyle={{padding: 0}}>
                    <div className="grid grid-cols-12 gap-x-2">
                      <div className='col-span-7 flex gap-4 p-4'>
                        <div className="w-12 h-12 rounded-full flex justify-center items-center text-white bg-primary text-3xl">
                          {_supplier.supplier.name.slice(0,1)}
                        </div>
                        <div className="flex-1 overflow-hidden">
                          <Tooltip title={_supplier.supplier.name}>
                            <Link to={`${_supplier.supplier.id}`} state={_supplier} className="text-lg text-primary overflow-hidden text-ellipsis whitespace-nowrap hover:underline">{_supplier.supplier.name}</Link>
                          </Tooltip>
                          <div className='text-mute'>ID: {_supplier.supplier.id}</div>
                        </div>
                      </div>
                      <div className='col-span-5 flex justify-between'>
                        <div className="flex p-4 gap-4">
                          <div className="flex flex-col">
                            <label className="text-mute">Vendor ID</label>
                            <div className="">{_supplier.supplier.vendorNumber}</div>
                          </div>
                          <div className="flex flex-col items-center">
                            <label className="text-mute">Score</label>
                            <div >
                              {_supplier.preQual.totalPoint}
                            </div>
                          </div>
                          <div className="flex flex-col items-center">
                            <label className="text-mute">Status</label>
                            <div className="font-semibold">
                              <Tag color={_supplier.supplier.validationStatus == 1 ? "yellow" :  _supplier.supplier.validationStatus == 2 ? "red" : _supplier.supplier.validationStatus == 3 ? "green" : "green"}>
                                {_supplier.preQual.status == 1 ? "Pre-qualified": "Not qualified"}
                              </Tag>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                )
              })
            } */}
          </div>
        </Card>
      </div>
      {/* <MainTable
        title="Qualification"
        pagination={true}
        dataTable={data}
        columns={columns({handleEdit})}
        loading={loading}>

      </MainTable> */}
      <Drawer
        title={editData ? <div className='flex items-center justify-start gap-2'>Edit</div> : <div className='flex items-center justify-start gap-2'>Add</div>}
        open={open}
        destroyOnClose
        maskClosable={false}
        okText='Save'
        onOk={form.submit}
        onClose={handleCloseModal}
        width={"40%"}
        footer={
          <div>

          </div>
        }
      >
        <Form
          form={form}
          fields={fields()}
          length={data.length}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          isEdit={isEdit}
          className='gap-4'
        />
      </Drawer>
      {contextHolder}
    </div>
  );
}

export default PrequalificationList;
