import { useState, useEffect } from 'react'
import { Form } from 'components'
import { Button, Form as AntForm, Card, Input, Select, Divider } from 'antd'
import { instance } from 'utils/axios'

const CompanyInformation = ({data}) => {  
  const [ loading, setLoading ] = useState(false)
  const [ form ] = AntForm.useForm();

  useEffect(() => {
    form.setFieldsValue(data)
  }, [data])
  
  const fields = () => {
    return [
      {
        label: 'Description',
        name: 'description',
        type: 'textarea',
        rules: [{required: true, message: 'Description is required!'}],
        className: 'col-span-12 mb-0',
        inputProps: {
          className: 'w-full',
          rows: 4
        }
      },
      {
        label: 'File',
        name: 'file',
        type: 'file',
        rules: [{required: true, message: 'File is required!'}],
        className: 'col-span-12 mb-0',
        inputProps: {
          className: 'w-full',
        }
      },
    ]
  }
  
  return (
    <div className='relative'>
      <Form
        disabled={true}
        form={form}
        fields={[]}
        layout="vertical"
        onFinish={() => {}}
        className='gap-4'>  
        <Card className="col-span-12" size="small">
          <div className="font-bold text-lg">
            1. Company details
          </div>
          <Divider className="my-3"></Divider>
          <AntForm.Item label="Are you existing supplier?" name="isExistingSupplier">
            <Select options={[{label: 'Yes', value: 1}, {label: 'No', value: 2}]}></Select>
          </AntForm.Item>
          <AntForm.Item label="Business type" name="businessTypeName">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="Company name (in English alphabet only)" name="name">
            <Input></Input>
          </AntForm.Item>
          <div className="font-bold text-lg">
            2. Address
          </div>
          <Divider className="my-3"></Divider>
          <AntForm.Item label="Address line" name="address1">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="Address line 2 / Soum" name="address2">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="Address line 3">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="Postcode or zipcode" name="zipcode">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="Town/City/Aimag" name="town">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="State/Province" name="province">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="Country" name="registeredCountryName">
            <Input></Input>
          </AntForm.Item>
          <div className="font-bold text-lg">
            3. More information
          </div>
          <Divider className="my-3"></Divider>
          <AntForm.Item label="3. Country you are registered in" name="registeredCountryName">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="Aimag you are registered in" name="registeredAimagName">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="6. Company registration number" name="regNo">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="7. Certificate of registration">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="8. Company website" name="website">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="9. Company e-mail" name="companyEmail">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="10. Please indicate what percentage of company is owned by foreign entity" name="foreignOwnPercentId">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="11. Total number of employees" name="totalEmployee">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="12. Total number of Mongolian employees" name="totalMongolianEmployee">
            <Input></Input>
          </AntForm.Item>
          <AntForm.Item label="13. Total number of Umnugovi employees" name="totalUmnugoviEmployee">
            <Input></Input>
          </AntForm.Item>
        </Card>
      </Form>
      
    </div>
  )
}

export default CompanyInformation