import { AuthContext } from "contexts";
import { useContext, useEffect } from "react";
import { createSignalRContext } from "react-signalr/signalr";

const SignalRContext = createSignalRContext();


const SocketProvider = ({children}) => {
  const authContext = useContext(AuthContext)

  SignalRContext.useSignalREffect(
    "connection", // Your Event Key
    (message) => {
      console.log(message)
    },
  );
  
  return (
    <SignalRContext.Provider
      connectEnabled={!!authContext.state.token}
      accessTokenFactory={() => authContext.state.token}
      dependencies={[authContext.state.token]} //remove previous connection and create a new connection if changed
      url={`${process.env.REACT_APP_UPLOAD_URL}_socket?token=${authContext.state.token}`}
    >
      {children}
    </SignalRContext.Provider>
  );
};

export { SocketProvider, SignalRContext}