import { AutoComplete, Button, Form, Input, InputNumber, Table } from "antd"
import { TinyEditor } from "components"
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { expandedDetailColumns, expandedServiceColumns, expandedSuppliersColumns, totalColumns } from "./rfqFormColumns";
import { losersColumns, suppliersColumns } from './serviceFormColumns'
import { instance } from "utils/axios";
import { UNSAFE_DataStaticRouterContext, useParams } from "react-router-dom";
import { TbChevronsDownLeft } from "react-icons/tb";

const EditableContext = createContext(null);

const EditableRow = ({ index, ...props }) => {
  return (
    <tr {...props} />
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  type,
  options,
  parent,
  count,
  format,
  ...restProps
}) => {
  const [editing, setEditing] = useState(true);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  
  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={[record.name, dataIndex]}
      > 
        {
          type && type == 'select' ?
          <AutoComplete 
            options={options} 
            ref={inputRef}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }>
            <Input.TextArea placeholder="Comment">

            </Input.TextArea>
          </AutoComplete>
          :
          <Input.TextArea ref={inputRef} placeholder="Comment"/>
        }
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
      >
        {children}
      </div>
    );
  }
  else {
    if(count) {
      childNode = record.name+1
    }
    else if(record) {
      childNode = form.getFieldValue([parent, record.name, dataIndex])
      if(format == 'currency') {
        childNode = childNode?.toLocaleString("en-US")
      }
    }
  }
  return (
    <td {...restProps}>
      {childNode}
    </td>
  )
};

const fixSuppliersData = (_data, selected) => {
  let tmp = []

  _data.map(_supplier => {
    let sum = 0;
    _supplier.details.map((foo, fooIndex) => {
      if(selected.find(ai => ai.id == foo.id)) {
        sum += foo.totalPrice
      }
    })
    _supplier.sum = sum
  })

  return _data.sort((a,b) => b.sum-a.sum)
}

const fixWinnersData = (_data, selected) => {
  let tmp = []

  _data.map(_supplier => {
    _supplier.details.map((foo) => {
      if(selected.find(ai => ai.id == foo.id)) {
        tmp.push({..._supplier, ...foo})
      }
    })
  })
  
  return tmp
}

const fixLosersData = (_data, selected) => {
  let tmp = []

  _data.map(_supplier => {
    let ids = _supplier.details.map(foo => foo.id)
    let found = false
    ids.map(_id => {
      if(selected.findIndex(foo => foo.id == _id) != -1) {
        found = true
      }
    })
    if(!found) {
      tmp.push(_supplier)
    }
    // _supplier.details.map((foo) => {
    //   if(selected.find(ai => ai.id != foo.id)) {
    //     tmp.push({..._supplier, ...foo, supplierId: _supplier.id})
    //   }
    // })
  })
  const ids = tmp.map(foo => foo.supplierId)
  return tmp.filter(({supplierId}, index) => !ids.includes(supplierId, index + 1))
}

const ServiceForm = ({rawData, data, selected, closeModal, handleSubmit}) => {
  const [ suppliersData, setSuppliersData ] = useState(fixSuppliersData(rawData.suppliers, selected))
  const [ winner, setWinner ] = useState(data.serviceDetails ? data.serviceDetails : fixWinnersData(rawData.suppliers, selected))
  const [ losers, setLosers ] = useState(data.details ? data.details : fixLosersData(rawData.suppliers, selected))
  const [ total, setTotal ] = useState([])
  const [ form ] = Form.useForm()
  const { id } = useParams() 
  const findDisqualifiedData = () => {
    let tmp = []
    rawData.suppliers.map((foo) => foo.details.map(_detail => {
      if(selected.findIndex(el => el == _detail.id) != -1) {
        tmp.push(_detail.tenderDetailId)
      }
    }))
    form.setFieldValue("disqualifiedData", rawData.detail.filter(foo => !tmp.some(el => el == foo.id)))
  }

  const findQualifiedData = () => {
    let tmp = []
    rawData.suppliers.map((foo) => foo.details.map(_detail => {
      if(selected.findIndex(el => el == _detail.id) != -1) {
        tmp.push({...rawData.detail.find(foo => foo.id == _detail.tenderDetailId), ..._detail, commentText: _detail.comment, _supplier: foo.name })
      }
    }))
    form.setFieldValue("supplierDetails", tmp)
  }

  // useEffect(() => {
  //   if(data.serviceDetails) {
  //     setWinner(data.serviceDetails)
  //   }
  //   else {
  //     setWinner(fixWinnersData(rawData.suppliers, selected))
  //   }
  //   if(data.details) {
  //     setWinner(data.details)
  //   }
  //   else {
  //     setLosers(fixLosersData(rawData.suppliers, selected))
  //   }
  // }, [rawData, selected])
  
  useEffect(() => {
    if(data.total) {
      form.setFieldsValue(data)
    }
    else {
      form.setFieldValue("serviceDetails", fixWinnersData(rawData.suppliers, selected))
      form.setFieldValue("suppliersData", fixLosersData(rawData.suppliers, selected))
      fetchTotal()
      fetchUSD()
    }
  }, [])

  const fetchUSD = () => {
    instance({
      method: 'get',
      url: `/Utils/usdrate`
    }).then(res => {
      form.setFieldValue("usdRate", res.data.responseData ?? 1)
    })
  }

  const fetchTotal = () => {
    instance({
      method: 'get',
      url: `/Tenderrfq/total/${id}?wcode=tender-detail`
    }).then(res => {
      let tmp = []
      tmp.push({
        title: 'Total Suppliers invited to RFQ',
        data: res.data.responseData.total
      })
      tmp.push({
        title: 'Responded suppliers to RFQ',
        data: res.data.responseData.statSubmitted
      })
      tmp.push({
        title: 'Not participated',
        data: res.data.responseData.statNotResponded
      })
      form.setFieldValue("total", tmp.map(foo => ({...foo, total: foo.data.length})))
    }).catch(err => {

    })
  }
  
  const handleFinish = (values) => {
    handleSubmit({
      ...values,
      // serviceDetails: winner,
      // details: losers,
    })
  }
  
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  
  const handleSuppliersSave = (row) => {
    const newData = [...winner];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setWinner(newData);
  };

  const handleLosersSave = (row) => {
    const newData = [...losers];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setLosers(newData);
  }

  const totalSave = (row) => {
    const newData = [...total];
    const index = newData.findIndex((item) => row.title === item.title);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setTotal(newData);
  }

  const columns = (_columns, parent) => {
    return _columns.map((col) => {
      if (!col.editable) {
        return {
          ...col,
          onCell: (record) => ({
            record,
            dataIndex: col.dataIndex,
            parent: parent
          })
        }
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          type: col.type,
          options: col.options,
          dataIndex: col.dataIndex,
          title: col.title,
        }),
      };
    });
  }
  
  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleFinish}>
      <EditableContext.Provider value={form}>
        <Form.Item name="usdRate" label="Exchange rate:">
          <InputNumber min={1} className="w-full"/>
        </Form.Item>
        <Form.Item name="shortReport" label="Short background:">
          <Input.TextArea placeholder="For example: (explain purpose of purchase, for where, for what work, etc)"/>
        </Form.Item>
        <Form.Item name="summaryReport" label="RFQ summary">
          <TinyEditor form={form} name="summaryReport" placeholder="Summary"/>
        </Form.Item>
        <div className="mb-4">
          <label>Total</label>
          <Form.List name="total">
            {(fields, {}, {errors}) => {
              return (
                <Table
                  size="small"
                  rowClassName={() => 'editable-row'}
                  dataSource={fields}
                  expandable={{
                    expandedRowRender: rec => {
                      return (
                        <div>
                          <Table
                            columns={expandedSuppliersColumns}
                            dataSource={form.getFieldValue(["total", rec.name]).data}
                            pagination={false}>

                          </Table>
                        </div>
                      )
                    }
                  }}
                  rowKey={(row, index) => {
                    return index
                  }}
                  pagination={false}
                  components={components}
                  bordered={true}
                  columns={columns(totalColumns, 'total')}>

                </Table>
              )
            }}
          </Form.List>
        </div>
        <div className="mb-4">
          <label>Suppliers</label>
          <Form.List name="serviceDetails">
            {(fields, {}, {errors}) => {
              return (
                <Table
                  size="small"
                  rowClassName={() => 'editable-row'}
                  dataSource={fields}
                  rowKey={(row, index) => {
                    return row.name
                  }}
                  pagination={false}
                  components={components}
                  bordered={true}
                  columns={columns(suppliersColumns(), 'serviceDetails')}>

                </Table>
              )
            }}
          </Form.List>
          {/* <Table
            size="small"
            rowClassName={() => 'editable-row'}
            dataSource={winner}
            expandable={{
              expandedRowRender: rec => {
                console.log(rec)
                return (
                  <div dangerouslySetInnerHTML={{__html: rec.content}}>

                  </div>
                )
              }
            }}
            rowKey={(row, index) => {
              return row.id
            }}
            pagination={false}
            components={components}
            bordered={true}
            columns={columns(suppliersColumns(), handleSuppliersSave)}>

          </Table> */}
        </div>
        <div className="mb-4">
          <label>Disqualified suppliers</label>
          <Form.List name="suppliersData">
            {(fields, {}, {errors}) => {
              return (
                <Table
                  size="small"
                  rowClassName={() => 'editable-row'}
                  dataSource={fields}
                  rowKey={(row, index) => {
                    return row.name
                  }}
                  pagination={false}
                  components={components}
                  bordered={true}
                  columns={columns(losersColumns(), 'suppliersData')}>

                </Table>
              )
            }}
          </Form.List>
          {/* <Table
            size="small"
            rowClassName={() => 'editable-row'}
            dataSource={losers}
            rowKey={(row, index) => {
              return row.id
            }}
            pagination={false}
            components={components}
            bordered={true}
            columns={columns(losersColumns(), handleLosersSave)}>

          </Table> */}
        </div>
        <div className="flex gap-4 justify-end">
          <Button onClick={() => closeModal()}>Close</Button>
          <Button type="primary" htmlType="submit">Next</Button>
        </div>
      </EditableContext.Provider>
    </Form>
  )
}

export default ServiceForm