import { useState, useEffect } from 'react'
import { Form } from 'components'
import { Button, Form as AntForm, Card, Input, Select } from 'antd'
import { instance } from 'utils/axios'
import { useOutletContext } from 'react-router-dom'

const ProductsAndServices = ({state, products=[], service}) => {
  const [ loading, setLoading ] = useState(false)
  const [ form ] = AntForm.useForm();
  const [ data ] = useOutletContext()
  const { supplierProducts } = data

  useEffect(() => {
    form.setFieldValue('productIds', supplierProducts.map((item) => item?.product.id))
  }, [supplierProducts])
  
  return (
    <div className='relative'>
      <Form
        disabled={true}
        form={form}
        fields={[]}
        layout="vertical"
        onFinish={() => {}}
        className='gap-4'>  
       <Card title="Products & services" className="col-span-12 rounded-none">
          <AntForm.Item label="Product codes" name="productIds">
            <Select mode='multiple' options={supplierProducts.map((item) => ({value: item.product.id, label: `${item.product.code} - ${item.product.name}`}))}></Select>
          </AntForm.Item>
        </Card>
      </Form>
      
    </div>
  )
}

export default ProductsAndServices