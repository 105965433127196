import React, { useEffect } from 'react';
import { AuthContext } from 'contexts';
import { useNavigate, Outlet } from 'react-router-dom';
import Layout from 'layout';

const RootPage = ({}) => {
  const authContext = React.useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    if(!authContext.state.loading && !authContext.state.token) {
      navigate('/login')
    }
  }, [authContext.state.loading])

  if(authContext.state.loading) {
    return (
      <div>
        loading
      </div>
    )
  }
  if(authContext.state.token) {
    return (
      <Layout>
        <Outlet></Outlet>
      </Layout>
    );
  }
  else {
    return (
      <Outlet>
      </Outlet>
    )
  }
}

export default RootPage;
