import { Upload, Button, message, Form, Radio, DatePicker, Select, TimePicker, Input, InputNumber, Checkbox, Segmented } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import ls from 'utils/ls';
import { instance } from 'utils/axios';
import dayjs from 'dayjs';
import dayjsBusinessDays from 'dayjs-business-days';

dayjs.extend(dayjsBusinessDays);

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

export const fields = (type, suppliers, products, users, tenderForm, setReminderModalOpen, reminderForm) => {
  const userInfo = ls.get("userInfo");
  const token = userInfo && userInfo.jwtToken;

  const handleAddDay = (_value) => {
    let tmp = 0
    let addDays = 0
    for(var i = 0; i <= _value; i++) {
      if(dayjs(tenderForm.getFieldValue("startDate")).add(i, 'days').day() == 6 || dayjs(tenderForm.getFieldValue("startDate")).add(i, 'days').day() == 0) {
        tmp += 1
      }
    }
    addDays = tmp
    for(var i = 0; i <= tmp; i++) {
      if(dayjs(tenderForm.getFieldValue("startDate")).add(i+_value, 'days').day() == 6 || dayjs(tenderForm.getFieldValue("startDate")).add(i+_value, 'days').day() == 0) {
        addDays += 1
      }
    }
    tenderForm.setFieldValue("endDate", dayjs(tenderForm.getFieldValue("startDate")).add(_value+addDays, 'days'))
  }

  const handleDurationChange = (e) => {
    if(tenderForm.getFieldValue("closeDateDurationType")) {
      tenderForm.setFieldValue("endDate", dayjs(tenderForm.getFieldValue("startDate")).add(e, tenderForm.getFieldValue("closeDateDurationType") == 1 ? 'days': 'hours'))
    }
    else {
      tenderForm.setFieldValue("endDate", dayjs(tenderForm.getFieldValue("startDate")).add(e, 'days'))
    }
  }

  const handleDurationTypeChange = (e) => {
    if(e == 1) {
      tenderForm.setFieldValue("endDate", dayjs(tenderForm.getFieldValue("startDate")).add(tenderForm.getFieldValue(""), 'days'))
    }
    else {
      tenderForm.setFieldValue("endDate", dayjs(tenderForm.getFieldValue("startDate")).add(e, 'hours'))
    }
  }

  const handleStartDateChange = (e) => {
    tenderForm.setFieldValue("startDate", dayjs(e.format("YYYY-MM-DD 09:00")))
  }
  
  return [
    {
      label: 'EOI number',
      name: 'code',
      rules: [{required: true, message: 'Code is required!'}],
      className: 'col-span-2 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Publish date',
      name: 'startDate',
      type: 'date',
      rules: [{required: true, type: "object", message: 'Publish date is required!'}],
      className: 'col-span-2 mb-0',
      inputProps: {
        className: 'w-full',
        format: "YYYY-MM-DD",
        onChange: handleStartDateChange
      },
    },
    {
      label: ' ',
      name: 'startDate',
      type: 'time',
      className: 'col-span-1 mb-0',
      inputProps: {
        className: 'w-full',
        minuteStep: 15,
        format: 'HH:mm'
      },
    },
    {
      type: 'component',
      component: (
        <Form.Item name="closeDateIsDuration" label="Close type" className="col-span-2">
          <Radio.Group>
            <Radio value={1}>Duration</Radio>
            <Radio value={0}>Fixed time</Radio>
          </Radio.Group>
        </Form.Item>
      )
    },
    {
      label: 'Duration',
      type: 'component',
      component: (
        <Form.Item noStyle shouldUpdate={(prev, next) => prev.closeDateIsDuration != next.closeDateIsDuration}>
          {({getFieldValue}) => {
            if(getFieldValue("closeDateIsDuration") == 1) {
              return (
                <>
                <Form.Item label="Close date" name="duration" className='col-span-2 mb-0' rules={[
                  {
                    required: true, 
                    message: 'Close date is required!'
                  },
                  {
                    validator: (_, value) => {
                    if (value < 1) {
                        return Promise.reject(new Error('Close date must be greater than 0'));
                      }
                      return Promise.resolve();
                    },
                  }
                ]}>
                  <InputNumber className="w-full" onChange={handleDurationChange}></InputNumber>
                </Form.Item>
                <Form.Item label=" " name="closeDateDurationType">
                  <Select onChange={handleDurationTypeChange}>
                    <Select.Option value={0}>Hours</Select.Option>
                    <Select.Option value={1}>Days</Select.Option>
                  </Select>
                </Form.Item>
                </>
              )
            }
          }}
        </Form.Item>
      )
    },
    {
      label: 'Close date',
      type: 'component',
      component: (
        <>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.closeDateIsDuration != next.closeDateIsDuration}>
            {({getFieldValue}) => {
              if(getFieldValue("closeDateIsDuration") == 0) {
                return (
                  <>
                  <Form.Item 
                    label="Close date" 
                    name="endDate" 
                    className="col-span-2" 
                    rules={[{required: true, type: "object", message: 'Close date is required!'}]}>
                    <DatePicker className="w-full" onChange={e => tenderForm.setFieldValue("endDate", dayjs(e.format("YYYY-MM-DD 17:00")))}/>
                  </Form.Item>
                  {/* <div className="flex flex-col">
                    <label className="mb-2">&nbsp;</label>
                    <TimePicker 
                      defaultValue={tenderForm.getFieldValue("endDate")}
                      format="HH:mm" 
                      className="w-full" 
                      minuteStep={15} 
                      onChange={e => {
                        tenderForm.setFieldValue("endDate", dayjs(`${tenderForm.getFieldValue("endDate").format("YYYY-MM-DD")} ${e.format("HH:mm")}`))
                      }}
                    />
                  </div> */}
                  <Form.Item
                    label=" "
                    name="endDate"
                    className="col-span-1 mb-0">
                    <TimePicker 
                      defaultValue={tenderForm.getFieldValue("endDate")}
                      format="HH:mm" 
                      picker="time"
                      className="w-full" 
                      minuteStep={15} 
                      onChange={e => {
                        tenderForm.setFieldValue("endDate", dayjs(`${tenderForm.getFieldValue("endDate").format("YYYY-MM-DD")} ${e.format("HH:mm")}`))
                      }}
                    />
                  </Form.Item>
                  </>
                )
              }
            }}
          </Form.Item>
          <Form.Item name="reminderDate" valuePropName='checked' className='col-span-2 mb-2 flex items-center'>
            <Checkbox onChange={e => {
              if(e.target.checked) {
                setReminderModalOpen(true)
              }
              else {
                tenderForm.setFieldValue("reminderDate", false)
              }
            }}>
              Reminder <span className='text-blue-500'>(Edit)</span>
            </Checkbox>
          </Form.Item>
        </>
      ),
    },
    {
      label: 'Description /Mongolia/',
      name: 'name',
      rules: [{required: true, message: 'Description is required!'}],
      className: 'col-span-6 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Description',
      name: 'enName',
      className: 'col-span-6 mb-0',
      rules: [{required: true, message: 'Description is required!'}],
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Short content /Mongolia/',
      name: 'description',
      type: 'textarea',
      rules: [{required: true, message: 'Short content is required!'}],
      className: 'col-span-6 mb-0',
      inputProps: {
        className: 'w-full',
        autoSize: true
      }
    },
    {
      label: 'Short content',
      name: 'enDescription',
      type: 'textarea',
      className: 'col-span-6 mb-0',
      rules: [{required: true, message: 'Short content is required!'}],
      inputProps: {
        className: 'w-full',
        autoSize: true
      }
    },
    
    {
      label: 'Body /Mongolia/',
      name: 'content',
      type: 'editor',
      rules: [{required: true, message: 'Body is required!'}],
      className: 'col-span-6 mb-0',
      inputProps: {
        className: 'w-full',
        template: true,
        type: type,
      }
    },
    {
      label: 'Body',
      name: 'enContent',
      type: 'editor',
      className: 'col-span-6 mb-0',
      rules: [{required: true, message: 'Body is required!'}],
      inputProps: {
        className: 'w-full',
        template: true,
        type: type,
      }
    },
  ]
}

const fixProductCodes = (pr, depth) => {
  let tmp = []

  pr.map((foo) => {
    tmp.push({
      selectable: depth == 1 ? true: false,
      value: foo.item.id, 
      title: `${foo.item.name} /${foo.item.code}/`, 
      children: fixProductCodes(foo.children, depth+1)
    })
  })

  return tmp 
}

  
export const rfqFields = (type, suppliers, products, users, tenderForm, setReminderModalOpen) => {
  const userInfo = ls.get("userInfo");
  const token = userInfo && userInfo.jwtToken;

  const ranges = [
    { label: 'Business 3 Days', value: [dayjs(), dayjs().businessDaysAdd(3)] },
    { label: 'Business 5 Days', value: [dayjs(), dayjs().businessDaysAdd(5)] },
    { label: 'Business 7 Days', value: [dayjs(), dayjs().businessDaysAdd(7)] },
    { label: 'Business 14 Days', value: [dayjs(), dayjs().businessDaysAdd(14)] },
    { label: 'Normal 3 Days', value: [dayjs(), dayjs().add(3, 'days')] },
    { label: 'Normal 5 Days', value: [dayjs(), dayjs().add(5, 'days')] },
    { label: 'Normal 7 Days', value: [dayjs(), dayjs().add(7, 'days')] },
    { label: 'Normal 14 Days', value: [dayjs(), dayjs().add(14, 'days')] },
  ]

  const handleAddDay = (_value) => {
    let tmp = 0
    let addDays = 0
    if(_value != 100) {
      for(var i = 0; i <= _value; i++) {
        if(dayjs(tenderForm.getFieldValue("startDate")).add(i, 'days').day() == 6 || dayjs(tenderForm.getFieldValue("startDate")).add(i, 'days').day() == 0) {
          tmp += 1
        }
      }
      addDays = tmp
      for(var i = 0; i <= tmp; i++) {
        if(dayjs(tenderForm.getFieldValue("startDate")).add(i+_value, 'days').day() == 6 || dayjs(tenderForm.getFieldValue("startDate")).add(i+_value, 'days').day() == 0) {
          addDays += 1
        }
      }
      tenderForm.setFieldValue("endDate", dayjs(tenderForm.getFieldValue("startDate")).add(_value+addDays, 'days'))
    }
  }
  
  return [
    {
      label: 'Bid #',
      name: 'code',
      rules: [{required: true, message: 'Code is required!'}],
      className: 'col-span-2 mb-0',
      inputProps: {
        className: 'w-full',
        disabled: true
      }
    },
    {
      label: 'ProcNow Ticket #',
      name: 'procurementNow',
      className: 'col-span-2 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Description',
      name: 'name',
      rules: [{required: true, message: 'Description is required!'}],
      className: 'col-span-4 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Type',
      name: 'rfqType',
      type: 'component',
      component: (
        <Form.Item name="rfqType" label="RQF Type" rules={[{required: true, message: 'RQF Type is required!'}]} className="col-span-2 mb-0">
          <Radio.Group>
            <Radio value={1}>Goods</Radio>
            <Radio value={2}>Service</Radio>
          </Radio.Group>
        </Form.Item>
      )
    },
    // {
    //   label: 'Allow alternative',
    //   name: 'allowAlternative',
    //   type: 'checkbox',
    //   className: 'col-span-1 mb-0 flex items-center',
    //   inputProps: {
    //     className: 'w-full flex items-center',
    //   }
    // },
    {
      label: 'Bid type',
      name: 'bidType',
      type: 'component',
      component: (
        <Form.Item name="bidType" label="RQF Type" rules={[{required: true, message: 'Bid Type is required!'}]} className="col-span-2 mb-0">
          <Radio.Group>
            <Radio value={1}>BID</Radio>
            <Radio value={2}>SSE</Radio>
          </Radio.Group>
        </Form.Item>
      )
    },
    {
      label: 'SSE Type',
      name: 'sseType',
      type: 'component',
      component: (
        <Form.Item noStyle shouldUpdate={(prev, next) => prev.bidType != next.bidType}>
          {({getFieldValue}) => {
            if(getFieldValue("bidType") == 2) {
              return (
                <>
                <Form.Item label="SSE Type" name="sseType" rules={[{required: true}]} className='col-span-2 mb-0'>
                  <Radio.Group>
                    <Radio value={1}>Normal</Radio>
                    <Radio value={2}>Send for approval</Radio>
                  </Radio.Group>
                </Form.Item>
                </>
              )
            }
          }}
        </Form.Item>
      )
    },
    {
      label: 'Short content',
      name: 'description',
      type: 'textarea',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    }, 
    {
      label: 'Publish date',
      name: 'startDate',
      type: 'date',
      rules: [{required: true, type: "object", message: 'Publish date is required!'}],
      className: 'col-span-2 mb-0',
      inputProps: {
        className: 'w-full',
        format: "YYYY-MM-DD",
      },
    },
    {
      label: ' ',
      name: 'startDate',
      type: 'time',
      className: 'col-span-1 mb-0',
      inputProps: {
        className: 'w-full',
        minuteStep: 15,
        format: 'HH:mm'
      },
    },
    {
      label: 'Close date',
      type: 'component',
      className: "col-span-1 mb-0",
      component: (
        <Form.Item name="closeDateIsDuration" label="Close type">
          <Radio.Group>
            <Radio value={1}>Duration</Radio>
            <Radio value={0}>Fixed time</Radio>
          </Radio.Group>
        </Form.Item>
      )
    },
    {
      label: 'Duration',
      type: 'component',
      component: (
        <Form.Item noStyle shouldUpdate={(prev, next) => prev.closeDateIsDuration != next.closeDateIsDuration}>
          {({getFieldValue}) => {
            if(getFieldValue("closeDateIsDuration") == 1) {
              return (
                <>
                <Form.Item label="Close date" name="duration" className='col-span-1 mb-0'>
                  <InputNumber className="w-full"></InputNumber>
                </Form.Item>
                <Form.Item label=" " name="closeDateDurationType">
                  <Select>
                    <Select.Option value={0}>Hours</Select.Option>
                    <Select.Option value={1}>Days</Select.Option>
                  </Select>
                </Form.Item>
                </>
              )
            }
          }}
        </Form.Item>
      )
    },
    {
      label: 'Close date',
      type: 'component',
      component: (
        <>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.closeDateIsDuration != next.closeDateIsDuration}>
            {({getFieldValue}) => {
              if(getFieldValue("closeDateIsDuration") == 0) {
                return (
                  <>
                  <Form.Item label="Close date" name="endDate" className="col-span-2 mb-0" rules={[{required: true, type: "object", message: 'Publish date is required!'}]}>
                    <DatePicker className="w-full"/>
                  </Form.Item>
                  <Form.Item label=" " name="endDate" className="col-span-1 mb-0">
                    <TimePicker format="HH:mm" className="w-full" minuteStep={15}/>
                  </Form.Item>
                  </>
                )
              }
            }}
          </Form.Item>
          <Form.Item name="reminderDate" valuePropName='checked' className='col-span-2 mb-2 flex items-center'>
            <Checkbox onChange={e => {
              if(e.target.checked) {
                setReminderModalOpen(true)
              }
              else {
                tenderForm.setFieldValue("reminderDate", false)
              }
            }}>
              Reminder <span className='text-blue-500'>(Edit)</span>
            </Checkbox>
          </Form.Item>
        </>
      ),
    },
    // {
    //   label: 'End users',
    //   name: 'endUserIds',
    //   type: 'select',
    //   className: 'col-span-12 mb-0',
    //   inputProps: {
    //     className: 'w-full',
    //     mode: 'multiple',
    //     filterOption: (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()),
    //     options: users.map(foo => ({label: `${foo.firstName} ${foo.lastName}`, value: foo.id})),
    //     placeholder: 'Search end users'
    //   }
    // },
    // {
    //   label: 'Attachment file',
    //   type: 'component',
    //   className: 'col-span-12 mb-0',
    //   component: (
    //     <Form.Item name="attachments" label="Attachment file" className="col-span-12 mb-0" valuePropName="fileList" getValueFromEvent={normFile}>
    //       <Upload 
    //         name="file" 
    //         action={`${process.env.REACT_APP_MAIN_API_URL}File/upload`}
    //         headers={{
    //           Authorization: `Bearer ${token}`,
    //         }}
    //         multiple
    //         onRemove={(file) => {
    //           instance({
    //             method: 'delete',
    //             url: `File/remove`,
    //             data: file.response.responseData
    //           })
    //         }}>
    //         <Button icon={<UploadOutlined />}>Select file</Button>
    //       </Upload>
    //     </Form.Item>
    //   )
    // },
    {
      label: 'Body',
      name: 'content',
      type: 'editor',
      rules: [{required: true, message: 'Body is required!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
        template: true,
        type: type
      }
    },
    // {
    //   label: 'Scope SECT Code',
    //   name: 'productTypeIds',
    //   type: 'treeSelect',
    //   className: 'col-span-12 mb-0',
    //   inputProps: {
    //     className: 'w-full',
    //     mode: 'multiple',
    //     filterOption: (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()),
    //     treeData: fixProductCodes(products, 0),
    //     allowClear: true,
    //     multiple: true,
    //     placeholder: 'Select product sect code'
    //   }
    // },
  ]
}

export const travelFields = (type, templateList, form) => {
  const userInfo = ls.get("userInfo");
  const token = userInfo && userInfo.jwtToken;
  
  return [
    {
      label: 'TR number',
      name: 'code',
      rules: [{required: true, message: 'Tr number is required!'}],
      className: 'col-span-3 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Travel purpose',
      name: 'name',
      type: 'select',
      rules: [{required: true, message: 'Travel purpose is required!'}],
      className: 'col-span-3 mb-0',
      inputProps: {
        className: 'w-full',
        options: templateList.map((foo) => ({value: foo.event.name, label: foo.event.name})),
        onChange: (e) => form.setFieldValue('content', templateList.find(foo => foo.event.name == e)?.template.content)
      }
    },
    {
      label: 'Start date',
      name: 'startDate',
      type: 'date',
      rules: [{required: true, message: 'Start date is required!'}],
      className: 'col-span-3 mb-0',
      inputProps: {
        className: 'w-full',
        format: "YYYY-MM-DD HH:mm",
        showTime: {
          format: 'HH:mm'
        },
      }
    },
    {
      label: 'End date',
      name: 'endDate',
      type: 'date',
      rules: [{required: true, message: 'End date is required!'}],
      className: 'col-span-3 mb-0',
      inputProps: {
        className: 'w-full',
        format: "YYYY-MM-DD HH:mm",
        showTime: {
          format: 'HH:mm'
        },
      }
    },
    {
      label: 'Use reminder date',
      name: 'reminderDateCheck',
      type: 'checkbox',
      className: 'col-span-2 mb-0 flex items-center',
      inputProps: {
        className: 'w-full flex items-center',
      }
    },
    {
      type: 'dependent',
      dependentName: 'reminderDateCheck',
      dependentValue: true,
      className: 'col-span-3 mb-0',
      children: [
        {
          label: 'Reminder date /minute/',
          name: 'lastNum',
          type: 'number',
          className: "col-span-3 mb-0",
          inputProps: {
            className: 'w-full',
          }
        }
      ],
    },
    {
      label: 'Attachment file',
      type: 'component',
      className: 'col-span-12 mb-0',
      component: (
        <Form.Item name="attachments" label="Attachment file" className="col-span-12 mb-0" valuePropName="fileList" getValueFromEvent={normFile}>
          <Upload 
            name="file" 
            action={`${process.env.REACT_APP_MAIN_API_URL}File/upload`}
            headers={{
              Authorization: `Bearer ${token}`,
            }}
            multiple
            onRemove={(file) => {
              instance({
                method: 'delete',
                url: `File/remove`,
                data: file.response.responseData
              })
            }}>
            <Button icon={<UploadOutlined />}>Select file</Button>
          </Upload>
        </Form.Item>
      )
    },
    {
      label: 'Content',
      name: 'content',
      type: 'editor',
      rules: [{required: true, message: 'Content is required!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
        // template: true,
        // type: type
      }
    },
  ]
}

export const freightFields = (type, suppliers, products, users, tenderForm, setReminderModalOpen) => {
  const userInfo = ls.get("userInfo");
  const token = userInfo && userInfo.jwtToken;

  const handleDurationChange = (e) => {
    if(tenderForm.getFieldValue("closeDateDurationType")) {
      tenderForm.setFieldValue("endDate", dayjs(tenderForm.getFieldValue("startDate")).add(e, tenderForm.getFieldValue("closeDateDurationType") == 1 ? 'days': 'hours'))
    }
    else {
      tenderForm.setFieldValue("endDate", dayjs(tenderForm.getFieldValue("startDate")).add(e, 'days'))
    }
  }

  const handleDurationTypeChange = (e) => {
    if(e == 1) {
      tenderForm.setFieldValue("endDate", dayjs(tenderForm.getFieldValue("startDate")).add(tenderForm.getFieldValue(""), 'days'))
    }
    else {
      tenderForm.setFieldValue("endDate", dayjs(tenderForm.getFieldValue("startDate")).add(e, 'hours'))
    }
  }
  
  return [
    {
      label: 'SRN Number',
      name: 'code',
      rules: [{required: true, message: 'SRN Number is required!'}],
      className: 'col-span-2 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Name',
      name: 'name',
      rules: [{required: true, message: 'Name is required!'}],
      className: 'col-span-4 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Priority',
      name: 'priority',
      rules: [{required: true, message: 'Priority is required!'}],
      className: 'col-span-1 mb-0',
      type: 'select',
      inputProps: {
        className: 'w-full',
        options: [
          { label: 'Shutdown', value: '4'},
          { label: 'High', value: '3'},
          { label: 'Medium', value: '2'},
          { label: 'Low', value: '1'},
        ]
      }
    },
    {
      type: 'component',
      className: 'col-span-1 mb-0',
      component: (
        <Form.Item label="Type" name="isImport" className='col-span-2 mb-0'>
          <Segmented options={[
            {
              label: 'Import',
              value: 1
            },
            {
              label: 'Export',
              value: 0
            },
          ]}>

          </Segmented>
        </Form.Item>
      )
    },
    {
      label: 'Oversized',
      name: 'isOversized',
      type: 'checkbox',
      className: 'col-span-1 mb-0',
    },
    {
      label: 'Dangerous goods',
      name: 'isDangerous',
      type: 'checkbox',
      className: 'col-span-2 mb-0',
    },
    {
      label: 'Description',
      name: 'description',
      type: 'textarea',
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Publish date',
      name: 'startDate',
      type: 'date',
      rules: [{required: true, type: "object", message: 'Publish date is required!'}],
      className: 'col-span-2 mb-0',
      inputProps: {
        className: 'w-full',
        format: "YYYY-MM-DD",
      },
    },
    {
      label: ' ',
      name: 'startDate',
      type: 'time',
      className: 'col-span-1 mb-0',
      inputProps: {
        className: 'w-full',
        minuteStep: 15,
        format: 'HH:mm'
      },
    },
    {
      label: 'Close date',
      type: 'component',
      className: "col-span-1 mb-0",
      component: (
        <Form.Item name="closeDateIsDuration" label="Close type">
          <Radio.Group>
            <Radio value={1}>Duration</Radio>
            <Radio value={0}>Fixed time</Radio>
          </Radio.Group>
        </Form.Item>
      )
    },
    {
      label: 'Duration',
      type: 'component',
      component: (
        <Form.Item noStyle shouldUpdate={(prev, next) => prev.closeDateIsDuration != next.closeDateIsDuration}>
          {({getFieldValue}) => {
            if(getFieldValue("closeDateIsDuration") == 1) {
              return (
                <>
                <Form.Item label="Close date" name="duration" className='col-span-1 mb-0'>
                  <InputNumber className="w-full"></InputNumber>
                </Form.Item>
                <Form.Item label=" " name="closeDateDurationType">
                  <Select>
                    <Select.Option value={0}>Hours</Select.Option>
                    <Select.Option value={1}>Days</Select.Option>
                  </Select>
                </Form.Item>
                </>
              )
            }
          }}
        </Form.Item>
      )
    },
    {
      label: 'Close date',
      type: 'component',
      component: (
        <>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.closeDateIsDuration != next.closeDateIsDuration}>
            {({getFieldValue}) => {
              if(getFieldValue("closeDateIsDuration") == 0) {
                return (
                  <>
                  <Form.Item label="Close date" name="endDate" className="col-span-2 mb-0" rules={[{required: true, type: "object", message: 'Publish date is required!'}]}>
                    <DatePicker className="w-full"/>
                  </Form.Item>
                  <Form.Item label=" " name="endDate" className="col-span-1 mb-0">
                    <TimePicker format="HH:mm" className="w-full" minuteStep={15}/>
                  </Form.Item>
                  </>
                )
              }
            }}
          </Form.Item>
        </>
      ),
    },
    {
      label: 'Use reminder',
      name: 'reminderDateCheck',
      type: 'checkbox',
      className: 'col-span-1 mb-0 flex',
      inputProps: {
        className: 'w-full flex items-center',
      }
    },
    {
      type: 'dependent',
      dependentName: 'reminderDateCheck',
      dependentValue: true,
      children: [
        {
          label: 'Reminder date /before close date/',
          name: 'lastNum',
          type: 'number',
          className: "col-span-2 mb-0",
          inputProps: {
            className: 'w-full',
          }
        }
      ],
    },
    {
      type: 'dependent',
      dependentName: 'reminderDateCheck',
      dependentValue: true,
      children: [
        {
          label: 'Reminder type',
          name: 'lastCounterType',
          type: 'select',
          className: "col-span-2 mb-0",
          inputProps: {
            options: [
              {
                value: 1,
                label: 'Minutes'
              },
              {
                value: 2,
                label: 'Hours'
              },
              {
                value: 3,
                label: 'Days'
              },
            ],
            className: 'w-full',
          }
        }
      ],
    },
    {
      label: 'Attachment file',
      type: 'component',
      className: 'col-span-12 mb-0',
      component: (
        <Form.Item name="attachments" label="Attachment file" className="col-span-12 mb-0" valuePropName="fileList" getValueFromEvent={normFile}>
          <Upload 
            name="file" 
            action={`${process.env.REACT_APP_MAIN_API_URL}File/upload`}
            headers={{
              Authorization: `Bearer ${token}`,
            }}
            multiple
            onRemove={(file) => {
              instance({
                method: 'delete',
                url: `File/remove`,
                data: file.response.responseData
              })
            }}>
            <Button icon={<UploadOutlined />}>Select file</Button>
          </Upload>
        </Form.Item>
      )
    },
    {
      label: 'Content',
      name: 'content',
      type: 'editor',
      rules: [{required: true, message: 'Content is required!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
        template: true,
        type: type
      }
    },
  ]
}