import React, { useEffect, useMemo, useState } from 'react'
import { Button, Modal, Dropdown, Menu, Form as AntForm, Tag, Space, Input, Skeleton, Card, Tooltip, InputNumber, Popconfirm, Upload } from 'antd'
import { EditOutlined, PlusCircleOutlined, DeleteOutlined, ExclamationCircleOutlined, MoreOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { Filters, Form, MainTable } from 'components'
import { instance } from 'utils/axios'
import fields from './fields'
import { Link } from 'react-router-dom'
import Filter from './Filter'
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment'
import { FixedSizeList as List } from 'react-window';
import ls from 'utils/ls'

const { Search } = Input

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const statusEnum = {
  0: {
    title: 'Draft',
    color: ''
  },
  1: {
    title: 'Open',
    color: ''
  },
  2: {
    title: 'Closed',
    color: ''
  },
  3: {
    title: 'Finished',
    color: ''
  },
  4: {
    title: 'Cancelled',
    color: ''
  },
}

function Auction() {
  const [ data, setData ] = useState({
    total: 0,
    data: []
  })
  const [ currentPage, setCurrentPage ] = useState(0)
  const [ open, setOpen ] = useState(false)
  const [ editData, setEditData ] = useState(null)
  const [ isEdit, setIsEdit ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ modal, contextHolder ] = Modal.useModal();
  const [ filter, setFilter ] = useState({
    
  })
  const [ page, setPage ] = useState(1)
  const [ pageSize, setPageSize ] = useState(20)
  const [ searchValue, setSearchValue ] = useState("")
  const [ hasMore, setHasMore ] = useState(true)
  const [ form ] = AntForm.useForm();

  
  useEffect(() => {
    setPage(1)
    fetchData()  
  },[])

  const fetchData = () => {
    setLoading(true)
    instance({
      method: 'get',
      url: '/Auction?wcode=auction',
      params: {
        page: page,
        pageSize: pageSize
      }
    }).then((res) => {
      if(res.data.responseData?.data.length < pageSize) {
        setHasMore(false)
      }
      setData(res.data.responseData)
      setPage(+1)
    }).catch((err) => {
    }).then(() => setLoading(false))
  }
  
  const fetchNext = () => {
    instance({
      method: 'get',
      url: '/Auction?wcode=auction'
    }).then((res) => {
      if(res.data.responseData?.data.length < pageSize) {
        setHasMore(false)
      }
      setData(res.data.responseData)
      setPage(page+1)
    }).catch((err) => {
    })
  }

  const handleDelete = (row) => {
    modal.confirm({
      title: 'Warning!',
      content: 'You are about to delete this row of data',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: 'delete',
            url: `/auction/${row.id}?wcode=auction`
          }).then((res) => {
            resolve();
          }).catch((err) => {
            reject();
          }).then(() => fetchData());
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {
      },
    });
  }
  
  const handleSubmit = (values) => {
    setLoading(true);
    if(editData) {
      instance({
        method: 'put',
        url: '/auction?wcode=auction', 
        data: {
          ...values,
          id: editData.id,
          image: values.image && values.image[0]?.response?.responseData,
          fileName: values.fileName && values.fileName[0]?.response?.responseData,
        }
      })
      .then((res) => {
        handleCloseModal();
        fetchData();
      })
      .catch((err) => {
      })
      .finally(() => setLoading(false));
    } 
    else {
      console.log(values)
      instance({
        method: 'post',
        url: '/auction?wcode=auction',
        data: {
          ...values,
          fileName: values.fileName && values.fileName[0]?.response?.responseData,
          image: values.image && values.image[0]?.response?.responseData,
          items: values.items.map((foo, fooIndex) => ({
            ...foo,
            image: foo?.image[0] ? foo.image[0].response.responseData : '',
            auctionId: 0
          }))
        } 
      })
      .then((res) => {
        handleCloseModal();
        fetchData();
      })
      .catch((err) => {
      })
      .finally(() => setLoading(false));
    }
  }

  const handleAddNew = () => {
    setIsEdit(true)
    setEditData(null)
    setOpen(true)
  }

  const handleEdit = (row) => {
    setEditData(row)
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
    form.resetFields()
  }

  const onSearch = (values) => {
    setFilter(prev => ({...prev, ...values}))
  }
  
  const filteredData = useMemo(() => {
    return data.data.filter(foo => foo.title.toLowerCase().includes(searchValue.toLowerCase()))
  }, [data, searchValue])
  
  
  return (
      <div className='p-4'>
        <div className="grid grid-cols-12 gap-3">
          <div className="col-span-12">
            <Filters activeFilters={filter} wcode="auction" onSearch={onSearch}/>
          </div>
          <div className="col-span-12">
            <div className="flex flex-col">
              <div className="mb-3">
                <Search 
                  placeholder="Search" 
                  size="large" 
                  className='border-none'
                  enterButton 
                  onChange={e => setSearchValue(e.target.value)} />
              </div>
              <div className="bg-white p-5 mb-3 font-semibold flex items-center justify-between">
                {filteredData.length} of {data.total} Results
                <div className='flex gap-2'>
                  <Button onClick={handleAddNew} type="primary">
                    Add new auction
                  </Button>
                </div>
              </div>
              {
                loading ? 
                <Skeleton></Skeleton>
                :
                <InfiniteScroll
                  dataLength={data.data.length}
                  next={fetchNext}
                  loader={<div className='text-center font-bold'>Fetching...</div>}
                  className="flex flex-col gap-1"
                  hasMore={hasMore}
                  endMessage={
                    <p className="text-center font-bold">
                      No more auction data
                    </p>
                  }>
                  {
                    filteredData.map((foo, fooIndex) => {
                      return (
                        <Card
                          bodyStyle={{padding: 0}}
                          className="rounded-none p-0"
                          type="inner"
                          key={`tender-${fooIndex}`}>
                          <div className="grid grid-cols-12 gap-x-2">
                            <div className='col-span-7 flex gap-4 p-4'>
                              <div className="w-12 h-12 rounded-full flex justify-center items-center text-white bg-primary text-3xl">
                                {foo.title.slice(0,1)}
                              </div>
                              <div className="flex-1 overflow-hidden">
                                <Tooltip title={foo.name}>
                                  <Link 
                                    className="text-primary overflow-hidden text-ellipsis whitespace-nowrap cursor-pointer" 
                                    to={`${foo.id}`} 
                                    state={{...foo}}>
                                    {foo.title}
                                  </Link>
                                </Tooltip>
                                {/* <div className='text-mute'>{`${foo.auditName}`}</div> */}
                              </div>
                            </div>
                            <div className='col-span-5 flex justify-between'>
                              <div className="flex p-4 gap-4">
                                <div className="flex flex-col items-start w-[100px]">
                                  <label className="text-mute">Status</label>
                                  <Tag color={statusEnum[foo.status]?.color}>
                                    {statusEnum[foo.status]?.title}
                                  </Tag>
                                </div>
                                <div className="flex flex-col items-center">
                                  <label className="text-mute">Date</label>
                                  <div className="font-semibold">
                                    {moment(foo.startDate).format("YYYY-MM-DD")} - {moment(foo.endDate).format("YYYY-MM-DD")}
                                  </div>
                                </div>
                              </div>
                              <Dropdown
                                trigger={['click']}
                                menu={{
                                  items: [
                                    {
                                      label: <div className='flex items-center gap-2' onClick={() => handleEdit(foo)}><EditOutlined/> Edit</div>,
                                      key: 0
                                    },
                                    {
                                      label: <div className='flex items-center gap-2' onClick={() => handleDelete(foo)}><DeleteOutlined /> Delete</div>,
                                      key: 1
                                    },
                                  ]
                                }}
                              >
                                <a className="flex justify-center items-center" onClick={e => e.preventDefault()}>
                                  <Space className="px-3">
                                    <MoreOutlined className='text-xl'/>
                                  </Space>
                                </a>
                              </Dropdown>
                            </div>
                          </div>
                        </Card>
                      )
                    })
                  }
                </InfiniteScroll>
              }
            </div>
          </div>
        </div>
        <Modal
          title={editData ? <div className='flex items-center justify-start gap-2'><EditOutlined/>Edit</div> : <div className='flex items-center justify-start gap-2'><PlusCircleOutlined/>Add</div>}
          open={open}
          destroyOnClose
          maskClosable={false}
          okText='Save'
          onOk={form.submit}
          onCancel={handleCloseModal}
          width={"80%"}
          style={{top: '20px'}}
        >
          <Form
            form={form}
            fields={fields()}
            length={data.length}
            layout="vertical"
            onFinish={handleSubmit}
            editData={editData}
            isEdit={isEdit}
            className='gap-4'
          >
          <AuctionItemsForm></AuctionItemsForm>
          </Form>
        </Modal>
        {contextHolder}
      </div>
  )
}

const AuctionItemsForm = () => {
  const form = AntForm.useFormInstance();
  
  const handleDelete = (key) => {
    let tmp = [...form.getFieldValue("items")]
    tmp.splice(key, 1);
    form.setFieldValue("items", tmp);
  };
  
  return (
    <AntForm.List name="items" label="Auction items">
      {(fields, {add, remove}) => {
        return (
          <div className="col-span-12 flex flex-col h-[500px]">
            <div className="my-1 text-nowrap">Items list</div>
              <div className="flex">
                <div className="w-[50px] text-center py-1 font-medium border border-r-0">
                  #
                </div>
                <div className="flex-1 px-3 py-1 font-medium border border-r-0">
                  Name
                </div>
                <div className="flex-1 px-3 py-1 font-medium border border-r-0">
                  Description
                </div>
                <div className="w-[120px] px-3 py-1 font-medium border border-r-0">
                  Quantity
                </div>
                <div className="w-[200px] px-3 py-1 font-medium border border-r-0">
                  Image
                </div>
                <div className="w-[50px] px-3 py-1 border">
                  &nbsp;
                </div>
              </div>
              <List
                className='List'
                height={fields.length > 0 ? 500 : 0}
                width={(window.innerWidth*0.8) - 54}
                itemCount={fields.length}
                itemSize={40}>
                {({index, style}) => EditRow({index, style, handleDelete})}
              </List>
              {/* <Table
                dataSource={fields}
                rowKey={(row, index) => {
                  return index
                }}
                pagination={false}
                components={{
                  body: {
                    cell: EditableCell,
                    row: EditableRow
                  },
                }}
                bordered={true}
                columns={columns}>

              </Table> */}
            <AntForm.Item noStyle>
              <Button type="dashed" className="mt-3" onClick={() => {
                add({state: 1})
              }} block icon={<PlusOutlined />}>
                Add
              </Button>
            </AntForm.Item>
          </div>
        )
      }} 
    </AntForm.List>
  )
}


const EditRow = ({index, style, handleDelete}) => {
  const [ modalOpen, setModalOpen ] = useState(false)
  const userInfo = ls.get("userInfo");
  const token = userInfo && userInfo.jwtToken;
  const form = AntForm.useFormInstance()
  const value = AntForm.useWatch(['items', index, 'image'], form)

  const handleDeleteImage = () => {
    form.setFieldValue(['items', index, 'image'], null)
    if(value && value[0] && value[0].response?.responseData) {
      instance({
        method: 'delete',
        url: `File/remove`,
        data: value[0].response?.responseData
      })
    }
  }
  
  return (
    <div style={style} className="flex">
      <div className="w-[50px] flex justify-center items-center border border-r-0 border-t-0">{index+1}</div>
      <div className="flex-1 px-3 py-1 border border-r-0 border-t-0">
        <AntForm.Item name={[index, 'name']} className='mb-0'>
          <Input size="small"/>
        </AntForm.Item>
      </div>
      <div className="flex-1 px-3 py-1 border border-r-0 border-t-0">
        <AntForm.Item name={[index, 'description']} className='mb-0'>
          <Input size="small" className='w-full'/>
        </AntForm.Item>
      </div>
      <div className="w-[120px] px-3 py-1 border border-r-0 border-t-0">
        <AntForm.Item name={[index, 'qty']} className='mb-0'>
          <InputNumber size="small"/>
        </AntForm.Item>
      </div>
      <div className="w-[200px] px-3 py-1 border border-r-0 border-t-0">
        {
          value && value.length > 0 &&
          <div className='flex items-center justify-between'>
            <div className="text-primary hover:underline transition-all cursor-pointer" onClick={() => setModalOpen(value)}>
              Preview
            </div>
            <Tooltip title="Delete image">
              <Button type="text" icon={<DeleteOutlined/>} size="small" onClick={handleDeleteImage}>

              </Button>
            </Tooltip>
          </div>
        }
        <AntForm.Item name={[index, 'image']} className='mb-0' valuePropName="fileList" getValueFromEvent={normFile}>
          {
            <Upload 
              size="small"
              className={value && value.length > 0 && "hidden"}
              multiple
              action={`${process.env.REACT_APP_MAIN_API_URL}File/upload`}
              headers={{
                Authorization: `Bearer ${token}`,
              }}
              accept='image/*'
              onRemove={(file) => {
                if(file.response) {
                  instance({
                    method: 'delete',
                    url: `File/remove`,
                    data: file.response.responseData
                  })
                }
              }}>
              <Button className='w-full' icon={<UploadOutlined />}>Select file</Button>

            </Upload>
          }
        </AntForm.Item>
      </div>
      <div className="w-[50px] flex justify-center items-center border border-t-0">
        <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(index)}>
          <a><DeleteOutlined /></a>
        </Popconfirm>
      </div>
      <Modal
        open={modalOpen}
        onCancel={() => setModalOpen(null)}
        title="Preview"
        width={"50%"}
        destroyOnClose>
        {
          modalOpen &&
          <img src={process.env.REACT_APP_CDN_URL+modalOpen[0]?.response?.responseData ?? ''} className='w-full'>

          </img>
        }
      </Modal>
    </div>
  )
}

export default Auction
