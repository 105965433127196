import { Button, Form, Select, Upload } from "antd";
import { UploadOutlined } from '@ant-design/icons'
import ls from "utils/ls";
import { instance } from "utils/axios";

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const ServiceAdditionalForm = ({endUsers, products}) => {
  const userInfo = ls.get("userInfo");
  const token = userInfo && userInfo.jwtToken;

  return (
    <>
      <Form.Item name="attachments" label="Attachment" className="col-span-6 mb-0" valuePropName="fileList" getValueFromEvent={normFile}>
        <Upload 
          multiple
          action={`${process.env.REACT_APP_MAIN_API_URL}File/upload`}
          headers={{
            Authorization: `Bearer ${token}`,
          }}
          onRemove={(file) => {
            if(file.response) {
              instance({
                method: 'delete',
                url: `File/remove`,
                data: file.response.responseData
              })
            }
          }}>
          <Button icon={<UploadOutlined />}>Select file</Button>
        </Upload>
      </Form.Item>
      <Form.Item className="col-span-12 mb-0" label="End users" name="endUserIds">
        <Select maxTagCount={2} maxCount={2} options={endUsers.map(foo => ({label: `${foo.firstName} ${foo.lastName}`, value: foo.id}))} multiple className='w-full' mode="tags"></Select>
      </Form.Item>
    </>
  )
}

export default ServiceAdditionalForm