import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, DatePicker, Form, Input, InputNumber, Popconfirm, Select, Table } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import dayjs from 'dayjs';

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  type,
  options,
  ...restProps
}) => {
  const [editing, setEditing] = useState(true);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[]}>
      {
        type ? 
        type == 'select' ?
        <Select onPressEnter={save} onBlur={save} options={options}/>
        :
        type == "number" ?
        <InputNumber onPressEnter={save} onBlur={save} ></InputNumber>
        :
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        :
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      }
      </Form.Item>
    ) : (
      children
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const PassengersInformation = ({tenderData, setActiveStep, setTenderData}) => {
  const [dataSource, setDataSource] = useState(tenderData?.passengers ?? []);
  const [count, setCount] = useState(tenderData?.passengers ? tenderData.passengers.length : 0);

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  const defaultColumns = [
    {
      title: "#",
      editable: false,
      width: 30,
      render: (cell, row, index) => index+1
    },
    {
      title: 'Request ID',
      dataIndex: 'requestId',
      type: 'number',
      editable: true,
      render: (cell, row, index) => {
        return (<InputNumber className='w-full' size="small" value={cell} onChange={(e) => handleChange(index, 'requestId', e.target.value)}></InputNumber>)
      }
    },
    {
      title: 'Firstname',
      dataIndex: 'firstName',
      editable: true,
      render: (cell, row, index) => (
        <Input size="small" value={cell} onChange={(e) => handleChange(index, 'firstName', e.target.value)}></Input>
      )
    },
    {
      title: 'Lastname',
      dataIndex: 'lastName',
      editable: true,
      render: (cell, row, index) => (
        <Input size="small" value={cell} onChange={(e) => handleChange(index, 'lastName', e.target.value)}></Input>
      )
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      editable: true,
      width: 100,
      render: (cell, row, index) => (
        <Select size="small" className="w-full" value={cell} options={[{label: 'Male', value: 'male'}, {label: 'Female', value: 'female'}]} onChange={e => handleChange(index, 'gender', e)}>

        </Select>
      )
    },
    {
      title: 'DOB',
      dataIndex: 'dob',
      editable: true,
      width: 150,
      render: (cell, row, index) => (
        <DatePicker size="small" value={dayjs(cell)} onChange={e => handleChange(index, 'dob', e)}></DatePicker>
      )
    },
    {
      title: 'Passport number',
      dataIndex: 'passportNo',
      editable: true,
      render: (cell, row, index) => (
        <Input size="small" value={cell} onChange={(e) => handleChange(index, 'passportNo', e.target.value)}></Input>
      )
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 50,
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
            <a><DeleteOutlined /></a>
          </Popconfirm>
        ) : null,
    },
  ];

  const handleAdd = () => {
    const newData = {};
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleChange = (index, dataIndex, value) => {
    setDataSource(prev => {
      let tmp = Object.assign([], prev)
      tmp[index][dataIndex] = value
      return tmp
    })
  }

  const handleSubmit = () => {
    setTenderData(prev => ({
      ...prev,
      passengers: dataSource
    }))
    setActiveStep(2)
  }
  
  return (
    <div>
      <Table
        // components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={defaultColumns}
        pagination={false}
        size='small'
      />
      <Button className="mt-4" type="dashed" onClick={handleAdd} block icon={<PlusOutlined />}>
        Add
      </Button>
      <div className="flex justify-end gap-3 mt-3">
        <Button onClick={() => setActiveStep(0)}>
          Previous
        </Button>
        <Button type="primary" onClick={() => {
          handleSubmit()
        }}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default PassengersInformation