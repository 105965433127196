import { Modal, Skeleton, Steps } from "antd"
import { SnippetsOutlined, SolutionOutlined, CheckCircleOutlined } from '@ant-design/icons'
import RFQReport from "./RFQReport"
import { useEffect, useState } from "react"
import RFQForm from "./RFQForm"
import { instance } from "utils/axios"
import { useParams } from "react-router-dom"
import RFQApprovers from "./RFQApprovers"
import ServiceForm from "./ServiceForm"
import ServiceReport from "./ServiceReport"

const ApprovalModal = ({open, close, selected, rawData}) => {
  const [ activeStep, setActiveStep ] = useState(0)
  const [ loading, setLoading ] = useState(true)
  const [ data, setData ] = useState({})
  const [ mainData, setMainData ] = useState({})
  const { id } = useParams() 
  
  useEffect(() => {
    instance({
      method: 'get',
      url: `/Tender/rfq/${id}?wcode=tender-detail`
    }).then((res) => {
      setMainData(res.data.responseData)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }, [])
  
  const handleClose = () => {
    close(false)
  }

  const handleSubmit = (_data) => {
    setData(prev => ({...prev, ..._data}))
    setActiveStep(1)
  }

  const handleApproversSubmit = (_data) => {
    setData(prev => ({...prev, users: _data}))
    setActiveStep(2)
  }

  const goBack = () => {
    setActiveStep(prev => prev-1)
  }

  return (
    <Modal
      width={"90vw"}
      style={{top: 20}}
      title="Send for approval"
      open={open}
      onCancel={handleClose}
      destroyOnClose
      footer={false}>
      <Steps
        current={activeStep}
        items={[
          {
            title: "RFQ report",
            icon: <SnippetsOutlined />,
          },
          {
            title: 'Confirm',
            icon: <CheckCircleOutlined />,
          },
        ]}/>
      <div className="mt-3">
        {
          loading ? 
          <Skeleton active/>
          :
          activeStep == 0 ?
          mainData.rfqType == 1?
          <RFQForm rawData={rawData} data={data} selected={selected} closeModal={close} handleSubmit={handleSubmit}/>
          :
          <ServiceForm  rawData={rawData} data={data} selected={selected} closeModal={close} handleSubmit={handleSubmit}/>
          :
          mainData.rfqType == 1?
          <RFQReport data={data} rawData={rawData} mainData={mainData} selected={selected} closeModal={goBack} handleClose={handleClose}/> 
          :
          <ServiceReport data={data} rawData={rawData} mainData={mainData} selected={selected} closeModal={goBack} handleClose={handleClose}/>
        }
      </div>
    </Modal>
  )
}

export default ApprovalModal