import React, { useEffect, useState } from 'react'
import { Button, Tooltip, Skeleton, Divider, Table, Card, Steps, Input, Collapse, Tabs } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { useNavigate, Outlet, NavLink } from 'react-router-dom';
import { instance } from 'utils/axios';
import { useParams, Link, useLocation } from "react-router-dom";
import { BsClipboardCheck, BsJournalBookmark, BsPeople, BsViewStacked, BsBoxes, BsChatQuote, BsPencilSquare, BsQuestionSquare  } from "react-icons/bs";
import { disqualifiedColumns, disqualifiedColumnsSummary, expandedDetailColumns, expandedServiceColumns, expandedSuppliersColumns, suppliersColumns, totalColumns } from './components/ApprovalDetail/rfqFormColumns';
import BidSummaryList from './components/BidSummary/BidSummaryTable';
import { twMerge } from 'tailwind-merge';

function TenderDetail() {
  const [ data, setData ] = useState(null)
  const [ mainData, setMainData ] = useState(null)
  const [ loading, setLoading ] = useState(true)
  const [ sending, setSending ] = useState(false)
  const [ comment, setComment ] = useState("")
  const location = useLocation()
  const navigate = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `/TenderApproval/rfq/summary/${id}?wcode=tender-detail`
    }).then(res => {
      setMainData(res.data.responseData)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const handleSend = (approveStatus) => {
    setSending(true)
    instance({
      method: 'post',
      url: approveStatus == 1 ? `/TenderApproval/rfq/approve?wcode=tender-detail` : `/TenderApproval/rfq/reject?wcode=tender-detail`,
      data: {
        tenderId: id,
        comment: comment,
      }
    }).then(() => {
      setComment("")
      fetchData()
    }).catch(err => {

    }).then(() => {
      setSending(false)
    })
  }
  
  return (
    <div className='p-4 grid grid-cols-12 gap-3 relative'>
      <div className="col-span-12">
        <Card size="small" className='sticky top-6'>
          {
            loading ?
            <div className="px-10">
              <Skeleton active></Skeleton>
            </div>
            :
            <>
              <div className="grid grid-cols-12 divide-x divide-y border-b border-r">
                <div className='col-span-3 text-sm font-bold border-t border-l px-3'>
                  RFQ number: 
                </div>
                <div className="col-span-3 px-3">{mainData.tenderInfo?.code}</div>
                <div className="col-span-3 px-3 font-bold">
                  Ticket number (if Pnow):
                </div>
                <div className="col-span-3 px-3 flex">
                  {mainData.tenderInfo?.procurementNow}
                </div>
                <div className="col-span-3 px-3 font-bold">
                  Recommendation from Purchasing officer:: 
                </div>
                <div className="col-span-3 px-3">{mainData.tenderInfo.buyerFirstName} {mainData.tenderInfo.buyerLastName}</div>
                <div className='col-span-3 px-3 font-bold'>
                  Approver as per DFA: 
                </div>
                <div className="col-span-3 px-3">{mainData.approvalSteps.at(-1)?.firstName} {mainData.approvalSteps.at(-1)?.lastName}</div>
                <div className="col-span-3 px-3 font-bold">
                  End user name Technical evaluation completed by:
                </div>
                <div className="col-span-3 px-3 flex gap-x-2">
                  {
                    mainData.endUsers.map((foo, fooIndex) => {
                      return (<span className="" key={`end-user-${fooIndex}`}>{foo.firstName} {foo.lastName},</span>)
                    })
                  }
                </div>
                
                <div className="col-span-3 px-3 font-bold">
                  Department/Area:
                </div>
                <div className="col-span-3 px-3 flex gap-x-2">
                  {
                    mainData.endUsers.map((foo, fooIndex) => {
                      return (<span className="" key={`end-user-department-${fooIndex}`}>{foo.teamName},</span>)
                    })
                  }
                </div>
                
                <div className="col-span-3 px-3 font-bold">
                  Total value for approval:
                </div>
                <div className="col-span-3 px-3">{mainData.data?.totalAmount ? mainData.data?.totalAmount.toLocaleString("en-US") : 0}$</div>
                <div className="col-span-3 px-3 font-bold">
                  Negotiation outcome:
                </div>
                <div className="col-span-3 px-3 flex flex-col">
                  
                </div>
                <div className="col-span-3 px-3 font-bold">
                  Total items:
                </div>
                <div className="col-span-3 px-3">{mainData.totalItems.length}</div>
                <div className="col-span-3 px-3 font-bold">
                  Total cost reduction:
                </div>
                <div className="col-span-3 px-3 flex flex-col">
                  
                </div>
              </div>
            </>
          }
        </Card>
      </div>
      <div className="col-span-12">
        
        {
          loading ?
          <Skeleton active></Skeleton>
          :
          <Tabs
            tabBarStyle={{ margin: 0}}
            onChange={e => navigate(e)}
            items={[
              {
                key: 'bid',
                label: `Bid report`,
                children: <Outlet/>
              },
              {
                key: 'bid-summary',
                label: `Bid summary`,
                children: <Outlet/>
              },
              {
                key: 'approval',
                label: `Approval detail`,
                children: <Outlet/>
              },
            ]}
            type="card">
            
          </Tabs>
        }
      </div>
    </div>
  )
}

export default TenderDetail
