import { MainTable } from "components"
import { Link, useOutletContext } from "react-router-dom"

const columns = ({currentPage}) => {
  return [
    {
      title: '#',
      dataIndex: 'No',
      key: 'No',
      width: 40,
      align: 'center',
      render: (text, row, i) => (
        <span>{currentPage+i+1}</span>
      )
    },
    {
      title: 'Request ID',
      dataIndex: 'requestId',
      key: 'requestId',
      filterType: "search",
    },
    {
      title: 'Firstname',
      dataIndex: 'firstName',
      key: 'firstName',
      filterType: "search",
    },
    {
      title: 'Lastname',
      dataIndex: 'lastName',
      key: 'lastName',
      align: 'center',
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      align: 'center',
      render: (cell) => cell == "male" ? "Male" : "Female"
    },
    {
      title: 'DOB',
      dataIndex: 'dob',
      key: 'dob',
    },
    {
      title: 'Passport No',
      dataIndex: 'passportNo',
      key: 'passportNo',
      align: 'center',
    },
  ]
}

const Passengers = ({tenderData}) => {
  const [ outletData ] = useOutletContext()

  return (
    <div className="bg-white border-b border-l border-r border-slate-100">
      <MainTable
        borderHide
        dataTable={outletData.passengers}
        columns={columns({currentPage: 0})}>
        
      </MainTable>
    </div>
  )
}

export default Passengers