import React, { useState } from 'react'
import { useOutletContext } from "react-router-dom";
import { MailOutlined, LikeOutlined, DislikeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import { Skeleton, Card } from 'antd'
import moment from 'moment';

const TenderOverview = () => {
  const [ data ] = useOutletContext()
  
  if(data.loading) {
    return (
      <Skeleton active/>
    )
  }

  return (
    <div className='flex flex-col gap-6 col-span-12'>
      <div className="grid grid-cols-4 gap-3">  
        <div className="bg-white px-6 py-4 flex justify-between items-center">
          <div>
            <div className="text-[#616569]">
              Invited
            </div>
            <div className="text-3xl font-bold text-black">
              {data.stat?.statTotal}
            </div>
          </div>
          <div>
            <div className="w-[70px] flex justify-center items-center bg-[#D8F5E2] rounded-xl aspect-square">
              <MailOutlined className='text-4xl text-[#3DCB6D]'/>
            </div>
          </div>
        </div>
        <div className="bg-white px-6 py-4 flex justify-between items-center">
          <div>
            <div className="text-[#616569]">
              Submitted
            </div>
            <div className="text-3xl font-bold text-black">
              {data.stat?.statSubmitted}
            </div>
          </div>
          <div>
            <div className="w-[70px] flex justify-center items-center bg-[#FFECDA] rounded-xl aspect-square">
              <LikeOutlined className='text-4xl text-[#FF9F46]'/>
            </div>
          </div>
        </div>
        <div className="bg-white px-6 py-4 flex justify-between items-center">
          <div>
            <div className="text-[#616569]">
              Not interested
            </div>
            <div className="text-3xl font-bold text-black">
              {data.stat?.statNotInterested}
            </div>
          </div>
          <div>
            <div className="w-[70px] flex justify-center items-center bg-[#FFDADA] rounded-xl aspect-square">
              <DislikeOutlined className='text-4xl text-[#FF4648]'/>
            </div>
          </div>
        </div>
        <div className="bg-white px-6 py-4 flex justify-between items-center">
          <div>
            <div className="text-[#616569]">
              Not responded
            </div>
            <div className="text-3xl font-bold text-black">
              {data.stat?.statResponded}
            </div>
          </div>
          <div>
            <div className="w-[70px] flex justify-center items-center bg-[#FFDADA] rounded-xl aspect-square">
              <EyeInvisibleOutlined className='text-4xl text-[#FF4648]'/>
            </div>
          </div>
        </div>
      </div>
      <Card className="rounded-none" title="Info" size="small">
        <div className="grid grid-cols-12 gap-2">
          <div className="col-span-3">
            <label className="text-xs font-semibold">Start date</label>
            <div className="">{moment(data.startDate).format("YYYY-MM-DD HH")}</div>
          </div>
          <div className="col-span-3">
            <label className="text-xs font-semibold">End date</label>
            <div className="">{moment(data.endDate).format("YYYY-MM-DD HH")}</div>
          </div>
          <div className="col-span-3">
            <label className="text-xs font-semibold">Reminder date</label>
            <div className="">{data.reminderDate}</div>
          </div>
          <div className="col-span-12">
            <label className="text-xs font-semibold">Products & services</label>
            <div className="flex gap-2 flex-wrap">
              {data.services.map((foo, fooindex) => (
                <span key={`service-${fooindex}`} className="bg-gray-200 px-3 rounded">{foo.name}</span>
              ))}
            </div>
          </div>
          <div className="col-span-12">
            <label className="text-xs font-semibold">Description</label>
            <div className="">
              {data.description}
            </div>
          </div>
          <div className="col-span-12">
            <label className="text-xs font-semibold">Content</label>
            <div dangerouslySetInnerHTML={{__html: data.content}} className="h-[400px] overflow-auto shadow-inner p-2">
            </div>
          </div>
        </div>
      </Card>
      {/* {
        location.state.type == 1 ?
        <EOIDetail tenderData={data}></EOIDetail>
        :
        location.state.type == 2?
        <RFQDetail tenderData={data}></RFQDetail>
        :
        <></>
      } */}
    </div>
  )
}

export default TenderOverview