import { Button, Card, DatePicker, Dropdown, Form, Input, InputNumber, Modal, Radio, Select, Space, Table, Tag, Tooltip } from "antd"
import { Filters } from "components"
import { useEffect, useMemo, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { instance } from "utils/axios"
import { MoreOutlined, SwapOutlined } from '@ant-design/icons'
import { Link } from "react-router-dom"
import moment from "moment"

const columns = [
  {
    title: 'Test',
    dataIndex: '',
  }
]

const enums = {
  '-1': 'NotSet',
  0: 'Requested',
  1: 'Low',
  2: 'Medium',
  3: 'High',
  4: 'Critical'
}

const { Search } = Input

const DueDiligence = () => {
  const [ data, setData ] = useState([])
  const [ filter, setFilter ] = useState({})
  const [ searchValue, setSearchValue ] = useState('')
  const [ hasMore, setHasMore ] = useState(true)
  const [ form ] = Form.useForm()
  const [ editData, setEditData ] = useState(null)
  const [ open, setOpen ] = useState(false)
  const [ users, setUsers ] = useState([])
  const [ suppliers, setSuppliers ] = useState([])
  const [ submitting, setSubmitting ] = useState(false)

  useEffect(() => {
    fetchData()
  }, [filter])

  useEffect(() => {
    fetchMasters()
  }, [])

  const fetchMasters = () => {
    Promise.all([
      instance({
        method: 'get',
        url: `user`
      }),
      instance({
        method: 'get',
        url: `Supplier/find`
      })
    ]).then(res => {
      setUsers(res[0]?.data.responseData)
      setSuppliers(res[1]?.data.responseData)
    })
  }

  const fetchData = () => {
    instance({
      method: 'get',
      url: `/SupplierDueDiligence`
    }).then(res => {
      setData(res.data.responseData)
    }).catch(() => {
      
    }).then(() => {
      setHasMore(false)
    })
  }

  const fetchNext = () => {
    setHasMore(false)
  }
  
  const onSearch = (values) => {
    setFilter(values)
  }

  const filteredData = useMemo(() => {
    return data.filter(foo => foo.name.toLowerCase().includes(searchValue.toLowerCase()))
  }, [data, searchValue])

  const handleAdd = () => {
    setOpen(true)
  }

  const handleFinish = (values) => {
    setSubmitting(true)
    if(editData) {
      instance({
        method: 'put',
        url: `/SupplierDueDiligence?wcode=coi`,
        data: {
          ...editData,
          ...values
        }
      }).then(() => {
        setOpen(false)
        fetchData()
      }).catch(() => {

      }).then(() => {
        setSubmitting(false)
      })
    }
    else {
      instance({
        method: 'post',
        url: `/SupplierDueDiligence?wcode=coi`,
        data: values
      }).then(() => {
        setOpen(false)
        fetchData()
      }).catch(() => {

      }).then(() => {
        setSubmitting(false)
      })
    }
  }

  return (
    <div className="p-4">
      <div className="mb-4">
        <Filters wcode="coi" onSearch={onSearch}/>
      </div>
      <Card size="small">
        <div className="mb-3 font-semibold flex gap-3 items-center justify-between">
          <div className="shrink-0">
            {filteredData.length} of {data.length} Results
          </div>
          <Search
            placeholder="Search" 
            className='border-none' 
            onSearch={onSearch} 
            allowClear
            enterButton 
            onChange={e => setSearchValue(e.target.value)} />
          <div className='flex gap-2'>
            <Button onClick={handleAdd} type="primary">
              Add new 
            </Button>
          </div>
        </div>
        <InfiniteScroll
          dataLength={data.length}
          next={fetchNext}
          loader={<div className='text-center font-bold'>Fetching...</div>}
          className="flex flex-col gap-1 overflow-x-hidden"
          hasMore={hasMore}
          endMessage={
            <p className="text-center font-bold">
              {/* No more tender data */}
            </p>
          }>
          <table className="oyu-table">
            <colgroup>
              <col style={{width: '50px'}}></col>
              <col style={{width: '50px'}}></col>
              <col></col>
              <col style={{width: '250px'}}></col>
              <col style={{}}></col>
              <col style={{width: '100px'}}></col>
              <col style={{width: '50px'}}></col>
            </colgroup>
            <thead>
              <tr>
                <th style={{textAlign: 'left'}}>#</th>
                <th style={{textAlign: 'left'}}>Code</th>
                <th style={{textAlign: 'left'}}>Name</th>
                <th>Sap ID</th>
                <th style={{textAlign: 'left'}}>Status</th>
                <th>Expire date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                filteredData.map((foo, fooIndex) => {
                  return (
                    <tr key={`tender-${fooIndex}`} className="hover:bg-gray-100 transition-all">
                      <td>
                        {fooIndex+1}
                      </td>
                      <td>{foo.id}</td>
                      <td>
                        {foo.name}
                      </td>
                      <td style={{textAlign: 'center'}}>{foo.vendorNumber}</td>
                      <td><Tag color={foo.dueDiligenceStatus == 0 ? 'yellow': foo.dueDiligenceStatus == 1 ? 'green' : foo.dueDiligenceStatus == 2 ? 'orange' : foo.dueDiligenceStatus == 3 ? "red" : 'yellow'}>{enums[foo.dueDiligenceStatus]}</Tag></td>
                      <td className="text-center text-nowrap">{foo.ddExpireDate && moment(foo.ddExpireDate).format("YYYY/MM/DD HH:mm")}</td>
                      <td>
                        <Dropdown 
                          trigger={['click']} 
                          menu={{items: []}}>
                          <a onClick={e => e.preventDefault()} className="flex justify-center items-center">
                            <Space className="px-3">
                              <MoreOutlined className='text-xl'/>
                            </Space>
                          </a>
                        </Dropdown>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>

        </InfiniteScroll>
      </Card>
      <Modal
        loading={submitting}
        width="50vw"
        title={editData ? 'Edit' : 'Add new'}
        open={open}
        onOk={form.submit}
        onCancel={() => {
          setEditData(null)
          setOpen(false)
        }}>
        <Form
          initialValues={{
            toggle: 1
          }}
          layout="vertical"
          form={form}
          onFinish={handleFinish}>
          <Form.Item name="supplierId" label="Supplier" rules={[{required: true}]}>
            <Select placeholder="Select user" options={suppliers.map(foo => ({value: foo.id, label: `${foo.name} `}))}></Select>
          </Form.Item>
          <Form.Item name="expireDate" label="Expire date">
            <DatePicker className="w-full"></DatePicker>
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea placeholder="Enter description"/>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default DueDiligence