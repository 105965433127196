import { Button, Card, Dropdown, Input, Modal, Table } from "antd"
import { useEffect, useMemo, useState } from "react"
import { useOutletContext, useParams } from "react-router-dom"
import { instance } from "utils/axios"
import { FilterOutlined } from '@ant-design/icons'
import columns from "./columns"

const FreightBidSummary = () => {
  const [ data, setData ] = useState({
    detail: [],
    suppliers: []
  })
  const [ loading, setLoading ] = useState(true)
  const [ selectedRow, setSelectedRow ] = useState(null)
  const [ selectedRowKeys, setSelectedRowKeys ] = useState([])
  const [ approvalModalOpen, setApprovalModalOpen ] = useState(false)
  const [ comment, setComment ] = useState("")
  const [ awarding, setAwarding ] = useState(false)
  const { id } = useParams()
  const [ outletContext ] = useOutletContext()
  const [ sending, setSending ] = useState(false)
  const [ modal, contextHolder ] = Modal.useModal();
  const filterItems = [
    {
      key: 0,
      label: (
        <a onClick={(e) => {
          e.preventDefault()
          findLowestPrice()
        }}>
          Lowest price
        </a>
      )
    },
    {
      key: 1,
      label: (
        <a onClick={(e) => {
          e.preventDefault()
          findLowestDays()
        }}>
          Lowest days
        </a>
      )
    },
  ]


  useEffect(() => {
    instance({
      method: 'get',
      url: `/Tender/freight/bidsummary/${id}`
    }).then(res => {
      if(res.status != 204) {
        setData(res.data.responseData)
      }
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }, [])

  const findLowestDays = () => {

  }

  const findLowestPrice = () => {

  }

  const fixData = useMemo(() => {
    let tmp = []

    data.suppliers.map((foo, fooIndex) => {
      foo.details.map(_detail => {
        let _detailFound = data.detail.find(foo => foo.id == _detail.detailId)
        tmp.push({
          ..._detail,
          status: foo.status,
          name: foo.name,
          awardStatus: foo.awardStatus,
          srnNumber: _detailFound?.srnNumber,
          transportMode: _detailFound?.transportMode,
          transportType: _detailFound?.transportType,
          rowSpan: foo.details.length,
        })
      })
    })

    return tmp
  }, [data])

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (_selectedRowKeys, selectedRows) => {
      setSelectedRow(selectedRows[0])
      setSelectedRowKeys(_selectedRowKeys)
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const handleAward = () => {
    setAwarding(true)

    instance({
      method: 'post',
      url: `/Tender/freight/award`,
      data: {        
        tenderId: id,
        supplierId: selectedRow.supplierId,
        tenderFreightDetailId: selectedRow.id,
      }
    }).then(res => {
      setSelectedRowKeys([])
      setSelectedRow(null)
      outletContext.fetchData()
    }).catch(err => {

    }).then(() => {
      setAwarding(false)
    })
  }

  const handleApprove = () => {
    setSending(true)

    instance({
      method: 'post',
      url: `/Tender/freight/sendtoapproval`,
      data: {        
        id: id,
        supplierId: selectedRow.supplierId,
        comment: comment,
        supplierDetailId: selectedRow.id
      }
    }).then(res => {
      setSelectedRowKeys([])
      setSelectedRow(null)
      setComment("")
      setApprovalModalOpen(false)
      outletContext.fetchData()
    }).catch(err => {

    }).then(() => {
      setSending(false)
    })
  }
  
  return (
    <div className="flex flex-col gap-3">
      <Card className="rounded-none" bodyStyle={{padding: 0}}>
        <div className="flex justify-between gap-2 px-4 py-2">
          <div className="flex gap-3">
            <Button type="primary" disabled={!selectedRow} onClick={() => setApprovalModalOpen(true)} loading={awarding}>Send for approval</Button>
            <Button type="primary" disabled={selectedRowKeys.length == 0 && outletContext.status != 9} onClick={handleAward} loading={awarding}>Award</Button>
          </div>
          <div className="flex items-center gap-2">
            {/* <Dropdown menu={{items: filterItems}}>
              <Button icon={<FilterOutlined className="align-middle"/>} type="primary">Filter</Button>
            </Dropdown> */}
            {/* <Button icon={<FilterOutlined className="align-middle"/>} type="primary" onClick={() => findLowestPrice()}>Lowest price</Button>
            <Button icon={<FilterOutlined className="align-middle"/>} type="primary" onClick={() => findLowestDays()}>Lowest days</Button>
            <Button icon={<FilterOutlined className="align-middle"/>} type="primary">Most items</Button> */}
          </div>
        </div>
        <Table
          rowSelection={{
            type: 'radio',
            ...rowSelection,
          }}
          onRow={(record, index) => {
            return {
              style: {
                background: record.status == 9 ? '#bdffbd' : record.isSelected == 1 ? 'rgb(253, 208, 167)' : ''
              }
            }
          }}
          rowKey={row => row.id}
          pagination={false}
          size="small"
          dataSource={fixData}
          columns={columns()}>

        </Table>
      </Card>
      <Modal
        width={800}
        title={`Send for approval`}
        open={approvalModalOpen}
        onCancel={() => {
          setComment('')
          setApprovalModalOpen(false)
        }}
        okText="Send"
        confirmLoading={sending}
        onOk={handleApprove}>
        <div>
          <label className="text-xs text-mute">Comment:</label>
          <Input.TextArea onChange={e => setComment(e.target.value)} value={comment}>

          </Input.TextArea>
        </div>
      </Modal>
      {contextHolder}
    </div>
  )
}

export default FreightBidSummary