
const totalColumns = [
  {
    title: 'Number of Suppliers Participation',
    dataIndex: 'title'
  },
  {
    title: 'Suppliers',
    dataIndex: 'title',
    width: 200,
    render: (cell, row) => row.data.length
  },
  {
    title: 'Comment',
    dataIndex: 'description',
    editable: true,
    width: 500
  },
]

const suppliersColumns = (selected) => {
  return [
    {
      title: 'Supplier',
      dataIndex: 'name',
      editable: false,
    },
    {
      title: 'Items',
      dataIndex: 'materialNumber',
      editable: false,
      width: 100,
      render: (cell, row) => {
        let count = 0 ;
  
        count += row.details.length
        
        return count
      }
    },
    {
      title: 'Value',
      dataIndex: 'value',
      editable: false,
      width: 100,
      render: (cell, row) => {
        let count = 0 ;
  
        count += row.details.reduce((a,b) => a+b.totalPrice, 0)
        
        return count.toLocaleString("en-US")
      }
    },
    {
      title: 'Comment',
      dataIndex: 'description',
      editable: true,
      width: 500
    },
  ]
}


const disqualifiedColumns = [
  {
    title: '#',
    width: 50,
    render: (cell, row, index) => index+1
  },
  {
    title: 'PR Number',
    dataIndex: 'prNumber'
  },
  {
    title: 'Material Number',
    dataIndex: 'materialNumber'
  },
  {
    title: 'Manufacturer',
    dataIndex: 'manufacturer'
  },
  {
    title: 'Part number',
    dataIndex: 'partNumber'
  },
  {
    title: 'UOM',
    dataIndex: 'uom'
  },
  {
    title: 'Quantity',
    dataIndex: 'qty'
  },
  {
    title: 'Comment',
    dataIndex: 'description',
    editable: true,
    type: 'select',
    options: [
      {
        label: 'Excluded if 35% higher or 50% cheaper than historical average price',
        value: 'Excluded if 35% higher or 50% cheaper than historical average price'
      },
      {
        label: 'Excluded if single source bid with no historical purchase',
        value: 'Excluded if single source bid with no historical purchase'
      },
      {
        label: 'Excluded if no quote received',
        value: 'Excluded if no quote received'
      },
      {
        label: 'Excluded if quote difference is unreasonably high',
        value: 'Excluded if quote difference is unreasonably high'
      },
      {
        label: 'Excluded if high value and single quote received',
        value: 'Excluded if high value and single quote received'
      },
    ],
    width: 500
  },
]

const expandedDetailColumns = [
  {
    title: 'Name',
    dataIndex: 'name'
  },
  {
    title: 'Unit price',
    dataIndex: 'unitPrice',
    render: foo => foo.toLocaleString("en-US")
  },
  {
    title: 'Quantity',
    dataIndex: 'shipQty'
  },
  {
    title: 'Shipping days',
    dataIndex: 'shipDays'
  },
  {
    title: 'Total price',
    dataIndex: 'totalPrice',
    render: foo => foo.toLocaleString("en-US")
  },
  {
    title: 'Suggested Manufacturer',
    dataIndex: 'isSuggestedManufacturer'
  },
  {
    title: 'Suggested Manufacturer Part Number',
    dataIndex: 'suggestedManufacturerPartNumber'
  },
]

const expandedSuppliersColumns = [
  {
    title: 'Name',
    dataIndex: 'supplierName'
  },
  {
    title: 'ID',
    dataIndex: 'supplierId'
  },
  {
    title: 'SAP ID',
    dataIndex: 'vendorNumber'
  },
]

const disqualifiedColumnsSummary = [
  {
    title: '#',
    width: 50,
    render: (cell, row, index) => index+1
  },
  {
    title: 'Dis-qualified items',
    dataIndex: 'title'
  },
  {
    title: 'Total',
    dataIndex: 'items'
  },
  {
    title: 'Comment',
    render: () => `Disqualified as per agreed criteria`
  },
]



export { suppliersColumns, disqualifiedColumns, totalColumns, expandedDetailColumns, expandedSuppliersColumns, disqualifiedColumnsSummary }