import { Card, Table } from "antd"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { instance } from "utils/axios"

const ShortList = () => {
  const [ data, setData ] = useState([])
  const { id } = useParams()
  
  useEffect(() => {
    instance({
      method: 'get',
      url: `/Tender/eoi/bidderlist/${id}`
    }).then(res => {
      setData(res.data.responseData.suppliers.filter(foo => foo.awardStatus == 1))
    })
  }, [])
  
  return (
    <Card title="Short list" bodyStyle={{padding: 0}} size="small">
      <Table
        pagination={false}
        size="small"
        dataSource={data}
        columns={[
          {
            dataIndex: 'name',
            title: 'Name'
          },
          {
            title: 'Email',
            dataIndex: 'companyEmail',
            width: 150
          },
          {
            dataIndex: 'regNo',
            title: 'Register number',
            width: 150
          },
          {
            dataIndex: 'documents',
            title: 'Attachments',
            render: (cell) => <div></div>
          }
        ]}>
        
      </Table>
    </Card>
  )
}

export default ShortList