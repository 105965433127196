
const totalColumns = [
  {
    title: 'Number of Suppliers Participation',
    dataIndex: 'title',
  },
  {
    title: 'Suppliers',
    dataIndex: 'title',
    render: (cell, row) => row.data.length,
    width: 200
  },
  {
    title: 'Comment',
    dataIndex: 'description',
    editable: true,
    width: 500
  },
]

const suppliersColumns = () => {
  return [
    {
      title: 'Supplier',
      dataIndex: 'name',
      editable: false,
    },
    {
      title: 'Items',
      dataIndex: 'materialNumber',
      editable: false,
      width: 100,
      render: (cell, row) => {
        let count = 0 ;
  
        count += row.details.length
        
        return count
      }
    },
    {
      title: 'Value',
      dataIndex: 'value',
      editable: false,
      width: 100,
      render: (cell, row) => {
        let count = 0 ;
  
        count += row.details.reduce((a,b) => a+b.totalPrice, 0)
        
        return count.toLocaleString("en-US")
      }
    },
    {
      title: 'Comment',
      dataIndex: 'description',
      width: 500
    },
  ]
}


const disqualifiedColumns = [
  {
    title: '#',
    width: 50,
    render: (cell, row, index) => index+1
  },
  {
    title: 'PR Number',
    dataIndex: 'prNumber',
    width: 120
  },
  {
    title: 'Material Number',
    dataIndex: 'materialNumber',
    width: 120
  },
  {
    title: 'Material Description',
    dataIndex: 'description',
    width: 500
  },
  {
    title: 'Manufacturer',
    dataIndex: 'manufacturer',
    width: 120
  },
  {
    title: 'Part number',
    dataIndex: 'partNumber',
    width: 120
  },
  {
    title: 'UOM',
    dataIndex: 'uom',
    width: 120
  },
  {
    title: 'Quantity',
    dataIndex: 'qty',
    width: 120
  },
  {
    title: 'Justification',
    dataIndex: 'comment',
    editable: true,
    width: 350
  },
]

const expandedDetailColumns = [
  {
    title: <span className="text-nowrap">Material #</span>,
    dataIndex: 'materialNumber',
    width: 90,
    fixed: 'left'
  },
  {
    title: 'Description',
    dataIndex: 'description',
    width: 250
  },
  {
    title: 'UOM',
    dataIndex: 'uom',
    width: 70
  },
  {
    title: 'Criticality',
    dataIndex: 'criticality',
    width: 100
  },
  {
    title: 'Average price',
    dataIndex: 'avgPrice',
    width: 120
  },
  {
    title: 'Last price',
    dataIndex: 'lastPrice',
    width: 120
  },
  {
    title: 'Quantity',
    dataIndex: 'shipQty',
    width: 120
  },
  {
    title: 'Total price',
    dataIndex: 'totalPrice',
    render: foo => foo.toLocaleString("en-US"),
    width: 120
  },
  {
    title: 'Shipping days',
    dataIndex: 'shipDays',
    width: 120
  },
  {
    title: 'Unit price',
    dataIndex: 'unitPrice',
    render: foo => foo.toLocaleString("en-US"),
    width: 120
  },
  {
    title: 'Suggested Manufacturer',
    dataIndex: 'isSuggestedManufacturer',
    width: 120,
    render: cell => cell == 0 ? "No" : "Yes"
  },
  {
    title: 'Suggested Manufacturer Part Number',
    dataIndex: 'suggestedManufacturerPartNumber',
    width: 120
  },
  {
    title: 'Justification',
    dataIndex: 'comment',
    width: 350
  },
]

const expandedServiceColumns = [
  {
    title: 'Detail',
    dataIndex: 'content',
    render: (cell, row) => {
      return (
        <div dangerouslySetInnerHTML={{__html: cell}}></div>
      )
    },
  },
  {
    title: 'Total price',
    dataIndex: 'totalPrice',
    render: (cell, row) => {
      return (
        <span>{cell.toLocaleString("en-US")}</span>
      )
    },
  },
]

const expandedSuppliersColumns = [
  {
    title: 'Name',
    dataIndex: 'supplierName'
  },
  {
    title: 'ID',
    dataIndex: 'supplierId'
  },
  {
    title: 'SAP ID',
    dataIndex: 'vendorNumber'
  },
]

const disqualifiedColumnsSummary = [
  {
    title: '#',
    width: 50,
    render: (cell, row, index) => index+1
  },
  {
    title: 'Dis-qualified items',
    dataIndex: 'title',
    width: 500
  },
  {
    title: 'Items',
    dataIndex: 'items',
    width: 200
  },
  {
    title: 'Justification',
    dataIndex: 'foo',
    width: 500,
    render: () => `Disqualified as per agreed criteria`
  },
]



export { suppliersColumns, disqualifiedColumns, totalColumns, expandedServiceColumns, expandedDetailColumns, expandedSuppliersColumns, disqualifiedColumnsSummary }