import { Card, Popover, Select, Skeleton, Spin, Steps } from "antd"
import { useEffect, useState } from "react"
import { useOutletContext, useParams } from "react-router-dom"
import { twMerge } from "tailwind-merge";
import { instance } from "utils/axios"
import ApprovalHistoryDetail from "./ApprovalHistoryDetail";

var special = ['zeroth','First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh', 'Eighth', 'Ninth', 'Tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];
var deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

const customDot = ({dot, status, index, currentApprovers}) => (
  <Popover
    content={
      <span >
        Approver #{index+1} status: <span className={twMerge('font-semibold', currentApprovers[index].status == 1 ? 'text-green-500' : currentApprovers[index].status == 0 ? 'text-red-500' : 'text-yellow-500')}>{currentApprovers[index].status == 1 ? 'Approved' : currentApprovers[index].status == 0 ? 'Rejected' : status}</span>
      </span>
    }
  >
    {dot}
  </Popover>
);


function stringifyNumber(n) {
  if (n < 20) return special[n];
  if (n%10 === 0) return deca[Math.floor(n/10)-2] + 'ieth';
  return deca[Math.floor(n/10)-2] + 'y-' + special[n%10];
}

const ApprovalHistory = () => {
  const [ loading, setLoading ] = useState(true)
  const [ historyData, setHistoryData ] = useState(null)
  const [ approvers, setApprovers ] = useState([])
  const [ currentApprovers, setCurrentApprovers ] = useState([])
  const [ data ] = useOutletContext()
  const { id } = useParams()

  useEffect(() => {
    instance({
      method: 'get',
      url: `/Tender/rfq/approvalstatus/${id}?wcode=tender-detail`
    }).then(res => {
      let tmp = []
      res.data.responseData.map(foo => {
        if(tmp[foo.seq-1]) {
          tmp[foo.seq-1].push(foo)
        }
        else {
          tmp[foo.seq-1] = [foo]
        }
      })
      setApprovers(tmp)
      setCurrentApprovers(tmp.at(-1) ?? [])
    })
  }, [])

  useEffect(() => {
    setLoading(true)
    instance({
      method: 'get',
      url: `/TenderApproval/rfq/history/${id}`,
      params: {
        seq: currentApprovers[0]?.seq,
        wcode: 'tender-detail'
      }
    }).then(res => {
      console.log(res.data.responseData)
      setHistoryData(res.data.responseData)
    }).finally(() => {
      setLoading(false)
    })
  }, [currentApprovers])

  const calcStatus = () => {
    if(currentApprovers.find(foo => foo.status == 0)) {
      return 'error'
    }
    else if(currentApprovers.every(foo => foo.status == 1)) {
      return "finish"
    }
    else {
      return "processing"
    }
  }

  const findCurrent = () => {
    if(currentApprovers.find(foo => foo.status == 0)) {
      return currentApprovers.findIndex(foo => foo.status == 0)
    }
    else if(currentApprovers.every(foo => foo.status == 1)) {
      return currentApprovers.length-1
    }
    else {
      return currentApprovers.findIndex(foo => foo.isCurrent == 1)
    }
  }
  
  return (
    <div className="flex flex-col gap-4 relative">
      {
        currentApprovers?.length > 0 &&
        <Card title={
          <div className="flex justify-between">
            <span>
              Approvers
            </span>
            <Select size='small' onChange={e => setCurrentApprovers(approvers[e])} className="w-[200px]" value={approvers.findIndex(foo => foo == currentApprovers)}>
              {
                approvers.map((foo, fooindex) => {
                  return (
                  <Select.Option key={`approval-version_${fooindex}`} value={fooindex} className="capitalize">{fooindex+1 == approvers.length ? 'Last' : stringifyNumber(fooindex+1)} approval</Select.Option>
                  )
                })
              }
            </Select>
          </div>
        } size="small">
          {
            loading ?
            <Skeleton active>

            </Skeleton>
            :
            <Steps 
              status={calcStatus()}
              progressDot={(dot, {status, index}) => customDot({dot, status, index, currentApprovers})}
              current={findCurrent()}
              items={currentApprovers.map((foo) => ({title: `${foo.firstName} ${foo.lastName}`}))}
              labelPlacement='vertical'
              size='small'>

            </Steps>
          }
        </Card>
      }
      {
        historyData && !loading &&
        <ApprovalHistoryDetail mainData={historyData}></ApprovalHistoryDetail>
      }
    </div>
  )
}

export default ApprovalHistory