import React, { useEffect, useState } from 'react'
import { Button, Modal, Form as AntForm, Dropdown, Space, Tree, Card } from 'antd'
import { EditOutlined, PlusCircleOutlined, MoreOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import { Form, MainTable } from 'components'
import { instance } from 'utils/axios'
import { Link, useLocation } from 'react-router-dom'


const items = ({row, handleEdit, handleDelete}) => {  
  return (
    [
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleEdit(row)
        }}>Edit</a>,
        key: 0
      },
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleDelete(row.id)
        }}>Delete</a>,
        key: 1
      },
    ]
  )
}

const fields = ({options}) => {
  return [
    {
      label: 'Code',
      name: 'code',
      rules: [{required: true, message: 'Code is required!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Name',
      name: 'name',
      rules: [{required: true, message: 'Name is required!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    }
  ]
}

function ProductType() {
  const [ data, setData ] = useState([])
  const [ currentPage, setCurrentPage ] = useState(0)
  const [ open, setOpen ] = useState(false)
  const [ editData, setEditData ] = useState(null)
  const [ isEdit, setIsEdit ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ modal, contextHolder ] = Modal.useModal();
  const [ form ] = AntForm.useForm();
  const [ selectedParentId, setSelectedParentId ] = useState(null)
  const location = useLocation()

  useEffect(() => {
    fetchData()
  },[location])

  const fetchData = () => {
    setLoading(true)
    instance({
      method: 'get',
      url: `/ProductType?wcode=product-type-list`
    }).then((res) => {
      setData(res.data.responseData)
    }).catch((err) => {
    }).then(() => setLoading(false))
  }

  const columns = [
    {
      title: '#',
      dataIndex: 'No',
      key: 'No',
      width: 40,
      align: 'center',
      render: (text, row, i) => (
        <span>{currentPage+i+1}</span>
      )
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      filterType: "search",
      width: 120
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      filterType: "search",
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 80,
      render: (text, row) => (
        <Dropdown 
          trigger={['click']} 
          menu={{items: items({row, handleEdit, handleDelete}),}}>
          <a onClick={e => e.preventDefault()}>
            <Space>
              <MoreOutlined className='text-xl'/>
            </Space>
          </a>
        </Dropdown>
      )
    },
  ]

  const handleDelete = (id) => {
    modal.confirm({
      title: 'Warning!',
      content: 'You are about to delete this row of data',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: 'delete',
            url: `/ProductType/${id}?wcode=product-type-list`
          }).then((res) => {
            resolve()
          }).catch((err) => {
            reject()
          }).then(() => fetchData())
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

  const handleSubmit = (values) => {
    setLoading(true)
    if(editData){
      instance({
        method: 'put',
        url: '/ProductType?wcode=product-type-list',
        data: {
          ...values,
          id: editData.id,
        }
      }).then((res) => {
        handleCloseModal()
        fetchData()
      }).catch((err) => {

      }).then(() => setLoading(false))
    }
    else{
      instance({
        method: 'post',
        url: '/ProductType?wcode=product-type-list',
        data: {
          ...values,
          parentId: selectedParentId ? selectedParentId : null
        }
      }).then((res) => {
        handleCloseModal()
        fetchData()
      }).catch((err) => {

      }).then(() => setLoading(false))
    }
  }

  const handleAdd = () => {
    setIsEdit(true)
    setEditData(null)
    setOpen(true)
  }

  const handleEdit = (row) => {
    setEditData(row)
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
    form.resetFields()
  }

  const fixData = (data, depth) => {
    let tmp = []

    data.map((foo, fooIndex) => {
      tmp.push({
        title: (
          <div className="flex items-center gap-6 group">
            {`${foo.item?.name} /${foo.item?.code}/`} 
            <div className="flex gap-3">
              <Button className="opacity-0 group-hover:opacity-100 transition-all" size='small' type="text" icon={<PlusOutlined />} onClick={() => {
                setSelectedParentId(foo.item.id)
                setEditData(null)
                setOpen(true)
              }}>
                
              </Button>
              <Button className="opacity-0 group-hover:opacity-100 transition-all" size='small' type="text" icon={<EditOutlined />} onClick={() => {
                setEditData(foo.item)
                setOpen(true)
              }}>

              </Button>
              <Button className="opacity-0 group-hover:opacity-100 transition-all" size='small' type="text" danger icon={<DeleteOutlined />} onClick={() => {
                handleDelete(foo.item.id)
              }}>
                
              </Button>
            </div>
          </div>
        ),
        key: `${depth}-${foo.item.id}`,
        children: foo.children ? fixData(foo.children, depth+1) : []
      })
    })
    
    return tmp
  }
  
  return (
    <Card className="m-3" size='small' title="Product SECT codes">
      <Tree
        showLine={true}
        showIcon={true}
        treeData={[
          {
            title: (
              <div className="flex items-center gap-6">
                All 
                <div className="flex gap-3">
                  <Button size='small' type="text" icon={<PlusOutlined />} onClick={() => {
                    setSelectedParentId(null)
                    setEditData(null)
                    setOpen(true)
                  }}></Button>
                </div>
              </div>
            ),
            key: 'all',
            children: fixData(data, 0)
          }
        ]}>

      </Tree>
      {/* <MainTable
        dataTable={data}
        columns={columns}
        rowKey={cell => cell.item.id}
        setCurrentPage={setCurrentPage}
        loading={loading}
        pagination={false}
        title="Product type"
        handleAdd={handleAdd}
        expandable={{
          expandedRowRender: (record) => {
            if(record.children) {
              return (
                <MainTable
                  dataTable={record.children}
                  columns={columns}
                  rowKey={cell => cell.item.id}>

                </MainTable>
              )
            }
          }
        }}
      /> */}
      <Modal
        title={editData ? <div className='flex items-center justify-start gap-2'>Edit</div> : <div className='flex items-center justify-start gap-2'>Add</div>}
        open={open}
        width="40%"
        destroyOnClose
        maskClosable={false}
        okText='Save'
        onOk={form.submit}
        onCancel={handleCloseModal}
        footer={
          <div className="flex gap-3">
            <Button onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button type="primary" onClick={form.submit}>
              Save
            </Button>
          </div>
        }
      >
        <Form
          form={form}
          fields={fields({options: data?  data:  []})}
          length={data.length}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          isEdit={isEdit}
          className='gap-4'
        />
      </Modal>
      {contextHolder}
    </Card>
  )
}

export default ProductType
