import React, { useEffect, useState } from 'react'
import { Button, Tooltip, Skeleton, Divider, Table, Card, Steps, Input, Collapse, Tree } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { useNavigate, Outlet } from 'react-router-dom';
import { instance } from 'utils/axios';
import { useParams, Link, useLocation } from "react-router-dom";
import { BsClipboardCheck, BsJournalBookmark, BsPeople, BsViewStacked, BsBoxes, BsChatQuote, BsPencilSquare, BsQuestionSquare  } from "react-icons/bs";
import { disqualifiedColumns, disqualifiedColumnsSummary, expandedDetailColumns, expandedServiceColumns, expandedSuppliersColumns, suppliersColumns, totalColumns } from './rfqFormColumns';
import BidSummaryTable from './BidSummaryTable';
import BidSummaryService from './ServiceSummary';
import LowerPriceSuppliers from './LowestPriceSuppliers';
import OtherRecommended from './OtherRecommended';
import DisqualifiedSuppliers from './DisqualifiedSuppliers';
import DisqualifiedItems from './DisqualifiedItems';

function BidSummaryList() {
  const [ data, setData ] = useState(null)
  const [ mainData, setMainData ] = useState(null)
  const [ loading, setLoading ] = useState(true)
  const [ sending, setSending ] = useState(false)
  const [ comment, setComment ] = useState("")
  const location = useLocation()
  const navigate = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `/TenderApproval/rfq/summary/${id}?wcode=tender-detail`
    }).then(res => {
      setMainData(res.data.responseData)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const handleSend = (approveStatus) => {
    setSending(true)
    instance({
      method: 'post',
      url: approveStatus == 1 ? `/TenderApproval/rfq/approve?wcode=tender-detail` : `/TenderApproval/rfq/reject?wcode=tender-detail`,
      data: {
        tenderId: id,
        comment: comment,
      }
    }).then(() => {
      setComment("")
      fetchData()
    }).catch(err => {

    }).then(() => {
      setSending(false)
    })
  }
  
  return (
    <div className='grid grid-cols-12 gap-3 relative'>
      {
        loading ? 
        <div className='col-span-12'>
          <Skeleton active/>
        </div>
        :
        <div className="grid grid-cols-12 col-span-12">
          <Card className="col-span-12" size="small" styles={{borderTopLeftRadius: 0}}>
            <div className="flex flex-col gap-8">
              <div>
                <div className='font-bold'>
                  Short background:
                </div>
                <div className="border-slate-200 border p-4 rounded">
                  {mainData.data.shortReport}
                </div>
              </div>
              <div>
                <div className='font-bold'>
                  Recommendation:
                </div>
                <div className="border-slate-200 border p-4 rounded" dangerouslySetInnerHTML={{__html: mainData.data.summaryReport}}/>
              </div>
              {
                mainData.rfqType == 1 &&
                <div>
                  <Tree treeData={[{
                    title: `Total supplier SECT percentage: ${(mainData.products.total*100/mainData.total.data.length).toFixed(2)}% (${mainData.products.total+`/`+mainData.total.data.length})`, 
                    key: `0-0`, 
                    children: mainData?.products?.productDetail.length > 0 ? mainData.products?.productDetail?.map((foo, fooIndex) => ({title: `${foo.name} (${foo.total})`, key: foo.id, })) : []
                    }]}>

                  </Tree>
                </div>
              }
              <Table
                className="border border-gray-300 rounded overflow-hidden darker-header"
                columns={totalColumns}
                dataSource={[
                  {
                    title: 'Total Suppliers invited to RFQ',
                    data: mainData.total.data,
                    description: mainData.total.commentText
                  },
                  {
                    title: 'Not participated',
                    data: mainData.statNotResponded.data,
                    description: mainData.statNotResponded.commentText
                  },
                  {
                    title: 'Responded suppliers to RFQ',
                    data: mainData.statSubmitted.data,
                    description: mainData.statSubmitted.commentText
                  },
                ]}
                pagination={false}
                size="small"
                rowKey={(row, index) => {
                  return index
                }}
                expandable={{
                  expandedRowRender: rec => {
                    // console.log(rec.data)
                    return (
                      <div>
                        <Table
                          columns={expandedSuppliersColumns}
                          dataSource={rec.data}
                          pagination={false}
                          size="small">

                        </Table>
                      </div>
                    )
                  }
                }}>

              </Table>
              <LowerPriceSuppliers data={mainData}>
                
              </LowerPriceSuppliers>
              <OtherRecommended data={mainData}>

              </OtherRecommended>
              <DisqualifiedSuppliers data={mainData}>

              </DisqualifiedSuppliers>
              {
                mainData.rfqType == 1 &&
                <DisqualifiedItems data={mainData}></DisqualifiedItems>
              }
              {/* {
                mainData.rfqType != 2 ?
                mainData.suppliers.length > 0 &&
                <Table
                  scroll={{
                    x: (window.innerWidth-280-32)/12*9-32
                  }}
                  columns={suppliersColumns()}
                  dataSource={mainData.suppliers}
                  pagination={false}
                  rowKey={(row, index) => {
                    return row.id
                  }}
                  expandable={{
                    expandedRowRender: rec => {
                      return (
                        <div>
                          <Table
                            scroll={{
                              x: (window.innerWidth-280-32)/12*9-200
                            }}
                            columns={expandedDetailColumns}
                            dataSource={rec.details}
                            pagination={false}
                            size="small">

                          </Table>
                        </div>
                      )
                    }
                  }}
                  size="small">
                  
                </Table>
                :
                mainData.serviceSuppliers.length > 0 &&
                <Table
                  columns={suppliersColumns()}
                  dataSource={mainData.serviceSuppliers}
                  pagination={false}
                  rowKey={(row, index) => {
                    return row.id
                  }}
                  expandable={{
                    expandedRowRender: rec => {
                      return (
                        <div>
                          <Table
                            columns={expandedServiceColumns}
                            dataSource={rec.details}
                            pagination={false}
                            size="small">

                          </Table>
                        </div>
                      )
                    }
                  }}
                  size="small">
                  
                </Table>
              }
              {
                mainData.disqualified.length > 0 &&
                <Table
                  scroll={{
                    x: (window.innerHeight/2),
                  }}
                  columns={disqualifiedColumnsSummary}
                  dataSource={Object.keys(Object.groupBy(mainData.disqualified, ({comment}) => comment)).map((foo,fooIndex) => {
                    return ({
                      title: foo,
                      items: Object.groupBy(mainData.disqualified, ({comment}) => comment)[foo].length,
                      data: Object.groupBy(mainData.disqualified, ({comment}) => comment)[foo],
                      key: fooIndex
                    })
                  })}
                  expandable={{
                    expandedRowRender: rec => {
                      return (
                        <div>
                          <Table
                            scroll={{
                              y:500
                            }}
                            columns={disqualifiedColumns}
                            dataSource={rec.data}
                            pagination={false}
                            size="small">

                          </Table>
                        </div>
                      )
                    }
                  }}
                  pagination={false}
                  size="small">

                </Table>
              } */}
            </div>
          </Card>
        </div>
      }
    </div>
  )
}

export default BidSummaryList
