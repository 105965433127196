import { Table } from "antd"
import { useMemo } from "react"

const LowerPriceSuppliers = ({data}) => {
  // console.log(data)
  const fixedData = useMemo(() => {
    let tmp = []

    const lowestList = data.rfqType == 1 ? data.qualifiedItems.filter(foo => foo.commentText == 'Recommended because price is the lowest') : data.qualifiedServices.filter(foo => foo.commentText == 'Recommended because price is the lowest')
    
    const groupedData = Object.groupBy(lowestList, ({supplierId}) => supplierId)

    Object.keys(groupedData).map(foo => {
      tmp.push({
        ...data.suppliers.find(_tmp => _tmp.id == foo),
        details: groupedData[foo]
      })
    })
    
    return tmp;
  }, [data])
  
  return (
    <div>
      <Table
        className="border border-gray-300 rounded overflow-hidden darker-header"
        pagination={false}
        size="small"
        dataSource={fixedData}
        columns={
          data.rfqType == 1 ?
          [
            {
              title: '#',
              dataIndex: 'index',
              width: 50,
              render: (cell, rec, index) => index+1
            },
            {
              title: 'Recommended to award items by lowest price selection',
              dataIndex: 'name',
              width: 400
            },
            {
              title: fixedData.reduce((a,b) => a+b.details.length, 0),
              dataIndex: 'details',
              width: 50,
              render: (cell) => cell.length
            },
            {
              title: `$${fixedData.reduce((a,b) => a+b.details.reduce((_a,_b) => _a+_b.totalPriceUsd, 0), 0).toLocaleString("en-US")}`,
              dataIndex: 'details',
              width: 150,
              render: (cell) => cell.reduce((a,b) => a+b.totalPriceUsd, 0).toLocaleString(`en-US`)
            },
            {
              title: '$Cost reduction',
              dataIndex: 'details',
              width: 150,
              render: (cell) => cell.reduce((a,b) => a+b.costReduction ?? 0, 0).toLocaleString(`en-US`)
            },
            {
              title: 'Comment',
              dataIndex: 'comment',
              render: () => 'Recommended because price is the lowest'
            },
          ]
          :
          [
            {
              title: '#',
              dataIndex: 'index',
              width: 50,
              render: (cell, rec, index) => index+1
            },
            {
              title: 'Recommended to award items by lowest price selection',
              dataIndex: 'name',
              width: 400
            },
            {
              title: fixedData.reduce((a,b) => a+b.details.length, 0),
              dataIndex: 'details',
              width: 50,
              render: (cell) => cell.length
            },
            {
              title: `$${fixedData.reduce((a,b) => a+b.details.reduce((_a,_b) => _a+_b.totalPrice, 0), 0).toLocaleString("en-US")}`,
              dataIndex: 'details',
              width: 150,
              render: (cell) => cell.reduce((a,b) => a+b.totalPrice, 0).toLocaleString(`en-US`)
            },
            {
              title: 'Comment',
              dataIndex: 'comment',
              render: () => 'Recommended because price is the lowest'
            },
          ]
        }
        rowKey={"id"}
        expandable={{
          expandedRowRender: rec => {
            return (
              <Table 
                pagination={false} size="small"
                dataSource={rec.details}
                columns={[
                  {
                    title: '#',
                    dataIndex: 'index',
                    width: 50,
                    render: (cell, rec, index) => index+1
                  },
                  {
                    title: 'Material #',
                    dataIndex: 'materialNumber',
                    width: 100
                  },
                  {
                    title: 'Criticality',
                    dataIndex: 'criticality',
                    width: 50
                  },
                  {
                    title: 'Description',
                    dataIndex: 'description',
                  },
                  {
                    title: 'Quantity',
                    dataIndex: 'shipQty',
                    width: 50
                  },
                  {
                    title: 'UOM',
                    dataIndex: 'shipUOM',
                    width: 50
                  },
                  {
                    title: 'Unit price',
                    dataIndex: 'unitPriceUsd',
                    width: 200
                  },
                  {
                    title: 'Total',
                    dataIndex: 'totalPriceUsd',
                    width: 200
                  },
                  {
                    title: 'Cost reduction',
                    dataIndex: 'costReduction',
                    width: 150
                  },
                ]}>
              </Table>
            )
          }
        }}>

      </Table>
    </div>
  )
}

export default LowerPriceSuppliers