import React, { useEffect, useState } from 'react'
import { Button, Modal, Form as AntForm, Drawer, Dropdown, Space } from 'antd'
import { EditOutlined, PlusCircleOutlined, MoreOutlined } from '@ant-design/icons'
import { Form, MainTable } from 'components'
import { instance } from 'utils/axios'
import { Link, useLocation } from 'react-router-dom'
import Template from './template'

const fields = () => {
  return [
    {
      label: 'Event name',
      name: 'name',
      rules: [{required: true, message: 'Event name is required!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
  ]
}

const items = ({row, handleDelete, handleEdit}) => {  
  return (
    [
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleEdit(row)
        }}>Edit</a>,
        key: 0
      },
      {
        label: <a onClick={(e) => {
          e.preventDefault()
          handleDelete(row.id)
        }}>Delete</a>,
        key: 1
      },
    ]
  )
}

function TemplateEvent() {
  const [ data, setData ] = useState([])
  const [ currentPage, setCurrentPage ] = useState(0)
  const [ open, setOpen ] = useState(false)
  const [ editData, setEditData ] = useState(null)
  const [ isEdit, setIsEdit ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ modal, contextHolder ] = Modal.useModal();
  const [ form ] = AntForm.useForm();
  const [ selectedEvent, setSelectedEvent ] = useState(null)
  const location = useLocation()

  useEffect(() => {
    fetchData()
  },[])

  const fetchData = () => {
    setLoading(true)
    instance({
      method: 'get',
      url: `/TemplateEvent/group/${location.state.id}`
    }).then((res) => {
      setData(res.data.responseData)
    }).catch((err) => {
    }).then(() => setLoading(false))
  }

  const columns = [
    {
      title: '№',
      dataIndex: 'No',
      key: 'No',
      width: 40,
      align: 'center',
      render: (text, row, i) => (
        <span>{currentPage+i+1}</span>
      )
    },
    {
      title: 'Нэр',
      dataIndex: 'name',
      key: 'name',
      filterType: "search",
      render: (text, row) => (
        <a className='text-link hover:underline hover:text-link' onClick={() => setSelectedEvent(row)}>{text}</a>
      )
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 80,
      render: (text, row) => (
        <Dropdown 
          trigger={['click']} 
          menu={{items: items({row, handleDelete, handleEdit}),}}>
          <a onClick={e => e.preventDefault()}>
            <Space>
              <MoreOutlined className='text-xl'/>
            </Space>
          </a>
        </Dropdown>
      )
    },
  ]
  
  const handleDelete = (id) => {
    modal.confirm({
      title: 'Warning!',
      content: 'You are about to delete this row of data',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: 'delete',
            url: `TemplateEvent/${id}`
          }).then((res) => {
            resolve()
          }).catch((err) => {
            reject()
          }).then(() => fetchData())
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

  const handleSubmit = (values) => {
    setLoading(true)
    if(editData){
      console.log(values, editData)
      instance({
        method: 'put',
        url: '/TemplateEvent',
        data: {
          ...editData,
          ...values,
          id: editData.id,
        }
      }).then((res) => {
        handleCloseModal()
        fetchData()
      }).catch((err) => {

      }).then(() => setLoading(false))
    }
    else{
      instance({
        method: 'post',
        url: '/TemplateEvent',
        data: {
          ...values,
          templateGroupId: location.state.id,
          templateType: 1
        }
      }).then((res) => {
        handleCloseModal()
        fetchData()
      }).catch((err) => {

      }).then(() => setLoading(false))
    }
  }

  const handleAdd = () => {
    setIsEdit(true)
    setEditData(null)
    setOpen(true)
  }

  const handleEdit = (row) => {
    setEditData(row)
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
    form.resetFields()
  }
  return (
      <div className='p-4'>
        <div className='grid grid-cols-3 gap-4'>
          <MainTable
            containerClass={selectedEvent ? "col-span-1" :"col-span-3"}
            goback
            dataTable={data}
            columns={columns}
            setCurrentPage={setCurrentPage}
            loading={loading}
            pagination={false}
            title={`${location.state.name} events`}
            handleAdd={handleAdd}
          />
          {
            selectedEvent &&
            <Template setSelectedEvent={setSelectedEvent} className="col-span-2" eventId={selectedEvent.id} groupId={location.state.id}>
              
            </Template>
          }
        </div>
        <Drawer
          title={editData ? <div className='flex items-center justify-start gap-2'>Edit</div> : <div className='flex items-center justify-start gap-2'>Add</div>}
          open={open}
          destroyOnClose
          maskClosable={false}
          okText='Save'
          onOk={form.submit}
          onClose={handleCloseModal}
          footer={
            <div className="flex gap-3">
              <Button onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button type="primary" onClick={form.submit}>
                Save
              </Button>
            </div>
          }
        >
          <Form
            form={form}
            fields={fields()}
            length={data.length}
            layout="vertical"
            onFinish={handleSubmit}
            editData={editData}
            isEdit={isEdit}
            className='gap-4'
          />
        </Drawer>
        {contextHolder}
      </div>
  )
}

export default TemplateEvent
