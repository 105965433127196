
const totalColumns = [
  {
    title: 'Number of Suppliers Participation',
    dataIndex: 'title'
  },
  {
    title: 'Suppliers',
    dataIndex: 'total',
    width: 700,
    // render: (cell, row) => row.data.length
  },
  {
    title: 'Comment',
    dataIndex: 'commentText',
    editable: true,
    width: 500
  },
]

const suppliersColumns = (selected) => {
  return [
    {
      title: '#',
      width: 50,
      count: true,
      fixed: 'left',
      render: (cell, row, index) => index+1,
      shouldCellUpdate: false
    },
    {
      title: 'Supplier',
      dataIndex: 'name',
      editable: false,
    },
    {
      title: 'Comment',
      dataIndex: 'commentText',
      editable: true,
      type: 'select',
      options: [
        {
          label: 'Recommended because price is the lowest',
          value: 'Recommended because price is the lowest'
        },
        {
          label: '1 item is not the lowest but LT is the shortest, end user preffered shortest LT selected',
          value: '1 item is not the lowest but LT is the shortest, end user preffered shortest LT selected'
        },
        {
          label: '1 item is not the lowest and alternative choice but EU checked the datasheet and chosen alternative brand',
          value: '1 item is not the lowest and alternative choice but EU checked the datasheet and chosen alternative brand'
        },
        {
          label: '1 item is not the lowest but supplier required combined bundle shipment for other 4 items, thus selected',
          value: '1 item is not the lowest but supplier required combined bundle shipment for other 4 items, thus selected'
        },
      ],
      width: 500
    },
  ]
}


const disqualifiedColumns = [
  {
    title: '#',
    width: 50,
    count: true,
    fixed: 'left',
    render: (cell, row, index) => index+1,
    shouldCellUpdate: false
  },
  // {
  //   title: 'PR Number',
  //   dataIndex: 'prNumber',
  //   shouldCellUpdate: false
  // },
  {
    title: 'Material Number',
    dataIndex: 'materialNumber',
    shouldCellUpdate: false
  },
  {
    title: 'Description',
    dataIndex: 'description',
    shouldCellUpdate: false
  },
  {
    title: 'Manufacturer',
    dataIndex: 'manufacturer',
    shouldCellUpdate: false
  },
  {
    title: 'Part number',
    dataIndex: 'partNumber',
    shouldCellUpdate: false
  },
  {
    title: 'UOM',
    dataIndex: 'uom',
    shouldCellUpdate: false
  },
  {
    title: 'Quantity',
    dataIndex: 'qty',
    shouldCellUpdate: false
  },
  {
    title: 'Comment',
    dataIndex: 'commentText',
    editable: true,
    type: 'select',
    options: [
      {
        label: 'Excluded if 35% higher or 50% cheaper than historical average price',
        value: 'Excluded if 35% higher or 50% cheaper than historical average price'
      },
      {
        label: 'Excluded if single source bid with no historical purchase',
        value: 'Excluded if single source bid with no historical purchase'
      },
      {
        label: 'Excluded if no quote received',
        value: 'Excluded if no quote received'
      },
      {
        label: 'Excluded if quote difference is unreasonably high',
        value: 'Excluded if quote difference is unreasonably high'
      },
      {
        label: 'Excluded if high value and single quote received',
        value: 'Excluded if high value and single quote received'
      },
    ],
    width: 500
  },
]

const qualifiedColumns = [
  {
    title: '#',
    width: 50,
    count: true,
    fixed: 'left',
    render: (cell, row, index) => index+1,
    shouldCellUpdate: false
  },
  // {
  //   title: 'PR Number',
  //   dataIndex: 'prNumber',
  //   shouldCellUpdate: false
  // },
  {
    title: 'Material Number',
    dataIndex: 'materialNumber',
    fixed: 'left',
    width: 140,
    shouldCellUpdate: false
  },
  {
    title: 'Supplier',
    dataIndex: '_supplier',
    width: 250,
    shouldCellUpdate: false,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    width: 400,
    shouldCellUpdate: false
  },
  {
    title: 'Manufacturer',
    dataIndex: 'manufacturer',
    width: 200,
    shouldCellUpdate: false
  },
  {
    title: 'Part number',
    dataIndex: 'partNumber',
    width: 130,
    shouldCellUpdate: false
  },
  {
    title: 'UOM',
    dataIndex: 'uom',
    width: 70,
    shouldCellUpdate: false
  },
  {
    title: 'Quantity',
    dataIndex: 'shipQty',
    width: 80,
    shouldCellUpdate: false
  },
  {
    title: 'Unit price',
    dataIndex: 'unitPriceUsd',
    width: 150,
    shouldCellUpdate: false,
    format: 'currency'
  },
  {
    title: 'Total price',
    dataIndex: 'totalPriceUsd',
    width: 200,
    shouldCellUpdate: false,
    format: 'currency'
  },
  {
    title: 'Lead time',
    dataIndex: 'shipDays',
    shouldCellUpdate: false,
    width: 100
  },
  {
    title: 'Comment',
    dataIndex: 'commentText',
    editable: true,
    type: 'select',
    fixed: 'right',
    options: [
      {
        label: 'Recommended because price is the lowest',
        value: 'Recommended because price is the lowest'
      },
      {
        label: '1 item is not the lowest but LT is the shortest, end user preffered shortest LT selected',
        value: '1 item is not the lowest but LT is the shortest, end user preffered shortest LT selected'
      },
      {
        label: '1 item is not the lowest and alternative choice but EU checked the datasheet and chosen alternative brand',
        value: '1 item is not the lowest and alternative choice but EU checked the datasheet and chosen alternative brand'
      },
      {
        label: '1 item is not the lowest but supplier required combined bundle shipment for other 4 items, thus selected',
        value: '1 item is not the lowest but supplier required combined bundle shipment for other 4 items, thus selected'
      },
    ],
    width: 300
  },
]

const expandedDetailColumns = [
  {
    title: 'Name',
    dataIndex: 'name'
  },
  {
    title: 'Unit price',
    dataIndex: 'unitPrice',
    render: foo => foo.toLocaleString("en-US")
  },
  {
    title: 'Quantity',
    dataIndex: 'shipQty'
  },
  {
    title: 'Shipping days',
    dataIndex: 'shipDays'
  },
  {
    title: 'Total price',
    dataIndex: 'totalPrice',
    render: foo => foo.toLocaleString("en-US")
  },
  {
    title: 'Suggested Manufacturer',
    dataIndex: 'isSuggestedManufacturer'
  },
  {
    title: 'Suggested Manufacturer Part Number',
    dataIndex: 'suggestedManufacturerPartNumber'
  },
]

const expandedServiceColumns = [
  {
    title: 'Detail',
    dataIndex: 'content',
    render: (cell, row) => {
      return (
        <div dangerouslySetInnerHTML={{__html: cell}}></div>
      )
    },
  },
  {
    title: 'Total price',
    dataIndex: 'totalPrice',
    render: (cell, row) => {
      return (
        <span>{cell.toLocaleString("en-US")}</span>
      )
    },
  },
]

const expandedSuppliersColumns = [
  {
    title: 'Name',
    dataIndex: 'supplierName'
  },
  {
    title: 'ID',
    dataIndex: 'supplierId'
  },
  {
    title: 'SAP ID',
    dataIndex: 'vendorNumber'
  },
]

const disqualifiedColumnsSummary = [
  {
    title: '#',
    dataIndex: 'index',
    fixed: 'left',
    width: 50,
    render: (cell, rec, index) => index+1
  },
  {
    title: 'Material #',
    dataIndex: 'materialNumber',
    fixed: 'left',
    width: 100
  },
  {
    title: 'Criticality',
    dataIndex: 'criticality',
    width: 100
  },
  {
    title: 'Description',
    dataIndex: 'description',
  },
  {
    title: 'Manufacturer',
    dataIndex: 'manufacturer',
    width: 200
  },
  {
    title: 'Part #',
    dataIndex: 'partNumber',
    width: 200
  },
  {
    title: 'UOM',
    dataIndex: 'uom',
    width: 50
  },
  {
    title: 'Quantity',
    dataIndex: 'qty',
    width: 100
  },
  {
    title: 'Comment',
    dataIndex: 'commentText',
    width: 300
  },
]



export { suppliersColumns, disqualifiedColumns, expandedServiceColumns, totalColumns, expandedDetailColumns, expandedSuppliersColumns, disqualifiedColumnsSummary, qualifiedColumns }