import { Table } from "antd"

const DisqualifiedItems = ({data}) => {
  
  return (
    <Table 
      className="border border-gray-300 rounded overflow-hidden"
      pagination={false}
      size="small"
      dataSource={data.disqualified}
      columns={[
        {
          title: '#',
          dataIndex: 'index',
          width: 50,
          render: (cell, rec, index) => index+1
        },
        {
          title: 'Material #',
          dataIndex: 'materialNumber',
          width: 100
        },
        {
          title: 'Criticality',
          dataIndex: 'criticality',
          width: 50
        },
        {
          title: 'Description',
          dataIndex: 'description',
        },
        {
          title: 'Manufacturer',
          dataIndex: 'manufacturer',
          width: 200
        },
        {
          title: 'Part #',
          dataIndex: 'partNumber',
          width: 200
        },
        {
          title: 'UOM',
          dataIndex: 'uom',
          width: 50
        },
        {
          title: 'Quantity',
          dataIndex: 'qty',
          width: 50
        },
        {
          title: 'Comment',
          dataIndex: 'commentText',
        },
      ]}>

    </Table>
  )
}

export default DisqualifiedItems