import { useEffect, useState } from "react";
import { Form } from "components"
import { rfqFields , fields, travelFields, freightFields } from './fields'
import { Form as AntForm, Button, Divider, Space, Input, Table, InputNumber, Select, DatePicker, Popconfirm, Modal } from 'antd'
import { instance } from "utils/axios";
import { PlusOutlined, MinusCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import dayjs from "dayjs";
import EOIAdditionalForm from "./EOIAdditionalForm";
import RFQAdditionalForm from "./RFQAdditionalForm";
import FreightAdditionalForm from "./FreightAdditionalForm";
import ServiceAdditionalForm from './ServiceAdditionalForm'

const TenderInformation = ({setActiveStep, open, tenderData, type, closeModal, setTenderData, editData}) => {
  const [ products, setProducts ] = useState([])
  const [ users, setUsers ] = useState([])
  const [ tenderForm ] = AntForm.useForm()
  const [ templateList, setTemplateList ] = useState([])
  const [ reminderModalOpen, setReminderModalOpen ] = useState(false)
  const [ reminderForm ] = AntForm.useForm()
  const rfqTypeValue = AntForm.useWatch('rfqType', tenderForm)
  const [ loading, setLoading ] = useState(false)
  
  useEffect(() => {
    fetchMasterData()
    // tenderForm.setFieldsValue(tenderData)
    fetchTenderId()
    if(type == 3) {
      fetchTemplate()
    }
  }, [])

  useEffect(() => {
    return () => {
      if(type == 2 && !open) {
        if(tenderForm.getFieldValue("details")?.length > 0) {
          instance({
            method: 'post',
            url: `/PurchaseRequest/unload?wcode=tender`,
            data: {
              loadIds: tenderForm.getFieldValue("details").map(foo => foo.loadId)
            }
          })
        }
      }
    }
  })
  
  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    window.addEventListener('unload', handleTabClosing)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
      window.removeEventListener('unload', handleTabClosing)
    }
  })

  const handleTabClosing = () => {
    if(type == 2) {
      if(tenderForm.getFieldValue("details")?.length > 0) {
        instance({
          method: 'post',
          url: `/PurchaseRequest/unload?wcode=tender`,
          data: {
            loadIds: tenderForm.getFieldValue("details").map(foo => foo.loadId)
          }
        })
      }
    }
    // removePlayerFromGame()
  }

  const alertUser = (event) => {
    event.preventDefault()
    event.returnValue = ''
  }

  const fetchTemplate = () => {
    instance({
      method: 'get',
      url: `/TemplateEvent/groupwithtemplate/3?type=2&wcode=tender`
    }).then((res) => {
      setTemplateList(res.data.responseData)
    }).catch((err) => {
    }).then(() => {})
  }

  const fetchTenderId = () => {
    if(!editData) {
      instance({
        method: 'get',
        url: `/TenderSeq/tendertype/${type}?wcode=tender`
      }).then(res => {
        if(type == 2) {
          tenderForm.setFieldValue('code', res.data.responseData)
        }
      })
    }
  }

  const fetchMasterData = () => {
    Promise.all([
      instance({
        method: 'get',
        url: `/User/permission/7?wcode=tender`
      }),
      instance({
        method: 'get',
        url: `/master/ProductType?wcode=tender`
      }),
    ]).then((res) => {
      setUsers(res[0].data.responseData)
      setProducts(res[1].data.responseData)
    }).catch(err => {
      
    }).then(() => {

    })
  }
  
  const findProducts = (_array) => {
    let tmp = []

    if(_array) {
      _array.map((foo, fooIndex) => {
        products.map((_product, _productIndex) => {
          if(foo == _product.id) {
            tmp.push(_product)
          }
        })
      })
    }

    return tmp
  }

  const findEndUsers = (_array) => {
    let tmp = []
    
    if(_array) {
      _array.map((foo, fooIndex) => {
        users.map((_user, _userIndex) => {
          if(foo == _user.id) {
            tmp.push(_user)
          }
        })
      })
    }

    return tmp
  }
  
  const handleSubmit = (values) => {
    if(type == 2) {
      setTenderData(prev => ({
        ...prev, 
        ...values, 
        endUsersLookup: findEndUsers(values.endUserIds), 
        productsLookup: findProducts(values.productTypeIds),
        endDate: values.endDate ? values.endDate : dayjs(values.startDate).add(values.duration, values.closeDateDurationType == 0 ? 'hour' : 'day'),
      }))
    }
    else if(type == 4) {
      setTenderData(prev => ({
        ...prev, 
        ...values, 
        endUsersLookup: findEndUsers(values.endUserIds), 
        endDate: values.endDate ? values.endDate : dayjs(values.startDate).add(values.duration, values.closeDateDurationType == 0 ? 'hour' : 'day'),
        productsLookup: findProducts(values.productTypeIds),
        isDangerous: values.isDangerous ? 1 : 0,
        isOversized: values.isOversized ? 1 : 0,
      }))
    }
    else if(type == 1) {
      setTenderData(prev => ({
        ...prev, 
        ...values, 
        details: values.details,
        endDate: values.endDate ? values.endDate : dayjs(values.startDate).add(values.duration, values.closeDateDurationType == 0 ? 'hour' : 'day'),
      }))
    }
    else {
      setTenderData(prev => ({
        ...prev, 
        ...values,
        endDate: values.endDate ? values.endDate : dayjs(values.startDate).add(values.duration, values.closeDateDurationType == 0 ? 'hour' : 'day'),
        endUsersLookup: findEndUsers(values.endUserIds), 
        productsLookup: findProducts(values.productTypeIds),
      }))
    }
    if(values.reminderDate) {

    }

    setActiveStep(1)
  }

  const saveAsDraft = () => {
    setLoading(true)
    tenderForm.validateFields().then(() => {
      const values = tenderForm.getFieldsValue()
      instance({
        method: 'post',
        url: `/Tender/rfq?wcode=tender`,
        data: {
          ...values,
          startDate: dayjs(values.startDate).tz("Asia/Ulaanbaatar").format("YYYY-MM-DDTHH:mm:00"),
          endDate: dayjs(values.endDate).tz("Asia/Ulaanbaatar").format("YYYY-MM-DDTHH:mm:00"),
          attachments: values.attachments?.map(foo => ({path: foo.response.responseData, name: foo.name})),
          tenderTypeId: type,
          productTypeIds: values.productTypeIds ? values.productTypeIds.map(foo => ({id: foo, state: 0})) : [],
          details: values.details ?? [],
          endUserIds: values.endUserIds ? values.endUserIds.map(foo => typeof foo == 'object' ? ({id: foo.id, state: foo.state}) : ({id: foo, state: 0})) : [],
          supplierIds: values.supplierIds?.map((foo) => ({id: foo, state: 1})) ?? [],
          status: -1,
          reminderDate: values.reminderDate ? 1 : 0
        }
      }).then((res) => {
        closeModal()
      }).catch((err) => {
  
      }).then(() => setLoading(false))
    }).catch(() => {
      setLoading(false)
    })
  }

  const handleReminderFormSubmit = (values) => {
    setTenderData(prev => ({
      ...prev, 
      startNum: values.startNum,
      startCounterType: values.startCounterType,
      everyNum: values.everyNum,
      everyCounterType: values.everyCounterType,
      lastNum: values.lastNum,
      lastCounterType: values.lastCounterType,
    }))
    tenderForm.setFieldValue("reminderDate", true)
    setReminderModalOpen(false)
  }

  const roundMinutes = () => {
    let tmp = dayjs().add(15, 'minutes').get("minutes")
    if(tmp < 8) {
      return dayjs().add(15-(tmp%15), 'minutes')
    }
    else {
      return dayjs().add(15+15-(tmp%15), 'minutes')
    }
  }
  
  return (
    <Form
      form={tenderForm}
      fields={type == 1 ? fields(type, [], [], users, tenderForm, setReminderModalOpen, reminderForm) : 
              type == 2 ? rfqFields(type, [], products, users, tenderForm, setReminderModalOpen, reminderForm) : 
              type == 3 ? travelFields(type, templateList, tenderForm) : 
              freightFields(type, [], [], [], tenderForm) }
      layout="vertical"
      editData={type == 2 ? {
        ...tenderData,
        details: tenderData.details?.map((foo) => ({...foo, manufacturers: foo.manufacturers ? typeof foo.manufacturers == 'object' ? foo.manufacturers : foo.manufacturers.split(", ").map(foo => {
          if(foo.length > 0) {
            return foo
          }
        }): []}))
      } : type == 1 ? {
        ...tenderData,
        details: tenderData.details?.filter(foo => foo.systemDocumentId == null)
      } : tenderData}
      className="gap-4"
      onFinish={handleSubmit}
      initialValues={
        type == 1 ?
        {
          startDate: roundMinutes(),
          endDate: roundMinutes(),
          reminderDate: false,
          closeDateIsDuration: 1,
          duration: 3,
          closeDateDurationType: 1,
          reminderDate: true,
          lastNum: 1,
          lastCounterType: 3
        }
        :
        type == 2 ? 
        {
          rfqType: 1,
          startDate: roundMinutes(),
          endDate: dayjs(roundMinutes()).add(5, 'days'),
          reminderDate: false,
          closeDateIsDuration: 1,
          duration: 5,
          closeDateDurationType: 1,
          bidType: 1,
          reminderDate: true,
          lastNum: 1,
          lastCounterType: 3
        }
        :
        type == 3 ? {
        startDate: dayjs(),
        endDate: dayjs(),
        peopleNumber: 1,
        childrenNumber: 0
        }:
        {
          startDate: dayjs(),
          endDate: dayjs(),
          reminderDate: false,
          closeDateIsDuration: 1,
          duration: 0,
          closeDateDurationType: 0
        }
      }
      >
      {
        type == 1 ?
        <EOIAdditionalForm/>
        :
        type == 2 ?
        rfqTypeValue == 2 ?
        <ServiceAdditionalForm endUsers={users} products={products}></ServiceAdditionalForm>
        :
        <RFQAdditionalForm endUsers={users} products={products}/>
        :
        type == 3 ?
        <>
        {/* <TravelAdditionalForm/> */}
        </>
        :
        <FreightAdditionalForm/>
      }
      <div className="flex justify-end gap-3 col-span-12">
        <Button loading={loading} onClick={closeModal}>Cancel</Button>
        {
          type == 2 &&
          <Button loading={loading} type="primary" onClick={() => saveAsDraft()}>Save as draft</Button>
        }
        <Button loading={loading} type="primary" onClick={() => tenderForm.submit()}>Next</Button>
        <Modal
          width={600}
          title="Edit reminder for response start date"
          open={reminderModalOpen}
          onCancel={() => {
            setReminderModalOpen(false)
            tenderForm.setFieldsValue({
              reminderDate: false,
              startNum: 0,
              startCounterType: 0,
              everyNum: 0,
              everyCounterType: 0,
              lastNum: 0,
              lastCounterType: 0
            })
          }}
          onOk={() => {
            reminderForm.submit()
          }}>
          <div>
            <p>
              You can configure one or all of the reminder fields. Enter 0 in the reminder fields
            </p>
            <AntForm
              form={reminderForm}
              onFinish={handleReminderFormSubmit}
              initialValues={tenderData && (tenderData.startNum || tenderData.everyNum || tenderData.closeNum) ? tenderData : {
                startNum: 0,
                startCounterType: 0,
                everyNum: 0,
                everyCounterType: 0,
                lastNum: 0,
                lastCounterType: 0
              }}>
              <div className="flex items-center gap-2">
                <p>
                  Start reminders
                </p>
                <AntForm.Item name="startNum">
                  <InputNumber/>
                </AntForm.Item>
                <AntForm.Item name="startCounterType" className="w-[100px]">
                  <Select>
                    <Select.Option value={0}>None</Select.Option>
                    <Select.Option value={1}>Minutes</Select.Option>
                    <Select.Option value={2}>Hours</Select.Option>
                    <Select.Option value={3}>Days</Select.Option>
                    <Select.Option value={4}>Weeks</Select.Option>
                  </Select>
                </AntForm.Item>
                <p>
                  before close date
                </p>
              </div>
              <div className="flex items-center gap-2">
                <p>
                  Send reminders every
                </p>
                <AntForm.Item label="" name="everyNum">
                  <InputNumber/>
                </AntForm.Item>
                <AntForm.Item name="everyCounterType" className="w-[100px]">
                  <Select>
                    <Select.Option value={0}>None</Select.Option>
                    <Select.Option value={3}>Days</Select.Option>
                  </Select>
                </AntForm.Item>
              </div>
              <div className="flex items-center gap-2">
                <p>
                  Send last reminder
                </p>
                <AntForm.Item label="" name="lastNum">
                  <InputNumber/>
                </AntForm.Item>
                <AntForm.Item name="lastCounterType" className="w-[100px]">
                  <Select>
                    <Select.Option value={0}>None</Select.Option>
                    <Select.Option value={1}>Minutes</Select.Option>
                    <Select.Option value={2}>Hours</Select.Option>
                    <Select.Option value={3}>Days</Select.Option>
                    <Select.Option value={4}>Weeks</Select.Option>
                  </Select>
                </AntForm.Item>
                <p>
                  before close date
                </p>
              </div>
            </AntForm>
          </div>
        </Modal>
      </div>
    </Form>
  )
}

export default TenderInformation