import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { Button, Modal, Form as AntForm, Tooltip, Skeleton, Divider, Card, Input, Form, Upload, Select, Table } from 'antd'
import { LeftOutlined, CaretDownOutlined, UploadOutlined } from '@ant-design/icons'
import { instance } from 'utils/axios'
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom'
import ValidationForm from './ValidationForm'
import CompanyInformation from './CompanyInformation'
import ContactDetail from './ContactDetails'
import ManagementTeam from './ManagementTeam'
import CompanyShareholders from './CompanyShareHolders'
import ProductsAndServices from './ProductsAndServices'
import { AuthContext } from 'contexts'
import CompanyLicense from './CompanyLicense'
import dayjs from 'dayjs'

const fields = () => {
  return [
    {
      label: 'Нэр',
      name: 'name',
      rules: [{required: true, message: 'Нэр оруулна уу!'}],
      className: 'col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
  ]
}

function SupplierValidation() {
  const [ data, setData ] = useState({
    supplierData: {
      contacts: [],
      employees: [],
      supplier: {},
      product: [],
      supplierServices: [],
      shareholders: [],
    },
    validationData: []
  })
  const [ modal, contextHolder ] = Modal.useModal();
  const [ loading, setLoading ] = useState(true)
  const [ tabIndex, setTabIndex ] = useState(0)
  const [ returnModalOpen, setReturnModalOpen ] = useState(false)
  const [ returning, setReturning ] = useState(false)
  const [ tiers, setTiers ] = useState([])
  const [ returnForm ] = Form.useForm()
  const location = useLocation()
  const navigate = useNavigate()
  const authContext = useContext(AuthContext)
  const [ submitting, setSubmitting] = useState(false)
  const { id } = useParams()

  const items = [
    {
      label: 'Company information',
      key: 0,
      children: <CompanyInformation data={data?.supplierData?.supplier}></CompanyInformation>
    },
    {
      label: 'Contact details',
      key: 1,
      children: <ContactDetail data={data?.supplierData?.contacts}></ContactDetail>
    },
    {
      label: 'Management Team',
      key: 2,
      children: <ManagementTeam data={data?.supplierData?.employees}></ManagementTeam>
    },
    {
      label: 'Company shareholder information',
      key: 3,
      children: <CompanyShareholders data={data?.supplierData?.shareholders}/>
    },
    {
      label: 'Company license',
      key: 5,
      children: <CompanyLicense data={data?.supplierData?.supplierLicenses}/>
    },
    {
      label: 'Products & services',
      key: 5,
      children: <ProductsAndServices products={data?.supplierData?.product} fullData={data}/>
    },
  ]
  
  useEffect(() => {
    fetchData()
    fetchTiers()
  },[])

  const fetchTiers = () => {
    instance({
      method: 'get',
      url: `/SupplierTier?wcode=master`
    }).then(res => {
      setTiers(res.data.responseData)
    })
  }

  const fetchData = () => {
    setLoading(true)
    instance({
      method: 'get',
      url: `/SupplierValidation/detail/${id}?wcode=supplier-validation-detail`
    }).then((res) => {
      setData(res.data.responseData)
      returnForm.setFieldValue("productIds", res.data.responseData.supplierData.product.map((foo) => foo.id))
    }).catch((err) => {
    }).then(() => setLoading(false))
  }

  const handleReturn = (values) => {
    setSubmitting(true)

    instance({
      method: 'put',
      url: `/SupplierValidation?wcode=supplier-validation-detail`,
      data: {
        ...values,
        productModels: values.productIds? values.productIds.map((foo, fooIndex) => ({id: foo, status: 1})) : data.supplierData.product,
        SupplierId: data.supplierData.supplier.id,
        validationStatus: returning ? data?.supplierData?.supplier.validationStatus == 1 ? 2 : 1 : data.supplierData.supplier.validationStatus == 1 ? 3 : data.supplierData.supplier.validationStatus == 3 && 4,
        fileName: values.file?.file && values.file.file.response.responseData
      }
    }).then(res => {
      navigate(-1)
    }).catch(err => {
      
    }).then(() => {
      setSubmitting(false)
    })
  }

  return (
    <div className='p-4 grid grid-cols-12 gap-3'>
      <Card className="col-span-3 sticky top-3" size="small" style={{height: `calc(100vh - 76px)`}} styles={{body: {height: '100%'}}}>
        <div className="flex flex-col gap-3 h-full">
          {
            loading ?
            <Skeleton active/>
            :
            <div className="flex items-center gap-3 mb-3">
              <div className="w-12 h-12 rounded-full flex justify-center items-center text-white bg-primary text-3xl">
                {location.state?.name?.slice(0,1)}
              </div>
              <div className="grow">
                <div className='text-xl font-bold'>
                  {location.state?.name}
                </div>
                <div className="text-mute">
                  ID: {location.state?.id}
                </div>
              </div>
            </div>
          }
          <div className="flex flex-col grow">
            {
              items.map((foo, fooIndex) => {
                return (
                  <div
                    key={`menu-${fooIndex}`} 
                    className="py-3 text-left hover:text-primary transition-all hover:bg-slate-200 px-3 flex items-center gap-2 rounded cursor-pointer"
                    onClick={() => setTabIndex(fooIndex)}>
                    <div className={`grow font-medium ${tabIndex == fooIndex && `text-primary`}`}>
                      {foo.label}
                    </div>
                    <CaretDownOutlined className={`text-black/40 transition-all  ${tabIndex == fooIndex && `!text-primary -rotate-90`}`}/>
                  </div>
                )
              })
            }
          </div>
          <Divider className="my-0"></Divider>
          <div
            className="py-3 text-left hover:text-primary transition-all hover:bg-slate-200 px-3 flex items-center gap-2 rounded cursor-pointer"
            onClick={() => setTabIndex(-1)}>
            <div className={`grow font-medium ${tabIndex == -1 && `text-primary`}`}>
              Validation history
            </div>
            <CaretDownOutlined className={`text-black/40 transition-all  ${tabIndex == -1 && `!text-primary -rotate-90`}`}/>
          </div>
        </div>
      </Card>
      <div className="col-span-9 pb-20">
        {
          tabIndex == -1 ? 
          <Card title="Validation history" size="small">
            <Table
              rowHoverable
              pagination={false}
              size="small"
              dataSource={data.validationData}
              columns={[
                {
                  title: 'Comment',
                  dataIndex: 'description'
                },
                {
                  title: 'Attachment',
                  dataIndex: 'fileName',
                  width: 120,
                  render: (cell) => cell && <a href={process.env.REACT_APP_UPLOAD_URL+cell} target='_blank'>Preview</a>
                },
                {
                  title: 'Date',
                  dataIndex: 'createdAt',
                  width: 120,
                  render: cell => dayjs(cell).format("YYYY/MM/DD")
                },
              ]}>

            </Table>
            {/* {
              data.validationData.map((foo, fooindex) => {
                return (
                  <div key={`row-${fooindex}`} className="[&:not(:last-child)]:border-b [&:not(:first-child)]:mt-4">
                    <p className="font-semibold">
                      {
                        fooindex == 0 ?
                        'Validation info'
                        :
                        'Approvement info'
                      }
                    </p>
                    <div className="flex gap-3">
                      <p>
                        Attachment: 
                      </p>
                      <a className="text-link font-medium" target='_blank' href={`${process.env.REACT_APP_UPLOAD_URL}/${foo.fileName}`}>
                        {foo.fileName?.split("/").slice(-1)}
                      </a>
                    </div>
                      <p>
                        Description:
                      </p>
                      <Input.TextArea rows={4} disabled={true} value={foo.description}></Input.TextArea>
                  </div>
                )
              })
            } */}
          </Card>
          :
          items[tabIndex].children
        }
      </div>
      <Card className="fixed right-4 bottom-3" size="small" style={{width: 'calc((100vw - 280px - 40px) / 12 * 9)'}}>
        <div className="flex gap-3 justify-between">
          <div className="flex gap-2 flex-wrap">

          </div>
          <div className="flex gap-3 justify-end">
            {
              (data?.supplierData?.supplier?.validationStatus == 3 || data?.supplierData?.supplier?.validationStatus == 2 || data?.supplierData?.supplier?.validationStatus == 1) &&
              <Button className="col-span-2" type="primary" danger loading={loading} onClick={() => {
                setReturning(true)
                setReturnModalOpen(true)
              }}>Return</Button>
            }
            {
              (data?.supplierData?.supplier.validationStatus == 1 || data?.supplierData?.supplier.validationStatus == 3 ) &&
              <Button className="col-span-2" type="primary" loading={loading} onClick={() => {
                setReturning(false)
                setReturnModalOpen(true)
              }}>Submit</Button>
            }
          </div>
        </div>
      </Card>
      <Modal
        title={returning ? "Return" : "Confirm"}
        open={returnModalOpen}
        onCancel={() => setReturnModalOpen(false)}
        onOk={returnForm.submit}
        confirmLoading={submitting}>
        <Form
          disabled={submitting}
          form={returnForm}
          layout='vertical'
          onFinish={handleReturn}
          defaultValue={{
            venderNumber: data.supplierData.supplier.vendorNumber,
            productIds: data.supplierData.product?.map(foo => foo.id)
          }}>
          <Form.Item label="Vendor number" name="vendorNumber">
            <Input></Input>
          </Form.Item>
          <Form.Item label="Tier type" name="supplierTierId">
            <Select options={tiers.map(foo => ({value: foo.id, label: foo.name}))}></Select>
          </Form.Item>
          <Form.Item label="Product codes" name="productIds">
            <Select mode='multiple' options={data.supplierData.product?.map((item) => ({value: item.id, label: `${item.productCode} - ${item.productName}`}))}></Select>
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input.TextArea rows={4}></Input.TextArea>
          </Form.Item>
          <Form.Item label="Attachment" name="file">
            <Upload 
              maxCount={1}
              action={`${process.env.REACT_APP_MAIN_API_URL}File/upload`}
              headers={{
                Authorization: `Bearer ${authContext.state.token}`,
              }}
              onRemove={(file) => {
                instance({
                  method: 'delete',
                  url: `File/remove`,
                  data: file.response.responseData
                })
              }}>
              <Button icon={<UploadOutlined />}>Select file</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default SupplierValidation
