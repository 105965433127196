import { Button, Card, Form, Input, Modal, Table, Tag, Tooltip, Upload } from "antd"
import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { instance } from "utils/axios"
import { DownloadOutlined, InboxOutlined, FileExcelOutlined } from "@ant-design/icons"
import { AuthContext } from "contexts"
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';
import { templateGenerate } from "./template"

const { Dragger } = Upload

const BidderList = () => {
  const [ data, setData ] = useState([])
  const [ fullData, setFullData ] = useState({
    documents: []
  })
  const [ loading, setLoading ] = useState(true)
  const [ selected, setSelected ] = useState([])
  const [ shortListModalOpen, setShortListModalOpen ] = useState(false)
  const [ bulkDownloading, setBulkDownloading ] = useState(false)
  const [ shortListForm ] = Form.useForm()
  const authContext = useContext(AuthContext)
  const { id } = useParams()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `/Tender/eoi/bidderlist/${id}`
    }).then(res => {
      setData(res.data.responseData.suppliers)
      setFullData(res.data.responseData)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const handleFinish = (values) => {
    setLoading(true)
    instance({
      method: 'post',
      url: `/Tender/eoi/award`,
      data: {
        tenderId: id,
        suppliers: selected
      }
    }).then(() => {
      setShortListModalOpen(false)
      setSelected([])
    }).catch(() => {

    }).then(() => {
      setLoading(false)
    })
  }

  // const exportToExcel = async () => {
  //   const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  //   const fileExtension = '.xlsx';
  //   const ws = XLSX.utils.json_to_sheet(data);
  //   const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  //   const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  //   const _data = new Blob([excelBuffer], { type: fileType });
  //   FileSaver.saveAs(_data, 'bidderlist' + fileExtension);
  // }
  
  const exportToExcel = async () => {
    templateGenerate(data, fullData)
  }

  const handleBulkDownload = () => {
    setBulkDownloading(true)
    instance({
      method: 'get',
      url: `Tender/eoi/download/${id}/null`,
      responseType: "blob",
    }).then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data])); // you can mention a type if you wish
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `eoi-${id}.zip`); //this is the name with which the file will be downloaded
      link.click();
      // no need to append link as child to body.
      setTimeout(() => window.URL.revokeObjectURL(url), 0);
      console.log(res.data)
    }).finally(() => {
      setBulkDownloading(false)
    })
  }
  
  return (
    <Card title={
      <div className="flex justify-between items-center p-2">
        <div className='font-semibold'>
          Bidder list
        </div>
        <div className="flex gap-3">
          <Tooltip title={selected.length == 0 ? "Select suppliers first" : "Create short list"}>
            <Button type="primary" onClick={() => setShortListModalOpen(true)} disabled={selected.length == 0}>
              Short list
            </Button>
          </Tooltip>
          <Tooltip title="Download files as zip">
            <Button type="primary" icon={<DownloadOutlined />} onClick={handleBulkDownload} loading={bulkDownloading}/>
          </Tooltip>
          <Tooltip title="Download as excel">
            <Button type="primary" icon={<FileExcelOutlined />} onClick={exportToExcel}/>
          </Tooltip>
        </div>
      </div>
      } 
      size="small" 
      bodyStyle={{padding: 0}}>
      <div className="flex flex-col">
        <Table
          rowKey={row => row.supplierId}
          dataSource={data}
          size="small"
          rowSelection={{
            type: 'checkbox',
            onChange: (selectedRowKeys, selectedRows) => {
              setSelected(selectedRowKeys)
            },
          }}
          expandable={{
            expandedRowRender: rec => {
              return (
                <div>
                  <Table
                    columns={[
                      {
                        title: 'Title',
                        dataIndex: 'documentId',
                        width: 250,
                        render: (cell, row) => {
                          return (
                            <span>{fullData.documents.find(foo => foo.id == cell)?.documentName ? fullData.documents.find(foo => foo.id == cell)?.documentName : fullData.documents.find(foo => foo.id == cell)?.name }</span>
                          )
                        }
                      },
                      {
                        title: 'Comment',
                        dataIndex: 'comment',
                      },
                      {
                        title: 'Filename',
                        dataIndex: 'name',
                        render: (cell) => {
                          return cell.split("\\").at(-1)
                        }
                      },
                      {
                        title: 'File',
                        dataIndex: 'name',
                        width: 90,
                        render: (cell) => {
                          return (
                            <a className="text-primary" href={process.env.REACT_APP_CDN_URL+cell} target="_blank">preview</a>
                          )
                        }
                      },
                    ]}
                    dataSource={rec.documents}
                    pagination={false}>

                  </Table>
                </div>
              )
            }
          }}
          columns={[
            {
              dataIndex: 'name',
              title: 'Name'
            },
            {
              title: 'Email',
              dataIndex: 'companyEmail',
              width: 150
            },
            {
              dataIndex: 'regNo',
              title: 'Register number',
              width: 150
            },
            {
              title: 'In short list',
              dataIndex: 'awardStatus',
              width: 150,
              render: (cell) => (
                <Tag color={cell == 1 ? 'success' : 'error'}>
                  {cell == 1 ? 'Yes' : 'No'}
                </Tag>
              )
            },
          ]}>

        </Table>
      </div>
      <Modal
        destroyOnClose
        open={shortListModalOpen}
        maskClosable={false}
        title="Short list"
        onCancel={() => {
          setShortListModalOpen(false)
        }}
        onOk={shortListForm.submit}
        confirmLoading={loading}>
        <Form
          form={shortListForm}
          layout="vertical"
          onFinish={handleFinish}>
          <Form.Item name="comment" label="Justification" rules={[{required: true, message: 'Justification is required!'}]}>
            <Input.TextArea placeholder='Type justification'>

            </Input.TextArea>
          </Form.Item>
          <Form.Item name="attachment" label="Attachment">
            <Dragger 
              defaultFileList={[]} 
              maxCount={1} 
              action={`${process.env.REACT_APP_MAIN_API_URL}File/upload`}
              headers={{
                Authorization: `Bearer ${authContext.state.token}`,
              }}
              onRemove={(file) => {
                instance({
                  method: 'delete',
                  url: `File/remove`,
                  data: file.response.responseData
                })
              }}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                banned files.
              </p>
              {/* <Button icon={<UploadOutlined />}>Select file</Button> */}
            </Dragger>
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  )
}

export default BidderList