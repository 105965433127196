import { AutoComplete, Button, Divider, Form, Input, InputNumber, Modal, Popconfirm, Popover, Select, Table } from 'antd'
import { PlusOutlined, DeleteOutlined, MoreOutlined } from '@ant-design/icons'
import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { instance } from 'utils/axios'

const EditableContext = createContext(null);

const EditableRow = ({ index, ...props }) => {
  const form = Form.useFormInstance();

  return (
    <EditableContext.Provider value={form}>
      <tr {...props} />
    </EditableContext.Provider>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  remove,
  dataIndex,
  record,
  handleSave,
  type,
  options,
  mode,
  ...restProps
}) => {
  const [editing, setEditing] = useState(true);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={[record.key, dataIndex]}
        rules={type == "select" ? [{required: true, message: 'Please select'}] : []}
      >
        {
          type ? 
          type == 'select' ?
          <Select options={options} mode={mode == 'tags' ? 'tags' : undefined} maxCount={1}/>
          :
          type == 'autocomplete' ? 
          <AutoComplete options={options} filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }>
            
          </AutoComplete>
          :
          type == "number" &&
          <InputNumber ref={inputRef} className="w-full"></InputNumber>
          :
          <Input ref={inputRef}/>
        }
      </Form.Item>
    ) : (
      {children}
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const FreightAdditionalForm = () => {
  const [ loading, setLoading ] = useState(true)
  const [ placeOfDelivery, setPlaceOfDelivery ] = useState([])
  const form = Form.useFormInstance();

  useEffect(() => {
    instance({
      method: 'get',
      url: `/TenderPlaceOfDelivery?wcode=master`
    }).then(res => {
      setPlaceOfDelivery(res.data.responseData.map(foo => ({value: foo.shortName, label: foo.shortName})))
    }).catch(err => {

    })
  }, [])
  
  const defaultColumns = useMemo(() => {
    return [
      {
        title: "#",
        editable: false,
        width: `50px`,
        render: (cell, row, index) => index+1
      },
      {
        title: 'SRN Number',
        dataIndex: 'srnNumber',
        editable: true,
        width: 120
      },
      {
        title: 'Total Gross Weight',
        dataIndex: 'grossWeight',
        type: 'number',
        editable: true,
        width: 150
      },
      {
        title: 'Freight Description',
        dataIndex: 'description',
        editable: true,
      },
      {
        title: 'Transport Mode',
        dataIndex: 'transportMode',
        type: 'select',
        options: [{label: 'Road', value: 'road'},{label: 'Rail', value: 'rail'},{label: 'Air', value: 'air'},{label: 'Maritime', value: 'maritime'},{label: 'Pipeline', value: 'pipeline'},{label: 'Sea', value: 'Sea'},],
        editable: true,
      },
      {
        title: 'Transport Type',
        dataIndex: 'transportType',
        type: 'autocomplete',
        mode: 'tags',
        options: [
          {
            label: "AIR",
            value: "AIR"
          }, 
          {
            label: "LCL",
            value: "LCL"
          }, 
          {
            label: "LTL",
            value: "LTL"
          }, 
          {
            label: "FTL",
            value: "FTL"
          }, 
          {
            label: "20' FCL",
            value: "20' FCL"
          }, 
          {
            label: "40' FCL",
            value: "40' FCL"
          }, 
          {
            label: "40H FCL",
            value: "40H FCL"
          }, 
          {
            label: "20' OT",
            value: "20' OT"
          }, 
          {
            label: "40' OT",
            value: "40' OT"
          }, 
          {
            label: "20' FR",
            value: "20' FR"
          }, 
          {
            label: "40' FR",
            value: "40' FR"
          }, 
          {
            label: "20' RF",
            value: "20' RF"
          }, 
          {
            label: "40' RF",
            value: "40' RF"
          }
        ],
        editable: true,
      },
      {
        title: 'Package Quantity',
        dataIndex: 'packageQty',
        type: 'number',
        editable: true,
        width: 100
      },
      {
        title: 'Total CBM',
        dataIndex: 'cbm',
        type: 'number',
        editable: true,
        width: 100
      },
      {
        title: 'Place Of Loading',
        dataIndex: 'placeLoading',
        editable: true,
      },
      {
        title: 'Place Of Delivery',
        dataIndex: 'placeDelivery',
        options: placeOfDelivery,
        editable: true,
      },
      {
        title: '',
        dataIndex: 'operation',
        render: (_, record) =>
          form.getFieldValue("details").length >= 1 ? (
            <Popover
              content={(
                <div className='flex flex-col'>
                  <Popconfirm title="Are you sure to duplicate?" onConfirm={() => handleDuplicate(record.key)}>
                    <a className="px-3 py-1">Duplicate</a>
                  </Popconfirm>
                  <Popconfirm title="Are you sure to delete?" onConfirm={() => handleDelete(record.key)}>
                    <a className='px-3 py-1'>Delete</a>
                  </Popconfirm>
                </div>
              )}>
              <MoreOutlined />
            </Popover>
          ) : null,
      },
    ]
  }, [placeOfDelivery])

  const handleDuplicate = (key) => {
    let tmp = [...form.getFieldValue("details")]
    form.setFieldValue("details", [...tmp, tmp[key]])
  }

  const handleDelete = (key) => {
    let tmp = [...form.getFieldValue("details")]
    tmp.splice(key, 1);
    form.setFieldValue("details", tmp);
  };

  const handleSave = (row) => {
    const newData = [...form.getFieldValue("details")]
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    form.setFieldValue("details", newData);
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        type: col.type,
        options: col.options,
        mode: col.mode,
        handleSave,
      }),
    };
  });
  
  return (
    <>
    <Divider className="col-span-12" orientation="left">Items</Divider>
    {/* <div className="col-span-12 flex gap-4 justify-end">
      <Button type="primary" onClick={() => setPrModalOpen(true)}>Import PR Number</Button>
      <Button type="primary" onClick={() => setMaterialModalOpen(true)}>Import Material Number</Button>
    </div> */}
    <Form.List 
      name="details"
      rules={[
        {
          validator: async (_, details) => {
            if (!details || details.length < 1) {
              return Promise.reject(new Error('At least 1 detail'));
            }
          },
        },
      ]}>
      {(fields, {add, remove}) => {
        return (
          <div className="col-span-12 flex flex-col gap-4">
            <Table
              dataSource={fields}
              rowKey={(row, index) => {
                return index
              }}
              pagination={false}
              components={{
                body: {
                  cell: EditableCell,
                  row: EditableRow
                },
              }}
              bordered={true}
              columns={columns}>

            </Table>
            <Form.Item noStyle>
              <Button type="dashed" onClick={() => {
                add({state: 1})
              }} block icon={<PlusOutlined />}>
                Add
              </Button>
            </Form.Item>
          </div>
        )
      }}      
      
    </Form.List> 
    </>
  )
}

export default FreightAdditionalForm