import { Button, Card, Checkbox, Collapse, DatePicker, Form, Input, Modal, Table, Tag, Tooltip } from "antd"
import { useEffect, useRef, useState } from "react"
import { useOutletContext, useParams } from "react-router-dom"
import { instance } from "utils/axios"

const { Panel } = Collapse

const BidSummaryService = () => {
  const [ data, setData ] = useState({
    suppliers: [],
    detail: []
  })
  const { id } = useParams()
  const [ selectedOption, setSelectedOption ] = useState([])
  const [ messageData, setMessageData ] = useState({
    thread: {},
    messages: []
  })
  const [ awarding, setAwarding ] = useState(false)
  const [ approvalModalOpen, setApprovalModalOpen ] = useState(false)
  const [ negotiationModalOpen, setNegotiationModalOpen ] = useState(false)
  const [ suggestModalOpen, setSuggestModalOpen ] = useState(false)
  const [ comment, setComment ] = useState("")
  const [ negotiationForm ] = Form.useForm()
  const carouselRef = useRef([])
  const [ sending, setSending ] = useState(false)
  
  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `/TenderBidSummary/rfq/servicebidsummary/${id}?wcode=tender-detail`,
    }).then(res => {
      console.log(res.data.responseData)
      if(res.status != 204) {
        setData(res.data.responseData)
      }
    }).catch(err => {
      console.log(err)
    })
  }

  const getSelectedDatas = () => {
    let tmp = []

    
    data.suppliers.map((foo, fooIndex) => {
      if(foo.details.filter(_detail => selectedOption.find(ai => ai.id == _detail.id)).length) {
        tmp.push({
          ...foo,
          details: foo.details.filter(_detail => selectedOption.find(ai => ai.id == _detail.id))
        })
      }
    })
    
    return tmp
  }

  const handleSend = () => {
    setSending(true)
    instance({
      method: 'post',
      url: `/TenderBidSummary/rfq/generatebidsummary?wcode=tender-detail`,
      data: {
        id: id,
        comment: comment,
        supplierDetails: selectedOption.map(foo => foo.id)
      }
    }).then(() => {
      setComment("")
      setSelectedOption([])
      setSuggestModalOpen(false)
      fetchData()
    }).catch(err => {

    }).then(() => {
      setSending(false)
    })
  }

  const handleSendMessage = () => {
    setSending(true)
    instance({
      method: 'post',
      url: `/Tender/rfq/generatebidsummary`,
      data: {
        messageThreadId: messageData.thread.id,
        tenderId: id,
        body: comment,
        details: selectedOption.map(foo => foo.id)
      }
    }).then(() => {
      setComment("")
      setSelectedOption([])
      setSuggestModalOpen(false)
      fetchData()
    }).catch(err => {

    }).then(() => {
      setSending(false)
    })
  }

  const handleNegotiation = (values) => {
    setSending(true)
    instance({
      method: 'put',
      url: `/Tender/opennegotiate`,
      data: {
        id: id,
        endDate: values.endDate,
        supplierItemIds: selectedOption.map(foo => foo.id),
        comment: values.comment
      }
    }).then(() => {
      setComment("")
      setNegotiationModalOpen(false)
      fetchData()
    }).catch(err => {

    }).then(() => {
      setSending(false)
    })
  }
  
  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col mt-3">
        {
          data.suppliers.sort((a,b) => b.awardStatus - a.awardStatus).map((foo, fooIndex) => {
            return (
              <div className="grid grid-cols-12 border" key={`supplier-${fooIndex}`}>
                <div className="col-span-4 flex flex-col gap-2 border-r py-2 px-4">
                  {foo.name}
                </div>
                <div className="col-span-8">
                  <Collapse ref={(refElement) => carouselRef.current[fooIndex] = refElement} className="rounded-none">
                    {
                      foo.details.map((option, optionIndex) => {
                        return (
                          <Panel header={
                            <div>
                              <div className="flex justify-between">
                                <div>
                                  <span className="font-semibold mr-6">Option #{optionIndex+1}: </span> 
                                  <span className="font-semibold">{option.totalPrice.toLocaleString("en-US")}₮</span>
                                </div>
                                {/* <Checkbox 
                                  checked={selectedOption.findIndex(foo => foo.id == option.id) != -1}
                                  onChange={e => {
                                    e.stopPropagation()
                                    if(e.target.checked) {
                                      setSelectedOption([...selectedOption, option])
                                    }
                                    else {
                                      setSelectedOption(prev => {
                                        return prev.filter(foo => foo.id != option.id)
                                      })
                                    }
                                  }}>
                                  Select option
                                </Checkbox> */}
                              </div>
                            </div>
                          } key={`supplier-${fooIndex}-option-${optionIndex}`} className='px-3 py-2'>
                            <div className="h-[250px] overflow-auto" dangerouslySetInnerHTML={{__html: option.content}}>

                            </div>
                          </Panel>
                        )
                      })
                    }
                  </Collapse>
                </div>
              </div>
            )
          })
        }
      </div>
      
    </div>
  )
}

export default BidSummaryService