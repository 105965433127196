import dayjs from 'dayjs';
import XLSX from 'sheetjs-style';

const templateGenerate = (data, tenderData) => {
  const wb = XLSX.utils.book_new();
  let col = []
  col.push(
    [
      {
        v: 'Bidders List Approval',
        t: 's',
        s: { 
          font: { sz: 18 },
          alignment: { 
            vertical: 'center',
            horizontal: 'center'
          },
        } 
      }
    ]
  )
  col.push(
    [
      {
        v: tenderData.name,
        t: 's',
        s: { font: { sz: 9}} 
      }
    ]
  )
  col.push(
    [
      {
        v: `ARIBA NO: WS/CW NUMBER: [${tenderData.code}]`,
        t: 's',
        s: { font: { sz: 9}} 
      }
    ]
  )
  //head
  col.push(
    [
      {
        v: 'VALUE FOR THIS SCOPE ONLY/TERM',
        t: 's',
        s: { 
          font: { sz: 9, bold: true },
          alignment: { wrapText: true },
          border: { 
            bottom: { 
              style: 'thin', color: { rgb: "#000000"}
            }
          }
        } 
      },
      {
        v: '',
        t: 's',
        s: { 
          font: { sz: 9, bold: true },
          alignment: { wrapText: true },
          border: { 
            bottom: { 
              style: 'thin', color: { rgb: "#000000"}
            }
          }
        } 
      },
      {
        v: 'BID ISSUE DATE',
        t: 's',
        s: { 
          font: { sz: 9, bold: true },
          alignment: { wrapText: true },
          border: { 
            bottom: { 
              style: 'thin', color: { rgb: "#000000"}
            }
          }
        } 
      },
      {
        v: 'BID CLOSING DATE',
        t: 's',
        s: { 
          font: { sz: 9, bold: true },
          alignment: { wrapText: true },
          border: { 
            bottom: { 
              style: 'thin', color: { rgb: "#000000"}
            }
          }
        } 
      },
      {
        v: 'SOURCING PROJECT OWNER',
        t: 's',
        s: { 
          font: { sz: 9, bold: true },
          alignment: { wrapText: true },
          border: { 
            bottom: { 
              style: 'thin', color: { rgb: "#000000"}
            }
          }
        } 
      },
      {
        v: '',
        t: 's',
        s: { 
          font: { sz: 9, bold: true },
          alignment: { wrapText: true },
          border: { 
            bottom: { 
              style: 'thin', color: { rgb: "#000000"}
            }
          }
        } 
      },
    ]
  )
  col.push(
    [
      {
        v: '',
        t: 's',
        s: { 
          font: { sz: 9, bold: true },
          alignment: { wrapText: true },
          border: { 
            bottom: { 
              style: 'thin', color: { rgb: "#000000"}
            }
          }
        } 
      },
      {
        v: '',
        t: 's',
        s: { 
          font: { sz: 9, bold: true },
          alignment: { wrapText: true },
          border: { 
            bottom: { 
              style: 'thin', color: { rgb: "#000000"}
            }
          }
        } 
      },
      {
        v: dayjs(tenderData.startDate).format("DD/MM/YYYY"),
        t: 's',
        s: { 
          font: { sz: 9, bold: true },
          alignment: { wrapText: true },
          border: { 
            bottom: { 
              style: 'thin', color: { rgb: "000000"}
            }
          }
        } 
      },
      {
        v: dayjs(tenderData.endDate).format("DD/MM/YYYY"),
        t: 's',
        s: { 
          font: { sz: 9, bold: true },
          alignment: { wrapText: true },
          border: { 
            bottom: { 
              style: 'thin', color: { rgb: "000000"}
            }
          }
        } 
      },
      {
        v: 'SOURCING PROJECT OWNER',
        t: 's',
        s: { 
          font: { sz: 9, bold: true },
          alignment: { wrapText: true },
          border: { 
            bottom: { 
              style: 'thin', color: { rgb: "000000"}
            }
          }
        } 
      },
      {
        v: '',
        t: 's',
        s: { 
          font: { sz: 9, bold: true },
          alignment: { wrapText: true },
          border: { 
            bottom: { 
              style: 'thin', color: { rgb: "000000"}
            }
          }
        } 
      },
    ]
  )
  col.push([
    {
      v: 'IDENTIFIED POTENTIAL SUPPLIERS INFORMATION:',
      t: 's',
      s: {
        font: { sz: 9, bold: true },
        alignment: { wrapText: true, vertical: 'center' },
        border: { 
          bottom: { 
            style: 'thin', color: { rgb: "000000"}
          }
        }
      }
    }
  ])
  col.push([
    {

    }
  ])
  col.push([
    {
      v: '#',
      t: 's',
      s: {
        font: { sz: 9, color: { rgb: "FFFFFF"}},
        fill: { fgColor: { rgb: "808080" } },
        alignment: { vertical: 'center', horizontal: 'center', wrapText: true},
        border: { 
          bottom: { 
            style: 'thin', color: { rgb: "000000"}
          },
          left: { 
            style: 'thin', color: { rgb: "000000"}
          },
          top: { 
            style: 'thin', color: { rgb: "000000"}
          },
          right: { 
            style: 'thin', color: { rgb: "000000"}
          },
        }
      }
    },
    {
      v: 'List of all identified potential suppliers',
      t: 's',
      s: {
        font: { sz: 9, color: { rgb: "FFFFFF"}},
        fill: { fgColor: { rgb: "808080" } },
        alignment: { vertical: 'center', horizontal: 'center', wrapText: true},
        border: { 
          bottom: { 
            style: 'thin', color: { rgb: "000000"}
          },
          left: { 
            style: 'thin', color: { rgb: "000000"}
          },
          top: { 
            style: 'thin', color: { rgb: "000000"}
          },
          right: { 
            style: 'thin', color: { rgb: "000000"}
          },
        }
      }
    },
    {
      v: 'Competitive Advantages  reason for / against inclusion',
      t: 's',
      s: {
        font: { sz: 9, color: { rgb: "FFFFFF"}},
        fill: { fgColor: { rgb: "808080" } },
        alignment: { vertical: 'center', horizontal: 'center', wrapText: true},
        border: { 
          bottom: { 
            style: 'thin', color: { rgb: "000000"}
          },
          left: { 
            style: 'thin', color: { rgb: "000000"}
          },
          top: { 
            style: 'thin', color: { rgb: "000000"}
          },
          right: { 
            style: 'thin', color: { rgb: "000000"}
          },
        }
      }
    },
    {
      v: 'Pre-qualified (Yes/ No, if no, what is the status in Oyu)',
      t: 's',
      s: {
        font: { sz: 9, color: { rgb: "FFFFFF"}},
        fill: { fgColor: { rgb: "808080" } },
        alignment: { vertical: 'center', horizontal: 'center', wrapText: true},
        border: { 
          bottom: { 
            style: 'thin', color: { rgb: "000000"}
          },
          left: { 
            style: 'thin', color: { rgb: "000000"}
          },
          top: { 
            style: 'thin', color: { rgb: "000000"}
          },
          right: { 
            style: 'thin', color: { rgb: "000000"}
          },
        }
      }
    },
    {
      v: 'Supplier Tier (Umnugovi, National, Tier1, Tier2, Tier3*)',
      t: 's',
      s: {
        font: { sz: 9, color: { rgb: "FFFFFF"}},
        fill: { fgColor: { rgb: "808080" } },
        alignment: { vertical: 'center', horizontal: 'center', wrapText: true},
        border: { 
          bottom: { 
            style: 'thin', color: { rgb: "000000"}
          },
          left: { 
            style: 'thin', color: { rgb: "000000"}
          },
          top: { 
            style: 'thin', color: { rgb: "000000"}
          },
          right: { 
            style: 'thin', color: { rgb: "000000"}
          },
        }
      }
    },
    {
      v: ' ',
      t: 's',
      s: {
        font: { sz: 9, color: { rgb: "FFFFFF"}},
        fill: { fgColor: { rgb: "808080" } },
        alignment: { vertical: 'center', horizontal: 'center', wrapText: true},
        border: { 
          bottom: { 
            style: 'thin', color: { rgb: "000000"}
          },
          left: { 
            style: 'thin', color: { rgb: "000000"}
          },
          top: { 
            style: 'thin', color: { rgb: "000000"}
          },
          right: { 
            style: 'thin', color: { rgb: "000000"}
          },
        }
      }
    },
  ])

  data.map((foo, fooIndex) => {
    col.push([
      {
        v: fooIndex+1,
        t: 's',
        s: {
          font: { sz: 9},
          alignment: { vertical: 'center', horizontal: 'center', wrapText: true},
          border: { 
            bottom: { 
              style: 'thin', color: { rgb: "000000"}
            },
            left: { 
              style: 'thin', color: { rgb: "000000"}
            },
            top: { 
              style: 'thin', color: { rgb: "000000"}
            },
            right: { 
              style: 'thin', color: { rgb: "000000"}
            },
          }
        }
      },
      {
        v: foo.name,
        t: 's',
        s: {
          font: { sz: 9},
          alignment: { vertical: 'center', horizontal: 'center', wrapText: true},
          border: { 
            bottom: { 
              style: 'thin', color: { rgb: "000000"}
            },
            left: { 
              style: 'thin', color: { rgb: "000000"}
            },
            top: { 
              style: 'thin', color: { rgb: "000000"}
            },
            right: { 
              style: 'thin', color: { rgb: "000000"}
            },
          }
        }
      },
      {
        v: '',
        t: 's',
        s: {
          font: { sz: 9},
          alignment: { vertical: 'center', horizontal: 'center', wrapText: true},
          border: { 
            bottom: { 
              style: 'thin', color: { rgb: "000000"}
            },
            left: { 
              style: 'thin', color: { rgb: "000000"}
            },
            top: { 
              style: 'thin', color: { rgb: "000000"}
            },
            right: { 
              style: 'thin', color: { rgb: "000000"}
            },
          }
        }
      },
      {
        v: foo.preQualificationStatus == 0 ? 'Not-qualified' : 'Pre-qualified',
        t: 's',
        s: {
          font: { sz: 9},
          alignment: { vertical: 'center', horizontal: 'center', wrapText: true},
          border: { 
            bottom: { 
              style: 'thin', color: { rgb: "000000"}
            },
            left: { 
              style: 'thin', color: { rgb: "000000"}
            },
            top: { 
              style: 'thin', color: { rgb: "000000"}
            },
            right: { 
              style: 'thin', color: { rgb: "000000"}
            },
          }
        }
      },
      {
        v: foo.supplierTierId == 0 ? '' : '',
        t: 's',
        s: {
          font: { sz: 9},
          alignment: { vertical: 'center', horizontal: 'center', wrapText: true},
          border: { 
            bottom: { 
              style: 'thin', color: { rgb: "000000"}
            },
            left: { 
              style: 'thin', color: { rgb: "000000"}
            },
            top: { 
              style: 'thin', color: { rgb: "000000"}
            },
            right: { 
              style: 'thin', color: { rgb: "000000"}
            },
          }
        }
      },
    ])
  })
  
  const ws = XLSX.utils.aoa_to_sheet(col);

  ws['!merges'] = [
    {
      s: {
        c: 0,
        r: 0
      },
      e: {
        c: 5,
        r: 0
      }
    },
    {
      s: {
        c: 0,
        r: 1
      },
      e: {
        c: 5,
        r: 1
      }
    },
    {
      s: {
        c: 0,
        r: 2
      },
      e: {
        c: 5,
        r: 2
      }
    },
    {
      s: {
        c: 0,
        r: 3
      },
      e: {
        c: 1,
        r: 3
      }
    },
    {
      s: {
        c: 4,
        r: 3
      },
      e: {
        c: 5,
        r: 3
      }
    },
    {
      s: {
        c: 0,
        r: 4
      },
      e: {
        c: 1,
        r: 4
      }
    },
    {
      s: {
        c: 4,
        r: 4
      },
      e: {
        c: 5,
        r: 4
      }
    },
    {
      s: {
        c: 0,
        r: 5
      },
      e: {
        c: 5,
        r: 6
      }
    },
  ]

  if(!ws["!rows"]) ws["!rows"] = [];

  if(!ws["!rows"][0]) ws['!rows'][0] = {hpx: 78}
  if(!ws["!rows"][1]) ws['!rows'][1] = {hpx: 12}
  if(!ws["!rows"][2]) ws['!rows'][2] = {hpx: 12}
  if(!ws["!rows"][3]) ws['!rows'][3] = {hpx: 34.5}
  if(!ws["!rows"][4]) ws['!rows'][4] = {hpx: 34.5}
  if(!ws["!rows"][5]) ws['!rows'][5] = {hpx: 21}
  if(!ws["!rows"][6]) ws['!rows'][6] = {hpx: 16}

  if(!ws["!cols"]) ws["!cols"] = [];

  if(!ws["!cols"][0]) ws["!cols"][0] = {wpx: 21};
  if(!ws["!cols"][1]) ws["!cols"][1] = {wpx: 100};
  if(!ws["!cols"][2]) ws["!cols"][2] = {wpx: 100};
  if(!ws["!cols"][3]) ws["!cols"][3] = {wpx: 100};
  if(!ws["!cols"][4]) ws["!cols"][4] = {wpx: 100};
  if(!ws["!cols"][5]) ws["!cols"][5] = {wpx: 100};
  
  XLSX.utils.book_append_sheet(wb, ws, "Bidderlist");

  // STEP 4: Write Excel file to browser
  XLSX.writeFile(wb, `eoi-${tenderData.code}-bidderlist.xlsx`);
}

export { templateGenerate }