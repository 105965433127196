import React, { useEffect, useState } from 'react';
import { AuthContext } from 'contexts';
import menu from '../../menu';
import { Menu, Dropdown } from 'antd';
import ls from 'utils/ls';
import logo from '../../../assets/images/oyu-logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { DownOutlined, CaretDownOutlined, UserOutlined } from '@ant-design/icons'
import { IoChevronBack } from "react-icons/io5";
import { SignalRContext } from 'contexts/socketProvider';
import { twMerge } from 'tailwind-merge';

const Sidebar = ({}) => {
  const { state, action } = React.useContext(AuthContext);
  const [ currentKey, setCurrentKey ] = useState('');
  const [ roleMenu, setRoleMenu ] = useState([]);
  const navigate = useNavigate()
  
  SignalRContext.useSignalREffect(
    "ReceiveNotification", 
    (message) => {
      // console.log(message)
    },
  );
  
  useEffect(() => {
    setCurrentKey(ls.get('currentMenuKey'))
    setRoleMenu(getMenus(menu));
    // setRoleMenu(menu)
  },[]);

  const getMenus = (_menu) => {
    let tmp = []

    if(state.userInfo.permissions) {
      if(state.userInfo.permissions.some(foo => foo.permissionId == 1)) {
        return _menu
      }
      else {
        _menu.map(menuItem => {
          if(menuItem.role) {
            if(menuItem.role.some(foo => state.userInfo.permissions.some(ai => ai.permissionId == foo))) {
              if(menuItem.children) {
                tmp.push({
                  ...menuItem,
                  children: getMenus(menuItem.children)
                })
              }
              else {
                tmp.push(menuItem)
              }
            }
          }
          else {
            tmp.push({
              ...menuItem,
            })
          }
        })
      }
    }

    return tmp
  }

  const handleClickMenu = (e) => {
    setCurrentKey(e.key);
    ls.set('currentMenuKey', e.key);
  };

  const handleChangeCollapse = () => {
    action.handleSidebarCollapse(!state.sidebarCollapsed);
  };

  return (
      <div className={state.sidebarCollapsed ? 'sidebar w-full overflow-x-hidden' : 'sidebar w-full'}>
        <div className={twMerge("flex flex-col justify-center items-center border-b gap-4 py-6 px-4")}>
          <img src={logo} height={state.sidebarCollapsed ? 50 : 110} alt="logo" />
          
        </div>
        <div className={twMerge('grow overflow-y-auto overflow-x-hidden px-4', state.sidebarCollapsed && 'px-0')}>
          <Menu
            className="text-base bg-[#214160]"
            defaultSelectedKeys={['menu-item-0']}
            mode="inline"
            theme={'dark'}
            selectedKeys={[currentKey]}
            onClick={handleClickMenu}
            key={'menu'}
            inlineCollapsed={state.sidebarCollapsed}
            items={state.sidebarCollapsed ? [...roleMenu] : roleMenu}
            style={{borderRight: 0}}
          />
        </div>
        <div className={state.sidebarCollapsed ? 'flex justify-center' : 'flex justify-end'}>
          <button className='p-4 bg-transparent cursor-pointer' onClick={handleChangeCollapse}>
            <IoChevronBack size={20} className={`transition-all duration-500 text-white ${state.sidebarCollapsed ? 'rotate-180' : 'rotate-0'}`}/>
          </button>
        </div>
        <div className="bg-[#375A7C] p-6 flex gap-3 items-center">
          <div className="w-12 h-12 rounded-full overflow-hidden flex shrink-0">
            <img src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"/>
          </div>
          <Dropdown
            className="w-full hover:!bg-transparent"
            menu={{
              items: [
                {
                  label: 'Profile',
                  key: 0,
                },
                {
                  label: <Link to="/change-password">
                    Change password
                  </Link>,
                  key: 1,
                },
                {
                  label: <Link to="/delegation">
                    Delegation
                  </Link>,
                  key: 5,
                },
                {
                  label: <a onClick={() => {
                    action.logout();
                    ls.remove('userInfo') ;
                    navigate("/login");
                  }}>
                    Logout
                  </a>,
                  key: 2
                }
              ] 
            }}>
            <div className="group hover:bg-gray-100 transition-all py-1 rounded-lg px-4 flex justify-between items-center cursor-pointer">
              <div className="flex flex-col">
                <p className="mb-0 select-none cursor-pointer font-medium group-hover:font-semibold transition-all text-white">
                  {state.userInfo?.userName}
                </p>
                <p className="mb-0 select-none cursor-pointer text-sm group-hover:font-semibold transition-all text-white">
                  {state.userInfo?.userName}
                </p>
              </div>
              <CaretDownOutlined className="text-white"/>
            </div>
          </Dropdown>

        </div>
      </div>
  );
};

export default Sidebar;
