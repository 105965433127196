import React, { useEffect, useState } from 'react'
import { Button, Tooltip, Skeleton, Divider, Card } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { useNavigate, Outlet, NavLink } from 'react-router-dom';
import { instance } from 'utils/axios';
import { useParams, Link, useLocation } from "react-router-dom";
import { BsClipboardCheck, BsJournalBookmark, BsPeople, BsViewStacked, BsBoxes, BsChatQuote, BsPencilSquare, BsQuestionSquare  } from "react-icons/bs";
import { twMerge } from 'tailwind-merge';

function TenderDetail() {
  const [ data, setData ] = useState(null)
  const [ loading, setLoading ] = useState(true)
  const location = useLocation()
  const navigate = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `TenderBidSummary/rfq/${id}?wcode=tender-detail`
    }).then(res => {
      setData(res.data.responseData)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  return (
    <div className='p-4 grid grid-cols-12 gap-3'>
      <div className="col-span-2">
        <Card size="small">
          {
            loading ?
            <Skeleton/>
            :
            <div className="flex flex-col gap-2 px-3">
              <div className='text-lg text-primary'>
                {data.name}
              </div>
              <div className="text-mute">
                Tender number: {data.code}
              </div>
            </div>
          }
          <Divider></Divider>
          <div className="flex flex-col px-3">
            {
              data && data.rfqType == 1 ?
              <NavLink className={({isActive}) => twMerge("py-1 hover:text-primary transition-all hover:bg-gray-100 px-3 flex items-center gap-2 rounded", location.pathname.split("/").length == 3 && isActive && "bg-gray-200 text-primary")} 
                to={`/tender-end-user/${id}`}>
                <BsPeople className="text-lg"/> Bid summary
              </NavLink>
              :
              <Link className="py-1 hover:text-primary transition-all hover:bg-slate-200 px-3 flex items-center gap-2" 
                to={`bid-summary-service`}>
                <BsPeople className="text-lg"/> Bid summary
              </Link>
            }
            <NavLink className={({isActive}) => twMerge("py-1 hover:text-primary transition-all hover:bg-gray-100 px-3 flex items-center gap-2 rounded", location.pathname.split("/").length == 3 && isActive && "bg-gray-200 text-primary")} 
              to={`chat`}>
              <BsBoxes className="text-lg"/> Chat
            </NavLink>
          </div>
        </Card>
      </div>
      <div className="col-span-10">
        {
          loading  ?
          <Skeleton active></Skeleton>
          :
          <Outlet context={[{...data, loading}]}></Outlet>
        }
        {/* <TenderMain/> */}
      </div>
    </div>
  )
}

export default TenderDetail
