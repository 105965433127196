import { useContext, useEffect, useRef, useState } from "react"
import { instance } from "utils/axios"
import { useParams } from "react-router-dom"
import { Tooltip, Input, Button, Tabs, Dropdown, Modal, Popconfirm, Upload, Skeleton } from 'antd'
import { SendOutlined, PlusOutlined, MoreOutlined, CloseOutlined, FileAddOutlined } from '@ant-design/icons'
import moment from "moment"
import { EndUserChat, PrivateChat } from ".."
import { AuthContext } from "contexts"
import { SignalRContext } from "contexts/socketProvider"
import { upload } from "@testing-library/user-event/dist/upload"
import MessageComponent from "./MessageComponent"

const Chat = ({tenderData, type}) => {
  const [ data, setData ] = useState({
    thread: {},
    messages: []
  })
  const [ fetching, setFetching ] = useState(true)
  const [ loading, setLoading ] = useState(false)
  const [ message, setMessage ] = useState("")
  const [ comment, setComment ] = useState("")
  const [ selectedChat, setSelectedChat ] = useState(null)
  const [ signalName, setSignalName ] = useState(null)
  const [ fileList, setFileList ] = useState([])
  
  const { state } = useContext(AuthContext)
  const [ error, setError ] = useState(false)
  const messageContainer = useRef()
  const uploadRef = useRef()
  const { id } = useParams()

  
  useEffect(() => {
    if(data.thread.id) {
      SignalRContext.invoke("JoinMessageChannel1", {
        id: data.thread.id
      })
      const handleMessage = _m => {
        console.log(_m)
      }
      
      SignalRContext.on(signalName, m => {
        console.log(m)
      })

      return () => {
        SignalRContext.off(signalName, handleMessage)
      }
    }
  }, [signalName])

  SignalRContext.useSignalREffect(signalName, (_message) => {
    setData(prev => ({
      thread: prev.thread,
      messages: [_message, ...prev.messages]
    }))
  }, [signalName]);

  
  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    instance({
      method: 'get',
      url: `/Message/tender/main/${id}?wcode=tender-detail`
    }).then(res => {
      setData(res.data.responseData)
      if(res.data.responseData.thread?.id) {
        setSignalName(`thread${res.data.responseData.thread.id}ReceiveMessage`)
      }
    }).catch(err => {

    }).then(() => {
      setFetching(false)
    })
  }

  const handleDelete = (_message) => {
    instance({
      method: 'delete',
      url: `/Message/undo/${_message.id}?wcode=tender-detail`
    }).then(res => {
      fetchData()
    }).catch(err => {

    })
  }

  const renderMessages = () => {
    let tmp = []

    data.messages.filter(foo => foo.status != 9).map((message, messageIndex) => {
      tmp.push(
        <div key={messageIndex} className={message.isSupplier ? 'flex gap-3 items-start' : 'flex gap-3 items-start justify-end'}>
          <div className="max-w-[60%] relative">
            {
              message.isSupplier ?
              <span className="text-xs text-gray-300">{message.supplierName}</span>
              :
              <></>
            }
            <div className="flex">
              {
                message.isSupplier ?
                <div className="w-8 h-8 rounded-full bg-gray-100 mr-3"></div> : 
                <div>
                  <Dropdown
                    trigger={["click"]}
                    menu={{
                      items: [
                        {
                          label: (
                            <Popconfirm
                              title="Delete the task"
                              description="Are you sure to delete this task?"
                              onConfirm={() => handleDelete(message)}
                              onCancel={() => {}}
                              okText="Yes"
                              cancelText="No">
                            <a onClick={e => {
                              e.preventDefault()
                            }}>
                              Delete
                            </a>
                          </Popconfirm>
                          ),
                          key: 1
                        },
                      ]
                    }}>
                    <Button type="link" icon={<MoreOutlined/>}></Button>
                  </Dropdown>
                </div>
              }
              <Tooltip title={moment(message.createdAt).format("YYYY-MM-DD HH:mm:ss")} >
                <div className="font-medium px-4 py-1 shadow bg-gray-100 rounded-xl">
                  {
                    message.attachment ? 
                    <a href={message.attachment} target="_blank" className="text-link">
                      Preview attachment
                    </a>
                    :
                    message.body
                  }
                </div>
              </Tooltip>
              {
                message.isSupplier ?
                <div>
                  <Dropdown
                    trigger={["click"]}
                    menu={{
                      items: [
                        {
                          label: <a onClick={e => {
                            e.preventDefault()
                            setSelectedChat(message)
                          }}>
                            Reply
                          </a>,
                          key: 1
                        },
                      ]
                    }}>
                    <Button type="link" icon={<MoreOutlined/>}></Button>
                  </Dropdown>
                </div>
                : <div className="w-8 h-8 rounded-full bg-gray-100 ml-3"></div>
              }
            </div>
          </div>
          
        </div>
      )
    })

    return tmp
  }

  const handleSubmit = () => {
    setLoading(true)
    let tmp = []

    if(fileList.length > 0) {
      fileList.map((foo) => {
        tmp.push(
          instance({
            method: 'post',
            url: `/Message/reply`, 
            data: {
              messageThreadId: data.thread.id,
              tenderId: id,
              body: '',
              attachment: foo.url,
              messageId: selectedChat && selectedChat.id
            }
          })
        )
      })
    }

    if(message && message.length > 0) {
      tmp.push(
        instance({
          method: 'post',
          url: `/Message/reply`, 
          data: {
            messageThreadId: data.thread.id,
            tenderId: id,
            body: message,
            messageId: selectedChat && selectedChat.id
          }
        })
      )
    }
    else {
      setError(true)
    }
    Promise.all(tmp).then(res => {
      setLoading(false)
      setSelectedChat(null)
      messageContainer.current.focus()
    }).catch(err => {

    }).then(() => {
      fetchData()
      setLoading(false)
      setFileList([])
      setMessage('')
    })
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]

    const fd = new FormData()

    fd.append("file", file)
    
    instance({
      method: 'post',
      url: `File/upload`,
      data: fd,
      onUploadProgress: progressEvent => console.log(progressEvent)
    }).then(res => {
      setFileList(prev => {
        let tmp = Object.assign([], prev)
        tmp.push({
          uid: tmp.length,
          name: res.data.responseData.split("\\").at(-1),
          url: `${process.env.REACT_APP_CDN_URL}/${res.data.responseData}`,
          status: 'done'
        })

        return tmp
      })
    }).catch(err => {
      console.log(err)
    })
  }

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  
  return (
    <>
      <Tabs
        type="card"
        tabBarStyle={{
          marginBottom: 0
        }}
        items={[
          {
            label: 'Public chat',
            key: 0,
            children: (
              fetching ? 
              <Skeleton>
                
              </Skeleton>
              :
              <MessageComponent threadData={data} signalName={signalName}/>
            )
          },
          {
            label: 'Private chat',
            key: 1,
            children: <PrivateChat mainThread={data}/>
          },
          type == 2 &&
          {
            label: 'End user chat',
            key: 2,
            children: <EndUserChat/>
          },
        ]}>

      </Tabs>
    </>
  )
}

const Fileupload = () => {
  return (
    <div>
    </div>
  )
}

export default Chat