import { Button, Card, Modal, Tooltip, Form as AntdForm, List, Skeleton, Avatar, Tree } from "antd"
import { useEffect, useState } from "react"
import { instance } from "utils/axios"
import { EditOutlined, DeleteOutlined, LeftOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from "components";
import { headerFields, detailFields } from "./fields";
import { Link } from "react-router-dom";

const updateTreeData = (list, key, children) =>
  list.map((node) => {
    if (node.id === key) {
      return {
        ...node,
        children,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      };
    }
    return node;
  });

function SupportHub() {
  const [ header, setHeader ] = useState([])
  const [ detail, setDetail ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const [ selectedHeader, setSelectedHeader ] = useState(null)
  const [ headerModalOpen, setHeaderModalOpen ] = useState(false)
  const [ modal, contextHolder ] = Modal.useModal();
  const [ selectedHeaderData, setSelectedHeaderData ] = useState(null)
  const [ form ] = AntdForm.useForm()
  
  const [ detailModalOpen, setDetailModalOpen ] = useState(false)
  const [ selectedDetailData, setSelectedDetailData ] = useState(null)
  const [ formDetail ] = AntdForm.useForm()
  
  const actions = (_data, index) => {
    return (
      [
        <EditOutlined key={`category-${index}-edit`} onClick={(e) => {
          e.stopPropagation()
          handleHeaderEdit(_data)
        }}/>,
        <DeleteOutlined key={`category-${index}-delete`} onClick={(e) => {
          e.stopPropagation()
          handleHeaderDelete(_data)
        }}/>,
      ]
    )
  }

  useEffect(() => {
    fetchHeader()
  }, [])
  
  const fetchHeader = () => {
    instance({
      method: 'get',
      url: selectedHeader ? `/SupportHub/${selectedHeader.id}?wcode=support-hub` : `/SupportHub?wcode=support-hub`
    }).then(res => {
      setHeader(res.data.responseData)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const fetchDetail = ({key, children}) => {
    return new Promise((resolve) => {
      if(children && children?.length > 0) {
        resolve()
        return
      }
      else {
        instance({
          method: 'get',
          url: `/SupportHubDetail/supporthub/${key}?wcode=support-hub`
        }).then(res => {
          setHeader(prev => {
            return updateTreeData(prev, key, res.data.responseData.map(foo => ({
              title: (
                <div className="flex items-center gap-3 group">
                  <span>{foo.title}</span> 
                  <div className="hidden items-center gap-1 opacity-0 transition-all group-hover:flex group-hover:opacity-100">
                    <Tooltip title={`Edit ${foo.title}`}>
                      <Button icon={<EditOutlined/>} type="text" size="small">

                      </Button>
                    </Tooltip>
                    <Tooltip title={`Delete ${foo.title}`}>
                      <Button danger icon={<DeleteOutlined/>} type="text" size="small">

                      </Button>
                    </Tooltip>
                  </div>
                </div>
              ),
              key: foo.id,
              isLeaf: true
            })))
          })
        }).catch(err => {
    
        }).then(() => {
          resolve()
        })
      }
    })
  }

  const handleHeaderDelete = (_data) => {
    modal.confirm({
      title: 'Warning',
      content: 'You are about to delete this support hub category',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: 'delete',
            url: `SupportHub/${_data.id}?wcode=support-hub`
          }).then((res) => {
            resolve()
          }).catch((err) => {
            reject()
          }).then(() => fetchHeader())
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {}
    })
  }

  const handleHeaderEdit = (_data) => {
    setSelectedHeaderData(_data)
    setHeaderModalOpen(true)
  }

  const handleDetailDelete = (_data) => {
    modal.confirm({
      title: 'Warning',
      content: 'You are about to delete this support hub category',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: 'delete',
            url: `SupportHubDetail/${_data.id}?wcode=support-hub`
          }).then((res) => {
            resolve()
          }).catch((err) => {
            reject()
          }).then(() => fetchDetail())
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {}
    })
  }

  const handleDetailEdit = (_data) => {
    setSelectedDetailData(_data)
    setDetailModalOpen(true)
  }

  const handleHeaderSubmit = (_values) => {
    if(selectedHeaderData) {
      instance({
        method: 'put',
        url: `/SupportHub?wcode=support-hub`,
        data: {
          ...selectedHeaderData,
          ..._values,
          image: _values.image[0].response?.responseData,
          parentId: selectedHeader ? selectedHeader.id : null
        }
      }).then(res => {
          form.resetFields()
          setSelectedHeaderData(null)
          setHeaderModalOpen(false)
          fetchHeader()
      }).catch(err => {
  
      })
    }
    else {
      instance({
        method: 'post',
        url: `/SupportHub?wcode=support-hub`,
        data: {
          ..._values,
          image: _values.image[0].response?.responseData,
          parentId: selectedHeader ? selectedHeader.id : null
        }
      }).then(res => {
          form.resetFields()
          setHeaderModalOpen(false)
          fetchHeader()
      }).catch(err => {
  
      })
    }
  }

  const handleDetailSubmit = (_values) => {
    if(selectedDetailData) {
      instance({
        method: 'put',
        url: `/SupportHub?wcode=support-hub`,
        data: {
          ...selectedDetailData,
          ..._values,
          image: _values.image[0].response?.responseData,
          supportHubId: selectedHeader.id
        }
      }).then(res => {
          form.resetFields()
          setSelectedDetailData(null)
          setDetailModalOpen(false)
          fetchDetail()
      }).catch(err => {
  
      })
    }
    else {
      instance({
        method: 'post',
        url: `/SupportHub?wcode=support-hub`,
        data: {
          ..._values,
          image: _values.image[0].response?.responseData,
          supportHubId: selectedHeader.id
        }
      }).then(res => {
          form.resetFields()
          setDetailModalOpen(false)
          fetchDetail()
      }).catch(err => {
  
      })
    }
  }

  return (
    <div className="p-4 grid grid-cols-12">
      <div className="flex gap-3 overflow-hidden col-span-12">
        {
          selectedHeader &&
          <Tooltip title="Go back">
            <Button type="link" onClick={() => selectedHeader.parentId ? setSelectedHeader({id: selectedHeader.parentId}) : setSelectedHeader(null)}>
              <LeftOutlined/>
            </Button>
          </Tooltip>
        }
      </div>
      <div className="col-span-3 bg-white rounded drop-shadow p-3">
        <h3>Categories</h3>
        <Tree
          onSelect={e => console.log(e)}
          loadData={fetchDetail}
          treeData={[
            {
              title: (
                <div className="flex items-center gap-3 group">
                    <span>All</span> 
                    <div className="hidden items-center gap-1 opacity-0 transition-all group-hover:flex group-hover:opacity-100">
                      <Tooltip title={`Add new category`}>
                        <Button icon={<PlusOutlined/>} type="text" size="small" onClick={() => {}}>

                        </Button>
                      </Tooltip>
                    </div>
                  </div>
              ),
              key: -1,
              children: header.map(foo => ({
                key: foo.id, 
                title: (
                  <div className="flex items-center gap-3 group">
                    <span>{foo.title}</span> 
                    <div className="hidden items-center gap-1 opacity-0 transition-all group-hover:flex group-hover:opacity-100">
                      <Tooltip title={`Add new sub category on ${foo.title}`}>
                        <Button icon={<PlusOutlined/>} type="text" size="small">

                        </Button>
                      </Tooltip>
                      <Tooltip title={`Edit ${foo.title}`}>
                        <Button icon={<EditOutlined/>} type="text" size="small">

                        </Button>
                      </Tooltip>
                      <Tooltip title={`Delete ${foo.title}`}>
                        <Button danger icon={<DeleteOutlined/>} type="text" size="small">

                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                ), 
                children: foo.children ?? []
              }))
            }
          ]}
          showLine>
          
        </Tree>
      </div>
      <div className="col-span-9">

      </div>
      <Modal
        destroyOnClose
        title={selectedHeaderData ? <div className='flex items-center justify-start gap-2'><EditOutlined/>Edit</div> : <div className='flex items-center justify-start gap-2'><PlusCircleOutlined/>Add</div> }
        open={headerModalOpen}
        maskClosable={false}
        okText='Save'
        onOk={form.submit}
        onCancel={() => {
          form.resetFields()
          setSelectedHeaderData(null)
          setHeaderModalOpen(false)
        }}>
        <Form
          form={form}
          fields={headerFields()}
          layout="vertical"
          onFinish={handleHeaderSubmit}
          editData={selectedHeaderData}
          className='gap-3'>

        </Form>
      </Modal>
      <Modal
        destroyOnClose
        title={selectedDetailData ? <div className='flex items-center justify-start gap-2'><EditOutlined/>Edit</div> : <div className='flex items-center justify-start gap-2'><PlusCircleOutlined/>Add</div> }
        open={detailModalOpen}
        maskClosable={false}
        okText='Save'
        onOk={formDetail.submit}
        onCancel={() => {
          formDetail.resetFields()
          setSelectedDetailData(null)
          setDetailModalOpen(false)
        }}>
        <Form
          form={formDetail}
          fields={detailFields()}
          layout="vertical"
          onFinish={handleDetailSubmit}
          editData={selectedDetailData}
          className='gap-3'>

        </Form>
      </Modal>
      {contextHolder}
    </div>
  )
}

export default SupportHub
